import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const coach_CARD = {
    filter: [],
    tableHead: [{ key: 'name', label: 'nom' }],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/coachs/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/coachs/:id') + '&mode=duplicate',
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteCoach,
        },
    ],
    tableAllOptions: {
        label: 'COACHS SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteCoach,
            },
        ],
    },
    header: ['Configurer le coach'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'coach',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            sending: false,
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom du coach',
            i18nLabel: t('Nom du coach'),
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom du coach',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom du coach',
                i18nMessage: t('Veuillez renseigner le nom du coach'),
            },
        },
        doctolib: {
            name: 'doctolib',
            type: 'string',
            label: 'Lien doctolib',
            i18nLabel: t('Lien doctolib'),
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le lien doctolib',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le lien doctolib',
                i18nMessage: t('Veuillez renseigner le lien doctolib'),
            },
        },
        presentation: {
            name: 'presentation',
            type: 'translation',
            label: 'Présentation',
            i18nLabel: t('Présentation'),
            field: 'textarea',
            mandatory: true,
            placeholder: 'Renseignez ici la présentation de votre coach',
            value: {},
            // 'validation': {
            //     'mandatory': true,
            //     'isValid': false,
            //     'type': 'not-empty',
            //     'message': 'Veuillez renseigner la présentation de votre coach',
            //     'i18nMessage': t('Veuillez renseigner la présentation de votre coach'),
            // }
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
    },
}

export { coach_CARD }

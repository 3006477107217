import { t } from '@app/utils'
import * as constants from '@app/constants'
import { User, Card, Settings } from '@app/api'
import { addCSRFToken, deepCopy } from '@app/core'

var userThemesDiab = deepCopy(constants.custo.CARDS_TYPES)
for (var userdt in userThemesDiab) {
    if (
        constants.custo.CARDS_TYPES_USER_IGNORE.indexOf(
            userThemesDiab[userdt].key
        ) > -1
    ) {
        delete userThemesDiab[userdt]
    }
}

const user_USER = {
    filter: ['licence', 'customer', 'registerStep'],
    search: { label: 'ID, KPI, Utilisateurs' },
    tableHead: [
        { key: 'email', label: 'email' },
        { key: 'customer', label: 'entreprise' },
        { key: 'expiration', label: 'expiration', search: 'expire' },
        { key: 'connexion', label: 'connexion' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/users/users/:id'),
        },
        {
            key: 'resetpasswordUser',
            label: 'Renvoyer Mdp',
            kind: 'api',
            action: User.resetPasswordUser,
        },
        {
            key: 'clearCardType',
            label: 'Vider une carte',
            i18nlabel: t('Vider une carte'),
            kind: 'list-api',
            list: Object.keys(constants.custo.CARDS_CREATION).map((i) => {
                return {
                    key: constants.custo.CARDS_CREATION[i].type,
                    value: constants.custo.CARDS_CREATION[i].label,
                }
            }),
            action: Card.resetCardType,
        },
        {
            key: 'deleteUser',
            label: 'Supprimer',
            kind: 'confirm',
            action: User.deleteUser,
        },
    ],
    tableAllOptions: {
        label: 'UTILISATEURS SÉLECTIONNÉS',
        list: [
            {
                key: 'resetpasswordUser',
                label: 'Renvoyer Mdp',
                kind: 'api',
                action: User.resetPasswordUser,
            },
            {
                key: 'clearCardType',
                label: 'Vider une carte',
                i18nlabel: t('Vider une carte'),
                kind: 'list-api',
                list: Object.keys(constants.custo.CARDS_CREATION).map((i) => {
                    return {
                        key: constants.custo.CARDS_CREATION[i].type,
                        value: constants.custo.CARDS_CREATION[i].label,
                    }
                }),
                action: Card.resetCardType,
            },
            {
                key: 'deleteUser',
                label: 'Supprimer',
                kind: 'confirm',
                action: User.deleteUser,
            },
        ],
    },
    header: ['Configurer l‘utilisateur'],
    form: {
        'tabs': {
            type: 'string',
            field: 'tabs',
            value: [
                {
                    label: 'kpi du compte',
                    key: 'accountkpi',
                    marker: 'listing-kpi',
                },
                {
                    label: 'historique de connexion',
                    key: 'connexionhistoric',
                    marker: 'listing-connexion',
                },
                {
                    label: 'historique du forum',
                    key: 'forumhistoric',
                    marker: 'listing-forum',
                },
                {
                    label: 'commentaires',
                    key: 'commenthistoric',
                    marker: 'listing-comment',
                },
            ],
        },
        'id': {
            field: 'hidden',
            value: '',
        },
        'template': {
            field: 'hidden',
            value: 'user',
        },
        'email': {
            name: 'email',
            type: 'string',
            label: 'Email',
            field: 'email',
            placeholder: 'Renseignez ici l‘email de l‘utilisateur',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'is-email',
                message: 'Veuillez renseigner l‘email de l‘utilisateur',
                i18nMessage: t('Veuillez renseigner l‘email de l‘utilisateur'),
            },
            display: {
                mode: 'half',
            },
        },
        'fname': {
            name: 'fname',
            type: 'string',
            label: 'Prénom',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le prénom de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'nick': {
            name: 'nick',
            substitute: 'nickname',
            type: 'string',
            label: 'Pseudo',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le pseudo de l‘utilisateur',
            value: 'Utilisateur',
            display: {
                mode: 'half',
            },
        },
        'lname': {
            name: 'lname',
            type: 'string',
            label: 'Nom',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'gender': {
            name: 'gender',
            type: 'int',
            label: 'Sexe',
            field: 'radio',
            choice: {
                Homme: 1,
                Femme: 0,
                Indéterminé: 2,
            },
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'birthdate': {
            name: 'birthdate',
            type: 'date',
            label: 'Date de naissance',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            validation: {
                mandatory: false,
                isValid: false,
                type: 'is-date',
                message: 'Veuillez renseigner une date de naissance valide',
                i18nMessage: t(
                    'Veuillez renseigner une date de naissance valide'
                ),
            },
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'customer': {
            name: 'customer',
            type: 'customer',
            label: 'Entreprise',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'stepRegistration': {
            name: 'stepRegistration',
            type: 'int',
            label: 'Etape d‘inscription',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.REGISTER_STEPS,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
            display: {
                mode: 'half',
            },
        },
        'roles': {
            name: 'roles',
            type: 'array',
            label: 'Type de compte',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.USER_ROLES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'expire': {
            name: 'expire',
            type: 'date',
            label: 'Date d‘expiration',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'ssos': {
            name: 'ssos',
            type: 'ssos',
            label: 'Numéro / code',
            field: 'ssos',
            value: '',
            sending: false,
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'city.namedepartement': {
            name: 'city.namedepartement',
            substitute: 'city',
            type: 'autocomplete/city',
            label: 'Ville de résidence',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Aucune',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'timezone': {
            name: 'timezone',
            type: 'timezone',
            label: 'Fuseau horaire',
            field: 'dropdown',
            placeholder: 'Aucun',
            searchbox: true,
            list: 'dynamic',
            simplecall: true,
            apicall: Settings.getTimezones,
            apiparam: '',
            value: 'Europe/Paris',
            display: {
                mode: 'half',
            },
        },
        'locale': {
            name: 'locale',
            type: 'int',
            label: 'Langue préféré',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.LANGUAGES,
            value: 'FR',
            display: {
                mode: 'half',
            },
        },
        'metrics': {
            name: 'metrics',
            type: 'int',
            label: 'Unités poids',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.METRICS_WIDTH,
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'heightMetrics': {
            name: 'heightMetrics',
            type: 'int',
            label: 'Unité de taille',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.METRICS_HEIGHT,
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'sponsored': {
            name: 'sponsored',
            type: 'mailing',
            label: 'Filleul',
            field: 'listing',
            placeholder: 'Ajouter ici l‘adresse email du filleul',
            value: '',
            sending: false,
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        // 'settings.STORY': {
        //     'name': 'settings.STORY',
        //     'type': 'boolean',
        //     'label': 'Email daily flow',
        //     'field': 'radio',
        //     'choice': {
        //         'Oui': 1,
        //         'Non': 0
        //     },
        //     'display': {
        //         'border': 'bottom',
        //     },
        //     'value' : '',
        //     'defaultValue': 0,
        // },
        // 'settings.CHECKUPDAILY': {
        //     'name': 'settings.CHECKUPDAILY',
        //     'type': 'boolean',
        //     'label': 'Email quotidien',
        //     'field': 'radio',
        //     'choice': {
        //         'Oui': 1,
        //         'Non': 0
        //     },
        //     'value' : '',
        // },
        // 'settings.EMAILHEBDO': {
        //     'name': 'settings.EMAILHEBDO',
        //     'type': 'boolean',
        //     'label': 'Email hebdomadaire',
        //     'field': 'radio',
        //     'choice': {
        //         'Oui': 1,
        //         'Non': 0
        //     },
        //     'value' : '',
        // },
        // 'settings.BADGEUNLOCKED': {
        //     'name': 'settings.BADGEUNLOCKED',
        //     'type': 'boolean',
        //     'label': 'Badges',
        //     'field': 'radio',
        //     'choice': {
        //         'Oui': 1,
        //         'Non': 0
        //     },
        //     'value' : '',
        //     'display': {
        //         'border': 'bottom',
        //     }
        // },
        'themes': {
            name: 'themes',
            type: 'themes',
            label: 'Thématiques',
            field: 'checkbox',
            list: userThemesDiab,
            value: constants.custo.MANDATORY_THEMES,
            readonlyValues: constants.custo.MANDATORY_THEMES,
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un thème',
                i18nMessage: t('Veuillez sélectionner au moins un thème'),
            },
            display: {
                border: 'bottom',
            },
        },
        'security.emailVerified': {
            name: 'security.emailVerified',
            type: 'boolean',
            label: 'Email vérifié',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        'security.passwordLastChange': {
            name: 'security.passwordLastChange',
            type: 'date',
            label: 'Dernière modification m.d.p',
            field: 'readonly',
            sending: false,
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            display: {
                mode: 'half',
            },
        },
        'security.phoneVerified': {
            name: 'security.phoneVerified',
            type: 'boolean',
            label: 'Téléphone vérifié',
            field: 'radio',
            readonly: true,
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        'security.unblockLogin': {
            name: 'security.unblockLogin',
            type: 'boolean',
            label: 'Débloquer la connexion ?',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            sending: 'not-if-false',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'security.refreshAllowed': {
            name: 'security.refreshAllowed',
            type: 'boolean',
            label: 'Reconnexion',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        'security.loginRetryLeft': {
            name: 'security.loginRetryLeft',
            type: 'int',
            label: 'Tentatives restantes',
            field: 'int',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'security.devices': {
            name: 'security.devices',
            type: 'listing',
            label: 'Listes des appareils',
            field: 'listing',
            sending: false,
            value: [],
        },
        'security.resetDevices': {
            name: 'security.resetDevices',
            type: 'boolean',
            label: 'Reset devices ?',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            sending: 'not-if-false',
            value: '',
            display: {
                mode: 'half',
            },
        },
    },
}

export { user_USER }

import { t } from '@app/utils'

export const SESSION_DURATION = {
    0: { key: 'PT12H', value: '12 heures' },
    1: { key: 'P1D', value: '1 jour' },
    2: { key: 'P10D', value: '10 jours' },
    3: { key: 'PT5M', value: '5 minutes' },
}

export const PASSWORD_RENEWAL_PERIOD = {
    0: { key: 'PT5M', value: '5 minutes' },
    1: { key: 'P30D', value: '3 mois' },
    2: { key: 'P60D', value: '6 mois' },
    3: { key: 'P90D', value: '9 mois' },
    4: { key: 'P1Y', value: '12 mois' },
}

export const LANGUAGES = {
    0: { key: 'fr', value: 'fr' },
    1: { key: 'nl', value: 'nl' },
    2: { key: 'en', value: 'en' },
    3: { key: 'es', value: 'es' },
    4: { key: 'it', value: 'it' },
    5: { key: 'de', value: 'de' },
}

export const CONFIG_DOWNLOADS_LANGS = {
    0: { value: 'Français', key: 'fr' },
    1: { value: 'Anglais', key: 'en' },
    2: { value: 'Espagnol', key: 'es' },
    3: { value: 'Italien', key: 'it' },
    4: { value: 'Allemand', key: 'de' },
    5: { value: 'Pays-Bas', key: 'nl' },
}

import icoFR from '@app/img/ico/flag/flag-fr.png'
import icoFRx2 from '@app/img/ico/flag/flag-fr@2x.png'
import icoEN from '@app/img/ico/flag/flag-en.png'
import icoENx2 from '@app/img/ico/flag/flag-en@2x.png'
import icoES from '@app/img/ico/flag/flag-es.png'
import icoESx2 from '@app/img/ico/flag/flag-es@2x.png'
import icoDE from '@app/img/ico/flag/flag-de.png'
import icoDEx2 from '@app/img/ico/flag/flag-de@2x.png'
import icoIT from '@app/img/ico/flag/flag-it.png'
import icoITx2 from '@app/img/ico/flag/flag-it@2x.png'
import icoNL from '@app/img/ico/flag/flag-nl.png'
import icoNLx2 from '@app/img/ico/flag/flag-nl@2x.png'

export const NATIONS = {
    0: {
        value: 'Allemagne',
        i18nValue: t('Allemagne'),
        key: 'DE',
    },
    1: {
        value: 'Belgique',
        i18nValue: t('Belgique'),
        key: 'BE',
    },
    2: {
        value: 'Canada',
        i18nValue: t('Canada'),
        key: 'CA',
    },
    3: {
        value: 'Espagne',
        i18nValue: t('Espagne'),
        key: 'ES',
    },
    4: {
        value: 'Etats-Unis',
        i18nValue: t('Etats-Unis'),
        key: 'US',
    },
    5: {
        value: 'France',
        i18nValue: t('France'),
        key: 'FR',
    },
    6: {
        value: 'Italie',
        i18nValue: t('Italie'),
        key: 'IT',
    },
    7: {
        value: 'Royaume-Uni',
        i18nValue: t('Royaume-Uni'),
        key: 'GB',
    },
}

export const COUNTRIES = {
    0: {
        label: 'Français',
        i18nLabel: t('Français'),
        key: 'fr',
        flag: { normal: icoFR, retina: icoFRx2 },
        canBeActivated: true,
    },
    1: {
        label: 'Anglais',
        i18nLabel: t('Anglais'),
        key: 'en',
        flag: { normal: icoEN, retina: icoENx2 },
        canBeActivated: true,
    },
    2: {
        label: 'Espagnol',
        i18nLabel: t('Espagnol'),
        key: 'es',
        flag: { normal: icoES, retina: icoESx2 },
        canBeActivated: true,
    },
    3: {
        label: 'Allemand',
        i18nLabel: t('Allemand'),
        key: 'de',
        flag: { normal: icoDE, retina: icoDEx2 },
        canBeActivated: true,
    },
    4: {
        label: 'Italien',
        i18nLabel: t('Italien'),
        key: 'it',
        flag: { normal: icoIT, retina: icoITx2 },
        canBeActivated: true,
    },
    5: {
        label: 'Pays-Bas',
        i18nLabel: t('Pays-Bas'),
        key: 'nl',
        flag: { normal: icoNL, retina: icoNLx2 },
        canBeActivated: true,
    },
    6: {
        label: 'Français (US)',
        i18nLabel: t('Pays-Bas (US)'),
        key: 'fr-US',
        flag: { normal: icoFR, retina: icoFRx2 },
        canBeActivated: false,
    },
    7: {
        label: 'Pays-Bas (US)',
        i18nLabel: t('Pays-Bas (US)'),
        key: 'nl-US',
        flag: { normal: icoNL, retina: icoNLx2 },
        canBeActivated: false,
    },
    8: {
        label: 'Anglais (US)',
        i18nLabel: t('Anglais (US)'),
        key: 'en-US',
        flag: { normal: icoEN, retina: icoENx2 },
        canBeActivated: false,
    },
    9: {
        label: 'Espagnol (US)',
        i18nLabel: t('Espagnol (US)'),
        key: 'es-US',
        flag: { normal: icoES, retina: icoESx2 },
        canBeActivated: false,
    },
    10: {
        label: 'Italien (US)',
        i18nLabel: t('Italien (US)'),
        key: 'it-US',
        flag: { normal: icoIT, retina: icoITx2 },
        canBeActivated: false,
    },
    11: {
        label: 'Allemand (US)',
        i18nLabel: t('Allemand (US)'),
        key: 'de-US',
        flag: { normal: icoDE, retina: icoDEx2 },
        canBeActivated: false,
    },
}

/****/

export const CUSTOMERS = {
    theraflow: {
        title: 'Theraflow Connect',
        description: 'Theraflow Connect description',
        i18ndescription: t('Theraflow Connect description'),
        mailto: 'alfred@betterise.me',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
    },
}

export const EVAL_TYPES_MEDICAL = {
    0: { key: 'Slider', value: 'Slider', type: 1 },
    1: { key: 'Button', value: 'Button', type: 2 },
    2: { key: 'QCM', value: 'QCM', type: 3 },
    3: { key: 'Textarea', value: 'Textarea', type: 4 },
    4: {
        key: 'CryptedWeight',
        value: 'Poids',
        type: 5,
        tag: ['CRYPTEDWEIGHT_VALUE'],
        label: ['Poids'],
        i18nLabel: [t('Poids')],
    },
    5: {
        key: 'BloodPressure',
        value: 'Tension',
        type: 6,
        tag: ['BLOODPRESSURE_SYSTOLIC', 'BLOODPRESSURE_DIASTOLIC'],
        label: [
            'Pression sanguine Systolique',
            'Pression sanguine Diastolique',
        ],
        i18nLabel: [
            t('Pression sanguine Systolique'),
            t('Pression sanguine Diastolique'),
        ],
    },
    6: {
        key: 'CryptedTemperature',
        value: 'Température',
        type: 7,
        tag: ['CRYPTEDTEMPERATURE_VALUE'],
        label: ['Température'],
        i18nLabel: [t('Température')],
    },
    7: {
        key: 'Heartbeat',
        value: 'BPM',
        type: 8,
        tag: ['HEARTBEAT_VALUE'],
        label: ['BPM'],
        i18nLabel: [t('BPM')],
    },
    8: { key: 'Counter', value: 'Compteur', type: 9 },
    9: { key: 'StatCounter', value: 'Compteur Statistique', type: 10 },
    10: { key: 'Yesno', value: 'Oui / Non', type: 11 },
}

export const USER_ROLES = {
    0: {
        key: 'ROLE_BETTERISE_SUPERADMIN',
        value: 'Super Administrateur',
        i18nValue: t('Super Administrateur'),
    },
    1: {
        key: 'ROLE_CUSTOMER_SUPER_ADMIN',
        value: 'Customer Stat + Admin + Users',
        i18nValue: t('Customer Stat + Admin + Users'),
    },
    2: {
        key: 'ROLE_CUSTOMER_ADMIN',
        value: 'Customer Stat + Admin',
        i18nValue: t('Customer Stat + Admin'),
    },
    3: {
        key: 'ROLE_CUSTOMER_CONTENT',
        value: 'Customer contenu',
        i18nValue: t('Customer contenu'),
    },
    4: {
        key: 'ROLE_CUSTOMER_STAT',
        value: 'Customer Stat',
        i18nValue: t('Customer Stat'),
    },
    5: { key: 'ROLE_USER', value: 'Utilisateur', i18nValue: t('Utilisateur') },
    6: {
        key: 'ROLE_CARDIO_CARDIOLOGIST',
        value: 'Cardiologue',
        i18nValue: t('Cardiologue'),
    },
    7: {
        key: 'ROLE_CARDIO_NURSE',
        value: 'Infirmière',
        i18nValue: t('Infirmière'),
    },
    8: {
        key: 'ROLE_CARDIO_COACH',
        value: 'Coach Syndo',
        i18nValue: t('Coach Syndo'),
    },
    9: { key: 'ROLE_DOCTOR', value: 'Docteur', i18nValue: t('Docteur') },
}

export const MOD_SETTINGS_MONITORING = {
    0: {
        key: 'CryptedTiredness',
        value: 'Fatigue',
        i18nValue: t('Fatigue'),
        checked: false,
        bydefault: false,
    },
    1: {
        key: 'Glycemy',
        value: 'Glycémie',
        i18nValue: t('Glycémie'),
        checked: false,
        bydefault: false,
    },
    2: {
        key: 'CryptedHydration',
        value: 'Hydratation',
        i18nValue: t('Hydratation'),
        checked: false,
        bydefault: false,
    },
    3: {
        key: 'CryptedActivity',
        value: 'Marche',
        i18nValue: t('Marche'),
        checked: false,
        bydefault: false,
    },
    4: {
        key: 'CryptedMoral',
        value: 'Moral',
        i18nValue: t('Moral'),
        checked: false,
        bydefault: false,
    },
    5: {
        key: 'Observance',
        value: 'Observance',
        i18nValue: t('Observance'),
        checked: false,
        bydefault: false,
    },
    6: {
        key: 'CryptedWeight',
        value: 'Poids',
        i18nValue: t('Poids'),
        checked: false,
        bydefault: false,
    },
    7: {
        key: 'Heartbeat',
        value: 'Rythme cardiaque',
        i18nValue: t('Rythme cardiaque'),
        checked: false,
        bydefault: false,
    },
    8: {
        key: 'CryptedSleep',
        value: 'Sommeil',
        i18nValue: t('Sommeil'),
        checked: false,
        bydefault: false,
    },
    9: {
        key: 'CryptedStress',
        value: 'Stress',
        i18nValue: t('Stress'),
        checked: false,
        bydefault: false,
    },
    10: {
        key: 'CryptedSmoke',
        value: 'Tabac',
        i18nValue: t('Tabac'),
        checked: false,
        bydefault: false,
    },
    11: {
        key: 'BloodPressure',
        value: 'Tension',
        i18nValue: t('Tension'),
        checked: false,
        bydefault: false,
    },
    12: {
        key: 'CryptedWaistSize',
        value: 'Tour de taille',
        i18nValue: t('Tour de taille'),
        checked: false,
        bydefault: false,
    },
    13: {
        key: 'CryptedUrine',
        value: 'Urine',
        i18nValue: t('Urine'),
        checked: false,
        bydefault: false,
    },
}

export const MOD_SETTINGS_ETP = {
    0: {
        key: 'ACTIVITY',
        value: 'Activité Physique',
        i18nValue: t('Activité Physique'),
        checked: false,
        bydefault: false,
    },
    1: {
        key: 'ALCOOL',
        value: 'Alcool',
        i18nValue: t('Alcool'),
        checked: false,
        bydefault: false,
    },
    2: {
        key: 'HAPPINESS',
        value: 'Bonheur',
        i18nValue: t('Bonheur'),
        checked: false,
        bydefault: false,
    },
    3: {
        key: 'HEART',
        value: 'Coeur en forme',
        i18nValue: t('Coeur en forme'),
        checked: false,
        bydefault: false,
    },
    4: {
        key: 'DIABETES',
        value: 'Diabète',
        i18nValue: t('Diabète'),
        checked: false,
        bydefault: false,
    },
    5: {
        key: 'FAQ',
        value: 'FAQ',
        i18nValue: t('FAQ'),
        checked: false,
        bydefault: false,
    },
    6: {
        key: 'TIREDNESS',
        value: 'Fatigue',
        i18nValue: t('Fatigue'),
        checked: false,
        bydefault: false,
    },
    7: {
        key: 'PREGNANCY',
        value: 'Grossesse',
        i18nValue: t('Grossesse'),
        checked: false,
        bydefault: false,
    },
    8: {
        key: 'MEMORY',
        value: 'Mémoire',
        i18nValue: t('Mémoire'),
        checked: false,
        bydefault: false,
    },
    9: {
        key: 'MORAL',
        value: 'Moral',
        i18nValue: t('Moral'),
        checked: false,
        bydefault: false,
    },
    10: {
        key: 'NUTRITION',
        value: 'Nutrition',
        i18nValue: t('Nutrition'),
        checked: false,
        bydefault: false,
    },
    11: {
        key: 'SLEEP',
        value: 'Sommeil',
        i18nValue: t('Sommeil'),
        checked: false,
        bydefault: false,
    },
    12: {
        key: 'STRESS',
        value: 'Stress',
        i18nValue: t('Stress'),
        checked: false,
        bydefault: false,
    },
    13: {
        key: 'TOBACCO',
        value: 'Tabac',
        i18nValue: t('Tabac'),
        checked: false,
        bydefault: false,
    },
    14: {
        key: 'TREATMENT',
        value: 'Traitement',
        i18nValue: t('Traitement'),
        checked: false,
        bydefault: false,
    },
    15: {
        key: 'MYPATHOLOGY',
        value: 'Ma pathologie',
        i18nValue: t('Ma pathologie'),
        checked: false,
        bydefault: false,
    },
    16: {
        key: 'PITUITARY',
        value: 'Hypophyse',
        i18nValue: t('Hypophyse'),
        checked: false,
        bydefault: false,
    },

    // '2' : {'key' : 'CANCER', 'value' : 'Anticancer', 'i18nValue' : t('Anticancer'), checked: false, bydefault: false},
    // '10' : {'key' : 'WEIGHT', 'value' : 'Poids', 'i18nValue' : t('Poids'), checked: false, bydefault: false},
}

import themeFood2 from '@app/img/themes/picto-nutrition.png'
import themeActivity from '@app/img/themes/picto-activite.png'
import themeSleep2 from '@app/img/themes/picto-sommeil.png'
import themeStress from '@app/img/themes/picto-stress.png'
import themeShape from '@app/img/themes/picto-moral.png'
import themeTobacco from '@app/img/themes/picto-tabac.png'
import themeAlcool from '@app/img/themes/picto-alcool.png'
import themeMemory from '@app/img/themes/picto-memoire.png'
import themeAnticancer from '@app/img/themes/picto-anticancer.png'
import themeBackache from '@app/img/themes/picto-maldedos.png'
import themeWorkingPosition from '@app/img/themes/picto-autravail.png'
import themeShapedHeart from '@app/img/themes/picto-coeur.png'
import themeHappinessPsychology from '@app/img/themes/picto-bonheur.png'
import themeProfil from '@app/img/themes/picto-profil.png'
import themeFollowedWeight from '@app/img/themes/picto-poids.png'
import themeHappyPregnancy from '@app/img/themes/picto-grossesse.png'
import themeTiredness from '@app/img/themes/picto-fatigue.png'
import themeSport from '@app/img/themes/picto-sport.png'
import themeDenivele from '@app/img/themes/picto-activite.png'

export const CARDS_TYPES_USER_IGNORE = [19, 20]
export const CARDS_TYPES = {
    0: { theme: '', value: '', key: 0, color: '' },
    1: {
        theme: 'activity',
        value: 'Activité',
        i18nLabel: t('Activité'),
        key: 2,
        color: '049588',
        order: 1,
        img: themeActivity,
        display: 'both',
    },
    2: {
        theme: 'alcool',
        value: 'Alcool',
        i18nLabel: t('Alcool'),
        key: 8,
        color: 'A62671',
        order: 2,
        img: themeAlcool,
        display: 'both',
    },
    3: {
        theme: 'happiness-psychology',
        value: 'Bonheur',
        i18nLabel: t('Bonheur'),
        key: 14,
        color: '',
        order: 3,
        img: themeHappinessPsychology,
        display: 'both',
    },
    4: {
        theme: 'shaped-heart',
        value: 'Coeur',
        i18nLabel: t('Coeur'),
        key: 13,
        color: '',
        order: 4,
        img: themeShapedHeart,
        display: 'both',
    },
    5: {
        theme: 'diabet',
        value: 'Diabète',
        i18nLabel: t('Diabète'),
        key: 7,
        color: '3E51B5',
        order: 5,
        img: themeSleep2,
        display: 'both',
    },
    6: {
        theme: 'faq',
        value: 'FAQ',
        i18nLabel: t('FAQ'),
        key: 28,
        color: '3E51B5',
        order: 6,
        img: themeSleep2,
        display: 'both',
    },
    7: {
        theme: 'tiredness',
        value: 'Fatigue',
        i18nLabel: t('Fatigue'),
        key: 18,
        color: '3576A1',
        order: 7,
        img: themeTiredness,
        display: 'both',
    },
    8: {
        theme: 'happy-pregnancy',
        value: 'Grossesse',
        i18nLabel: t('Grossesse'),
        key: 17,
        color: '7481CF',
        order: 8,
        img: themeHappyPregnancy,
        display: 'both',
    },
    9: {
        theme: 'mypathology',
        value: 'Ma pathologie',
        i18nLabel: t('Ma pathologie'),
        key: 31,
        color: '7481CF',
        order: 9,
        img: themeHappyPregnancy,
        display: 'both',
    },
    10: {
        theme: 'pituitary',
        value: 'Hypophyse',
        i18nLabel: t('Hypophyse'),
        key: 32,
        color: '7481CF',
        order: 10,
        img: themeHappyPregnancy,
        display: 'both',
    },
    11: {
        theme: 'memory',
        value: 'Mémoire',
        i18nLabel: t('Mémoire'),
        key: 9,
        color: '00BABA',
        order: 11,
        img: themeMemory,
        display: 'both',
    },
    12: {
        theme: 'shape',
        value: 'Moral',
        i18nLabel: t('Moral'),
        key: 5,
        color: 'FEC009',
        order: 12,
        img: themeShape,
        display: 'both',
    },
    13: {
        theme: 'food',
        value: 'Nutrition',
        i18nLabel: t('Nutrition'),
        key: 1,
        color: 'FF6F41',
        order: 13,
        img: themeFood2,
        display: 'both',
    },
    14: {
        theme: 'sleep',
        value: 'Sommeil',
        i18nLabel: t('Sommeil'),
        key: 3,
        color: '3E51B5',
        order: 14,
        img: themeSleep2,
        display: 'both',
    },
    15: {
        theme: 'stress',
        value: 'Stress',
        i18nLabel: t('Stress'),
        key: 4,
        color: 'E81E63',
        order: 15,
        img: themeStress,
        display: 'both',
    },
    16: {
        theme: 'tobacco',
        value: 'Tabac',
        i18nLabel: t('Tabac'),
        key: 6,
        color: 'FF9A00',
        order: 16,
        img: themeTobacco,
        display: 'both',
    },
    17: {
        theme: 'treatment',
        value: 'Traitement',
        i18nLabel: t('Traitement'),
        key: 29,
        color: 'FF9A00',
        order: 17,
        img: themeTobacco,
        display: 'both',
    },
}

export const adminElements = {
    0: {
        label: 'l‘article',
        i18nLabel: t('l‘article'),
        url: 'private.articles',
    },
    1: {
        label: 'la recette',
        i18nLabel: t('la recette'),
        url: 'private.recipes',
    },
    2: { label: 'la carte', i18nLabel: t('la carte'), url: 'private.cards' },
    3: { label: 'le défi', i18nLabel: t('le défi'), url: 'private.challenges' },
    5: { label: 'le coach', i18nLabel: t('le coach'), url: 'private.coachs' },
    6: {
        label: 'le container',
        i18nLabel: t('le container'),
        url: 'private.containers',
    },
    7: {
        label: 'la conversation',
        i18nLabel: t('la conversation'),
        url: 'private.conversations',
    },
    8: {
        label: 'l‘établissement',
        i18nLabel: t('l‘établissement'),
        url: 'private.users.establishments',
    },
    9: {
        label: 'l‘aliment',
        i18nLabel: t('l‘aliment'),
        url: 'private.aliments',
    },
    10: { label: 'le push', i18nLabel: t('le push'), url: 'private.alerts' },
    11: {
        label: 'le soignant',
        i18nLabel: t('le soignant'),
        url: 'private.users.nurses',
    },
    12: { label: 'l‘alerte', i18nLabel: t('l‘alerte'), url: 'private.alerts' },
    15: {
        label: 'le reward',
        i18nLabel: t('le reward'),
        url: 'private.reward.actions',
    },
    16: {
        label: 'l‘objectif',
        i18nLabel: t('l‘objectif'),
        url: 'private.objectives',
    },
    17: {
        label: 'le topic',
        i18nLabel: t('le topic'),
        url: 'private.forum.forumfr',
    },
    18: {
        label: 'le badge',
        i18nLabel: t('le badge'),
        url: 'private.gamif.badges',
    },
    19: { label: 'le tag', i18nLabel: t('le tag'), url: 'private.forum.tag' },
    20: { label: 'le mail', i18nLabel: t('le mail'), url: 'private.emails' },
    21: {
        label: 'l‘action',
        i18nLabel: t('l‘action'),
        url: 'private.gamif.actions',
    },
    22: {
        label: 'le niveau',
        i18nLabel: t('le niveau'),
        url: 'private.gamif.levels',
    },
}

export const CARDS_CREATION = {
    0: {
        label: 'Alfred',
        infos: ['Illustration', '1 par jour'],
        i18nInfos: [t('Illustration'), t('1 par jour')],
        type: 13,
        template: 'alfred_info',
        name: 'alfred',
        addurl: 'private.cards.edit',
    },
    1: {
        label: 'Article',
        infos: ['Sujet de fond', '1 par jour', 'Entre 1000 et 2000 caractères'],
        i18nInfos: [t('Sujet de fond'), t('Entre 1000 et 2000 caractères')],
        type: 18,
        template: 'article',
        name: 'article',
        addurl: 'private.cards.edit',
    },
    2: {
        label: 'Bandeau Image',
        infos: ['Illustration', '1 par jour', '1280x300px'],
        type: 15,
        template: 'bandeau_image',
        name: '',
        addurl: 'private.cards.edit',
    },
    3: {
        label: 'Bonjour',
        infos: [
            'Carte d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        i18nInfos: [
            t('Carte d‘accueil / info'),
            t('Découpé en 4 blocs KPIsable'),
            t('Bonjour, contexte, remontée, salutations'),
        ],
        type: 16,
        template: 'hello',
        name: 'hello',
        addurl: 'private.cards.edit',
    },
    4: {
        label: 'Citation',
        infos: [
            'Phrase de motivation',
            '1 par jour',
            'Nombre de caractères limité',
        ],
        i18nInfos: [
            t('Phrase de motivation'),
            t('Nombre de caractères limité'),
        ],
        type: 20,
        template: 'citation',
        name: 'citation',
        addurl: 'private.cards.edit',
    },
    5: {
        label: 'Conseil',
        infos: [
            'Fortement personnalisé',
            '3 par jour',
            'Entre 400 et 500 caractères',
        ],
        i18nInfos: [
            t('Fortement personnalisé'),
            t('3 par jour'),
            t('Entre 400 et 500 caractères'),
        ],
        type: 2,
        template: 'accompagnement_generique',
        name: 'advice',
        addurl: 'private.cards.edit',
    },
    6: {
        label: 'Coup de pouce',
        infos: [],
        type: 54,
        template: 'coup_de_pouce',
        name: '',
        addurl: 'private.cards.edit',
    },
    7: {
        label: 'Dashboard',
        infos: [
            'Analyse de la donnée quantitative',
            'Accompagne les graphiques',
            'Entre 300 et 400 caractères',
        ],
        i18nInfos: [
            t('Analyse de la donnée quantitative'),
            t('Accompagne les graphiques'),
            t('Entre 300 et 400 caractères'),
        ],
        type: 17,
        template: 'dashboard_info',
        name: 'dashboard',
        addurl: 'private.cards.edit',
    },
    8: {
        label: 'Défi',
        infos: ['Cap ou pas cap', 'Défi simple', 'Nombre de caractères limité'],
        i18nInfos: [
            t('Cap ou pas cap'),
            t('Défi simple'),
            t('Nombre de caractères limité'),
        ],
        type: 14,
        template: 'defis',
        name: 'challenge',
        addurl: 'private.cards.edit',
    },
    9: {
        label: 'Évaluation classique',
        infos: [
            'Question / collecte de données',
            '3 par jour',
            'Plusieurs types possibles',
        ],
        i18nInfos: [
            t('Question / collecte de données'),
            t('Plusieurs types possibles'),
        ],
        type: 1,
        template: 'evaluation_generique',
        name: 'eval classic',
        addurl: 'private.cards.edit',
    },
    10: {
        label: 'Évaluation comportement',
        infos: [
            'Question / collecte de données',
            '4 par jour',
            'Plusieurs types possibles',
        ],
        i18nInfos: [t('4 par jour')],
        type: 22,
        template: 'evaluation_comportement',
        name: 'eval comportement',
        addurl: 'private.cards.edit',
    },
    11: {
        label: 'Évaluation questionnaire',
        infos: ['Question / collecte de données', 'Plusieurs types possibles'],
        type: 58,
        template: 'evaluation_survey',
        name: 'eval questionnaire',
        addurl: 'private.cards.edit',
    },
    12: {
        label: 'Mail Hebdo',
        infos: [
            'Mail d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        i18nInfos: [
            t('Mail d‘accueil / info'),
            t('Découpé en 4 blocs KPIsable'),
            t('Bonjour, contexte, remontée, salutations'),
        ],
        type: 7,
        template: 'mail_hebdo',
        name: 'hebdo',
        addurl: 'private.cards.edit',
    },
    13: {
        label: 'Partenaire',
        infos: ['Illutration', 'Lien cliquable', 'Accompagne le parrainage'],
        i18nInfos: [t('Lien cliquable'), t('Accompagne le parrainage')],
        type: 24,
        template: 'partenaire',
        name: '',
        addurl: 'private.cards.edit',
    },
    14: {
        label: 'Quiz',
        infos: ['Vrai ou faux', '1 par jour', 'Nombre de caractères limité'],
        i18nInfos: [t('Vrai ou faux'), t('Nombre de caractères limité')],
        type: 12,
        template: 'quizz',
        name: 'quizz',
        addurl: 'private.cards.edit',
    },
    15: {
        label: 'Recette',
        infos: ['Semaine et week-end', '1 par jour', 'Variante possible'],
        i18nInfos: [t('Semaine et week-end'), t('Variante possible')],
        type: 19,
        template: 'recipe',
        name: 'recipe',
        addurl: 'private.cards.edit',
    },
    16: {
        label: 'Alerte questionnaire',
        infos: [''],
        type: 59,
        template: 'surveyalert',
        name: 'alert questionnaire',
        addurl: 'private.cards.edit',
    },
    17: {
        label: 'Symptôme',
        infos: ['Rafraichie après chaque fin de questionnaire'],
        i18nInfos: [t('Rafraichie après chaque fin de questionnaire')],
        type: 60,
        template: 'symptom',
        name: 'symptom',
        addurl: 'private.cards.edit',
    },
    18: {
        label: 'Bibliothéque',
        infos: ['Toujours dans le journal'],
        i18nInfos: [t('Toujours dans le journal')],
        type: 61,
        template: 'library',
        name: 'library',
        addurl: 'private.cards.edit',
    },
}

export const CONFIG_PROGRAM_CARDS = {
    1: { label: 'Programme', i18nLabel: t('Programme'), type: 'programs' },
    2: { label: 'Séances', i18nLabel: t('Séances'), type: 'sessions' },
    3: { label: 'Étapes', i18nLabel: t('Étapes'), type: 'steps' },
}

export const CONFIG_DOWNLOADS = {
    0: {
        label: 'de traductions',
        key: 'translations',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Anglais',
            key: 'en',
        },
        defaultId: '',
    },
    1: {
        label: 'de cartes ???',
        key: 'cards',
        availableType: true,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: {
            label: 'Bonjour',
            key: '16',
        },
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
        typeList: CARDS_CREATION,
    },
    2: {
        label: 'd‘articles',
        key: 'articles',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    3: {
        label: 'de recettes',
        key: 'recipes',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    4: {
        label: 'de défis',
        key: 'challenges',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    5: {
        label: 'de programmes',
        key: 'program',
        availableType: false,
        availableLang: true,
        availableId: true,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: {
            label: '',
            key: '',
        },
        typeList: CONFIG_PROGRAM_CARDS,
    },
}

export const PRACTITIONER_PROFESSIONS = {
    0: { key: 'Médecin', value: 'Médecin', i18nValue: t('Médecin') },
    1: { key: 'Pharmacien', value: 'Pharmacien', i18nValue: t('Pharmacien') },
    2: {
        key: 'Audioprothésiste',
        value: 'Audioprothésiste',
        i18nValue: t('Audioprothésiste'),
    },
    3: {
        key: 'Opticien-Lunetier',
        value: 'Opticien-Lunetier',
        i18nValue: t('Opticien-Lunetier'),
    },
    4: {
        key: 'Assistant dentaire',
        value: 'Assistant dentaire',
        i18nValue: t('Assistant dentaire'),
    },
    5: {
        key: 'Physicien médical',
        value: 'Physicien médical',
        i18nValue: t('Physicien médical'),
    },
    6: {
        key: 'Aide-soignant',
        value: 'Aide-soignant',
        i18nValue: t('Aide-soignant'),
    },
    7: {
        key: 'Ambulancier',
        value: 'Ambulancier',
        i18nValue: t('Ambulancier'),
    },
    8: {
        key: 'Auxiliaire de puériculture',
        value: 'Auxiliaire de puériculture',
        i18nValue: t('Auxiliaire de puériculture'),
    },
    9: {
        key: 'Préparateur en pharmacie hospitalière',
        value: 'Préparateur en pharmacie hospitalière',
        i18nValue: t('Préparateur en pharmacie hospitalière'),
    },
    10: {
        key: 'Préparateur en pharmacie (officine)',
        value: 'Préparateur en pharmacie (officine)',
        i18nValue: t('Préparateur en pharmacie (officine)'),
    },
    11: {
        key: 'Chirurgien-Dentiste',
        value: 'Chirurgien-Dentiste',
        i18nValue: t('Chirurgien-Dentiste'),
    },
    12: { key: 'Sage-Femme', value: 'Sage-Femme', i18nValue: t('Sage-Femme') },
    13: { key: 'Infirmier', value: 'Infirmier', i18nValue: t('Infirmier') },
    14: {
        key: 'Infirmier psychiatrique',
        value: 'Infirmier psychiatrique',
        i18nValue: t('Infirmier psychiatrique'),
    },
    15: {
        key: 'Masseur-Kinésithérapeute',
        value: 'Masseur-Kinésithérapeute',
        i18nValue: t('Masseur-Kinésithérapeute'),
    },
    16: {
        key: 'Pédicure-Podologue',
        value: 'Pédicure-Podologue',
        i18nValue: t('Pédicure-Podologue'),
    },
    17: {
        key: 'Orthoprothésiste',
        value: 'Orthoprothésiste',
        i18nValue: t('Orthoprothésiste'),
    },
    18: {
        key: 'Podo-Orthésiste',
        value: 'Podo-Orthésiste',
        i18nValue: t('Podo-Orthésiste'),
    },
    19: {
        key: 'Orthopédiste-Orthésiste',
        value: 'Orthopédiste-Orthésiste',
        i18nValue: t('Orthopédiste-Orthésiste'),
    },
    20: { key: 'Oculariste', value: 'Oculariste', i18nValue: t('Oculariste') },
    21: {
        key: 'Epithésiste',
        value: 'Epithésiste',
        i18nValue: t('Epithésiste'),
    },
    22: {
        key: 'Technicien de laboratoire médical',
        value: 'Technicien de laboratoire médical',
        i18nValue: t('Technicien de laboratoire médical'),
    },
    23: {
        key: 'Orthophoniste',
        value: 'Orthophoniste',
        i18nValue: t('Orthophoniste'),
    },
    24: {
        key: 'Orthoptiste',
        value: 'Orthoptiste',
        i18nValue: t('Orthoptiste'),
    },
    25: {
        key: 'Psychologue',
        value: 'Psychologue',
        i18nValue: t('Psychologue'),
    },
    26: {
        key: 'Ergothérapeute',
        value: 'Ergothérapeute',
        i18nValue: t('Ergothérapeute'),
    },
    27: {
        key: 'Diététicien',
        value: 'Diététicien',
        i18nValue: t('Diététicien'),
    },
    28: {
        key: 'Psychomotricien',
        value: 'Psychomotricien',
        i18nValue: t('Psychomotricien'),
    },
    29: {
        key: 'Manipulateur ERM',
        value: 'Manipulateur ERM',
        i18nValue: t('Manipulateur ERM'),
    },
}

export const PRACTITIONER_QUALIFICATIONS = {
    0: {
        key: 'ONCOHEM',
        value: 'Onco-hématologue',
        i18nValue: t('Onco-hématologue'),
    },
    1: {
        key: 'ONCOMED',
        value: 'Oncologue médical',
        i18nValue: t('Oncologue médical'),
    },
    2: {
        key: 'ONCORAD',
        value: 'Oncologue radiothérapeute',
        i18nValue: t('Oncologue radiothérapeute'),
    },
    3: { key: 'CHIR', value: 'Chirurgien(ne)', i18nValue: t('Chirurgien(ne)') },
    4: {
        key: 'NURSE',
        value: 'Infirmier(ère)',
        i18nValue: t('Infirmier(ère)'),
    },
    5: {
        key: 'APN',
        value: 'Infirmier(ère) de pratique avancée',
        i18nValue: t('Infirmier(ère) de pratique avancée'),
    },
    6: { key: 'HEMATO', value: 'Hématologue', i18nValue: t('Hématologue') },
    7: {
        key: 'RADT',
        value: 'Radiologue interventionel(le)',
        i18nValue: t('Radiologue interventionel(le)'),
    },
    8: {
        key: 'CRA',
        value: 'Attaché(e) de Recherche Clinique',
        i18nValue: t('Attaché(e) de Recherche Clinique'),
    },
    9: {
        key: 'PROM',
        value: 'Promoteur(trice) d’étude clinique',
        i18nValue: t('Promoteur(trice) d’étude clinique'),
    },
    10: {
        key: 'PHARM',
        value: 'Pharmacien(ne)',
        i18nValue: t('Pharmacien(ne)'),
    },
    11: { key: 'PNEUMO', value: 'Pneumologue', i18nValue: t('Pneumologue') },
    12: {
        key: 'GASTROHEPATO',
        value: 'Hépatologue',
        i18nValue: t('Hépatologue'),
    },
    13: {
        key: 'MEDICAL_OTHER',
        value: 'Autre spécialité médicale',
        i18nValue: t('Autre spécialité médicale'),
    },
    14: {
        key: 'NOT_MEDICAL_OTHER',
        value: 'Personnel non médical',
        i18nValue: t('Personnel non médical'),
    },
}

export const PRACTITIONER_CATEGORIES = {
    0: {
        key: 'Professionnel en exercice',
        value: 'Professionnel en exercice',
        i18nValue: t('Professionnel en exercice'),
    },
    1: {
        key: 'Étudiant / Interne',
        value: 'Étudiant / Interne',
        i18nValue: t('Étudiant / Interne'),
    },
}

export const PRACTITIONER_STATUS = {
    0: {
        key: 'UNKNOWN',
        value: 'Information non disponible',
        i18nValue: t('Information non disponible'),
    },
    1: { key: 'VOLUNTEER', value: 'Bénévole', i18nValue: t('Bénévole') },
    2: { key: 'EMPLOYEE', value: 'Salarié', i18nValue: t('Salarié') },
    3: { key: 'LIBERAL', value: 'Libéral', i18nValue: t('Libéral') },
}

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const corpowordsrh_CARD = {
    filter: ['status'],
    tableHead: [
        { key: 'name', label: 'ID' },
        { key: 'title', label: 'titre' },
        { key: 'startDate', label: 'date de début' },
        { key: 'endDate', label: 'date de fin' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/wordsrh/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/wordsrh/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editCard,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteCard,
        },
    ],
    tableAllOptions: {
        label: 'MOTS DU RH SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteCard,
            },
        ],
    },
    header: ['Configurer le mot du rh'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'hello',
        },
        type: {
            field: 'hidden',
            value: '56',
        },
        frequency: {
            field: 'hidden',
            value: '1',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activation',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            display: {
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id du message',
            field: 'text',
            mandatory: true,
            placeholder: "Renseignez ici l'id du message",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: "Veuillez renseigner l'id du message",
                i18nMessage: t("Veuillez renseigner l'id du message"),
            },
        },
        startDate: {
            name: 'startDate',
            type: 'date',
            label: 'Date de début',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            display: {
                mode: 'half',
            },
        },
        endDate: {
            name: 'endDate',
            type: 'date',
            label: 'Date de fin',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            display: {
                mode: 'half',
            },
        },
        flags: {
            field: 'hidden',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            field: 'hidden',
            value: { fr: true },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Message',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de la carte',
                i18nMessage: t(
                    'Veuillez renseigner la description de la carte'
                ),
            },
        },
    },
}

export { corpowordsrh_CARD }

import { dynLoad } from '@app/core'

import * as base from '@app/constants/constants-default.js'
import { t } from '@app/utils'

const customerConfig = { ...base, ...dynLoad('constants', 'constants', '') }

if (window.hasRuntimeEnv) {
    const runtimeEnvConstants = JSON.parse(
        JSON.stringify(window.appRuntimeEnv.constants)
    )

    // remove the default instances and inject the ones from config.json
    customerConfig.INSTANCES = {}

    for (const [index, instance] of runtimeEnvConstants.INSTANCES.entries()) {
        customerConfig.INSTANCES[index + 1] = {
            value: instance.value,
            i18nValue: t(instance.value),
            key: instance.key,
        }
    }

    // only copies the urls constants from the config.json file
    for (const [, api] of Object.entries(customerConfig.APIS)) {
        if (Object.keys(runtimeEnvConstants.APIS).includes(api.value)) {
            api.urls = runtimeEnvConstants.APIS[api.value].urls
        }
    }
}

export { customerConfig as custo }

//export default Object.assign({}, base, dynLoad('constants', 'constants', ''))

import { h, Component } from '@app/utils'

import { Grid, Navbar, Enter } from '@app/elements'
import { allRoutes } from '@app/core'
import * as constants from '@app/constants'

import { TopLogoView } from '@app/layouts/toplogo'

import './index.scss'

const actions = {
    goBack: () => (state) => {
        window.history.back()
    },
}

const view = (state, actions) => (props, children) =>
    (
        <div key={'register'} class={props.page}>
            <Enter
                time={constants.custo.ANIM_DURATION}
                easing={constants.custo.ANIM_EASING}
                css={constants.custo.ANIM_CSS}
            >
                <Grid classes={'btzHeader btzHeader-btzNoMediaQuery'}>
                    <Grid.Row classes={'btzHeaderWrapper'}>
                        <Grid.Col x={10} mx={'auto'}>
                            <Navbar>
                                <Navbar.Section classes="btzHeader-btzHeaderLeftHand">
                                    <TopLogoView
                                        {...props}
                                        redirectTo={allRoutes['index'].pathname}
                                        customer={props.customer || 'betterise'}
                                    ></TopLogoView>
                                </Navbar.Section>
                            </Navbar>
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
                <Grid
                    classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'}
                    style={{
                        'padding-bottom': '130px',
                        'padding-top': '105px',
                    }}
                >
                    <Grid.Row
                        classes={
                            'btzWrapper-verticalAlignContent' +
                            (props.howthatwork
                                ? ' btzWrapper-verticalAlignContent-minHeight'
                                : '')
                        }
                    >
                        <Grid.Col
                            x={12}
                            mx={'auto'}
                            style={{
                                position: 'relative',
                                display: 'table',
                                height: '100%',
                            }}
                        >
                            {children}
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
            </Enter>
        </div>
    )

export default Component({}, actions, view, 'layout')

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const aliment_CARD = {
    filter: ['family', 'status'],
    search: { label: 'ID, Name' },
    tableHead: [
        { key: 'id', label: 'id' },
        { key: 'name', label: 'nom' },
        { key: 'families', label: 'fam. aliments' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/aliments/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/aliments/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editAliment,
        },
        // {'key': 'frequency', 'label': 'Changer la fréquence', 'kind': 'list-api', 'list': Object.keys(constants.custo.FREQUENCY).map(i => constants.custo.FREQUENCY[i]), 'action': Card.editAliment},
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteAliment,
        },
    ],
    tableAllOptions: {
        label: 'ALIMENTS SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteAliment,
            },
        ],
    },
    header: ['Configurer l‘aliment'],
    form: {
        'id': {
            field: 'hidden',
            value: '',
        },
        'template': {
            field: 'hidden',
            value: 'aliment',
        },
        'flags': {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        'langs': {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        'status': {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        'nameid': {
            name: 'id',
            substitute: 'id',
            type: 'string',
            label: 'Id',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici l‘ID de votre aliment',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner l‘ID de votre aliment',
                i18nMessage: t('Veuillez renseigner l‘ID de votre aliment'),
            },
            display: {
                mode: 'half',
            },
        },
        'alias': {
            name: 'alias',
            substitute: '',
            type: 'string',
            label: 'Alias (auto-généré)',
            field: 'readonly',
            placeholder: '',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'families': {
            name: 'families',
            type: 'ciqualfamily',
            label: 'Famille d‘aliments',
            field: 'dropdown-to-div',
            searchbox: false,
            list: 'dynamic',
            model: { key: null, value: null },
            value: '',
            placeholder: 'Tapez votre recherche ici ...',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'atLeastOneSelected',
                message: 'Veuillez sélectionner au moins une famille',
                i18nMessage: t('Veuillez sélectionner au moins une famille'),
            },
            display: {
                border: 'bottom',
            },
        },
        'portion': {
            name: 'portion',
            type: 'int',
            label: 'Portion (g)',
            field: 'int',
            placeholder: '',
            value: '',
        },
        'pregnancyCompatible': {
            name: 'pregnancyCompatible',
            type: 'boolean',
            label: 'Convient aux femmes enceinte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez renseigner si cela convient aux femmes enceinte',
                i18nMessage: t(
                    'Veuillez renseigner si cela convient aux femmes enceinte'
                ),
            },
            display: {
                border: 'bottom',
            },
        },
        'list-composition': {
            name: 'list-composition',
            type: 'listing',
            label: 'Composition',
            field: 'listing',
            sending: false,
            value: Object.keys(constants.custo.FOOD_DATAS).map(
                (i) => constants.custo.FOOD_DATAS[i]
            ),
            display: {
                border: 'bottom',
            },
        },
        'glycemyIndex': {
            name: 'glycemyIndex',
            type: 'int',
            label: 'Index glycémique',
            field: 'int',
            mandatory: true,
            placeholder: '',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner l‘index glycémique',
                i18nMessage: t('Veuillez renseigner l‘index glycémique'),
            },
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'glycemyLoad': {
            name: 'glycemyLoad',
            type: 'float',
            label: 'Charge glycémique',
            field: 'int',
            mandatory: true,
            placeholder: '',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la charge glycémique',
                i18nMessage: t('Veuillez renseigner la charge glycémique'),
            },
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'list-kpis': {
            name: 'list-kpis',
            type: 'listing',
            label: 'KPIS',
            field: 'listing',
            value: 'kpis',
            sending: false,
            display: {
                border: 'bottom',
            },
        },
        'name': {
            name: 'name',
            type: 'translation',
            label: 'Nom usuel',
            field: 'text',
            placeholder: 'Renseignez ici le nom usuel de votre aliment',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom usuel de l‘aliment',
                i18nMessage: t('Veuillez renseigner le nom usuel de l‘aliment'),
            },
        },
        'portionLabel': {
            name: 'portionLabel',
            type: 'translation',
            label: 'Portion (texte)',
            field: 'text',
            placeholder: 'Renseignez ici la portion',
            value: {},
        },
        'media': {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
    },
}

export { aliment_CARD }

import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { Customers, Programs } from '@app/api'
import { isDefined, deepCopy } from '@app/core'
import { Form, Button, Delete, OrderedLinked } from '@app/elements'
import './index.scss'

import icoArrowTopOff from '@app/img/ico/img-arrow-up-grey.png'
import icoArrowTopOffx2 from '@app/img/ico/img-arrow-up-grey@2x.png'
import icoArrowTopOn from '@app/img/ico/img-arrow-up.png'
import icoArrowTopOnx2 from '@app/img/ico/img-arrow-up@2x.png'

import icoArrowBottomOff from '@app/img/ico/img-arrow-down-grey.png'
import icoArrowBottomOffx2 from '@app/img/ico/img-arrow-down-grey@2x.png'
import icoArrowBottomOn from '@app/img/ico/img-arrow-down.png'
import icoArrowBottomOnx2 from '@app/img/ico/img-arrow-down@2x.png'

const AdminFormArrayOfOrderedLinked = Component(
    {
        name: '',
        ulDisplayed: false,
        sendToParent: null,
        list: [],
        model: { key: null, value: null, order: 'order' },
        orderedlinkedmodel: { value: '' },
        originalList: [],
        orderedlinkeds: [],
        selected: null,
        readonly: false,
        search: '',
        infoError: '',
        parentId: null,
        document: null,
        original: null,
        wholeDocument: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.document) {
                if (props.document.name) {
                    state.name = props.document.name
                }
                if (props.document.readonly) {
                    state.readonly = props.document.readonly
                }
                if (props.document.model) {
                    actions.setModel(props.document.model)
                } else {
                    actions.setModel({
                        key: 'id',
                        value: 'name',
                        order: 'order',
                    })
                }
            }
            actions.setOrderedLinked(deepCopy(state.orderedlinkeds))
            //
            if (props.document && props.document.value) {
                console.log(
                    'ORDERINGonComponentUpdate : setting > ',
                    props.document.value
                )
                actions.setOrderedLinked(props.document.value)
            }
            actions.onUpdateOrderedLinked()
            //
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            console.log('ORDERINGonComponentUpdate : ', props)
            if (props.document) {
                actions.setDocument(props.document)
                //
                if (isDefined(props.original)) {
                    actions.setOriginalDocument(props.original)
                }
            }

            if (props.search) {
                actions.selectThisOne({ name: props.search, id: props.search })
                actions.setSearch(props.search)
            }
        },
        updateFieldSelection: (props) => (state, actions) => {
            let target = props.document.value
            if (isDefined(target)) {
                let selected = null
                if (isDefined(target.name) && isDefined(target.id)) {
                    selected = { name: target.name, id: target.id }
                } else if (typeof target === 'string' && target !== '') {
                    selected = { name: target, id: target }
                }
                if (isDefined(selected)) {
                    actions.selectThisOne(selected)
                }
            }
        },
        selectThisOne: (newState) => (state) => ({
            search: '',
            selected: newState,
            ulDisplayed: false,
        }),
        setOriginalList: (newState) => (state) => ({
            originalList: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setOriginalDocument: (newState) => (state) => ({
            original: newState,
        }),
        setWholeDocument: (newState) => (state) => ({
            wholeDocument: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        setSearch: (newState) => (state) => ({
            search: newState,
        }),
        setParentId: (newState) => (state) => ({
            parentId: newState,
        }),
        setInfoError: (newState) => (state) => ({
            infoError: newState,
        }),
        setOrderedLinked: (newState) => (state) => ({
            orderedlinkeds: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        onUpdateOrderedLinked: () => (state, actions) => {
            console.log(
                'DOCUMENT update : state.orderedlinkeds > ',
                state.orderedlinkeds
            )
            state.sendToParent({
                target: {
                    name: state.name,
                    value: state.orderedlinkeds.map((m) => m.id),
                    lang: state.lang,
                },
            })
        },
        addOrderedLinked: () => (state, actions) => {
            let orderedlinkeds = state.orderedlinkeds
            orderedlinkeds.push(
                JSON.parse(JSON.stringify(state.orderedlinkedmodel))
            )
            actions.setOrderedLinked(orderedlinkeds)
        },
        removeThisOne: (idx) => (state, actions) => {
            let orderedlinkeds = JSON.parse(
                JSON.stringify(state.orderedlinkeds)
            )
            orderedlinkeds.splice(idx, 1)
            actions.setOrderedLinked(orderedlinkeds)
            actions.onUpdateOrderedLinked()
        },
        onUpdateOrder:
            ({ index, upOrDown }) =>
            (state, actions) => {
                let orderedlinkeds = state.orderedlinkeds
                let current = state.orderedlinkeds[index]
                if (upOrDown === 'up') {
                    orderedlinkeds[index] = orderedlinkeds[index - 1]
                    orderedlinkeds[index - 1] = current
                } else if (upOrDown === 'down') {
                    orderedlinkeds[index] = orderedlinkeds[index + 1]
                    orderedlinkeds[index + 1] = current
                }
                console.log('orderedlinkeds :', orderedlinkeds)
                actions.setOrderedLinked(orderedlinkeds)
                actions.onUpdateOrderedLinked()
            },
    },
    (state, actions) => (props, children) => (
        <div
            key={'adminformarrayoforderedlinked-' + state.name}
            tabindex="0"
            onblur={() => {
                actions.toggleUl(false)
            }}
        >
            {props.document !== undefined && (
                <dummy>
                    <dummy>
                        {state.orderedlinkeds.map((orderedlinked, idx) => (
                            <dummy
                                class="adminformarrayoforderedlinked-entry-wrapper"
                                style="display: block;"
                            >
                                <OrderedLinked
                                    key={
                                        'orderedlinked-entry-' +
                                        state.name +
                                        '-' +
                                        idx
                                    }
                                    document={orderedlinked}
                                    idx={idx}
                                    parentdocument={state.document}
                                    name={state.name}
                                    model={state.model}
                                    list={state.list}
                                    order={idx}
                                    onclick={actions.onUpdateOrderedLinked}
                                    ondelete={actions.removeThisOne}
                                ></OrderedLinked>
                                <div class="arrows">
                                    <div class="arrow-up">
                                        <img
                                            class="arrow-up-off"
                                            src={icoArrowTopOff}
                                            srcset={`${icoArrowTopOffx2} 2x`}
                                            alt=""
                                        />
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            class="arrow-up-on"
                                            src={icoArrowTopOn}
                                            srcset={`${icoArrowTopOnx2} 2x`}
                                            alt=""
                                            onclick={() => {
                                                actions.onUpdateOrder({
                                                    index: idx,
                                                    upOrDown: 'up',
                                                })
                                            }}
                                        />
                                    </div>
                                    <div class="arrow-down">
                                        <img
                                            class="arrow-down-off"
                                            src={icoArrowBottomOff}
                                            srcset={`${icoArrowBottomOffx2} 2x`}
                                            alt=""
                                        />
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            class="arrow-down-on"
                                            src={icoArrowBottomOn}
                                            srcset={`${icoArrowBottomOnx2} 2x`}
                                            alt=""
                                            onclick={() => {
                                                actions.onUpdateOrder({
                                                    index: idx,
                                                    upOrDown: 'down',
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </dummy>
                        ))}
                    </dummy>
                    <Form.Group classes="btzForm-btzFooter">
                        <Button primary flat onclick={actions.addOrderedLinked}>
                            {t('Ajouter une entrée')}
                        </Button>
                    </Form.Group>
                </dummy>
            )}
        </div>
    ),
    'adminformarrayoforderedlinked'
)

export { AdminFormArrayOfOrderedLinked }

import { h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { User } from '@app/api'
import { allRoutes, addCSRFToken } from '@app/core'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.user_CUSTOMER.form,
    initialized: false,
    tableHead: documents.user_CUSTOMER.tableHead,
    tableOptions: documents.user_CUSTOMER.tableOptions,
    tableAllOptions: documents.user_CUSTOMER.tableAllOptions,
    tableFilter: documents.user_CUSTOMER.filter,
    tableSearch: documents.user_CUSTOMER.search,
    header: documents.user_CUSTOMER.header,
    document: documents.user_CUSTOMER,
    originaldocument: null,
    display: '',
    editId: null,
    customers: [],
    currentUrl: '',
    kind: 'customers',
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Users/actions')

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout page="customers" customer={customer} currentPage={'users'}>
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            search={state.tableSearch}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={User.getCustomers}
                            searchName={'search'}
                        ></Table>
                    ) : (
                        <div
                            class=""
                            key={
                                state.editId +
                                '-' +
                                state.initialized +
                                '-' +
                                Object.keys(state.document).length
                            }
                        >
                            {state.editId !== null ? (
                                <div
                                    class={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                    key={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        originaldocument={
                                            state.originaldocument
                                        }
                                        target={User.editCustomer}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.users.customers']
                                                .pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            ) : (
                                <div key={'adminform-creation'}>
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        target={User.addCustomer}
                                        card={state.document}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.users.customers']
                                                .pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'customers')

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const emailstory_CARD = {
    filter: ['status'],
    tableHead: [
        { key: 'name', label: 'nom' },
        { key: 'title', label: 'titre' },
        { key: 'status', label: 'statut' },
        { key: 'date', label: 'date création' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/emails/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/emails/:id') + '&mode=duplicate',
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteEmailStory,
        },
    ],
    tableAllOptions: {
        label: 'EMAILS SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteEmailStory,
            },
        ],
    },
    header: ['Configurer le mail'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'emailstory',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de l‘email',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de l‘email',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de l‘email',
                i18nMessage: t('Veuillez renseigner le nom de l‘email'),
            },
        },
        seniority: {
            name: 'seniority',
            type: 'int',
            label: 'Jour d‘envoi',
            i18nLabel: t('Jour d‘envoi'),
            field: 'int',
            mandatory: true,
            placeholder: '',
            value: '',
        },
        hour: {
            name: 'hour',
            type: 'int',
            label: 'Heure d‘envoi',
            i18nLabel: t('Heure d‘envoi'),
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.CARDS_EMAIL_SENDING_HOURS,
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        dashboardDatas: {
            name: 'dashboardDatas',
            type: 'int',
            label: 'Recap dashboard',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.CARDS_EMAIL_RECAP_DASHBOARD,
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘email',
            i18nLabel: t('Titre de l‘email'),
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de l‘email',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de l‘email',
                i18nMessage: t('Veuillez renseigner le titre de l‘email'),
            },
        },
        content: {
            name: 'content',
            type: 'translation',
            label: 'Contenu',
            field: 'textarea',
            mandatory: true,
            placeholder: 'Renseignez ici le contenu de l‘email',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le contenu de l‘email',
                i18nMessage: t('Veuillez renseigner le contenu de l‘email'),
            },
        },
    },
}

export { emailstory_CARD }

const withSecurity =
    () =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        /*
    Should do security related stuff,
    as this HOA is included over every
    Hyperapp' apps in our application.
    - could checks tokens on regular basis
    - could compare active route to public/private
    - ...
    */

        const enhancedState = {
            user: {
                isAuth: false,
                profile: {},
            },
            tokens: {
                api: null,
                csrf: null,
                refresh: null,
            },
        }

        const handler = {
            get: (target, key) => {
                localStorage.setItem('session', JSON.stringify(target))

                return target[key]
            },
            set: (target, key, value) => {
                localStorage.setItem('session', JSON.stringify(target))
                if (key in target) {
                    return false
                }
                return (target[key] = value)
            },
            enumerate: (target) => {
                return target.keys()
            },
            has: (target, key) => {
                return key in target
            },
        }

        const enhancedActions = {
            setStateFromSpace:
                ({ ns, key, value }) =>
                (state, actions) => ({
                    [ns]: { ...state[ns], ...{ [key]: value } },
                }),
            setTokenApi: (string) => (state, actions) =>
                actions.setStateFromSpace({
                    ns: 'tokens',
                    key: 'api',
                    value: string,
                }),
        }

        const enhancedApp = nextApp(
            { ...initialState, ...enhancedState },
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )

        return enhancedApp
    }

export { withSecurity }

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const gamif_ACTIONS = {
    filter: [],
    search: { label: 'ID' },
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'description', label: 'description' },
        { key: 'alias', label: 'alias' },
        { key: 'date', label: 'date' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/gamif/actions/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/gamif/actions/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editGamificationPoint,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteGamificationPoint,
        },
    ],
    tableAllOptions: {
        label: 'ACTIONS SÉLECTIONNÉES',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editGamificationPoint,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editGamificationPoint,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteGamificationPoint,
            },
        ],
    },
    header: ['Configurer la gamif', 'Action'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'gamif-actions',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer l‘action',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de l‘action',
            field: 'text',
            placeholder: 'Renseignez ici le nom de l‘action',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de l‘action',
                i18nMessage: t('Veuillez renseigner le nom de l‘action'),
            },
        },
        points: {
            name: 'points',
            type: 'int',
            label: 'Points',
            field: 'int',
            placeholder: 'Renseignez ici les points de votre badge',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner les points du badge',
                i18nMessage: t('Veuillez renseigner les points du badge'),
            },
        },
        notification: {
            name: 'notification',
            type: 'boolean',
            label: 'Notification',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        alias: {
            name: 'alias',
            type: 'int',
            label: 'Alias',
            field: 'dropdown',
            list: constants.custo.BADGES_ACTIONS,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le résumé de votre action (entre 300 et 600 caractères)',
            value: {},
        },
    },
}

export { gamif_ACTIONS }

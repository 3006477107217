import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const alert_CARD = {
    filter: ['typeAlert', 'status'],
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'type', label: 'type' },
        { key: 'description', label: 'description' },
        { key: 'date', label: 'date de création' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/alerts/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: +addCSRFToken('/content/alerts/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editAlert,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteAlert,
        },
    ],
    tableAllOptions: {
        label: 'ALERTES SÉLECTIONNÉES',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editAlert,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editAlert,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteAlert,
            },
        ],
    },
    form: {},
}

export { alert_CARD }

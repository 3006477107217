import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const calendar_CARD = {
    filter: [],
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'Activé', label: 'status' },
        { key: 'Type', label: 'type' },
        { key: 'date', label: 'date création' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/calendars/:id'),
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: addCSRFToken('/content/calendars/:id') + '&mode=duplicate',
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteCalendar,
        },
    ],
    tableAllOptions: {
        label: 'CALENDRIERS SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteCalendar,
            },
        ],
    },
    header: ['Configurer l‘entrée calendrier'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'calendar',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        type: {
            name: 'type',
            type: 'int',
            label: 'Type',
            field: 'dropdown',
            list: constants.custo.CALENDAR_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une catégorie',
                i18nMessage: t('Veuillez sélectionner une catégorie'),
            },
        },
        dayNumber: {
            name: 'dayNumber',
            type: 'int',
            label: 'Numéro de jour',
            field: 'int',
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le numéro du jour',
                i18nMessage: t('Veuillez renseigner le numéro du jour'),
            },
        },
        weekNumber: {
            name: 'weekNumber',
            type: 'int',
            label: 'Numéro de la semaine',
            field: 'int',
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le numéro de la semaine',
                i18nMessage: t('Veuillez renseigner le numéro de la semaine'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de la recette',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
    },
}

export { calendar_CARD }

import { t, h, Component } from '@app/utils'
import {
    Modal,
    AdminFormCheckBoxLinkedModal,
    AdminFormIntInput,
    AdminFormDropDown,
} from '@app/elements'
import { isDefined } from '@app/core'
import * as constants from '@app/constants'
import './index.scss'
import { ViewModalConfirmDeleteWeight } from './ViewModalConfirmDeleteWeight'

import icoTrash from '@app/img/ico/ico-trash.png'
import icoTrashx2 from '@app/img/ico/ico-trash@2x.png'

const AFSUpAndDown = Component(
    {
        document: null,
        name: '',
        readOnly: false,
        conditionsDisplayed: false,
        currentConditionDisplayed: '',
        originalAlerts: null,
        confirmDeleteAlert: false,
        deleteAlert: null,
        index: 0,
        kind: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.id) {
                state.kind = props.id
            }
            if (props.ondelete) {
                state.deleteAlert = props.ondelete
            }
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        onUpdate:
            ({ name, value }) =>
            (state, actions) => {
                if (isDefined(state.document[name])) {
                    state.document[name] = value
                }
                actions.setDocument(state.document)
            },
        onConfirmDeleteDocument: () => (state, actions) => {
            if (state.deleteAlert !== null) {
                state.deleteAlert({ kind: state.kind, index: state.index })
                actions.displayConfirmDeleteAlert(false)
            }
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        displayConfirmDeleteAlert: (newState) => (state) => ({
            confirmDeleteAlert: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key} class={'afsupanddown-wrapper'}>
            <p class="">{t('Augmentation de')}</p>
            <AdminFormIntInput
                key={props.key + '-value1'}
                floating={true}
                oninput={props.updateValues}
                name={'value1'}
                kind={props.id}
                idx={props.name}
                value={state.document.value1}
            ></AdminFormIntInput>
            <p class="">{t('kg en moins de')}</p>
            <AdminFormIntInput
                key={props.key + '-interval'}
                maxValue={7}
                oninput={props.updateValues}
                name={'interval'}
                kind={props.id}
                idx={props.name}
                value={state.document.interval}
            ></AdminFormIntInput>
            <AdminFormDropDown
                data-name={'period'}
                name={'value'}
                document={state.document}
                list={constants.custo.PERIODS}
                onclick={props.updatePeriodValue}
                kind={props.id}
                idx={props.name}
            ></AdminFormDropDown>
            <p
                class="btn btn-link"
                onclick={() =>
                    props.ontoggle({ id: props.id, name: props.name })
                }
            >
                {t(
                    '+ ajouter une condition' +
                        ' (' +
                        (props.document.expressions !== undefined
                            ? props.document.expressions.length
                            : '0') +
                        ')'
                )}
            </p>
            <Modal
                key={props.key + '-delete-weight'}
                class="btzModalCenter"
                modalDisplayed={state.confirmDeleteAlert}
                content={
                    <ViewModalConfirmDeleteWeight
                        document={props.document}
                        header={props.header}
                        onConfirmCancel={() =>
                            actions.displayConfirmDeleteAlert(false)
                        }
                        onConfirmDeleteDocument={
                            actions.onConfirmDeleteDocument
                        }
                    ></ViewModalConfirmDeleteWeight>
                }
            >
                <img
                    src={icoTrash}
                    srcset={`${icoTrashx2} 2x`}
                    alt=""
                    onclick={() => actions.displayConfirmDeleteAlert(true)}
                    style={{
                        'vertical-align': 'middle',
                        'margin-top': '5px',
                        'cursor': 'pointer',
                    }}
                />
            </Modal>
        </div>
    ),
    'afsupanddown'
)

export { AFSUpAndDown }

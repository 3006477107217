import { t, h, Component } from '@app/utils'
import { Card, User, Trackers } from '@app/api'
import {
    Form,
    Link,
    Button,
    CustomSelect,
    AdminFormDropDown,
    MessagePopup,
} from '@app/elements'
import { createQueryMessage, isDefined } from '@app/core'
import { settings } from '@app/core/settings'
import './index.scss'

import * as constants from '@app/constants'
import * as definitions from '@app/definitions'

const { APP_API_URL } = settings

const Table = Component(
    {
        search: null,
        filter: [],
        head: [],
        data: [],
        options: [],
        allOptions: { label: null, list: [] },
        alimentsFamily: [],
        trackersList: [],
        sorts: {},
        filters: {},
        target: '',
        page: 0,
        type: '',
        pagination: 25,
        optDisplayed: false,
        optIdEntry: null,
        searching: false,
        searchValue: '',
        customerValue: '',
        updateUrl: '',
        searchName: 'name',
        confirmAction: false,
        confirmActionKin: '',
        checkedIdInputs: [],
        messageKind: '',
        messageContent: '',
        messageActive: false,
        forbiddenDeletion: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.head) {
                state.head = props.head
            }
            if (props.filter) {
                state.filter = props.filter
            }
            if (props.forbiddenDeletion) {
                state.forbiddenDeletion = props.forbiddenDeletion
            }
            if (props.options) {
                state.options = props.options
            }
            if (props.allOptions) {
                state.allOptions = props.allOptions
            }
            if (props.target) {
                state.target = props.target
                // actions.setSearchValue('');
                // actions.setSearching(false);
                // actions.getDatas();
                actions.resetSearch(null)
            }
            if (props.updateUrl) {
                state.updateUrl = props.updateUrl
            }
            if (props.pagination) {
                state.pagination = props.pagination
            }
            if (props.type) {
                state.type = props.type
            }
            if (props.searchName) {
                actions.setSearchName(props.searchName)
            }

            if (props.filter.indexOf('family') > -1) {
                Card.getCiqualFamily().then((res) => {
                    let ciqual = []
                    for (var fam in res.data) {
                        ciqual.push({ key: fam, value: fam })
                    }
                    actions.setAlimentsFamily(ciqual)
                })
            }

            if (props.filter.indexOf('oc') > -1) {
                Trackers.getTrackersList().then((res) => {
                    let trackers = []
                    for (var band in res.data) {
                        for (var trk in res.data[band]) {
                            trackers.push({
                                key: band,
                                value: res.data[band][trk],
                            })
                        }
                    }
                    actions.setTrackersList(trackers)
                })
            }
        },
        getDatas: () => (state, actions) => {
            state
                .target(
                    state.searching ? 0 : state.page,
                    state.sorts,
                    state.filters
                )
                .then((res) => {
                    actions.setSearching(false)
                    actions.setDatas(res.data)
                })
        },
        setDatas: (newState) => (state) => ({
            data: newState,
        }),
        updateTable: (page) => (state, actions) => {
            actions.setPage(page)
            if (typeof state.updateUrl === 'function') {
                state.updateUrl({ page: page })
            }
            actions.getDatas()
        },
        updateSorts: (sorts) => (state, actions) => {
            let existingSorts = {}
            existingSorts[sorts] = state.sorts[sorts] === -1 ? 1 : -1
            actions.setSorts(existingSorts)
            actions.getDatas()
        },
        setSorts: (newState) => (state) => ({
            sorts: newState,
        }),
        setAlimentsFamily: (newState) => (state) => ({
            alimentsFamily: newState,
        }),
        setTrackersList: (newState) => (state) => ({
            trackersList: newState,
        }),
        updateFilters: (filters) => (state, actions) => {
            let existingFilters = state.filters
            if (filters === null) {
                existingFilters = []
            } else {
                if (filters.name === 'trackers') {
                    existingFilters[filters.name] = {
                        brand: filters.key,
                        name: filters.value,
                    }
                } else {
                    existingFilters[filters.name] = filters.key
                }
            }
            actions.setFilters(existingFilters)
            actions.getDatas()
        },
        updateFiltersLicence: (licenceType) => (state, actions) => {
            if (licenceType === null) {
                actions.getDatas()
            } else {
                let licenceFilter = { license: licenceType.key }
                User.getUsersByLicense(
                    state.searching ? 0 : state.page,
                    state.sorts,
                    licenceFilter
                ).then((res) => {
                    actions.setSearching(false)
                    actions.setDatas(res.data)
                })
            }
        },
        updateFiltersCustomer: (customer) => (state, actions) => {
            let existingFilters = state.filters
            if (
                customer !== null &&
                customer.target !== null &&
                customer.target.value !== null
            ) {
                existingFilters['customer'] = customer.target.value.id
                actions.setCustomerValue(customer.target.value.name)
            }
            // if (customer === null) {
            //     existingFilters = [];
            // } else {
            //     existingFilters['customer'] = customer.target.getAttribute('data-value');
            // }
            // actions.setCustomerValue(customer.target.getAttribute('data-readable'));
            actions.setFilters(existingFilters)
            actions.getDatas()
        },
        setFilters: (newState) => (state) => ({
            filters: newState,
        }),
        setPage: (newState) => (state) => ({
            page: newState,
        }),
        setCustomerValue: (newState) => (state) => ({
            customerValue: newState,
        }),
        toggleOptions: (identry) => (state, actions) => {
            actions.setOptionIdKey(identry)
            if (identry === state.optIdEntry || !state.optDisplayed) {
                actions.displayOptions(
                    state.optDisplayed === true ? false : true
                )
            }
            actions.setConfirmAction(false)
        },
        displayOptions: (newState) => (state) => ({
            optDisplayed: newState,
            confirmAction: false,
        }),
        setOptionIdKey: (newState) => (state) => ({
            optIdEntry: newState,
        }),
        setSearchName: (newState) => (state) => ({
            searchName: newState,
        }),
        parseDataIfNeeded:
            ({ el, dat, key, raw_data }) =>
            (state, actions) => {
                if (key === 'frequency') {
                    dat = actions.getReadableFrequency(dat)
                } else if (
                    isDefined(dat) &&
                    (key.indexOf('date') > -1 ||
                        ['expiration', 'connexion'].indexOf(key) > -1 ||
                        ['startDate', 'endDate'].indexOf(key) > -1)
                ) {
                    dat = actions.getReadableDate(dat.date)
                } else if (key === 'type') {
                    dat = actions.getReadableType(dat)
                } else if (key === 'status') {
                    dat = actions.getReadableStatus(dat)
                } else if (key === 'shown') {
                    dat = actions.getReadableShown(dat)
                } else if (key === 'mainThemes') {
                    dat = actions.getReadableThemes(dat)
                } else if (key === 'theme') {
                    dat = actions.getReadableTheme(dat)
                } else if (key === 'customer') {
                    dat = actions.getReadableCustomer(dat)
                } else if (key === 'duration') {
                    dat = actions.getReadableDuration(dat)
                } else if (key.indexOf('user.') > -1) {
                    let ukey = key.split('.')[1]
                    dat = actions.getReadableUserInformation({
                        user: dat,
                        ukey: ukey,
                    })
                } else if (key === 'content') {
                    dat = actions.getReadableContent(dat)
                } else if (key === 'corpoLinkSurvey') {
                    dat = actions.getReadableCorpoSurveyLink(raw_data)
                } else if (key === 'corpoChallengeType') {
                    dat = actions.getReadableCorpoChallengeType(raw_data.type)
                } else if (key === 'corpoChallengeWinner') {
                    dat = actions.getReadableCorpoChallengeWinner(raw_data)
                } else if (key.indexOf('[-]') > -1) {
                    let keys = key.split('[-]')
                    dat = +raw_data[keys[0]] - +raw_data[keys[1]]
                }

                el.innerHTML =
                    isDefined(dat) && dat.fr !== undefined
                        ? dat.fr !== null
                            ? dat.fr
                            : ''
                        : dat
            },
        getReadableDate: (ymd) => (state, actions) => {
            if (ymd.length > 8) {
                ymd = ymd.split(' ')[0].split('-').join('')
            }
            // var date = new Date(Date.parse(new Date(ymd.substr(0, 4) + '-' + ymd.substr(4, 2) + '-' + ymd.substr(6, 2))));
            // var options = { weekday: 'long', month: 'long', day: 'numeric' };
            // var readableDate = date.toLocaleDateString('fr-FR', options);
            let readableDate =
                ymd.substr(6, 2) +
                '/' +
                ymd.substr(4, 2) +
                '/' +
                ymd.substr(0, 4)
            return readableDate
        },
        getReadableFrequency: (dat) => (state, actions) => {
            let frequencies = Object.keys(constants.custo.FREQUENCY).map(
                (i) => constants.custo.FREQUENCY[i]
            )
            let frequency = frequencies.filter((freq) => dat == freq.key)[0]
            return frequency !== undefined
                ? frequency.num !== ''
                    ? frequency.num + t(frequency.value)
                    : t(frequency.value)
                : dat
        },
        getReadableType: (dat) => (state, actions) => {
            let cards = Object.keys(definitions.custo.CARDS).map(
                (i) => definitions.custo.CARDS[i]
            )
            if (state.type === 'alert') {
                cards = Object.keys(definitions.custo.ALERTS).map(
                    (i) => definitions.custo.ALERTS[i]
                )
            }
            let card = cards.filter((card) => dat == card.key)[0]
            return card !== undefined ? card.value : dat
        },
        getReadableStatus: (dat) => (state, actions) => {
            if (dat === 1) {
                return "<font class='statut-1'>" + t('Activée') + '</font>'
            } else if (dat === 0 || dat === null) {
                return "<font class='statut-0'>" + t('Désactivée') + '</font>'
            } else if (dat === 2) {
                return "<font class='statut-2'>" + t('À vérifier') + '</font>'
            }
            return dat
        },
        getReadableShown: (dat) => (state, actions) => {
            if (dat === true || dat === 1) {
                return "<font class='statut-1'>" + t('Visible') + '</font>'
            } else if (dat === false || dat === 0) {
                return "<font class='statut-0'>" + t('Invisible') + '</font>'
            } else {
                return "<font class='statut-0'>" + t('Bloqué') + '</font>'
            }
        },
        getReadableThemes: (themes) => (state, actions) => {
            if (!isDefined(themes)) {
                return ''
            }
            let cards = Object.keys(constants.custo.CARDS_TYPES).map(
                (i) => constants.custo.CARDS_TYPES[i]
            )
            let card = cards.filter(
                (card) => themes.map((m) => +m).indexOf(+card.key) > -1
            )
            card = card.map((f) => f.value)
            return card.join(', ')
        },
        getReadableTheme: (theme) => (state, actions) => {
            let cards = Object.keys(constants.custo.CARDS_TYPES).map(
                (i) => constants.custo.CARDS_TYPES[i]
            )
            let card = cards.filter((card) => theme === card.key)
            card = card.map((f) => f.value)
            return card.join(', ')
        },
        getReadableCustomer: (customer) => (state, actions) => {
            if (isDefined(customer)) {
                if (customer.name) {
                    return customer.name
                }
            }
            return ''
        },
        getReadableDuration: (duration) => (state, actions) => {
            let cards = Object.keys(constants.custo.CARDS_VALIDITY).map(
                (i) => constants.custo.CARDS_VALIDITY[i]
            )
            let card = cards.filter((card) => duration == card.key)[0]
            return isDefined(card)
                ? isDefined(card.num)
                    ? card.num + card.value
                    : card.value
                : duration
        },
        getReadableUserInformation:
            ({ user, ukey }) =>
            (state, actions) => {
                if (user !== null && user[ukey] !== undefined) {
                    return user[ukey]
                }
                return '-'
            },
        getReadableContent: (content) => (state, actions) => {
            if (content.length > 200) {
                return content.substring(0, 200)
            }
            return content
        },
        getReadableCorpoSurveyLink: (raw_data) => (state, actions) => {
            if (raw_data.status > 0) {
                var link =
                    APP_API_URL +
                    '/corporate-survey/' +
                    raw_data.id +
                    '/'
                return (
                    "<a target='_blank' style='color:#3297CB;' href='" +
                    link +
                    "'>" +
                    raw_data.id +
                    '</a>'
                )
            }
            return ''
        },
        getReadableCorpoChallengeType: (currentType) => (state, actions) => {
            let types = Object.keys(
                constants.custo.CHALLENGE_TOURNAMENT_TYPES
            ).map((i) => constants.custo.CHALLENGE_TOURNAMENT_TYPES[i])
            let type = types.filter((type) => currentType === type.key)
            type = type.map((f) => f.value)
            return type.join(', ')
        },
        getReadableCorpoChallengeWinner: (raw_data) => (state, actions) => {
            if (raw_data.status > 0) {
                if (raw_data.winner !== '') {
                    for (var iTe = raw_data.teams.length - 1; iTe >= 0; iTe--) {
                        if (raw_data.teams[iTe].id === raw_data.winner) {
                            return raw_data.teams[iTe].label
                        }
                    }
                }
                let currentDate = new Date(Date.now())
                let startDate = null
                let endDatePlusOneDay = null
                if (raw_data.startDate && raw_data.endDate) {
                    startDate = new Date(raw_data.startDate.date)
                    endDatePlusOneDay = new Date(raw_data.endDate.date)
                    endDatePlusOneDay = new Date(
                        endDatePlusOneDay.setDate(
                            endDatePlusOneDay.getDate() + 1
                        )
                    )
                }
                if (
                    startDate &&
                    currentDate.getTime() >= startDate.getTime() &&
                    currentDate.getTime() <= endDatePlusOneDay.getTime()
                ) {
                    return t('en cours')
                } else if (
                    startDate &&
                    currentDate.getTime() < startDate.getTime()
                ) {
                    return t('à venir')
                }
            }
            return '/'
        },
        goToEdit: (id) => (state, actions) => {
            if (
                state.options.filter((opt) => opt.key === 'edit')[0] ===
                undefined
            ) {
                return false
            }
            window.location.href = state.options
                .filter((opt) => opt.key === 'edit')[0]
                .action.replace(':id', id)
        },
        updateSearch:
            ({ target }) =>
            (state, actions) => {
                actions.setSearchValue(target.value)
            },
        launchSearch: () => (state, actions) => {
            if (state.searchValue === '') {
                actions.setFilters([])
                actions.setSorts({})
            }
            actions.setPage(0)
            actions.updateFilters({
                name: state.searchName,
                key: state.searchValue,
            })
            actions.setSearching(true)
        },
        submitSearch: (ev) => (state, actions) => {
            var keycode = ev.which || ev.keyCode
            if (+keycode === 13) {
                actions.launchSearch()
            }
        },
        resetSearch: (ev) => (state, actions) => {
            if (ev !== null) {
                var keycode = ev.which || ev.keyCode
                if (
                    (keycode === 1 && ev.x === 0 && ev.y === 0) ||
                    (isDefined(ev.webkitForce) && ev.webkitForce === 0)
                ) {
                    return false
                }
            }
            state.customerValue = ''
            actions.setFilters([])
            actions.setSorts({})
            actions.setPage(0)
            actions.setSearchValue('')
            actions.setSearching(true)
            actions.updateFilters(null)
        },
        setSearching: (newState) => (state) => ({
            searching: newState,
        }),
        setSearchValue: (newState) => (state) => ({
            searchValue: newState,
        }),
        setConfirmAction: (newState) => (state) => ({
            confirmAction: newState,
        }),
        setConfirmActionKind: (newState) => (state) => ({
            confirmActionKind: newState,
        }),
        setCheckedIdInputs: (newState) => (state) => ({
            checkedIdInputs: newState,
        }),
        onCheckedInput:
            ({ id, checked }) =>
            (state, actions) => {
                let hasInput = false
                state.checkedIdInputs.forEach((idInput) => {
                    if (idInput == id) {
                        hasInput = true
                    }
                })
                let checkedIdInputs = JSON.parse(
                    JSON.stringify(state.checkedIdInputs)
                )
                if (checked && !hasInput) {
                    checkedIdInputs.push(id)
                }
                if (!checked && hasInput) {
                    checkedIdInputs = []
                    state.checkedIdInputs.forEach((idInput) => {
                        if (idInput !== id) {
                            checkedIdInputs.push(idInput)
                        }
                    })
                }
                actions.setCheckedIdInputs(checkedIdInputs)
                if (!checked) {
                    let checkboxAllInputs = document.querySelector(
                        'label.checkboxAllRows input'
                    )
                    checkboxAllInputs.checked = false
                } else {
                    if (checkedIdInputs.length == state.data.length) {
                        let checkboxAllInputs = document.querySelector(
                            'label.checkboxAllRows input'
                        )
                        checkboxAllInputs.checked = true
                    }
                }
            },
        onCheckedAllInputs: (checked) => (state, actions) => {
            let checkedIdInputs = []
            if (checked) {
                state.data.forEach((dat) => {
                    checkedIdInputs.push(dat.id)
                })
            }
            actions.setCheckedIdInputs(checkedIdInputs)

            var checkboxInputs = document.querySelectorAll(
                'label.checkboxRow input'
            )
            if (checkboxInputs !== null) {
                checkboxInputs.forEach((input) => {
                    input.checked = checked
                })
            }
        },
        onAllOptionsConfirm:
            ({ action }) =>
            (state, actions) => {
                if (!state.confirmAction) {
                    actions.setConfirmAction(true)
                } else {
                    actions.setConfirmAction(false)
                    actions.displayOptions(false)
                    //concurrent actions
                    var payloads = state.checkedIdInputs.map((id) => {
                        return action(id)
                            .then(() => {
                                let result = {}
                                result['err'] = {}
                                result['id'] = id
                                return new Promise(function (resolve) {
                                    resolve(result)
                                    return
                                })
                            })
                            .catch((err) => {
                                let result = {}
                                result['err'] = err
                                result['id'] = id
                                return new Promise(function (resolve) {
                                    resolve(result)
                                    return
                                })
                            })
                    })
                    Promise.all(payloads).then(() => {
                        //todo filtering error on result array
                        let queryMessage = createQueryMessage(
                            'success',
                            'mass-treatment',
                            'delete'
                        )
                        actions.setQueryMessage({
                            newStateKind: queryMessage.type,
                            newStateContent: queryMessage.message,
                        })
                        actions.displaySuccessMessage(true)
                        actions.getDatas()
                    })
                }
            },
        onAllOptionsApi:
            ({ key, action }) =>
            (state, actions) => {
                let data = {}

                if (key === 'status_activate') {
                    data['status'] = 1
                }
                if (key === 'status_deactivate') {
                    data['status'] = 0
                }
                if (
                    Object.keys(data).length > 0 ||
                    ['resetpasswordUser', 'cleartimelineUser'].indexOf(key) > -1
                ) {
                    //concurrent actions
                    var payloads = state.checkedIdInputs.map((id) => {
                        return action(id, data)
                            .then(() => {
                                let result = {}
                                result['err'] = {}
                                result['id'] = id
                                return new Promise(function (resolve) {
                                    resolve(result)
                                    return
                                })
                            })
                            .catch((err) => {
                                let result = {}
                                result['err'] = err
                                result['id'] = id
                                return new Promise(function (resolve) {
                                    resolve(result)
                                    return
                                })
                            })
                    })
                    Promise.all(payloads).then(() => {
                        //todo filtering error on result array
                        // state.data.filter((d) => {
                        //     if (d.id ===  dat.id) {
                        //         dat[opt.key] = data[opt.key];
                        //     }
                        // });
                        // actions.setDatas(state.data);
                        let queryMessage = createQueryMessage(
                            'success',
                            'mass-treatment',
                            'update'
                        )
                        actions.setQueryMessage({
                            newStateKind: queryMessage.type,
                            newStateContent: queryMessage.message,
                        })
                        actions.displaySuccessMessage(true)
                        actions.getDatas() //on first step
                        actions.displayOptions(false)
                    })
                }
            },
        onAllOptionsListApi:
            ({ key, action, target }) =>
            (state, actions) => {
                let el = target
                let data = {}
                if (key === 'frequency') {
                    data[key] = el.getAttribute('data-value')
                }
                //concurrent actions
                var payloads = state.checkedIdInputs.map((id) => {
                    return action(id, data)
                        .then(() => {
                            let result = {}
                            result['err'] = {}
                            result['id'] = id
                            return new Promise(function (resolve) {
                                resolve(result)
                                return
                            })
                        })
                        .catch((err) => {
                            let result = {}
                            result['err'] = err
                            result['id'] = id
                            return new Promise(function (resolve) {
                                resolve(result)
                                return
                            })
                        })
                })
                Promise.all(payloads).then(() => {
                    //todo filtering error on result array
                    // state.data.filter((d) => {
                    //     if (d.id ===  dat.id) {
                    //         dat[o.key] = data[o.key];
                    //     }
                    // });
                    // actions.displayOptions(false);
                    // actions.setDatas(state.data);
                    let queryMessage = createQueryMessage(
                        'success',
                        'mass-treatment',
                        'update'
                    )
                    actions.setQueryMessage({
                        newStateKind: queryMessage.type,
                        newStateContent: queryMessage.message,
                    })
                    actions.displaySuccessMessage(true)
                    actions.getDatas() //on first step
                    actions.displayOptions(false)
                })
            },
        setQueryMessage:
            ({ newStateKind, newStateContent }) =>
            (state) => ({
                messageKind: newStateKind,
                messageContent: newStateContent,
            }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
    },
    (state, actions) => (props, children) =>
        (
            <div class="btzTable">
                <div class="customtable-filters sup-top-wrapper">
                    <div class="sup-top-wrapped-filter">
                        {state.messageActive && (
                            <MessagePopup
                                kind={state.messageKind}
                                active={'active'}
                                duration={3000}
                                durationCallback={() =>
                                    actions.displaySuccessMessage(false)
                                }
                                message={state.messageContent}
                            ></MessagePopup>
                        )}

                        {state.filter.length > 0 && (
                            <p
                                class=""
                                style={{
                                    'display': 'inline-block',
                                    'vertical-align': 'top',
                                    'margin': '0 15px 0 0',
                                    'color': '#8A8A8A',
                                    'font-size': '14px',
                                }}
                            >
                                {t('Filtrer ')}
                            </p>
                        )}
                        <div
                            style={{
                                'display': 'inline-block',
                                'vertical-align': 'middle',
                                'width': '90%',
                            }}
                        >
                            {state.filter.indexOf('mobileDevices') > -1 && (
                                <CustomSelect
                                    key={'mobileDevices'}
                                    selecteds={state.filters}
                                    list={state.mobileDevices}
                                    action={actions.updateFilters}
                                    name="mobileDevices"
                                    label={t('app utilisée')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('type') > -1 && (
                                <CustomSelect
                                    key={'type'}
                                    selecteds={state.filters}
                                    list={definitions.custo.CARDS}
                                    action={actions.updateFilters}
                                    name="type"
                                    label={t('type')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('typeAlert') > -1 && (
                                <CustomSelect
                                    key={'typeAlert'}
                                    selecteds={state.filters}
                                    list={constants.custo.ALERTS_TYPES}
                                    action={actions.updateFilters}
                                    name="type"
                                    label={t('type')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('theme') > -1 && (
                                <CustomSelect
                                    key={'theme'}
                                    selecteds={state.filters}
                                    list={constants.custo.CARDS_TYPES}
                                    action={actions.updateFilters}
                                    name="theme"
                                    label={t('thème')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('status') > -1 && (
                                <CustomSelect
                                    key={'status'}
                                    selecteds={state.filters}
                                    list={constants.custo.CARDS_STATUT}
                                    action={actions.updateFilters}
                                    name="status"
                                    label={t('statut')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('frequency') > -1 && (
                                <CustomSelect
                                    key={'frequency'}
                                    selecteds={state.filters}
                                    list={constants.custo.FREQUENCY}
                                    action={actions.updateFilters}
                                    name="frequency"
                                    label={t('fréquence')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('duration') > -1 && (
                                <CustomSelect
                                    key={'duration'}
                                    selecteds={state.filters}
                                    list={constants.custo.CARDS_VALIDITY}
                                    action={actions.updateFilters}
                                    name="duration"
                                    label={t('durée')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('family') > -1 && (
                                <CustomSelect
                                    key={'family'}
                                    selecteds={state.filters}
                                    list={state.alimentsFamily}
                                    action={actions.updateFilters}
                                    name="family"
                                    label={t('famille')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('mainThemes') > -1 && (
                                <CustomSelect
                                    key={'mainThemes'}
                                    selecteds={state.filters}
                                    list={constants.custo.CARDS_TYPES}
                                    action={actions.updateFilters}
                                    name="theme"
                                    label={t('thème principal')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('licence') > -1 && (
                                <CustomSelect
                                    key={'licence'}
                                    selecteds={state.filters}
                                    list={constants.custo.LICENCES_KIND}
                                    action={actions.updateFiltersLicence}
                                    name="license"
                                    label={t('licence')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('abuses') > -1 && (
                                <CustomSelect
                                    key={'abuses'}
                                    selecteds={state.filters}
                                    list={constants.custo.TOPIC_ABUSES_TYPE}
                                    action={actions.updateFilters}
                                    name="abuses"
                                    label={t('signalement')}
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('customer') > -1 && (
                                <div
                                    class="customselect"
                                    style={{ display: 'inline-block' }}
                                >
                                    <p
                                        class="sup-grey-italic-title"
                                        style={{
                                            margin: '0 0 10px 0',
                                            color: '#8A8A8A',
                                        }}
                                    >
                                        <span>{t('par entreprise')}</span>
                                    </p>
                                    <AdminFormDropDown
                                        name={'customer'}
                                        document={{
                                            type: 'customer',
                                            name: 'customer',
                                            list: 'dynamic',
                                            value: '',
                                        }}
                                        search={state.customerValue}
                                        list={'dynamic'}
                                        onclick={actions.updateFiltersCustomer}
                                    ></AdminFormDropDown>
                                </div>
                            )}
                            {state.filter.indexOf('oc') > -1 && (
                                <CustomSelect
                                    key={'oc'}
                                    selecteds={state.filters}
                                    list={state.trackersList}
                                    action={actions.updateFilters}
                                    name="trackers"
                                    label="oc"
                                ></CustomSelect>
                            )}
                            {state.filter.indexOf('registerStep') > -1 && (
                                <CustomSelect
                                    key={'registerStep'}
                                    selecteds={state.filters}
                                    list={constants.custo.REGISTER_STEPS}
                                    action={actions.updateFilters}
                                    name="stepRegistration"
                                    label={t('étape d‘inscription')}
                                ></CustomSelect>
                            )}
                        </div>
                    </div>

                    <Form
                        classes={'sup-search-wrapper'}
                        onsubmit={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            return false
                        }}
                    >
                        <Form.Group>
                            <Form.Input
                                placeholder={
                                    state.search !== null &&
                                    state.search !== undefined &&
                                    state.search.label !== undefined
                                        ? state.search.label
                                        : t('ID, KPI')
                                }
                                type="text"
                                id="search"
                                name="search"
                                value={state.searchValue}
                                oninput={actions.updateSearch}
                                onkeypress={actions.submitSearch}
                            />
                        </Form.Group>

                        <Form.Group classes="btzForm-btzFooter">
                            <Button
                                primary
                                flat
                                loading={state.searching}
                                dangerous
                                onclick={actions.resetSearch}
                                style={{ 'margin-bottom': '10px' }}
                            >
                                {t('Réinitialiser')}
                            </Button>
                            <Button
                                primary
                                flat
                                loading={state.searching}
                                onclick={actions.launchSearch}
                            >
                                {t('Rechercher')}
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
                <table>
                    {state.data !== undefined &&
                        state.data !== false &&
                        Array.isArray(state.data) && (
                            <tbody>
                                <tr class="customtable-head">
                                    <td key={'empty-check'} class=""></td>
                                    {state.head.map((head) => (
                                        <td
                                            key={head.key}
                                            class={
                                                'sup-table-arrow ' +
                                                head.key +
                                                ' ' +
                                                (state.sorts[head.key] === -1
                                                    ? 'tothetop'
                                                    : 'tothebottom')
                                            }
                                        >
                                            {t(head.label)}
                                            <span
                                                onclick={() =>
                                                    actions.updateSorts(
                                                        head.search !==
                                                            undefined
                                                            ? head.search
                                                            : head.key
                                                    )
                                                }
                                            ></span>
                                        </td>
                                    ))}
                                    <td key={'empty-options'} class=""></td>
                                </tr>
                                {state.data.map((dat) => (
                                    <tr
                                        class="customtable-data"
                                        key={dat.id}
                                        data-redtr={
                                            dat.totalAbuses > 0 ? true : false
                                        }
                                    >
                                        <td key={'empty-check'} class="">
                                            <label class="control checkbox checkboxRow">
                                                <input
                                                    type="checkbox"
                                                    onclick={(event) =>
                                                        actions.onCheckedInput({
                                                            id: dat.id,
                                                            checked:
                                                                event.target
                                                                    .checked,
                                                        })
                                                    }
                                                />
                                                <span class="control-indicator"></span>
                                            </label>
                                        </td>
                                        {state.head.map((head) => (
                                            <td
                                                ondblclick={() => {
                                                    actions.goToEdit(dat.id)
                                                }}
                                                class={head.key}
                                                key={
                                                    head.key +
                                                    '-' +
                                                    dat[head.key]
                                                }
                                                oncreate={(el) => {
                                                    actions.parseDataIfNeeded({
                                                        el: el,
                                                        dat:
                                                            head.key.indexOf(
                                                                '.'
                                                            ) > -1
                                                                ? dat[
                                                                      head.key.split(
                                                                          '.'
                                                                      )[0]
                                                                  ]
                                                                : dat[head.key],
                                                        key: head.key,
                                                        raw_data: dat,
                                                    })
                                                }}
                                            ></td>
                                        ))}
                                        <td
                                            key={'empty-options'}
                                            class=""
                                            style={{
                                                position: 'relative',
                                                overflow: 'visible',
                                            }}
                                            tabindex="0"
                                            onblur={(e) => {
                                                isDefined(e.relatedTarget)
                                                    ? ''
                                                    : actions.displayOptions(
                                                          false
                                                      )
                                            }}
                                        >
                                            <div
                                                class="sup-drop-down"
                                                onclick={() =>
                                                    actions.toggleOptions(
                                                        dat.id
                                                    )
                                                }
                                            ></div>
                                            {state.optDisplayed &&
                                                state.optIdEntry === dat.id && (
                                                    <div class="sup-drop-down-options">
                                                        {state.options
                                                            .filter(
                                                                (opt) =>
                                                                    opt.kind ===
                                                                    'web'
                                                            )
                                                            .map((opt) => (
                                                                <Link
                                                                    class="sup-drop-down-option"
                                                                    to={opt.action.replace(
                                                                        ':id',
                                                                        dat.id
                                                                    )}
                                                                >
                                                                    {t(
                                                                        opt.label
                                                                    )}
                                                                </Link>
                                                            ))}
                                                        {state.options
                                                            .filter(
                                                                (opt) =>
                                                                    [
                                                                        'api',
                                                                        'list-api',
                                                                    ].indexOf(
                                                                        opt.kind
                                                                    ) > -1
                                                            )
                                                            .map((opt) => (
                                                                <div
                                                                    class={
                                                                        'sup-drop-down-option ' +
                                                                        (opt.kind ===
                                                                        'list-api'
                                                                            ? 'sup-dd-submenu'
                                                                            : '')
                                                                    }
                                                                    onclick={() => {
                                                                        let data =
                                                                            {}
                                                                        if (
                                                                            [
                                                                                'status',
                                                                                'shown',
                                                                            ].indexOf(
                                                                                opt.key
                                                                            ) >
                                                                            -1
                                                                        ) {
                                                                            data[
                                                                                opt.key
                                                                            ] =
                                                                                dat[
                                                                                    opt
                                                                                        .key
                                                                                ] ===
                                                                                    0 ||
                                                                                dat[
                                                                                    opt
                                                                                        .key
                                                                                ] ===
                                                                                    false
                                                                                    ? 1
                                                                                    : 0
                                                                        }
                                                                        if (
                                                                            Object.keys(
                                                                                data
                                                                            )
                                                                                .length >
                                                                                0 ||
                                                                            [
                                                                                'resetpasswordUser',
                                                                                'cleartimelineUser',
                                                                            ].indexOf(
                                                                                opt.key
                                                                            ) >
                                                                                -1
                                                                        ) {
                                                                            opt.action(
                                                                                dat.id,
                                                                                data
                                                                            ).then(
                                                                                () => {
                                                                                    state.data.filter(
                                                                                        (
                                                                                            d
                                                                                        ) => {
                                                                                            if (
                                                                                                d.id ===
                                                                                                dat.id
                                                                                            ) {
                                                                                                dat[
                                                                                                    opt.key
                                                                                                ] =
                                                                                                    data[
                                                                                                        opt.key
                                                                                                    ]
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                    let queryMessage =
                                                                                        createQueryMessage(
                                                                                            'success',
                                                                                            opt.key,
                                                                                            'update'
                                                                                        )
                                                                                    actions.setQueryMessage(
                                                                                        {
                                                                                            newStateKind:
                                                                                                queryMessage.type,
                                                                                            newStateContent:
                                                                                                queryMessage.message,
                                                                                        }
                                                                                    )
                                                                                    actions.displaySuccessMessage(
                                                                                        true
                                                                                    )
                                                                                    actions.setDatas(
                                                                                        state.data
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                        actions.displayOptions(
                                                                            false
                                                                        )
                                                                    }}
                                                                >
                                                                    {() => {
                                                                        if (
                                                                            opt.key ===
                                                                            'status'
                                                                        ) {
                                                                            if (
                                                                                dat[
                                                                                    opt
                                                                                        .key
                                                                                ] ===
                                                                                    0 ||
                                                                                dat[
                                                                                    opt
                                                                                        .key
                                                                                ] ===
                                                                                    false
                                                                            ) {
                                                                                return t(
                                                                                    'Activer'
                                                                                )
                                                                            } else {
                                                                                return t(
                                                                                    'Désactiver'
                                                                                )
                                                                            }
                                                                        } else if (
                                                                            opt.key ===
                                                                            'shown'
                                                                        ) {
                                                                            if (
                                                                                dat[
                                                                                    opt
                                                                                        .key
                                                                                ] ===
                                                                                    0 ||
                                                                                dat[
                                                                                    opt
                                                                                        .key
                                                                                ] ===
                                                                                    false
                                                                            ) {
                                                                                return t(
                                                                                    'Rendre visible'
                                                                                )
                                                                            } else {
                                                                                return t(
                                                                                    'Rendre invisible'
                                                                                )
                                                                            }
                                                                        } else {
                                                                            return t(
                                                                                opt.label
                                                                            )
                                                                        }
                                                                    }}
                                                                    {state.options
                                                                        .filter(
                                                                            (
                                                                                opt
                                                                            ) =>
                                                                                opt.kind ===
                                                                                'list-api'
                                                                        )
                                                                        .map(
                                                                            (
                                                                                o
                                                                            ) => (
                                                                                <div>
                                                                                    {o.key ===
                                                                                        opt.key && (
                                                                                        <ul
                                                                                            class={
                                                                                                'sup-drop-down-options-submenu list-' +
                                                                                                o.key
                                                                                            }
                                                                                        >
                                                                                            {o.list.map(
                                                                                                (
                                                                                                    l
                                                                                                ) => (
                                                                                                    <li
                                                                                                        data-value={
                                                                                                            l.key
                                                                                                        }
                                                                                                        onclick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            let el =
                                                                                                                e.target
                                                                                                            let data =
                                                                                                                {}
                                                                                                            if (
                                                                                                                o.key ===
                                                                                                                'frequency'
                                                                                                            ) {
                                                                                                                data[
                                                                                                                    o.key
                                                                                                                ] =
                                                                                                                    el.getAttribute(
                                                                                                                        'data-value'
                                                                                                                    )
                                                                                                            } else if (
                                                                                                                o.key ===
                                                                                                                'clearCardType'
                                                                                                            ) {
                                                                                                                data =
                                                                                                                    el.getAttribute(
                                                                                                                        'data-value'
                                                                                                                    )
                                                                                                            }
                                                                                                            o.action(
                                                                                                                dat.id,
                                                                                                                data
                                                                                                            ).then(
                                                                                                                () => {
                                                                                                                    state.data.filter(
                                                                                                                        (
                                                                                                                            d
                                                                                                                        ) => {
                                                                                                                            if (
                                                                                                                                d.id ===
                                                                                                                                dat.id
                                                                                                                            ) {
                                                                                                                                dat[
                                                                                                                                    o.key
                                                                                                                                ] =
                                                                                                                                    data[
                                                                                                                                        o.key
                                                                                                                                    ]
                                                                                                                            }
                                                                                                                        }
                                                                                                                    )
                                                                                                                    actions.displayOptions(
                                                                                                                        false
                                                                                                                    )
                                                                                                                    let queryMessage =
                                                                                                                        createQueryMessage(
                                                                                                                            'success',
                                                                                                                            opt.key,
                                                                                                                            'update'
                                                                                                                        )
                                                                                                                    actions.setQueryMessage(
                                                                                                                        {
                                                                                                                            newStateKind:
                                                                                                                                queryMessage.type,
                                                                                                                            newStateContent:
                                                                                                                                queryMessage.message,
                                                                                                                        }
                                                                                                                    )
                                                                                                                    actions.displaySuccessMessage(
                                                                                                                        true
                                                                                                                    )
                                                                                                                    actions.setDatas(
                                                                                                                        state.data
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        {() => {
                                                                                                            return (
                                                                                                                (isDefined(
                                                                                                                    l.num
                                                                                                                )
                                                                                                                    ? l.num
                                                                                                                    : '') +
                                                                                                                t(
                                                                                                                    l.value
                                                                                                                )
                                                                                                            )
                                                                                                        }}
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                </div>
                                                            ))}
                                                        {state.options
                                                            .filter(
                                                                (opt) =>
                                                                    opt.kind ===
                                                                        'confirm' &&
                                                                    (([
                                                                        'deleteNurse',
                                                                        'deletePatient',
                                                                    ].indexOf(
                                                                        opt.key
                                                                    ) > -1 &&
                                                                        (!isDefined(
                                                                            dat.isTestData
                                                                        ) ||
                                                                            (isDefined(
                                                                                dat.isTestData
                                                                            ) &&
                                                                                dat.isTestData ===
                                                                                    true))) ||
                                                                        [
                                                                            'deleteNurse',
                                                                            'deletePatient',
                                                                        ].indexOf(
                                                                            opt.key
                                                                        ) ===
                                                                            -1)
                                                            )
                                                            .map((opt) => (
                                                                <div>
                                                                    {!isDefined(
                                                                        state.forbiddenDeletion
                                                                    ) ||
                                                                    (isDefined(
                                                                        state.forbiddenDeletion
                                                                    ) &&
                                                                        (state.forbiddenDeletion.dependsOn.filter(
                                                                            (
                                                                                on
                                                                            ) =>
                                                                                isDefined(
                                                                                    on.is
                                                                                )
                                                                        )
                                                                            .length ===
                                                                            0 ||
                                                                            (state.forbiddenDeletion.dependsOn.filter(
                                                                                (
                                                                                    on
                                                                                ) =>
                                                                                    isDefined(
                                                                                        on.is
                                                                                    )
                                                                                        .length >
                                                                                    0
                                                                            ) &&
                                                                                state.forbiddenDeletion.dependsOn.reduce(
                                                                                    (
                                                                                        acc,
                                                                                        on
                                                                                    ) => {
                                                                                        if (
                                                                                            isDefined(
                                                                                                dat[
                                                                                                    on
                                                                                                        .field
                                                                                                ]
                                                                                            ) &&
                                                                                            String(
                                                                                                dat[
                                                                                                    on
                                                                                                        .field
                                                                                                ]
                                                                                            ) !==
                                                                                                String(
                                                                                                    on.is
                                                                                                )
                                                                                        ) {
                                                                                            acc.push(
                                                                                                on
                                                                                            )
                                                                                        }
                                                                                        return acc
                                                                                    },
                                                                                    []
                                                                                )
                                                                                    .length ===
                                                                                    0))) ? (
                                                                        <div
                                                                            class={
                                                                                'sup-drop-down-option sup-drop-down-option-dangerous'
                                                                            }
                                                                            onclick={() => {
                                                                                if (
                                                                                    !state.confirmAction ||
                                                                                    state.confirmActionKind !==
                                                                                        opt.key
                                                                                ) {
                                                                                    actions.setConfirmAction(
                                                                                        true
                                                                                    )
                                                                                    actions.setConfirmActionKind(
                                                                                        opt.key
                                                                                    )
                                                                                } else {
                                                                                    actions.setConfirmAction(
                                                                                        false
                                                                                    )
                                                                                    actions.displayOptions(
                                                                                        false
                                                                                    )
                                                                                    opt.action(
                                                                                        dat.id
                                                                                    ).then(
                                                                                        () => {
                                                                                            let queryMessage =
                                                                                                createQueryMessage(
                                                                                                    'success',
                                                                                                    opt.key,
                                                                                                    'delete'
                                                                                                )
                                                                                            actions.setQueryMessage(
                                                                                                {
                                                                                                    newStateKind:
                                                                                                        queryMessage.type,
                                                                                                    newStateContent:
                                                                                                        queryMessage.message,
                                                                                                }
                                                                                            )
                                                                                            actions.displaySuccessMessage(
                                                                                                true
                                                                                            )
                                                                                            actions.getDatas()
                                                                                        }
                                                                                    )
                                                                                }
                                                                            }}
                                                                        >
                                                                            {state.confirmAction &&
                                                                            state.confirmActionKind ===
                                                                                opt.key
                                                                                ? t(
                                                                                      'Confirmer ?'
                                                                                  )
                                                                                : t(
                                                                                      opt.label
                                                                                  )}
                                                                        </div>
                                                                    ) : (
                                                                        <div />
                                                                    )}
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                        </td>
                                    </tr>
                                ))}

                                {state.allOptions.list.length > 0 && (
                                    <tr
                                        class="customtable-data sup-table-footer"
                                        data-redtr={false}
                                        tabindex="0"
                                        onblur={() => {
                                            actions.displayOptions(false)
                                        }}
                                    >
                                        <td key={'empty-check'} class="">
                                            <label class="control checkbox checkboxAllRows">
                                                <input
                                                    type="checkbox"
                                                    onclick={(event) =>
                                                        actions.onCheckedAllInputs(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                                <span class="control-indicator control-indicator-select-all"></span>
                                            </label>
                                        </td>

                                        {state.head
                                            .filter((head, index) => index == 0)
                                            .map((head) => (
                                                <td
                                                    class={head.key}
                                                    key={head.key + '-' + 'all'}
                                                    style={{
                                                        'min-width': '175px',
                                                    }}
                                                >
                                                    {state.allOptions &&
                                                        state.allOptions
                                                            .label &&
                                                        state.allOptions.label}
                                                </td>
                                            ))}
                                        {state.head
                                            .filter((head, index) => index > 0)
                                            .map((head) => (
                                                <td
                                                    class={head.key}
                                                    key={head.key + '-' + 'all'}
                                                ></td>
                                            ))}
                                        <td
                                            key={'empty-options'}
                                            class=""
                                            style={{
                                                position: 'relative',
                                                overflow: 'visible',
                                            }}
                                            tabindex="0"
                                            onblur={(e) => {
                                                isDefined(e.relatedTarget)
                                                    ? ''
                                                    : actions.displayOptions(
                                                          false
                                                      )
                                            }}
                                        >
                                            <div
                                                class="sup-drop-down"
                                                onclick={() =>
                                                    actions.toggleOptions('123')
                                                }
                                            ></div>
                                            {state.optDisplayed &&
                                                state.optIdEntry === '123' && (
                                                    <div class="sup-drop-down-options">
                                                        {/*
                                            {state.allOptions.list.filter((opt) => opt.kind === 'web').map(opt =>
                                                <Link class='sup-drop-down-option' to={opt.action.replace(':id', '123')}>{opt.label}</Link>
                                            )}
                                            */}
                                                        {state.allOptions.list
                                                            .filter(
                                                                (opt) =>
                                                                    [
                                                                        'api',
                                                                        'list-api',
                                                                    ].indexOf(
                                                                        opt.kind
                                                                    ) > -1
                                                            )
                                                            .map((opt) => (
                                                                <div
                                                                    class={
                                                                        'sup-drop-down-option ' +
                                                                        (opt.kind ===
                                                                        'list-api'
                                                                            ? 'sup-dd-submenu'
                                                                            : '')
                                                                    }
                                                                    onclick={() => {
                                                                        actions.onAllOptionsApi(
                                                                            {
                                                                                key: opt.key,
                                                                                action: opt.action,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    {t(
                                                                        opt.label
                                                                    )}
                                                                    {state.allOptions.list
                                                                        .filter(
                                                                            (
                                                                                opt
                                                                            ) =>
                                                                                opt.kind ===
                                                                                'list-api'
                                                                        )
                                                                        .map(
                                                                            (
                                                                                o
                                                                            ) => (
                                                                                <div>
                                                                                    {o.key ===
                                                                                        opt.key && (
                                                                                        <ul
                                                                                            class={
                                                                                                'sup-drop-down-options-submenu list-' +
                                                                                                o.key
                                                                                            }
                                                                                        >
                                                                                            {o.list.map(
                                                                                                (
                                                                                                    l
                                                                                                ) => (
                                                                                                    <li
                                                                                                        data-value={
                                                                                                            l.key
                                                                                                        }
                                                                                                        onclick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            actions.onAllOptionsListApi(
                                                                                                                {
                                                                                                                    key: o.key,
                                                                                                                    action: o.action,
                                                                                                                    target: e.target,
                                                                                                                }
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        {() => {
                                                                                                            return (
                                                                                                                l.num +
                                                                                                                t(
                                                                                                                    l.value
                                                                                                                )
                                                                                                            )
                                                                                                        }}
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                </div>
                                                            ))}
                                                        {state.allOptions.list
                                                            .filter(
                                                                (opt) =>
                                                                    opt.kind ===
                                                                    'confirm'
                                                            )
                                                            .map((opt) => (
                                                                <div
                                                                    class={
                                                                        'sup-drop-down-option sup-drop-down-option-dangerous'
                                                                    }
                                                                    onclick={() => {
                                                                        actions.setConfirmActionKind(
                                                                            opt.key
                                                                        )
                                                                        actions.onAllOptionsConfirm(
                                                                            {
                                                                                action: opt.action,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    {state.confirmAction &&
                                                                    state.confirmActionKind ===
                                                                        opt.key
                                                                        ? t(
                                                                              'Confirmer ?'
                                                                          )
                                                                        : t(
                                                                              opt.label
                                                                          )}
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        )}
                </table>

                <div class="customtable-page">
                    <p class="">{'Page' + ' #' + (state.page + 1)}</p>
                </div>
                {((state.data && state.data.length === state.pagination) ||
                    state.page > 0) && (
                    <div class="customtable-pagination">
                        {state.page > 0 && (
                            <div
                                class="customtable-pagination-side"
                                onclick={() =>
                                    actions.updateTable(state.page - 1)
                                }
                            >
                                {'<'}
                            </div>
                        )}
                        {state.data.length === state.pagination && (
                            <div
                                class="customtable-pagination-side"
                                onclick={() =>
                                    actions.updateTable(state.page + 1)
                                }
                            >
                                {'>'}
                            </div>
                        )}
                    </div>
                )}
            </div>
        ),
    'customtable'
)

export { Table }

import { settings } from '@app/core/settings'

const { APP_CUSTOMISATION, APP_WEB_URL } = settings

//async
const dynLoad = function (
    type,
    baseurl,
    root = 'modules',
    condition = 'custo'
) {
    let _v = null
    if (baseurl !== '') {
        baseurl += '/'
    }
    if (root !== '' || root === 'modules') {
        root += '/'
    }
    try {
        let _custo = '',
            _targetCusto = '',
            _targetInstance = '',
            _targetServer = ''
        if (condition.indexOf('custo') > -1) {
            _targetCusto = APP_CUSTOMISATION.toLowerCase()
            if (_targetCusto === 'betterise') {
                _targetCusto = 'default'
            }
        }
        if (condition.indexOf('instance') > -1) {
            _targetInstance = localStorage
                .getItem('overridecustomer')
                .toLowerCase()
        }
        if (condition.indexOf('server') > -1) {
            if (_targetInstance !== '') {
                _targetInstance = _targetInstance + '-'
            }
            //
            _targetServer = APP_WEB_URL.toLowerCase()
            // _targetServer = 'https://theradmin-release.betterise.me/';
            if (_targetServer.match(/release/) !== null) {
                _targetServer = 'release'
            } else if (process.env.NODE_ENV !== undefined) {
                _targetServer = process.env.NODE_ENV
            }

            if (_targetCusto !== '') {
                _targetServer = '-' + _targetServer
            }
        }
        _custo = _targetInstance + _targetCusto + _targetServer
        _v = require('./../' + root + baseurl + type + '-' + _custo + '.js')
    } catch (error) {
        _v = require('./../' + root + baseurl + type + '-default.js')
    }

    _v = _v.default || _v
    if (type === 'view') {
        return _v.initView()
    } else if (type === 'action') {
        return _v.initAction()
    }
    return _v
}

export { dynLoad }

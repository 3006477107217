import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getCityById(id) {
    return queueQueries(`${APP_API_URL}/city/${id}`, 'GET')
}

export const City = {
    getCityById,
}

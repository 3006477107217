import { t } from '@app/utils'

const triggerList = [
    { id: 'reallylow', name: 'très faible' },
    { id: 'low', name: 'faible' },
    { id: 'high', name: 'élevée' },
    { id: 'reallyhigh', name: 'très élevée' },
]
const scenario_CARD_CUSTOMER_BPM = {
    filter: [],
    header: { key: 'bpm', title: 'BPM', subtitle: 'aucune alerte configurée' },
    variable: { unit: 'mmHG', word: 'bpm', gender: 0 },
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'seuils',
        },
        seuils: {
            name: 'BPM-seuils',
            type: 'seuils',
            label: 'Configuration des Seuils',
            field: 'seuils',
            placeholder: '',
            value: {},
        },
        alerts: {
            name: 'BPM-alerts',
            type: 'triggers',
            label: 'Configuration des alertes',
            field: 'triggers',
            placeholder: '',
            value: {},
            list: triggerList,
            withTriggers: true,
            triggerValues: [
                { reallylow: 'MEASURE_RANGES_HEARTBEAT_VALUE_LOWER' },
                { low: 'MEASURE_RANGES_HEARTBEAT_VALUE_LOW' },
                { normal: 'MEASURE_RANGES_HEARTBEAT_VALUE_NORMAL' },
                { high: 'MEASURE_RANGES_HEARTBEAT_VALUE_HIGH' },
                { reallyhigh: 'MEASURE_RANGES_HEARTBEAT_VALUE_HIGHER' },
            ],
            withConditions: 'questions',
            triggerConditions: [
                {
                    question: 'J’ai oublié de prendre mes médicaments :',
                    i18nQuestion: t('J’ai oublié de prendre mes médicaments :'),
                    answer: [
                        {
                            label: 'ce matin',
                            i18nLabel: t('ce matin'),
                            kpi: 'measure.treatment == 0',
                        },
                        {
                            label: 'ces 2 derniers jours',
                            i18nLabel: t('ces 2 derniers jours'),
                            kpi: 'measure.treatment == 1',
                        },
                        {
                            label: 'plusieurs fois cette semaine',
                            i18nLabel: t('plusieurs fois cette semaine'),
                            kpi: 'measure.treatment == 2',
                        },
                        {
                            label: 'j‘ai pris tous mes médicaments comme recommandé',
                            i18nLabel: t(
                                'j‘ai pris tous mes médicaments comme recommandé'
                            ),
                            kpi: 'measure.treatment == 3',
                        },
                        {
                            label: 'seulement certains d‘entre eux',
                            i18nLabel: t('seulement certains d‘entre eux'),
                            kpi: 'measure.treatment == 4',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.heartbeat.value.lower == 1) or (measure.ranges.heartbeat.value.low == 1) or (measure.ranges.heartbeat.value.high == 1) or (measure.ranges.heartbeat.value.higher == 1))',
                },
                {
                    question:
                        'Je suis à cours de médicaments depuis quelques jours :',
                    i18nQuestion: t(
                        'Je suis à cours de médicaments depuis quelques jours :'
                    ),
                    answer: [
                        {
                            label: 'Oui',
                            i18nLabel: t('Oui'),
                            kpi: 'measure.nomedic == 1',
                        },
                        {
                            label: 'Non',
                            i18nLabel: t('Non'),
                            kpi: 'measure.nomedic == 0',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.heartbeat.value.lower == 1) or (measure.ranges.heartbeat.value.low == 1) or (measure.ranges.heartbeat.value.high == 1) or (measure.ranges.heartbeat.value.higher == 1))',
                },
                {
                    question: 'Ressentez-vous des douleurs à la poitrine ?',
                    i18nQuestion: t(
                        'Ressentez-vous des douleurs à la poitrine ?'
                    ),
                    answer: [
                        {
                            label: 'Vous n‘éprouvez aucune douleur à la poitrine',
                            i18nLabel: t(
                                'Vous n‘éprouvez aucune douleur à la poitrine'
                            ),
                            kpi: 'measure.chestpain == 0',
                        },
                        {
                            label: 'Les douleurs sont identiques à celle de mon hospialisation',
                            i18nLabel: t(
                                'Les douleurs sont identiques à celle de mon hospialisation'
                            ),
                            kpi: 'measure.chestpain == 1',
                        },
                        {
                            label: 'Les douleurs sont différentes',
                            i18nLabel: t('Les douleurs sont différentes'),
                            kpi: 'measure.chestpain == 2',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 1) and ((measure.ranges.heartbeat.value.lower == 1) or (measure.ranges.heartbeat.value.low == 1) or (measure.ranges.heartbeat.value.high == 1) or (measure.ranges.heartbeat.value.higher == 1))',
                },
            ],
        },
    },
}

const scenario_CARD_CUSTOMER_TENSION = {
    filter: [],
    header: {
        key: 'tension',
        title: 'Tension systolique',
        subtitle: 'aucune alerte configurée',
    },
    variable: { unit: 'mmHG', word: 'tension', gender: 0 },
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'seuils',
        },
        seuils: {
            name: 'TENSION-seuils',
            type: 'seuils',
            label: 'Configuration des Seuils',
            field: 'seuils',
            placeholder: '',
            value: {},
        },
        alerts: {
            name: 'TENSION-alerts',
            type: 'triggers',
            label: 'Configuration des alertes',
            field: 'triggers',
            placeholder: '',
            value: {},
            list: triggerList,
            withTriggers: true,
            triggerValues: [
                { reallylow: 'MEASURE_RANGES_BLOODPRESSURE_SYSTOLIC_LOWER' },
                { low: 'MEASURE_RANGES_BLOODPRESSURE_SYSTOLIC_LOW' },
                { normal: 'MEASURE_RANGES_BLOODPRESSURE_SYSTOLIC_NORMAL' },
                { high: 'MEASURE_RANGES_BLOODPRESSURE_SYSTOLIC_HIGH' },
                { reallyhigh: 'MEASURE_RANGES_BLOODPRESSURE_SYSTOLIC_HIGHER' },
            ],
            withConditions: 'questions',
            triggerConditions: [
                {
                    question: 'J’ai oublié de prendre mes médicaments :',
                    i18nQuestion: t('J’ai oublié de prendre mes médicaments :'),
                    answer: [
                        {
                            label: 'ce matin',
                            i18nLabel: t('ce matin'),
                            kpi: 'measure.treatment == 0',
                        },
                        {
                            label: 'ces 2 derniers jours',
                            i18nLabel: t('ces 2 derniers jours'),
                            kpi: 'measure.treatment == 1',
                        },
                        {
                            label: 'plusieurs fois cette semaine',
                            i18nLabel: t('plusieurs fois cette semaine'),
                            kpi: 'measure.treatment == 2',
                        },
                        {
                            label: 'j‘ai pris tous mes médicaments comme recommandé',
                            i18nLabel: t(
                                'j‘ai pris tous mes médicaments comme recommandé'
                            ),
                            kpi: 'measure.treatment == 3',
                        },
                        {
                            label: 'seulement certains d‘entre eux',
                            i18nLabel: t('seulement certains d‘entre eux'),
                            kpi: 'measure.treatment == 4',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.bloodpressure.systolic.lower == 1) or (measure.ranges.bloodpressure.systolic.low == 1) or (measure.ranges.bloodpressure.systolic.high == 1) or (measure.ranges.bloodpressure.systolic.higher == 1))',
                },
                {
                    question:
                        'Je suis à cours de médicaments depuis quelques jours :',
                    i18nQuestion: t(
                        'Je suis à cours de médicaments depuis quelques jours :'
                    ),
                    answer: [
                        {
                            label: 'Oui',
                            i18nLabel: t('Oui'),
                            kpi: 'measure.nomedic == 1',
                        },
                        {
                            label: 'Non',
                            i18nLabel: t('Non'),
                            kpi: 'measure.nomedic == 0',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.bloodpressure.systolic.lower == 1) or (measure.ranges.bloodpressure.systolic.low == 1) or (measure.ranges.bloodpressure.systolic.high == 1) or (measure.ranges.bloodpressure.systolic.higher == 1))',
                },
                {
                    question: 'J’ai mangé des aliments salés :',
                    i18nQuestion: t('J’ai mangé des aliments salés :'),
                    answer: [
                        {
                            label: 'Hier',
                            i18nLabel: t('Hier'),
                            kpi: 'measure.salty == 0',
                        },
                        {
                            label: 'Plusieurs fois ces derniers jours',
                            i18nLabel: t('Plusieurs fois ces derniers jours'),
                            kpi: 'measure.salty == 1',
                        },
                        {
                            label: 'J‘ai évité le sel comme recommandé',
                            i18nLabel: t('J‘ai évité le sel comme recommandé'),
                            kpi: 'measure.salty == 2',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.bloodpressure.systolic.lower == 1) or (measure.ranges.bloodpressure.systolic.low == 1) or (measure.ranges.bloodpressure.systolic.high == 1) or (measure.ranges.bloodpressure.systolic.higher == 1))',
                },
                {
                    question: 'Ressentez-vous des douleurs à la poitrine ?',
                    i18nQuestion: t(
                        'Ressentez-vous des douleurs à la poitrine ?'
                    ),
                    answer: [
                        {
                            label: 'Vous n‘éprouvez aucune douleur à la poitrine',
                            i18nLabel: t(
                                'Vous n‘éprouvez aucune douleur à la poitrine'
                            ),
                            kpi: 'measure.chestpain == 0',
                        },
                        {
                            label: 'Les douleurs sont identiques à celle de mon hospialisation',
                            i18nLabel: t(
                                'Les douleurs sont identiques à celle de mon hospialisation'
                            ),
                            kpi: 'measure.chestpain == 1',
                        },
                        {
                            label: 'Les douleurs sont différentes',
                            i18nLabel: t('Les douleurs sont différentes'),
                            kpi: 'measure.chestpain == 2',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 1) and ((measure.ranges.bloodpressure.systolic.lower == 1) or (measure.ranges.bloodpressure.systolic.low == 1) or (measure.ranges.bloodpressure.systolic.high == 1) or (measure.ranges.bloodpressure.systolic.higher == 1))',
                },
            ],
        },
    },
}

const scenario_CARD_CUSTOMER_ACTIVITY = {
    filter: [],
    header: {
        key: 'activity',
        title: 'Activité',
        subtitle: 'aucune alerte configurée',
    },
    variable: { unit: 'pas', word: 'activité', gender: 1 },
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'seuils',
        },
        seuils: {
            name: 'ACTIVITY-seuils',
            type: 'seuils',
            label: 'Configuration des Seuils',
            field: 'seuils',
            placeholder: '',
            value: {},
        },
        alerts: {
            name: 'ACTIVITY-alerts',
            type: 'triggers',
            label: 'Configuration des alertes',
            field: 'triggers',
            placeholder: '',
            value: {},
            list: triggerList,
            withTriggers: true,
            triggerValues: [
                { reallylow: 'MEASURE_RANGES_CRYPTEDACTIVITY_STEPS_LOWER' },
                { low: 'MEASURE_RANGES_CRYPTEDACTIVITY_STEPS_LOW' },
                { normal: 'MEASURE_RANGES_CRYPTEDACTIVITY_STEPS_NORMAL' },
                { high: 'MEASURE_RANGES_CRYPTEDACTIVITY_STEPS_HIGH' },
                { reallyhigh: 'MEASURE_RANGES_CRYPTEDACTIVITY_STEPS_HIGHER' },
            ],
            withConditions: false,
        },
    },
}

const scenario_CARD_CUSTOMER_WEIGHT = {
    filter: [],
    header: {
        key: 'weight',
        title: 'Poids',
        subtitle: 'aucune alerte configurée',
    },
    variable: { unit: 'kg', word: 'poids', gender: 1 },
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'upanddown',
        },
        alerts: {
            name: 'WEIGHT-alerts',
            type: 'triggers',
            label: 'Configuration des alertes',
            field: 'triggers',
            placeholder: '',
            value: { up: [], down: [] },
            list: triggerList,
            withTriggers: true,
            triggerValues: [
                { reallylow: 'measure.ranges.cryptedweight.value.lower' },
                { low: 'measure.ranges.cryptedweight.value.low' },
                { normal: 'measure.ranges.cryptedweight.value.normal' },
                { high: 'measure.ranges.cryptedweight.value.high' },
                { reallyhigh: 'measure.ranges.cryptedweight.value.higher' },
            ],
            withConditions: 'questions',
            triggerConditions: [
                {
                    question: 'J’ai oublié de prendre mes médicaments :',
                    i18nQuestion: t('J’ai oublié de prendre mes médicaments :'),
                    answer: [
                        {
                            label: 'ce matin',
                            i18nLabel: t('ce matin'),
                            kpi: 'measure.treatment == 0',
                        },
                        {
                            label: 'ces 2 derniers jours',
                            i18nLabel: t('ces 2 derniers jours'),
                            kpi: 'measure.treatment == 1',
                        },
                        {
                            label: 'plusieurs fois cette semaine',
                            i18nLabel: t('plusieurs fois cette semaine'),
                            kpi: 'measure.treatment == 2',
                        },
                        {
                            label: 'j‘ai pris tous mes médicaments comme recommandé',
                            i18nLabel: t(
                                'j‘ai pris tous mes médicaments comme recommandé'
                            ),
                            kpi: 'measure.treatment == 3',
                        },
                        {
                            label: 'seulement certains d‘entre eux',
                            i18nLabel: t('seulement certains d‘entre eux'),
                            kpi: 'measure.treatment == 4',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
                {
                    question:
                        'Je suis à cours de médicaments depuis quelques jours :',
                    i18nQuestion: t(
                        'Je suis à cours de médicaments depuis quelques jours :'
                    ),
                    answer: [
                        {
                            label: 'Oui',
                            i18nLabel: t('Oui'),
                            kpi: 'measure.nomedic == 1',
                        },
                        {
                            label: 'Non',
                            i18nLabel: t('Non'),
                            kpi: 'measure.nomedic == 0',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
                {
                    question: 'J’ai mangé des aliments salés :',
                    i18nQuestion: t('J’ai mangé des aliments salés :'),
                    answer: [
                        {
                            label: 'Hier',
                            i18nLabel: t('Hier'),
                            kpi: 'measure.salty == 0',
                        },
                        {
                            label: 'Plusieurs fois ces derniers jours',
                            i18nLabel: t('Plusieurs fois ces derniers jours'),
                            kpi: 'measure.salty == 1',
                        },
                        {
                            label: 'J‘ai évité le sel comme recommandé',
                            i18nLabel: t('J‘ai évité le sel comme recommandé'),
                            kpi: 'measure.salty == 2',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
                {
                    // for both pathologytype 0/1
                    question:
                        'Comment est votre niveau d’essoufflement par rapport aux derniers jours ?',
                    i18nQuestion: t(
                        'Comment est votre niveau d’essoufflement par rapport aux derniers jours ?'
                    ),
                    answer: [
                        {
                            label: 'Je ne suis pas essoufflé',
                            i18nLabel: t('Je ne suis pas essoufflé'),
                            kpi: 'measure.breathlessness == 0',
                        },
                        {
                            label: 'Je suis autant essoufflé',
                            i18nLabel: t('Je suis autant essoufflé'),
                            kpi: 'measure.breathlessness == 1',
                        },
                        {
                            label: 'Je suis moins essoufflé',
                            i18nLabel: t('Je suis moins essoufflé'),
                            kpi: 'measure.breathlessness == 2',
                        },
                        {
                            label: 'Je suis plus essoufflé',
                            i18nLabel: t('Je suis plus essoufflé'),
                            kpi: 'measure.breathlessness == 3',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 1) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
                {
                    question: 'Par rapport aux dernières nuits :',
                    i18nQuestion: t('Par rapport aux dernières nuits :'),
                    answer: [
                        {
                            label: 'Je n’ai pas dû ajouter un oreiller',
                            i18nLabel: t('Je n’ai pas dû ajouter un oreiller'),
                            kpi: 'measure.needpillow == 0',
                        },
                        {
                            label: 'J‘ai dû ajouter un oreiller',
                            i18nLabel: t('J‘ai dû ajouter un oreiller'),
                            kpi: 'measure.needpillow == 1',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
                {
                    question:
                        'Souffrez-vous de gonflement des pieds et/ou des chevilles ?',
                    i18nQuestion: t(
                        'Souffrez-vous de gonflement des pieds et/ou des chevilles ?'
                    ),
                    answer: [
                        {
                            label: 'Non',
                            i18nLabel: t('Non'),
                            kpi: 'measure.swelling == 0',
                        },
                        {
                            label: 'Oui mais moins que les derniers jours',
                            i18nLabel: t(
                                'Oui mais moins que les derniers jours'
                            ),
                            kpi: 'measure.swelling == 1',
                        },
                        {
                            label: 'Oui mais pas plus que les derniers jours',
                            i18nLabel: t(
                                'Oui mais pas plus que les derniers jours'
                            ),
                            kpi: 'measure.swelling == 2',
                        },
                        {
                            label: 'Oui mais plus que les derniers jours',
                            i18nLabel: t(
                                'Oui mais plus que les derniers jours'
                            ),
                            kpi: 'measure.swelling == 3',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
                {
                    question: 'Par rapport aux derniers jours :',
                    i18nQuestion: t('Par rapport aux derniers jours :'),
                    answer: [
                        {
                            label: 'Je ne tousse pas',
                            i18nLabel: t('Je ne tousse pas'),
                            kpi: 'measure.cough == 0',
                        },
                        {
                            label: 'Je tousse moins',
                            i18nLabel: t('Je tousse moins'),
                            kpi: 'measure.cough == 1',
                        },
                        {
                            label: 'Pareil',
                            i18nLabel: t('Pareil'),
                            kpi: 'measure.cough == 2',
                        },
                        {
                            label: 'Je tousse plus',
                            i18nLabel: t('Je tousse plus'),
                            kpi: 'measure.cough == 3',
                        },
                    ],
                    kpi: '(cardio.pathologytype == 0) and ((measure.ranges.cryptedweight.value.lower == 1) or (measure.ranges.cryptedweight.value.low == 1) or (measure.ranges.cryptedweight.value.high == 1) or (measure.ranges.cryptedweight.value.higher == 1))',
                },
            ],
        },
    },
}

export { scenario_CARD_CUSTOMER_WEIGHT }
export { scenario_CARD_CUSTOMER_ACTIVITY }
export { scenario_CARD_CUSTOMER_TENSION }
export { scenario_CARD_CUSTOMER_BPM }

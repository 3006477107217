import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function createLegalDocument(data) {
    return queueQueries(
        `${APP_API_URL}/admin/legal-documents/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function createLegalAgreement(data) {
    return queueQueries(
        `${APP_API_URL}/admin/legal-agreements/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getLegalDocument() {
    return queueQueries(
        `${APP_API_URL}/admin/legal-agreements/service-types/`,
        'GET'
    )
}
function getAgreements(userType) {
    return queueQueries(
        `${APP_API_URL}/admin/legal-agreements/search/0/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: { date: 'desc' }, userType: userType }
    )
}

export const Pdfs = {
    createLegalDocument,
    createLegalAgreement,
    getLegalDocument,
    getAgreements,
}

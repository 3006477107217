import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { isDefined } from '@app/core'
import './index.scss'

import icoECS from '@app/img/ico/ecs-link.png'
import icoECSx2 from '@app/img/ico/ecs-link@2x.png'

const AFECS = Component(
    {
        document: null,
        sendToParent: null,
        ulDisplayed: false,
        list: [],
        name: '',
        model: { key: null, value: null },
        currentSearch: '',
        readOnly: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.list) {
                state.list = props.list
            }
            if (props.document) {
                if (props.document.expression === 'default') {
                    state.readOnly = 'readOnly'
                }
                actions.setDocument(props.document)
            }
            if (props.model) {
                actions.setModel(props.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        selectThisOne:
            ({ el, props }) =>
            (state, actions) => {
                let document = state.document
                document.expression = el

                actions.setDocument(document)
                state.sendToParent()
                actions.toggleUl(false)
            },
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                let document = state.document
                actions.setCurrentSearch(value)
                document[name] = value
                actions.toggleUl(true)
                actions.setDocument(document)
                state.sendToParent()
            },
        setOrder: (order) => (state, actions) => {
            let document = state.document
            document.order = order
            actions.setDocument(document)
        },
        setCurrentSearch: (newState) => (state) => ({
            currentSearch: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        waitForLastInput: ({ target }) => debounce(500, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <input
                key={'ecs-order-' + props.key}
                type={'hidden'}
                name={'order'}
                value={state.document[state.model.order]}
                oncreate={() => {
                    actions.setOrder(props.order)
                }}
            />
            <div
                class="sup-custom-select sup-custom-select-wide-one"
                tabindex="0"
                onblur={() => {
                    actions.toggleUl(false)
                }}
                onclick={() => {
                    actions.toggleUl(!state.ulDisplayed)
                }}
            >
                <input
                    key={'expression'}
                    name={'expression'}
                    type="text"
                    placeholder={t('Tapez votre rechercher ici ...')}
                    oninput={actions.waitForLastInput}
                    value={state.document.expression}
                    onblur={(e) => {
                        isDefined(e.relatedTarget)
                            ? ''
                            : actions.toggleUl(false)
                    }}
                    readOnly={state.readOnly}
                />
                {state.readOnly === false && (
                    <div>
                        <div
                            class="sup-drop-down"
                            style={{ 'margin-top': '-33px' }}
                            onclick={() => {
                                actions.toggleUl(!state.ulDisplayed)
                            }}
                        ></div>
                        <ul
                            class="sup-custom-select-ul"
                            style={{
                                display: state.ulDisplayed ? 'block' : 'none',
                                margin: '0px',
                            }}
                        >
                            {state.list
                                .filter(
                                    (li) =>
                                        (state.model.key === null
                                            ? li
                                            : li[state.model.value]) !== '' &&
                                        ((state.currentSearch !== '' &&
                                            (state.model.key === null
                                                ? li
                                                : li[state.model.value]
                                            ).indexOf(state.currentSearch) >
                                                -1) ||
                                            state.currentSearch === '')
                                )
                                .map((l) => (
                                    <li
                                        class="sup-custom-select-li"
                                        data-value={
                                            state.model.key === null
                                                ? l
                                                : l[state.model.key]
                                        }
                                        onclick={() => {
                                            actions.selectThisOne({
                                                el: l,
                                                props: props,
                                            })
                                        }}
                                    >
                                        {state.model.key === null
                                            ? l
                                            : l[state.model.value]}
                                    </li>
                                ))}
                        </ul>
                        <div
                            class="sup-custom-select-delete"
                            onclick={(el) => {
                                props.ondelete(el)
                            }}
                        >
                            <p class="">{t('Supprimer')}</p>
                        </div>
                    </div>
                )}
            </div>
            <img src={icoECS} srcset={`${icoECSx2} 2x`} />
            <input
                type={'text'}
                name={'value'}
                placeholder={t('Texte dynamique')}
                value={state.document.value}
                oninput={actions.waitForLastInput}
            />
        </div>
    ),
    'afecs'
)

export { AFECS }

import { t, h, Component } from '@app/utils'
import { isDefined } from '@app/core'
import { debounce } from 'hyperapp-fx'
import './index.scss'

import icoArrowTopOff from '@app/img/ico/img-arrow-up-grey.png'
import icoArrowTopOffx2 from '@app/img/ico/img-arrow-up-grey@2x.png'
import icoArrowTopOn from '@app/img/ico/img-arrow-up.png'
import icoArrowTopOnx2 from '@app/img/ico/img-arrow-up@2x.png'

import icoArrowBottomOff from '@app/img/ico/img-arrow-down-grey.png'
import icoArrowBottomOffx2 from '@app/img/ico/img-arrow-down-grey@2x.png'
import icoArrowBottomOn from '@app/img/ico/img-arrow-down.png'
import icoArrowBottomOnx2 from '@app/img/ico/img-arrow-down@2x.png'

const AFStep = Component(
    {
        document: null,
        sendToParent: null,
        list: [],
        name: '',
        model: { key: null, value: null, order: 'order' },
        currentSearch: '',
        readOnly: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.list) {
                state.list = props.list
            }
            if (props.document) {
                actions.setDocument(props.document)
            }
            if (props.model) {
                actions.setModel(props.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                let document = state.document
                document[name] = value
                document.value = value
                actions.setDocument(document)
                state.sendToParent(document)
            },
        setOrder: (order) => (state, actions) => {
            let document = state.document
            document.order = order
            actions.setDocument(document)
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        waitForLastInput: ({ target }) => debounce(500, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div
            class={
                'order-' +
                props.order +
                '-' +
                (isDefined(state.document) ? state.document.value : '')
            }
        >
            {isDefined(state.document) && (
                <dummy key={state.document[state.model.order] + 1}>
                    <div
                        style={{
                            'display': 'inline-block',
                            'width': '185px',
                            'vertical-align': 'top',
                        }}
                    >
                        <input
                            key={'ecs-order-' + props.key}
                            class="input-as-hidden"
                            type={'text'}
                            name={'order'}
                            value={state.document[state.model.order] + 1}
                            oncreate={() => {
                                actions.setOrder(props.order)
                            }}
                            readOnly
                        />
                        <div
                            class="sup-custom-select-delete"
                            onclick={(el) => {
                                props.ondelete(el)
                            }}
                        >
                            <p class="">{t('Supprimer')}</p>
                        </div>
                    </div>
                    <div class="sup-textarea" style={{ margin: '0px' }}>
                        <textarea
                            key={state.document[state.model.order] + 1}
                            name={'description'}
                            oninput={actions.waitForLastInput}
                            placeholder={props.placeholder}
                            value={state.document.value}
                        >
                            {state.document.value}
                        </textarea>
                    </div>
                    <div class="arrows">
                        <div class="arrow-up">
                            <img
                                class="arrow-up-off"
                                src={icoArrowTopOff}
                                srcset={`${icoArrowTopOffx2} 2x`}
                                alt=""
                            />
                            <img
                                style={{ cursor: 'pointer' }}
                                class="arrow-up-on"
                                src={icoArrowTopOn}
                                srcset={`${icoArrowTopOnx2} 2x`}
                                alt=""
                                onclick={() => {
                                    props.onupdateorder({
                                        step: state.document,
                                        upOrDown: 'up',
                                    })
                                }}
                            />
                        </div>
                        <div class="arrow-down">
                            <img
                                class="arrow-down-off"
                                src={icoArrowBottomOff}
                                srcset={`${icoArrowBottomOffx2} 2x`}
                                alt=""
                            />
                            <img
                                style={{ cursor: 'pointer' }}
                                class="arrow-down-on"
                                src={icoArrowBottomOn}
                                srcset={`${icoArrowBottomOnx2} 2x`}
                                alt=""
                                onclick={() => {
                                    props.onupdateorder({
                                        step: state.document,
                                        upOrDown: 'down',
                                    })
                                }}
                            />
                        </div>
                    </div>
                </dummy>
            )}
        </div>
    ),
    'afstep'
)

export { AFStep }

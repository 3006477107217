import { t } from '@app/utils'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const gamif_LEVELS = {
    filter: [],
    search: { label: 'ID' },
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'value', label: 'value' },
        { key: 'points', label: 'points' },
        { key: 'date', label: 'date' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/gamif/levels/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/gamif/levels/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editGamificationLevel,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteGamificationLevel,
        },
    ],
    tableAllOptions: {
        label: 'NIVEAUX SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editGamificationLevel,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editGamificationLevel,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteGamificationLevel,
            },
        ],
    },
    header: ['Configurer la gamif', 'Niveau'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'gamif-level',
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici l‘id de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner l‘id de la carte',
                i18nMessage: t('Veuillez renseigner l‘id de la carte'),
            },
        },
        value: {
            name: 'value',
            type: 'int',
            label: 'Niveau',
            field: 'int',
            placeholder: 'Renseignez ici le niveau',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le niveau',
                i18nMessage: t('Veuillez renseigner le niveau'),
            },
        },
        points: {
            name: 'points',
            type: 'int',
            label: 'Points',
            field: 'int',
            placeholder: 'Renseignez ici le nombre de points',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nombre de points',
                i18nMessage: t('Veuillez renseigner le nombre de points'),
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
    },
}

export { gamif_LEVELS }

import { h, Component } from '@app/utils'
import Player from '@vimeo/player'
import './index.scss'

let player = null

import imgClose from './ico-close-black-big.png'
import imgClosex2 from './ico-close-black-big@2x.png'

import { ModalConfirm } from './kind/confirm'

const Modal = Component(
    {
        modalStatus: 'btzModal',
        kind: '',
        displayedon: '',
        confirm: false,
        confirmActivated: false,
        customClass: '',
        cancelAction: null,
        rendering: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.kind) {
                state.kind = props.kind
            }
            if (props.displayedon) {
                state.displayedon = props.displayedon
            }
            if (props.confirm) {
                state.confirm = props.confirm
                if (props.confirm === true) {
                    state.confirmActivated = true
                }
            }
            if (props.customClass) {
                state.customClass = props.customClass
            }
            if (props.cancelAction) {
                state.cancelAction = props.cancelAction
            }

            actions.closeModalStatus()
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (
                props.modalDisplayed !== undefined &&
                props.modalDisplayed !== null
            ) {
                if (props.modalDisplayed == false) {
                    actions.closeModal(false)
                } else if (props.modalDisplayed == true) {
                    actions.showModal()
                }
            }
            if (props.confirm) {
                actions.openConfirmModal()
            }
        },
        showModal: () => (state, actions) => {
            document.body.classList.add('btzOverflowHidden')
            state.modalStatus = 'btzModal active'
            if (player !== null) {
                player.play()
            }
            if (state.kind === 'vimeo') {
                if (player === null) {
                    player = new Player(document.getElementById('test'), {
                        id: 156669587,
                    })
                }

                setTimeout(function () {
                    player.pause()
                    player.setCurrentTime(0)
                    player.play()
                }, 500)
            }
            if (state.confirmActivated) {
                actions.openConfirmModal()
            }
            actions.showModalStatus()
        },
        closeModal:
            (force = true) =>
            (state, actions) => {
                document.body.classList.remove('btzOverflowHidden')
                state.confirm = false
                state.modalStatus = 'btzModal'

                if (player !== null) {
                    player.pause()
                }
                if (
                    state.cancelAction !== null &&
                    state.cancelAction !== undefined &&
                    force
                ) {
                    state.cancelAction()
                }

                actions.closeConfirmModal()
                actions.closeModalStatus()
                actions.forceRendering()
            },
        showModalStatus: () => (state) => ({
            modalStatus: 'btzModal active',
        }),
        closeModalStatus: () => (state) => ({
            modalStatus: 'btzModal',
        }),
        closeConfirmModal: () => (state) => ({
            confirm: false,
        }),
        openConfirmModal: () => (state) => ({
            confirm: true,
        }),
        forceRendering: () => (state) => ({
            rendering: !state.rendering,
        }),
        updateParentProps: (props) => (state, actions) => {
            if (props) {
                props.confirmConfirm(props)
                actions.closeModal()
            }
        },
    },
    (state, actions) => (props, children) => (
        <div
            key={props.key}
            class={
                state.customClass + ' btzMod' + (' -' + props.modalDisplayed)
            }
        >
            <div
                onclick={(e) => actions.showModal(e)}
                style={{ display: 'inline-block', width: '100%' }}
            >
                {children}
            </div>

            <div key={state.rendering} id={props.id} class={state.modalStatus}>
                <div
                    class="btzModal-btzModalWrapper"
                    data-confirm={state.confirm}
                >
                    <div class="btzModal-btzModalWrapper-btzModalContent">
                        <div
                            class="btzModal-btzCloseModal"
                            style=""
                            onclick={() => actions.closeModal()}
                        >
                            <img
                                src={imgClose}
                                srcset={`${imgClosex2} 2x`}
                                alt=""
                                class=""
                            />
                        </div>
                        {props.content}

                        {state.confirm && (
                            <ModalConfirm
                                {...props}
                                propagateConfirm={actions.updateParentProps}
                                propagateCancel={actions.closeModal}
                            ></ModalConfirm>
                        )}
                    </div>
                </div>

                <div
                    class="btzModal-btzBackdrop"
                    onclick={() => actions.closeModal()}
                ></div>
            </div>
        </div>
    ),
    'modal'
)

export { Modal }

import { t, h, Component } from '@app/utils'
import { Settings } from '@app/api'
import { Convert } from '@app/elements'
import './index.scss'
import AIMessage from './AIMessage'

import IndexLayout from '@app/layouts/pages/layout'

import icoValid from '@app/img/ico/picto-check.png'
import icoValidx2 from '@app/img/ico/picto-check@2x.png'

import icoAlert from '@app/img/ico/picto-warning.png'
import icoAlertx2 from '@app/img/ico/picto-warning@2x.png'

const state = {
    logsPage: 0,
    logs: [],
    message: null,
    isLoading: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // Settings.getAdminLogs(state.logsPage).then((res) => {
        //     actions.setLogs(res.data);
        // });
        actions.getPreviousLogs(true)
        Settings.getAdminMessage().then((res) => {
            if (res.data && res.data.content !== undefined) {
                actions.setMessage(res.data)
            } else {
                actions.setMessage({ content: t('Tout va bien.'), type: 0 })
            }
        })
    },
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
    setLogs: (newState) => (state) => ({
        logs: newState,
    }),
    setMessage: (newState) => (state) => ({
        message: newState,
    }),
    onLogsInit: (el) => (state, actions) => {
        el.addEventListener('scroll', actions.onLogsScroll, true)
    },
    onLogsScroll: (e) => (state, actions) => {
        if (state.isLoading) {
            return false
        }
        if (
            e.target.scrollTop + e.target.clientHeight >=
            e.target.scrollHeight
        ) {
            actions.getPreviousLogs()
        }
    },
    getPreviousLogs:
        (init = false) =>
        (state, actions) => {
            let page = +state.logsPage + 1
            if (init) {
                page = 0
            }
            actions.loading()
            Settings.getAdminLogs(page).then((res) => {
                actions.setLogsPage(page)
                actions.setLogs([...state.logs, ...res.data])
                actions.loading()
            })
        },
    setLogsPage: (page) => (state) => ({
        logsPage: page,
    }),
    loading: () => (state) => ({
        isLoading: !state.isLoading,
    }),
}

const view =
    (state, actions) =>
    ({ customer }, children) => (
        <IndexLayout
            page="adminindex"
            customer={customer}
            currentPage={'adminindex'}
        >
            <div
                class="col-5 col-md-12"
                style={{ 'vertical-align': 'top', 'padding-left': '0px' }}
            >
                {state.message !== null && (
                    <div class="adminindex-message">
                        {state.message.type === 0 ? (
                            <img
                                class=""
                                src={icoValid}
                                srcset={`${icoValidx2} 2x`}
                                alt=""
                            />
                        ) : (
                            <img
                                class=""
                                src={icoAlert}
                                srcset={`${icoAlertx2} 2x`}
                                alt=""
                            />
                        )}
                        <div class="adminindex-message-head">
                            <p
                                class=""
                                innerHTML={
                                    <Convert
                                        separator={''}
                                        is_xhtml={true}
                                        type="nl2br"
                                    >
                                        {state.message.content}
                                    </Convert>
                                }
                            ></p>
                        </div>
                        <div class="adminindex-message-content">
                            <p class="">{t('Une question ?')}</p>
                            <a href="mailto:edouard@betterise.me">
                                edouard@betterise.me
                            </a>
                        </div>
                    </div>
                )}
            </div>
            <div class="col-7 col-md-12" style={{ 'vertical-align': 'top' }}>
                <p class="sup-title" style={{ margin: '0 0 25px 30px' }}>
                    {t('FLUX D‘ACTIVITÉ')}
                </p>
                {state.logs.length > 0 && (
                    <div
                        class="adminindex-logs"
                        oncreate={(el) => {
                            actions.onLogsInit(el)
                        }}
                    >
                        {state.logs.map((log, idx) => (
                            <div key={'log-' + idx}>
                                <AIMessage {...log}></AIMessage>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </IndexLayout>
    )

export default Component(state, actions, view, 'adminindex')

import { t, h, Component } from '@app/utils'
import {
    Form,
    Button,
    AdminFormHelp,
    AdminDataviz,
    DatePicker,
} from '@app/elements'
import { sortObject, deepCopy, getYMDfromDateObj } from '@app/core'
import * as d3 from 'd3'
import './../AdminForm/index.scss'
import './index.scss'

const AdminStats = Component(
    {
        document: null,
        statistics: null,
        originalStatistics: null,
        target: null,
        formIsSubmitting: false,
        ticksMode: 'normal',
        refDate: '20190429',
        mode: 'week',
        particularKind: null,
        loadingData: true,
        svgString: null,
        extractedCSSText: '',
        pdfDoc: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            var refDate = new Date()
            var year = refDate.getUTCFullYear(),
                month = refDate.getUTCMonth() + 1,
                day = refDate.getUTCDate()
            if (+month < 10) {
                month = '0' + month
            }
            if (+day < 10) {
                day = '0' + day
            }
            refDate = year + '' + month + '' + day
            actions.setRefDate(refDate)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
            if (props.statistics) {
                actions.setOriginalStatistics(props.statistics)
            }
            if (props.target) {
                actions.setTarget(props.target)
            }
            if (props.particularKind) {
                actions.setParticularKind(props.particularKind)
            }

            if (props.target) {
                actions.updateAllDataViz()
            }
        },
        updateAllDataViz: () => (state, actions) => {
            //
            actions.setStatistics(null) // temp
            //
            actions.setLoadingData(true)
            if (state.particularKind === 'corpo-survey') {
                // actions.setTicksMode('date');
                state
                    .target(
                        state.particularKind,
                        state.document.id.value,
                        state.refDate,
                        state.mode
                    )
                    .then((res) => {
                        let statistics = deepCopy(state.originalStatistics)
                        if (res.data !== undefined) {
                            for (var stat in statistics) {
                                if (
                                    res.data[stat] !== undefined &&
                                    res.data[stat] !== null
                                ) {
                                    statistics[stat].value = res.data[stat]
                                }
                            }
                        }
                        statistics['results'] = statistics['data']
                        delete statistics['data']

                        let parsedStat = []
                        if (statistics.results !== undefined) {
                            for (var day in statistics.results.value) {
                                // iterate on each day
                                let oday = sortObject(
                                    statistics.results.value[day]
                                )
                                let idxQuestion = 0

                                for (var question in oday) {
                                    // iterate on each question in current day
                                    let oquestion = oday[question]
                                    let questionDatas = null
                                    if (parsedStat[idxQuestion] === undefined) {
                                        parsedStat[idxQuestion] = {}
                                    }

                                    for (var dday in statistics.results.value) {
                                        if (
                                            parsedStat[idxQuestion].results ===
                                            undefined
                                        ) {
                                            parsedStat[idxQuestion].results = {}
                                            parsedStat[
                                                idxQuestion
                                            ].results.value = {}
                                        }
                                        if (
                                            parsedStat[idxQuestion].results
                                                .value[dday] === undefined ||
                                            parsedStat[idxQuestion].results
                                                .value[dday].length === 0
                                        ) {
                                            parsedStat[
                                                idxQuestion
                                            ].results.value[dday] = [] // initiate a complete week/month/year
                                        }
                                    }
                                    parsedStat[idxQuestion].ticksMode = 'date'
                                    parsedStat[idxQuestion].dvType = 'linechart'
                                    parsedStat[idxQuestion].dvDisplay =
                                        'vertical'
                                    parsedStat[idxQuestion].type = {}
                                    parsedStat[idxQuestion].type.value =
                                        'corpo-survey'
                                    parsedStat[idxQuestion].question = {}
                                    parsedStat[idxQuestion].question.value =
                                        question
                                    parsedStat[idxQuestion].results.value[day] =
                                        sortObject(oquestion) // push linechart data for week/month/year display
                                    //
                                    idxQuestion++ // push successively linechart then barchart
                                    if (parsedStat[idxQuestion] === undefined) {
                                        questionDatas = {}
                                        questionDatas.results = {}
                                        questionDatas.type = {}
                                        questionDatas.type.value =
                                            'corpo-survey'
                                        questionDatas.dvType = 'barchart'
                                        questionDatas.dvDisplay = 'horizontal'
                                        questionDatas.ticksMode = 'normal'
                                    } else {
                                        questionDatas = parsedStat[idxQuestion]
                                    }
                                    let values = []
                                    let idxValue = 0
                                    for (var answer in oquestion) {
                                        // iterate on answer of each question in current day
                                        let oanswer = oquestion[answer]
                                        if (values[idxValue] === undefined) {
                                            values[idxValue] = {}
                                            values[idxValue].label = answer
                                            values[idxValue].value = 0
                                        }
                                        values[idxValue].value +=
                                            values[idxValue].value + oanswer

                                        idxValue++
                                    }
                                    questionDatas.results.value = values
                                    parsedStat[idxQuestion] = questionDatas // push barchart data for percent display
                                    idxQuestion++
                                }
                                //
                            }
                        }
                        actions.setLoadingData(false)
                        actions.setStatistics(parsedStat)
                    })
            } else {
                state
                    .target(state.particularKind, state.document.id.value)
                    .then((res) => {
                        let statistics = deepCopy(state.originalStatistics)
                        if (res.data[0] !== undefined) {
                            for (var stat in statistics) {
                                if (
                                    res.data[0][stat] !== undefined &&
                                    res.data[0][stat] !== null
                                ) {
                                    statistics[stat].value = res.data[0][stat]
                                }
                            }
                        }
                        statistics.dvType = 'barchart'
                        statistics.ticksMode = 'normal'
                        actions.setLoadingData(false)
                        actions.setStatistics([statistics])
                    })
            }
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setOriginalStatistics: (newState) => (state) => ({
            originalStatistics: newState,
        }),
        setStatistics: (newState) => (state) => ({
            statistics: newState,
        }),
        setSubmitting: (newState) => (state) => ({
            formIsSubmitting: newState,
        }),
        // dependencies : js/d3.v5.min.js, js/FileSaver.2.0.0.min.js, js/canvas-to-blob.3.14.0.min.js, js/jspdf.1.4.1.min.js
        // from:http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177, https://mrrio.github.io/
        getPDF: (kind) => (state, actions) => {
            let filenamePrefix = '',
                title = '',
                respLabel = ''
            let refDate = null,
                startDate = null,
                endDate = null
            if (kind === 'corpo-survey') {
                filenamePrefix = 'Sondage'
                refDate = state.document.original.date.date
                startDate = getYMDfromDateObj(refDate, true)
                title = t('Sondage') + ' ' + state.document.name.value
                respLabel =
                    state.document.original.countResponse + ' ' + t('réponses')
            } else {
                filenamePrefix = 'Evaluation'
                refDate = state.document.startDate.value.date
                startDate = getYMDfromDateObj(refDate, true)
                refDate = state.document.endDate.value.date
                endDate = getYMDfromDateObj(refDate, true)
                title = t('Question') + ' ' + state.document.title.value.fr
                respLabel =
                    state.statistics[0].results.value
                        .map((m) => m.value)
                        .reduce((sum, current) => sum + current, 0) +
                    ' ' +
                    t('réponses')
            }
            //
            let labelWeekLabel = t('Semaine du') + ' ' + startDate
            if (endDate !== null) {
                labelWeekLabel += ' ' + t('au') + ' ' + endDate
            }
            let subtitle = respLabel + ' - ' + labelWeekLabel
            let filename =
                filenamePrefix +
                '-' +
                state.document.name.value +
                '-' +
                getYMDfromDateObj(null, true).replace(/\//g, '_')
            //
            // eslint-disable-next-line
            state.pdfDoc = new jsPDF('landscape');
            actions.exportPdfVisuD3({
                svgParent: 'pdf-marker',
                filename: filename,
                title: title,
                subtitle: subtitle,
            })
        },
        exportPdfVisuD3:
            ({ svgParent, width, height, filename, title, subtitle }) =>
            (state, actions) => {
                let parent = document.getElementById(svgParent)
                let svgChildren = parent.querySelectorAll('svg')
                let svgChildNumber = svgChildren.length
                svgChildren.forEach((svg, idx) => {
                    setTimeout(() => {
                        let width = svg.width.animVal.value
                        let height = svg.height.animVal.value
                        svg = d3.select(svg)
                        actions.getSVGString({ svgNode: svg.node() })
                        actions.svgString2Image({
                            svgString: state.svgString,
                            width: width,
                            height: height,
                            callback: save,
                        }) // passes Blob and filesize String to he callback
                    }, 100 * idx)
                })
                function save(dataBlob, filesize, canvas) {
                    let imageSrcJpeg = canvas.toDataURL('image/jpeg')
                    let doc = state.pdfDoc
                    svgChildNumber--
                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(25)
                    doc.text(30, 20, title)
                    doc.setFontSize(16)
                    doc.text(30, 30, subtitle)
                    if (svgChildNumber > 0) {
                        let questionIndex =
                            state.statistics.length - (svgChildNumber + 1)
                        let question = state.statistics[questionIndex].question
                        if (question !== undefined) {
                            doc.setFontSize(20)
                            doc.setTextColor(107, 149, 171)
                            doc.text(
                                30,
                                45,
                                t('Question') +
                                    ' #' +
                                    (questionIndex / 2 + 1) +
                                    ' : ' +
                                    question.value
                            )
                        }
                    }
                    doc.addImage(imageSrcJpeg, 'JPEG', 35, 60)
                    // Optional - set properties on the document
                    // doc.setProperties({
                    //     title: 'Title',
                    //     subject: 'This is the subject',
                    //     author: 'James Hall',
                    //     keywords: 'generated, javascript, web 2.0, ajax',
                    //     creator: 'MEEE'
                    // });
                    if (svgChildNumber === 0) {
                        doc.save(filename + '.pdf')
                    } else {
                        doc.addPage()
                    }
                }
            },
        getSVGString:
            ({ svgNode, extraCss = false }) =>
            (state, actions) => {
                let refSvgParentId = '.admindataviz' // mandatory to retrieve usefull css, discard refSvgParentId corresponding css
                //
                let svnNodeCopy = svgNode.cloneNode(true)
                svnNodeCopy.setAttribute(
                    'xlink',
                    'http://www.w3.org/1999/xlink'
                )
                actions.svgGetCSSStyles(refSvgParentId)
                if (extraCss && typeof extraCss === 'string') {
                    state.extractedCSSText += ' ' + extraCss
                }
                actions.svgAppendCSS({
                    cssText: state.extractedCSSText,
                    element: svnNodeCopy,
                })

                var serializer = new XMLSerializer()
                var svgString = serializer.serializeToString(svnNodeCopy)
                svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=') // Fix root xlink without namespace
                svgString = svgString.replace(/NS\d+:href/g, 'xlink:href') // Safari NS namespace fix
                // eslint-disable-next-line
            svgString = svgString.replace(/\#/g, '%23');
                state.svgString = svgString
            },
        svgAppendCSS:
            ({ cssText, element }) =>
            (state, actions) => {
                var styleElement = document.createElement('style')
                styleElement.setAttribute('type', 'text/css')
                styleElement.innerHTML = cssText
                var refNode = element.hasChildNodes()
                    ? element.children[0]
                    : null
                element.insertBefore(styleElement, refNode)
            },
        svgGetCSSStyles: (refSvgParentId) => (state, actions) => {
            var selectorTextArr = [refSvgParentId]
            var i = null
            // Extract CSS Rules
            var extractedCSSText = ''
            for (i = 0; i < document.styleSheets.length; i++) {
                var s = document.styleSheets[i]
                try {
                    if (!s.cssRules) continue
                } catch (e) {
                    if (e.name !== 'SecurityError') throw e // for Firefox
                    continue
                }

                var cssRules = s.cssRules
                for (var r = 0; r < cssRules.length; r++) {
                    if (contains(cssRules[r].selectorText, selectorTextArr))
                        extractedCSSText += cssRules[r].cssText.replace(
                            refSvgParentId,
                            ''
                        )
                }
            }
            // return extractedCSSText;
            state.extractedCSSText = extractedCSSText

            function contains(str, arr) {
                // return arr.indexOf( str ) === -1 ? false : true;
                for (var ref in arr) {
                    if (str !== undefined && str.indexOf(arr[ref]) > -1) {
                        return true
                    }
                }
                return false
            }
        },
        svgString2Image:
            ({ svgString, width, height, callback, dataCallback = null }) =>
            (state, actions) => {
                // var imgsrc = 'data:image/svg+xml;charset=utf8,' + svgString;
                var imgsrc = 'data:image/svg+xml,' + svgString
                var canvas = document.createElement('canvas')
                var context = canvas.getContext('2d')

                canvas.width = width
                canvas.height = height

                var image = new Image()
                image.onload = function () {
                    context.clearRect(0, 0, width, height)
                    context.drawImage(image, 0, 0, width, height)
                    canvas.toBlob(function (blob) {
                        var filesize = Math.round(blob.length / 1024) + ' KB'
                        if (callback)
                            callback(blob, filesize, canvas, dataCallback)
                    })
                }
                image.onerror = function () {
                    alert(t('Echec de l‘export PDF sur ce navigateur'))
                }
                image.src = imgsrc
            },
        updatePeriod: (mode) => (state, actions) => {
            state.mode = mode
            actions.updateAllDataViz()
        },
        updateSelector: (date) => (state, actions) => {
            actions.setRefDate(date)
            actions.updateAllDataViz()
        },
        setRefDate: (newState) => (state) => ({
            refDate: newState,
        }),
        setTarget: (newState) => (state) => ({
            target: newState,
        }),
        setParticularKind: (newState) => (state) => ({
            particularKind: newState,
        }),
        setLoadingData: (newState) => (state) => ({
            loadingData: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class={'btzAdminForm btzAdminStats'}>
            {state.document !== null && (
                <Form
                    classes={'col-xs-12 sup-custom-form btzForm'}
                    onsubmit={(event) => event.preventDefault()}
                >
                    <Form.Group
                        classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                        style={{ 'text-align': 'left' }}
                    >
                        <div class="col-6 p-0">
                            {props.header && (
                                <p class="sup-title">
                                    {props.header[0]}
                                    <font>
                                        {props.header.length > 1
                                            ? ' > ' + props.header[1]
                                            : ''}
                                    </font>
                                </p>
                            )}
                        </div>
                        <div class="col-12 p-0">
                            {state.previousPage && (
                                <p class="sup-title">
                                    {t('Revenir') + ' '}
                                    {state.previousPage.map((pp) => (
                                        <dummy
                                            style={{
                                                'margin-right': '5px',
                                                'cursor': 'pointer',
                                                'text-decoration': 'underline',
                                            }}
                                            onclick={() => {
                                                if (pp.url !== null) {
                                                    window.main.location.go(
                                                        pp.url
                                                            .replace(
                                                                ':id',
                                                                state.refId
                                                            )
                                                            .replace(
                                                                ':sid',
                                                                state.refSId
                                                            )
                                                    )
                                                    // window.location.href = pp.url.replace(':id', state.refId).replace(':sid', state.refSId);
                                                } else {
                                                    window.history.back()
                                                }
                                            }}
                                        >
                                            {'< ' + pp.label}
                                        </dummy>
                                    ))}
                                </p>
                            )}
                        </div>
                    </Form.Group>

                    <div key={'main-form'}>
                        {Object.keys(state.document)
                            .filter(
                                (f) =>
                                    state.document[f].type !== 'translation' &&
                                    state.document[f].field !== 'translation' &&
                                    state.document[f].field !== 'tabs'
                            )
                            .map((key) => (
                                <dummy>
                                    {(state.document[key].dependsOn ===
                                        undefined ||
                                        (state.document[key].dependsOn !==
                                            undefined &&
                                            state.document[
                                                state.document[key].dependsOn
                                                    .field
                                            ].value ===
                                                state.document[key].dependsOn
                                                    .value)) && (
                                        <dummy>
                                            {['name'].indexOf(
                                                state.document[key].name
                                            ) > -1 && (
                                                <div
                                                    class={
                                                        'sup-group-form ' +
                                                        'input-' +
                                                        state.document[key]
                                                            .name +
                                                        ' ' +
                                                        (state.document[key]
                                                            .display &&
                                                        state.document[key]
                                                            .display.border ===
                                                            'bottom'
                                                            ? 'border-bottom'
                                                            : '') +
                                                        ' ' +
                                                        (state.document[key]
                                                            .display &&
                                                        state.document[key]
                                                            .display.mode ===
                                                            'half'
                                                            ? 'col-6 col-xs-12'
                                                            : 'col-12') +
                                                        (state.formSubmitted &&
                                                        state.document[key]
                                                            .validation !==
                                                            undefined &&
                                                        state.document[key]
                                                            .validation
                                                            .isValid === false
                                                            ? ' field-is-in-error'
                                                            : '')
                                                    }
                                                    style={{
                                                        display:
                                                            state.document[key]
                                                                .field ===
                                                            'hidden'
                                                                ? 'none'
                                                                : 'inline-block',
                                                    }}
                                                >
                                                    {state.document[key]
                                                        .label !== '' &&
                                                        state.document[key]
                                                            .label !==
                                                            undefined && (
                                                            <div
                                                                style={{
                                                                    'display':
                                                                        'inline-block',
                                                                    'vertical-align':
                                                                        'top',
                                                                }}
                                                            >
                                                                <label class="sup-label">
                                                                    {state
                                                                        .document[
                                                                        key
                                                                    ].label +
                                                                        (state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .validation !==
                                                                            undefined &&
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .validation
                                                                            .mandatory ===
                                                                            true
                                                                            ? '*'
                                                                            : '')}
                                                                </label>
                                                                <AdminFormHelp
                                                                    type={
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ].type
                                                                    }
                                                                ></AdminFormHelp>
                                                            </div>
                                                        )}
                                                    <input
                                                        key={
                                                            'field-' +
                                                            state.document[key]
                                                                .name
                                                        }
                                                        type={
                                                            state.document[key]
                                                                .field
                                                        }
                                                        name={key}
                                                        placeholder={
                                                            state.document[key]
                                                                .placeholder
                                                        }
                                                        value={
                                                            state.document[key]
                                                                .value
                                                        }
                                                        readonly
                                                    />
                                                </div>
                                            )}
                                        </dummy>
                                    )}
                                </dummy>
                            ))}
                        {state.particularKind === 'corpo-survey' &&
                            state.refDate !== null && (
                                <DatePicker
                                    date={state.refDate}
                                    periodAction={actions.updatePeriod}
                                    selectorAction={actions.updateSelector}
                                ></DatePicker>
                            )}
                        {state.statistics !== null &&
                        ((Array.isArray(state.statistics) &&
                            state.statistics.length > 0) ||
                            !Array.isArray(state.statistics)) ? (
                            <dummy
                                id="pdf-marker"
                                key={state.refDate + '-' + state.mode}
                                style={{
                                    'display': 'block',
                                    'margin-top': '25px',
                                }}
                            >
                                {state.statistics.map((stats, idx) => (
                                    <div key={state.refDate + '-' + state.mode}>
                                        <AdminDataviz
                                            key={
                                                state.refDate + '-' + state.mode
                                            }
                                            idx={idx}
                                            mode={state.mode}
                                            data={stats}
                                            refDate={state.refDate}
                                        ></AdminDataviz>
                                    </div>
                                ))}
                            </dummy>
                        ) : (
                            <dummy key={state.refDate + '-' + state.mode}>
                                <p
                                    class=""
                                    style={{
                                        'text-align': 'center',
                                        'font-size': '18px',
                                        'padding': '25px',
                                    }}
                                >
                                    {state.loadingData ? (
                                        <dummy
                                            style={{
                                                'text-align': 'center',
                                                'font-size': '18px',
                                                'padding': '25px',
                                            }}
                                        >
                                            {t(
                                                'Chargement de la donnée en cours ...'
                                            )}
                                        </dummy>
                                    ) : (
                                        <dummy
                                            style={{
                                                'text-align': 'center',
                                                'font-size': '18px',
                                                'padding': '25px',
                                            }}
                                        >
                                            {t(
                                                'Aucune donnée disponible sur cette période !'
                                            )}
                                        </dummy>
                                    )}
                                </p>
                            </dummy>
                        )}
                    </div>

                    <Form.Group classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits">
                        <Button
                            primary
                            flat
                            cancel
                            onclick={() => {
                                window.location.href = state.backUrl
                            }}
                        >
                            {t('Retour')}
                        </Button>
                        <Button
                            primary
                            flat
                            active
                            loading={state.formIsSubmitting}
                            onclick={() => actions.getPDF(state.particularKind)}
                        >
                            {t('Export PDF')}
                        </Button>
                    </Form.Group>
                </Form>
            )}
        </div>
    ),
    'adminstats'
)

export { AdminStats }

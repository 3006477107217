import { h, Component } from '@app/utils'
import './index.scss'

import icoValid from '@app/img/picto-tick.png'
import icoValidx2 from '@app/img/picto-tick@2x.png'

const AFCheckBox = Component(
    {
        checkbox: {},
        checkedboxs: [],
        name: '',
        img: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.name) {
                state.name = props.name
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.checkbox.img) {
                state.img = props.checkbox.img
            }
            if (props.checkbox) {
                actions.setCheckBox(props.checkbox)
            }
            if (props.checkedboxs) {
                actions.setCheckedBoxs(props.checkedboxs)
            }
        },
        setCheckedBox: (newState) => (state) => ({
            checkedboxs: newState,
        }),
        setCheckBox: (newState) => (state) => ({
            checkbox: newState,
        }),
        setCheckedBoxs: (newState) => (state) => ({
            checkedboxs: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div
            key={props.key}
            class={'sup-group-checkbox length-' + state.checkedboxs.length}
        >
            {state.img === null ? (
                <label
                    class="control checkbox"
                    style={{ 'padding-left': '0px' }}
                >
                    <input
                        type="checkbox"
                        name={state.name}
                        id=""
                        class={
                            'to-check check-checked is-retina is-' +
                            (state.checkedboxs.indexOf(state.checkbox.id) >
                                -1 ||
                            state.checkedboxs.indexOf(+state.checkbox.id) > -1
                                ? 'checked'
                                : '')
                        }
                        value={state.checkbox.id}
                        checked={
                            state.checkedboxs.indexOf(state.checkbox.id) > -1 ||
                            state.checkedboxs.indexOf(+state.checkbox.id) > -1
                                ? 'checked'
                                : ''
                        }
                        onclick={(el) => {
                            props.onclick(el)
                        }}
                    />
                    <span class="control-indicator"></span>
                </label>
            ) : (
                <label class="sup-group-checkbox-image">
                    <input
                        type="hidden"
                        name={state.name}
                        value={state.checkbox.id}
                        checked={
                            state.checkedboxs.indexOf(+state.checkbox.id) > -1
                                ? 'checked'
                                : ''
                        }
                    />
                    <img
                        src={state.img}
                        srcset={state.img + ' 2x'}
                        alt=""
                        class=""
                        onclick={(el) => {
                            props.onclick(el)
                        }}
                        style={{
                            display:
                                state.checkedboxs.indexOf(+state.checkbox.id) >
                                -1
                                    ? 'none'
                                    : 'inline-block',
                        }}
                        data-name={state.name}
                        data-value={state.checkbox.id}
                        data-checked="true"
                    />
                    <img
                        src={icoValid}
                        srcset={icoValidx2 + ' 2x'}
                        alt=""
                        class="img-selected"
                        onclick={(el) => {
                            props.onclick(el)
                        }}
                        style={{
                            display:
                                state.checkedboxs.indexOf(+state.checkbox.id) >
                                -1
                                    ? 'inline-block'
                                    : 'none',
                        }}
                        data-name={state.name}
                        data-value={state.checkbox.id}
                        data-checked="false"
                    />
                </label>
            )}
            {state.checkbox.name !== '' && (
                <label class="sup-input-label sup-input-label-customers">
                    {state.checkbox.name}
                </label>
            )}
        </div>
    ),
    'afcheckbox'
)

export { AFCheckBox }

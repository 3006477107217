import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import './index.scss'

const AFExtra = Component(
    {
        document: null,
        sendToParent: null,
        ulDisplayed: false,
        list: [],
        name: '',
        model: { key: null, value: null, order: 'order' },
        currentSearch: '',
        readOnly: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.list) {
                state.list = props.list
            }
            if (props.document) {
                actions.setDocument(props.document)
            }
            if (props.model) {
                actions.setModel(props.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                let document = state.document
                if (document.value === undefined) {
                    document.value = {}
                }
                document.value[name] = value
                actions.toggleUl(true)
                actions.setDocument(document)
                state.sendToParent()
            },
        setOrder: (order) => (state, actions) => {
            let document = state.document
            document.order = order
            actions.setDocument(document)
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        waitForLastInput: ({ target }) => debounce(240, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <div
                class="sup-custom-select-delete"
                onclick={(el) => {
                    props.ondelete(el)
                }}
            >
                <p class="">{t('Supprimer')}</p>
            </div>
            <div style={{ display: 'inline-block', width: '50%' }}>
                <input
                    type="text"
                    name={'title'}
                    oninput={actions.waitForLastInput}
                    placeholder={props.placeholder.title}
                    value={
                        state.document.value !== undefined
                            ? state.document.value.title
                            : ''
                    }
                />
                <div class="sup-textarea" style={{ margin: '0px' }}>
                    <textarea
                        name={'description'}
                        oninput={actions.waitForLastInput}
                        placeholder={props.placeholder.description}
                    >
                        {state.document.value !== undefined
                            ? state.document.value.content
                            : ''}
                    </textarea>
                </div>
            </div>
        </div>
    ),
    'afextra'
)

export { AFExtra }

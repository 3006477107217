import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const corpocitation_CARD = {
    filter: ['status', 'frequency'],
    tableHead: [
        { key: 'name', label: 'ID' },
        { key: 'description', label: 'Citation' },
        { key: 'frequency', label: 'Fréquence' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/citations/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/citations/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editCard,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteCard,
        },
    ],
    tableAllOptions: {
        label: 'MOTS DU RH SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteCard,
            },
        ],
    },
    header: ['Configurer la citation'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'citation',
        },
        type: {
            field: 'hidden',
            value: '20',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activation',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            display: {
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la citation',
            field: 'text',
            mandatory: true,
            placeholder: "Renseignez ici l'id de la citation",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: "Veuillez renseigner l'id de la citation",
                i18nMessage: t("Veuillez renseigner l'id de la citation"),
            },
        },
        frequency: {
            name: 'frequency',
            type: 'int',
            label: 'Fréquence',
            field: 'dropdown',
            list: constants.custo.FREQUENCY,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        flags: {
            field: 'hidden',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            field: 'hidden',
            value: { fr: true },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Auteur',
            field: 'text',
            placeholder: 'Renseignez ici l‘auteur de la citation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner l‘auteur de la citation',
                i18nMessage: t('Veuillez renseigner l‘auteur de la citation'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Citation',
            field: 'textarea',
            placeholder: 'Renseignez ici la citation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la citation',
                i18nMessage: t('Veuillez renseigner la citation'),
            },
        },
    },
}

export { corpocitation_CARD }

let settings = {
    APP_WEB_URL: process.env.APP_WEB_URL,
    APP_FRONT_WEB_URL: process.env.APP_FRONT_WEB_URL,
    APP_BUILD_ERROR_SIMULATION: process.env.APP_BUILD_ERROR_SIMULATION,
    APP_CDN_URL: process.env.APP_CDN_URL,
    APP_API_URL: process.env.APP_API_URL,
    APP_CUSTOMISATION: process.env.APP_CUSTOMISATION,
    APP_L10N: process.env.APP_L10N,
    APP_LANG: process.env.APP_LANG,
    APP_FALLBACKLANG: process.env.APP_FALLBACKLANG,
    APP_DEBUG: process.env.APP_DEBUG,
    LANGUAGES: { fr: 'Français', en: 'Anglais' },
    METRICS: {
        '0-0': 'kg / cm',
        '0-1': 'kg / feet',
        '1-0': 'stones / cm',
        '1-1': 'stones / feet',
        '2-0': 'pounds / cm',
        '2-1': 'pounds / feet',
    },
}

if (window.hasRuntimeEnv) {
    const runtimeEnvSettings = JSON.parse(
        JSON.stringify(window.appRuntimeEnv.settings)
    )
    settings = { ...settings, ...runtimeEnvSettings }
}

export { settings }

import { t, h, Component } from '@app/utils'
import { User } from '@app/api'
import {
    allRoutes,
    addCSRFToken,
    createUTCDate,
    isDefined,
    deepCopy,
} from '@app/core'
import './index.scss'

import { AdminFormListingMail } from '@app/elements'

const AdminFormListing = Component(
    {
        kind: '',
        placeholder: '',
        list: [],
        maxChildren: 0,
        userid: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            // console.log('CRATE UTC : ', createUTCDate('2019-10-24 14:30:45.954000', false).toLocaleString());
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document.name) {
                state.placeholder = props.document.placeholder
                actions.setKind(props.document.name)
                if (props.document.name === 'sponsored') {
                    if (props.original !== undefined) {
                        actions.setMaxChildren(props.original.maxChildren)
                        actions.setUserId(props.original.id)
                    }
                }
            }
            if (props.document.value) {
                var val = ''
                if (
                    props.document.name === 'list-composition' &&
                    props.original !== undefined
                ) {
                    for (val in props.original) {
                        let search = props.document.value.filter(
                            (f) => f.key == val
                        )[0]
                        if (search && isDefined(search)) {
                            search.value = props.original[val]
                        }
                    }
                    actions.setList(props.document.value)
                } else if (
                    props.document.name === 'list-kpis' &&
                    props.original !== undefined
                ) {
                    actions.setList(props.original.kpis)
                } else if (
                    props.document.name === 'sponsored' &&
                    props.original !== undefined
                ) {
                    actions.setList(props.original.sponsored)
                } else {
                    let listValue = deepCopy(props.document.value)
                    for (val in listValue) {
                        if (listValue[val].date !== undefined) {
                            listValue[val].date.date = createUTCDate(
                                listValue[val].date.date,
                                true
                            ).toLocaleString()
                        }
                    }
                    actions.setList(listValue)
                }
            }
        },
        addIntoList: (newEntry) => (state, actions) => {
            let existingList = state.list
            existingList.push(newEntry)
            actions.setList(existingList)
        },
        deleteChildrenMail: (email) => (state, actions) => {
            User.removeChildren(state.userid, email).then(() => {
                let existingList = state.list
                existingList.splice(existingList.indexOf(email), 1)
                actions.setList(existingList)
            })
        },
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setMaxChildren: (newState) => (state) => ({
            maxChildren: newState,
        }),
        setUserId: (newState) => (state) => ({
            userid: newState,
        }),
        setKind: (newState) => (state) => ({
            kind: newState,
        }),
        getTrackerDate: ({ el, dat }) => {
            if (!isDefined(dat)) {
                el.innerHTML =
                    t('Dernière synchronisation') + ' : ' + t('date inconnue')
                return false
            }
            dat = dat.replace(/-/g, '/')
            dat = dat.replace('.000000', '')

            var date = new Date(Date.parse(new Date(dat)))
            var options = { hour: '2-digit', minute: '2-digit' }

            el.innerHTML =
                t('Dernière synchronisation') +
                ' : ' +
                date.toLocaleDateString('fr-FR', options)
        },
    },
    (state, actions) => (props, children) => (
        <div class={'sup-group-listing ' + state.kind}>
            <div>
                {state.kind.indexOf('devices') > -1 && (
                    <ul class={state.kind + '-container'}>
                        <dummy>
                            {Array.isArray(state.list) &&
                            state.list.length > 0 ? (
                                <dummy>
                                    {state.list.map((lst) => (
                                        <li>
                                            {isDefined(lst.date)
                                                ? lst.date.date
                                                : 'Ø'}{' '}
                                            -{' '}
                                            {isDefined(lst.browser)
                                                ? lst.browser
                                                : lst.version}{' '}
                                            -{' '}
                                            {isDefined(lst.os)
                                                ? lst.os
                                                : lst.model +
                                                  ' - ' +
                                                  lst.type +
                                                  ' ' +
                                                  lst.deviceVersion}{' '}
                                            -{' '}
                                            {isDefined(lst.ip)
                                                ? lst.ip + ' -'
                                                : ''}{' '}
                                            {lst.state === 1 ||
                                            lst.state === true
                                                ? t('Autorisé')
                                                : t('Inconnu')}
                                        </li>
                                    ))}
                                </dummy>
                            ) : (
                                <p>{t('Aucun appareil enregistré')}</p>
                            )}
                        </dummy>
                    </ul>
                )}
                {state.kind === 'list-composition' && (
                    <div class={state.kind + '-container'}>
                        <dummy>
                            {Array.isArray(state.list) &&
                                state.list.length > 0 && (
                                    <dummy>
                                        {state.list
                                            .filter((f) => f.displayed === true)
                                            .map((lst) => (
                                                <div>
                                                    {lst.key +
                                                        ' ' +
                                                        lst.unit +
                                                        ' : '}
                                                    <font>{lst.value}</font>
                                                </div>
                                            ))}
                                    </dummy>
                                )}
                        </dummy>
                    </div>
                )}
                {state.kind === 'list-kpis' && (
                    <div class={state.kind + '-container'}>
                        {Array.isArray(state.list) && state.list.length > 0 && (
                            <dummy>
                                {state.list
                                    .filter((f) => f !== '')
                                    .map((lst) => (
                                        <div>
                                            <font>{'• '}</font>
                                            {lst}
                                        </div>
                                    ))}
                            </dummy>
                        )}
                    </div>
                )}
                {state.kind === 'trackers' && (
                    <div class={state.kind + '-container'}>
                        {Array.isArray(state.list) && state.list.length > 0 ? (
                            <dummy>
                                {state.list.map((lst) => (
                                    <dummy style={{ display: 'block' }}>
                                        <input
                                            key={'field-' + lst.name}
                                            type={'text'}
                                            value={lst.name + ' - ' + lst.brand}
                                            readOnly
                                        />
                                        <p
                                            style={{
                                                'display': 'inline-block',
                                                'margin-left': '15px',
                                            }}
                                            oncreate={(el) =>
                                                actions.getTrackerDate({
                                                    el: el,
                                                    dat: isDefined(
                                                        props.original.sensors
                                                    )
                                                        ? props.original.sensors.filter(
                                                              (f) =>
                                                                  f.name.indexOf(
                                                                      lst.brand
                                                                  ) > -1
                                                          )[0].lastUpdate.date
                                                        : null,
                                                })
                                            }
                                        ></p>
                                    </dummy>
                                ))}
                            </dummy>
                        ) : (
                            <p style={{ margin: '5px 0' }}>
                                {t('Aucun objet connecté')}
                            </p>
                        )}
                    </div>
                )}
                {state.kind === 'sponsored' && (
                    <div class={state.kind + '-container'}>
                        {Array.isArray(state.list) && state.list.length > 0 && (
                            <dummy style={{ display: 'block' }}>
                                {state.list
                                    .filter((f) => f !== '')
                                    .map((lst) => (
                                        <dummy>
                                            <input
                                                key={'field-' + lst}
                                                type={'text'}
                                                value={lst}
                                                readOnly
                                            />
                                            <div
                                                class="btn btn-link"
                                                offsetheader="150"
                                                onclick={() => {
                                                    actions.deleteChildrenMail(
                                                        lst
                                                    )
                                                }}
                                                style={{
                                                    'display': 'inline-block',
                                                    'margin-left': '15px',
                                                }}
                                            >
                                                {t('Supprimer')}
                                            </div>
                                        </dummy>
                                    ))}
                            </dummy>
                        )}
                        {[
                            ...Array.from({
                                length: Array.isArray(state.list)
                                    ? state.maxChildren - state.list.length
                                    : state.maxChildren,
                            }),
                        ].map((item, idx) => (
                            <dummy>
                                <AdminFormListingMail
                                    placeholder={state.placeholder}
                                    item={item}
                                    idx={idx}
                                    userid={state.userid}
                                    addIntoList={actions.addIntoList}
                                ></AdminFormListingMail>
                            </dummy>
                        ))}
                        {(state.maxChildren === 0 ||
                            state.maxChildren === null) && (
                            <dummy>
                                {props.original !== undefined &&
                                props.original.parent !== null &&
                                props.original.parent.email !== undefined ? (
                                    <dummy class="sponsor-parent">
                                        <p>
                                            {t(
                                                'Cet utilisateur utilise une licence secondaire de'
                                            )}
                                        </p>{' '}
                                        <p
                                            class="sponsor-parent-email"
                                            onclick={() => {
                                                window.location.href =
                                                    addCSRFToken(
                                                        allRoutes[
                                                            'private.users.users.edit'
                                                        ].pathname.replace(
                                                            ':id',
                                                            props.original
                                                                .parent.id
                                                        )
                                                    )
                                            }}
                                        >
                                            {props.original.parent.email}
                                        </p>
                                    </dummy>
                                ) : (
                                    <p style={{ margin: '5px 0' }}>
                                        {t('Aucun filleul autorisé')}
                                    </p>
                                )}
                            </dummy>
                        )}
                    </div>
                )}
            </div>
        </div>
    ),
    'adminformlisting'
)

export { AdminFormListing }

import { t, h, Component } from '@app/utils'
import { Pdfs, Card } from '@app/api'
import { isDefined, markedDownContent } from '@app/core'
import { Form, Button } from '@app/elements'
import './index.scss'

import * as constants from '@app/constants'

const state = {
    //
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
}

const view = (state, actions) => (props, children) => (
    <div class="modalWrapper">
        <p class="modalTitle">{t('Prévisualisation de votre description')}</p>
        <p class="modalSubTitle">
            {t('Vous visualisez le rendu final de votre document.')}
        </p>
        <div class="btzAdminForm">
            <Form
                classes={'col-xs-12 sup-custom-form btzForm'}
                onsubmit={(event) => event.preventDefault()}
                autocomplete="off"
            >
                <Form.Group classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits">
                    <div
                        class="modalPreviewContent"
                        innerHTML={markedDownContent(props.value)}
                    ></div>

                    <div class="modalFooter">
                        <Button
                            primary
                            flat
                            cancel
                            onclick={() => {
                                props.closeModalPreview()
                            }}
                        >
                            {t('annuler')}
                        </Button>
                    </div>
                </Form.Group>
            </Form>
        </div>
    </div>
)

export default Component(state, actions, view, 'modalpreviewmarkdown')

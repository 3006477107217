import { h, Component } from '@app/utils'
import { AFMedia } from '@app/elements'
import './index.scss'

const AdminFormMedia = Component(
    {
        document: null,
        sendToParent: null,
        name: '',
        media: [],
        lang: null,
        index: 0,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document && props.document.name) {
                state.name = props.document.name
            }
            if (props.name) {
                state.name = props.name
            }
            if (props.oninput) {
                state.sendToParent = props.oninput
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.index) {
                state.index = props.index
            }
        },
        onComponentUpdate: (props) => (state, actions) => {},
    },
    (state, actions) => (props, children) => (
        <div>
            <AFMedia
                document={props.document}
                name={state.name}
                lang={state.lang}
                index={state.index}
                onclick={props.onclick}
                desabled={props.desabled}
                cantChooseMedia={props.cantChooseMedia}
                particularity={props.particularity}
            ></AFMedia>
        </div>
    ),
    'adminformmedia'
)

export { AdminFormMedia }

import { h, classMerge } from '@app/utils'

const Element =
    (tag) =>
    ({ classes = [], ...otherProps }, children) =>
        h(
            tag,
            {
                class: classMerge(classes) || false,
                ...otherProps,
            },
            children
        )

export { Element }

import { t, h, Component } from '@app/utils'
import { Form, AFEvalMaker, Button } from '@app/elements'
import { isDefined } from '@app/core'
import './index.scss'

const AdminFormEvalMaker = Component(
    {
        sendToParent: null,
        onSynchEachLanguage: null,
        onSynchEachLanguageTag: null,
        name: '',
        formName: 'forms',
        lang: '',
        list: [],
        evals: [],
        forcedtag: '',
        formsType: '',
        evalsmodel: {
            button: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
            yesno: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
            slider: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
            qcm: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
            textarea: {
                key: 'name',
                value: 'value',
                tag: 'tag',
                order: 'order',
            },
            cryptedweight: {
                key: 'name',
                value: 'value',
                tag: 'tag',
                order: 'order',
                min: 'min',
                max: 'max',
                default: 'default',
            },
            bloodpressure: {
                key: 'name',
                value: 'value',
                tag: 'tag',
                order: 'order',
                min: 'min',
                max: 'max',
                default: 'default',
            },
            cryptedtemperature: {
                key: 'name',
                value: 'value',
                tag: 'tag',
                order: 'order',
                min: 'min',
                max: 'max',
                default: 'default',
            },
            heartbeat: {
                key: 'name',
                value: 'value',
                tag: 'tag',
                order: 'order',
                min: 'min',
                max: 'max',
                default: 'default',
            },
        },
        modelList: null,
        specialDisplay: false,
        order: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (isDefined(props.document)) {
                if (props.document.name) {
                    state.name = props.document.name
                }
            } else {
                if (props.name) {
                    state.name = props.name
                }
            }
            if (props.formName) {
                state.formName = props.formName
            }
            if (props.specialDisplay) {
                state.specialDisplay = props.specialDisplay
            }
            if (props.evalsmodel) {
                state.evalsmodel = props.evalsmodel
            }
            if (props.forcedtag) {
                state.forcedtag = props.forcedtag
            }
            if (props.onSynchEachLanguage) {
                state.onSynchEachLanguage = props.onSynchEachLanguage
            }
            if (props.onSynchEachLanguageTag) {
                state.onSynchEachLanguageTag = props.onSynchEachLanguageTag
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.list) {
                actions.parseAndSetList(props.list)
            }
            if (props.order !== '') {
                actions.setOrder(props.order)
            }
            // if (props.formsType) {
            //     actions.setFormsType(props.formsType);
            // }
            if (props.oninput) {
                actions.setSendToParent(props.oninput)
                // state.sendToParent = props.oninput;
            }
            // if (props.formsType) {
            //     actions.setFormsType(props.formsType);
            // }
            actions.updateEvals(props)
            actions.setClinicalEvals(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.list) {
                actions.parseAndSetList(props.list)
            }
            // if (props.formsType) {
            //     actions.setFormsType(props.formsType);
            // }
            actions.setClinicalEvals(props)
            if (
                props.name === 'responses' ||
                props.specialDisplay === 'quizz' ||
                props.specialDisplay === 'yesno'
            ) {
                actions.updateEvals(props)
            }
        },
        setClinicalEvals: (props) => (state, actions) => {
            if (props.formsType.toLowerCase() === 'bloodpressure') {
                if (
                    state.formsType !== '' &&
                    props.formsType !== state.formsType
                ) {
                    state.evals = []
                }
                if (props.formsType) {
                    actions.setFormsType(props.formsType)
                }
                if (state.evals.length === 0) {
                    actions.addEvalEntry('BLOODPRESSURE_SYSTOLIC')
                    actions.addEvalEntry('BLOODPRESSURE_DIASTOLIC')
                    state.onSynchEachLanguageTag({
                        lang: state.lang,
                        evals: state.evals,
                    })
                }
            } else if (
                ['cryptedweight', 'cryptedtemperature', 'heartbeat'].indexOf(
                    props.formsType.toLowerCase()
                ) > -1
            ) {
                if (
                    state.formsType !== '' &&
                    props.formsType !== state.formsType
                ) {
                    state.evals = []
                }
                if (props.formsType) {
                    actions.setFormsType(props.formsType)
                }
                if (state.evals.length === 0) {
                    actions.addEvalEntry(
                        props.formsType.toUpperCase() + '_VALUE'
                    )
                    //actions.addEvalEntry((props.formsType.toLowerCase() === 'cryptedweight') ? 'CRYPTEDWEIGHT_VALUE' : 'CRYPTEDTEMPERATURE_VALUE');
                    state.onSynchEachLanguageTag({
                        lang: state.lang,
                        evals: state.evals,
                    })
                }
            } else {
                if (props.formsType) {
                    actions.setFormsType(props.formsType)
                }
            }
        },
        updateEvals: (props) => (state, actions) => {
            let evl = [],
                nevl = null
            if (props.specialDisplay === 'quizz') {
                if (
                    props.document &&
                    isDefined(props.document) &&
                    props.document.length > 0
                ) {
                    let newEval = props.document
                    for (nevl in newEval) {
                        if (!isDefined(newEval[nevl].goodResponse)) {
                            newEval[nevl].goodResponse = 0
                        }
                        if (+newEval[nevl].value === 0) {
                            newEval[nevl].choice = { Faux: 0 }
                        } else {
                            newEval[nevl].choice = { Vrai: 1 }
                        }
                    }
                    evl = newEval
                } else {
                    evl.push({
                        name: t('Vrai'),
                        value: 1,
                        tag: 'dd',
                        goodResponse: 0,
                        order: 0,
                        choice: { Vrai: 1 },
                    })
                    evl.push({
                        name: t('Faux'),
                        value: 0,
                        tag: 'dd',
                        goodResponse: 0,
                        order: 1,
                        choice: { Faux: 0 },
                    })
                }
                evl.sort((a, b) => {
                    if (a.order >= b.order) {
                        return 1
                    }
                    return -1
                })
            } else if (props.formsType === 'Yesno') {
                if (
                    props.document &&
                    isDefined(props.document) &&
                    props.document.length > 0
                ) {
                    /* force a re-order to have value 4 at order 0
                    reason: the default behaviour of admin was to have value 0 at order 0
                    but cards are created by batch, and the batch set the value 0 at order 1
                    that result with value 0 and 4 at the same order,
                    resulting with wrong value sended and/or inverted button on patient
                    */
                    const evl4 = props.document.find((evl) => +evl.value === 4)
                    const evl0 = props.document.find((evl) => +evl.value === 0)
                    evl.push({ ...evl4, order: 0 })
                    evl.push({ ...evl0, order: 1 })
                } else {
                    evl.push({ name: '', value: 4, tag: '', order: 0 })
                    evl.push({ name: '', value: 0, tag: '', order: 1 })
                }
                evl.sort((a, b) => {
                    if (a.order >= b.order) {
                        return 1
                    }
                    return -1
                })
                actions.setModelList({ key: 'name', value: 'value' })
            } else {
                if (props.document && isDefined(props.document)) {
                    evl = props.document
                    for (nevl in evl) {
                        if (state.forcedtag !== '') {
                            evl[nevl].tag = state.forcedtag
                        }
                    }
                }
            }
            actions.setEvals(evl)
        },
        addEvalEntry:
            (customtag = '') =>
            (state, actions) => {
                let evals = state.evals
                let newEval = JSON.parse(
                    JSON.stringify(
                        state.evalsmodel[state.formsType.toLowerCase()]
                    )
                )
                let createEval = {}
                createEval[newEval.key] = ''
                createEval[newEval.value] = ''
                createEval[newEval.order] = ''
                if (isDefined(newEval.min)) {
                    createEval[newEval.min] = ''
                }
                if (isDefined(newEval.max)) {
                    createEval[newEval.max] = ''
                }
                if (isDefined(newEval.default)) {
                    createEval[newEval.default] = ''
                }
                if (isDefined(newEval.step)) {
                    createEval[newEval.step] = ''
                }
                if (newEval.tag !== undefined) {
                    createEval[newEval.tag] = ''
                }
                if (state.forcedtag !== '') {
                    createEval[newEval.tag] = state.forcedtag
                }
                if (createEval.tag === '' && isDefined(state.evals[0])) {
                    createEval.tag = JSON.parse(
                        JSON.stringify(state.evals[0].tag)
                    )
                }
                if (customtag !== '') {
                    createEval.tag = customtag
                }
                evals.push(createEval)
                actions.setEvals(evals)
            },
        onUpdateEvals: () => (state, actions) => {
            state.sendToParent({
                target: {
                    name: state.formName,
                    value: state.evals,
                    lang: state.lang,
                    order: state.order,
                },
            })
        },
        removeThisOne: (el) => (state, actions) => {
            let evals = state.evals
            let parent = el.target.closest('.afevalmaker')
            let targetIndex = Array.prototype.indexOf.call(
                parent.parentElement.children,
                parent
            )
            evals.splice(targetIndex, 1)

            actions.setEvals(evals)
            actions.onUpdateEvals()
        },
        parseAndSetList: (list) => (state, actions) => {
            if (list instanceof Array) {
            } else {
                let newList = []
                for (var i in list) {
                    newList.push({ name: i, value: list[i] })
                }
                list = newList
                actions.setModelList({ key: 'name', value: 'value' })
            }
            actions.setList(list)
        },
        setEvals: (newState) => (state) => ({
            evals: newState,
        }),
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setFormsType: (newState) => (state) => ({
            formsType: newState,
        }),
        setModelList: (newState) => (state) => ({
            modelList: newState,
        }),
        setOrder: (newState) => (state) => ({
            order: newState,
        }),
        setSendToParent: (newState) => (state) => ({
            sendToParent: newState,
        }),
        selectThisOne: (el) => (state, actions) => {
            let evals = state.evals
            for (var evl in evals) {
                evals[evl].goodResponse = 0
                if (+evals[evl].value === +el.target.value) {
                    evals[evl].goodResponse = 1
                }
            }
            state.sendToParent({
                target: { name: 'forms', value: evals, lang: state.lang },
            })
        },
    },
    (state, actions) => (props, children) =>
        (
            <div key={'eval-maker-' + props.key + '-' + state.lang}>
                {isDefined(props.original) &&
                    isDefined(props.original.formHeader) && (
                        <div class="adminformevalmaker-formheader">
                            {props.original.formHeader.map((m) => (
                                <div
                                    class="adminformevalmaker-formheader-entry"
                                    style={{ width: m.width }}
                                >
                                    {m.label}
                                </div>
                            ))}
                        </div>
                    )}
                {state.formsType !== '' ? (
                    <div key={'evmk-' + state.lang}>
                        <dummy>
                            {state.formsType.toLowerCase() === 'textarea' ? (
                                <dummy>
                                    <p class="">
                                        {t(
                                            'Un champ de saisie libre sera affiché.'
                                        )}
                                    </p>
                                </dummy>
                            ) : (
                                <dummy>
                                    {(state.list.length > 0 ||
                                        state.specialDisplay === 'quizz' ||
                                        state.formsType === 'Yesno' ||
                                        state.specialDisplay === 'forcedtag' ||
                                        state.specialDisplay ===
                                            'evalClinical') && (
                                        <div>
                                            {state.evals.map((evl, idx) => (
                                                <AFEvalMaker
                                                    key={
                                                        'eval-entry-' +
                                                        idx +
                                                        '-' +
                                                        props.key +
                                                        '-' +
                                                        state.lang
                                                    }
                                                    class={
                                                        'eval-entry-' +
                                                        idx +
                                                        '-' +
                                                        props.key
                                                    }
                                                    document={evl}
                                                    original={props.original}
                                                    name={state.name}
                                                    modelList={state.modelList}
                                                    order={idx}
                                                    model={
                                                        state.evalsmodel[
                                                            state.formsType.toLowerCase()
                                                        ]
                                                    }
                                                    list={state.list}
                                                    onclick={
                                                        actions.onUpdateEvals
                                                    }
                                                    ondelete={
                                                        actions.removeThisOne
                                                    }
                                                    onSynchEachLanguage={
                                                        state.onSynchEachLanguage
                                                    }
                                                    lang={state.lang}
                                                    onclickquizz={
                                                        actions.selectThisOne
                                                    }
                                                    formsType={state.formsType}
                                                    specialDisplay={
                                                        state.specialDisplay
                                                    }
                                                    customer={props.customer}
                                                ></AFEvalMaker>
                                            ))}
                                        </div>
                                    )}

                                    {state.specialDisplay !== 'quizz' &&
                                        state.formsType !== 'Yesno' &&
                                        [
                                            'cryptedweight',
                                            'bloodpressure',
                                            'cryptedtemperature',
                                            'heartbeat',
                                        ].indexOf(
                                            props.formsType.toLowerCase()
                                        ) === -1 && (
                                            <Form.Group classes="btzForm-btzFooter">
                                                <Button
                                                    primary
                                                    flat
                                                    onclick={() => {
                                                        actions.addEvalEntry()
                                                        if (
                                                            isDefined(
                                                                state.onSynchEachLanguage
                                                            )
                                                        )
                                                            state.onSynchEachLanguage(
                                                                {
                                                                    lang: state.lang,
                                                                    order: null,
                                                                }
                                                            )
                                                    }}
                                                >
                                                    {t('Ajouter une réponse')}
                                                </Button>
                                            </Form.Group>
                                        )}
                                </dummy>
                            )}
                        </dummy>
                    </div>
                ) : (
                    <div class="">
                        <p class="">
                            {t('Veuillez sélectionner un type d‘évaluation.')}
                        </p>
                    </div>
                )}
            </div>
        ),
    'adminformevalmaker'
)

export { AdminFormEvalMaker }

import { t, h, Component } from '@app/utils'
import { isDefined } from '@app/core'
import { Settings } from '@app/api'
import './index.scss'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    statistiques: [],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        Settings.getPlateformStats().then((res) => {
            if (isDefined(res.data) && !res.data.isError) {
                actions.setStatistiques(res.data)
            }
        })
    },
    setStatistiques: (newState) => (state) => ({
        statistiques: newState,
    }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout
            page="adminstatistiques"
            customer={customer}
            currentPage={'statistiques'}
        >
            <div class="border-bottom">
                <div class="">
                    <p class="sup-title">{t('Patients total').toUpperCase()}</p>
                    <p class="sup-title sup-number">
                        {isDefined(state.statistiques.patients)
                            ? state.statistiques.patients
                            : 'Ø'}
                    </p>
                </div>
            </div>
            <div class="border-bottom" style={{ 'padding-top': '25px' }}>
                <div class="col-xs-12 col-6" style={{ 'padding-left': '0px' }}>
                    <p class="sup-title">
                        {t(
                            'Patients actifs uniques sur les 7 derniers jours'
                        ).toUpperCase()}
                    </p>
                    <p class="sup-title sup-number">
                        {isDefined(state.statistiques.patients7)
                            ? state.statistiques.patients7
                            : 'Ø'}
                    </p>
                </div>
                <div class="col-xs-12 col-6" style={{ 'padding-right': '0px' }}>
                    <p class="sup-title">
                        {t(
                            'Patients actifs uniques sur les 30 derniers jours'
                        ).toUpperCase()}
                    </p>
                    <p class="sup-title sup-number">
                        {isDefined(state.statistiques.patients30)
                            ? state.statistiques.patients30
                            : 'Ø'}
                    </p>
                </div>
            </div>
            <div class="border-bottom" style={{ 'padding-top': '25px' }}>
                <div class="">
                    <p class="sup-title">
                        {t('Nombre total de lancements d’app').toUpperCase()}
                    </p>
                    <p class="sup-title sup-number">
                        {isDefined(state.statistiques.connexions)
                            ? state.statistiques.connexions
                            : 'Ø'}
                    </p>
                </div>
            </div>
            <div class="" style={{ 'padding-top': '25px' }}>
                <div class="">
                    <p class="sup-title">
                        {t('nombre total de patients etapes').toUpperCase()}
                    </p>
                    <p class="sup-title sup-number">
                        {isDefined(state.statistiques.etapes)
                            ? state.statistiques.etapes
                            : 'Ø'}
                    </p>
                </div>
            </div>
        </IndexLayout>
    )

export default Component(state, actions, view, 'adminstatistiques')

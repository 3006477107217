import { t } from '@app/utils'
import * as constants from '@app/constants'
import { ClientErrors } from '@app/api'
import { addCSRFToken } from '@app/core'

const clientErrors_CARD = {
    filter: [],
    tableHead: [
        { key: 'date', label: 'date' },
        { key: 'error', label: 'erreur' },
        { key: 'count', label: "nombre d'occurence" },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/errors/:id'),
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: ClientErrors.deleteClientError,
        },
    ],
    header: ["Traitement de l'erreur"],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        count: {
            name: 'count',
            type: 'string',
            label: "Nombre d'occurence",
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'half',
            },
        },
        hash: {
            name: 'hash',
            type: 'string',
            label: 'Hash',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'half',
            },
        },
        url: {
            name: 'url',
            type: 'string',
            label: 'Url',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'full',
            },
        },
        error: {
            name: 'error',
            type: 'textarea',
            label: 'Erreur',
            field: 'textarea',
            readonly: true,
            mandatory: true,
            value: '',
            display: {
                mode: 'full',
            },
        },
        stack: {
            name: 'stack',
            type: 'string',
            label: 'Stack',
            field: 'textarea',
            readonly: true,
            mandatory: true,
            value: '',
            display: {
                mode: 'full',
            },
        },
        ua: {
            name: 'ua',
            type: 'string',
            label: 'User agent',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'full',
            },
        },
        version: {
            name: 'version',
            type: 'string',
            label: 'Version',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'half',
            },
        },
        userId: {
            name: 'userId',
            type: 'string',
            label: 'ID user',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'half',
            },
        },
        instance: {
            name: 'instance',
            type: 'string',
            label: 'Instance',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'half',
            },
        },
        env: {
            name: 'env',
            type: 'string',
            label: 'Environnement',
            field: 'readonly',
            mandatory: true,
            value: '',
            display: {
                mode: 'half',
            },
        },
    },
}

export { clientErrors_CARD }

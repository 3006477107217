import { t, h, Component } from '@app/utils'
import { Button } from '@app/elements'
import { User } from '@app/api'
import { isEmail, isDefined } from '@app/core'

const AdminFormListingMail = Component(
    {
        userid: null,
        email: '',
        addIntoList: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.addIntoList) {
                state.addIntoList = props.addIntoList
            }
            if (props.userid) {
                actions.setUserId(props.userid)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {},
        sendChildrenMail: (e) => (state, actions) => {
            if (e !== undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            if (isEmail(state.email)) {
                User.addChildren(state.userid, state.email).then((res) => {
                    actions.setEmail('')
                    if (
                        isDefined(res.data) &&
                        !res.data.isError &&
                        res.data.response === 'Ok'
                    ) {
                        state.addIntoList(state.email)
                    }
                })
            }
        },
        setEmail: (newState) => (state) => ({
            email: newState,
        }),
        setUserId: (newState) => (state) => ({
            userid: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <dummy style={{ display: 'block' }}>
            <input
                key={'field-' + props.idx}
                type={'text'}
                value={state.email}
                oninput={(el) => actions.setEmail(el.target.value)}
                placeholder={props.placeholder || ''}
            />
            <Button primary flat onclick={actions.sendChildrenMail}>
                {t('Envoyer')}
            </Button>
        </dummy>
    ),
    'adminformlistingmail'
)

export { AdminFormListingMail }

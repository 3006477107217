import { t } from '@app/utils'
import * as constants from '@app/constants'

const scenario_CARD = {
    filter: [],
    header: ['Configurer un scénario'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'article',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Nom du scénario',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        scenarios: {
            name: 'scenarios',
            type: 'scenarios',
            label: 'Scenarios',
            field: 'scenarios',
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez configurer les seuils d’au moins une mesure pour enregistrer.',
                i18nMessage: t(
                    'Veuillez configurer les seuils d’au moins une mesure pour enregistrer.'
                ),
            },
        },
    },
}

export { scenario_CARD }

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const program_CARD = {
    filter: [],
    tableHead: [
        { key: 'name', label: 'nom' },
        { key: 'theme', label: 'thème' },
        { key: 'duration', label: 'durée' },
        { key: 'sessions', label: 'séance' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/programs/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/programs/:id') + '&mode=duplicate',
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteProgram,
        },
    ],
    tableAllOptions: {
        label: 'PROGRAMMES SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteProgram,
            },
        ],
    },
    header: ['Configurer le programme'],
    form: {
        tabs: {
            type: 'string',
            field: 'tabs',
            value: [
                {
                    label: 'seances',
                    key: 'programsessions',
                    marker: 'listing-sessions',
                },
            ],
        },
        id: {
            field: 'hidden',
            value: '',
        },
        preview: {
            field: 'hidden',
            value: true,
            sending: false,
        },
        template: {
            field: 'hidden',
            value: 'program',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        theme: {
            name: 'theme',
            type: 'int',
            label: 'Thématique',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
        },
        coach: {
            name: 'coach',
            substitute: 'coachId',
            type: 'coach',
            label: 'Coach',
            field: 'dropdown',
            list: 'dynamic',
            searchbox: false,
            model: { key: null, value: null },
            value: '',
            placeholder: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un coach',
                i18nMessage: t('Veuillez sélectionner un coach'),
            },
            display: {
                border: 'bottom',
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
        objectives: {
            name: 'objectives',
            type: 'autocomplete/objectif',
            label: 'Objectifs liés',
            field: 'dyninput-to-div',
            searchbox: true,
            model: { key: 'id', value: 'name' },
            list: 'dynamic',
            placeholder: {
                input: 'Aucun',
                textarea: '',
            },
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        pictures: {
            name: 'pictures',
            type: 'file',
            label: 'Slideshow',
            field: 'pictures',
            nMedia: 3,
            value: '',
        },
        duration: {
            name: 'duration',
            type: 'translation',
            label: 'Durée',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici la durée totale du programme',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la durée totale du programme',
                i18nMessage: t(
                    'Veuillez renseigner la durée totale du programme'
                ),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Nom du programme',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre programme',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre programme',
                i18nMessage: t(
                    'Veuillez renseigner le titre de votre programme'
                ),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre programme (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez renseigner la description de votre programme',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre programme'
                ),
            },
        },
        equipment: {
            name: 'equipment',
            type: 'translation',
            label: 'Matériel',
            field: 'textarea',
            mandatory: true,
            placeholder: 'Renseignez ici le matériel nécessaire au programme',
            value: {},
        },
        mediaTranslations: {
            name: 'mediaTranslations',
            type: 'translation',
            label: 'Image',
            field: 'media',
            value: {},
        },
        picturesTranslations: {
            name: 'picturesTranslations',
            type: 'translation',
            label: 'Slideshow',
            field: 'pictures',
            nMedia: 3,
            value: {},
        },
    },
}

const program_session_CARD = {
    header: ['Configurer la séance'],
    form: {
        tabs: {
            type: 'string',
            field: 'tabs',
            value: [
                {
                    label: 'etapes',
                    key: 'programsteps',
                    marker: 'listing-steps',
                },
            ],
        },
        id: {
            field: 'hidden',
            value: '',
        },
        preview: {
            field: 'hidden',
            value: true,
            sending: false,
        },
        template: {
            field: 'hidden',
            value: 'program-session',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            // 'validation': {
            //     'mandatory': true,
            //     'isValid': false,
            //     'type': 'atLeastOneChecked',
            //     'message': 'Veuillez sélectionner au moins une langue',
            //     'i18nMessage': t('Veuillez sélectionner au moins une langue'),
            // }
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        type: {
            name: 'type',
            type: 'boolean',
            label: 'Type',
            field: 'radio',
            choice: {
                on: 1,
                off: 0,
            },
            value: '',
        },
        interval: {
            name: 'interval',
            type: 'int',
            label: 'Intervalle',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.INTERVALLE,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un intervalle',
                i18nMessage: t('Veuillez sélectionner un intervalle'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre séance (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de votre séance',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre séance'
                ),
            },
        },
        equipment: {
            name: 'equipment',
            type: 'translation',
            label: 'Matériel',
            field: 'textarea',
            mandatory: true,
            placeholder: 'Renseignez ici le matériel nécessaire à la séance',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le matériel nécessaire au séance',
                i18nMessage: t(
                    'Veuillez renseigner le matériel nécessaire au séance'
                ),
            },
        },
    },
}

const program_step_CARD = {
    header: ['Configurer l‘étape'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'program-step',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            // 'validation': {
            //     'mandatory': true,
            //     'isValid': false,
            //     'type': 'atLeastOneChecked',
            //     'message': 'Veuillez sélectionner au moins une langue',
            //     'i18nMessage': t('Veuillez sélectionner au moins une langue'),
            // }
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        order: {
            name: 'order',
            type: 'int',
            label: 'Odre de l‘étape',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.ORDER100,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        mediaUrl: {
            name: 'mediaUrl',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Nom de l‘étape',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre étape',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre étape',
                i18nMessage: t('Veuillez renseigner le titre de votre étape'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre étape (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de votre étape',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre étape'
                ),
            },
        },
        mediaTranslations: {
            name: 'mediaTranslations',
            type: 'translation',
            label: 'Image',
            field: 'media',
            value: {},
        },
    },
}

const program_step_CARD_OBJECTIF = {
    header: ['Configurer l‘étape objectif'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        type: {
            field: 'hidden',
            value: '1',
        },
        template: {
            field: 'hidden',
            value: 'program-step',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        order: {
            name: 'order',
            type: 'int',
            label: 'Odre de l‘étape',
            field: 'dropdown',
            list: constants.custo.ORDER100,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        category: {
            name: 'category',
            type: 'int',
            label: 'Catégorie de l‘étape',
            field: 'dropdown',
            list: constants.custo.STEP_CATEGORIES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une catégorie',
                i18nMessage: t('Veuillez sélectionner une catégorie'),
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘étape',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre étape',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre étape',
                i18nMessage: t('Veuillez renseigner le titre de votre étape'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            placeholder:
                'Renseignez ici le corps de votre étape (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de votre étape',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre étape'
                ),
            },
        },
        mediaTranslations: {
            name: 'mediaTranslations',
            type: 'translation',
            label: 'Image',
            field: 'media',
            value: {},
        },
    },
}

const program_step_CARD_EVALUATION = {
    header: ['Configurer l‘étape évaluation'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        type: {
            field: 'hidden',
            value: '2',
        },
        template: {
            field: 'hidden',
            value: 'program-step',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        order: {
            name: 'order',
            type: 'int',
            label: 'Odre de l‘étape',
            field: 'dropdown',
            list: constants.custo.ORDER100,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        category: {
            name: 'category',
            type: 'int',
            label: 'Catégorie de l‘étape',
            field: 'dropdown',
            list: constants.custo.STEP_CATEGORIES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une catégorie',
                i18nMessage: t('Veuillez sélectionner une catégorie'),
            },
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Intitulé de la question',
            field: 'text',
            placeholder: 'Renseignez ici l‘intitulé de la question',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner l‘intitulé de la question',
                i18nMessage: t('Veuillez renseigner l‘intitulé de la question'),
            },
        },
        forms: {
            // for evaluations
            name: 'eval',
            substitute: 'formDatas',
            type: 'translation',
            label: 'Détail du formulaire',
            field: 'eval',
            model: {},
            list: 'static',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastTwoFilled',
                message:
                    'Veuillez renseigner au moins deux entrées dans votre évaluation',
                i18nMessage: t(
                    'Veuillez renseigner au moins deux entrées dans votre évaluation'
                ),
            },
            sendElsewhere: true,
            wrappedIn: 'forms',
        },
        dataDate: {
            name: 'dataDate',
            type: 'string',
            label: 'dateDate',
            field: 'hidden',
            value: 'P0D',
            wrappedIn: 'forms',
        },
        formsType: {
            name: 'type',
            substitute: 'type',
            type: 'int',
            label: 'Type',
            field: 'dropdown',
            list: constants.custo.EVAL_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un type pour votre évaluation',
                i18nMessage: t(
                    'Veuillez sélectionner un type pour votre évaluation'
                ),
            },
            wrappedIn: 'forms',
        },
        sliderTag: {
            name: 'sliderTag',
            type: 'datatags',
            label: 'Data tag',
            field: 'dropdown',
            list: 'dynamic',
            value: '',
            placeholder: 'Tapez votre recherche ici ...',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un dataTag pour votre slider',
                i18nMessage: t(
                    'Veuillez sélectionner un dataTag pour votre slider'
                ),
            },
            wrappedIn: 'forms',
            dependsOn: {
                field: 'formsType',
                value: 'Slider',
            },
        },
    },
}

const program_step_CARD_EXERCICE = {
    header: ['Configurer l‘étape exercice'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        type: {
            field: 'hidden',
            value: '3',
        },
        template: {
            field: 'hidden',
            value: 'program-step',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        order: {
            name: 'order',
            type: 'int',
            label: 'Odre de l‘étape',
            field: 'dropdown',
            list: constants.custo.ORDER100,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        category: {
            name: 'category',
            type: 'int',
            label: 'Catégorie de l‘étape',
            field: 'dropdown',
            list: constants.custo.STEP_CATEGORIES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une catégorie',
                i18nMessage: t('Veuillez sélectionner une catégorie'),
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        repeat: {
            name: 'repeat',
            type: 'string',
            label: 'Répétition de la séance',
            field: 'text',
            placeholder: 'Renseignez ici le nombre de répétitions',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        serieNumber: {
            name: 'serieNumber',
            type: 'string',
            label: 'Nombre de série',
            field: 'text',
            placeholder: 'Renseignez ici le nombre de séries',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘étape',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre étape',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre étape',
                i18nMessage: t('Veuillez renseigner le titre de votre étape'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            placeholder:
                'Renseignez ici le corps de votre étape (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de votre étape',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre étape'
                ),
            },
        },
        mediaTranslations: {
            name: 'mediaTranslations',
            type: 'translation',
            label: 'Image',
            field: 'media',
            value: {},
        },
        duration: {
            name: 'duration',
            type: 'translation',
            label: 'Durée totale',
            field: 'text',
            placeholder: 'Renseignez ici la durée totale',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        recovery: {
            name: 'recovery',
            type: 'translation',
            label: 'Temps de récupération',
            field: 'text',
            placeholder: 'Renseignez ici le temps de récupération',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        security: {
            name: 'security',
            type: 'translation',
            label: 'Point sécurité',
            field: 'textarea',
            placeholder: 'Renseignez ici le temps de récupération',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
    },
}

const program_step_CARD_ADVICE = {
    header: ['Configurer l‘étape conseil'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        type: {
            field: 'hidden',
            value: '4',
        },
        template: {
            field: 'hidden',
            value: 'program-step',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        order: {
            name: 'order',
            type: 'int',
            label: 'Odre de l‘étape',
            field: 'dropdown',
            list: constants.custo.ORDER100,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        category: {
            name: 'category',
            type: 'int',
            label: 'Catégorie de l‘étape',
            field: 'dropdown',
            list: constants.custo.STEP_CATEGORIES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une catégorie',
                i18nMessage: t('Veuillez sélectionner une catégorie'),
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘étape',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre étape',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre étape',
                i18nMessage: t('Veuillez renseigner le titre de votre étape'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            placeholder:
                'Renseignez ici le corps de votre étape (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de votre étape',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre étape'
                ),
            },
        },
        mediaTranslations: {
            name: 'mediaTranslations',
            type: 'translation',
            label: 'Image',
            field: 'media',
            value: {},
        },
    },
}

export { program_CARD }
export { program_session_CARD }
export { program_step_CARD }
export { program_step_CARD_OBJECTIF }
export { program_step_CARD_EVALUATION }
export { program_step_CARD_EXERCICE }
export { program_step_CARD_ADVICE }

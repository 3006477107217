import { t, h } from '@app/utils'
import { allRoutes, addCSRFToken } from '@app/core'
import { Link } from '@app/elements'

import { UserMenu } from '@app/layouts/usermenu'

import icoAddEntry from '@app/img/ico/add-icon.png'
import icoAddEntryx2 from '@app/img/ico/add-icon@2x.png'

// import icoCards from '@app/img/ico/left-menu/ico-cartes.png';
// import icoCardsx2 from '@app/img/ico/left-menu/ico-cartes@2x.png';
// import icoCardsGrey from '@app/img/ico/left-menu/ico-cartes-grey.png';
// import icoCardsGreyx2 from '@app/img/ico/left-menu/ico-cartes-grey@2x.png';

import icoChallenges from '@app/img/ico/left-menu/ico-defis.png'
import icoChallengesx2 from '@app/img/ico/left-menu/ico-defis@2x.png'
import icoChallengesGrey from '@app/img/ico/left-menu/ico-defis-grey.png'
import icoChallengesGreyx2 from '@app/img/ico/left-menu/ico-defis-grey@2x.png'

import icoCitations from '@app/img/ico/left-menu/ico-citation.png'
import icoCitationsx2 from '@app/img/ico/left-menu/ico-citation@2x.png'
import icoCitationsGrey from '@app/img/ico/left-menu/ico-citation-grey.png'
import icoCitationsGreyx2 from '@app/img/ico/left-menu/ico-citation-grey@2x.png'

import icoMotRH from '@app/img/ico/left-menu/ico-message-rh.png'
import icoMotRHx2 from '@app/img/ico/left-menu/ico-message-rh@2x.png'
import icoMotRHGrey from '@app/img/ico/left-menu/ico-message-rh-grey.png'
import icoMotRHGreyx2 from '@app/img/ico/left-menu/ico-message-rh-grey@2x.png'

import icoSurvey from '@app/img/ico/left-menu/ico-survey.png'
import icoSurveyx2 from '@app/img/ico/left-menu/ico-survey@2x.png'
import icoSurveyGrey from '@app/img/ico/left-menu/ico-survey-grey.png'
import icoSurveyGreyx2 from '@app/img/ico/left-menu/ico-survey-grey@2x.png'

import icoAnswer from '@app/img/ico/left-menu/ico-service.png'
import icoAnswerx2 from '@app/img/ico/left-menu/ico-service@2x.png'
import icoAnswerGrey from '@app/img/ico/left-menu/ico-service-grey.png'
import icoAnswerGreyx2 from '@app/img/ico/left-menu/ico-service-grey@2x.png'

import icoUsers from '@app/img/ico/left-menu/ico-users.png'
import icoUsersx2 from '@app/img/ico/left-menu/ico-users@2x.png'
import icoUsersGrey from '@app/img/ico/left-menu/ico-users-grey.png'
import icoUsersGreyx2 from '@app/img/ico/left-menu/ico-users-grey@2x.png'

import icoCustomers from '@app/img/ico/left-menu/ico-entreprises.png'
import icoCustomersx2 from '@app/img/ico/left-menu/ico-entreprises@2x.png'
import icoCustomersGrey from '@app/img/ico/left-menu/ico-entreprises-grey.png'
import icoCustomersGreyx2 from '@app/img/ico/left-menu/ico-entreprises-grey@2x.png'

export default {
    initView: function () {
        let view = (props, actions) => (
            <div style={{ padding: '20px 0px' }}>
                <div>
                    {props.currentPage == 'content' && (
                        <ul>
                            {/*<li class={props.page == 'cards' ? 'active' : ''}><div><img class='activated' src={icoCards} srcset={`${icoCardsx2} 2x`} alt='' /><img class='not-activated' src={icoCardsGrey} srcset={`${icoCardsGreyx2} 2x`} alt='' /></div><Link to={addCSRFToken(allRoutes['private.cards'].pathname)}>{t('Cartes')}</Link><Link to={addCSRFToken(allRoutes['private.cards.edit'].pathname.replace(':id', 'null'))}><img src={icoAddEntry} srcset={`${icoAddEntryx2} 2x`} alt='' /></Link></li>*/}
                            <li
                                class={
                                    props.page == 'corpowordsrh' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoMotRH}
                                        srcset={`${icoMotRHx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoMotRHGrey}
                                        srcset={`${icoMotRHGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.wordsrh'].pathname
                                    )}
                                >
                                    {t('Mots du RH')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.wordsrh.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'corpochallenges'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoChallenges}
                                        srcset={`${icoChallengesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoChallengesGrey}
                                        srcset={`${icoChallengesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.challenges.corpo']
                                            .pathname
                                    )}
                                >
                                    {t('Challenges')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.challenges.corpo.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'corpocitations'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCitations}
                                        srcset={`${icoCitationsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCitationsGrey}
                                        srcset={`${icoCitationsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.citations'].pathname
                                    )}
                                >
                                    {t('Citations')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.citations.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'corpoevaluations'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoAnswer}
                                        srcset={`${icoAnswerx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoAnswerGrey}
                                        srcset={`${icoAnswerGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.evaluations']
                                            .pathname
                                    )}
                                >
                                    {t('Une question, votre avis')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.evaluations.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'corposurveys' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoSurvey}
                                        srcset={`${icoSurveyx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoSurveyGrey}
                                        srcset={`${icoSurveyGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.surveys'].pathname
                                    )}
                                >
                                    {t('Sondages')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.surveys.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'themes' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoUsers}
                                        srcset={`${icoUsersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoUsersGrey}
                                        srcset={`${icoUsersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.themes'].pathname
                                    )}
                                >
                                    {t('Thématiques')}
                                </Link>
                                <a style={{ display: 'none' }}></a>
                            </li>
                        </ul>
                    )}

                    {props.currentPage == 'users' && (
                        <ul>
                            <li class={props.page == 'users' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoUsers}
                                        srcset={`${icoUsersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoUsersGrey}
                                        srcset={`${icoUsersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.users']
                                            .pathname
                                    )}
                                >
                                    {t('Utilisateurs')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.users.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'customers' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCustomers}
                                        srcset={`${icoCustomersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCustomersGrey}
                                        srcset={`${icoCustomersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.customers']
                                            .pathname
                                    )}
                                >
                                    {t('Entreprises')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.customers.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '65px',
                        }}
                    >
                        <UserMenu display={'connected'}></UserMenu>
                    </div>
                </div>
            </div>
        )
        return view
    },
}

import { h, classMerge, isLength, isNumeric } from '@app/utils'
import { isEmail } from '@app/core'
import './index.scss'

import icoEye from '@app/img/ico/ico-eye-off.png'
import icoEyex2 from '@app/img/ico/ico-eye-off@2x.png'
import icoEyeOn from '@app/img/ico/ico-eye-on.png'
import icoEyeOnx2 from '@app/img/ico/ico-eye-on@2x.png'

import icoValidQCM from '@app/img/ico/ico-valid-green.png'
import icoValidQCMx2 from '@app/img/ico/ico-valid-green@2x.png'

import icoValidRadio from '@app/img/ico/ico-valid-white.png'
import icoValidRadiox2 from '@app/img/ico/ico-valid-white@2x.png'

export const actions = {
    switchPwd: ({ target }) => {
        var inp = target.parentNode.firstChild
        inp.setAttribute(
            'type',
            inp.getAttribute('type') === 'text' ? 'password' : 'text'
        )
    },
    updateListingFilter: ({ target }) => {
        actions.debounce({ func: actions.debounceListing(target), wait: 500 })
    },
    debounceListing: (target) => {
        let filter = target.value
        let parentNode = target.parentNode
        let childNode = parentNode.querySelectorAll(
            '.btzForm-btzFormInputListing'
        )
        childNode.forEach(function (entry) {
            if (
                entry.textContent.toLowerCase().indexOf(filter.toLowerCase()) >
                -1
            ) {
                entry.style.display = ''
            } else {
                entry.style.display = 'none'
            }
        })
    },
    debounce: ({ func: func, wait: wait, immediate: immediate }) => {
        var timeout
        return function () {
            var context = this,
                args = arguments
            var later = function () {
                timeout = null
                if (!immediate) func.apply(context, args)
            }
            var callNow = immediate && !timeout
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
            if (callNow) func.apply(context, args)
        }
    },
    updateIsInputValid: ({ target }) => {
        actions.debounce({ func: actions.onUpdate(target), wait: 500 })
    },
    onUpdate: (target) => {
        let name = target.name.toLowerCase()
        let value = target.value
        let isValid = false
        if (name === undefined) {
            return false
        }

        switch (true) {
            case name.indexOf('email') > -1:
                isValid = isEmail(value)

                break
            case name.indexOf('password') > -1:
            case name.indexOf('secret') > -1:
            case name.indexOf('nick') > -1:
                isValid = isLength(value, { min: 1 })

                break
            case name.indexOf('date') > -1 && name.indexOf('day') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 1, max: 2 }) &&
                    value > 0 &&
                    value < 32

                break
            case name.indexOf('date') > -1 && name.indexOf('month') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 1, max: 2 }) &&
                    value > 0 &&
                    value < 13

                break
            case name.indexOf('date') > -1 && name.indexOf('year') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 4, max: 4 }) &&
                    value > 1900 &&
                    value < 2019

                break
            default:
                throw Error(`${name} has no validator assigned`)
        }

        target.classList.remove('is-success')
        target.classList.remove('is-error')
        if (isValid) {
            target.classList.add('is-success')
        } else {
            target.classList.add('is-error')
        }
    },
}

const Form = (props, children) => {
    const { horizontal, classes, ...otherProps } = props

    const allClasses = classMerge([
        'btzForm',
        horizontal && 'form-horizontal',
        classes,
    ])

    return (
        <form class={allClasses} {...otherProps}>
            {children}
        </form>
    )
}

const Group = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzForm-btzFormGroup', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Label = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzForm-btzFormLabel', classes])

    return (
        <label class={allClasses} {...otherProps}>
            {children}
        </label>
    )
}

const Hint = (props, children) => {
    const { success, error, classes, ...otherProps } = props

    const allClasses = classMerge([
        'form-input-hint',
        success && 'is-success',
        error && 'is-error',
        classes,
    ])

    return (
        <p class={allClasses} {...otherProps}>
            {children}
        </p>
    )
}

const Select = (props) => {
    const { name, classes, onchange = null, ...otherProps } = props

    const allClasses = classMerge(['form-input-select', classes])

    return (
        <select class={allClasses} name={name} onchange={props.onchange}>
            {props.options.map((item) => (
                <option
                    value={item.value !== undefined ? item.value : item}
                    selected={
                        item.value !== undefined
                            ? item.value === props.selected
                                ? 'selected'
                                : ''
                            : item === props.selected
                            ? 'selected'
                            : ''
                    }
                >
                    {item.key !== undefined ? item.key : item}
                </option>
            ))}
        </select>
    )
}

const Input = (props) => {
    const {
        name,
        type = 'text',
        size,
        success,
        error,
        classes,
        dynResize = false,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput',
        size,
        success && 'is-success',
        error && 'is-error',
        classes,
    ])

    let dateValueDay = '00',
        dateValueMonth = '00',
        dateValueYear = '0000'
    if (type === 'date' && otherProps.value !== undefined) {
        dateValueDay = otherProps.value.day
        dateValueMonth = otherProps.value.month
        dateValueYear = otherProps.value.year
        delete otherProps.value
    }

    if (dynResize) {
        let tmt = setTimeout(() => {
            clearTimeout(tmt)
            tmt = null
            let trg = document.querySelectorAll('input[name="' + name + '"]')
            if (trg.length > 0) {
                let ruler = document.getElementById('btzRuler'),
                    val = '',
                    offsetWidth = 0,
                    inputWidth = trg[0].offsetWidth,
                    initialFontSize = +window
                        .getComputedStyle(trg[0], null)
                        .getPropertyValue('font-size')
                        .replace('px', '')
                let fontSize = 0
                trg[0].style.height =
                    trg[0].getBoundingClientRect().height + 'px'
                trg[0].addEventListener('keyup', () => {
                    val = trg[0].value
                    fontSize = window
                        .getComputedStyle(trg[0], null)
                        .getPropertyValue('font-size')
                    ruler.style.fontSize = fontSize
                    ruler.innerHTML = val
                    offsetWidth = ruler.offsetWidth
                    if (offsetWidth + 100 >= inputWidth) {
                        if (+fontSize.replace('px', '') > 16) {
                            trg[0].style.fontSize =
                                +fontSize.replace('px', '') - 2 + 'px'
                        }
                    } else if (offsetWidth - 100 < inputWidth) {
                        if (
                            +trg[0].style.fontSize.replace('px', '') <
                            initialFontSize
                        ) {
                            trg[0].style.fontSize =
                                +fontSize.replace('px', '') + 2 + 'px'
                        }
                    }
                })

                trg[0].dispatchEvent(new KeyboardEvent('keyup', { key: '' }))
            }
        }, 1000)
    }

    const npt =
        type == 'password' ? (
            <div class={allClasses} style={{ position: 'relative' }}>
                <input
                    name={name}
                    type={type}
                    class="btzForm-btzFormInput"
                    {...otherProps}
                />
                <img
                    src={icoEye}
                    srcset={icoEyex2 + ' 2x'}
                    alt=""
                    class="btzForm-btzFormInput-btzEye"
                    onclick={actions.switchPwd}
                />
                <img
                    src={icoEyeOn}
                    srcset={icoEyeOnx2 + ' 2x'}
                    alt=""
                    class="btzForm-btzFormInput-btzEyeOn"
                    onclick={actions.switchPwd}
                />
            </div>
        ) : type === 'date' ? (
            <div
                class={allClasses + ' btzInputDate'}
                style={{ position: 'relative' }}
            >
                <input
                    name={name}
                    type={'hidden'}
                    value={dateValueYear + dateValueMonth + dateValueDay}
                />
                <input
                    name={name + '-day'}
                    type={'text'}
                    maxlength={'2'}
                    class="btzForm-btzFormInput"
                    value={dateValueDay}
                    placeholder={props.placeholderDay}
                    oninput={
                        props.validation ? actions.updateIsInputValid : null
                    }
                    {...otherProps}
                />
                <p>/</p>
                <input
                    style={{ width: '55px' }}
                    name={name + '-month'}
                    type={'text'}
                    maxlength={'2'}
                    class="btzForm-btzFormInput"
                    value={dateValueMonth}
                    placeholder={props.placeholderMonth}
                    oninput={
                        props.validation ? actions.updateIsInputValid : null
                    }
                    {...otherProps}
                />
                <p>/</p>
                <input
                    name={name + '-year'}
                    type={'text'}
                    maxlength={'4'}
                    class="btzForm-btzFormInput"
                    value={dateValueYear}
                    placeholder={props.placeholderYear}
                    oninput={
                        props.validation ? actions.updateIsInputValid : null
                    }
                    {...otherProps}
                />
            </div>
        ) : (
            <input name={name} type={type} class={allClasses} {...otherProps} />
        )

    return (
        <div
            class={allClasses + ' btzForm-btzFormInputWrapper'}
            style={{ position: 'relative' }}
        >
            {npt}
            <div class="btzForm-btzFormInput-hr"></div>
        </div>
    )
}

const Listing = (props) => {
    const {
        name,
        listing = [],
        withsearch = false,
        classes,
        placeholder = '',
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput btzForm-btzFormInputListing',
        classes,
    ])

    return (
        <div>
            {withsearch === true && (
                <input
                    type="text"
                    class="btzForm-btzFormDropdown"
                    placeholder={placeholder}
                    oninput={actions.updateListingFilter}
                />
            )}
            <div class="btzForm-btzListing">
                {Object.keys(listing).map(function (key) {
                    return (
                        <div
                            key={key}
                            class={
                                allClasses +
                                (props.value === key ? ' active' : '')
                            }
                        >
                            <input
                                id={key}
                                type="radio"
                                name={name}
                                value={key}
                                checked={props.value === key}
                                data-checked={props.value === key}
                            />
                            <label
                                for={key}
                                {...otherProps}
                                data-value={key}
                                style={{ cursor: 'pointer' }}
                            >
                                <p>{listing[key]}</p>
                                <div class="btzForm-btzFormInput-QcmPuce">
                                    <img
                                        src={icoValidQCM}
                                        srcset={`${icoValidQCMx2} 2x`}
                                        alt=""
                                    />
                                </div>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const TextArea = (props) => {
    const { name, rows = '3', classes, ...otherProps } = props

    const allClasses = classMerge(['btzForm-btzFormInput', classes])

    return (
        <textarea name={name} rows={rows} class={allClasses} {...otherProps} />
    )
}

const CheckBox = (props, children) => {
    const {
        id,
        name,
        value,
        classes,
        placeholder,
        checked,
        custom = '',
        image = null,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput btzForm-btzCheckbox',
        classes,
    ])

    return (
        <div class="btzForm-btzFormInputWrapper">
            {image && (
                <input
                    id={id}
                    type="checkbox"
                    name={name}
                    checked={checked}
                    value={value}
                />
            )}
            <label for={id} class={allClasses} {...otherProps}>
                {image == null && (
                    <div>
                        {custom === '' ? (
                            <div>
                                <p>{placeholder}</p>
                                <input
                                    id={id}
                                    type="checkbox"
                                    name={name}
                                    checked={checked}
                                    value={value}
                                />
                            </div>
                        ) : (
                            <label class={custom}>
                                <input
                                    type="checkbox"
                                    name={name}
                                    value={value}
                                />
                                <span class="btzCheckmark">
                                    <img
                                        src={icoValidRadio}
                                        srcset={`${icoValidRadiox2} 2x`}
                                        alt=""
                                    />
                                </span>
                                <p
                                    style={{
                                        'vertical-align': 'middle',
                                        'display': 'inline-block',
                                        'margin': '0px',
                                    }}
                                >
                                    {placeholder}
                                </p>
                            </label>
                        )}
                    </div>
                )}
                {image && (
                    <div>
                        <div
                            class="btzForm-btzFormInput-btzCheckbox-image"
                            style={{
                                'display': 'table-cell',
                                'vertical-align': 'middle',
                                'background-image': 'url(' + image + ')',
                            }}
                        ></div>
                        <p>{placeholder}</p>
                    </div>
                )}
                <i class="form-icon"></i> {children}
            </label>
        </div>
    )
}

const Radio = (props, children) => {
    const {
        id,
        name,
        placeholder,
        checked,
        classes,
        value,
        custom = '',
        image = null,
        imagex2 = null,
        imageSelected = null,
        imageSelectedx2 = null,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput btzForm-btzRadiobox',
        classes,
    ])

    return (
        <div class="btzForm-btzFormInputWrapper">
            {image && (
                <input
                    id={id}
                    type="radio"
                    name={name}
                    checked={checked}
                    value={value}
                />
            )}
            <label for={id} class={allClasses} {...otherProps}>
                {image == null && (
                    <div>
                        {custom === '' ? (
                            <div>
                                <p>{placeholder}</p>
                                <input
                                    id={id}
                                    type="radio"
                                    name={name}
                                    checked={checked}
                                    value={value}
                                />
                            </div>
                        ) : (
                            <label class={custom}>
                                <input type="radio" name={name} value={value} />
                                <span class="btzCheckmark">
                                    <img
                                        src={icoValidRadio}
                                        srcset={`${icoValidRadiox2} 2x`}
                                        alt=""
                                    />
                                </span>
                                <p
                                    style={{
                                        'vertical-align': 'middle',
                                        'display': 'inline-block',
                                        'margin': '0px',
                                    }}
                                >
                                    {placeholder}
                                </p>
                            </label>
                        )}
                    </div>
                )}
                {image && (
                    <div
                        style={{
                            'display': 'table-cell',
                            'vertical-align': 'middle',
                        }}
                    >
                        <img
                            src={image}
                            srcset={
                                (imagex2 !== null ? imagex2 : image) + ' 2x'
                            }
                            alt=""
                            class="btzForm-btzFormInput-btzRadio-image"
                        />
                        {imageSelected !== null && imageSelectedx2 !== null && (
                            <img
                                src={imageSelected}
                                srcset={
                                    (imageSelectedx2 !== null
                                        ? imageSelectedx2
                                        : imageSelected) + ' 2x'
                                }
                                alt=""
                                class="btzForm-btzFormInput-btzRadio-image btzForm-btzFormInput-btzRadio-image-selected"
                            />
                        )}
                        <p>{placeholder}</p>
                    </div>
                )}
                <i class="form-icon"></i> {children}
            </label>
        </div>
    )
}

const Switch = (props, children) => {
    const { name, classes, ...otherProps } = props

    const allClasses = classMerge(['form-switch', classes])

    return (
        <label class={allClasses}>
            <input type="checkbox" name={name} />
            <i class="form-icon"></i> {children}
        </label>
    )
}

Form.Listing = Listing
Form.Group = Group
Form.Label = Label
Form.Hint = Hint
Form.Input = Input
Form.TextArea = TextArea
Form.CheckBox = CheckBox
Form.Radio = Radio
Form.Switch = Switch
Form.Select = Select
export { Form }

import { h, Component } from '@app/utils'
import './index.scss'

const Media = Component(
    {
        classes: '',
        mediaSrc: null,
        media: null,
        kind: '',
        videoKind: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.classes) {
                state.classes = props.classes
            }
            if (props.src) {
                state.mediaSrc = props.src
                actions.defineMedia(props.src)
            }
        },
        defineMedia: (media) => (state, actions) => {
            if (media === null) {
                return null
            }
            let src = media.url
            actions.setMedia(src)

            let kind = 'image'
            if (src.indexOf('youtube') > -1 || src.indexOf('vimeo') > -1) {
                kind = 'video'
                let videokind = 'youtube'
                if (src.indexOf('vimeo') > -1) {
                    videokind = 'vimeo'
                    src =
                        '//player.vimeo.com/video/' +
                        src.split('/').pop() +
                        '?title=0&portrait=0&byline=0&badge=0'
                } else {
                    src =
                        '//www.youtube.com/embed/' +
                        src.split('?v=').pop() +
                        '?controls=0&showinfo=0'
                }
                actions.setVideoKind(videokind)
                actions.setMedia(src)
            }
            actions.setKind(kind)
        },
        setMedia: (newState) => (state) => ({
            media: newState,
        }),
        setKind: (newState) => (state) => ({
            kind: newState,
        }),
        setVideoKind: (newState) => (state) => ({
            videoKind: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div style={{ height: '100%' }}>
            {state.kind === 'image' && (
                <div
                    class="btzBackgroundCover"
                    style={{ 'background-image': 'url(' + state.media + ')' }}
                ></div>
            )}
            {
                state.kind === 'video' && (
                    // <div class='embed-container'>
                    <iframe
                        style={{ width: '100%', height: '100%' }}
                        src={state.media}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowfullscreen="true"
                        width="100%"
                        height="100%"
                        frameborder="0"
                    ></iframe>
                )
                // </div>
            }
        </div>
    ),
    'media'
)

export { Media }

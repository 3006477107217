import { h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { Card } from '@app/api'
import { allRoutes, addCSRFToken } from '@app/core'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.reward_PROMOTIONS.form,
    initialized: false,
    tableHead: documents.reward_PROMOTIONS.tableHead,
    tableOptions: documents.reward_PROMOTIONS.tableOptions,
    tableAllOptions: documents.reward_PROMOTIONS.tableAllOptions,
    tableFilter: documents.reward_PROMOTIONS.filter,
    header: documents.reward_PROMOTIONS.header,
    document: documents.reward_PROMOTIONS,
    display: '',
    editId: null,
    promotions: [],
    currentUrl: '',
    kind: 'rewards',
    modeDuplicate: false,
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Content/pages/actions')

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout
            page="rewpromotions"
            customer={customer}
            currentPage={'reward'}
        >
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Card.getRewards}
                            updateUrl={actions.updateUrl}
                        ></Table>
                    ) : (
                        <div
                            class=""
                            key={
                                state.editId +
                                '-' +
                                state.initialized +
                                '-' +
                                Object.keys(state.document).length
                            }
                        >
                            {state.editId !== null ? (
                                <div
                                    class={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                    key={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        target={
                                            state.modeDuplicate
                                                ? Card.addReward
                                                : Card.editReward
                                        }
                                        secondtarget={Card.sendCardForm}
                                        backUrl={addCSRFToken(
                                            allRoutes[
                                                'private.reward.promotions'
                                            ].pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            ) : (
                                <div key={'adminform-creation'}>
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        target={Card.addReward}
                                        secondtarget={Card.sendCardForm}
                                        card={state.document}
                                        backUrl={addCSRFToken(
                                            allRoutes[
                                                'private.reward.promotions'
                                            ].pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'rewpromotions')

import { h } from '@app/utils'

const Replace = (props, children) => {
    if (props.mode === 'isempty') {
        if (
            props.isempty === '' ||
            props.isempty === null ||
            props.isempty === undefined
        ) {
            if (props.replacewith) {
                return props.replacewith
            } else {
                return ''
            }
        }
    }
    return children
}
export { Replace }

import { Card } from '@app/api'

const corposurvey_STATISTICS = {
    header: ['Statistique question'],
    target: Card.getCardStatisticsByKindAndPeriod,
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        type: {
            field: 'hidden',
            value: 'corpo-survey',
        },
        countResponsePeriod: {
            name: 'countResponsePeriod',
            type: 'int',
            label: '',
            field: 'int',
            value: '',
        },
        data: {
            name: 'data',
            type: 'dataviz',
            label: '',
            field: 'result',
            value: '',
        },
    },
}

export { corposurvey_STATISTICS }

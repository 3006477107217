import { t } from '@app/utils'
import * as constants from '@app/constants'

const card_SURVEYALERT = {
    header: ["Configurer l'alerte questionnaire", 'Alerte questionnaire'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: '',
        },
        type: {
            type: 'int',
            field: 'hidden',
            value: '',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        medicalStudy: {
            name: 'medicalStudy',
            type: 'string',
            label: 'Étude médicale',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_MEDICAL_STUDIES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une étude médicale',
                i18nMessage: t('Veuillez sélectionner une étude médicale'),
            },
        },
        parent: {
            name: 'parent',
            substitute: 'parent',
            type: 'autocomplete/cards/type[58',
            label: 'Carte parente',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigner une carte parent',
                i18nMessage: t('Veuillez renseigner une carte parent'),
            },
        },
        medicalStudyAlertColor: {
            name: 'medicalStudyAlertColor',
            type: 'int',
            label: "Couleur de l'alerte",
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.MEDICALSTUDYALERTCOLOR,
            value: '',
            display: {
                border: 'bottom',
            },
            beforeSend: {
                ifempty: null,
            },
        },
    },
}

export { card_SURVEYALERT }

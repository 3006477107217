import { h, t } from '@app/utils'
import {
    Avatar,
    Balloon,
    Button,
    Form,
    Tile,
    MessagePopup,
} from '@app/elements'

export const view = (state, actions) => (props, children) => (
    <div style={{ position: 'relative' }}>
        {state.messageActive && (
            <MessagePopup
                kind={'success'}
                active={'active'}
                duration={3000}
                durationCallback={() => actions.displaySuccessMessage(false)}
                message={t('Email de réinitialisation envoyé !')}
            ></MessagePopup>
        )}

        <Tile>
            <Tile.Content>
                <p
                    class="btzTitle btzXl"
                    style={{ 'margin-bottom': '0', 'height': '115px' }}
                >
                    Vous avez oublié
                    <br />
                    votre mot de passe ?
                </p>
            </Tile.Content>
        </Tile>
        <Form onsubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Form.Input
                    placeholder={t('adresse@mail.com')}
                    type="email"
                    id="email"
                    name="email"
                    value={state.email.value}
                    dynResize={true}
                    oninput={(event) =>
                        actions.onEmailInputDebouncedUpdate(event.target.value)
                    }
                    onfocus={() => actions.onEmailInputTouched()}
                    error={state.email.isTouched && !state.email.isValid}
                    classes={
                        state.email.isTouched
                            ? state.email.isValid
                                ? 'is-success'
                                : 'is-error'
                            : ''
                    }
                    required
                />
            </Form.Group>

            <Form.Group classes="btzForm-btzFooter">
                <Button
                    primary
                    active={state.email.isValid}
                    loading={state.formIsSubmitting}
                    onclick={(event) => actions.onStepSubmit(event)}
                >
                    {t('Envoyer')}
                </Button>
            </Form.Group>
        </Form>

        <Tile classes={'btzAlfred'}>
            <Tile.Icon>
                <Avatar.Alfred classes={'centered'} />
            </Tile.Icon>
            <Tile.Content>
                <Balloon
                    kind={state.api.kind}
                    title={t('MOT DE PASSE OUBLIE')}
                    style={{ 'white-space': 'normal' }}
                >
                    {state.api.message}
                </Balloon>
            </Tile.Content>
        </Tile>
    </div>
)

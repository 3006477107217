import { t, h, Component } from '@app/utils'
import { Button } from '@app/elements'
import { AFSUpAndDown } from './AFSUpAndDown'
import { isDefined, deepCopy, isInteger } from '@app/core'
import { debounce } from 'hyperapp-fx'
import * as constants from '@app/constants'
import './index.scss'

const AFScenarioUpAndDown = Component(
    {
        document: null,
        sendToParent: null,
        list: [],
        name: '',
        readOnly: false,
        // confirmDelete: false,
        conditionsDisplayed: false,
        currentConditionDisplayed: '',
        currentConditionIndexDisplayed: '',
        originalAlerts: null,
        rendering: false,
        model: {
            value1: 1,
            interval: 1,
            value: 'day',
            expressions: [],
            atLeastOneChange: true,
            list: constants.custo.PERIODS,
        },
        periods: Object.keys(constants.custo.PERIODS).map(
            (i) => constants.custo.PERIODS[i]
        ),
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.list) {
                state.list = props.list
            }
            if (props.document) {
                actions.setDocument(props.document)
                if (props.document.form.alerts) {
                    actions.setOriginalAlerts(
                        deepCopy(props.document.form.alerts)
                    )
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        addAlert: (kind) => (state, actions) => {
            let refModel = state.model
            let config = null
            if (kind === 'up') {
                config = state.document.form.alerts.value.up
            } else if (kind === 'down') {
                config = state.document.form.alerts.value.down
            }
            if (isDefined(config)) {
                config.push(deepCopy(refModel))
            }
            actions.setDocument(state.document)
        },
        //
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                if (value !== '' && !isInteger(value)) {
                    actions.setDocument(state.document)
                    return false
                }
                let document = state.document.form.seuils
                if (!Array.isArray(document.value)) {
                    document.value = ['', '', '', '']
                }
                let valueIdx = +name.replace('value', '')
                document.value[valueIdx - 1] = value
                state.document.form.seuils = document
                actions.setDocument(state.document)
            },
        onUpdateConditions:
            ({ target: { value } }) =>
            (state, actions) => {
                let document = state.document.form.alerts
                document.value = value
                state.document.form.alerts = document
                actions.setDocument(state.document)
            },
        selectThisConditionAnswer: (el) => (state, actions) => {
            let document = state.document.form.alerts
            let condition =
                document.value[state.currentConditionDisplayed][
                    state.currentConditionIndexDisplayed
                ].expressions
            if (condition.indexOf(el.kpi) > -1) {
                condition.splice(condition.indexOf(el.kpi), 1)
            } else {
                let tmpKpi = el.kpi.split('==')[0].replace(' ', '')
                let tmpCond = condition.filter((f) => f.indexOf(tmpKpi) > -1)[0]
                if (isDefined(tmpCond)) {
                    condition[condition.indexOf(tmpCond)] = el.kpi
                } else {
                    condition.push(el.kpi)
                }
            }
            document.value[state.currentConditionDisplayed][
                state.currentConditionIndexDisplayed
            ].expressions = condition
            state.document.form.alerts = document
            actions.setDocument(state.document)
            actions.rendering()
        },
        saveConditions: () => (state, actions) => {
            actions.setOriginalAlerts(deepCopy(state.document.form.alerts))
            //
            actions.toggleConditionsDisplay()
        },
        cancelConditions: () => (state, actions) => {
            let document = state.document
            if (
                isDefined(
                    state.originalAlerts.value[state.currentConditionDisplayed][
                        state.currentConditionIndexDisplayed
                    ]
                )
            ) {
                document.form.alerts.value[state.currentConditionDisplayed][
                    state.currentConditionIndexDisplayed
                ].expressions = deepCopy(
                    state.originalAlerts.value[state.currentConditionDisplayed][
                        state.currentConditionIndexDisplayed
                    ].expressions
                )
            } else {
                document.form.alerts.value[state.currentConditionDisplayed][
                    state.currentConditionIndexDisplayed
                ].expressions = deepCopy(state.model.expressions)
            }
            actions.setDocument(document)
            actions.toggleConditionsDisplay()
        },
        updateConditionsDisplay: (el) => (state, actions) => {
            actions.setConditionsDisplay(el.id)
            actions.setConditionsIndexDisplay(el.name)
            actions.toggleConditionsDisplay()
        },
        updateValues:
            ({ name, value, kind, idx }) =>
            (state, actions) => {
                let document = state.document
                let alerts = document.form.alerts
                alerts.value[kind][idx][name] = value
                document.alerts = alerts
                actions.setDocument(document)
            },
        updatePeriodValue: (el) => (state, actions) => {
            let name = el.target.getAttribute('data-name')
            let value = el.target.getAttribute('data-value')
            let kind = el.target.getAttribute('data-kind')
            let idx = el.target.getAttribute('data-idx')
            let document = state.document
            let alerts = document.form.alerts
            alerts.value[kind][idx][name] = value
            document.alerts = alerts
            actions.setDocument(document)
        },
        onDeleteAlert:
            ({ kind, index }) =>
            (state, actions) => {
                let target = state.document.form.alerts
                if (
                    isDefined(target) &&
                    isDefined(target.value) &&
                    isDefined(target.value[kind])
                ) {
                    target.value[kind].splice(index, 1)
                }
                state.document.form.alerts = target
                actions.setDocument(state.document)
            },
        toggleConditionsDisplay: () => (state) => ({
            conditionsDisplayed: !state.conditionsDisplayed,
            currentConditionDisplayed: !state.conditionsDisplayed
                ? state.currentConditionDisplayed
                : '',
        }),
        setConditionsDisplay: (newState) => (state) => ({
            currentConditionDisplayed: newState,
        }),
        setConditionsIndexDisplay: (newState) => (state) => ({
            currentConditionIndexDisplayed: newState,
        }),
        // updateConfirmModalDisplay: (el) => (state, actions) => {
        //     actions.setConditionsDisplay(el.id);
        //     actions.setConditionsIndexDisplay(el.name);
        //     actions.displayConfirmDelete(true);
        // },
        // displayConfirmDelete: newState => state => ({
        //     confirmDelete: newState,
        // }),
        setOriginalAlerts: (newState) => (state) => ({
            originalAlerts: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        rendering: () => (state) => ({
            rendering: !state.rendering,
        }),
        waitForLastInput: ({ target }) => debounce(240, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div
            key={props.key}
            class={
                'btzModalCustomHDS ' +
                (props.readonly ? '' : 'supModalContainer')
            }
        >
            {!state.conditionsDisplayed ? (
                <dummy>
                    {!props.readonly && (
                        <dummy>
                            <div class="btzModalCustomHDS-buttons">
                                <Button
                                    primary
                                    cancel
                                    flat
                                    onclick={() => props.onCancel()}
                                >
                                    {t('Annuler')}
                                </Button>
                                <Button
                                    primary
                                    flat
                                    onclick={() => props.onSaveDocument()}
                                >
                                    {t('Enregistrer la configuration')}
                                </Button>
                            </div>

                            <div class="btzModalCustomHDS-header">
                                <label class="sup-label afscenario-header-title">
                                    {state.document.header.title +
                                        ' (' +
                                        state.document.variable.unit +
                                        ')'}
                                </label>
                                <p class="afscenario-header-subtitle">
                                    {state.document.header.subtitle}
                                </p>
                            </div>
                        </dummy>
                    )}

                    {!props.readonly && (
                        <div class="alerting-obj-alert">
                            <div class="alerting-obj-alert-header">
                                <label class="sup-label alerting-obj-alert-header-title">
                                    {t('Configuration des alertes')}
                                </label>
                                <p class="alerting-obj-alert-header-subtitle">
                                    {t(
                                        'Le cardiologue/coach reçoit une alerte si :'
                                    )}
                                </p>
                            </div>

                            <div class="alerting-obj-alert-wrapper">
                                <p
                                    class="afscenario-header-subtitle"
                                    style={{
                                        'text-align': 'left',
                                        'margin': '0 0 10px 0',
                                    }}
                                >
                                    {t('Augmentation')}
                                </p>
                                <div class="">
                                    {state.document.form.alerts.value.up.map(
                                        (u, idx) => (
                                            <AFSUpAndDown
                                                id="up"
                                                name={idx}
                                                key={
                                                    'alert-up-' +
                                                    idx +
                                                    '-' +
                                                    state.rendering
                                                }
                                                ontoggle={
                                                    actions.updateConditionsDisplay
                                                }
                                                ondelete={actions.onDeleteAlert}
                                                updateValues={
                                                    actions.updateValues
                                                }
                                                updatePeriodValue={
                                                    actions.updatePeriodValue
                                                }
                                                document={u}
                                                header={state.document.header}
                                                index={idx}
                                            ></AFSUpAndDown>
                                        )
                                    )}
                                </div>
                                <Button
                                    primary
                                    flat
                                    onclick={() => actions.addAlert('up')}
                                >
                                    {t('+ Alerte d‘augmentation')}
                                </Button>
                            </div>

                            <div class="alerting-obj-alert-wrapper">
                                <p
                                    class="afscenario-header-subtitle"
                                    style={{
                                        'text-align': 'left',
                                        'margin': '0 0 10px 0',
                                    }}
                                >
                                    {t('Diminition')}
                                </p>
                                <div class="">
                                    {state.document.form.alerts.value.down.map(
                                        (d, idx) => (
                                            <AFSUpAndDown
                                                id="down"
                                                name={idx}
                                                key={
                                                    'alert-down-' +
                                                    idx +
                                                    '-' +
                                                    state.rendering
                                                }
                                                ontoggle={
                                                    actions.updateConditionsDisplay
                                                }
                                                ondelete={actions.onDeleteAlert}
                                                updateValues={
                                                    actions.updateValues
                                                }
                                                updatePeriodValue={
                                                    actions.updatePeriodValue
                                                }
                                                document={d}
                                                header={state.document.header}
                                                index={idx}
                                            ></AFSUpAndDown>
                                        )
                                    )}
                                </div>
                                <Button
                                    primary
                                    flat
                                    onclick={() => actions.addAlert('down')}
                                >
                                    {t('+ Alerte de diminution')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {props.readonly &&
                        isDefined(state.document.form.alerts.value) && (
                            <div class="alerting-obj-alert-upanddowns">
                                {isDefined(
                                    state.document.form.alerts.value.up
                                ) && (
                                    <div class="alerting-obj-alert-up">
                                        {state.document.form.alerts.value.up.map(
                                            (m, idx) => (
                                                <div
                                                    key={
                                                        props.key +
                                                        idx +
                                                        '-' +
                                                        m.value1 +
                                                        m.interval +
                                                        m.value
                                                    }
                                                    class="alerting-obj-alert-upanddown"
                                                >
                                                    <p>
                                                        {t('Augmentation de')}
                                                    </p>
                                                    <b>{m.value1}</b>
                                                    <p>
                                                        {t('kg en')}
                                                        <b>
                                                            {m.interval +
                                                                ' ' +
                                                                state.periods.filter(
                                                                    (f) =>
                                                                        f.key ===
                                                                        m.value
                                                                )[0].value}
                                                        </b>
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                                {isDefined(
                                    state.document.form.alerts.value.down
                                ) && (
                                    <div class="alerting-obj-alert-down">
                                        {state.document.form.alerts.value.down.map(
                                            (m, idx) => (
                                                <div
                                                    key={
                                                        props.key +
                                                        idx +
                                                        '-' +
                                                        m.value1 +
                                                        m.interval +
                                                        m.value
                                                    }
                                                    class="alerting-obj-alert-upanddown"
                                                >
                                                    <p>{t('Diminition de')}</p>
                                                    <b>{m.value1}</b>
                                                    <p>
                                                        {t('kg en')}
                                                        <b>
                                                            {m.interval +
                                                                ' ' +
                                                                state.periods.filter(
                                                                    (f) =>
                                                                        f.key ===
                                                                        m.value
                                                                )[0].value}
                                                        </b>
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                </dummy>
            ) : (
                <dummy>
                    {!props.readonly && (
                        <div class="btzModalCustomHDS-buttons">
                            <Button
                                primary
                                cancel
                                flat
                                onclick={() => actions.cancelConditions()}
                            >
                                {t('Annuler')}
                            </Button>
                            <Button
                                primary
                                flat
                                onclick={() => actions.saveConditions()}
                            >
                                {t('Enregistrer')}
                            </Button>
                        </div>
                    )}
                    <div class="btzModalCustomHDS-header">
                        <label class="sup-label afscenario-header-title">
                            {state.document.header.title +
                                ' (' +
                                state.document.variable.unit +
                                ')'}
                        </label>
                        <p class="afscenario-header-subtitle">
                            {state.document.header.subtitle}
                        </p>
                    </div>

                    <div class="alerting-obj-alert alerting-obj-alert-conditions">
                        <div class="alerting-obj-alert-header">
                            <p
                                class="alerting-obj-alert-header-subtitle"
                                style={{
                                    'white-space': 'initial',
                                    'margin': 'auto',
                                }}
                            >
                                {t('Si les limites des seuils de') + ' '}
                                <b>{state.document.header.title}</b>
                                {' ' +
                                    t(
                                        'définis pour ce patient sont dépassées, les questions suivantes lui seront posées.'
                                    )}
                            </p>
                            <p
                                class="alerting-obj-alert-header-subtitle"
                                style={{
                                    'white-space': 'initial',
                                    'margin': 'auto',
                                }}
                            >
                                {t(
                                    'Pour chaque question, vous pouvez sélectionner la réponse qui conditionnera la remontée d’une alerte :'
                                )}
                            </p>
                        </div>
                        <div class="alerting-obj-alert-conditions-wrapper">
                            {state.document.form.alerts.triggerConditions.map(
                                (m, idx) => (
                                    <div class="alerting-obj-alert-condition">
                                        <p class="alerting-obj-alert-condition-title">
                                            <b>{+idx + 1 + '. '}</b>
                                            {t(m.question)}
                                        </p>
                                        <div class="alerting-obj-alert-condition-answers">
                                            {m.answer.map((a) => (
                                                <p
                                                    class={
                                                        'alerting-obj-alert-condition-answer ' +
                                                        (Array.isArray(
                                                            state.document.form
                                                                .alerts.value[
                                                                state
                                                                    .currentConditionDisplayed
                                                            ][
                                                                state
                                                                    .currentConditionIndexDisplayed
                                                            ].expressions
                                                        ) &&
                                                        state.document.form.alerts.value[
                                                            state
                                                                .currentConditionDisplayed
                                                        ][
                                                            state
                                                                .currentConditionIndexDisplayed
                                                        ].expressions.indexOf(
                                                            a.kpi
                                                        ) > -1
                                                            ? 'active'
                                                            : '')
                                                    }
                                                    onclick={() =>
                                                        actions.selectThisConditionAnswer(
                                                            a
                                                        )
                                                    }
                                                >
                                                    {t(a.label)}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </dummy>
            )}
        </div>
    ),
    'afscenarioupanddown'
)

export { AFScenarioUpAndDown }

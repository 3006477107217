import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const recipe_CARD = {
    filter: [],
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'date', label: 'date création' },
        { key: 'title', label: 'titre' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/recipes/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/recipes/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editRecipe,
        },
        // {'key': 'frequency', 'label': 'Changer la fréquence', 'kind': 'list-api', 'list': Object.keys(constants.custo.FREQUENCY).map(i => constants.custo.FREQUENCY[i]), 'action': Card.editRecipe},
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteRecipe,
        },
    ],
    tableAllOptions: {
        label: 'RECETTES SÉLECTIONNÉES',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteRecipe,
            },
        ],
    },
    header: ['Configurer la recette'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        preview: {
            field: 'hidden',
            value: true,
            sending: false,
        },
        template: {
            field: 'hidden',
            value: 'recipe',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        persons: {
            name: 'persons',
            type: 'int',
            label: 'Nombre de personnes',
            field: 'int',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner un nombre de personnes',
                i18nMessage: t('Veuillez renseigner un nombre de personnes'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de la recette',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Résumé de la recette',
            field: 'textarea',
            placeholder:
                'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de la carte',
                i18nMessage: t(
                    'Veuillez renseigner la description de la carte'
                ),
            },
        },
        baking: {
            name: 'baking',
            type: 'translation',
            label: 'Cuisson',
            field: 'text',
            placeholder: 'Ajoutez le temps en minutes, suivi de "min"',
            value: {},
        },
        making: {
            name: 'making',
            type: 'translation',
            label: 'Préparation',
            field: 'text',
            placeholder: 'Ajoutez le temps en minutes, suivi de "min"',
            value: {},
        },
        rest: {
            name: 'rest',
            type: 'translation',
            label: 'Repos',
            field: 'text',
            placeholder: 'Ajoutez le temps en minutes, suivi de "min"',
            value: {},
        },
        ingredients: {
            name: 'ingredients',
            type: 'translation',
            label: 'Ingrédients',
            field: 'textarea',
            placeholder: 'Renseignez ici les ingrédients de votre recette',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner au moins un ingrédients',
                i18nMessage: t('Veuillez renseigner au moins un ingrédients'),
            },
        },
        tags: {
            name: 'tags',
            type: 'tags',
            label: 'Tag Aliment',
            field: 'dyninput-to-div',
            searchbox: true,
            model: { key: 'id', value: 'alias' },
            list: 'dynamic',
            placeholder: {
                input: 'Aucune',
                textarea: '',
            },
            value: '',
        },
        glycemicLoad: {
            name: 'glycemicLoad',
            type: 'int',
            label: 'Charge glycémique',
            field: 'int',
            placeholder: '',
            value: '',
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
        steps: {
            // for recipe
            name: 'steps',
            type: 'translation',
            label: 'Étapes de la recette',
            field: 'step',
            value: '',
            placeholder: 'Renseignez ici les ingrédients de votre recette',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneFilled',
                message: 'Veuillez renseigner des étapes pour votre recette',
                i18nMessage: t(
                    'Veuillez renseigner des étapes pour votre recette'
                ),
            },
        },
        alternative: {
            name: 'alternative',
            type: 'translation',
            label: 'Variante',
            field: 'textarea',
            placeholder: 'Renseignez ici les ingrédients de votre recette',
            value: {},
        },
        extras: {
            // for recipe
            name: 'extras',
            type: 'translation',
            label: 'Ingrédients extra',
            field: 'extra',
            value: '',
            placeholder: {
                title: 'Titre de l‘extra',
                description: 'Description de l‘extra',
            },
        },
    },
}

export { recipe_CARD }

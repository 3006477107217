import { t, h, Component } from '@app/utils'
import { Button, AdminFormCheckBoxLinkedModal } from '@app/elements'
import { isDefined, deepCopy, isInteger } from '@app/core'
import { debounce } from 'hyperapp-fx'
import './index.scss'

const AFScenarioSeuils = Component(
    {
        document: null,
        sendToParent: null,
        list: [],
        name: '',
        readOnly: false,
        conditionsDisplayed: false,
        currentConditionDisplayed: '',
        originalAlerts: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.list) {
                state.list = props.list
            }
            if (props.document) {
                actions.setDocument(props.document)
                if (props.document.form.alerts) {
                    actions.setOriginalAlerts(
                        deepCopy(props.document.form.alerts)
                    )
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                if (value !== '' && !isInteger(value)) {
                    actions.setDocument(state.document)
                    return false
                }
                let document = state.document.form.seuils
                if (!Array.isArray(document.value)) {
                    document.value = ['', '', '', '']
                }
                let valueIdx = +name.replace('value', '')
                document.value[valueIdx - 1] = value
                state.document.form.seuils = document
                actions.setDocument(state.document)
            },
        onUpdateConditions:
            ({ target: { value } }) =>
            (state, actions) => {
                let document = state.document.form.alerts
                document.value = value
                state.document.form.alerts = document
                actions.setDocument(state.document)
            },
        selectThisConditionAnswer: (el) => (state, actions) => {
            let document = state.document.form.alerts
            let condition = document.value[state.currentConditionDisplayed]
            if (condition.indexOf(el.kpi) > -1) {
                condition.splice(condition.indexOf(el.kpi), 1)
            } else {
                let tmpKpi = el.kpi.split('==')[0].replace(' ', '')
                let tmpCond = condition.filter((f) => f.indexOf(tmpKpi) > -1)[0]
                if (isDefined(tmpCond)) {
                    condition[condition.indexOf(tmpCond)] = el.kpi
                } else {
                    condition.push(el.kpi)
                }
            }
            document.value[state.currentConditionDisplayed] = condition
            state.document.form.alerts = document
            actions.setDocument(state.document)
        },
        saveConditions: () => (state, actions) => {
            actions.setOriginalAlerts(deepCopy(state.document.form.alerts))
            //
            actions.toggleConditionsDisplay()
        },
        cancelConditions: () => (state, actions) => {
            let document = state.document
            if (
                isDefined(
                    state.originalAlerts.value[state.currentConditionDisplayed]
                )
            ) {
                document.form.alerts.value[state.currentConditionDisplayed] =
                    deepCopy(
                        state.originalAlerts.value[
                            state.currentConditionDisplayed
                        ]
                    )
            } else {
                document.form.alerts.value[state.currentConditionDisplayed] = []
            }
            actions.setDocument(document)
            actions.toggleConditionsDisplay()
        },
        updateConditionsDisplay: (el) => (state, actions) => {
            actions.setConditionsDisplay(el.id)
            actions.toggleConditionsDisplay()
        },
        toggleConditionsDisplay: () => (state) => ({
            conditionsDisplayed: !state.conditionsDisplayed,
            currentConditionDisplayed: !state.conditionsDisplayed
                ? state.currentConditionDisplayed
                : '',
        }),
        setConditionsDisplay: (newState) => (state) => ({
            currentConditionDisplayed: newState,
        }),
        setOriginalAlerts: (newState) => (state) => ({
            originalAlerts: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        waitForLastInput: ({ target }) => debounce(240, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div
            key={props.key}
            class={
                'btzModalCustomHDS ' +
                (props.readonly ? '' : 'supModalContainer')
            }
        >
            {!state.conditionsDisplayed ? (
                <dummy>
                    {!props.readonly && (
                        <dummy>
                            <div class="btzModalCustomHDS-buttons">
                                <Button
                                    primary
                                    cancel
                                    flat
                                    onclick={() => props.onCancel()}
                                >
                                    {t('Annuler')}
                                </Button>
                                <Button
                                    primary
                                    flat
                                    onclick={() => props.onSaveDocument()}
                                >
                                    {t('Enregistrer la configuration')}
                                </Button>
                            </div>

                            <div class="btzModalCustomHDS-header">
                                <label class="sup-label afscenario-header-title">
                                    {state.document.header.title +
                                        ' (' +
                                        state.document.variable.unit +
                                        ')'}
                                </label>
                                <p class="afscenario-header-subtitle">
                                    {state.document.header.subtitle}
                                </p>
                            </div>
                        </dummy>
                    )}

                    <div class="alerting-obj-alert alerting-obj-alert-seuils">
                        {!props.readonly && (
                            <div class="alerting-obj-alert-header">
                                <label class="sup-label alerting-obj-alert-header-title">
                                    {t('Configuration des seuils')}
                                </label>
                            </div>
                        )}

                        {isDefined(state.document.form.seuils) && (
                            <dummy>
                                <div
                                    key={props.key + '-value1'}
                                    class="alerting-obj-alert-seuil alerting-obj-alert-seuils-reallylow"
                                >
                                    <div class="alerting-obj-alert-seuils-line alerting-obj-alert-seuils-reallylow-line"></div>
                                    <div class="alerting-obj-alert-seuils-input alerting-obj-alert-seuils-reallylow-input">
                                        <input
                                            class="hb-default-form-input hb-default-form-input-unit"
                                            oninput={actions.waitForLastInput}
                                            placeholder=""
                                            name="value1"
                                            readonly={
                                                props.readonly
                                                    ? 'readonly'
                                                    : false
                                            }
                                            value={
                                                Array.isArray(
                                                    state.document.form.seuils
                                                        .value
                                                ) &&
                                                state.document.form.seuils
                                                    .value[0] !== undefined
                                                    ? state.document.form.seuils
                                                          .value[0]
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <p class="alerting-obj-alert-seuil-label">
                                        {t('Très faible')}
                                    </p>
                                </div>
                                <div
                                    key={props.key + '-value2'}
                                    class="alerting-obj-alert-seuil alerting-obj-alert-seuils-low"
                                >
                                    <div class="alerting-obj-alert-seuils-line alerting-obj-alert-seuils-low-line"></div>
                                    <div class="alerting-obj-alert-seuils-input alerting-obj-alert-seuils-low-input">
                                        <input
                                            class="hb-default-form-input hb-default-form-input-unit"
                                            oninput={actions.waitForLastInput}
                                            placeholder=""
                                            name="value2"
                                            readonly={
                                                props.readonly
                                                    ? 'readonly'
                                                    : false
                                            }
                                            value={
                                                Array.isArray(
                                                    state.document.form.seuils
                                                        .value
                                                ) &&
                                                state.document.form.seuils
                                                    .value[1] !== undefined
                                                    ? state.document.form.seuils
                                                          .value[1]
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <p class="alerting-obj-alert-seuil-label">
                                        {t('Faible')}
                                    </p>
                                </div>
                                <div class="alerting-obj-alert-seuil alerting-obj-alert-seuils-normal">
                                    <div class="alerting-obj-alert-seuils-line alerting-obj-alert-seuils-high-normal"></div>
                                    <p class="alerting-obj-alert-seuil-label">
                                        {t('Normal')}
                                    </p>
                                </div>
                                <div
                                    key={props.key + '-value3'}
                                    class="alerting-obj-alert-seuil alerting-obj-alert-seuils-high"
                                >
                                    <div class="alerting-obj-alert-seuils-input alerting-obj-alert-seuils-high-input">
                                        <input
                                            class="hb-default-form-input hb-default-form-input-unit"
                                            oninput={actions.waitForLastInput}
                                            placeholder=""
                                            name="value3"
                                            readonly={
                                                props.readonly
                                                    ? 'readonly'
                                                    : false
                                            }
                                            value={
                                                Array.isArray(
                                                    state.document.form.seuils
                                                        .value
                                                ) &&
                                                state.document.form.seuils
                                                    .value[2] !== undefined
                                                    ? state.document.form.seuils
                                                          .value[2]
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div class="alerting-obj-alert-seuils-line alerting-obj-alert-seuils-high-line"></div>
                                    <p class="alerting-obj-alert-seuil-label">
                                        {t('Élevé')}
                                    </p>
                                </div>
                                <div
                                    key={props.key + '-value4'}
                                    class="alerting-obj-alert-seuil alerting-obj-alert-seuils-reallyhigh"
                                >
                                    <div class="alerting-obj-alert-seuils-input alerting-obj-alert-seuils-reallyhigh-input">
                                        <input
                                            class="hb-default-form-input hb-default-form-input-unit"
                                            oninput={actions.waitForLastInput}
                                            placeholder=""
                                            name="value4"
                                            readonly={
                                                props.readonly
                                                    ? 'readonly'
                                                    : false
                                            }
                                            value={
                                                Array.isArray(
                                                    state.document.form.seuils
                                                        .value
                                                ) &&
                                                state.document.form.seuils
                                                    .value[3] !== undefined
                                                    ? state.document.form.seuils
                                                          .value[3]
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div class="alerting-obj-alert-seuils-line alerting-obj-alert-seuils-reallyhigh-line"></div>
                                    <p class="alerting-obj-alert-seuil-label">
                                        {t('Très élevé')}
                                    </p>
                                </div>
                            </dummy>
                        )}
                    </div>

                    {!props.readonly && (
                        <div class="alerting-obj-alert">
                            <div class="alerting-obj-alert-header">
                                <label class="sup-label alerting-obj-alert-header-title">
                                    {t('Configuration des alertes')}
                                </label>
                                <p class="alerting-obj-alert-header-subtitle">
                                    {t(
                                        'Le cardiologue/coach reçoit une alerte si :'
                                    )}
                                </p>
                            </div>
                            <AdminFormCheckBoxLinkedModal
                                key={props.key}
                                name={state.document.form.alerts.name}
                                document={state.document.form.alerts}
                                ontoggle={actions.updateConditionsDisplay}
                                oninput={actions.onUpdateConditions}
                                onclick={actions.onUpdateConditions}
                            ></AdminFormCheckBoxLinkedModal>
                        </div>
                    )}
                </dummy>
            ) : (
                <dummy>
                    {!props.readonly && (
                        <div class="btzModalCustomHDS-buttons">
                            <Button
                                primary
                                cancel
                                flat
                                onclick={() => actions.cancelConditions()}
                            >
                                {t('Annuler')}
                            </Button>
                            <Button
                                primary
                                flat
                                onclick={() => actions.saveConditions()}
                            >
                                {t('Enregistrer')}
                            </Button>
                        </div>
                    )}
                    <div class="btzModalCustomHDS-header">
                        <label class="sup-label afscenario-header-title">
                            {state.document.header.title +
                                ' (' +
                                state.document.variable.unit +
                                ')'}
                        </label>
                        <p class="afscenario-header-subtitle">
                            {state.document.header.subtitle}
                        </p>
                    </div>

                    <div class="alerting-obj-alert alerting-obj-alert-conditions">
                        <div class="alerting-obj-alert-header">
                            <p
                                class="alerting-obj-alert-header-subtitle"
                                style={{
                                    'white-space': 'initial',
                                    'margin': 'auto',
                                }}
                            >
                                {t('Si les limites des seuils de') + ' '}
                                <b>{state.document.header.title}</b>
                                {' ' +
                                    t(
                                        'définis pour ce patient sont dépassées, les questions suivantes lui seront posées.'
                                    )}
                            </p>
                            <p
                                class="alerting-obj-alert-header-subtitle"
                                style={{
                                    'white-space': 'initial',
                                    'margin': 'auto',
                                }}
                            >
                                {t(
                                    'Pour chaque question, vous pouvez sélectionner la réponse qui conditionnera la remontée d’une alerte :'
                                )}
                            </p>
                        </div>
                        <div class="alerting-obj-alert-conditions-wrapper">
                            {state.document.form.alerts.triggerConditions.map(
                                (m, idx) => (
                                    <div class="alerting-obj-alert-condition">
                                        <p class="alerting-obj-alert-condition-title">
                                            <b>{+idx + 1 + '. '}</b>
                                            {t(m.question)}
                                        </p>
                                        <div class="alerting-obj-alert-condition-answers">
                                            {m.answer.map((a) => (
                                                <p
                                                    class={
                                                        'alerting-obj-alert-condition-answer ' +
                                                        (Array.isArray(
                                                            state.document.form
                                                                .alerts.value[
                                                                state
                                                                    .currentConditionDisplayed
                                                            ]
                                                        ) &&
                                                        state.document.form.alerts.value[
                                                            state
                                                                .currentConditionDisplayed
                                                        ].indexOf(a.kpi) > -1
                                                            ? 'active'
                                                            : '')
                                                    }
                                                    onclick={() =>
                                                        actions.selectThisConditionAnswer(
                                                            a
                                                        )
                                                    }
                                                >
                                                    {t(a.label)}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </dummy>
            )}
        </div>
    ),
    'afscenarioseuils'
)

export { AFScenarioSeuils }

import { h } from '@app/utils'
import { Avatar, Balloon, Button, Form, Tile, Trans } from '@app/elements'

export const view = (state, actions) => (props, children) => (
    <div style={{ position: 'relative' }}>
        <Tile>
            <Tile.Content>
                <p
                    class="btzTitle btzXl"
                    style={{ 'margin-bottom': '0', 'height': '115px' }}
                >
                    Connectez-vous avec
                    <br />
                    vos identifiants
                </p>
            </Tile.Content>
        </Tile>

        <Form onsubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Form.Input
                    autocomplete="false"
                    placeholder={<Trans>adresse@mail.com</Trans>}
                    type="email"
                    id="email"
                    name="email"
                    value={state.email.value}
                    oncreate={(el) => {
                        setTimeout(() => {
                            if (el.matches(':-webkit-autofill')) {
                                el.classList.add('is-autofilled')
                            }
                            el.focus()
                            el.select()
                        }, 1000)
                    }}
                    onanimationstart={(event) =>
                        actions.onEmailAnimationStart(event.target.value)
                    }
                    oninput={(event) =>
                        actions.onEmailInputDebouncedUpdate(event.target.value)
                    }
                    onfocus={() => actions.onEmailInputTouched()}
                    error={state.email.isTouched && !state.email.isValid}
                    required
                />
                <Form.Input
                    autocomplete="false"
                    placeholder={<Trans>mot de passe</Trans>}
                    type={state.password.isVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={state.password.value}
                    oncreate={(el) => {
                        setTimeout(() => {
                            if (el.matches(':-webkit-autofill')) {
                                el.classList.add('is-autofilled')
                            }
                        }, 1000)
                    }}
                    onanimationstart={(event) =>
                        actions.onPasswordAnimationStart(event.target.value)
                    }
                    oninput={(e) =>
                        actions.onPasswordInputDebouncedUpdate(e.target.value)
                    }
                    onfocus={() => actions.onPasswordInputTouched()}
                    toggle={() => actions.onPasswordClickUpdate()}
                    error={state.password.isTouched && !state.password.isValid}
                    required
                />
            </Form.Group>
            <Button
                link
                block
                style={{ display: 'block' }}
                onclick={() => actions.goToNextStep()}
            >
                <Trans>Mot de passe oublié ?</Trans>
            </Button>

            <Form.Group classes="btzForm-btzFooter">
                <Button
                    primary
                    block
                    onclick={(event) => actions.onStepSubmit(event)}
                    active={state.email.isValid && state.password.isValid}
                    disabled={!(state.email.isValid && state.password.isValid)}
                >
                    <Trans>Valider</Trans>
                </Button>
            </Form.Group>
        </Form>

        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
            <Tile.Icon>
                <Avatar.Alfred classes={'centered'} />
            </Tile.Icon>
            <Tile.Content>
                {!!state.email.value === false &&
                    !!state.password.value === false && (
                        <Balloon
                            title={'CONNEXION'}
                            atagTitle={'alfred@betterise.me'}
                            atag={'mailto:alfred@betterise.me'}
                            style={{ 'white-space': 'normal' }}
                        >
                            <Trans>Vous avez une questions ?</Trans> <br />{' '}
                            <Trans>Contactez moi</Trans>
                        </Balloon>
                    )}
                {state.email.isTouched === true && (
                    <Balloon kind={'error'}>
                        <Trans>Merci de renseigner une adresse mail</Trans>
                    </Balloon>
                )}
            </Tile.Content>
        </Tile>
    </div>
)

import { h, Component } from '@app/utils'
import './index.scss'

import LoginLayout from '@app/layouts/register/layout'

import Authentication from './authentication'
import Recovery from './recovery'
import Blockd from './blockd'

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null,
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null,
    },
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
}

const view =
    (state, actions) =>
    ({ location, match, customer }, children) => (
        <div key="login">
            <LoginLayout page="login" customer={customer}>
                {/* Default view */}
                {match.isExact && (
                    <Authentication
                        initial={state.email}
                        customer={customer}
                        send={actions.setState}
                    />
                )}

                {location.pathname === '/login/recovery' && (
                    <Recovery
                        initial={state.email}
                        customer={customer}
                        send={actions.setState}
                    />
                )}

                {location.pathname === '/login/blocked' && (
                    <Blockd
                        initial={state.email}
                        customer={customer}
                        send={actions.setState}
                    />
                )}
            </LoginLayout>
        </div>
    )

export { Authentication, Recovery, Blockd }

export default Component(state, actions, view, 'login')

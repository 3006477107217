import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getCustomers() {
    return queueQueries(`${APP_API_URL}/admin/settings/parent-customers/`, 'GET')
}

function getCustomerDaugthers() {
    return queueQueries(
        `${APP_API_URL}/admin/settings/parent-customers/?hasParent=true`
    )
}

function getAdminLogs(page = 0) {
    return queueQueries(`${APP_API_URL}/admin/settings/admin-logs/${page}/`, 'GET')
}

function getTimezones(qparam = '') {
    return queueQueries(`${APP_API_URL}/timezones/${qparam}`, 'GET')
}

function getAdminMessage() {
    return queueQueries(`${APP_API_URL}/admin/settings/admin-message/`, 'GET')
}
function setAdminMessage(content, type) {
    return queueQueries(
        `${APP_API_URL}/admin/settings/admin-message/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { content: content, type: type }
    )
}

function getPlateformStats() {
    return queueQueries(`${APP_API_URL}/admin/theraflow/statistics/`, 'GET')
}

function getAdminUserInfos() {
    return queueQueries(`${APP_API_URL}/admin/users/infos/`, 'GET')
}

function getAdminSecurityInfos() {
    return queueQueries(`${APP_API_URL}/admin/security-settings/`, 'GET')
}

function updateAdminSecurityInfos(id, data) {
    return queueQueries(
        `${APP_API_URL}/admin/security-settings/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function getAdminMedia() {
    return queueQueries(`${APP_API_URL}/admin/medias/default/`, 'GET')
}
function sendAdminMedia(file) {
    let formData = new FormData()
    formData.append('media', file)
    return queueQueries(
        `${APP_API_URL}/admin/medias/default/`,
        'POST',
        null,
        true,
        true,
        true,
        true,
        true,
        formData
    )
}

function verifyKPIS() {
    return queueQueries(`${APP_API_URL}/admin/settings/check-kpis/`, 'GET')
}

function sendBatch(data, lang = 'fr', name = '', type = '') {
    var file = data.f[0]
    let formData = new FormData()
    formData.append('f', file)

    if (type === '') {
        return queueQueries(
            `${APP_API_URL}/admin/settings/batch/?l=${lang}&name=${name}`,
            'POST',
            null,
            true,
            true,
            true,
            true,
            true,
            formData
        )
    } else {
        return queueQueries(
            `${APP_API_URL}/admin/settings/batch/?l=${lang}&name=${name}&c=${type}`,
            'POST',
            null,
            true,
            true,
            true,
            true,
            true,
            formData
        )
    }
}

function sendPatients(data, emailDoctorInsert) {
    var file = data.f[0]
    let formData = new FormData()
    formData.append('f', file)
    formData.append('emailDoctorInsert', emailDoctorInsert)

    return queueQueries(
        `${APP_API_URL}/admin/settings/import-patient-repeatable-study/`,
        'POST',
        null,
        true,
        true,
        true,
        true,
        true,
        formData
    )
}

function sendSurveyAnswer(data) {
    var file = data.f[0]
    let formData = new FormData()
    formData.append('f', file)

    return queueQueries(
        `${APP_API_URL}/admin/settings/import-repeatable-study-answer/`,
        'POST',
        null,
        true,
        true,
        true,
        true,
        true,
        formData
    )
}

export const Settings = {
    getCustomers,
    getCustomerDaugthers,
    getAdminLogs,
    getAdminMessage,
    setAdminMessage,
    getPlateformStats,
    getAdminUserInfos,
    getAdminMedia,
    sendAdminMedia,
    verifyKPIS,
    sendBatch,
    sendPatients,
    sendSurveyAnswer,
    getAdminSecurityInfos,
    updateAdminSecurityInfos,
    getTimezones,
}

import { t } from '@app/utils'
import { User, displayErrors } from '@app/api'
import { allRoutes } from '@app/core'
import { settings } from '@app/core/settings'

const { APP_WEB_URL } = settings

Math.fmod = function (a, b) {
    return Number((a - Math.floor(a / b) * b).toPrecision(8))
}

Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1)
    var jul = new Date(this.getFullYear(), 6, 1)
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset()
}

const $ = function (selector) {
    if (!selector) {
        return null
    }

    const els = document.querySelectorAll(selector)
    return selector.indexOf('#') > -1 ? els[0] : els
}

const scrollTo = function (element, to, duration) {
    if (duration <= 0) return
    if (element === null) {
        element = document.scrollingElement || document.documentElement
    }
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    // document.scrollingElement || document.documentElement
    setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick
        if (element.scrollTop === to) return
        scrollTo(element, to, duration - 10)
    }, 10)
}

const getClosest = function (elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                var matches = (
                        this.document || this.ownerDocument
                    ).querySelectorAll(s),
                    i = matches.length
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1
            }
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem
    }
    return null
}

const getYMDfromDateObj = function (
    obj,
    readable = false,
    ymd = false,
    withHour = false
) {
    let utcDate = createUTCDate(obj, false)
    //
    if (!obj || !isDefined(obj) || obj === '') {
        obj = new Date()
    } else if (Object.prototype.toString.call(obj) !== '[object Date]') {
        obj = new Date(obj)
    }

    let dateDay = obj.getUTCDate()
    let dateMonth = obj.getUTCMonth() + 1
    let dateYear = obj.getUTCFullYear()

    if (+dateDay < 10) {
        dateDay = '0' + dateDay
    }
    if (+dateMonth < 10) {
        dateMonth = '0' + dateMonth
    }

    let dateTime = ''
    if (withHour) {
        let utcTime =
            (+utcDate.getHours() < 10
                ? '0' + +utcDate.getHours()
                : utcDate.getHours()) +
            ':' +
            (+utcDate.getMinutes() < 10
                ? '0' + +utcDate.getMinutes()
                : utcDate.getMinutes()) +
            ':' +
            (+utcDate.getSeconds() < 10
                ? '0' + +utcDate.getSeconds()
                : utcDate.getSeconds())
        dateTime = ' ' + t('à') + ' ' + utcTime
    }
    if (readable) {
        if (ymd) {
            return dateYear + '/' + dateMonth + '/' + dateDay + dateTime
        }
        return dateDay + '/' + dateMonth + '/' + dateYear + dateTime
    }

    return {
        day: dateDay,
        month: dateMonth,
        year: dateYear,
        time: dateTime,
    }
}

const getDateObjFromYMD = function (ymd) {
    if (!ymd) {
        return null
    }

    let dateYear = ymd.substring(0, 4)
    let dateMonth = ymd.substring(4, 6)
    let dateDay = ymd.substring(6, 8)

    var parsedDate = {
        // date: new Date(dateYear + '-' + dateMonth + '-' + dateDay),
        date: dateYear + '-' + dateMonth + '-' + dateDay,
        timezone: 'UTC',
        timezone_type: 3,
    }

    return parsedDate
}

const getMinHourFromMinutes = function (min) {
    let hours = Math.floor(min / 60)
    let minutes = Math.round(min % 60)

    if (minutes < 10) {
        minutes = '0' + minutes
    }

    return { min: minutes, h: hours }
}

const getQueryVariable = function (variable, editQuery = true) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
            if (editQuery) {
                window.history.pushState(
                    '',
                    '',
                    removeURLParameter(
                        window.location.pathname + window.location.search,
                        variable
                    )
                )
            }
            return pair[1]
        }
    }

    return false
}

const removeURLParameter = function (url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?')
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '='
        var pars = urlparts[1].split(/[&;]/g)

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0; ) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1)
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
        return url
    } else {
        return url
    }
}

const createCookie = function (name, value, days) {
    var expires = null
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toGMTString()
    } else {
        expires = ''
    }
    document.cookie = name + '=' + value + expires + '; path=/'
}

const readCookie = function (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

const eraseCookie = function (name) {
    this.createCookie(name, '', -1)
}

const isPublicRoute = function () {
    var currentUrl = window.location.href
    // var publicRoutes = [];
    // var notPublicRoutes = [];
    var isPublic = false
    if (window.location.pathname === '/') {
        isPublic = true
    } else {
        // for (var route in publicRoutes) {
        //     if (currentUrl.indexOf(publicRoutes[route]) > -1) {
        //         isPublic =  true;
        //     }
        // }
        // for (var notroute in notPublicRoutes) {
        //     if (currentUrl.indexOf(notPublicRoutes[notroute]) > -1) {
        //         isPublic =  false;
        //     }
        // }
    }
    return isPublic
}

const checkForValidCSRFToken = function () {
    if (isPublicRoute()) {
        return true
    }
    var isValid = true
    var token = getQueryVariable('csrftoken', false)
    if (!token || token === '') {
        token = getQueryVariable('token', false)
        if (!token || token === '') {
            displayErrors('The provided token is corrupted or missing.', 2000)
            isValid = false
        }
    }

    var localToken = localStorage.getItem('csrftoken')
    if (token !== localToken) {
        displayErrors('The provided token does not match expected one.', 2000)
        isValid = false
    }

    if (!isValid) {
        setTimeout(function () {
            //window.location.href = allRoutes['index'].pathname;
            logoutAndRedirect()
        }, 1500)
        return false
    }

    return true
}

const addCSRFToken = function (string) {
    var existingCsrftoken = localStorage.getItem('csrftoken')
    if (!isDefined(existingCsrftoken)) {
        createAndSaveCSRFToken()
    }
    if (!checkForValidCSRFToken()) {
        return string
    }

    var localToken = localStorage.getItem('csrftoken')
    var querySeparator = string.indexOf('?') > -1 ? '&' : '?'
    if (string.match('/[?&](csrftoken)=[^&]*/') !== null) {
        // eslint-disable-next-line
        string = string.replace('/([?&]csrftoken)=[^&]*/', $token)
    } else {
        if (localToken !== null) {
            localToken = localToken.replace(/"/g, '')
        }
        string += querySeparator + 'csrftoken=' + localToken
    }
    return string
}

const createAndSaveCSRFToken = function () {
    var existingCsrftoken = localStorage.getItem('csrftoken')
    if (
        existingCsrftoken !== null &&
        existingCsrftoken !== '' &&
        existingCsrftoken !== undefined
    ) {
        existingCsrftoken = existingCsrftoken.replace(/"/g, '')
        return existingCsrftoken
    }
    var csrfToken = guid()
    csrfToken = csrfToken.replace(/"/g, '')
    localStorage.setItem('csrftoken', csrfToken)
    return csrfToken
}

const guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()
}

const convertMetrics = function (from, type, metric, value) {
    if (+metric === 0 || value === null) {
        if (from === 'metric') {
            return value
        }
        return value + type
    }

    metric = +metric
    if (from === 'weight') {
        if (metric === 1) {
            let stone = Math.floor(value / 6.35029)
            let remainingPound = Math.fmod(value, 6.35029)
            let pound = remainingPound / 0.4536

            value = stone + '.' + Math.floor(pound)
        } else {
            value = value * 2.2046
        }
    } else if (from === 'height') {
        if (metric === 1 || metric === 2) {
            let feet = Math.floor(value / 30.48)
            let remainingInch = Math.fmod(value, 30.48)
            let inch = remainingInch / 2.54

            value = feet + '.' + Math.floor(inch)
        } else {
            value = Math.round(value, 1)
        }
    } else if (from === 'metric') {
        if (type === 'weight') {
            if (metric === 1) {
                let values = value.split('.')
                let stone = values[0]
                let pound = 0
                if (values.length > 1) {
                    pound = values[1]
                }
                stone = +stone * 6.35029
                pound = +pound * 0.4536
                value = stone + pound
            } else {
                value = value / 2.2046
            }
            type = ''
        } else if (type === 'height') {
            if (metric === 1 || metric === 2) {
                let values = value.split('.')
                let feet = values[0]
                let inch = 0
                if (values.length > 1) {
                    inch = values[1]
                }
                feet = +feet * 30.48
                inch = +inch * 2.54
                value = feet + inch
            } else {
            }
            type = ''
        }
    }

    if (from != 'metric') {
        type = convertMetricsLabels(
            +type.length <= 2 ? true : false,
            from,
            metric,
            type
        )
        return Math.round(value * 10) / 10 + type
    } else {
        return Math.round(value * 10) / 10
    }
}

const convertMetricsLabels = function (short, from, metric, value) {
    if (+metric === 0) {
        return value
    }

    let toreplace = null
    if (short) {
        if (from === 'weight') {
            toreplace = +metric === 1 ? 'st' : 'lb'
            value = value.replace('kg', toreplace)
        } else {
            toreplace = +metric === 1 ? 'ft' : 'Ø'
            value = value.replace('cm', toreplace)
        }
    } else {
        if (from === 'weight') {
            toreplace = +metric === 1 ? 'stone' : 'pound'
            value = value.replace('kilogramme', toreplace)
        } else {
            toreplace = +metric === 1 ? 'feet' : 'Ø'
            value = value.replace('centimètre', toreplace)
        }
    }

    return value
}

const capitalize = function (text) {
    return text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
}

const getUser = function (callback, force = false) {
    let sessionUser = localStorage.getItem('adminuser')
    if (
        sessionUser === null ||
        sessionUser === undefined ||
        sessionUser === 'false' ||
        force
    ) {
        User.getUser().then((res) => {
            sessionUser = res.data
            if (
                isDefined(sessionUser) &&
                isDefined(sessionUser.media) &&
                isDefined(sessionUser.media.url)
            ) {
                if (sessionUser.media.name == 'DEFAULT') {
                    sessionUser.media.url = null
                }
            }
            localStorage.setItem('adminuser', JSON.stringify(sessionUser))
            if (callback !== null) {
                callback(sessionUser)
            }
        })
    } else {
        if (callback !== null) {
            callback(JSON.parse(sessionUser))
        }
    }
}

const setUser = function (user) {
    localStorage.setItem('adminuser', JSON.stringify(user))
    return user
}

const compareByKey = function (key, order = 'asc') {
    return function (a, b) {
        if (
            !Object.getOwnPropertyDescriptor(a, key) ||
            !Object.getOwnPropertyDescriptor(b, key)
        ) {
            // property doesn't exist on either object
            return 0
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
            comparison = 1
        } else if (varA < varB) {
            comparison = -1
        }
        return order == 'desc' ? comparison * -1 : comparison
    }
}

const trimSpecialChar = function (str) {
    let dict = {
        á: 'a',
        à: 'a',
        â: 'a',
        ç: 'c',
        é: 'e',
        è: 'e',
        ê: 'e',
        ë: 'e',
        ù: 'u',
        û: 'u',
        ô: 'o',
    }
    if (Array.isArray(str)) {
        for (var entry in str) {
            if (typeof str[entry].replace === 'function') {
                str[entry] = str[entry].replace(/[^\w ]/g, function (char) {
                    return dict[char] || char
                })
            }
        }
    } else {
        if (typeof str.replace === 'function') {
            str = str.replace(/[^\w ]/g, function (char) {
                return dict[char] || char
            })
        }
    }
    return str
}

const isFloat = function (n) {
    // if ((n === '') || (n === null) || (n === undefined)) {
    //     return false;
    // }
    // return n === +n && n !== (n|0);
    if (n === '' || n === null || n === undefined) {
        return false
    }
    return !isNaN(n)
}

const isInteger = function (n) {
    // return n === +n && n === (n|0);
    if (
        n === '' ||
        n === null ||
        n === undefined ||
        String(n).indexOf('.') > -1 ||
        String(n).indexOf(',') > -1
    ) {
        return false
    }
    return !isNaN(n)
}

function hasOnlyDigit(value) {
    return /^\d+$/.test(value)
}
const castToNumberOfFloat = function (str) {
    if (str === null || str === undefined || str === '') {
        return null
    } else if (isInteger(str) || isFloat(str)) {
        if (isInteger(str)) {
            return +str
        }
        return parseFloat(str)
    } else if (str.indexOf('.') > -1 || str.indexOf(',') > -1) {
        return parseFloat(str.replace(',', '.'))
    } else {
        return parseInt(str)
    }
}

const castToBoolean = function (boul) {
    if (boul === '0' || boul === 0 || boul === 'false') {
        return false
    } else if (boul === '1' || boul === 1 || boul === 'true') {
        return true
    } else if (boul === 'null') {
        return null
    }
    if (hasOnlyDigit(boul)) {
        return +boul
    }
    return boul
}

// function parseDate(date) {
//     //
//     const parsed = Date.parse(date);
//     if (!isNaN(parsed)) {
//         return parsed;
//     }
//     return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
// }

const today = new Date()
const fromNow = function (date) {
    let sufix = ''
    if (date === null) {
        return '/'
    }
    // construct this fucking dumb date for this stupid safari that only create GMT date
    let tmpDate = date
    tmpDate = tmpDate.split('.')[0].split(' ')
    let tmpDateDate = tmpDate[0]
    tmpDateDate = tmpDateDate.split('-')
    let tmpDateHours = tmpDate[1]
    tmpDateHours = tmpDateHours.split(':')
    let fuckinDate = new Date(
        +tmpDateDate[0],
        +tmpDateDate[1] - 1,
        +tmpDateDate[2],
        +tmpDateHours[0],
        +tmpDateHours[1],
        +tmpDateHours[2]
    )
    //
    var delta = Math.abs(fuckinDate.getTime() - today.getTime()) / 1000
    var diff = ''
    var days = Math.floor(delta / 86400)
    delta -= days * 86400
    var hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600
    var minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60
    var seconds = Math.round(delta % 60)
    //
    if (days < 1) {
        if (hours < 1) {
            if (minutes < 1) {
                diff = seconds
                sufix = +seconds >= 2 ? t('secondes') : t('seconde')
            } else {
                diff = minutes
                sufix = +minutes >= 2 ? t('minutes') : t('minute')
            }
        } else {
            diff = hours
            sufix = +hours >= 2 ? t('heures') : t('heure')
        }
    } else {
        diff = days
        sufix = +days >= 2 ? t('jours') : t('jour')
    }
    return diff + ' ' + sufix
}

const deepCopy = function (src) {
    let target = Array.isArray(src) ? [] : {}
    for (let key in src) {
        let v = src[key]
        if (v) {
            if (typeof v === 'object') {
                if (v.lastModified !== undefined) {
                    target[key] = v // to keep File object intact
                } else {
                    target[key] = deepCopy(v)
                }
            } else {
                target[key] = v
            }
        } else {
            target[key] = v
        }
    }
    return target
}

const createQueryMessage = function (success, type, kind) {
    let mssgPrevix =
        kind === 'create'
            ? t('Création')
            : kind === 'update'
            ? t('Mise à jour')
            : t('Suppression')
    if (!success) {
        mssgPrevix =
            kind === 'create'
                ? t('Échec de la création')
                : kind === 'update'
                ? t('Échec de la mise à jour')
                : t('Échec de la suppression')
    }
    let mssgCard = t('de la carte')
    if (type !== null && type !== undefined) {
        if (type === 'evaluation_generique') {
            mssgCard = t('de l‘évaluation classique')
        } else if (type === 'evaluation_comportement') {
            mssgCard = t('de l‘évaluation comportement')
        } else if (type === 'evaluation_survey') {
            mssgCard = t('de l‘évaluation questionnaire')
        } else if (type === 'accompagnement_generique') {
            mssgCard = t('du conseil')
        } else if (type === 'article') {
            mssgCard = t('de l‘article')
        } else if (type === 'recipe') {
            mssgCard = t('de la recette')
        } else if (type === 'bandeau_image') {
            mssgCard = t('du bandeau image')
        } else if (type === 'hello') {
            mssgCard = t('de la carte bonjour')
        } else if (type === 'citation') {
            mssgCard = t('de la citation')
        } else if (type === 'coup_de_pouce') {
            mssgCard = t('de la carte coup de pouce')
        } else if (type === 'dashboard_info') {
            mssgCard = t('de la carte dashboard')
        } else if (type === 'mail_hebdo') {
            mssgCard = t('du mail hebdo')
        } else if (type === 'partenaire') {
            mssgCard = t('de la carte partenaire')
        } else if (type === 'program') {
            mssgCard = t('du programme')
        } else if (type === 'quizz') {
            mssgCard = t('du quizz')
        } else if (type === 'objective') {
            mssgCard = t('de l‘objectif')
        } else if (type === 'alert') {
            mssgCard = t('de l‘alerte')
        } else if (type === 'mailstory') {
            mssgCard = t('de l‘email')
        } else if (type === 'coach') {
            mssgCard = t('du coach')
        } else if (type === 'aliment') {
            mssgCard = t('de l‘aliment')
        } else if (type === 'user') {
            mssgCard = t('de l‘utilisateur')
        } else if (type === 'customer') {
            mssgCard = t('de l‘entreprise')
        } else if (type === 'reward') {
            mssgCard = t('de l‘action')
        } else if (type === 'rew-promotions') {
            mssgCard = t('de la promotion')
        } else if (type === 'gamif-badge') {
            mssgCard = t('du badge')
        } else if (type === 'rew-actions') {
            mssgCard = t('de l‘action')
        } else if (type === 'rew-level') {
            mssgCard = t('du niveau')
        } else if (type === 'formulaire_generique') {
            mssgCard = t('du formulaire classique')
        } else if (type === 'formulaire_comportement') {
            mssgCard = t('du formulaire comportement')
        } else if (type === 'status') {
            mssgCard = t('du statut')
        } else if (type === 'frequency') {
            mssgCard = t('de la fréquence')
        } else if (type === 'shown') {
            mssgCard = t('de la visibilité')
        } else if (type === 'forum-topic') {
            mssgCard = t('du topic')
        } else if (type === 'forum-tag') {
            mssgCard = t('du tag')
        } else if (type === 'calendar') {
            mssgCard = t('du calendrier')
        } else if (type === 'establishments') {
            mssgCard = t('de l‘établissement')
        } else if (type === 'nurses') {
            mssgCard = t('du soignant')
        } else if (type === 'patients') {
            mssgCard = t('du patient')
        } else if (type === 'mass-treatment') {
            mssgCard = t('par lot')
        } else if (['defis', 'challenge'].indexOf(type.toLowerCase()) > -1) {
            mssgCard = t('du défis')
        } else if (type === 'resetpasswordUser') {
            mssgCard = ''
            if (success) {
                mssgPrevix = t(
                    'Mail de réinitialisation de mot de passe envoyé'
                )
            }
        } else if (type === 'cleartimelineUser') {
            mssgCard = ''
            if (success) {
                mssgPrevix = t('Timeline vidée')
            }
        } else if (['deleteUser', 'resiliateUser'].indexOf(type) > -1) {
            mssgCard = t("de l'utilisateur")
        } else if (['deleteCustomer', 'resiliateCustomer'].indexOf(type) > -1) {
            mssgCard = t("de l'entreprise")
        } else if (
            ['deleteEstablishment', 'resiliateEstablishment'].indexOf(type) > -1
        ) {
            mssgCard = t('de l‘établissement')
        } else if (['deleteNurse', 'resiliateNurse'].indexOf(type) > -1) {
            mssgCard = t('du soignant')
        } else if (['deletePatient', 'resiliatePatient'].indexOf(type) > -1) {
            mssgCard = t('du patient')
        }
    }
    let mssgContent = mssgPrevix + ' ' + mssgCard
    return { type: success ? 'success' : 'error', message: mssgContent }
}

const isEmail = function (string) {
    // eslint-disable-next-line
    var regex = /(.+)@(.+){2,}\.(.+){2,3}/
    if (regex.test(string)) {
        return true
    }
    return false
}

const isDefined = function (variable, andNotEmpty = false) {
    if (variable !== null && variable !== undefined) {
        if (andNotEmpty) {
            if (variable !== '') {
                return true
            }
            return false
        }
        return true
    }
    return false
}

const isObjEmpty = function (obj) {
    for (var prop in obj) {
        if (Object.getOwnPropertyDescriptor(obj, prop) !== undefined) {
            return false
        }
    }

    return true
}

const sortObject = function (o) {
    return Object.keys(o)
        .sort()
        .reduce((r, k) => ((r[k] = o[k]), r), {})
}
const sortObjectBy = function (arr, key) {
    arr.sort(function (a, b) {
        var keyA = a[key],
            keyB = b[key]
        // Compare the 2 dates
        if (+keyA < +keyB) return -1
        if (+keyA > +keyB) return 1
        return 0
    })
    return arr
}

const createUTCDate = function (date = null, withOffset = true) {
    var oDate = new Date()
    if (
        date !== null &&
        Object.prototype.toString.call(date) !== '[object Date]'
    ) {
        if (date.indexOf('.') > -1) {
            date = date.replace(' ', 'T') + 'Z'
        } else if (date.indexOf('/') > -1) {
            return date
        }
        oDate = new Date(date)
    } else {
        oDate.setMilliseconds(0)
    }
    if (Object.prototype.toString.call(oDate) === '[object Date]') {
        let startday = oDate.getDate(),
            startmonth = oDate.getMonth(),
            startyear = oDate.getFullYear()
        let starthours = oDate.getUTCHours(),
            startminutes = oDate.getMinutes(),
            startseconds = oDate.getSeconds()
        if (+startday < 10) {
            startday = '0' + startday
        }
        startmonth++
        if (+startmonth < 10) {
            startmonth = '0' + +startmonth
        }
        if (+startday < 10) {
            startday = '0' + +startday
        }
        if (+starthours < 10) {
            starthours = '0' + +starthours
        }
        if (+startminutes < 10) {
            startminutes = '0' + +startminutes
        }
        if (+startseconds < 10) {
            startseconds = '0' + +startseconds
        }
        oDate =
            startyear +
            '-' +
            startmonth +
            '-' +
            startday +
            'T' +
            starthours +
            ':' +
            startminutes +
            ':' +
            startseconds +
            'Z'
        oDate = new Date(oDate)
        var offset = withOffset ? oDate.getTimezoneOffset() : 0
        oDate.setTime(oDate.getTime() + offset * 60 * -1000)
        return oDate
    } else {
        return date
    }
}

// const createUTCDate = function(date = null, withOffset = true) {
//     var oDate = new Date();
//     if (date !== null) {
//         if (date.indexOf('.') > -1) {
//             date = date.replace(' ', 'T') + 'Z';
//         } else if (date.indexOf('/') > -1) {
//             return date;
//         }
//         oDate = new Date(date);
//     } else {
//         oDate.setMilliseconds(0);
//     }
//     if (Object.prototype.toString.call(oDate) === '[object Date]') {
//         let startday = oDate.getDate(), startmonth = oDate.getMonth(), startyear = oDate.getFullYear();
//         let starthours = oDate.getHours(), startminutes = oDate.getMinutes(), startseconds = oDate.getSeconds();
//         if (+startday <  10) {
//             startday = '0' + startday;
//         }
//         startmonth++;
//         if (+startmonth <  10) {
//             startmonth = '0' + +startmonth;
//         }
//         if (+startday <  10) {
//             startday = '0' + +startday;
//         }
//         if (+starthours <  10) {
//             starthours = '0' + +starthours;
//         }
//         if (+startminutes <  10) {
//             startminutes = '0' + +startminutes;
//         }
//         if (+startseconds <  10) {
//             startseconds = '0' + +startseconds;
//         }
//         oDate = startyear + '-' + startmonth + '-' + startday + 'T' + starthours + ':' + startminutes + ':' + startseconds + 'Z';
//         console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DDAATTEE :: ', date, oDate);
//         oDate = new Date(oDate)
//         var offset = (withOffset ? (oDate.getTimezoneOffset() + (!oDate.isDstObserved() ? -60 : 0)) : 0);
//         console.log('offset : ', offset, oDate.isDstObserved());
//         oDate.setTime(oDate.getTime() + (offset * 60 * 1000));
//         return oDate;
//     } else {
//         return date;
//     }
// }

const logoutAndRedirect = function () {
    localStorage.clear()
    //
    redirectToIndex()
}

const redirectToIndex = function () {
    let redirect = APP_WEB_URL + allRoutes['index'].pathname
    setTimeout(() => {
        window.location.href = redirect
    }, 210)
}

const parseMobileLeadingZero = function (phone) {
    if (isDefined(phone) && String(phone).length === 9) {
        return '0' + phone
    }
    return phone
}

import { marked } from 'marked'
// (\[video(\(width=.*\))?(\(height=.*\))?\](.*)\[\/video\])
// (\[video\](.*)\[\/video\])
const markedDownContent = function (str) {
    str = str.replace(
        /(\[video(\(height=(.*)\))?\](.*)\[\/video\])/g,
        '<iframe width="100%" height="$3" src="$4" style="display: block;" allowfullscreen frameborder="0"></iframe>'
    )
    //
    console.log('str : ', str)
    return marked(str, function (err, content) {
        // eslint-disable-next-line
        content = content.replace(/<a/g, '<a target="_blank"')
        // eslint-disable-next-line
        content = content.replace(
            /(<img([a-zA-Z ":.\/=-\d])+(src="([a-zA-Z ":.\/=-\d]+)")>)/g,
            '<a class="dyn-wrapper-clictoenlarge" href="$4" target="_blank">$1<span class="dyn-marked-clictoenlarge">Cliquez pour agrandir</span></a>'
        )
        return content
    })
}

export { markedDownContent }
export { getQueryVariable, getMinHourFromMinutes, getClosest, $ }
export { createCookie, readCookie, eraseCookie, scrollTo, isEmail }
export {
    convertMetrics,
    convertMetricsLabels,
    getUser,
    setUser,
    logoutAndRedirect,
    redirectToIndex,
}
export {
    capitalize,
    compareByKey,
    trimSpecialChar,
    castToNumberOfFloat,
    castToBoolean,
}
export { sortObject }
export {
    addCSRFToken,
    checkForValidCSRFToken,
    createAndSaveCSRFToken,
    sortObjectBy,
}
export { getDateObjFromYMD, getYMDfromDateObj, isDefined, createUTCDate }
export {
    isInteger,
    isFloat,
    fromNow,
    deepCopy,
    createQueryMessage,
    isObjEmpty,
    parseMobileLeadingZero,
}

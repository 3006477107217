import { t, h, Component } from '@app/utils'
import { Form, AFECS, Button } from '@app/elements'
import './index.scss'

const AdminFormECS = Component(
    {
        sendToParent: null,
        name: '',
        ecss: [],
        model: { key: null, value: null, order: 'order' },
        ecsmodel: { expression: '', value: '' },
        lang: '',
        list: [],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.name) {
                state.name = props.name
            }
            if (props.document !== undefined) {
                if (props.document.model) {
                    actions.setModel(props.document.model)
                }
            }
            if (props.oninput) {
                state.sendToParent = props.oninput
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.list) {
                actions.setList(props.list)
            }

            let ecss = []
            if (props.document && props.document !== undefined) {
                ecss = props.document
            } else {
                ecss = [{ expression: 'default', value: '' }]
            }
            actions.setECSS(ecss)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.list) {
                actions.setList(props.list)
            }

            let ecss = []
            if (props.document && props.document !== undefined) {
                ecss = props.document
            } else {
                ecss = [{ expression: 'default', value: '' }]
            }
            actions.setECSS(ecss)
        },
        addECS: () => (state, actions) => {
            let ecss = state.ecss
            ecss.splice(
                ecss.length - 1,
                0,
                JSON.parse(JSON.stringify(state.ecsmodel))
            )
            actions.setECSS(ecss)
        },
        onUpdateEcs: () => (state, actions) => {
            state.sendToParent({
                target: {
                    name: state.name,
                    value: state.ecss,
                    lang: state.lang,
                },
            })
        },
        removeThisOne: (el) => (state, actions) => {
            let ecss = JSON.parse(JSON.stringify(state.ecss))
            let parent = el.target.closest('.afecs')
            let targetIndex = Array.prototype.indexOf.call(
                parent.parentElement.children,
                parent
            )
            ecss.splice(targetIndex, 1)
            actions.setECSS(ecss)
            actions.onUpdateEcs()
        },
        setECSS: (newState) => (state) => ({
            ecss: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        setList: (newState) => (state) => ({
            list: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {state.list.length > 0 && (
                <div>
                    {state.ecss
                        .filter((f) => f.expression !== 'default')
                        .map((ecs, idx) => (
                            <AFECS
                                key={'ecs-entry-' + idx}
                                document={ecs}
                                name={state.name}
                                model={state.model}
                                list={state.list}
                                onclick={actions.onUpdateEcs}
                                ondelete={actions.removeThisOne}
                            ></AFECS>
                        ))}
                    {state.ecss
                        .filter((f) => f.expression === 'default')
                        .map((ecs, idx) => (
                            <AFECS
                                key={'ecs-entry-default-' + idx}
                                document={ecs}
                                name={state.name}
                                model={state.model}
                                list={state.list}
                                onclick={actions.onUpdateEcs}
                                ondelete={actions.removeThisOne}
                            ></AFECS>
                        ))}
                </div>
            )}

            <Form.Group classes="btzForm-btzFooter">
                <Button primary flat onclick={actions.addECS}>
                    {t('Ajouter')}
                </Button>
            </Form.Group>
        </div>
    ),
    'adminformecs'
)

export { AdminFormECS }

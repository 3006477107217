import { h, Component } from '@app/utils'
import { isDefined, getYMDfromDateObj } from '@app/core'
import { Consent } from '@app/api'

import './index.scss'

const AdminFormDisplayFromQuery = Component(
    {
        value: [],
        name: '',
        readonly: false,
        displayQuery: null,
        displayParam: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('FROM QUERY : ', props)
            if (props.document.readonly) {
                actions.setReadonly(props.document.readonly)
            }
            if (props.document.displayQuery) {
                actions.setDisplayQuery(props.document.displayQuery)
            }
            if (props.name) {
                actions.setName(props.name)
            }
            if (props.document.displayParam && props.original) {
                let parsedParam = []
                for (let dParam in props.document.displayParam) {
                    if (props.document.displayParam[dParam].kind === 'static') {
                        parsedParam.push(
                            props.document.displayParam[dParam].key
                        )
                    } else if (
                        props.document.displayParam[dParam].kind === 'dynamic'
                    ) {
                        parsedParam.push(
                            props.original[
                                props.document.displayParam[dParam].key
                            ]
                        )
                    }
                }
                actions.setDisplayParam(parsedParam)
            }

            actions.displayField()
        },
        onComponentUpdate: (props) => (state, actions) => {
            console.log('FROM QUERY : ', props)
        },
        displayField: () => (state, actions) => {
            if (isDefined(state.displayQuery)) {
                let queryParam = null
                if (isDefined(state.displayParam)) {
                    queryParam = state.displayParam
                } else {
                    queryParam = state.value
                }
                //
                console.log('displayField : ', state.displayQuery, queryParam)
                //
                if (Array.isArray(queryParam) && queryParam.length > 0) {
                    state.displayQuery(...queryParam).then((res) => {
                        let allResults = res.data
                        if (
                            state.name === 'agreements' &&
                            allResults.length > 0
                        ) {
                            let userLegalAgreements =
                                allResults[0].userLegalAgreements
                            allResults = allResults[0].legalDocuments
                            //
                            for (let allResult in allResults) {
                                let filteredAgreement =
                                    userLegalAgreements.filter(
                                        (f) =>
                                            f.legalDocument ===
                                            allResults[allResult].id
                                    )[0]
                                if (
                                    isDefined(filteredAgreement) &&
                                    isDefined(filteredAgreement.agreement)
                                ) {
                                    if (filteredAgreement.agreement === true) {
                                        allResults[allResult].agreement = true
                                        allResults[allResult].acceptDate =
                                            filteredAgreement.date.date
                                    } else if (
                                        filteredAgreement.agreement === false
                                    ) {
                                        allResults[allResult].agreement = false
                                    }
                                }
                            }
                        }
                        console.log('displayField : ', allResults)
                        if (allResults && allResults.length > 0) {
                            actions.setValue(allResults)
                        }
                    })
                }
            }
        },
        setValue: (newState) => (state) => ({
            value: newState,
        }),
        setDisplayQuery: (newState) => (state) => ({
            displayQuery: newState,
        }),
        setDisplayParam: (newState) => (state) => ({
            displayParam: newState,
        }),
        setReadonly: (newState) => (state) => ({
            readonly: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            {props.name === 'agreements' && (
                <p name={state.name} readonly={state.readonly}>
                    <span>
                        {isDefined(
                            state.value.filter((f) => f.type === 'terms')[0]
                        ) && (
                            <dummy>
                                {'CGU - ' +
                                    state.value.filter(
                                        (f) => f.type === 'terms'
                                    )[0].version +
                                    ' - ' +
                                    (isDefined(
                                        state.value.filter(
                                            (f) => f.type === 'terms'
                                        )[0].acceptDate
                                    )
                                        ? getYMDfromDateObj(
                                              state.value.filter(
                                                  (f) => f.type === 'terms'
                                              )[0].acceptDate,
                                              true,
                                              false,
                                              true
                                          )
                                        : 'Ø') +
                                    ' - '}
                                <span style="display: inline-block;">
                                    {!isDefined(
                                        state.value.filter(
                                            (f) => f.type === 'terms'
                                        )[0].agreement
                                    )
                                        ? 'en attente'
                                        : state.value.filter(
                                              (f) => f.type === 'terms'
                                          )[0].agreement
                                        ? 'accepté'
                                        : 'refusé'}
                                </span>
                            </dummy>
                        )}
                        {isDefined(
                            state.value.filter((f) => f.type === 'privacy')[0]
                        ) && (
                            <dummy>
                                {'PdC - ' +
                                    state.value.filter(
                                        (f) => f.type === 'terms'
                                    )[0].version +
                                    ' - ' +
                                    (isDefined(
                                        state.value.filter(
                                            (f) => f.type === 'privacy'
                                        )[0].acceptDate
                                    )
                                        ? getYMDfromDateObj(
                                              state.value.filter(
                                                  (f) => f.type === 'privacy'
                                              )[0].acceptDate,
                                              true,
                                              false,
                                              true
                                          )
                                        : 'Ø') +
                                    ' - '}
                                <span style="display: inline-block;">
                                    {!isDefined(
                                        state.value.filter(
                                            (f) => f.type === 'privacy'
                                        )[0].agreement
                                    )
                                        ? 'en attente'
                                        : state.value.filter(
                                              (f) => f.type === 'privacy'
                                          )[0].agreement
                                        ? 'accepté'
                                        : 'refusé'}
                                </span>
                            </dummy>
                        )}
                    </span>
                </p>
            )}
        </div>
    ),
    'adminformdisplayfromquery'
)

export { AdminFormDisplayFromQuery }

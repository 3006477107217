import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { Customers, AutoComplete, Programs, Card, Settings } from '@app/api'
import { isDefined, deepCopy } from '@app/core'
import { Delete } from '@app/elements'
import './index.scss'

const AdminFormDropDown = Component(
    {
        name: '',
        type: '',
        ulDisplayed: false,
        list: [],
        originalList: [],
        selected: null,
        isBlured: true,
        readonly: false,
        search: '',
        infoError: '',
        parentId: null,
        document: null,
        original: null,
        wholeDocument: null,
        resetTargetValue: null,
        filterValue: null,
        dynListKey: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {

            if (props.document) {
                if (props.document.name) {
                    state.name = props.document.name
                }
                if (props.document.type) {
                    state.type = props.document.type
                }
                if (props.document.resetTargetValue) {
                    state.resetTargetValue = props.document.resetTargetValue
                }
                if (props.document.filterValue) {
                    state.filterValue = props.document.filterValue
                }
                if (props.document.dynListKey) {
                    state.dynListKey = props.document.dynListKey
                }

                if (props.document.list === 'dynamic') {
                    actions.updateFieldSelection(props)
                }else{
                    actions.onComponentUpdate(props)
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (isDefined(props.document.readonly)) {
                state.readonly = props.document.readonly
            }

            if (props.document) {
                actions.setDocument(props.document)
                let documentList = props.document.list
                const listInitializedOnce = state.list.length > 0
                if (!isDefined(documentList)) {
                    documentList = state.list
                    if (isDefined(documentList)) {
                        actions.selectThisOne({
                            id: props.document.value,
                            name: props.document.value,
                        })
                    }
                } else if (isDefined(documentList)) {
                    if (!props.document.simplecall) {
                        actions.defineList(props)
                    }
                    if (documentList === 'dynamic') {
                        let id = props.document.value,
                            name = props.document.value
                        if ('apicall' in props.document &&
                            !listInitializedOnce
                        ) {
                            props.document
                                .apicall(props.document.apiparam)
                                .then((response) => {
                                    const list = response.data.map(
                                        (item) => {
                                            if (props.document.model){
                                                return {
                                                    id : item[props.document.model.key],
                                                    name: item[props.document.model.label],
                                                    value: item[props.document.model.value],
                                                }
                                            }
                                            return {
                                                id: item,
                                                name: item,
                                            }
                                        }
                                    )
                                    actions.setList(list)
                                    actions.setOriginalList(deepCopy(list))
                                    
                                    if (props.document.model){
                                        actions.selectThisOne({
                                            key: '',
                                            value: '',
                                            label: props.document.value?.label,
                                            id: props.document.value?.id,
                                            name: props.document.value?.label,
                                        })
                                    }
                                    else{
                                        actions.selectThisOne({
                                            key: '',
                                            value: '',
                                            id: props.document.value,
                                            name: props.document.value,
                                        })}
                                })
                        }
                        if (props.document.type === 'datatags') {
                            name = ''
                            for (var tag in props.dataTags) {
                                if (tag === props.document.value) {
                                    name = props.dataTags[tag]
                                }
                            }
                            id = props.document.value
                        }
                        if (isDefined(props.document.value)) {
                            if (isDefined(props.document.value.id)) {
                                id = props.document.value.id
                                name = '' // to survey
                            }
                            if (isDefined(props.document.value.name)) {
                                name = props.document.value.name
                            }
                            if (
                                isDefined(props.document.value.namedepartement)
                            ) {
                                name = props.document.value.namedepartement
                            }
                        }
                        if (
                            props.document.name === 'parent' &&
                            (!isDefined(props.document.value.name) ||
                                id === name)
                        ) {
                            // two different parent, one (like customer) with complete object, and second (like card parent) with only an id
                        } else {
                            actions.selectThisOne({ id: id, name: name })
                        }
                    } else {
                        let propsList = props.list
                        if (isDefined(state.dynListKey)) {
                            propsList =
                                propsList[
                                    props.wholeDocument[state.dynListKey].value
                                ]
                        }
                        //
                        if (isDefined(propsList)) {
                            let list = Object.keys(propsList).map(
                                (i) => propsList[i]
                            )
                            let selected = list.filter(
                                (f) =>
                                    props.document.value !== '' &&
                                    f.key == props.document.value
                            )
                            if (
                                selected.length === 0 &&
                                Array.isArray(props.document.value)
                            ) {
                                dance: for (var v in props.document.value) {
                                    for (var l in list) {
                                        if (
                                            props.document.value[v] ===
                                            list[l].key
                                        ) {
                                            selected = [list[l]]
                                            break dance
                                        }
                                    }
                                }
                            }

                            if (selected.length > 0) {
                                selected = selected[0]
                                actions.selectThisOne(selected)
                            } else {
                                if (props.document.value !== '') {
                                    selected = list.filter((f) => f.key == '')
                                    if (selected.length > 0) {
                                        selected = selected[0]
                                        actions.selectThisOne(selected)
                                    }
                                } else {
                                    actions.selectThisOne(null)
                                }
                            }
                        }
                    }
                }
                //
                if (isDefined(props.original)) {
                    actions.setOriginalDocument(props.original)
                }
                if (isDefined(props.wholeDocument)) {
                    actions.setWholeDocument(props.wholeDocument)
                    if (
                        isDefined(props.wholeDocument.parent) &&
                        isDefined(props.wholeDocument.parent.value) &&
                        isDefined(props.wholeDocument.parent.value.id)
                    ) {
                        actions.setParentId(props.wholeDocument.parent.value.id)
                    }
                }
                //
            }

            if (props.search) {
                actions.selectThisOne({ name: props.search, id: props.search })
                actions.setSearch(props.search)
            }
        },
        updateFieldSelection: (props) => (state, actions) => {
            if (
                (props.document.type === 'owner' ||
                    props.document.type === 'customer') &&
                props.document.value !== '' &&
                props.document.value !== undefined
            ) {
                let customerId = props.document.value
                if (customerId.id !== undefined) {
                    customerId = customerId.id
                }
                Customers.getCustomersById(customerId).then((res) => {
                    if (
                        isDefined(res.data) &&
                        !res.data.isError &&
                        res.data.id
                    ) {
                        actions.selectThisOne({
                            key: '',
                            value: '',
                            id: res.data.id,
                            name: res.data.name,
                        })
                        let customerData = {
                            name: res.data.name,
                            id: res.data.id,
                            expiration: res.data.expiration,
                        }
                        if (res.data.parent) {
                            /*
                                synchedCustomer must be child customer, not parent one
                                 child customer has a parent, parent customer dont
                            */
                            customerData.synchedCustomer = res.data
                        }
                        props.onclick({
                            target: {
                                name: props.document.name,
                                value: customerData,
                            },
                        })
                        if (
                            isDefined(props.original) &&
                            props.original.parent !== undefined
                        ) {
                            let parentValue = {
                                name: res.data.name,
                                id: res.data.id,
                                expiration: res.data.expiration,
                            }
                            if (isDefined(res.data.parent)) {
                                parentValue = {
                                    name: res.data.parent.name,
                                    id: res.data.parent.id,
                                    expiration: res.data.parent.expiration,
                                }
                            }
                            props.onclick({
                                target: { name: 'parent', value: parentValue },
                            })
                            if (isDefined(props.document.update)) {
                                let updateValue = props.document.update.notnull
                                props.onclick({
                                    target: {
                                        name: updateValue.key,
                                        value: updateValue.value,
                                    },
                                })
                            }
                        }
                    }
                })
            } else if (
                props.document.name === 'programId' &&
                props.document.value !== ''
            ) {
                Programs.getProgramById(props.document.value).then((res) => {
                    if (
                        isDefined(res.data) &&
                        !res.data.isError &&
                        res.data.id
                    ) {
                        actions.selectThisOne({
                            key: '',
                            value: '',
                            id: res.data.id,
                            name: res.data.name,
                        })
                    }
                })
            } else if (
                props.document.name === 'parent' &&
                props.document.value !== ''
            ) {
                let cardKind = props.document.type.split('/')
                if (cardKind[1] === 'cards') {
                    Card.getCard(
                        props.document.value.id || props.document.value
                    ).then((res) => {
                        if (
                            isDefined(res.data) &&
                            !res.data.isError &&
                            res.data.id
                        ) {
                            actions.selectThisOne({
                                key: '',
                                value: '',
                                id: res.data.id,
                                name: res.data.name,
                            })
                        }
                    })
                }
            } else if (
                props.document.name.indexOf('city') > -1 &&
                props.document.value !== '' &&
                props.document.value !== undefined
            ) {
                let name = props.document.value
                let id = props.document.value
                if (props.document.value.name !== undefined) {
                    name = props.document.value.name
                    id = props.document.value.value
                }
                actions.selectThisOne({ id: id, name: name })
            } else {
                let target = props.document.value
                if (isDefined(target)) {
                    let selected = null
                    if (isDefined(target.name) && isDefined(target.id)) {
                        selected = { name: target.name, id: target.id }
                    } else if (typeof target === 'string' && target !== '') {
                        selected = { name: target, id: target }
                    }
                    if (isDefined(selected)) {
                        actions.selectThisOne(selected)
                    }
                }
            }
            if (isDefined(state.resetTargetValue)) {
                props.onclick({
                    target: {
                        name: state.resetTargetValue,
                        value: { name: 'clearfield', id: '' },
                    },
                })
            }
        },
        selectThisOne: (newState) => (state) => ({
            search: newState && newState.name ? newState.name : '',
            selected: newState,
            ulDisplayed: false,
        }),
        defineList: (props) => (state, actions) => {
            let list = props.list
            if (props.document.type === 'kpi') {
                list = props.kpiList
            } else if (props.document.type === 'datatags') {
                list = props.dataTags
            } else if (props.document.type === 'coach') {
                list = props.coachsList
            }
            if (isDefined(state.dynListKey)) {
                list = list[props.wholeDocument[state.dynListKey].value]
            }
            //
            if (isDefined(props.document.filterValue)) {
                let filterValues = props.document.filterValue
                if (!Array.isArray(filterValues)) {
                    filterValues = [filterValues]
                }
                let filteredList = deepCopy(list)
                for (let fVal in filterValues) {
                    let filterValue = props.wholeDocument[filterValues[fVal]]
                    if (isDefined(filterValue)) {
                        filterValue = filterValue.value
                        for (let fList in filteredList) {
                            const filterKeys = Array.isArray(
                                filteredList[fList].filter
                            )
                                ? filteredList[fList].filter
                                : Object.keys(filteredList[fList].filter)
                            //
                            const filterValues = Array.isArray(
                                filteredList[fList].filter
                            )
                                ? filteredList[fList].filter
                                : Object.keys(
                                    filteredList[fList].filter
                                ).reduce((acc, curr) => {
                                    return [
                                        ...acc,
                                        ...filteredList[fList].filter[curr],
                                    ]
                                }, [])
                            if (
                                !filterKeys.includes(filterValue) &&
                                !filterValues.includes(filterValue)
                            ) {
                                delete filteredList[fList]
                            }
                        }
                        list = filteredList
                    }
                }
            }
            //
            let parsedList = []
            if (isDefined(list)) {
                if (
                    Array.isArray(list) &&
                    list[0] !== undefined &&
                    list[0].name === undefined
                ) {
                    for (var l in list) {
                        // parsedList.push({name: list[l], id: list[l]});
                        parsedList.push({ key: list[l], value: list[l] })
                    }
                    list = parsedList
                } else {
                    if (
                        list !== 'dynamic' &&
                        Object.keys(list)[0] !== undefined &&
                        Object.keys(list)[0].name === undefined
                    ) {
                        for (var o in list) {
                            if (
                                list[o].name !== undefined &&
                                list[o].id !== undefined
                            ) {
                                parsedList.push({
                                    name: list[o].name,
                                    id: list[o].id,
                                })
                            } else {
                                parsedList.push({ name: list[o], id: o })
                            }
                        }
                    }
                    if (props.document.list === 'dynamic') {
                        list = parsedList
                    } else {
                        list = Object.keys(list).map((i) => list[i])
                    }
                }
                if (isDefined(list)) {
                    actions.setList(list)
                    actions.setOriginalList(deepCopy(list))
                }
            }
        },
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setOriginalList: (newState) => (state) => ({
            originalList: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setOriginalDocument: (newState) => (state) => ({
            original: newState,
        }),
        setWholeDocument: (newState) => (state) => ({
            wholeDocument: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        setBlured: (newState) => (state) => ({
            isBlured: newState,
        }),
        setSearch: (newState) => (state) => ({
            search: newState,
        }),
        setParentId: (newState) => (state) => ({
            parentId: newState,
        }),
        setInfoError: (newState) => (state) => ({
            infoError: newState,
        }),
        onUpdate: (value) => (state, actions) => {
            actions.setSearch(value)
            if (state.name === 'owner' || state.type === 'customer') {
                if (value === '') {
                    actions.setList([])
                    actions.toggleUl(false)
                } else {
                    let treatAtParent = false
                    if (
                        isDefined(state.wholeDocument) &&
                        isDefined(state.wholeDocument.parent)
                    ) {
                        let dependsOn = state.wholeDocument.parent.dependsOn
                        if (
                            isDefined(dependsOn) &&
                            isDefined(state.wholeDocument[dependsOn.field])
                        ) {
                            let dependsOnValue =
                                state.wholeDocument[dependsOn.field].value
                            if (
                                (typeof dependsOnValue === 'string' ||
                                    Array.isArray(dependsOnValue)) &&
                                dependsOnValue.indexOf(dependsOn.value) > -1
                            ) {
                                treatAtParent = true
                            }
                        }
                    }
                    if (
                        isDefined(state.document) &&
                        state.document.name === 'customer' &&
                        treatAtParent
                    ) {
                        if (isDefined(state.parentId)) {
                            actions.setInfoError('')
                            Customers.getCustomerChildsByNameAndParentId(
                                value,
                                state.parentId
                            ).then((res) => {
                                if (isDefined(res.data) && !res.data.isError) {
                                    actions.setList(res.data)
                                }
                                actions.toggleUl(true)
                            })
                        } else if (state.infoError === '') {
                            let errorMessage = t(
                                'Veuillez sélectionner un établissement mère'
                            )
                            if (
                                isDefined(state.document.validation) &&
                                isDefined(state.document.validation.message)
                            ) {
                                errorMessage = state.document.validation.message
                            }
                            actions.setInfoError(errorMessage)
                        }
                    } else {
                        if (state.document?.name === 'parent') {
                            Customers.getCustomerAsMother(value).then((res) => {
                                if (isDefined(res.data) && !res.data.isError) {
                                    actions.setList(res.data)
                                }
                                actions.toggleUl(true)
                            })
                        } else {
                            Customers.getCustomersByName(value).then((res) => {
                                if (isDefined(res.data) && !res.data.isError) {
                                    actions.setList(res.data)
                                }
                                actions.toggleUl(true)
                            })
                        }
                    }
                }
            } else {
                if (state.type.indexOf('autocomplete') > -1) {
                    if (value === '') {
                        actions.setList([])
                        actions.toggleUl(false)
                    } else {
                        let completeKind = state.type.split('/')[1]
                        //
                        let param = null
                        let paramValue = ''
                        if (completeKind === 'cards') {
                            param = state.type.split('/')[2].split('[')
                            paramValue = param[1]
                        }
                        //
                        switch (completeKind) {
                            case 'article':
                                AutoComplete.getArticles(value).then((res) => {
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        actions.setList(res.data)
                                    }
                                    actions.toggleUl(true)
                                })
                                break
                            case 'recipe':
                                AutoComplete.getRecipes(value).then((res) => {
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        actions.setList(res.data)
                                    }
                                    actions.toggleUl(true)
                                })
                                break
                            case 'challenge':
                                AutoComplete.getChallenges(value).then(
                                    (res) => {
                                        if (
                                            isDefined(res.data) &&
                                            !res.data.isError
                                        ) {
                                            actions.setList(res.data)
                                        }
                                        actions.toggleUl(true)
                                    }
                                )
                                break
                            case 'coach':
                                AutoComplete.getCoachs(value).then((res) => {
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        actions.setList(res.data)
                                    }
                                    actions.toggleUl(true)
                                })
                                break
                            case 'program':
                                AutoComplete.getPrograms(value).then((res) => {
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        actions.setList(res.data)
                                    }
                                    actions.toggleUl(true)
                                })
                                break
                            case 'reward':
                                AutoComplete.getRewards(value).then((res) => {
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        actions.setList(res.data)
                                    }
                                    actions.toggleUl(true)
                                })
                                break
                            case 'rewardcontent':
                                AutoComplete.getRewardsContent(value).then(
                                    (res) => {
                                        if (
                                            isDefined(res.data) &&
                                            !res.data.isError
                                        ) {
                                            actions.setList(res.data)
                                        }
                                        actions.toggleUl(true)
                                    }
                                )
                                break
                            case 'cards':
                                AutoComplete.getCardsByType(
                                    value,
                                    paramValue
                                ).then((res) => {
                                    let parsedList = []
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        for (var o in res.data) {
                                            if (isDefined(res.data[o].name)) {
                                                if (
                                                    state.document.name ===
                                                    'parent'
                                                ) {
                                                    parsedList.push({
                                                        name: res.data[o].name,
                                                        id: res.data[o].id,
                                                    })
                                                } else {
                                                    parsedList.push({
                                                        name: res.data[o].name,
                                                        id: res.data[o].name,
                                                    })
                                                }
                                            }
                                        }
                                    }
                                    actions.setList(parsedList)
                                    actions.toggleUl(true)
                                })
                                break
                            case 'city':
                                AutoComplete.getCities(value).then((res) => {
                                    let parsedList = []
                                    if (
                                        isDefined(res.data) &&
                                        !res.data.isError
                                    ) {
                                        for (var o in res.data) {
                                            parsedList.push({
                                                name: res.data[o],
                                                id: res.data[o],
                                            })
                                        }
                                    }
                                    actions.setList(parsedList)
                                    actions.toggleUl(true)
                                })
                                break
                            default:
                                console.log(
                                    'Sorry, we are out of ' + completeKind + '.'
                                )
                        }
                    }
                } else {
                    if (value === '') {
                        console.log('LIST : ', state.originalList)
                        actions.setList(deepCopy(state.originalList))
                    } else {
                        let list = deepCopy(state.originalList)
                        list = list.filter((f) => {
                            if (
                                isDefined(f.value) &&
                                typeof f.value === 'string'
                            ) {
                                return (
                                    String(f.value)
                                        .toLowerCase()
                                        .indexOf(value.toLowerCase()) > -1
                                )
                            } else if (
                                isDefined(f.name) &&
                                typeof f.name === 'string'
                            ) {
                                return (
                                    String(f.name)
                                        .toLowerCase()
                                        .indexOf(value.toLowerCase()) > -1
                                )
                            }
                            return null
                        })
                        console.log('LIST : ', list)
                        actions.setList(list)
                        actions.toggleUl(true)
                    }
                }
            }
        },
        waitForLastInput: ({ target }) =>
            debounce(500, 'onUpdate', target.value),
    },
    (state, actions) => (props, children) =>
        (
            <div
                key={props.name}
                tabindex='0'
                onblur={() => {
                    actions.toggleUl(false)
                }}
            >
                {props.document !== undefined && (
                    <dummy>
                        {props.list === 'dynamic' ? (
                            <div
                                class={
                                    'sup-custom-select sup-custom-select-wide-one ' +
                                    (state.readonly ? 'sup-readonly' : '')
                                }
                                data-name={props.name}
                            >
                                <input
                                    type='hidden'
                                    name={props.name}
                                    value={
                                        state.selected !== null &&
                                        state.selected.id !== ''
                                            ? state.selected.id
                                            : ''
                                    }
                                />
                                {props.document.searchbox === false ? (
                                    <div
                                        class='sup-custom-select-default-label'
                                        data-key-value={
                                            state.selected !== null
                                                ? state.selected.id
                                                : ''
                                        }
                                        data-val-value={
                                            state.selected !== null
                                                ? state.selected.name
                                                : ''
                                        }
                                        onclick={() => {
                                            if (!state.readonly) {
                                                actions.toggleUl(
                                                    !state.ulDisplayed
                                                )
                                            }
                                        }}
                                    >
                                        {state.selected !== null
                                            ? state.selected.name
                                            : 'Faites dérouler la liste'}
                                    </div>
                                ) : (
                                    <dummy>
                                        <input
                                            key={props.document.value}
                                            type='text'
                                            value={
                                                state.selected !== null &&
                                                state.selected.name !== '' &&
                                                // state.search === ''
                                                state.isBlured
                                                    ? state.selected.name
                                                    : state.search
                                            }
                                            placeholder={
                                                props.document.placeholder
                                            }
                                            oninput={actions.waitForLastInput}
                                            onclick={() => {
                                                if (!state.readonly) {
                                                    actions.toggleUl(true)
                                                }
                                            }}
                                            onblur={(e) => {
                                                actions.setBlured(true)
                                                isDefined(e.relatedTarget)
                                                    ? ''
                                                    : actions.toggleUl(false)
                                            }}
                                            onfocus={(e) => {
                                                actions.setBlured(false)
                                            }}
                                            readonly={
                                                isDefined(state.readonly)
                                                    ? state.readonly
                                                    : false
                                            }
                                        />
                                        {state.infoError !== '' && (
                                            <p
                                                class=''
                                                style={{ position: 'absolute' }}
                                            >
                                                {state.infoError}
                                            </p>
                                        )}
                                    </dummy>
                                )}
                                {(props.document.type === 'kpi' ||
                                    props.document.type === 'datatags' ||
                                    props.document.type === 'coach') && (
                                    <div
                                        class='sup-drop-down'
                                        style={{
                                            'margin-top':
                                                props.document.searchbox ===
                                                false
                                                    ? '0'
                                                    : '-30px',
                                        }}
                                        onclick={() => {
                                            if (!state.readonly) {
                                                actions.toggleUl(true)
                                            }
                                        }}
                                    ></div>
                                )}
                                {isDefined(state.list) && (
                                    <ul
                                        class='sup-custom-select-ul'
                                        style={{
                                            display: state.ulDisplayed
                                                ? 'block'
                                                : 'none',
                                            margin: '0px',
                                        }}
                                    >
                                        {state.list
                                            .filter((li) => li.name !== '')
                                            .map((l) => {
                                                let value = l.id
                                                let readable = l.name

                                                if (state.document?.model){
                                                    // a model is a mapping to key, value & label
                                                    value = l.value
                                                    readable = l.label
                                                }

                                                return (
                                                    <li
                                                        class='sup-custom-select-li'
                                                        data-name={props.name}
                                                        data-value={value}
                                                        data-readable={readable}
                                                        onclick={() => {
                                                            actions.selectThisOne(l)
                                                            props.onclick({
                                                                target: {
                                                                    name: state.name,
                                                                    value : l,
                                                                },
                                                            })
                                                            actions.updateFieldSelection(
                                                                props
                                                            )
                                                        }}
                                                    >
                                                        {l.name}
                                                    </li>
                                                )})}
                                    </ul>
                                )}
                            </div>
                        ) : (
                            <div
                                class={
                                    'sup-custom-select sup-custom-select-wide-one ' +
                                    (state.readonly ? 'sup-readonly' : '')
                                }
                                data-name={props.name}
                            >
                                <input
                                    type='hidden'
                                    value={props.document.value}
                                    name={props.name}
                                    placeholder='Non défini'
                                    class='sup-group-increment-clone'
                                />
                                {props.document.searchbox &&
                                props.document.searchbox === true ? (
                                        <input
                                            type={'text'}
                                            class='sup-custom-select-default-label'
                                            data-key-value={props.document.value}
                                            data-val-value={props.document.value}
                                            onclick={() => {
                                                if (!state.readonly) {
                                                    actions.toggleUl(true)
                                                }
                                            }}
                                            oninput={actions.waitForLastInput}
                                            value={
                                                state.selected !== null
                                                    ? state.selected.value
                                                    : state.search
                                            }
                                            onblur={(e) => {
                                                isDefined(e.relatedTarget)
                                                    ? ''
                                                    : actions.toggleUl(false)
                                            }}
                                            readonly={
                                                isDefined(state.readonly)
                                                    ? state.readonly
                                                    : false
                                            }
                                        />
                                    ) : (
                                        <div
                                            class='sup-custom-select-default-label'
                                            data-key-value={props.document.value}
                                            data-val-value={props.document.value}
                                            onclick={() => {
                                                if (!state.readonly) {
                                                    actions.toggleUl(true)
                                                }
                                            }}
                                        >
                                            {state.selected !== null &&
                                        (isDefined(
                                            state.selected.num,
                                            true,
                                            true
                                        ) ||
                                            isDefined(
                                                state.selected.value,
                                                true,
                                                true
                                            ) ||
                                            isDefined(
                                                state.selected.id,
                                                true,
                                                true
                                            ))
                                                ? (state.selected.num !== '' &&
                                              state.selected.num !== undefined
                                                    ? state.selected.num
                                                    : '') +
                                              (t(state.selected.value) ||
                                                  t(state.selected.id))
                                                : isDefined(
                                                    props.document.placeholder,
                                                    true,
                                                    true
                                                )
                                                    ? props.document.placeholder
                                                    : t('Faites dérouler la liste')}
                                        </div>
                                    )}
                                <div
                                    class='sup-drop-down'
                                    onclick={() => {
                                        if (!state.readonly) {
                                            actions.toggleUl(true)
                                        }
                                    }}
                                ></div>
                                {isDefined(state.list) && (
                                    <ul
                                        class='sup-custom-select-ul'
                                        style={{
                                            display: state.ulDisplayed
                                                ? 'block'
                                                : 'none',
                                        }}
                                    >
                                        {state.list
                                            .filter(
                                                (li) =>
                                                    li.value !== '' &&
                                                    (state.search === '' ||
                                                        (state.search !== '' &&
                                                            li.value
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    state.search.toLowerCase()
                                                                ) > -1))
                                            )
                                            .map((l) => (
                                                <li
                                                    class='sup-custom-select-li'
                                                    data-name={props.name}
                                                    data-value={l.key}
                                                    data-kind={props.kind}
                                                    data-idx={props.idx}
                                                    onclick={(el) => {
                                                        actions.selectThisOne(l)
                                                        props.onclick(el)
                                                        actions.updateFieldSelection(
                                                            props
                                                        )
                                                    }}
                                                >
                                                    {(l.num !== undefined &&
                                                    l.num !== ''
                                                        ? l.num
                                                        : '') +
                                                        (typeof l.value ===
                                                        'string'
                                                            ? t(l.value)
                                                            : '')}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                                {state.selected !== null &&
                                    props.document.value !== '' &&
                                    ((state.selected.key !== undefined &&
                                        state.selected.key === '') ||
                                        (state.selected.id !== undefined &&
                                            state.selected.id === '')) && ( // used for dropdown with empty field, like [link] that need a second field
                                    <input
                                        style={{ 'margin-left': '55px' }}
                                        type='text'
                                        name={props.name}
                                        value={props.document.value}
                                        oninput={(el) => {
                                            props.onclick(el)
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        {props.document.deletable === true && (
                            <Delete
                                action={() => {
                                    actions.selectThisOne({
                                        key: '-1',
                                        value: '',
                                        id: '',
                                        name: '',
                                    })
                                    props.onclick({
                                        target: { name: props.name, value: '' },
                                    })
                                    actions.updateFieldSelection(props)
                                }}
                            ></Delete>
                        )}
                    </dummy>
                )}
            </div>
        ),
    'adminformdropdown'
)

export { AdminFormDropDown }

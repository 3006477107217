import { getTrustIPsArray, getTrustIPsArrayWithoutInput } from './utils'

describe('Operations Healthteam', () => {
    describe('getTrustIPsArray', () => {
        test('should work with a single entry', () => {
            const input = '0.0.0.0:hospital1'
            const trustIpsObject = getTrustIPsArray(input)

            expect(trustIpsObject).toEqual([
                { ip: '0.0.0.0', label: 'hospital1' },
            ])
        })

        test('should only return full entry', () => {
            const input = '0.0.0.0:hospital1, 0.0.0.1'
            const trustIpsObject = getTrustIPsArray(input)

            expect(trustIpsObject).toEqual([
                { ip: '0.0.0.0', label: 'hospital1' },
            ])
        })

        test('should work with multiple entries', () => {
            const input = '0.0.0.0:hospital1,0.0.0.0:hospital2'
            const trustIpsObject = getTrustIPsArray(input)

            expect(trustIpsObject).toEqual([
                { ip: '0.0.0.0', label: 'hospital1' },
                { ip: '0.0.0.0', label: 'hospital2' },
            ])
        })

        test('should return an empty array for empty string', () => {
            const input = ''
            const trustIpsObject = getTrustIPsArray(input)

            expect(trustIpsObject).toEqual([])
        })
    })
})

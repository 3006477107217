import { t, h, Component } from '@app/utils'
import {
    AdminFormDropDown,
    AdminFormEvalMaker,
    AdminFormHelp,
    Form,
    Button,
} from '@app/elements'
import { isInteger, isFloat, deepCopy, getClosest, isDefined } from '@app/core'
import './index.scss'

const AdminFormArrayOfEvalMaker = Component(
    {
        document: null,
        name: '',
        formName: 'questions',
        arrayOfField: [],
        questionEntry: [],
        evalsmodel: {
            button: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
            slider: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
            qcm: { key: 'name', value: 'value', tag: 'tag', order: 'order' },
        },
        sendToParent: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.document) {
                state.document = props.document

                if (props.document.name) {
                    state.name = props.document.name
                }
                if (props.document.arrayOfField) {
                    state.arrayOfField = props.document.arrayOfField
                    state.questionEntry = []
                    if (
                        props.document.value &&
                        props.document.value.length > 0
                    ) {
                        for (var entry in props.document.value) {
                            let baseEntry = deepCopy(state.arrayOfField)
                            for (var base in baseEntry) {
                                baseEntry[base].value =
                                    props.document.value[entry][base]
                                if (base === 'responses') {
                                    baseEntry[base].value = baseEntry[
                                        base
                                    ].value.map((m) => ({ name: m }))
                                }
                            }
                            actions.addQuestionEntry(baseEntry)
                        }
                    } else {
                        actions.addQuestionEntry()
                    }
                }
            }

            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
        sendToParent: (field) => (state, actions) => {
            let value =
                field.target !== undefined && field.target.value !== undefined
                    ? field.target.value
                    : null
            let index =
                field.target !== undefined && field.target.order !== undefined
                    ? field.target.order
                    : null
            if (value !== null && index !== null) {
                actions.updateQuestionEntry({ index: index, value: value })
                actions.updateDocument(field)
                if (state.sendToParent !== null) {
                    actions.parseAndSendToParent()
                }
            }
        },
        parseAndSendToParent: () => (state, actions) => {
            let parsedQuestions = []
            // state.questionEntry.map((m) => ({value: m}));
            for (var questions in state.questionEntry) {
                let parsedQuestionEntry = {}
                for (var question in state.questionEntry[questions]) {
                    parsedQuestionEntry[question] =
                        state.questionEntry[questions][question].value
                    if (
                        question === 'responses' &&
                        Array.isArray(parsedQuestionEntry[question])
                    ) {
                        parsedQuestionEntry[question] = state.questionEntry[
                            questions
                        ][question].value.map((m) => m.name)
                    }
                }
                parsedQuestions.push(parsedQuestionEntry)
            }
            state.sendToParent({
                target: { name: state.formName, value: parsedQuestions },
            })
        },
        updateDocument: (field) => (state, actions) => {
            let currentdoc = state.questionEntry
            let currentTarget = field.target
            let index = currentTarget.order
            if (currentTarget instanceof Element) {
                let parent = getClosest(
                    currentTarget,
                    '.adminformarrayofevalmaker-wrapper'
                )
                index = parent.getAttribute('data-order')
            }

            if (!isDefined(currentTarget)) {
                currentTarget = field
            }
            let fieldName = currentTarget.name || null
            if (!isDefined(fieldName) && currentTarget instanceof Element) {
                fieldName = currentTarget.getAttribute('data-name')
            }
            let fieldValue = currentTarget.value || null
            if (
                isDefined(fieldValue === null) &&
                currentTarget instanceof Element
            ) {
                fieldValue = currentTarget.getAttribute('data-value')
            }

            let fieldLang = currentTarget.lang || null
            let fieldType = currentTarget.type || null
            if (currentdoc[index][fieldName] !== undefined) {
                if (fieldType === 'int') {
                    if (!isInteger(fieldValue)) {
                        fieldValue = currentdoc[index][fieldName].value
                    }
                } else if (fieldType === 'float') {
                    if (!isFloat(fieldValue)) {
                        fieldValue = currentdoc[index][fieldName].value
                    }
                } else if (fieldType === 'array') {
                    let tmpValue = fieldValue
                    if (Array.isArray(tmpValue)) {
                        tmpValue = tmpValue.join(',')
                    }
                    if (isDefined(tmpValue)) {
                        fieldValue = tmpValue.split(',')
                    }
                }

                if (fieldLang && isDefined(fieldLang) && fieldLang !== '') {
                    if (
                        currentdoc[index][fieldName].value === '' ||
                        Array.isArray(currentdoc[index][fieldName].value)
                    ) {
                        currentdoc[index][fieldName].value = {}
                    }
                    currentdoc[index][fieldName].value[fieldLang] = fieldValue
                } else if (
                    currentdoc[index][fieldName] &&
                    currentdoc[index][fieldName] !== ''
                ) {
                    currentdoc[index][fieldName].value = fieldValue
                }
            }

            state.questionEntry = currentdoc
            actions.updateValidation({
                fieldName: fieldName,
                fieldValue: fieldValue,
                fieldLang: fieldLang,
                fieldIndex: index,
                returnValue: false,
            })
        },
        updateValidation:
            ({
                fieldName,
                fieldValue,
                fieldLang,
                fieldIndex,
                returnValue = false,
            }) =>
            (state, actions) => {
                let currentdoc = state.questionEntry
                if (fieldName === undefined || fieldName === null) {
                    return false
                }
                let validation =
                    currentdoc[fieldIndex][fieldName] !== undefined
                        ? currentdoc[fieldIndex][fieldName].validation
                        : null
                let isValid = false,
                    val = '',
                    entry = '',
                    errorLang = null
                if (validation !== undefined && validation !== null) {
                    let validationMode = validation.type
                    let validationMandatory = validation.mandatory
                    if (
                        (fieldValue !== '' &&
                            fieldValue !== null &&
                            fieldValue !== undefined) ||
                        validationMandatory === true
                    ) {
                        if (validationMode === 'isChecked') {
                            if (fieldValue === true) {
                                isValid = true
                            }
                        } else if (validationMode === 'atLeastOneChecked') {
                            if (
                                currentdoc[fieldIndex][fieldName].type ===
                                'translation'
                            ) {
                                let found = false
                                for (val in currentdoc[fieldIndex][fieldName]
                                    .value) {
                                    for (entry in currentdoc[fieldIndex][
                                        fieldName
                                    ].value[val]) {
                                        if (
                                            (currentdoc[fieldIndex][fieldName]
                                                .value[val][entry]
                                                .goodResponse !== undefined &&
                                                currentdoc[fieldIndex][
                                                    fieldName
                                                ].value[val][entry]
                                                    .goodResponse === 1) ||
                                            (currentdoc[fieldIndex][fieldName]
                                                .value[val][entry]
                                                .goodResponse === undefined &&
                                                currentdoc[fieldIndex][
                                                    fieldName
                                                ].value[val][entry].value !==
                                                    '')
                                        ) {
                                            found = true
                                            break
                                        }
                                    }
                                    if (found) {
                                        isValid = true
                                        break
                                    }
                                }
                            } else {
                                if (
                                    Array.isArray(
                                        currentdoc[fieldIndex][fieldName].value
                                    ) &&
                                    currentdoc[fieldIndex][fieldName].value
                                        .length > 0
                                ) {
                                    isValid = true
                                } else {
                                    for (val in currentdoc[fieldIndex][
                                        fieldName
                                    ].value) {
                                        if (
                                            currentdoc[fieldIndex][fieldName]
                                                .value[val] === true
                                        ) {
                                            isValid = true
                                            break
                                        }
                                    }
                                }
                            }
                        } else if (
                            validationMode === 'not-empty' ||
                            validationMode === 'selected'
                        ) {
                            let found = true
                            if (
                                currentdoc[fieldIndex][fieldName].type ===
                                'translation'
                            ) {
                                for (var lang in currentdoc[fieldIndex].langs
                                    .value) {
                                    if (
                                        currentdoc[fieldIndex].langs.value[
                                            lang
                                        ] === true &&
                                        currentdoc[fieldIndex][fieldName].value[
                                            lang
                                        ] === ''
                                    ) {
                                        found = false
                                        if (
                                            lang !== null &&
                                            lang !== undefined
                                        ) {
                                            errorLang = lang
                                        }
                                        break
                                    }
                                }
                                if (found) {
                                    isValid = true
                                }
                            } else {
                                if (
                                    fieldValue !== '' &&
                                    fieldValue !== null &&
                                    fieldValue !== undefined
                                ) {
                                    isValid = true
                                }
                            }
                        } else if (
                            validationMode === 'atLeastTwoFilled' ||
                            validationMode ===
                                'atLeastTwoFilledForceValueToOrder'
                        ) {
                            let nInput = 0
                            let inputValid = true
                            let values = currentdoc[fieldIndex][fieldName].value
                            if (fieldLang && fieldLang !== null) {
                                values = values[fieldLang]
                            }
                            for (var langg in values) {
                                inputValid = true
                                for (var evall in values[langg]) {
                                    for (var vall in values[langg][evall]) {
                                        if (
                                            vall !== 'tag' &&
                                            (values[langg][evall][vall] ===
                                                '' ||
                                                values[langg][evall][vall] ===
                                                    null)
                                        ) {
                                            if (
                                                validationMode ===
                                                    'atLeastTwoFilledForceValueToOrder' &&
                                                vall === 'value' &&
                                                +values[langg][evall]['order'] >
                                                    -1
                                            ) {
                                                values[langg][evall][vall] =
                                                    values[langg][evall][
                                                        'order'
                                                    ].toString()
                                            } else {
                                                inputValid = false
                                            }
                                        }
                                    }
                                    if (inputValid) {
                                        nInput++
                                    }
                                }
                                if (nInput >= 2) {
                                    isValid = true
                                } else {
                                    break
                                }
                            }
                        } else if (validationMode === 'atLeastOneSelected') {
                            let values = currentdoc[fieldIndex][fieldName].value
                            if (Array.isArray(values)) {
                                if (values.length > 0) {
                                    isValid = true
                                }
                            } else {
                                if (Object.keys(values).length > 0) {
                                    isValid = true
                                }
                            }
                        } else if (validationMode === 'atLeastOneFilled') {
                            let found = false
                            for (val in currentdoc[fieldIndex][fieldName]
                                .value) {
                                if (
                                    currentdoc[fieldIndex][fieldName].type ===
                                    'translation'
                                ) {
                                    for (entry in currentdoc[fieldIndex][
                                        fieldName
                                    ].value[val]) {
                                        if (
                                            currentdoc[fieldIndex][fieldName]
                                                .value[val][entry] !== ''
                                        ) {
                                            found = true
                                            break
                                        }
                                    }
                                } else {
                                    if (
                                        currentdoc[fieldIndex][fieldName].value[
                                            val
                                        ] !== ''
                                    ) {
                                        found = true
                                        break
                                    }
                                }
                                if (found) {
                                    break
                                }
                            }
                            if (found) {
                                isValid = true
                            }
                        } else if (validationMode === 'is-email') {
                            let value =
                                currentdoc[fieldIndex][fieldName].value || null
                            let regex = new RegExp(/^\S+@\S+$/)
                            if (value !== null && value !== '') {
                                isValid =
                                    value.match(regex) === null ? false : true
                            }
                        } else if (validationMode === 'is-date') {
                            let value =
                                currentdoc[fieldIndex][fieldName].value || null
                            if (value !== null && value !== '') {
                                var testDate = null
                                if (value.date !== undefined) {
                                    testDate = new Date(value.date)
                                } else if (value.day !== undefined) {
                                    testDate = new Date(
                                        value.year +
                                            '/' +
                                            value.month +
                                            '/' +
                                            value.day
                                    )
                                } else {
                                    testDate = new Date(value)
                                }
                                if (
                                    testDate instanceof Date &&
                                    !isNaN(testDate)
                                ) {
                                    isValid = true
                                }
                            }
                        }
                    } else {
                        isValid = true
                    }
                    currentdoc[fieldIndex][fieldName].validation.isValid =
                        isValid
                    currentdoc[fieldIndex][fieldName].validation.errorLang =
                        errorLang
                    if (!isValid) {
                        if (fieldLang !== undefined && fieldLang !== null) {
                            errorLang = fieldLang
                        }
                    } else {
                        errorLang = null
                    }
                    if (!returnValue) {
                        state.questionEntry = currentdoc
                    }
                }
                if (!returnValue) {
                    actions.setQuestionEntry(currentdoc)
                } else {
                    return currentdoc
                }
            },
        addQuestionEntry:
            (entry = null) =>
            (state, actions) => {
                let questionEntry = state.questionEntry
                let newEntry = null
                if (entry !== null) {
                    newEntry = deepCopy(entry)
                } else {
                    newEntry = deepCopy(state.arrayOfField)
                }
                questionEntry.push(newEntry)
                actions.setQuestionEntry(questionEntry)
            },
        updateQuestionEntry:
            ({ index, value }) =>
            (state, actions) => {
                let questionEntry = state.questionEntry
                questionEntry[index].responses.value = value
                actions.setQuestionEntry(questionEntry)
            },
        removeQuestionEntry: (index) => (state, actions) => {
            let questionEntry = state.questionEntry
            questionEntry.splice(index, 1)
            actions.setQuestionEntry(questionEntry)
            actions.parseAndSendToParent()
        },
        setArrayOfField: (newState) => (state) => ({
            arrayOfField: newState,
        }),
        setQuestionEntry: (newState) => (state) => ({
            questionEntry: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {state.questionEntry.map((entry, order) => (
                <dummy
                    key={'entry-' + order}
                    class="adminformarrayofevalmaker-wrapper"
                    data-order={order}
                >
                    <div
                        class="sup-custom-select-delete-question"
                        onclick={() => {
                            actions.removeQuestionEntry(order)
                        }}
                    >
                        <p class="">{t('Supprimer la question')}</p>
                    </div>
                    {Object.keys(entry).map((key) => (
                        <div
                            key={'entry-' + key + '-' + order}
                            class={
                                'sup-group-form ' +
                                'input-' +
                                entry[key].name +
                                ' ' +
                                (entry[key].display &&
                                entry[key].display.border === 'bottom'
                                    ? 'border-bottom'
                                    : '') +
                                ' ' +
                                (entry[key].display &&
                                entry[key].display.mode === 'half'
                                    ? 'col-6 col-xs-12'
                                    : 'col-12') +
                                (state.formSubmitted &&
                                entry[key].validation !== undefined &&
                                entry[key].validation.isValid === false
                                    ? ' field-is-in-error'
                                    : '')
                            }
                            style={{
                                display:
                                    entry[key].field === 'hidden'
                                        ? 'none'
                                        : 'inline-block',
                            }}
                        >
                            {entry[key].label !== '' &&
                                entry[key].label !== undefined && (
                                    <div
                                        style={{
                                            'display': 'inline-block',
                                            'vertical-align': 'top',
                                        }}
                                    >
                                        <label class="sup-label">
                                            {entry[key].label +
                                                (entry[key].validation !==
                                                    undefined &&
                                                entry[key].validation
                                                    .mandatory === true
                                                    ? '*'
                                                    : '')}
                                        </label>
                                        <AdminFormHelp
                                            type={entry[key].type}
                                        ></AdminFormHelp>
                                    </div>
                                )}
                            {entry[key].field === 'dropdown' && (
                                <AdminFormDropDown
                                    name={key}
                                    document={entry[key]}
                                    list={entry[key].list}
                                    onclick={actions.updateDocument}
                                ></AdminFormDropDown>
                            )}
                            {entry[key].field === 'eval' && (
                                <AdminFormEvalMaker
                                    key={'entry-eval-' + key + '-' + order}
                                    order={order}
                                    name={entry[key].name}
                                    document={entry[key].value}
                                    formsType={entry.type.value}
                                    formName={state.formName}
                                    list={entry[key].list}
                                    onclick={actions.updateDocument}
                                    oninput={actions.sendToParent}
                                    evalsmodel={state.evalsmodel}
                                ></AdminFormEvalMaker>
                            )}
                            {['dropdown', 'eval'].indexOf(entry[key].field) ===
                                -1 &&
                                entry[key].field !== undefined && (
                                    <input
                                        key={'field-' + entry[key].name}
                                        type={entry[key].field}
                                        name={entry[key].name}
                                        placeholder={entry[key].placeholder}
                                        value={entry[key].value}
                                        oninput={actions.updateDocument}
                                    />
                                )}
                        </div>
                    ))}
                </dummy>
            ))}

            <Form.Group classes="btzForm-btzFooter">
                <Button
                    primary
                    flat
                    onclick={() => {
                        actions.addQuestionEntry(null)
                    }}
                >
                    {t('Ajouter une question')}
                </Button>
            </Form.Group>
        </div>
    ),
    'adminformarrayofevalmaker'
)

export { AdminFormArrayOfEvalMaker }

import { Card } from '@app/api'

const corpoevaluation_STATISTICS = {
    header: ['Statistique question'],
    target: Card.getCardStatisticsByKind,
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        type: {
            field: 'hidden',
            value: 'corpo-eval',
        },
        question: {
            name: 'question',
            type: 'string',
            label: '',
            field: 'question',
            value: '',
        },
        results: {
            name: 'results',
            type: 'dataviz',
            label: '',
            field: 'result',
            value: '',
        },
    },
}

export { corpoevaluation_STATISTICS }

import { t, h } from '@app/utils'
import { allRoutes, addCSRFToken } from '@app/core'
import { Link, MessagePopup } from '@app/elements'

import icoFlagfrFR from '@app/img/ico/flag/flag-fr.png'
import icoFlagfrFRx2 from '@app/img/ico/flag/flag-fr@2x.png'
import icoFlagenGB from '@app/img/ico/flag/flag-en.png'
import icoFlagenGBx2 from '@app/img/ico/flag/flag-en@2x.png'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) =>
            (
                <div class="">
                    {state.messageActive && (
                        <MessagePopup
                            key={'msspp-usermenu'}
                            kind={state.messageKind}
                            active={'active'}
                            duration={3000}
                            durationCallback={() =>
                                actions.displaySuccessMessage(false)
                            }
                            message={state.messageContent}
                        ></MessagePopup>
                    )}
                    {props.display !== 'login' && (
                        <dummy>
                            <div class="usermenu-userinformations">
                                <img src={state.URLUserMedia} />
                                <p class="">{state.userName}</p>
                                <a onclick={actions.clearTimeline}>
                                    {t('Vider le journal')}
                                </a>
                            </div>
                            <Link
                                to={addCSRFToken(
                                    allRoutes['configuration'].pathname
                                )}
                                class={'btn btn-link'}
                            >
                                {t('Configuration')}
                            </Link>
                            <Link
                                to={addCSRFToken(
                                    allRoutes['operations'].pathname
                                )}
                                class={'btn btn-link'}
                            >
                                {t('Gestion')}
                            </Link>
                            <a
                                onclick={actions.deconnectUser}
                                class={'btn btn-link'}
                            >
                                {t('Déconnexion')}
                            </a>
                        </dummy>
                    )}

                    <div class={'usermenu-userlanguages'}>
                        <img
                            class={
                                state.currentLanguage === 'fr'
                                    ? 'active'
                                    : ''
                            }
                            src={icoFlagfrFR}
                            srcset={`${icoFlagfrFRx2} 2x`}
                            alt={t('Changer la langue en Français')}
                            onclick={() => actions.updateLanguage('fr')}
                        />
                        <img
                            class={
                                state.currentLanguage === 'en'
                                    ? 'active'
                                    : ''
                            }
                            src={icoFlagenGB}
                            srcset={`${icoFlagenGBx2} 2x`}
                            alt={t('Changer la langue en Anglais')}
                            onclick={() => actions.updateLanguage('en')}
                        />
                    </div>
                </div>
            )
        return view
    },
}

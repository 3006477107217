import { t } from '@app/utils'
import * as constants from '@app/constants'

const card_CHALLENGE = {
    header: ['Configurer la carte', 'Défi'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: '',
        },
        type: {
            type: 'int',
            field: 'hidden',
            value: '',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de la carte',
            field: 'text',
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        theme: {
            name: 'theme',
            type: 'int',
            label: 'Thématique',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
        },
        frequency: {
            name: 'frequency',
            type: 'int',
            label: 'Fréquence',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.FREQUENCY,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        challenge: {
            name: 'challenge',
            substitute: 'challengeId',
            type: 'autocomplete/challenge',
            label: 'Défi lié',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigné un défi lié',
                i18nMessage: t('Veuillez renseigné un défi lié'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
    },
}

export { card_CHALLENGE }

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const card_CARD = {
    filter: ['type', 'theme', 'status', 'frequency'],
    tableHead: [
        { key: 'name', label: 'nom' },
        { key: 'type', label: 'type' },
        { key: 'frequency', label: 'fréquence' },
        { key: 'date', label: 'date création', i18nlabel: t('date création') },
        { key: 'status', label: 'statut', i18nlabel: t('statut') },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            i18nlabel: t('Éditer'),
            kind: 'web',
            action: addCSRFToken('/content/cards/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            i18nlabel: t('Dupliquer'),
            kind: 'web',
            action: addCSRFToken('/content/cards/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            i18nlabel: t('Désactiver'),
            kind: 'api',
            action: Card.editCard,
        },
        {
            key: 'frequency',
            label: 'Changer la fréquence',
            i18nlabel: t('Changer la fréquence'),
            kind: 'list-api',
            list: Object.keys(constants.custo.FREQUENCY).map(
                (i) => constants.custo.FREQUENCY[i]
            ),
            action: Card.editCard,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            i18nlabel: t('Supprimer'),
            kind: 'confirm',
            action: Card.deleteCard,
        },
    ],
    tableAllOptions: {
        label: 'CARTES SÉLECTIONNÉES',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                i18nlabel: t('Activer'),
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'frequency',
                label: 'Changer la fréquence',
                kind: 'list-api',
                list: Object.keys(constants.custo.FREQUENCY).map(
                    (i) => constants.custo.FREQUENCY[i]
                ),
                action: Card.editCard,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteCard,
            },
        ],
    },
    form: {},
}

export { card_CARD }

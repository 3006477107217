import { h, Component } from '@app/utils'
import { Trans, Button } from '@app/elements'

import imgClose from './../../ico-close-black-big.png'
import imgClosex2 from './../../ico-close-black-big@2x.png'

import './index.scss'

const ModalConfirm = Component(
    {
        modalStatus: 'btzModalConfirm',
        kind: '',
    },
    {
        onComponentCreate: (props) => (state) => {
            if (props.kind) {
                state.kind = props.kind
            }
        },
        confirmAction: (props) => (state, actions) => {
            if (props) {
                props.propagateConfirm(props)
            }
        },
        cancelAction: (props) => (state, actions) => {
            if (props) {
                props.propagateCancel()
            }
        },
    },
    (state, actions) => (props, children) => (
        <div class="btzModalConfirm">
            <div
                class="btzModal-btzCloseModal"
                style=""
                onclick={() => actions.cancelAction(props)}
            >
                <img
                    src={imgClose}
                    srcset={`${imgClosex2} 2x`}
                    alt=""
                    class=""
                />
            </div>

            <div class="btzModalConfirm-btzVerticalWrapper">
                <p class="btzTitle btzXl">{props.title}</p>
                <p class="btzSubTitle">{props.description}</p>

                <Button
                    primary
                    onclick={() => {
                        actions.cancelAction(props)
                    }}
                >
                    <Trans>Annuler</Trans>
                </Button>

                <Button
                    primary
                    active
                    onclick={() => {
                        actions.confirmAction(props)
                    }}
                >
                    <Trans>Confirmer</Trans>
                </Button>
            </div>
        </div>
    ),
    'modalConfirm'
)

export { ModalConfirm }

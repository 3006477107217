import { h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { isDefined } from '@app/core'
import './index.scss'

const AdminFormWithInputToTextarea = Component(
    {
        ulDisplayed: false,
        list: [],
        listKind: 'static',
        selected: [],
        expression: '',
        model: { key: null, value: null },
        name: '',
        type: '',
        currentSearch: '',
        sendToParent: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document.name) {
                state.name = props.document.name
            }
            if (props.document.type) {
                state.type = props.document.type
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.document.model) {
                actions.setModel(props.document.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            actions.setExpression(props.document.value)
            actions.defineList(props)
        },
        selectThisOne:
            ({ el, props }) =>
            (state, actions) => {
                actions.setCurrentSearch('')
                state.selected.name = ''
                state.sendToParent({
                    target: { name: state.name, value: state.expression + el },
                })
                actions.toggleUl(false)
            },
        createThisOne:
            ({ el, props }) =>
            (state, actions) => {
                state.sendToParent({
                    target: { name: state.name, value: state.expression + el },
                })
                actions.toggleUl(false)
            },
        removeThisOne:
            ({ props }) =>
            (state, actions) => {
                actions.toggleUl(false)
            },
        defineList: (props) => (state, actions) => {
            let list = props.list
            if (props.document.type === 'kpi') {
                list = props.kpiList
            }
            if (isDefined(list)) {
                list = Object.keys(list).map((i) => list[i])
                if (isDefined(list)) {
                    actions.setList(list)
                }
            }
        },
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setListKind: (newState) => (state) => ({
            listKind: newState,
        }),
        setCurrentSearch: (newState) => (state) => ({
            currentSearch: newState,
        }),
        setExpression: (newState) => (state) => ({
            expression: newState,
        }),
        setSelected: (newState) => (state) => ({
            selected: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        onUpdate: (value) => (state, actions) => {
            state.selected.name = value
            actions.setCurrentSearch(value)
            actions.toggleUl(true)
        },
        onBlur: (el) => (state, actions) => {
            var target = el.target || el
            state.sendToParent({
                target: { name: state.name, value: target.value },
            })
        },
        waitForLastInput: ({ target }) =>
            debounce(500, 'onUpdate', target.value),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            <div
                class="sup-custom-select sup-custom-select-wide-one"
                tabindex="0"
                onblur={() => {
                    actions.toggleUl(false)
                }}
                onclick={() => {
                    actions.toggleUl(!state.ulDisplayed)
                }}
            >
                <input
                    key={state.name}
                    class={'state-' + state.name}
                    type="text"
                    placeholder={props.document.placeholder.input}
                    oninput={actions.waitForLastInput}
                    onblur={(e) => {
                        isDefined(e.relatedTarget)
                            ? ''
                            : actions.toggleUl(false)
                    }}
                />
                <div
                    class="sup-drop-down"
                    style={{ 'margin-top': '-30px' }}
                ></div>
                <ul
                    class="sup-custom-select-ul"
                    style={{
                        display: state.ulDisplayed ? 'block' : 'none',
                        margin: '0px',
                    }}
                >
                    {state.list
                        .filter(
                            (li) =>
                                (props.document.searchbox === false &&
                                    (state.model.key === null
                                        ? li !== ''
                                        : li[state.model.value] !== '')) ||
                                ((state.model.key === null
                                    ? li
                                    : li[state.model.value]) !== '' &&
                                    ((state.currentSearch !== '' &&
                                        (state.model.key === null
                                            ? String(li)
                                            : li[state.model.value]
                                        ).indexOf(state.currentSearch) > -1) ||
                                        state.currentSearch === ''))
                        )
                        .map((l) => (
                            <li
                                class="sup-custom-select-li"
                                data-value={
                                    state.model.key === null
                                        ? l
                                        : l[state.model.key]
                                }
                                onclick={() => {
                                    actions.selectThisOne({
                                        el: l,
                                        props: props,
                                    })
                                }}
                            >
                                {state.model.key === null
                                    ? l
                                    : l[state.model.value]}
                            </li>
                        ))}
                </ul>
            </div>
            <div class="sup-textarea" style="">
                <textarea
                    name={props.name}
                    class={
                        'input-text' +
                        (props.document.type === 'kpi' ? ' small-one' : '')
                    }
                    value={state.expression}
                    onblur={(el) => {
                        actions.onBlur(el)
                    }}
                    placeholder={props.document.placeholder.textarea}
                ></textarea>
            </div>
        </div>
    ),
    'adminformwithinputtotextarea'
)

export { AdminFormWithInputToTextarea }

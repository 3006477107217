import { h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { User, Operations } from '@app/api'
import { allRoutes, addCSRFToken } from '@app/core'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.user_ESTABLISHMENT.form,
    initialized: false,
    tableHead: documents.user_ESTABLISHMENT.tableHead,
    tableOptions: documents.user_ESTABLISHMENT.tableOptions,
    tableAllOptions: documents.user_ESTABLISHMENT.tableAllOptions,
    tableFilter: documents.user_ESTABLISHMENT.filter,
    tableSearch: documents.user_ESTABLISHMENT.search,
    header: documents.user_ESTABLISHMENT.header,
    document: documents.user_ESTABLISHMENT,
    originaldocument: null,
    display: '',
    editId: null,
    canBeDeleted: true,
    forbiddenDeletion: documents.user_ESTABLISHMENT.forbiddenDeletion,
    establishments: [],
    currentUrl: '',
    kind: 'customers',
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Users/actions')

const view =
    (state, actions) => {
        return ({ match, customer }, children) => {
            return(
            <IndexLayout
                page="establishments"
                customer={customer}
                currentPage={'users'}
            >
                {state.initialized === true ? (
                    <div
                        key={state.initialized + '-' + state.display}
                        class={state.initialized + '-' + state.display}
                    >
                        {state.display === 'table' ? (
                            <Table
                                head={state.tableHead}
                                filter={state.tableFilter}
                                search={state.tableSearch}
                                options={state.tableOptions}
                                allOptions={state.tableAllOptions}
                                target={User.getEstablishments}
                                searchName={'search'}
                                forbiddenDeletion={state.forbiddenDeletion}
                            ></Table>
                        ) : (
                            <div
                                class=""
                                key={
                                    state.editId +
                                '-' +
                                state.initialized +
                                '-' +
                                Object.keys(state.document).length
                                }
                            >
                                {state.editId !== null ? (
                                    <div
                                        class={
                                            'adminform-edition-' +
                                        Object.keys(state.document).length
                                        }
                                        key={
                                            'adminform-edition-' +
                                        Object.keys(state.document).length
                                        }
                                    >
                                        <AdminForm
                                            header={state.header}
                                            editType={'1'}
                                            document={state.document}
                                            originaldocument={
                                                state.originaldocument
                                            }
                                            target={User.editEstablishment}
                                            backUrl={addCSRFToken(
                                                allRoutes[
                                                    'private.users.establishments'
                                                ].pathname
                                            )}
                                            canBeDeleted={state.canBeDeleted}
                                            deleteAction={User.deleteCustomer}
                                        ></AdminForm>
                                    </div>
                                ) : (
                                    <div key={'adminform-creation'}>
                                        <AdminForm
                                            header={state.header}
                                            editType={'1'}
                                            document={state.document}
                                            target={User.addEstablishment}
                                            card={state.document}
                                            backUrl={addCSRFToken(
                                                allRoutes[
                                                    'private.users.establishments'
                                                ].pathname
                                            )}
                                        ></AdminForm>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div> NOP {state.initialized} </div>
                )}
            </IndexLayout>
        )}
    }

export default Component(state, actions, view, 'establishments')

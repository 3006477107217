import { t } from '@app/utils'
import { allRoutes, logoutAndRedirect, isDefined } from '@app/core'
import { settings } from '@app/core/settings'

let msgTimeout = null
const { APP_WEB_URL } = settings

const handleErrors = (response) => {
    if (isDefined(response) && isDefined(response.code)) {
        response.isError = true
        let errorResponse = null,
            errorField = null
        switch (+response.code) {
            case 200:
            case 201:
            case 202:
            case 303: // no timeline for this day
            case 510: // tracker conflict
            case 812: // invalid invitation code, see #BL-5516
            case 907: // no debit was done with this credit card
                // OK, no error to display
                break

            case 208: // account blocked
                window.location.href =
                    APP_WEB_URL +
                    allRoutes['index'].pathname +
                    'login/blocked'
                break

            case 122: // no valid payment method
            case 805: // customer license is expired
                logoutAndRedirect()
                // window.location.href = allRoutes['index'].pathname
                break

            case 115:
                logoutAndRedirect()
                // window.location.href = allRoutes['index'].pathname
                break

            case 103: // disabled account
            case 210:
                logoutAndRedirect()
                // window.location.href = allRoutes['index'].pathname;
                break

            case 205:
            case 204: // expired session
                logoutAndRedirect()
                // window.location.href = allRoutes['index'].pathname;
                // return 'expired';
                break

            default:
                errorResponse = response.message
                //
                if (isDefined(response.extras)) {
                    errorField = response.extras.field
                    if (isDefined(errorField)) {
                        errorField = errorField.replace(/ /, '').split('/')[0]
                        if (isDefined(errorField)) {
                            if (errorField === 'phone') {
                                errorResponse = t(
                                    "Veuillez renseigner le numéro de téléphone de l'utilisateur"
                                )
                            }
                        }
                    }
                }
                //
                displayErrors(errorResponse, 2000)
        }
        return response
    } else {
        // everything is ok
    }
    return response
}

const displayErrors = function (message, time) {
    let errorContent = document.getElementById('btzMaintextContent')
    let errorMssg = document.getElementById('btzMainMessage')
    if (errorContent !== null) {
        errorContent.innerHTML = message

        if (errorMssg !== null) {
            errorMssg.classList.add('active')

            msgTimeout = setTimeout(function () {
                clearTimeout(msgTimeout)
                msgTimeout = null
                errorMssg.classList.remove('active')
            }, time)
        }
    }
}

export { displayErrors, handleErrors }

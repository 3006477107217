import { t, h, Component } from '@app/utils'
import { Modal, Button, Delete } from '@app/elements'
import { isDefined } from '@app/core'
import { Card } from '@app/api'
import './index.scss'

import { ModalViewMedia } from './modalView'

import icoDefaultNoImage from '@app/img/deco/default/img-default-square.png'
import icoDefaultNoImagex2 from '@app/img/deco/default/img-default-square@2x.png'

const AFMedia = Component(
    {
        name: '',
        index: 0,
        modeDisplayed: '',
        mediaBase64: '',
        mediaVideoUrl: '',
        mediaVideoUrlParsed: '',
        mediaActualSource: '',
        mediaId: null,
        mediaLang: null,
        mediaData: [],
        sendToParent: null,
        modalDisplayed: false,
        particularity: '',
        isVideo: false,
        mediaUrlGenerated: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('MEDIA: ', props)
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.particularity) {
                state.particularity = props.particularity
            }

            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                if (props.document.name) {
                    state.name = props.document.name
                }
                if (props.name) {
                    state.name = props.name
                }
                if (props.index) {
                    state.index = props.index
                }
                if (props.lang) {
                    state.mediaLang = props.lang
                }
                let url = null
                let id = null
                if (props.document.value === undefined) {
                    url = props.document.url
                    id = props.document.id
                } else {
                    url = props.document.value.url
                    id = props.document.value.id
                }

                if (id && isDefined(id)) {
                    actions.setMediaId(id)
                }
                if (url && isDefined(url) && typeof url === 'string') {
                    if (
                        url.indexOf('youtube') > -1 ||
                        url.indexOf('vimeo') > -1
                    ) {
                        actions.setMediaVideoUrls(url)
                        actions.setIsVideo(true)
                    } else {
                        actions.setMediaBase64(url)
                        actions.setIsVideo(false)
                    }
                    actions.setMediaActualSource(url)
                }
            } else {
                if (props.name) {
                    state.name = props.name
                }
                if (props.lang) {
                    state.mediaLang = props.lang
                }
                if (props.index) {
                    state.index = props.index
                }
            }
        },
        displayImageForm: () => (state, actions) => {
            actions.setDisplayedMode('image')
        },
        displayVideoForm: () => (state, actions) => {
            actions.setDisplayedMode('video')
        },
        setMediaId: (newState) => (state) => ({
            mediaId: newState,
        }),
        setDisplayedMode: (newState) => (state) => ({
            modeDisplayed: newState,
        }),
        setMediaBase64: (newState) => (state) => ({
            mediaBase64: newState,
        }),
        setIsVideo: (newState) => (state) => ({
            isVideo: newState,
        }),
        setMediaUrlGenerated: (newState) => (state) => ({
            mediaUrlGenerated: newState,
        }),
        setMediaVideoUrls: (url) => (state, actions) => {
            actions.setMediaVideoUrl(url)

            if (url !== '') {
                if (url.indexOf('vimeo') > -1) {
                    url =
                        '//player.vimeo.com/video/' +
                        url.split('/').pop() +
                        '?title=0&portrait=0&byline=0&badge=0'
                } else {
                    url =
                        '//www.youtube.com/embed/' +
                        url.split('?v=').pop() +
                        '?controls=0&showinfo=0'
                }
            }
            actions.setMediaVideoUrlParsed(url)
        },
        setMediaVideoUrl: (newState) => (state) => ({
            mediaVideoUrl: newState,
        }),
        setMediaVideoUrlParsed: (newState) => (state) => ({
            mediaVideoUrlParsed: newState,
        }),
        setMediaData: (newState) => (state) => ({
            mediaData: newState,
        }),
        setMediaLang: (newState) => (state) => ({
            mediaLang: newState,
        }),
        updateVideoUrl: (el) => (state, actions) => {
            actions.setMediaData(el.target.value)
            actions.setMediaVideoUrls(el.target.value)
            actions.setMediaBase64('')
            actions.setMediaActualSource('')
        },
        convertImageToB64: (el) => (state, actions) => {
            actions.setMediaVideoUrls('')
            actions.setMediaActualSource('')

            var f = el.target.files[0]
            var reader = new FileReader()
            reader.addEventListener(
                'load',
                function () {
                    actions.setMediaData(f)
                    actions.setMediaBase64(reader.result)
                },
                false
            )
            reader.readAsDataURL(f)
        },
        addMedia: () => (state, actions) => {
            if (state.particularity === 'mediaUrlGenerator') {
                if (!state.isVideo) {
                    Card.sendMedia(state.mediaData).then((res) => {
                        actions.setMediaUrlGenerated(res.data.url)
                        actions.displayModal(false)
                    })
                } else {
                    Card.sendVideo({ type: 1, video: state.mediaData }).then(
                        (res) => {
                            actions.setMediaUrlGenerated(res.data.url)
                            actions.displayModal(false)
                        }
                    )
                }
            } else {
                state.sendToParent({
                    target: {
                        name: state.name,
                        value: state.mediaData,
                        lang: state.mediaLang,
                        base64:
                            state.mediaBase64 !== ''
                                ? state.mediaBase64
                                : state.mediaVideoUrlParsed,
                    },
                })
                actions.displayModal(false)
                actions.setMediaActualSource(
                    state.mediaBase64 !== ''
                        ? state.mediaBase64
                        : state.mediaVideoUrlParsed
                )
            }
        },
        removeMedia: () => (state, actions) => {
            state.sendToParent({
                target: {
                    name: state.name,
                    value: false,
                    lang: state.mediaLang,
                    idx: state.index,
                },
            })
            actions.setMediaBase64('')
            actions.setMediaVideoUrl('')
            actions.setMediaActualSource('')
            actions.setMediaId(null)
        },
        setMediaActualSource: (newState) => (state) => ({
            mediaActualSource: newState,
        }),
        displayModal: (newState) => (state) => ({
            modalDisplayed: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key} style={{ position: 'relative' }}>
            <div key={'media-id'}>
                {state.mediaId !== null && (
                    <input
                        type="hidden"
                        name={state.name}
                        value={state.mediaId}
                    />
                )}
            </div>
            <div key={'default-media'}>
                {state.mediaActualSource === '' &&
                    state.particularity !== 'mediaUrlGenerator' && (
                        <img
                            class="activated"
                            src={icoDefaultNoImage}
                            srcset={`${icoDefaultNoImagex2} 2x`}
                            alt=""
                        />
                    )}
            </div>
            {state.mediaBase64 !== '' && state.mediaActualSource !== '' && (
                <img
                    style={{ 'max-width': '100%' }}
                    src={state.mediaActualSource}
                />
            )}
            {state.mediaVideoUrl !== '' &&
                state.mediaActualSource !== '' &&
                state.mediaActualSource !== '' && (
                    <iframe
                        style={{
                            'width': '100%',
                            'height': '100%',
                            'max-width': '200px',
                            'margin': '25px auto',
                            'display': 'block',
                        }}
                        src={state.mediaVideoUrlParsed}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowfullscreen="true"
                        width="100%"
                        height="100%"
                        frameborder="0"
                    ></iframe>
                )}
            {(state.mediaBase64 !== '' || state.mediaVideoUrl !== '') &&
                state.mediaActualSource !== '' &&
                (props.desabled === undefined ||
                    props.desabled !== 'desabled') && (
                    <Delete
                        action={() => {
                            actions.removeMedia()
                        }}
                    ></Delete>
                )}
            {(props.desabled && props.desabled === 'desabled') ||
            (props.cantChooseMedia && props.cantChooseMedia === true) ? (
                <dummy></dummy>
            ) : (
                <Modal
                    modalDisplayed={state.modalDisplayed}
                    cancelAction={(e) => {
                        console.log('CANCEL ACTION')
                        actions.displayModal(false)
                    }}
                    content={
                        <ModalViewMedia
                            addMedia={actions.addMedia}
                            updateVideoUrl={actions.updateVideoUrl}
                            convertImageToB64={actions.convertImageToB64}
                            displayImageForm={actions.displayImageForm}
                            displayVideoForm={actions.displayVideoForm}
                            modeDisplayed={state.modeDisplayed}
                            mediaData={state.mediaData}
                            mediaBase64={state.mediaBase64}
                            mediaVideoUrlParsed={state.mediaVideoUrlParsed}
                            particularity={state.particularity}
                        ></ModalViewMedia>
                    }
                >
                    <div class="supAddMediaButton">
                        <Button
                            primary
                            colored
                            onclick={() => actions.displayModal(true)}
                        >
                            <p class="" style={{ margin: '0px' }}>
                                {state.particularity === 'mediaUrlGenerator'
                                    ? t('Générer une url de média')
                                    : t('Choisir un média')}
                            </p>
                        </Button>
                    </div>
                </Modal>
            )}
            {state.particularity === 'mediaUrlGenerator' && (
                <p class="">
                    {t('Url de la dernière image générée : ') +
                        state.mediaUrlGenerated}
                </p>
            )}
        </div>
    ),
    'afmedia'
)

export { AFMedia }

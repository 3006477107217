import { settings } from '@app/core/settings'
import { i18n } from '@app/utils'
import { en, fr, no } from '@app/locales'

const withTranslation =
    (opts = {}) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        let lng = localStorage.getItem('language')
        if (
            lng === undefined ||
            lng === null ||
            lng.length === 0 ||
            lng.indexOf('_') === -1
        ) {
            lng = 'fr'
        }
        const options = {
            ...{
                // 'lng': settings.LANG,
                lng: lng.split('_')[0],
                fallbackLng: settings.APP_FALLBACKLANG,
                // 'debug': settings.DEBUG,
                debug: false,
                nsSeparator: false,
                keySeparator: false,
                formatSeparator: false,
                pluralSeparator: false,
                contextSeparator: false,
                joinArrays: true,
                resources: {
                    fr: { translation: fr },
                    en: { translation: en },
                    no: { translation: no },
                },
                interpolation: {
                    escapeValue: true,
                    formatSeparator: false,
                },
            },
            ...opts,
        }

        const enhancedState = {
            lang: options.lng,
        }

        const enhancedActions = {
            initLang: () => {
                i18n.init(options)
            },
            changeLanguage: (lang) => {
                i18n.changeLanguage(lang)
                return { lang }
            },
        }
        enhancedActions.initLang()
        // addEventListener('changelang', () => enhancedActions.changeLanguage('en'));

        return nextApp(
            { ...initialState, ...enhancedState },
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )
    }

export { withTranslation }

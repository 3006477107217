import { h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { AutoComplete, Programs } from '@app/api'
import { isDefined } from '@app/core'
import { Delete } from '@app/elements'
import './index.scss'

const AdminFormLinkedDropDown = Component(
    {
        name: '',
        secondName: '',
        type: '',
        ulDisplayed: false,
        secondUlDisplayed: false,
        list: [],
        secondList: [],
        selected: null,
        secondSelected: null,
        search: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document.name) {
                state.name = props.document.name
                if (
                    props.document.secondName &&
                    props.document.secondName !== undefined
                ) {
                    state.secondName = props.document.secondName
                }
            }
            if (props.document.type) {
                state.type = props.document.type
            }

            if (props.document.list === 'dynamic') {
                actions.updateFieldSelection(props)
            }

            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.search) {
                actions.selectThisOne({ name: props.search, id: props.search })
                actions.setSearch(props.search)
            }
        },
        updateFieldSelection: (props) => (state, actions) => {
            if (
                props.document.name === 'programId' &&
                props.document.value !== ''
            ) {
                Programs.getProgramById(props.document.value).then((res) => {
                    if (
                        isDefined(res.data) &&
                        !res.data.isError &&
                        res.data.is
                    ) {
                        actions.selectThisOne({
                            key: '',
                            value: '',
                            id: res.data.id,
                            name: res.data.name,
                        })
                        actions.setSecondList(res.data.sessions)

                        if (props.document.secondValue) {
                            let secondOne = res.data.sessions.filter(
                                (f) => f.id === props.document.secondValue
                            )
                            if (secondOne.length > 0) {
                                secondOne = secondOne[0]
                                actions.selectThisSecondOne({
                                    key: '',
                                    value: '',
                                    id: secondOne.id,
                                    name: secondOne.name,
                                })
                            }
                        }
                    }
                })
            } else {
                //
            }
        },
        selectThisOne: (newState) => (state) => ({
            search: '',
            selected: newState,
            ulDisplayed: false,
        }),
        selectThisSecondOne: (newState) => (state) => ({
            secondSelected: newState,
            secondUlDisplayed: false,
        }),
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setSecondList: (newState) => (state) => ({
            secondList: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        toggleSecondUl: (newState) => (state) => ({
            secondUlDisplayed: newState,
        }),
        setSearch: (newState) => (state) => ({
            search: newState,
        }),
        onUpdate: (value) => (state, actions) => {
            actions.setSearch(value)
            if (value === '') {
                actions.setList([])
                actions.toggleUl(false)
            } else {
                if (state.type.indexOf('autocomplete') > -1) {
                    let completeKind = state.type.split('/')[1]
                    switch (completeKind) {
                        case 'program':
                            AutoComplete.getPrograms(value).then((res) => {
                                if (isDefined(res.data) && !res.data.isError) {
                                    actions.setList(res.data)
                                }
                                actions.toggleUl(true)
                            })
                            break
                        default:
                            console.log(
                                'Sorry, we are out of ' + completeKind + '.'
                            )
                    }
                } else {
                    actions.toggleUl(true)
                }
            }
        },
        waitForLastInput: ({ target }) =>
            debounce(500, 'onUpdate', target.value),
    },
    (state, actions) => (props, children) => (
        <div>
            <div style={{ display: 'inline-block', position: 'relative' }}>
                <div
                    class="sup-custom-select sup-custom-select-wide-one"
                    data-name={props.name}
                    tabindex="0"
                    onblur={() => {
                        actions.toggleUl(false)
                    }}
                >
                    <input
                        type="hidden"
                        name={props.name}
                        value={
                            state.selected !== null && state.selected.id !== ''
                                ? state.selected.id
                                : ''
                        }
                    />
                    <input
                        key={props.document.value}
                        type="text"
                        value={
                            state.selected !== null &&
                            state.selected.name !== '' &&
                            state.search === ''
                                ? state.selected.name
                                : state.search
                        }
                        placeholder={props.document.placeholder}
                        oninput={actions.waitForLastInput}
                    />
                    <ul
                        class="sup-custom-select-ul"
                        style={{
                            display: state.ulDisplayed ? 'block' : 'none',
                            margin: '0px',
                        }}
                    >
                        {state.list
                            .filter((li) => li.name !== '')
                            .map((l) => (
                                <li
                                    class="sup-custom-select-li"
                                    data-name={props.name}
                                    data-value={l.id}
                                    data-readable={l.name}
                                    onclick={(el) => {
                                        actions.selectThisOne(l)
                                        props.onclick(el)
                                        actions.updateFieldSelection(props)
                                    }}
                                >
                                    {l.name}
                                </li>
                            ))}
                    </ul>
                </div>
                {props.document.deletable === true && (
                    <Delete
                        action={() => {
                            actions.selectThisOne({
                                key: '-1',
                                value: '',
                                id: '',
                                name: '',
                            })
                            props.onclick({
                                target: { name: props.name, value: '' },
                            })
                            actions.updateFieldSelection(props)
                        }}
                    ></Delete>
                )}
            </div>
            {state.selected !== null && (
                <div
                    style={{ 'display': 'inline-block', 'margin-left': '25px' }}
                    tabindex="0"
                    onblur={() => {
                        actions.toggleSecondUl(false)
                    }}
                >
                    <div
                        class="sup-custom-select sup-custom-select-wide-one"
                        data-name={props.document.secondName}
                    >
                        <input
                            type="hidden"
                            name={props.document.secondName}
                            value={
                                state.secondSelected !== null &&
                                state.secondSelected.id !== ''
                                    ? state.secondSelected.id
                                    : ''
                            }
                        />
                        <input
                            key={props.document.value}
                            type="text"
                            value={
                                state.secondSelected !== null &&
                                state.secondSelected.name !== ''
                                    ? state.secondSelected.name
                                    : ''
                            }
                            placeholder={props.document.placeholder}
                            readOnly
                        />
                        <div
                            class="sup-drop-down"
                            style={{ 'margin-top': '-30px' }}
                            onclick={() => {
                                actions.toggleSecondUl(!state.secondUlDisplayed)
                            }}
                        ></div>
                        <ul
                            class="sup-custom-select-ul"
                            style={{
                                display: state.secondUlDisplayed
                                    ? 'block'
                                    : 'none',
                                margin: '0px',
                            }}
                        >
                            {state.secondList
                                .filter((li) => li.name !== '')
                                .map((l) => (
                                    <li
                                        class="sup-custom-select-li"
                                        data-name={props.document.secondName}
                                        data-value={l.id}
                                        data-linkedTo={props.name}
                                        data-readable={l.name}
                                        onclick={(el) => {
                                            actions.selectThisSecondOne(l)
                                            props.onclick(el)
                                        }}
                                    >
                                        {l.name}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    ),
    'adminformlinkeddropdown'
)

export { AdminFormLinkedDropDown }

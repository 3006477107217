import { t, h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { Card } from '@app/api'
import { allRoutes, addCSRFToken, getQueryVariable, isDefined } from '@app/core'
import './index.scss'
import * as definitions from '@app/definitions'
import * as constants from '@app/constants'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    initialized: false,
    documents: Object.keys(definitions.custo.CARDS).map(
        (i) => definitions.custo.CARDS[i]
    ),
    tableHead: documents.card_CARD.tableHead,
    tableOptions: documents.card_CARD.tableOptions,
    tableAllOptions: documents.card_CARD.tableAllOptions,
    tableFilter: documents.card_CARD.filter,
    header: '',
    document: { template: '' },
    display: '',
    editId: null,
    editType: null,
    cards: Object.keys(constants.custo.CARDS_CREATION).map(
        (i) => constants.custo.CARDS_CREATION[i]
    ),
    currentUrl: '',
    kind: 'cards',
    modeDuplicate: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // let cards = Object.keys(constants.custo.CARDS_CREATION).map(i => constants.custo.CARDS_CREATION[i]);
        // state.cards = cards;
        let params = props === null ? null : props.match.params
        if (
            params === null ||
            params === undefined ||
            (params.id !== undefined && params.id === 'null')
        ) {
            actions.onComponentUpdate(props)
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        actions.setInitialized(false)
        actions.setEditType(null)
        actions.setEditId(null)
        actions.setDocument(null)
        //
        let params = props === null ? null : props.match.params
        if (params === null || params === undefined) {
            actions.setDisplay('table')
            actions.setInitialized(true)
        } else {
            let modeDuplicate = getQueryVariable('mode', false) === 'duplicate'
            actions.setModeDuplicate(modeDuplicate)
            let editType = getQueryVariable('type', false)
            if (isDefined(editType) && editType !== false) {
                let filtered = state.documents.filter(
                    (f) => +f.key === +editType
                )[0]
                if (isDefined(filtered) && isDefined(filtered.document)) {
                    actions.setHeader(filtered.document.header)
                    actions.setDocument(filtered.document.form)
                }
                actions.setEditType(editType)
            } else {
                actions.setEditType(null)
            }

            let cardid = params.id
            if (cardid) {
                if (cardid !== 'null') {
                    actions.setEditId(cardid)
                    actions.setDisplay('form')
                    actions.setInitialized(true)
                    Card.getCard(cardid).then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            let docType = state.documents.filter(
                                (f) => +f.key === +res.data.type
                            )[0]
                            if (docType !== undefined) {
                                actions.setHeader(docType.document.header)
                                actions.setDocument(docType.document.form)
                            }
                            if (modeDuplicate) {
                                res.data.id = undefined
                                if (res.data.name) {
                                    res.data.name = res.data.name + '-copy'
                                }
                                res.data.status = 0
                            }
                            actions.parseAndSetDocument(res.data)
                            if (isDefined(res.data.type)) {
                                actions.setEditType(res.data.type)
                            }
                        }
                    })
                } else {
                    actions.setEditId(null)
                    actions.setDisplay('form')
                    actions.setInitialized(true)
                }
            } else {
                actions.setDisplay('table')
                actions.setInitialized(true)
            }
        }
    },
    parseAndSetDocument: (doc) => (state, actions) => {
        let document = JSON.parse(JSON.stringify(state.document))
        let sliderType = '',
            sliderTag = '',
            potentialCounterObject = null
        for (var field in doc) {
            var fieldName = field
            var mappedFieldName = fieldName
            field = doc[fieldName]
            if (field !== null && field !== undefined) {
                if (
                    document[mappedFieldName] &&
                    document[mappedFieldName] !== null &&
                    document[mappedFieldName] !== undefined
                ) {
                    if (
                        document[mappedFieldName].type === 'translation' &&
                        field[0] !== undefined &&
                        field[0].datas !== undefined
                    ) {
                        let lang = '',
                            name = '',
                            value = '',
                            goodResponse = '',
                            media = '',
                            order = '',
                            min = '',
                            max = '',
                            defaut = '',
                            terminology = {},
                            terminologyAttribute = ''
                        let newValues = {}
                        for (var entry in field) {
                            sliderType = field[entry].type
                            sliderTag = field[entry].tag
                            terminology = field[entry].terminology
                            terminologyAttribute =
                                field[entry].terminologyAttribute
                            goodResponse = field[entry].goodResponse
                            order = field[entry].order
                            for (var dat in field[entry].datas) {
                                lang = field[entry].datas[dat].lang
                                name = field[entry].datas[dat].name
                                value = field[entry].datas[dat].value
                                min = field[entry].datas[dat].min
                                max = field[entry].datas[dat].max
                                defaut = field[entry].datas[dat].default
                                media = field[entry].datas[dat].media
                                if (
                                    isDefined(field[entry].datas[dat].order) &&
                                    !isDefined(order)
                                ) {
                                    order = field[entry].datas[dat].order
                                }
                                if (isDefined(lang)) {
                                    if (!isDefined(newValues[lang])) {
                                        newValues[lang] = []
                                    }
                                    if (
                                        goodResponse === null ||
                                        goodResponse === undefined ||
                                        goodResponse === ''
                                    ) {
                                        newValues[lang].push({
                                            name: name,
                                            value: value,
                                            tag: sliderTag,
                                            media: media,
                                            order: order,
                                            min: min,
                                            max: max,
                                            default: defaut,
                                            terminology: terminology,
                                            terminologyAttribute:
                                                terminologyAttribute,
                                        })
                                    } else {
                                        newValues[lang].push({
                                            name: name,
                                            value: value,
                                            tag: sliderTag,
                                            media: media,
                                            order: order,
                                            min: min,
                                            max: max,
                                            default: defaut,
                                            terminology: terminology,
                                            terminologyAttribute:
                                                terminologyAttribute,
                                            goodResponse: goodResponse,
                                        })
                                    }
                                }
                            }
                        }

                        document[mappedFieldName].value = newValues
                        if (mappedFieldName === 'forms') {
                            if (isDefined(document.formsClinical)) {
                                document.formsClinical.value = newValues
                            }
                            potentialCounterObject = doc[mappedFieldName][0]
                        }
                    } else {
                        document[mappedFieldName].value = field
                    }
                }
            }
        }
        if (sliderType !== '' && document.formsType !== undefined) {
            document.formsType.value = sliderType
        }
        if (sliderTag !== '') {
            if (document.sliderTag !== undefined) {
                document.sliderTag.value = sliderTag
            }
            if (document.textareaTag !== undefined) {
                document.textareaTag.value = sliderTag
            }
            if (document.measureTag !== undefined) {
                document.measureTag.value = sliderTag
            }
            if (document.counterTag !== undefined) {
                document.counterTag.value = sliderTag
            }
            if (isDefined(potentialCounterObject)) {
                if (isDefined(potentialCounterObject.min)) {
                    document.min.value = potentialCounterObject.min
                }
                if (isDefined(potentialCounterObject.max)) {
                    document.max.value = potentialCounterObject.max
                }
                if (isDefined(potentialCounterObject.default)) {
                    document.default.value = potentialCounterObject.default
                }
                if (isDefined(potentialCounterObject.step)) {
                    document.step.value = potentialCounterObject.step
                }
            }
        }
        document.original = doc

        actions.setDocument(document)
    },
    setCurrentUrl: (newState) => (state) => ({
        currentUrl: newState,
    }),
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
    setDisplay: (newState) => (state) => ({
        display: newState,
    }),
    setEditId: (newState) => (state) => ({
        editId: newState,
    }),
    setEditType: (newState) => (state) => ({
        editType: newState,
    }),
    setDocument: (newState) => (state) => ({
        document: newState,
    }),
    setHeader: (newState) => (state) => ({
        header: newState,
    }),
    setInitialized: (newState) => (state) => ({
        initialized: newState,
    }),
    setModeDuplicate: (newState) => (state) => ({
        modeDuplicate: newState,
    }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout page="cards" customer={customer} currentPage={'content'}>
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Card.getCards}
                        ></Table>
                    ) : (
                        <div
                            class=""
                            key={
                                state.initialized +
                                '-' +
                                (isDefined(state.document)
                                    ? Object.keys(state.document).length
                                    : 'Ø')
                            }
                        >
                            {state.editId !== null &&
                            isDefined(state.document) ? (
                                <div
                                    class={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                    key={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    <AdminForm
                                        header={state.header}
                                        editType={state.editType}
                                        document={state.document}
                                        target={
                                            state.modeDuplicate
                                                ? Card.addCard
                                                : Card.editCard
                                        }
                                        secondtarget={Card.sendCardForm}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.cards'].pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            ) : (
                                <div>
                                    {state.editType !== null ? (
                                        <div key={'adminform-creation'}>
                                            <AdminForm
                                                header={state.header}
                                                editType={state.editType}
                                                document={state.document}
                                                target={Card.addCard}
                                                secondtarget={Card.sendCardForm}
                                                card={
                                                    state.cards.filter(
                                                        (f) =>
                                                            f.type ==
                                                            state.editType
                                                    )[0]
                                                }
                                                backUrl={addCSRFToken(
                                                    allRoutes['private.cards']
                                                        .pathname
                                                )}
                                            ></AdminForm>
                                        </div>
                                    ) : (
                                        <div>
                                            {state.cards.map((card) => (
                                                <div class="sup-content-card">
                                                    <a
                                                        href={addCSRFToken(
                                                            allRoutes[
                                                                card.addurl
                                                            ].pathname.replace(
                                                                ':id',
                                                                'null'
                                                            ) +
                                                                '?type=' +
                                                                card.type
                                                        )}
                                                    >
                                                        <div class="sup-content-card-header">
                                                            <p class="sup-content-card-header-title">
                                                                {t(card.label)}
                                                            </p>
                                                            <p class="sup-content-card-header-kpi">
                                                                kpi
                                                            </p>
                                                        </div>
                                                        <div class="sup-content-card-infos">
                                                            {card.infos.map(
                                                                (info) => (
                                                                    <div class="sup-content-card-info">
                                                                        <p class="sup-content-card-info-puce"></p>
                                                                        <p class="sup-content-card-info-content">
                                                                            {t(
                                                                                info
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                            <div class="sup-content-card-add">
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'cards')

import { h, Component } from '@app/utils'
import { isFloat, isInteger } from '@app/core'

import './index.scss'

const AdminFormIntInput = Component(
    {
        intValue: 1,
        minValue: 1,
        maxValue: null,
        floating: false,
        sendToParent: null,
        kind: '',
        idx: '',
        name: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.maxValue) {
                state.maxValue = props.maxValue
            }
            if (props.value) {
                state.intValue = props.value
            }
            if (props.kind) {
                state.kind = props.kind
            }
            if (props.idx !== undefined) {
                state.idx = props.idx
            }
            if (props.floating) {
                state.floating = props.floating
            }
            if (props.oninput) {
                state.sendToParent = props.oninput
            }
            if (props.name) {
                state.name = props.name
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.value) {
                state.intValue = props.value
            }
        },
        addOne: () => (state, actions) => {
            let value = state.intValue
            if (String(value).indexOf(',') > -1) {
                value = +String(value).replace(',', '.')
            }
            value += 1
            if (state.maxValue !== null && value > state.maxValue) {
                value = state.maxValue
            }
            if (String(value).indexOf('.') > -1) {
                value = String(value).replace('.', ',')
            }
            actions.setValue(value)
            if (state.sendToParent !== null) {
                state.sendToParent({
                    name: state.name,
                    value: value,
                    kind: state.kind,
                    idx: state.idx,
                })
            }
        },
        subOne: () => (state, actions) => {
            let value = state.intValue
            if (String(value).indexOf(',') > -1) {
                value = +String(value).replace(',', '.')
            }
            value -= 1
            if (value < state.minValue) {
                value = state.minValue
            }
            if (String(value).indexOf('.') > -1) {
                value = String(value).replace('.', ',')
            }
            actions.setValue(value)
            if (state.sendToParent !== null) {
                state.sendToParent({
                    name: state.name,
                    value: value,
                    kind: state.kind,
                    idx: state.idx,
                })
            }
        },
        updateOne: (e) => (state, actions) => {
            let inpValue = e.target.value,
                newValue = state.intValue
            if (!state.floating && isInteger(inpValue)) {
                newValue = inpValue
            } else if (state.floating) {
                if (String(inpValue).indexOf(',') > -1) {
                    inpValue = String(inpValue).replace(',', '.')
                }
                if (isFloat(inpValue)) {
                    newValue = inpValue
                }
                if (String(newValue).indexOf('.') > -1) {
                    newValue = String(newValue).replace('.', ',')
                }
            }
            if (newValue < state.minValue) {
                newValue = state.minValue
            } else if (state.maxValue !== null && newValue > state.maxValue) {
                newValue = state.maxValue
            }
            actions.setValue(newValue)
            if (state.sendToParent !== null) {
                state.sendToParent({
                    name: state.name,
                    value: newValue,
                    kind: state.kind,
                    idx: state.idx,
                })
            }
        },
        setValue: (newState) => (state) => ({
            intValue: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <div class="adminformintinput-minus" onclick={actions.subOne}>
                -
            </div>
            <input
                type="text"
                value={state.intValue}
                oninput={actions.updateOne}
            />
            <div class="adminformintinput-plus" onclick={actions.addOne}>
                +
            </div>
        </div>
    ),
    'adminformintinput'
)

export { AdminFormIntInput }

import { t } from '@app/utils'
import * as constants from '@app/constants'

const alert_CHALLENGE = {
    header: ['Configurer l‘alerte', 'Défi'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'alert',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de l‘alerte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de l‘alerte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de l‘alerte',
                i18nMessage: t('Veuillez renseigner le nom de l‘alerte'),
            },
        },
        pushhour: {
            name: 'pushhour',
            substitute: 'pushHour',
            type: 'int',
            label: 'Heure du push',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_ALERT_PUSH_HOURS,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner l‘heure du push',
                i18nMessage: t('Veuillez sélectionner l‘heure du push'),
            },
        },
        pushdate: {
            name: 'pushdate',
            substitute: 'pushDate',
            type: 'int',
            label: 'Date du push',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_ALERT_PUSH_DATES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner le jour du push',
                i18nMessage: t('Veuillez sélectionner le jour du push'),
            },
        },
        challenge: {
            name: 'challenge',
            substitute: 'challengeId',
            type: 'autocomplete/challenge',
            label: 'Défi rattaché',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Aucun',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigné un défi lié',
                i18nMessage: t('Veuillez renseigné un défi lié'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘alerte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Déscription',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici la description de votre alerte (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de votre alerte',
                i18nMessage: t(
                    'Veuillez renseigner la description de votre alerte'
                ),
            },
        },
    },
}

export { alert_CHALLENGE }

import { h, Component } from '@app/utils'
import './index.scss'

const ProgressBar = Component(
    {
        percent: 0,
        displayed: 'none',
        activeAjax: 0,
    },
    {
        onComponentCreate: (props) => (state, actions) => {},
        setActiveAjax: (newState) => (state) => ({
            activeAjax: newState,
        }),
    },
    (state, actions) => (props, children) => <div id="btzProgressBar"></div>,
    'progressbar'
)

export { ProgressBar }

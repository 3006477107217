import { h } from '@app/utils'
import {
    allRoutes,
    getQueryVariable,
    addCSRFToken,
    isDefined,
    deepCopy,
} from '@app/core'
import { Card, Programs, ProgramsEtp, ClientErrors } from '@app/api'

export default {
    initAction: function () {
        const action = {
            onComponentCreate: (props) => (state, actions) => {
                let params = props === null ? null : props.match.params
                if (
                    params === null ||
                    params === undefined ||
                    (params.id !== undefined && params.id === 'null')
                ) {
                    actions.onComponentUpdate(props)
                }
            },
            onComponentUpdate: (props) => (state, actions) => {
                let params = props === null ? null : props.match.params
                console.log('params : ', params)
                if (params === null || params === undefined) {
                    actions.setDisplay('table')
                    actions.setInitialized(true)
                } else {
                    let id = params.id
                    let sid = params.sid
                    let ssid = params.ssid
                    let modeDuplicate =
                        getQueryVariable('mode', false) === 'duplicate'
                    actions.setModeDuplicate(modeDuplicate)
                    let editType = getQueryVariable('type', false)
                    actions.setEditType(editType)
                    if (editType !== null && editType !== false) {
                        let refDocument = state.documents.filter(
                            (f) => +f.key === +editType
                        )[0]
                        if (isDefined(refDocument)) {
                            actions.setDocument(refDocument.document.form)
                            actions.setHeader(refDocument.document.header)
                        }
                    }
                    if (sid && sid !== 'null') {
                        actions.setRefSId(sid)
                    }
                    if (ssid && ssid !== 'null') {
                        actions.setRefSSId(ssid)
                    }
                    if (id) {
                        if (id !== 'null') {
                            actions.setRefId(id)
                            actions.setEditId(id)
                            actions.setDisplay('form')
                            if (props.particularDisplay) {
                                actions.setDisplay(props.particularDisplay)
                            }
                            actions.setInitialized(true)
                            let found = false
                            if (state.thirdDocument !== undefined) {
                                if (
                                    props.location.pathname.indexOf(
                                        state.thirdDocument.urlKey
                                    ) > -1
                                ) {
                                    actions.setRedirectObject({
                                        from: state.kind,
                                        to: 'session',
                                        refId: id,
                                        refSId: sid,
                                    })
                                    if (
                                        ssid &&
                                        ssid !== 'null' &&
                                        sid &&
                                        sid !== 'null'
                                    ) {
                                        Programs.getProgramSessionStepById(
                                            id,
                                            sid,
                                            ssid
                                        ).then((res) => {
                                            if (
                                                editType === false ||
                                                editType === null
                                            ) {
                                                actions.setDocument(
                                                    state.thirdDocument.document
                                                        .form
                                                )
                                                actions.setHeader(
                                                    state.thirdDocument.document
                                                        .header
                                                )
                                            } else {
                                                let refDocument =
                                                    state.documents.filter(
                                                        (f) =>
                                                            +f.key ===
                                                            +res.data.type
                                                    )[0]
                                                if (isDefined(refDocument)) {
                                                    actions.setDocument(
                                                        refDocument.document
                                                            .form
                                                    )
                                                    actions.setHeader(
                                                        refDocument.document
                                                            .header
                                                    )
                                                }
                                            }
                                            if (
                                                isDefined(res.data) &&
                                                !res.data.isError
                                            ) {
                                                if (modeDuplicate) {
                                                    res.data.id = undefined
                                                    if (res.data.name) {
                                                        res.data.name =
                                                            res.data.name +
                                                            '-copy'
                                                    }
                                                    res.data.status = 0
                                                }
                                                actions.parseAndSetDocument(
                                                    res.data
                                                )
                                                actions.setOriginalDocument(
                                                    res.data
                                                )
                                            }
                                        })
                                    } else if (
                                        editType === false ||
                                        editType === null
                                    ) {
                                        actions.setHeader(
                                            state.thirdDocument.document.header
                                        )
                                        actions.setDocument(
                                            state.thirdDocument.document.form
                                        )
                                        actions.setOriginalDocument(
                                            state.secondDocument.document
                                        )
                                    }
                                    actions.setPreviousPage([
                                        {
                                            label: 'au programe',
                                            url: addCSRFToken(
                                                allRoutes[
                                                    'private.programs.edit'
                                                ].pathname
                                            ),
                                        },
                                        {
                                            label: 'à la session',
                                            url: addCSRFToken(
                                                allRoutes[
                                                    'private.programs.sessions.edit'
                                                ].pathname
                                            ),
                                        },
                                        {
                                            label: 'au choix d‘étape',
                                            url: addCSRFToken(
                                                allRoutes[
                                                    'private.programs.sessions.steps'
                                                ].pathname
                                            ),
                                        },
                                    ])
                                    found = true
                                }
                            }
                            if (!found && state.secondDocument !== undefined) {
                                if (
                                    props.location.pathname.indexOf(
                                        state.secondDocument.urlKey
                                    ) > -1
                                ) {
                                    let sid = params.sid
                                    actions.setHeader(
                                        state.secondDocument.document.header
                                    )
                                    actions.setRedirectObject({
                                        from: state.kind,
                                        to: 'program',
                                        refId: id,
                                    })
                                    if (sid && sid !== 'null') {
                                        let then = (res) => {
                                            actions.setDocument(
                                                state.secondDocument.document
                                                    .form
                                            )
                                            if (
                                                isDefined(res.data) &&
                                                !res.data.isError
                                            ) {
                                                if (modeDuplicate) {
                                                    res.data.id = undefined
                                                    if (res.data.name) {
                                                        res.data.name =
                                                            res.data.name +
                                                            '-copy'
                                                    }
                                                    res.data.status = 0
                                                }
                                                actions.parseAndSetDocument(
                                                    res.data
                                                )
                                                actions.setOriginalDocument(
                                                    res.data
                                                )
                                            }
                                        }
                                        //
                                    } else {
                                        actions.setDocument(
                                            state.secondDocument.document.form
                                        )
                                        actions.setOriginalDocument(
                                            state.secondDocument.document
                                        )
                                    }
                                    actions.setPreviousPage([
                                        { label: 'au programe', url: null },
                                    ])
                                    found = true
                                }
                            }
                            //
                            if (!found) {
                                if (
                                    isDefined(state.kind) &&
                                    state.kind !== ''
                                ) {
                                    if (state.kind === 'clientErrors') {
                                        ClientErrors.getClientError(id).then(
                                            (res) => {
                                                actions.setHeader(
                                                    state.document.header ||
                                                        state.header
                                                )
                                                actions.setDocument(
                                                    deepCopy(state.form)
                                                )
                                                actions.parseAndSetDocument(
                                                    res.data
                                                )
                                                actions.setOriginalDocument(
                                                    res.data
                                                )
                                            }
                                        )
                                    } else if (state.kind !== 'scenario') {
                                        let cardAction = Card.getCardByKind(
                                            state.kind,
                                            id
                                        )
                                        cardAction.then((res) => {
                                            actions.setHeader(
                                                state.document.header ||
                                                    state.header
                                            )
                                            actions.setDocument(
                                                deepCopy(state.form)
                                            )
                                            if (
                                                isDefined(res.data) &&
                                                !res.data.isError
                                            ) {
                                                if (modeDuplicate) {
                                                    res.data.id = undefined
                                                    if (res.data.name) {
                                                        res.data.name =
                                                            res.data.name +
                                                            '-copy'
                                                    }
                                                    res.data.status = 0
                                                }
                                                console.log(
                                                    'modeDuplicate : ',
                                                    modeDuplicate,
                                                    res.data
                                                )
                                                actions.parseAndSetDocument(
                                                    res.data
                                                )
                                                actions.setOriginalDocument(
                                                    res.data
                                                )
                                            }
                                        })
                                    } else {
                                        // get scenario using customer id & scenario id
                                    }
                                }
                            }
                        } else {
                            actions.setDocument(deepCopy(state.form))
                            actions.setHeader(
                                state.document.header || state.header
                            )
                            actions.setOriginalDocument(deepCopy(state.form))
                            actions.setRefId(null)
                            actions.setEditId(null)
                            actions.setDisplay('form')
                            actions.setInitialized(true)
                        }
                    } else {
                        actions.setDisplay('table')
                        actions.setInitialized(true)
                    }
                }
            },
            parseAndSetDocument: (doc) => (state, actions) => {
                if (!isDefined(doc)) {
                    return false
                }
                // let document = JSON.parse(JSON.stringify(state.document));
                let document = state.document
                let sliderType = '',
                    sliderTag = '',
                    trgField = ''
                for (var field in document) {
                    var fieldName = field,
                        docName = field
                    if (
                        isDefined(document[fieldName].name) &&
                        isDefined(doc[document[fieldName].name])
                    ) {
                        fieldName = document[fieldName].name
                    } else if (
                        isDefined(document[fieldName].substitute) &&
                        isDefined(doc[document[fieldName].substitute])
                    ) {
                        fieldName = document[fieldName].substitute
                    }
                    //
                    field = doc[fieldName]
                    if (isDefined(field)) {
                        if (field[0] !== undefined) {
                            if (field[0].datas !== undefined) {
                                trgField = 'datas' // for evaluation
                            } else if (field[0].content !== undefined) {
                                trgField = 'content' // [steps], used by recipe
                            }
                        }

                        if (
                            document[docName] &&
                            document[docName] !== null &&
                            document[docName] !== undefined
                        ) {
                            if (
                                document[docName].type === 'translation' &&
                                field[0] !== undefined &&
                                field[0][trgField] !== undefined
                            ) {
                                let lang = '',
                                    name = '',
                                    value = '',
                                    order = null
                                let newValues = {}
                                for (var entry in field) {
                                    sliderType = field[entry].type || ''
                                    sliderTag = field[entry].tag || ''
                                    order = isDefined(field[entry].order)
                                        ? field[entry].order
                                        : null

                                    for (var dat in field[entry][trgField]) {
                                        if (
                                            field[entry][trgField][dat] === null
                                        ) {
                                            name = trgField
                                            value = field[entry][trgField][dat]
                                            lang = dat
                                        } else {
                                            lang =
                                                field[entry][trgField][dat]
                                                    .lang || dat
                                            name =
                                                field[entry][trgField][dat]
                                                    .name || trgField
                                            value =
                                                field[entry][trgField][dat]
                                                    .value ||
                                                field[entry][trgField][dat]
                                        }
                                        if (name === undefined) {
                                            name = trgField
                                            value = field[entry][trgField][dat]
                                            lang = dat
                                        }
                                        if (isDefined(field[entry]['title'])) {
                                            value = {}
                                            value[trgField] =
                                                field[entry][trgField][dat]
                                            value['title'] =
                                                field[entry]['title'][dat]
                                        }
                                        if (
                                            isDefined(lang) &&
                                            !isDefined(newValues[lang])
                                        ) {
                                            newValues[lang] = []
                                        }
                                        if (
                                            name !== '' &&
                                            isDefined(name) &&
                                            isDefined(lang)
                                        ) {
                                            if (!isDefined(value)) {
                                                value = ''
                                            }
                                            if (!isDefined(sliderTag)) {
                                                sliderTag = ''
                                            }
                                            newValues[lang].push({
                                                name: name,
                                                value: value,
                                                tag: sliderTag,
                                                order: order,
                                            })
                                        }
                                    }
                                }
                                document[docName].value = newValues
                            } else {
                                if (isDefined(document[docName].duplicateby)) {
                                    for (var duplicate in document[docName]
                                        .duplicateby) {
                                        document[
                                            document[docName].duplicateby[
                                                duplicate
                                            ]
                                        ].value = field
                                    }
                                }
                                if (docName === 'ecs') {
                                    // used to remove unwanted [default] value already sended to api
                                    //  when api returned that, admin try to send it and generate an error
                                    //  need to parse ECS and remove empty value field
                                    var ecsField = null
                                    for (var lang in field) {
                                        for (var ecs in field[lang]) {
                                            if (field[lang][ecs].value !== '') {
                                                if (!isDefined(ecsField)) {
                                                    ecsField = {}
                                                }
                                                if (
                                                    !isDefined(ecsField[lang])
                                                ) {
                                                    ecsField[lang] = []
                                                }
                                                ecsField[lang][ecs] =
                                                    field[lang][ecs]
                                            }
                                        }
                                    }
                                    field = ecsField || []
                                }
                                if (
                                    docName.indexOf('media') > -1 &&
                                    !isDefined(field.url)
                                ) {
                                    field = {
                                        url: field,
                                    }
                                }
                                document[docName].value = field
                            }
                        }
                    }
                }
                if (sliderType !== '') {
                    document.formsType.value = sliderType
                }
                document.original = doc
                actions.setDocument(document)
            },
            setCurrentUrl: (newState) => (state) => ({
                currentUrl: newState,
            }),
            setState:
                ({ ns, key, value }) =>
                (state) => ({
                    [ns]: { ...state[ns], ...{ [key]: value } },
                }),
            setDisplay: (newState) => (state) => ({
                display: newState,
            }),
            setEditId: (newState) => (state) => ({
                editId: newState,
            }),
            setForm: (newState) => (state) => ({
                form: newState,
            }),
            setDocument: (newState) => (state) => ({
                document: newState,
            }),
            setHeader: (newState) => (state) => ({
                header: newState,
            }),
            setInitialized: (newState) => (state) => ({
                initialized: newState,
            }),
            setOriginalDocument: (newState) => (state) => ({
                originaldocument: newState,
            }),
            setRefId: (newState) => (state) => ({
                refId: newState,
            }),
            setRefSId: (newState) => (state) => ({
                refSId: newState,
            }),
            setRefSSId: (newState) => (state) => ({
                refSSId: newState,
            }),
            setPreviousPage: (newState) => (state) => ({
                previousPage: newState,
            }),
            setEditType: (newState) => (state) => ({
                editType: newState,
            }),
            setModeDuplicate: (newState) => (state) => ({
                modeDuplicate: newState,
            }),
            setRedirectObject: (newState) => (state) => ({
                redirectObject: newState,
            }),
        }
        return action
    },
}

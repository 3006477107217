import { t, h } from '@app/utils'
import { Button, Form } from '@app/elements'

import icoTeam from '@app/img/default/def-team.png'
import icoTeamx2 from '@app/img/default/def-team@2x.png'

export const ModalViewTeam = (props, actions) => (
    <div class={'supModalContainer ' + props.view} style={{ width: '650px' }}>
        {props.view == 'team-compose' && (
            <div class="">
                <p class="sup-title btzXl">{props.team.label}</p>
                <div class="afteams-entry">
                    {props.team.media !== null ? (
                        <img
                            class="afteams-entry-img"
                            src={props.team.media.url}
                        />
                    ) : (
                        <img
                            class="afteams-entry-img"
                            src={icoTeam}
                            srcset={`${icoTeamx2} 2x`}
                        />
                    )}
                </div>

                <div class="" style={{ 'margin-top': '35px' }}>
                    {props.team.members.map((m) => (
                        <div class="">
                            <p
                                class="afteams-entry"
                                style={{
                                    'width': '300px',
                                    'text-align': 'left',
                                }}
                            >
                                {m.email}
                            </p>
                            <p class="afteams-entry">
                                {m.status === 1
                                    ? t('Invitation acceptée !')
                                    : t('Invitation envoyée')}
                            </p>
                            <div class="afteams-entry">
                                <Form.Group classes="btzForm-btzFooter">
                                    <Button
                                        primary
                                        flat
                                        onclick={() => {
                                            props.setSuggestEmail(m.email)
                                            props.setCurrentTeamMember(m)
                                            props.updateStateView(
                                                'team-suggest-delete'
                                            )
                                        }}
                                    >
                                        {t('Supprimer')}
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    class="adminformteams-suggest"
                    style={{ 'margin-top': '50px' }}
                >
                    <div class="">
                        <Button
                            primary
                            flat
                            onclick={() =>
                                props.updateStateView('team-suggest')
                            }
                        >
                            {t('Suggérer un membre')}
                        </Button>
                    </div>
                </div>
            </div>
        )}

        {props.view == 'team-suggest' && (
            <div class="">
                {props.emailError && props.emailError !== '' && (
                    <p class="sup-error-above-title">{props.emailError}</p>
                )}

                <p class="sup-title">{t('Invitation à rejoindre l‘équipe')}</p>
                <input
                    type="text"
                    placeholder={t('Renseignez ici l‘email')}
                    name="email"
                    oninput={props.waitForLastInput}
                />

                <Form.Group classes="btzForm-btzFooter">
                    <Button
                        primary
                        flat
                        cancel
                        onclick={() => props.updateStateView('team-compose')}
                    >
                        {t('Annuler')}
                    </Button>
                </Form.Group>
                <Form.Group classes="btzForm-btzFooter">
                    <Button primary flat active onclick={props.suggestThisOne}>
                        {t('Envoyer')}
                    </Button>
                </Form.Group>
            </div>
        )}

        {props.view == 'team-suggest-delete' && (
            <div class="">
                <p class="sup-title" style={{ 'margin-bottom': '50px' }}>
                    {t(
                        'Êtes-vous certain de vouloir supprimer ce membre de l‘équipe :'
                    ) +
                        ' ' +
                        props.email +
                        ' ?'}
                </p>

                <Form.Group classes="btzForm-btzFooter">
                    <Button
                        primary
                        flat
                        cancel
                        onclick={() => props.updateStateView('team-compose')}
                    >
                        {t('Annuler')}
                    </Button>
                </Form.Group>
                <Form.Group classes="btzForm-btzFooter">
                    <Button
                        primary
                        flat
                        active
                        onclick={() => props.deleteThisOne()}
                    >
                        {t('Confirmer')}
                    </Button>
                </Form.Group>
            </div>
        )}
    </div>
)

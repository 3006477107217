import { t, h, Component } from '@app/utils'
import { Pdfs, Card } from '@app/api'
import {
    isDefined,
    addCSRFToken,
    allRoutes,
    getYMDfromDateObj,
} from '@app/core'
import {
    AdminFormDownloadDropDown,
    AdminFormMedia,
    AdminFormRadio,
    Form,
    Button,
    MessagePopup,
    Link,
    Convert,
} from '@app/elements'
import './index.scss'

import * as constants from '@app/constants'

const state = {
    //
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('PROPS : ', props)
    },
}

const view = (state, actions) => (props, children) => (
    <div class="modalWrapper">
        {props.mode === 'preview' && (
            <dummy>
                <p class="modalTitle">{t('Aperçu')}</p>
                <p class="modalSubTitle">
                    {t('Pour les utilisateurs') +
                        ' ' +
                        (props.previewedDocument.userType === 'doctor'
                            ? t('SOIGNANT')
                            : t('PATIENT')) +
                        ' :'}
                </p>
                <p class="">
                    {'- ' + t('Voici le message affiché aux utilisateurs')}
                </p>
                <div class="btzAdminForm">
                    <Form
                        classes={'col-xs-12 sup-custom-form btzForm'}
                        onsubmit={(event) => event.preventDefault()}
                        autocomplete="off"
                    >
                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{ 'text-align': 'right' }}
                        >
                            <p class="">
                                {'« ' + props.previewedDocument.title}
                            </p>
                            <p
                                class=""
                                innerHTML={
                                    <Convert
                                        separator={''}
                                        is_xhtml={true}
                                        type="nl2br"
                                    >
                                        {props.previewedDocument.description +
                                            ' »'}
                                    </Convert>
                                }
                            ></p>
                            <div class="modalFooter">
                                <Button
                                    primary
                                    flat
                                    cancel
                                    onclick={() => {
                                        props.closeModal()
                                    }}
                                >
                                    {t('fermer')}
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </dummy>
        )}
        {props.mode === 'confirm' && (
            <dummy>
                <p class="modalTitle">{t('Confirmation requise')}</p>
                <p class="modalSubTitle">
                    {t('Pour les utilisateurs') +
                        ' ' +
                        props.modalMedicalKind.toUpperCase() +
                        ' :'}
                </p>
                {isDefined(props.lastAgreement.terms) && (
                    <dummy>
                        {+props.activeCGU === 1 &&
                        props.previewedDocument.cgu.version !== '' ? (
                            <p class="modalSubTitle">
                                {'- ' +
                                    t('Vous allez écraser la version') +
                                    ' ' +
                                    props.lastAgreement.terms.version +
                                    ' ' +
                                    t('des CGU par la version') +
                                    ' ' +
                                    props.previewedDocument.cgu.version}
                            </p>
                        ) : (
                            <p class="modalSubTitle">
                                {'- ' +
                                    t(
                                        "Les Conditions Générales d'Utilisation restent inchangées"
                                    ) +
                                    ' (' +
                                    props.lastAgreement.terms.version +
                                    ')'}
                            </p>
                        )}
                    </dummy>
                )}
                {isDefined(props.lastAgreement.privacy) && (
                    <dummy>
                        {+props.activePDC === 1 &&
                        props.previewedDocument.pdc.version !== '' ? (
                            <p class="modalSubTitle">
                                {'- ' +
                                    t('Vous allez écraser la version') +
                                    ' ' +
                                    props.lastAgreement.privacy.version +
                                    ' ' +
                                    t('des PDC par la version') +
                                    ' ' +
                                    props.previewedDocument.pdc.version}
                            </p>
                        ) : (
                            <p class="modalSubTitle">
                                {'- ' +
                                    t(
                                        'La Politique de Confidentialité reste inchangé'
                                    ) +
                                    ' (' +
                                    props.lastAgreement.privacy.version +
                                    ')'}
                            </p>
                        )}
                    </dummy>
                )}
                <p class="modalSubTitle">
                    {'- ' + t('Voici le message affiché aux utilisateurs')}
                </p>
                <div class="btzAdminForm">
                    <Form
                        classes={'col-xs-12 sup-custom-form btzForm'}
                        onsubmit={(event) => event.preventDefault()}
                        autocomplete="off"
                    >
                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{ 'text-align': 'right' }}
                        >
                            <p class="">
                                {'« ' + props.previewedDocument.title}
                            </p>
                            <p
                                class=""
                                innerHTML={
                                    <Convert
                                        separator={''}
                                        is_xhtml={true}
                                        type="nl2br"
                                    >
                                        {props.previewedDocument.description +
                                            ' »'}
                                    </Convert>
                                }
                            ></p>
                            <div class="modalFooter">
                                <Button
                                    primary
                                    flat
                                    cancel
                                    onclick={() => {
                                        props.closeModal()
                                    }}
                                >
                                    {t('annuler')}
                                </Button>
                                <Button
                                    primary
                                    flat
                                    active
                                    onclick={() => {
                                        props.sendFields()
                                    }}
                                >
                                    {t('mettre à jour')}
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </dummy>
        )}
    </div>
)

export default Component(state, actions, view, 'modalconfirm')

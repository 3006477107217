let loadingQueries = 0
let loadingDisplayed = false

function resetQuery() {
    loadingQueries = 0
    hideLoader()
}

function addQuery() {
    loadingQueries++
    if (!loadingDisplayed) {
        loadingDisplayed = true
        displayLoader()
    }
}

function removeQuery(response) {
    loadingQueries--
    if (+loadingQueries <= 0) {
        loadingQueries = 0
        loadingDisplayed = false
        hideLoader()
    }
    return response
}

function displayLoader() {
    if (
        document.getElementById('btzProgressBar') !== undefined &&
        document.getElementById('btzProgressBar') !== null
    ) {
        document.getElementById('btzProgressBar').style.display = 'block'
    }
}

function hideLoader() {
    if (
        document.getElementById('btzProgressBar') !== undefined &&
        document.getElementById('btzProgressBar') !== null
    ) {
        document.getElementById('btzProgressBar').style.display = 'none'
    }
}

export const Loader = {
    resetQuery,
    addQuery,
    removeQuery,
}

import { t, h, Component } from '@app/utils'
import { fromNow, allRoutes, addCSRFToken, isDefined } from '@app/core'
import './index.scss'
import * as constants from '@app/constants'

import icoDefaultUser from '@app/img/default/default.png'
import icoDefaultUserx2 from '@app/img/default/default@2x.png'

const AIMessage = Component(
    {
        name: '',
        media: null,
        action: '',
        actionLabel: '',
        elementName: '',
        elementType: '',
        elementUrl: '#',
        date: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.user) {
                if (props.user.nick) {
                    state.name = props.user.nick
                } else if (props.user.fname) {
                    state.name = props.user.fname
                }
            }
            if (props.user && props.user.media && props.user.media.url) {
                state.media = props.user.media.url
            }
            if (props && props.action !== null) {
                state.action = props.action
                state.actionLabel =
                    props.action === 1
                        ? t('édité')
                        : props.action === 0
                        ? t('créé')
                        : props.action === 3
                        ? t('résilié')
                        : t('supprimé')
            }
            if (props && props.elementName) {
                state.elementName = props.elementName.toUpperCase()
            }
            if (props && props.date && props.date.date) {
                state.date = props.date.date
            }
            if (props && props.elementType) {
                let adminElement =
                    constants.custo.adminElements[props.elementType]
                if (isDefined(adminElement)) {
                    state.elementType = adminElement.label
                    if (
                        adminElement.url !== '' &&
                        allRoutes[adminElement.url] !== undefined
                    ) {
                        state.elementUrl = addCSRFToken(
                            allRoutes[adminElement.url].pathname +
                                '/' +
                                props.elementId
                        )
                    }
                }
            }
        },
    },
    (state, actions) => (props, children) => (
        <div>
            <div class="aimessage-media">
                {state.media !== null ? (
                    <img src={state.media} />
                ) : (
                    <img
                        src={icoDefaultUser}
                        srcset={`${icoDefaultUserx2} 2x`}
                        alt=""
                        class=""
                    />
                )}
            </div>
            <div class="aimessage-content">
                <div class="aimessage-message">
                    <p class="">
                        {(state.name !== ''
                            ? state.name
                            : t('Un utilisateur')) +
                            ' ' +
                            t('a') +
                            ' '}
                        <font class={'action-' + state.action}>
                            {state.actionLabel}
                        </font>
                        {' ' + state.elementType + ' '}
                        <font class={'element-name'}>
                            <a href={state.elementUrl}>{state.elementName}</a>
                        </font>
                    </p>
                </div>
                <div class="aimessage-fromnow">
                    <p class="">
                        {t('il y a') +
                            ' ' +
                            (state.date !== null ? fromNow(state.date) : '/')}
                    </p>
                </div>
            </div>
        </div>
    ),
    'aimessage'
)

export default AIMessage

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const objective_CARD = {
    filter: ['mainThemes'],
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'date', label: 'date de création' },
        { key: 'title', label: 'titre' },
        { key: 'mainThemes', label: 'thèmes principaux' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/objectives/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/objectives/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editObjective,
        },
        // {'key': 'frequency', 'label': 'Changer la fréquence', 'kind': 'list-api', 'list': Object.keys(constants.custo.FREQUENCY).map(i => constants.custo.FREQUENCY[i]), 'action': Card.editObjetive},
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteObjective,
        },
    ],
    tableAllOptions: {
        label: 'OBJECTIFS SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editObjective,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editObjective,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteObjective,
            },
        ],
    },
    header: ['Configurer l‘objectif'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'objective',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        mainThemes: {
            name: 'mainThemes',
            type: 'theme',
            label: 'Thèmes principaux',
            field: 'dropdown-to-div',
            searchbox: false,
            model: { key: 'key', value: 'value' },
            list: constants.custo.CARDS_TYPES,
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneSelected',
                message: 'Veuillez sélectionner au moins un thème principal',
                i18nMessage: t(
                    'Veuillez sélectionner au moins un thème principal'
                ),
            },
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        secondaryThemes: {
            name: 'secondaryThemes',
            type: 'theme',
            label: 'Thèmes secondaire',
            field: 'dropdown-to-div',
            searchbox: false,
            model: { key: 'key', value: 'value' },
            list: constants.custo.CARDS_TYPES,
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘objectif',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
    },
}

export { objective_CARD }

import { t, h } from '@app/utils'
import { Card, Form, Button, Tile, Enter, MessagePopup } from '@app/elements'
import * as constants from '@app/constants'

import bgIndexx2 from '@app/img/bg/btz-bckg@2x.png'

import { UserMenu } from '@app/layouts/usermenu'

import './../index.scss'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) =>
            (
                <div>
                    {state.messageActive && (
                        <MessagePopup
                            kind={'success'}
                            active={'active'}
                            duration={3000}
                            durationCallback={() =>
                                actions.displaySuccessMessage(false)
                            }
                            message={t('Email de réinitialisation envoyé !')}
                        ></MessagePopup>
                    )}

                    {state.blockedStep === 0 && (
                        <div class="login-central">
                            <UserMenu display={'login'}></UserMenu>
                            <Card
                                classes={'btzPageCard btzBackgroundCover'}
                                kind={'datas'}
                                style={{
                                    'background-image':
                                        'url(' + bgIndexx2 + ')',
                                }}
                            >
                                <Card.Header
                                    classes={'btzCard-btzHeader-btzPage'}
                                >
                                    <Enter
                                        time={constants.custo.ANIM_DURATION}
                                        easing={constants.custo.ANIM_EASING}
                                        css={constants.custo.ANIM_CSS}
                                    >
                                        <Tile>
                                            <Tile.Content classes="btzLeftHrArrowed col-xs-12">
                                                <p class="btzTitle btzXl">
                                                    {t('Compte bloqué')}
                                                </p>
                                                <p
                                                    class="btzTexte"
                                                    style={{
                                                        'text-align': 'left',
                                                        'padding-bottom':
                                                            '20px',
                                                    }}
                                                >
                                                    {t(
                                                        'Vous avez fait 5 erreurs successives dans la saisie du mot de passe lors de votre connexion. Votre compte est maintenant bloqué.'
                                                    )}
                                                </p>
                                                <p
                                                    class="btzTexte"
                                                    style={{
                                                        'margin': '0px',
                                                        'text-align': 'left',
                                                        'padding': '0',
                                                    }}
                                                >
                                                    {t(
                                                        'Veuillez réinitialiser votre mot de passe sur l’email que nous avons envoyé à votre adresse mail pour débloquer votre compte.'
                                                    )}
                                                </p>
                                                <p
                                                    class="btzTexte"
                                                    style={{
                                                        'text-align': 'left',
                                                        'margin': '0',
                                                        'padding': '20px 0',
                                                    }}
                                                >
                                                    {t(
                                                        'Vous n’avez pas reçu l’email ?'
                                                    )}
                                                </p>
                                                <div
                                                    style={{
                                                        'text-align': 'left',
                                                        'margin': '0',
                                                        'font-size': '14px',
                                                        'color': 'red',
                                                        'background-color':
                                                            'white',
                                                        'border-radius': '4px',
                                                        'padding': '15px',
                                                        'line-height': '1.2',
                                                    }}
                                                >
                                                    <p
                                                        class="btzTexte"
                                                        style={{
                                                            'font-size': '14px',
                                                            'color': 'red',
                                                            'line-height':
                                                                '1.2',
                                                            'margin':
                                                                '0px 0px 25px',
                                                            'padding': '0',
                                                        }}
                                                    >
                                                        {t(
                                                            'Il n‘existe actuellement aucun moyen de récupérer votre mot de passe après l‘avoir bloqué. Veuillez contacter le service technique de Betterise.'
                                                        )}
                                                    </p>
                                                    <a
                                                        href="mailto:alfred@betterise.me"
                                                        style={{
                                                            'font-size': '14px',
                                                            'background-color':
                                                                'red',
                                                            'color': 'white',
                                                            'border-radius':
                                                                '4px',
                                                            'line-height':
                                                                '1.2',
                                                            'margin': '0',
                                                            'padding':
                                                                '5px 10px',
                                                        }}
                                                    >
                                                        {'alfred@betterise.me'}
                                                    </a>
                                                </div>
                                            </Tile.Content>
                                        </Tile>
                                    </Enter>
                                </Card.Header>
                            </Card>
                        </div>
                    )}

                    {state.blockedStep === 1 && (
                        <div style={{ position: 'relative' }}>
                            <Tile>
                                <Tile.Content classes="btzLeftHrArrowed col-xs-12 col-8">
                                    <p class="btzTitle btzXl">
                                        {t('Compte bloqué')}
                                    </p>
                                    <p
                                        class="btzTexte"
                                        style={{
                                            'margin-bottom': '35px',
                                            'text-align': 'left',
                                        }}
                                    >
                                        {t(
                                            'Pour débloquer votre compte, veuillez réinitialiser votre mot de passe sur l’email que nous avons envoyé à votre adresse mail lors du blocage de votre compte.'
                                        )}
                                    </p>
                                    <p
                                        class="btzTexte"
                                        style={{ 'text-align': 'left' }}
                                    >
                                        {t('Vous n’avez pas reçu l’email ?')}
                                    </p>

                                    {state.emailInputDisplayed && (
                                        <Form
                                            onsubmit={(event) =>
                                                event.preventDefault()
                                            }
                                        >
                                            <Form.Group>
                                                <Form.Input
                                                    classes={'btzFullWidth'}
                                                    placeholder={t(
                                                        'adresse@mail.com'
                                                    )}
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    dynResize={true}
                                                    oninput={
                                                        actions.waitForLastInput
                                                    }
                                                    error={
                                                        state.isTouched.email &&
                                                        !state.isValid.email
                                                    }
                                                    classes={
                                                        state.isTouched.email
                                                            ? state.isValid
                                                                  .email
                                                                ? 'is-success'
                                                                : 'is-error'
                                                            : ''
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </Form>
                                    )}
                                    <Button
                                        primary
                                        flat
                                        onclick={actions.sendEmail}
                                    >
                                        {t('Renvoyer l‘email')}
                                    </Button>
                                </Tile.Content>
                            </Tile>
                        </div>
                    )}
                </div>
            )
        return view
    },
}

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Corpo } from '@app/api'
import { addCSRFToken } from '@app/core'

const corposurvey_CARD = {
    filter: ['status'],
    tableHead: [
        { key: 'name', label: 'ID' },
        { key: 'countQuestion', label: " Nombre d'items" },
        { key: 'countResponse', label: 'Nombre de réponses' },
        { key: 'corpoLinkSurvey', label: 'Liens' }, //todo
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/surveys/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/surveys/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Corpo.editSurvey,
        },
        {
            key: 'statistics',
            label: 'Voir statistiques',
            kind: 'web',
            action: addCSRFToken('/content/surveys/:id/statistics'),
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Corpo.deleteSurvey,
        },
    ],
    tableAllOptions: {
        label: 'SONDAGES SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Corpo.editSurvey,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Corpo.editSurvey,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Corpo.deleteSurvey,
            },
        ],
    },
    header: ['Configurer le sondage'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'survey',
        },
        // 'type': {
        //     'field': 'hidden',
        //     'value': '57',
        // },
        // 'theme': {
        //     'field': 'hidden',
        //     'value': '99',
        // },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activation',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            display: {
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom du sondage',
            field: 'text',
            mandatory: true,
            placeholder: "Renseignez ici l'id du sondage",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom du sondage',
                i18nMessage: t('Veuillez renseigner le nom du sondage'),
            },
        },
        // 'flags': {
        //     'field': 'hidden',
        //     'value': Object.keys(constants.custo.COUNTRIES).map(i => constants.custo.COUNTRIES[i]),
        // },
        // 'langs': {
        //     'field': 'hidden',
        //     'value': {fr: true},
        // },
        questions: {
            // for corpo evaluations
            name: 'eval',
            type: 'arrayOfEval',
            label: '',
            field: 'arrayOfEval',
            arrayOfField: {
                question: {
                    name: 'question',
                    type: 'string',
                    label: 'Question',
                    field: 'text',
                    mandatory: true,
                    placeholder: 'Renseignez ici le titre de votre question',
                    value: '',
                    validation: {
                        mandatory: true,
                        isValid: false,
                        type: 'not-empty',
                        message:
                            'Veuillez renseigner le titre de votre question',
                        i18nMessage: t(
                            'Veuillez renseigner le titre de votre question'
                        ),
                    },
                    wrappedIn: 'questions',
                },
                type: {
                    name: 'type',
                    type: 'dropdown',
                    label: 'Type de question',
                    field: 'dropdown',
                    list: constants.custo.EVAL_TYPES,
                    value: '',
                    validation: {
                        mandatory: true,
                        isValid: false,
                        type: 'selected',
                        message:
                            'Veuillez sélectionner un type pour votre question',
                        i18nMessage: t(
                            'Veuillez sélectionner un type pour votre question'
                        ),
                    },
                    wrappedIn: 'questions',
                },
                responses: {
                    name: 'responses',
                    type: 'array',
                    label: 'Détail du formulaire',
                    field: 'eval',
                    list: constants.custo.EVAL_TYPES,
                    value: '',
                    validation: {
                        mandatory: true,
                        isValid: false,
                        type: 'selected',
                        message:
                            'Veuillez sélectionner un type pour votre question',
                        i18nMessage: t(
                            'Veuillez sélectionner un type pour votre question'
                        ),
                    },
                    wrappedIn: 'questions',
                },
            },
            model: {},
            list: 'static',
            value: {},
            // 'validation': {
            //     'mandatory': true,
            //     'isValid': false,
            //     'type': 'atLeastTwoFilledForceValueToOrder',
            //     'message': 'Veuillez renseigner au moins deux réponses à la question',
            //     'i18nMessage': t('Veuillez renseigner au moins deux réponses à la question'),
            // },
        },
    },
}

export { corposurvey_CARD }

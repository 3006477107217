import { t, h, Component } from '@app/utils'
import { addCSRFToken, isDefined } from '@app/core'
import { Forum } from '@app/api'
import { Form, Button } from '@app/elements'
import './index.scss'

import icoDefaultUser from '@app/img/default/default.png'
import icoDefaultUserx2 from '@app/img/default/default@2x.png'

const AFTopicAnswer = Component(
    {
        sendToParent: null,
        name: '',
        user: null,
        topicId: '',
        moderated: 0,
        placeholder: '',
        abusesCount: 0,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.placeholder) {
                state.placeholder = props.placeholder
            }

            if (props.name) {
                actions.setName(props.name)
            }
            if (props.topicId) {
                actions.setTopicId(props.topicId)
            }

            if (props.user) {
                actions.setUser(props.user)
            }

            if (props.moderated) {
                actions.setModerated(props.moderated)
            }

            if (props.abusesCount) {
                actions.setAbusesCount(props.abusesCount)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
        onModerate: (props) => (state, actions) => {
            let data = {}
            data['answerModerated_' + props.id] = true
            Forum.moderateAnswer(state.topicId, data).then(() => {
                props.addOneModeratedCount()
                actions.setModerated(1)
            })
        },
        onResetAnswer: (props) => (state, actions) => {
            let data = {}
            data['answerResetAbuses_' + props.id] = true
            Forum.moderateAnswer(state.topicId, data).then(() => {
                props.addOneModeratedCount()
                actions.setModerated(1)
                actions.setAbusesCount(0)
            })
        },
        getDate: ({ el, dat }) => {
            dat = dat.replace(/-/g, '/')
            dat = dat.split('.')[0]
            var date = new Date(Date.parse(new Date(dat)))
            var options = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }
            el.innerHTML =
                t('Le') + ' ' + date.toLocaleDateString('fr-FR', options)
        },
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setUser: (newState) => (state) => ({
            user: newState,
        }),
        setTopicId: (newState) => (state) => ({
            topicId: newState,
        }),
        setModerated: (newState) => (state) => ({
            moderated: newState,
        }),
        setAbusesCount: (newState) => (state) => ({
            abusesCount: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div
            class="aftopicanswer-wrapper"
            key={props.key}
            data-redcolored={state.abusesCount > 0}
        >
            <div class="forumtopic-left-hand">
                <div class="forumtopic-left-hand-wrapper">
                    <label class="forumtopic-left-hand-subtitle sup-label">
                        {t('User')}
                    </label>
                </div>
                {props.id !== null && (
                    <div class="forumtopic-left-hand-wrapper">
                        <label class="forumtopic-left-hand-title sup-label">
                            {t('Commentaire')}
                        </label>
                        <p
                            class="btzLightTexte"
                            oncreate={(el) =>
                                actions.getDate({
                                    el: el,
                                    dat: props.date.date,
                                })
                            }
                        ></p>
                    </div>
                )}
            </div>
            <div class="forumtopic-center-hand">
                <div class="forumtopic-center-hand-user">
                    {state.user !== null && (
                        <dummy>
                            {state.user.media !== null ? (
                                <img
                                    style={{ 'max-width': '100%' }}
                                    src={state.user.media.url}
                                />
                            ) : (
                                <img
                                    style={{ 'max-width': '100%' }}
                                    src={icoDefaultUser}
                                    srcset={`${icoDefaultUserx2} 2x`}
                                />
                            )}
                            <div
                                class=""
                                style={{
                                    'cursor': 'pointer',
                                    'white-space': 'normal',
                                    'line-height': '1.2',
                                    'max-width': '85%',
                                }}
                                onclick={() => {
                                    window.location.href = addCSRFToken(
                                        '/users/users/' + state.user.id
                                    )
                                }}
                            >
                                {state.user.fname + ' / ' + state.user.email}
                            </div>
                        </dummy>
                    )}
                </div>
                <div class="forumtopic-center-hand-content">
                    {state.moderated > 0 && (
                        <p class="forumtopic-redlabel">
                            {t(
                                'Considéré inapproprié, ce post a été modéré par les administrateurs.'
                            )}
                        </p>
                    )}
                    <div class="sup-textarea" style="">
                        <textarea
                            id={props.id}
                            name={props.name}
                            class={'input-text'}
                            value={props.content}
                            oninput={props.oninput}
                            placeholder={props.placeholder}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="forumtopic-right-hand">
                <div class="forumtopic-right-hand-user">
                    {state.abusesCount > 0 && (
                        <div>
                            <p
                                class="forumtopic-redlabel"
                                style={{ display: 'inline-block' }}
                            >
                                {t('Signalement(s)') + ' ' + state.abusesCount}
                            </p>
                            <p
                                class="forumtopic-redlabel"
                                onclick={() => {
                                    actions.onResetAnswer(props)
                                }}
                                style={{
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    margin: '0 0 0 50px',
                                }}
                            >
                                {t('> Reset')}
                            </p>
                        </div>
                    )}
                </div>
                <div class="forumtopic-right-hand-content">
                    {isDefined(props.media) && props.media.url !== null && (
                        <img
                            style={{ 'max-width': '100%' }}
                            src={props.media.url}
                        />
                    )}
                </div>
            </div>
            {(state.moderated === null || state.moderated === 0) &&
                props.id !== null && (
                    <Form.Group classes="btzForm-btzFooter">
                        <Button
                            primary
                            flat
                            onclick={() => {
                                actions.onModerate(props)
                            }}
                        >
                            {t('Modérer')}
                        </Button>
                    </Form.Group>
                )}
        </div>
    ),
    'aftopicanswer'
)

export { AFTopicAnswer }

import { debounce } from 'hyperapp-fx'
import { isLength } from '@app/utils'
import { Session, User } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    isEmail,
    getUser,
    isDefined,
} from '@app/core'
import { allRoutes, createAndSaveCSRFToken, addCSRFToken } from '@app/core'
import { displayErrors } from '@app/api/errors'

export default {
    initAction: function () {
        const action = {
            onComponentCreate: (props) => (state, actions) => {
                let refresh = localStorage.getItem('refresh')
                if (isDefined(refresh) && refresh !== 'null') {
                    User.refreshUser({ refresh: refresh }).then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            localStorage.setItem('apitoken', res.data.token)
                            localStorage.setItem('refresh', res.data.refresh)
                            localStorage.setItem(
                                'expiration',
                                res.data.expiration.date
                            )
                            createAndSaveCSRFToken()

                            getUser(() => {
                                window.location.href = addCSRFToken(
                                    allRoutes['private.wordsrh'].pathname
                                )
                            }, true)
                        } else {
                            actions.initLogin(props)
                        }
                    })
                } else {
                    actions.initLogin(props)
                }
            },

            initLogin: (props) => (state, actions) => {
                if (props.send) {
                    state.sendToParent = props.send
                }
                localStorage.removeItem('apitoken')
                localStorage.removeItem('refresh')
                localStorage.removeItem('expiration')

                window.onkeyup = function (e) {
                    var key = e.keyCode ? e.keyCode : e.which
                    if (key === 13) {
                        actions.onSubmit(e)
                    }
                }
            },

            setValues: (newState) => (state) => ({
                values: { ...state.values, ...newState },
            }),

            setTouched: (newState) => (state) => ({
                isTouched: { ...state.isTouched, ...newState },
            }),

            setValidity: (newState) => (state) => ({
                isValid: { ...state.isValid, ...newState },
            }),

            setPasswordState: (newState) => (state) => ({
                passwordHidden: newState,
            }),

            isInputValid:
                ({ name, value }) =>
                (state, actions) => {
                    if (name === undefined) {
                        return false
                    }

                    state.sendToParent({
                        ns: name,
                        key: 'value',
                        value: value,
                    })

                    switch (name) {
                        case 'email':
                            return isEmail(value)
                        case 'password':
                            return isLength(value, { min: 1 })
                        default:
                            throw Error(`${name} has no validator assigned`)
                    }
                },

            contactApiToCheckEmail: async (email) =>
                await Session.checkEmail(email),

            contactApiToCreateSession: async (email, password) =>
                await Session.createSessionCorpo(email, password),

            onUpdate:
                ({ target: { name, value } }) =>
                (state, actions) => {
                    actions.setValues({ [name]: value })
                    actions.setValidity({
                        [name]: actions.isInputValid({ name, value }),
                    })

                    actions.forceHydratation()
                },

            waitForLastInput: ({ target }) =>
                debounce(10, 'onUpdate', { target }),

            togglePasswordInput: () => (state, actions) => {
                actions.setPasswordState(!state.passwordHidden)
            },

            // onBlur: ({ target: { name, value } }) => (state, actions) => {
            //     actions.setTouched({ [name]: true });
            // },

            setFormIsSubmitting: (active) => (state, actions) => ({
                formIsSubmitting: active,
            }),

            goToNextStep: () => (state, actions) => {
                // window.location.href = allRoutes['home.login.recovery'].pathname + '?email=' + state.values.email;
            },

            switchResetPwd: () => (state) => ({
                showResetPwd: !state.showResetPwd,
            }),
            onCreate: () => (state, actions) => {
                setTimeout(function () {
                    actions.forceHydratation()
                }, 200)
            },
            forceHydratation: () => (state, actions) => {
                var els = document.querySelectorAll('.btzForm-btzFormInput')
                for (var iEls = 0; iEls < els.length; iEls++) {
                    var el = els[iEls]
                    const { name, value } = el
                    if (isDefined(name) && isDefined(value)) {
                        actions.setTouched({ [name]: true })
                        actions.setValues({ [name]: value })
                        actions.setValidity({
                            [name]: actions.isInputValid({ name, value }),
                        })
                    }
                }
            },

            onSubmit: (event) => (state, actions) => {
                event.preventDefault()

                if (state.formIsSubmitting) {
                    return false
                }

                // const { email, password } = state.values;

                if (state.isValid.email && state.isValid.password) {
                    actions.setFormIsSubmitting(true)
                    actions.createSession()
                } else {
                    actions.setFormIsSubmitting(false)
                    displayErrors(
                        'Votre email ou votre mot de passe est mauvais.',
                        2000
                    )
                }
            },

            onSubmitResetPwd: (event) => (state, actions) => {
                event.preventDefault()

                if (state.formIsSubmitting) {
                    return false
                }

                // const { email } = state.values;

                if (state.isValid.email) {
                    actions.setFormIsSubmitting(true)
                    actions.resetSession()
                } else {
                    actions.setFormIsSubmitting(false)
                    displayErrors('Votre email est mauvais.', 2000)
                }
            },

            createSession: () => (state, actions) => {
                const { email, password } = state.values
                Session.createSessionCorpo(email, password).then((res) => {
                    actions.setFormIsSubmitting(false)
                    if (isDefined(res.data) && !res.data.isError) {
                        localStorage.setItem('apitoken', res.data.token)
                        localStorage.setItem('refresh', res.data.refresh)
                        localStorage.setItem(
                            'expiration',
                            res.data.expiration.date
                        )
                        createAndSaveCSRFToken()

                        getUser((res) => {
                            if (
                                isDefined(res) &&
                                isDefined(res.data) &&
                                isDefined(res.data.roles)
                            ) {
                                if (
                                    res.data.roles.length === 1 &&
                                    res.data.roles[0] === 'ROLE_USER'
                                ) {
                                    localStorage.removeItem('apitoken')
                                    localStorage.removeItem('refresh')
                                    localStorage.removeItem('expiration')
                                    displayErrors(
                                        'Seuls les administrateur peuvent se connecter aux administrations.',
                                        2000
                                    )
                                    return false
                                }
                            }
                            window.location.href = addCSRFToken(
                                allRoutes['private.index'].pathname
                            )
                        }, true)
                    }
                })
            },
            resetSession: () => (state, actions) => {
                const { email } = state.values
                Session.resetSessionCorpo(email).then((res) => {
                    actions.setFormIsSubmitting(false)
                    if (isDefined(res.data) && !res.data.isError) {
                        actions.switchResetPwd()
                    }
                })
            },
        }
        return action
    },
}

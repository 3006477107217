import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getUserLegalAgreements(id, type) {
    return queueQueries(
        `${APP_API_URL}/admin/user-legal-agreements/${id}/${type}/`,
        'GET'
    )
}
function getCurrentLegalAgreements(type) {
    return queueQueries(
        `${APP_API_URL}/admin/legal-agreements/current/${type}/`,
        'GET'
    )
}

export const Consent = {
    getUserLegalAgreements,
    getCurrentLegalAgreements,
}

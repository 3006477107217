import { t, h } from '@app/utils'
import { Button } from '@app/elements'

export const ModalViewMedia = (props, actions) => (
    <div class="supModalContainer">
        <div class="">
            <p class="sup-title">{t('Ajout du média')}</p>
            <p class="sup-subtitle">
                {t('Choisissez le type de média à ajouter')}
            </p>
            <div class="">
                <Button
                    primary
                    flat
                    active={props.modeDisplayed === 'image'}
                    onclick={props.displayImageForm}
                >
                    <p class="" style={{ margin: '0px' }}>
                        {t('Image')}
                    </p>
                </Button>
                <Button
                    primary
                    flat
                    active={props.modeDisplayed === 'video'}
                    onclick={props.displayVideoForm}
                >
                    <p class="" style={{ margin: '0px' }}>
                        {t('Vidéo')}
                    </p>
                </Button>
            </div>
            {props.modeDisplayed !== '' && (
                <div class="supModalContainer-supModalMediaWrapper">
                    {props.modeDisplayed === 'video' ? (
                        <div class="">
                            <p class="sup-subtitle">
                                {t('Copier/coller l‘url de la vidéo')}
                            </p>
                            <input
                                type="text"
                                placeholder={t('Renseignez l‘url de la vidéo')}
                                oninput={(el) => props.updateVideoUrl(el)}
                            />
                            {props.mediaVideoUrlParsed !== '' && (
                                <div>
                                    <iframe
                                        style={{
                                            'width': '100%',
                                            'height': '100%',
                                            'max-width': '200px',
                                            'margin': '25px auto',
                                            'display': 'block',
                                        }}
                                        src={props.mediaVideoUrlParsed}
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        allowfullscreen="true"
                                        width="100%"
                                        height="100%"
                                        frameborder="0"
                                    ></iframe>
                                    <Button
                                        primary
                                        flat
                                        active
                                        onclick={props.addMedia}
                                    >
                                        <p class="" style={{ margin: '0px' }}>
                                            {t('Ajouter')}
                                        </p>
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div class="">
                            <p class="sup-subtitle">
                                {t('Choisissez l‘image à ajouter')}
                            </p>
                            <div
                                class="input-file-container"
                                style="width: 100%"
                            >
                                <input
                                    type="file"
                                    name=""
                                    onchange={(el) =>
                                        props.convertImageToB64(el)
                                    }
                                />
                                <label for="my-file" tabindex="0">
                                    {t('Choisir une image')}
                                </label>
                                {props.mediaBase64 !== '' && (
                                    <div>
                                        <img src={props.mediaBase64} />
                                        <Button
                                            primary
                                            flat
                                            active
                                            onclick={props.addMedia}
                                        >
                                            <p
                                                class=""
                                                style={{ margin: '0px' }}
                                            >
                                                {t('Ajouter')}
                                            </p>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    </div>
)

import { h, Component, classMerge } from '@app/utils'
import './index.scss'

/**
 * Alfred' speech balloon
 * @param {any} props All properties to element
 * @param {any} children All children to be nested inside
 * @return vNode
 * @example
 * <Balloon {...props}>
 *   {children}
 * </Balloon>
 */
const Balloon = Component(
    {
        childs: '',
        title: '',
        oldTitle: '',
        classes: null,
        allClasses: '',
        kind: 'info',
        aTag: null,
        atagTitle: null,
        tmout: null,
        oldChildren: '',
        oldKind: 'info',
        actionAfterError: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.title) {
                actions.setTitle(props.title)
            }
            if (props.atag) {
                actions.setATag(props.atag)
                actions.setAtagTitle(props.atagTitle)
            }
            if (props.atagTitle) {
                actions.setAtagTitle(props.atagTitle)
            }
            if (props.kind) {
                actions.setKind(props.kind)
                state.allClasses = classMerge([props.kind])
            }
            if (props.classes) {
                state.allClasses = classMerge([props.classes, state.allClasses])
            }
            if (props.actionAfterError) {
                actions.setActionAfterError(props.actionAfterError)
            }

            actions.setChilds(props.children[0] || '')

            if (props.kind !== 'error') {
                actions.setOldChildren(props.children[0] || '')
                state.oldKind = props.kind
                clearTimeout(state.tmout)
                state.tmout = null
            } else {
                actions.timeBeforeRemovingError()
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.title) {
                actions.setTitle(props.title)
            }
            if (props.atag) {
                actions.setATag(props.atag)
            }
            if (props.atagTitle) {
                actions.setAtagTitle(props.atagTitle)
            }
            if (props.kind) {
                actions.setKind(props.kind)
            }
            if (props.actionAfterError) {
                actions.setActionAfterError(props.actionAfterError)
            }

            actions.setChilds(props.children[0] || '')

            if (props.kind !== 'error') {
                actions.setOldChildren(props.children[0] || '')
                state.oldKind = props.kind
                clearTimeout(state.tmout)
                state.tmout = null
            } else {
                actions.timeBeforeRemovingError()
            }
        },
        timeBeforeRemovingError: () => (state, actions) => {
            clearTimeout(state.tmout)
            state.tmout = setTimeout(() => {
                clearTimeout(state.tmout)
                state.tmout = null
                actions.setChilds(state.oldChildren)
                actions.setKind(state.oldKind)

                if (
                    state.actionAfterError !== null &&
                    typeof state.actionAfterError === 'function'
                ) {
                    state.actionAfterError()
                }
            }, 3500)
        },
        setChilds: (newState) => (state) => ({
            childs: newState,
        }),
        setOldChildren: (newState) => (state) => ({
            oldChildren: newState,
        }),
        setTitle: (newState) => (state) => ({
            title: newState,
        }),
        setKind: (newState) => (state) => ({
            kind: newState,
        }),
        setATag: (newState) => (state) => ({
            aTag: newState,
        }),
        setAtagTitle: (newState) => (state) => ({
            atagTitle: newState,
        }),
        setActionAfterError: (newState) => (state) => ({
            actionAfterError: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {state.childs !== '' && (
                <blockquote class={'btzBaloon ' + state.kind}>
                    {state.kind == 'info' && (
                        <div class="btzBaloonInfo">
                            {state.title && (
                                <p
                                    class="btzBaloon-btzTitle"
                                    innerHTML={state.title}
                                ></p>
                            )}
                            <p class="btzBaloon-btzContent">{state.childs}</p>
                            {state.aTag !== null &&
                                state.aTag !== undefined && (
                                    <a
                                        class="btzLink btzBaloon-btzLink"
                                        href={state.aTag}
                                    >
                                        {state.atagTitle}
                                    </a>
                                )}
                        </div>
                    )}
                    {state.kind == 'success' && (
                        <div class="btzBaloonSuccess">
                            {state.title && (
                                <p
                                    class="btzBaloon-btzTitle"
                                    innerHTML={state.title}
                                ></p>
                            )}
                            <p class="btzBaloon-btzContent">{state.childs}</p>
                        </div>
                    )}
                    {state.kind == 'error' && (
                        <div key={state.title} class="btzBaloonError">
                            {/*state.title &&
                                <p key={state.title} class='btzBaloon-btzTitle' innerHTML={state.title}></p>
                            */}
                            <p class="btzBaloon-btzContent">{state.childs}</p>
                        </div>
                    )}
                </blockquote>
            )}
        </div>
    ),
    'balloon'
)

export { Balloon }

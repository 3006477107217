import { h, Component } from '@app/utils'
import { deepCopy, isDefined } from '@app/core'
import './index.scss'

import { AdminFormLinkedSelect } from '@app/elements'

const AdminFormLinkedSelects = Component(
    {
        name: '',
        listSelects: [],
        document: null,
        sendToParent: null,
        linkedDocument: [],
        allDynamicCallDoned: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document) {
                let doc = props.document
                if (doc.choiceList) {
                    let listSelects = []
                    for (var chList in props.document.choiceList) {
                        let tmpChoiceList = deepCopy(
                            props.document.choiceList[chList].list
                        )
                        let tmpChoiceTempList = deepCopy(
                            props.document.choiceList[chList].tempList
                        )
                        let choiceList = []
                        if (
                            props.document.choiceList[chList].list === 'dynamic'
                        ) {
                            let choiceListAPI =
                                props.document.choiceList[chList].listAPI
                            let choiceListParams =
                                props.document.choiceList[chList].listParams ||
                                []
                            //
                            if (isDefined(choiceListAPI)) {
                                let withDefaultOpt =
                                    props.document.choiceList[chList]
                                        .withDefaultOpt
                                let withActiveLabel =
                                    props.document.choiceList[chList]
                                        .withActiveLabel
                                let activeKey =
                                    props.document.choiceList[chList].activeKey
                                props.document.choiceList[chList]
                                    .withActiveLabel
                                choiceListAPI(
                                    ...choiceListParams.map((param) => {
                                        if (param.type === 'dynamic') {
                                            return props.wholeDocument[
                                                param.key
                                            ].value
                                        }
                                        return
                                    })
                                ).then((res) => {
                                    //
                                    console.log(
                                        'tmpChoiceTempList : ',
                                        tmpChoiceTempList
                                    )
                                    for (var resList in res.data) {
                                        if (isDefined(res.data[resList].name)) {
                                            let choiceTempList =
                                                tmpChoiceTempList.filter(
                                                    (f) =>
                                                        f.id ===
                                                        res.data[resList].id
                                                )[0]
                                            choiceList.push({
                                                name: res.data[resList].name,
                                                id: res.data[resList].id,
                                                checked: isDefined(
                                                    choiceTempList
                                                )
                                                    ? choiceTempList.checked
                                                    : false,
                                                bydefault: false,
                                                defaultOpt:
                                                    withDefaultOpt || false,
                                                isactive: activeKey
                                                    ? res.data[resList][
                                                          activeKey.name
                                                      ] === activeKey.value
                                                    : false,
                                                activeLabel:
                                                    withActiveLabel || false,
                                            })
                                        }
                                    }
                                    actions.setAllDynamicCallDoned(true)
                                })
                            }
                        } else {
                            for (var tmpChList in tmpChoiceList) {
                                choiceList.push({
                                    name: tmpChoiceList[tmpChList].value,
                                    id: tmpChoiceList[tmpChList].key,
                                    checked: tmpChoiceList[tmpChList].checked,
                                    bydefault:
                                        tmpChoiceList[tmpChList].bydefault,
                                    defaultOpt:
                                        props.document.choiceList[chList]
                                            .withDefaultOpt || false,
                                    activeLabel:
                                        props.document.choiceList[chList]
                                            .withActiveLabel || false,
                                })
                            }
                        }
                        listSelects.push({
                            name: props.document.choiceList[chList].label,
                            id: props.document.choiceList[chList].id,
                            value:
                                props.document.choiceList[chList].checked ===
                                    0 ||
                                !props.document.choiceList[chList].checked
                                    ? 0
                                    : 1,
                            linkedDocument: choiceList,
                        })
                    }
                    actions.setListSelects(listSelects)
                }
                actions.setDocument(doc)
            }

            if (props.onclick) {
                actions.setSendToParent(props.onclick)
            }
            if (props.name) {
                actions.setName(props.name)
            }
            //
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            actions.parseListSelects(state.listSelects)
            //
            let listSelects = state.listSelects
            for (let select in listSelects) {
                let selectId = listSelects[select].id + 'Settings'
                let programObjs = props.document.value[selectId]
                if (isDefined(listSelects[select].linkedDocument)) {
                    for (let lDoc in listSelects[select].linkedDocument) {
                        if (
                            isDefined(programObjs) &&
                            Array.isArray(programObjs)
                        ) {
                            let programObj = programObjs.filter(
                                (f) =>
                                    f.name ===
                                    listSelects[select].linkedDocument[lDoc].id
                            )[0]
                            if (
                                isDefined(programObj) &&
                                programObj.status === true
                            ) {
                                listSelects[select].linkedDocument[
                                    lDoc
                                ].checked = true
                            }
                        }
                    }
                }
            }
        },
        updateDocument: (el) => (state, actions) => {
            let name = el.target.name
            let value = el.target.value
            let kind = el.target.kind
            let listSelects = state.listSelects
            for (var lsSlc in listSelects) {
                var lkDoc = null
                if (listSelects[lsSlc].name === name) {
                    if (kind === 'linkeddoc') {
                        for (lkDoc in listSelects[lsSlc].linkedDocument) {
                            listSelects[lsSlc].linkedDocument[
                                lkDoc
                            ].checked = false
                            if (
                                value.indexOf(
                                    listSelects[lsSlc].linkedDocument[lkDoc].id
                                ) > -1
                            ) {
                                listSelects[lsSlc].linkedDocument[
                                    lkDoc
                                ].checked = true
                            } else {
                                listSelects[lsSlc].linkedDocument[
                                    lkDoc
                                ].bydefault = false
                            }
                        }
                    } else if (kind === 'active') {
                        listSelects[lsSlc].value = +value
                    } else if (kind === 'bydefault') {
                        value = value.split('-bydefault-')
                        for (lkDoc in listSelects[lsSlc].linkedDocument) {
                            if (
                                String(value[0]) ===
                                String(
                                    listSelects[lsSlc].linkedDocument[lkDoc].id
                                )
                            ) {
                                listSelects[lsSlc].linkedDocument[
                                    lkDoc
                                ].bydefault =
                                    value[1] === 'true' || value[1] === true
                                        ? true
                                        : false
                            }
                        }
                    }
                }
            }
            let parsedSelects = actions.parseListSelects(listSelects)
            state.sendToParent({ name: state.name, value: parsedSelects })
        },
        parseListSelects: (listSelects) => (state, actions) => {
            let parsedSelects = {}
            for (var lSelect in listSelects) {
                parsedSelects[listSelects[lSelect].id] =
                    listSelects[lSelect].value === 1 ? true : false
                if (listSelects[lSelect].linkedDocument.length > 0) {
                    parsedSelects[listSelects[lSelect].id + 'Settings'] =
                        listSelects[lSelect].linkedDocument.reduce(
                            (acc, obj) => {
                                acc.push({
                                    name: obj.id,
                                    status: obj.checked,
                                    default: obj.bydefault,
                                })
                                return acc
                            },
                            []
                        )
                }
            }
            actions.setListSelects(listSelects)
            return parsedSelects
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setLinkedDocument: (newState) => (state) => ({
            linkedDocument: newState,
        }),
        setListSelects: (newState) => (state) => ({
            listSelects: newState,
        }),
        setSendToParent: (newState) => (state) => ({
            sendToParent: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setAllDynamicCallDoned: (newState) => (state) => ({
            allDynamicCallDoned: newState,
        }),
    },
    (state, actions) => (props, children) =>
        (
            <div>
                {state.listSelects.map((ls, idx) => (
                    <AdminFormLinkedSelect
                        key={ls.name + '-linkedselect-' + idx}
                        label={ls.name}
                        active={ls.value}
                        document={state.document}
                        linkedDocument={ls.linkedDocument}
                        sendToParent={actions.updateDocument}
                        allDynamicCallDoned={state.allDynamicCallDoned}
                    ></AdminFormLinkedSelect>
                ))}
            </div>
        ),
    'adminformlinkedselects'
)

export { AdminFormLinkedSelects }

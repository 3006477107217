import { h, classMerge } from '@app/utils'

const Pos = (props, children) => {
    const { m, mt, mx, my, p, pt, px, py, classes, ...otherProps } = props

    const allClasses = classMerge([
        !!m && `m-${m}`,
        !!mt && `mt-${mt}`,
        !!mx && `mx-${mx}`,
        !!my && `my-${my}`,
        !!p && `p-${p}`,
        !!pt && `pt-${pt}`,
        !!px && `px-${px}`,
        !!py && `py-${py}`,
        classes,
    ])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Float = (props, children) => {
    const { left, clear, right, classes, ...otherProps } = props

    const allClasses = classMerge([
        left && 'float-left',
        clear && 'clearfix',
        right && 'float-right',
        classes,
    ])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Relative = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['relative', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Absolute = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['absolute', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Fixed = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['fixed', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Centered = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['centered', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

Pos.Float = Float
Pos.Relative = Relative
Pos.Absolute = Absolute
Pos.Fixed = Fixed
Pos.Centered = Centered
export { Pos }

import { t } from '@app/utils'

export const CUSTOMERS = {
    maternite: {
        title: 'Maternite Connect',
        description: 'Maternite Connect description',
        i18ndescription: t('Maternite Connect description'),
        mailto: 'alfred@betterise.me',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
    },
}

import icoFR from '@app/img/ico/flag/flag-fr.png'
import icoFRx2 from '@app/img/ico/flag/flag-fr@2x.png'
import icoEN from '@app/img/ico/flag/flag-en.png'
import icoENx2 from '@app/img/ico/flag/flag-en@2x.png'

export const COUNTRIES = {
    0: {
        label: 'Français',
        key: 'fr',
        flag: { normal: icoFR, retina: icoFRx2 },
    },
    1: {
        label: 'Anglais',
        key: 'en_US',
        flag: { normal: icoEN, retina: icoENx2 },
    },
}

export const CONFIG_DOWNLOADS_LANGS = {
    0: { value: 'Français', key: 'fr' },
    1: { value: 'Anglais', key: 'en_US' },
}

export const LANGUAGES = {
    0: { key: 'fr', value: 'FR' },
    1: { key: 'en_US', value: 'EN' },
}

export const CARDS_CREATION = {
    0: {
        label: 'Alfred',
        infos: ['Illustration', '1 par jour'],
        type: 13,
        template: 'alfred_info',
        name: 'alfred',
        addurl: 'private.cards.edit',
    },
    1: {
        label: 'Article',
        infos: ['Sujet de fond', '1 par jour', 'Entre 1000 et 2000 caractères'],
        type: 18,
        template: 'article',
        name: 'article',
        addurl: 'private.cards.edit',
    },
    2: {
        label: 'Bandeau Image',
        infos: ['Illustration', '1 par jour', '1280x300px'],
        type: 15,
        template: 'bandeau_image',
        name: '',
        addurl: 'private.cards.edit',
    },
    3: {
        label: 'Bonjour',
        infos: [
            'Carte d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        type: 16,
        template: 'hello',
        name: 'hello',
        addurl: 'private.cards.edit',
    },
    4: {
        label: 'Coach',
        infos: ['Semaine et week-end', '1 par jour'],
        type: 50,
        template: 'coach',
        name: 'coach',
        addurl: 'private.cards.edit',
    },
    5: {
        label: 'Conseil',
        infos: [
            'Fortement personnalisé',
            '3 par jour',
            'Entre 400 et 500 caractères',
        ],
        type: 2,
        template: 'accompagnement_generique',
        name: 'advice',
        addurl: 'private.cards.edit',
    },
    6: {
        label: 'Évaluation classique',
        infos: [
            'Question / collecte de données',
            '3 par jour',
            'Plusieurs types possibles',
        ],
        type: 1,
        template: 'evaluation_generique',
        name: 'eval classic',
        addurl: 'private.cards.edit',
    },
    7: {
        label: 'Évaluation comportement',
        infos: [
            'Question / collecte de données',
            '4 par jour',
            'Plusieurs types possibles',
        ],
        type: 22,
        template: 'evaluation_comportement',
        name: 'eval comportement',
        addurl: 'private.cards.edit',
    },
    8: {
        label: 'Prénom',
        infos: ['Carte prénom'],
        type: 8,
        template: 'surname',
        name: 'prenom',
        addurl: 'private.cards.edit',
    },
    9: {
        label: 'Quiz',
        infos: ['Vrai ou faux', '1 par jour', 'Nombre de caractères limité'],
        type: 12,
        template: 'quizz',
        name: 'quizz',
        addurl: 'private.cards.edit',
    },
    10: {
        label: 'Recette',
        infos: ['Semaine et week-end', '1 par jour', 'Variante possible'],
        type: 19,
        template: 'recipe',
        name: 'recipe',
        addurl: 'private.cards.edit',
    },
    11: {
        label: 'Votre bébé',
        infos: ['Carte bébé'],
        type: 52,
        template: 'your_baby',
        name: 'votre bebe',
        addurl: 'private.cards.edit',
    },
    12: {
        label: 'Évaluation Symptomes',
        infos: [
            'Question / collecte de données',
            '3 par jour',
            'Plusieurs types possibles',
        ],
        type: 9,
        template: 'evaluation_symptome',
        name: 'eval symptomes',
        addurl: 'private.cards.edit',
    },
}

export const CONFIG_DOWNLOADS = {
    0: {
        label: 'de traductions',
        key: 'translations',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Anglais',
            key: 'en_US',
        },
        defaultId: '',
    },
    1: {
        label: 'de cartes',
        key: 'cards',
        availableType: true,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: {
            label: 'Bonjour',
            key: '16',
        },
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
        typeList: CARDS_CREATION,
    },
    2: {
        label: 'd‘articles',
        key: 'articles',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    3: {
        label: 'de recettes',
        key: 'recipes',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
}

import themeFood2 from '@app/img/themes/picto-nutrition.png'
import themeActivity from '@app/img/themes/picto-activite.png'
import themeSleep2 from '@app/img/themes/picto-sommeil.png'
import themeStress from '@app/img/themes/picto-stress.png'
import themeShape from '@app/img/themes/picto-moral.png'
import themeTobacco from '@app/img/themes/picto-tabac.png'
import themeAlcool from '@app/img/themes/picto-alcool.png'
import themeMemory from '@app/img/themes/picto-memoire.png'
import themeAnticancer from '@app/img/themes/picto-anticancer.png'
import themeBackache from '@app/img/themes/picto-maldedos.png'
import themeWorkingPosition from '@app/img/themes/picto-autravail.png'
import themeShapedHeart from '@app/img/themes/picto-coeur.png'
import themeHappinessPsychology from '@app/img/themes/picto-bonheur.png'
import themeProfil from '@app/img/themes/picto-profil.png'
import themeFollowedWeight from '@app/img/themes/picto-poids.png'
import themeHappyPregnancy from '@app/img/themes/picto-grossesse.png'
import themeTiredness from '@app/img/themes/picto-fatigue.png'
import themeSport from '@app/img/themes/picto-sport.png'
import themeDenivele from '@app/img/themes/picto-activite.png'

export const CARDS_TYPES = {
    0: { theme: '', value: '', key: 0, color: '' },
    1: {
        theme: 'accouchement',
        value: 'Accouchement',
        i18nLabel: t('Accouchement'),
        key: 9,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    2: {
        theme: 'activity',
        value: 'Activité',
        i18nLabel: t('Activité'),
        key: 2,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    3: {
        theme: 'alcool',
        value: 'Alcool',
        i18nLabel: t('Alcool'),
        key: 6,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    4: {
        theme: 'demarche',
        value: 'Démarche',
        i18nLabel: t('Démarche'),
        key: 12,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    5: {
        theme: 'examens-medicaux',
        value: 'Examens médicaux',
        i18nLabel: t('Examens médicaux'),
        key: 11,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    6: {
        theme: 'food',
        value: 'Nutrition',
        i18nLabel: t('Nutrition'),
        key: 1,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    7: {
        theme: 'pratique',
        value: 'Pratique',
        i18nLabel: t('Pratique'),
        key: 8,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    8: {
        theme: 'psychologie',
        value: 'Psychologie',
        i18nLabel: t('Psychologie'),
        key: 7,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    9: {
        theme: 'symptomes',
        value: 'Symptômes',
        i18nLabel: t('Symptômes'),
        key: 10,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    10: {
        theme: 'stress',
        value: 'Stress',
        i18nLabel: t('Stress'),
        key: 4,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    11: {
        theme: 'sleep',
        value: 'Sommeil',
        i18nLabel: t('Sommeil'),
        key: 3,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    12: {
        theme: 'tobacco',
        value: 'Tabac',
        i18nLabel: t('Tabac'),
        key: 5,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
    13: {
        theme: 'your-babay',
        value: 'Votre bébé',
        i18nLabel: t('Votre bébé'),
        key: 13,
        color: '',
        order: 13,
        img: null,
        display: 'both',
    },
}
/*
export const CARDS_TYPES = {
    '0' : {'theme' : '', 'value' : '', 'key' : 0, 'color' : ''},
    '1' : {'theme' : 'food', 'value' : 'Nutrition', 'i18nLabel' : t('Nutrition'), 'key' : 1, 'color' : 'FF6F41', 'order': 13, 'img': themeFood2, 'display': 'both'},
    '2' : {'theme' : 'activity', 'value' : 'Activité', 'i18nLabel' : t('Activité'), 'key' : 2, 'color' : '049588', 'order': 1, 'img': themeActivity, 'display': 'both'},
    '3' : {'theme' : 'sleep', 'value' : 'Sommeil', 'i18nLabel' : t('Sommeil'), 'key' : 3, 'color' : '3E51B5', 'order': 16, 'img': themeSleep2, 'display': 'both'},
    '4' : {'theme' : 'stress', 'value' : 'Stress', 'i18nLabel' : t('Stress'), 'key' : 4, 'color' : 'E81E63', 'order': 17, 'img': themeStress, 'display': 'both'},
    '5' : {'theme' : 'shape', 'value' : 'Moral', 'i18nLabel' : t('Moral'), 'key' : 5, 'color' : 'FEC009', 'order': 12, 'img': themeShape, 'display': 'both'},
    '6' : {'theme' : 'tobacco', 'value' : 'Tabac', 'i18nLabel' : t('Tabac'), 'key' : 6, 'color' : 'FF9A00', 'order': 19, 'img': themeTobacco, 'display': 'both'},
    '7' : {'theme' : 'alcool', 'value' : 'Alcool', 'i18nLabel' : t('Alcool'), 'key' : 8, 'color' : 'A62671', 'order': 2, 'img': themeAlcool, 'display': 'both'},
    '8' : {'theme' : 'memory', 'value' : 'Mémoire', 'i18nLabel' : t('Mémoire'), 'key' : 9, 'color' : '00BABA', 'order': 12, 'img': themeMemory, 'display': 'both'},
    '9' : {'theme' : 'anticancer', 'value' : 'Anti-cancer', 'i18nLabel' : t('Anti-cancer'), 'key' : 10, 'color' : '', 'order': 3, 'img': themeAnticancer, 'display': 'both'},
    '10' : {'theme' : 'backache', 'value' : 'Mal de dos', 'i18nLabel' : t('Mal de dos'), 'key' : 11, 'color' : 'C83B4C', 'order': 11, 'img': themeBackache, 'display': 'both'},
    '11' : {'theme' : 'working-position', 'value' : 'Au travail', 'i18nLabel' : t('Au travail'), 'key' : 12, 'color' : '4EC9DF', 'order': 4, 'img': themeWorkingPosition, 'display': 'both'},
    '12' : {'theme' : 'shaped-heart', 'value' : 'Coeur', 'i18nLabel' : t('Coeur'), 'key' : 13, 'color' : '', 'order': 6, 'img': themeShapedHeart, 'display': 'both'},
    '13' : {'theme' : 'happiness-psychology', 'value' : 'Bonheur', 'i18nLabel' : t('Bonheur'), 'key' : 14, 'color' : '', 'order': 5, 'img': themeHappinessPsychology, 'display': 'both'},
    '14' : {'theme' : 'profil', 'value' : 'Profil', 'i18nLabel' : t('Profil'), 'key' : 15, 'color' : '', 'order': 14, 'img': themeProfil, 'display': 'both'},
    '15' : {'theme' : 'followed-weight', 'value' : 'Suivis de poids', 'i18nLabel' : t('Suivis de poids'), 'key' : 16, 'color' : '5F7D8B', 'order': 15, 'img': themeFollowedWeight, 'display': 'both'},
    '16' : {'theme' : 'happy-pregnancy', 'value' : 'Grossesse', 'i18nLabel' : t('Grossesse'), 'key' : 17, 'color' : '7481CF', 'order': 10, 'img': themeHappyPregnancy, 'display': 'both'},
    '17' : {'theme' : 'tiredness', 'value' : 'Fatigue', 'i18nLabel' : t('Fatigue'), 'key' : 18, 'color' : '3576A1', 'order': 9, 'img': themeTiredness, 'display': 'both'},
    '18' : {'theme' : 'sport', 'value' : 'Sport', 'i18nLabel' : t('Sport'), 'key' : 19, 'color' : '4CAF50', 'order': 18, 'img': themeSport, 'display': 'both'},
    '19' : {'theme' : 'denivele', 'value' : 'Dénivelé', 'i18nLabel' : t('Dénivelé'), 'key' : 20, 'color' : '049588', 'order': 8, 'img': themeDenivele, 'display': 'both'},
    '20' : {'theme' : 'je_bouge', 'value' : 'Je bouge', 'i18nLabel' : t('Je bouge'), 'key' : 22, 'color' : '049588', 'order': 22, 'img': themeActivity, 'display': 'card'},
    '21' : {'theme' : 'je_suis_zen', 'value' : 'Je suis zen', 'i18nLabel' : t('Je suis zen'), 'key' : 23, 'color' : 'E81E63', 'order': 23, 'img': themeStress, 'display': 'card'},
    '22' : {'theme' : 'je_mange_equilibre', 'value' : 'Je mange équilibré', 'i18nLabel' : t('Je mange équilibré'), 'key' : 24, 'color' : 'FF6F41', 'order': 24, 'img': themeFood2, 'display': 'card'},
    '23' : {'theme' : 'je_concilie_ma_vie_pro_perso', 'value' : 'Je concilie ma vie pro/perso', 'i18nLabel' : t('Je concilie ma vie pro/perso'), 'key' : 25, 'color' : '4CAF50', 'order': 25, 'img': themeSport, 'display': 'card'},
    '24' : {'theme' : 'je_gere_mon_sommeil', 'value' : 'Je gère mon sommeil', 'i18nLabel' : t('Je gère mon sommeil'), 'key' : 26, 'color' : '3E51B5', 'order': 26, 'img': themeSleep2, 'display': 'card'},
    '25' : {'theme' : 'je_suis_bien_dans_mon_environnement_de_travail', 'value' : 'Je suis bien dans mon environnement de travail', 'i18nLabel' : t('Je suis bien dans mon environnement de travail'), 'key' : 27, 'color' : 'FEC009', 'order': 27, 'img': themeShape, 'display': 'card'},
};
*/

// require('@app/__tests__/setup')
// const { isAlphaNum } = require('.')
import { isAlphaNum } from '.'

describe('isAlphaNum', () => {
    test('should failed when string/number is not alphanum', async () => {
        expect(isAlphaNum('abc-123')).toBe(false)
        expect(isAlphaNum('abc123=')).toBe(false)
    })

    test('should failed when arg is not a string/number', async () => {
        expect(isAlphaNum(false)).toBe(false)
        expect(isAlphaNum([])).toBe(false)
        expect(isAlphaNum({})).toBe(false)
        expect(isAlphaNum(undefined)).toBe(false)
        expect(isAlphaNum(null)).toBe(false)
        expect(isAlphaNum(NaN)).toBe(false)
        expect(isAlphaNum(() => {})).toBe(false)
    })

    test('should succeed when arg is a valid string/number', async () => {
        expect(isAlphaNum('abc123')).toBe(true)
        expect(isAlphaNum('abc')).toBe(true)
        expect(isAlphaNum(123)).toBe(true)
    })
})

import { t, h } from '@app/utils'
import { allRoutes } from '@app/core'
import { Link } from '@app/elements'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class="">
                {state.messageActive && (
                    <MessagePopup
                        key={'msspp-usermenu'}
                        kind={state.messageKind}
                        active={'active'}
                        duration={3000}
                        durationCallback={() =>
                            actions.displaySuccessMessage(false)
                        }
                        message={state.messageContent}
                    ></MessagePopup>
                )}
                {props.display !== 'login' && (
                    <dummy>
                        <div class="usermenu-userinformations">
                            <img src={state.URLUserMedia} />
                            <p class="">{state.userName}</p>
                        </div>
                        <Link
                            to={allRoutes['pdf.help.corpo'].pathname}
                            target={'_blank'}
                        >
                            {t("Besoin d'aide ?")}
                        </Link>
                        <a
                            onclick={actions.deconnectUser}
                            class={'btn btn-link'}
                        >
                            {t('Déconnexion')}
                        </a>
                    </dummy>
                )}
            </div>
        )
        return view
    },
}

import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { AdminFormRadio, AdminFormMedia, AdminFormHelp } from '@app/elements'
import { isDefined, isFloat } from '@app/core'
import './index.scss'

import * as constants from '@app/constants'

const AFEvalMaker = Component(
    {
        document: null,
        sendToParent: null,
        ulDisplayed: false,
        list: [],
        name: '',
        label: '',
        model: {
            key: null,
            value: null,
            min: null,
            max: null,
            default: null,
            step: null,
        },
        modelList: { key: null, value: null },
        currentSearch: '',
        withMedia: true,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.original) {
                if (isDefined(props.original.withMedia)) {
                    actions.setWithMedia(props.original.withMedia)
                }
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.model) {
                actions.setModel(props.model)
            }
            if (props.modelList) {
                actions.setModelList(props.modelList)
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.list) {
                state.list = props.list
            }
            console.log(
                'state.document>> : ',
                props,
                state.document,
                state.model.tag,
                state.modelList.key
            )
            //
            if (
                props.modelList &&
                props.modelList.key !== null &&
                isDefined(props.document.tag, true) &&
                props.document.tag.name === undefined
            ) {
                let refTag = props.document.tag
                let newTag = {}
                newTag[props.modelList.key] = refTag
                let newValue = props.list.filter((l) => l.name === refTag)
                if (newValue === undefined || newValue[0] === undefined) {
                    newValue = ''
                } else {
                    newValue = newValue[0][props.modelList.value]
                }
                newTag[props.modelList.value] = newValue
                if (newTag.value === '') {
                    newTag.value = newTag.name
                }
                props.document.tag = newTag
                actions.setDocument(props.document)
            } else {
                actions.setDocument(props.document)
            }
            /*
            for (var tg in constants.custo.EVAL_TYPES_MEDICAL) {
                                if (constants.custo.EVAL_TYPES_MEDICAL[tg].key === fieldTag) {
             */
            if (
                isDefined(props.document.tag) &&
                isDefined(props.document.tag.name)
            ) {
                let tagName = props.document.tag.name,
                    tagLabel = ''
                for (var evalType in constants.custo.EVAL_TYPES_MEDICAL) {
                    if (
                        isDefined(
                            constants.custo.EVAL_TYPES_MEDICAL[evalType].tag
                        ) &&
                        constants.custo.EVAL_TYPES_MEDICAL[
                            evalType
                        ].tag.indexOf(tagName) > -1
                    ) {
                        tagLabel =
                            constants.custo.EVAL_TYPES_MEDICAL[evalType].label[
                                constants.custo.EVAL_TYPES_MEDICAL[
                                    evalType
                                ].tag.indexOf(tagName)
                            ]
                    }
                }
                actions.setLabel(tagLabel)
            }
        },
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        setModelList: (newState) => (state) => ({
            modelList: newState,
        }),
        setLabel: (newState) => (state) => ({
            label: newState,
        }),
        selectThisOne:
            ({ el, props }) =>
            (state, actions) => {
                let document = state.document
                document.tag = el

                actions.setCurrentSearch('')
                actions.setDocument(document)
                if (isDefined(state.sendToParent)) {
                    state.sendToParent()
                }
                actions.toggleUl(false)
            },
        updateMedia: (field) => (state, actions) => {
            let document = state.document
            document.media = field.target.value
            actions.setDocument(document)
            if (isDefined(state.sendToParent)) {
                state.sendToParent()
            }
        },
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                let document = state.document,
                    newValue = null
                //
                if (['min', 'max', 'default'].indexOf(name) > -1) {
                    if (String(value).indexOf(',') > -1) {
                        value = String(value).replace(',', '.')
                    }
                    if (isFloat(value)) {
                        newValue = value
                    }
                    if (String(newValue).indexOf('.') > -1) {
                        newValue = String(newValue).replace('.', ',')
                    }
                    if (isDefined(newValue)) {
                        document[name] = newValue
                    }
                } else {
                    document[name] = value
                }
                //
                actions.setDocument(document)
                if (isDefined(state.sendToParent)) {
                    state.sendToParent()
                }
            },
        setOrder: (order) => (state, actions) => {
            // let document = state.document;
            // document.order = order;
            // actions.setDocument(document);
            state.document.order = order
        },
        setCurrentSearch: (newState) => (state) => ({
            currentSearch: newState,
            ulDisplayed: newState !== '' ? true : false,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setWithMedia: (newState) => (state) => ({
            withMedia: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            {props.formsType.toLowerCase() === 'button' ||
            props.formsType.toLowerCase() === 'qcm' ||
            props.formsType === 'Yesno' ? (
                <div>
                    {props.specialDisplay !== 'quizz' &&
                    props.formsType !== 'Yesno' ? (
                        <div
                            class="sup-custom-select-delete"
                            onclick={(el) => {
                                props.ondelete(el)
                                if (isDefined(props.onSynchEachLanguage))
                                    props.onSynchEachLanguage({
                                        lang: props.lang,
                                        order: state.document[
                                            state.model.order
                                        ],
                                    })
                            }}
                        >
                            <p class="">{t('Supprimer')}</p>
                        </div>
                    ) : (
                        <div
                            class="sup-custom-select-delete"
                            style={{ 'vertical-align': 'top' }}
                        >
                            <p class="">{state.document[state.model.order]}</p>
                        </div>
                    )}
                    <input
                        key={
                            'eval-' +
                            props.formsType.toLowerCase() +
                            '-order-' +
                            props.key
                        }
                        type={'hidden'}
                        name={'order'}
                        value={state.document[state.model.order]}
                        onupdate={() => {
                            actions.setOrder(props.order)
                        }}
                    />
                    <input
                        key={
                            'eval-' +
                            props.formsType.toLowerCase() +
                            '-label-' +
                            props.key
                        }
                        type={'text'}
                        name={'name'}
                        placeholder={t('Saisissez l‘intitulé de la réponse')}
                        value={state.document[state.model.key]}
                        oninput={actions.onUpdate}
                        onblur={actions.onUpdate}
                        readonly={
                            props.specialDisplay === 'quizz' ? true : false
                        }
                    />
                    {props.customer !== 'corpo' && (
                        <input
                            key={
                                'eval-' +
                                props.formsType.toLowerCase() +
                                '-value-' +
                                props.key
                            }
                            type={'text'}
                            name={'value'}
                            value={state.document[state.model.value]}
                            oninput={actions.onUpdate}
                            onblur={actions.onUpdate}
                            readonly={
                                props.specialDisplay === 'quizz' ||
                                props.formsType === 'Yesno'
                                    ? true
                                    : false
                            }
                        />
                    )}
                    {props.specialDisplay !== 'quizz' &&
                    props.customer !== 'corpo' ? (
                        <div class="sup-custom-select sup-custom-select-wide-one sup-custom-select-datatag">
                            <div
                                tabindex="0"
                                onblur={() => {
                                    actions.toggleUl(false)
                                }}
                            >
                                {/*<input key={'eval-tag'} name={'tag'} type='text' placeholder={t('Tapez votre rechercher ici ...')} oninput={(el) => { actions.setCurrentSearch(el.target.value); console.log('HERE : ', el) }} onblur={actions.onUpdate} onclick={(el) => { actions.toggleUl(!state.ulDisplayed); }} value={(state.currentSearch === '' ? (state.modelList.value !== null ? state.document[state.model.value][state.modelList.key] : state.document[state.model.tag]) : state.currentSearch)} />*/}
                                <input
                                    key={'eval-tag-hidden'}
                                    name={'tag'}
                                    type="hidden"
                                    value={
                                        state.modelList.key !== null
                                            ? isDefined(
                                                  state.document[
                                                      state.model.tag
                                                  ]
                                              )
                                                ? state.document[
                                                      state.model.tag
                                                  ][state.modelList.key]
                                                : ''
                                            : state.document[state.model.tag]
                                    }
                                />
                                <input
                                    key={'eval-tag-input'}
                                    type="text"
                                    placeholder={t(
                                        'Tapez votre rechercher ici ...'
                                    )}
                                    oninput={(el) => {
                                        actions.setCurrentSearch(
                                            el.target.value
                                        )
                                    }}
                                    onblur={(e) => {
                                        if (!isDefined(e.relatedTarget)) {
                                            actions.toggleUl(false)
                                        }
                                    }}
                                    onclick={() => {
                                        actions.toggleUl(!state.ulDisplayed)
                                    }}
                                    value={
                                        state.currentSearch === ''
                                            ? state.modelList.value !== null
                                                ? isDefined(
                                                      state.document[
                                                          state.model.tag
                                                      ]
                                                  )
                                                    ? state.document[
                                                          state.model.tag
                                                      ][state.modelList.value]
                                                    : ''
                                                : state.document[
                                                      state.model.tag
                                                  ]
                                            : state.currentSearch
                                    }
                                />
                                <div
                                    class="sup-drop-down"
                                    style={{ 'margin-left': '-30px' }}
                                    onclick={() => {
                                        actions.toggleUl(!state.ulDisplayed)
                                    }}
                                ></div>
                                <ul
                                    class="sup-custom-select-ul"
                                    style={{
                                        display: state.ulDisplayed
                                            ? 'block'
                                            : 'none',
                                        margin: '0px',
                                    }}
                                >
                                    {state.list
                                        .filter(
                                            (li) =>
                                                (state.modelList.value === null
                                                    ? li
                                                    : li[
                                                          state.modelList.value
                                                      ]) !== '' &&
                                                ((state.currentSearch !== '' &&
                                                    (state.modelList.value ===
                                                    null
                                                        ? li
                                                        : String(
                                                              li[
                                                                  state
                                                                      .modelList
                                                                      .value
                                                              ]
                                                          ).toLowerCase()
                                                    ).indexOf(
                                                        state.currentSearch.toLowerCase()
                                                    ) > -1) ||
                                                    state.currentSearch === '')
                                        )
                                        .map((l) => (
                                            <li
                                                class="sup-custom-select-li"
                                                data-value={
                                                    state.modelList.value ===
                                                    null
                                                        ? l
                                                        : l[
                                                              state.modelList
                                                                  .value
                                                          ]
                                                }
                                                onclick={() => {
                                                    actions.selectThisOne({
                                                        el: l,
                                                        props: props,
                                                    })
                                                }}
                                            >
                                                {state.modelList.value === null
                                                    ? l
                                                    : l[state.modelList.value]}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <AdminFormRadio
                            key={'adfr-' + props.lang + '-' + props.key}
                            name={'goodResponse'}
                            document={state.document}
                            lang={props.lang}
                            onclick={props.onclickquizz}
                        ></AdminFormRadio>
                    )}
                    {props.customer !== 'corpo' &&
                        props.formsType !== 'Yesno' &&
                        state.withMedia && (
                            <AdminFormMedia
                                key={
                                    'eval-' +
                                    props.formsType.toLowerCase() +
                                    '-media-' +
                                    props.key
                                }
                                name={'media'}
                                document={state.document.media}
                                onclick={actions.updateMedia}
                            ></AdminFormMedia>
                        )}
                    {isDefined(state.document.terminology) &&
                        isDefined(state.document.terminology.systemName) && (
                            <div style="display: inline-block; vertical-align: top;">
                                <AdminFormHelp
                                    infos={
                                        '<b>Nom</b>: ' +
                                        state.document.terminology.systemName +
                                        '<br/><b>Version</b>: ' +
                                        state.document.terminology
                                            .systemVersion +
                                        '<br/><b>Code</b>: ' +
                                        state.document.terminology
                                            .terminologyCode +
                                        '<br/><b>Valeur</b>: ' +
                                        state.document.terminology
                                            .terminologyValue +
                                        '<br/><br/><b>Attribut</b>: ' +
                                        state.document.terminologyAttribute
                                    }
                                    label={'symptome'}
                                ></AdminFormHelp>
                            </div>
                        )}
                </div>
            ) : (
                <div>
                    {[
                        'cryptedweight',
                        'bloodpressure',
                        'cryptedtemperature',
                        'heartbeat',
                    ].indexOf(props.formsType.toLowerCase()) > -1 ? (
                        <dummy>
                            <input
                                key={
                                    'eval-' +
                                    props.formsType.toLowerCase() +
                                    '-order-' +
                                    props.key
                                }
                                type={'hidden'}
                                name={'order'}
                                value={state.document[state.model.order]}
                                onupdate={() => {
                                    actions.setOrder(props.order)
                                }}
                            />
                            {state.label !== '' && (
                                <p class="afevalmakerclinical-label">
                                    {t(state.label)}
                                </p>
                            )}
                            <div class="afevalmakerclinical-block">
                                <p class="">{t('valeur min')}</p>
                                <input
                                    key={
                                        'eval-' +
                                        props.formsType.toLowerCase() +
                                        '-min-' +
                                        props.key
                                    }
                                    type={'text'}
                                    name={'min'}
                                    value={
                                        state.document[state.model.min] ===
                                        undefined
                                            ? ''
                                            : state.document[state.model.min]
                                    }
                                    oninput={actions.onUpdate}
                                    onblur={actions.onUpdate}
                                />
                            </div>
                            <div class="afevalmakerclinical-block">
                                <p class="">{t('valeur max')}</p>
                                <input
                                    key={
                                        'eval-' +
                                        props.formsType.toLowerCase() +
                                        '-max-' +
                                        props.key
                                    }
                                    type={'text'}
                                    name={'max'}
                                    value={
                                        state.document[state.model.max] ===
                                        undefined
                                            ? ''
                                            : state.document[state.model.max]
                                    }
                                    oninput={actions.onUpdate}
                                    onblur={actions.onUpdate}
                                />
                            </div>
                            <div class="afevalmakerclinical-block">
                                <p class="">{t('valeur par défaut')}</p>
                                <input
                                    key={
                                        'eval-' +
                                        props.formsType.toLowerCase() +
                                        '-default-' +
                                        props.key
                                    }
                                    type={'text'}
                                    name={'default'}
                                    value={
                                        state.document[state.model.default] ===
                                        undefined
                                            ? ''
                                            : state.document[
                                                  state.model.default
                                              ]
                                    }
                                    oninput={actions.onUpdate}
                                    onblur={actions.onUpdate}
                                />
                            </div>
                        </dummy>
                    ) : (
                        <dummy>
                            <div
                                class="sup-custom-select-delete"
                                onclick={(el) => {
                                    props.ondelete(el)
                                    if (isDefined(props.onSynchEachLanguage))
                                        props.onSynchEachLanguage({
                                            lang: props.lang,
                                            order: state.document[
                                                state.model.order
                                            ],
                                        })
                                }}
                            >
                                <p class="">{t('Supprimer')}</p>
                            </div>
                            <input
                                key={
                                    'eval-' +
                                    props.formsType.toLowerCase() +
                                    '-order-' +
                                    props.key
                                }
                                type={'hidden'}
                                name={'order'}
                                value={state.document[state.model.order]}
                                onupdate={() => {
                                    actions.setOrder(props.order)
                                }}
                            />
                            <input
                                key={
                                    'eval-' +
                                    props.formsType.toLowerCase() +
                                    '-label-' +
                                    props.key
                                }
                                type={'text'}
                                name={'name'}
                                placeholder={t(
                                    'Saisissez l‘intitulé de la réponse'
                                )}
                                value={
                                    state.document[state.model.key] ===
                                    undefined
                                        ? ''
                                        : state.document[state.model.key]
                                }
                                oninput={actions.onUpdate}
                                onblur={actions.onUpdate}
                            />
                            {props.customer !== 'corpo' && (
                                <input
                                    key={
                                        'eval-' +
                                        props.formsType.toLowerCase() +
                                        '-value-' +
                                        props.key
                                    }
                                    type={'text'}
                                    name={'value'}
                                    value={
                                        state.document[state.model.value] ===
                                        undefined
                                            ? ''
                                            : state.document[state.model.value]
                                    }
                                    oninput={actions.onUpdate}
                                    onblur={actions.onUpdate}
                                />
                            )}
                        </dummy>
                    )}
                    {isDefined(state.document.terminology) &&
                        isDefined(state.document.terminology.systemName) && (
                            <div style="display: inline-block; vertical-align: top;">
                                <AdminFormHelp
                                    infos={
                                        '<b>Nom</b>: ' +
                                        state.document.terminology.systemName +
                                        '<br/><b>Version</b>: ' +
                                        state.document.terminology
                                            .systemVersion +
                                        '<br/><b>Code</b>: ' +
                                        state.document.terminology
                                            .terminologyCode +
                                        '<br/><b>Valeur</b>: ' +
                                        state.document.terminology
                                            .terminologyValue +
                                        '<br/><br/><b>Attribut</b>: ' +
                                        state.document.terminologyAttribute
                                    }
                                    label={'symptome'}
                                ></AdminFormHelp>
                            </div>
                        )}
                </div>
            )}
        </div>
    ),
    'afevalmaker'
)

export { AFEvalMaker }

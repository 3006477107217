const withStorage =
    (opts = {}) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        const serialize = JSON.stringify
        const deserialize = JSON.parse

        const options = {
            ...{
                driver: localStorage,
                name: 'default',
            },
            ...opts,
        }

        const enhancedActions = {
            initStorage: () => (state, actions) => {
                const stored = options.driver.getItem(options.name)

                if (stored) {
                    window.main.restoreStorage(deserialize(stored))
                }

                addEventListener('pushstate', () => window.main.saveStorage())
            },
            saveStorage: () => (state, actions) => {
                options.driver.setItem(options.name, serialize(state))
            },
            restoreStorage: () => (state, actions, stored) => {
                return stored
            },
        }

        if (initialState.stored) {
            addEventListener('pushstate', () => window.main.restoreStorage())
        }

        addEventListener('pushstate', () => window.main.initStorage())

        return nextApp(
            initialState,
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )
    }

export { withStorage }

import { isValidEmail } from '.'

describe('isValidEmail', () => {
    test('should failed when email is not a string', async () => {
        expect(isValidEmail(false)).toBe(false)
        expect(isValidEmail([])).toBe(false)
        expect(isValidEmail({})).toBe(false)
        expect(isValidEmail(undefined)).toBe(false)
        expect(isValidEmail(null)).toBe(false)
        expect(isValidEmail(NaN)).toBe(false)
        expect(isValidEmail(() => {})).toBe(false)
    })

    test('should failed when email is an invlaid string', async () => {
        expect(isValidEmail('dumb')).toBe(false)
        expect(isValidEmail('resilience@@gmail.com')).toBe(false)
        expect(isValidEmail('resilience@nope.fré')).toBe(false)
        expect(isValidEmail('résilience@gmail.com')).toBe(false)
        expect(isValidEmail('re"silience@gmail.com')).toBe(false)
    })

    test('should success when email is valid string', async () => {
        expect(isValidEmail('resilience@gmail.com')).toBe(true)
        expect(isValidEmail('resilience+careteam@gmail.com')).toBe(true)
        expect(isValidEmail('resilience+careteam777@gmail.com')).toBe(true)
    })
})

import { settings } from '@app/core/settings'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

/**
 * Calls API to check email existence
 * @see https://betterise-api-doc.betterise.me/#api-Session-checkEmail
 * @param {string} accountEmail Account email
 * @return Response object
 */
async function checkEmail(accountEmail) {
    return queueQueries(
        `${APP_API_URL}/user/checkemail/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        { email: accountEmail }
    )
}

/**
 * Calls API to get a session token from user email and password
 * @see https://betterise-api-doc.betterise.me/#api-Session-createSession
 * @param {string} accountEmail Account email
 * @param {string} accountPassword Account password
 * @return Response object
 * @example
 * createSession(<email>, <password>).then(res => {
 *   localStorage.setItem("apitoken", `"${res.token}"`);
 *   localStorage.setItem("refresh", `"${res.refresh}"`);
 * }
 */
function createSession(accountEmail, accountPassword) {
    return queueQueries(
        `${APP_API_URL}/admin/session/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        { email: accountEmail, password: accountPassword }
    )
}

function createSessionCorpo(accountEmail, accountPassword) {
    return queueQueries(
        `${APP_API_URL}/admin/session/?corporate=1`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        { email: accountEmail, password: accountPassword }
    )
}

/**
 * Calls API to reset password of an account identified by its email
 * @see https://betterise-api-doc.betterise.me/#api-Session-resetSession
 * @param {string} accountEmail Account email
 * @param {string} [mobileSchemeUrl=''] Mobile app scheme URL
 * @return Response object
 */
async function refreshSession(accountEmail, mobileSchemeUrl = '') {
    return queueQueries(
        `${APP_API_URL}/admin/session/refresh/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        { email: accountEmail, scheme: mobileSchemeUrl }
    )
}

async function resetSession(accountEmail, mobileSchemeUrl = '') {
    return queueQueries(
        `${APP_API_URL}/session/reset/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        { email: accountEmail, scheme: mobileSchemeUrl }
    )
}

async function resetSessionCorpo(accountEmail, mobileSchemeUrl = '') {
    return queueQueries(
        `${APP_API_URL}/session/corporate/reset/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        { email: accountEmail, scheme: mobileSchemeUrl }
    )
}

export const Session = {
    checkEmail,
    createSession,
    createSessionCorpo,
    refreshSession,
    resetSession,
    resetSessionCorpo,
}

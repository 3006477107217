import { h, Component } from '@app/utils'
import './index.scss'

import IndexLayout from '@app/layouts/pages/layout'

const state = {}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout
            page="containers"
            customer={customer}
            currentPage={'alfred'}
        >
            COUCOU CONTAINERS
        </IndexLayout>
    )

export default Component(state, actions, view, 'containers')

import { t } from '@app/utils'
import * as constants from '@app/constants'

/* EXEMPLE */
/*
// [evaluations] document, to send a second form containing others form entries linked with main one by [id]
//  'forms' name is replaced with 'formDatas' with [substitute]
//  'forms', 'formsType' and 'sliderTag' are both send as second form 'forms' with [wrappedIn]
//  'formsType' display a list of <constants.EVAL_TYPES> using [list] and [field] fields
//  'sliderTag' display a dynamic list of <datatags> using [list] [field] and [type] fields
//  'sliderTag' display depends on 'formsType' type using [dependsOn] field
'forms': {
    'name': 'eval',
    'substitute': 'formDatas',
    'type': 'translation',
    'label': 'Détail du formulaire',
    'field': 'eval',
    'model': {},
    'list': 'static',
    'value' : '',
    'validation': {
        'mandatory': true,
        'isValid': false,
        'type': 'atLeastTwoFilled',
        'message': 'Veuillez renseigner au moins deux entrées dans votre évaluation',
        'i18nMessage': t('Veuillez renseigner au moins deux entrées dans votre évaluation'),
    },
    'sendElsewhere': true,
    'wrappedIn': 'forms',
},
'formsType': {
    'name': 'type',
    'type': 'int',
    'label': 'Type',
    'field': 'dropdown',
    'list': constants.EVAL_TYPES,
    'value' : '',
    'validation': {
        'mandatory': true,
        'isValid': false,
        'type': 'selected',
        'message': 'Veuillez sélectionner un type pour votre évaluation',
        'i18nMessage': t('Veuillez sélectionner un type pour votre évaluation'),
    },
    'wrappedIn': 'forms',
},
'sliderTag': {
    'name': 'sliderTag',
    'type': 'datatags',
    'label': 'Data tag',
    'field': 'dropdown',
    'list': 'dynamic',
    'value' : '',
    'placeholder': 'Tapez votre recherche ici ...',
    'validation': {
        'mandatory': true,
        'isValid': false,
        'type': 'selected',
        'message': 'Veuillez sélectionner un dataTag pour votre slider',
        'i18nMessage': t('Veuillez sélectionner un dataTag pour votre slider'),
    },
    'wrappedIn': 'forms',
    'dependsOn': {
        'field': 'formsType',
        'value': 'Slider'
    }
},
 */

/* TABLE LISTING DEFINITION */
/*
'filter': [kind0, kind1, ...], // 'licence', 'customer', 'oc', 'registerStep', 'status' ...
'tableHead': [
    {'key': used for sorting, 'label': label displayed on the header of the table},
    ...
],
'tableOptions': [
    {'key': edit/delete/frequency/status/..., 'label': label displayed on the dropdown entry, 'kind': web/api, 'action': [addCSRFToken('/api/controller/:id')]},
    ...
],
*/

/* FORM DEFINITION */
/*
'form': {
    [field] : {
        'type': not-empty/selected/is-email/is-date/int/atLeastOneChecked/atLeastTwoFilled/atLeastOneFilled // used for verification
        'name': string // [field] name used to send data
        'substitute': string // [field] substitute name to send data // mandatory to [sendElsewhere]
        'label': string // text before input
        'field': string // kind of field, used to display good input
        'choice': {} // used for radio input
        'validation': {
            'mandatory': input is mandatory
            'isValid': input is valid,
            'type': type of validation,
            'message': [error message],
            'i18nMessage': t([error message]), // for translate, i know its ugly
        },
        'deletable': true/false // a little ico will be displayed to permit replace input value with [false]
        'placeholder': string // input placeholder
        'list': {} // used for dropdown and input-to-textarea input //
        'allSelect': true/false // used with [checkbox] to display 'all' checkbox to select all checkbox values
        'wrappedIn': string // field will be moved to [wrappedIn] field instead of beeing send with it own name // used for second form like linked [evaluation] form
        'dependsOn': {
            'field': string // field name
            'value': char // field value, both dermine the [field name] and its [value] required to display [field]
        }
        'display': {
            'mode': half/full // width of input
            'border': bottom, // display a border at bottom, remove the key to remove the border
        },
        'sendElsewhere': true/false // to send in other form (sended first, get the [id] and send the first one with [id])
    }
    ...
}
 */

const card_ADVICE = {
    form: {
        'id': {
            field: 'hidden',
            value: '',
        },
        'template': {
            field: 'hidden',
            value: '',
        },
        'flags': {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        'langs': {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        'type': {
            type: 'int',
            field: 'hidden',
            value: '',
        },
        'status': {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        'customers': {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        'owner': {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        'name': {
            name: 'name',
            type: 'string',
            label: 'Nom de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseigner ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        'title': {
            name: 'title',
            type: 'translation',
            label: 'Titre du contenu',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseigner ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        'description': {
            name: 'description',
            type: 'translation',
            label: 'Corps',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseigner ici le corps de votre contenu (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de la carte',
                i18nMessage: t(
                    'Veuillez renseigner la description de la carte'
                ),
            },
        },
        'ecs': {
            name: 'ecs',
            substitute: 'mappedEcs',
            type: 'translation',
            label: '[[ECS]]',
            field: 'ecs',
            model: { key: null, value: null },
            list: 'static',
            placeholder: 'Texte dynamique',
            value: '',
        },
        'theme': {
            name: 'theme',
            type: 'int',
            label: 'Thématique',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
        },
        'frequency': {
            name: 'frequency',
            type: 'int',
            label: 'Fréquence',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.FREQUENCY,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        'expression': {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        'tags': {
            name: 'tags',
            type: 'tags',
            label: 'Tag Aliment',
            field: 'dyninput-to-div',
            searchbox: true,
            model: { key: 'id', value: 'alias' },
            list: 'dynamic',
            placeholder: {
                input: 'Aucune',
                textarea: '',
            },
            value: '',
        },
        'media': {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
        'rewardsId': {
            name: 'rewardsId',
            type: 'autocomplete/reward',
            label: 'Rewards liés',
            field: 'dyninput-to-div',
            searchbox: true,
            model: { key: 'id', value: 'name' },
            list: 'dynamic',
            placeholder: {
                input: 'Aucun',
                textarea: '',
            },
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneSelected',
                message: 'Veuillez renseigné au moins un reward lié',
                i18nMessage: t('Veuillez renseigné au moins un reward lié'),
            },
        },
        'redirectUrl': {
            name: 'redirectUrl',
            type: 'text',
            label: 'Url de redirection',
            field: 'dropdown',
            list: constants.custo.REDIRECT_URL,
            value: '',
        },
        'evals-form': {
            // for quizz
            name: 'eval',
            substitute: 'formDatas',
            type: 'translation',
            label: 'Détail du formulaire (quizz)',
            field: 'quizz',
            model: {},
            list: 'static',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message:
                    'Veuillez choisir une des réponses aux quizz comme bonne réponse',
                i18nMessage: t(
                    'Veuillez choisir une des réponses aux quizz comme bonne réponse'
                ),
            },
            sendElsewhere: true,
            wrappedIn: 'evals-form',
        },
        'evals-type': {
            name: 'type',
            type: 'int',
            label: 'Type',
            field: 'hidden',
            list: {},
            value: 'Button',
            wrappedIn: 'evals-form',
        },
        'mainThemes': {
            name: 'mainThemes',
            type: 'theme',
            label: 'Thèmes principaux',
            field: 'dropdown-to-div',
            searchbox: false,
            model: { key: 'key', value: 'value' },
            list: constants.custo.CARDS_TYPES,
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneSelected',
                message: 'Veuillez sélectionner au moins un thème principal',
                i18nMessage: t(
                    'Veuillez sélectionner au moins un thème principal'
                ),
            },
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        'birthDate': {
            name: 'birthDate',
            type: 'date',
            label: 'Date de naissance',
            field: 'date',
            mandatory: true,
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
        },
        'steps': {
            // for recipe
            name: 'steps',
            type: 'translation',
            label: 'Étapes de la recette',
            field: 'step',
            value: '',
            placeholder: 'Renseignez ici les ingrédients de votre recette',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneFilled',
                message: 'Veuillez renseigner des étapes pour votre recette',
                i18nMessage: t(
                    'Veuillez renseigner des étapes pour votre recette'
                ),
            },
        },
        'extra': {
            // for recipe
            name: 'extra',
            type: 'translation',
            label: 'Ingrédients extra',
            field: 'extra',
            value: '',
            placeholder: {
                title: 'Titre de l‘extra',
                description: 'Description de l‘extra',
            },
        },
    },
}

export { card_ADVICE }

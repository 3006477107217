import { t, h, Component } from '@app/utils'
import { Settings, Pdfs } from '@app/api'
import moment from 'moment'
import {
    isDefined,
    addCSRFToken,
    allRoutes,
    getYMDfromDateObj,
} from '@app/core'
import {
    AdminFormDownloadDropDown,
    AdminFormMedia,
    AdminFormDropDown,
    Form,
    Button,
    MessagePopup,
    Link,
} from '@app/elements'
import './index.scss'

import * as constants from '@app/constants'

import IndexLayout from '@app/layouts/pages/layout'

import ModalDocument from './modals/modalDocuments'
import ModalConfirm from './modals/modalConfirm'

const state = {
    list: [],
    verifying: false,
    sending: false,
    messageActive: false,
    messageKind: 'success',
    messageContent: '',
    document: {
        media: {
            id: null,
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
            url: '',
        },
    },
    adminMessage: null,
    modalMedicalOpened: false,
    modalMedicalKind: '',
    agreements: {
        doctor: [],
        patient: [],
    },
    previewedDocument: null,
    securitySettings: { sessionDuration: null, passwordRenewalPeriod: null },
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.defineList({ list: constants.custo.CONFIG_DOWNLOADS })
        console.log(
            'constants.custo.CONFIG_DOWNLOADS : ',
            constants.custo,
            constants.custo.CONFIG_DOWNLOADS
        )
        Settings.getAdminMessage().then((res) => {
            if (isDefined(res.data) && !res.data.isError) {
                actions.setAdminMessage(res.data)
            }
        })
        Settings.getAdminSecurityInfos().then((res) => {
            if (isDefined(res.data) && !res.data.isError) {
                actions.setSecuritySettings(res.data)
            }
        })
        actions.updateLegalDocumentsListing()
    },
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
    defineList: (props) => (state, actions) => {
        let list = props.list
        let parsedList = []
        if (
            Object.keys(list)[0] !== undefined &&
            Object.keys(list)[0].name === undefined
        ) {
            for (var o in list) {
                parsedList.push({ name: list[o], id: o })
            }
        }
        list = Object.keys(list).map((i) => list[i])
        if (list !== undefined) {
            actions.setList(list)
        }
    },
    launchKPIVerification: () => (state, actions) => {
        if (state.verifying) {
            return false
        }
        actions.setVerifying(true)
        Settings.verifyKPIS(() => {
            actions.setMessageKind('success')
            actions.setMessageContent(
                t(
                    'La vérification est en cours, vous recevrez un mail lorsqu‘elle sera achevée'
                )
            )
            actions.displaySuccessMessage(true)
        })
    },
    // updateDocument: (el) => (state, actions) => {
    //     if (el.target.name === 'media') {
    //         let mediaDocument = state.document.media;
    //         let newValue = {id: null, value: el.target.value}
    //         mediaDocument.value = newValue;
    //         actions.setState('ns': document, 'key': 'media', 'value': mediaDocument);
    //     }
    // },
    sendDefaultMedia: () => (state, actions) => {
        if (
            state.document.media.id === null &&
            state.document.media.value.value !== undefined
        ) {
            Settings.sendAdminMedia(state.document.media.value.value).then(
                () => {}
            )
        }
    },
    updateAdminSecurityInfos: (field) => (state, actions) => {
        let newSecuritySettings = state.securitySettings
        let securityName = field.target.getAttribute('data-name')
        let securityValue = field.target.getAttribute('data-value')
        newSecuritySettings[securityName] = securityValue
        console.log(
            'SECURITY > updateAdminSecurityInfos : ',
            field,
            newSecuritySettings,
            securityName,
            securityValue
        )

        actions.setSecuritySettings(newSecuritySettings)
    },
    sendAdminSecurityInfos: () => (state, actions) => {
        console.log(
            'SECURITY > sendAdminSecurityInfos : ',
            state.securitySettings
        )
        //
        Settings.updateAdminSecurityInfos(state.securitySettings.id, {
            sessionDuration: state.securitySettings.sessionDuration,
            passwordRenewalPeriod: state.securitySettings.passwordRenewalPeriod,
        }).then(() => {
            actions.setMessageKind('success')
            actions.setMessageContent(t('Modification sauvegardée.'))
        })
    },
    updateLegalDocumentsListing: () => (state, actions) => {
        let agreements = state.agreements
        Pdfs.getAgreements('patient').then((res) => {
            if (isDefined(res.data)) {
                console.log('res.data : ', res.data)
                agreements.patient = res.data.sort(
                    (a, b) => moment(a.date.date) - moment(b.date.date)
                )
                console.log('getAgreements : patient : ', res)
                Pdfs.getAgreements('doctor').then((res) => {
                    agreements.doctor = res.data.sort(
                        (a, b) => moment(a.date.date) - moment(b.date.date)
                    )
                    actions.setAgreements(agreements)
                })
            }
        })
    },
    setPreviewedDocument: (newState) => (state) => ({
        previewedDocument: newState,
    }),
    openModalDocuments: (newState) => (state) => ({
        modalMedicalOpened: newState.opened,
        modalMedicalKind: newState.kind,
    }),
    closeModalDocuments: (newState) => (state) => ({
        modalMedicalOpened: false,
    }),
    closePreviewedDocuments: (newState) => (state) => ({
        previewedDocument: null,
    }),
    setAgreements: (newState) => (state) => ({
        agreements: newState,
    }),
    setList: (newState) => (state) => ({
        list: newState,
    }),
    setAdminMessage: (newState) => (state) => ({
        adminMessage: newState,
    }),
    setSecuritySettings: (newState) => (state) => ({
        securitySettings: newState,
    }),
    setVerifying: (newState) => (state) => ({
        verifying: newState,
    }),
    displaySuccessMessage: (newState) => (state) => ({
        messageActive: newState,
    }),
    setMessageKind: (newState) => (state) => ({
        messageKind: newState,
    }),
    setMessageContent: (newState) => (state) => ({
        messageContent: newState,
    }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) =>
        (
            <IndexLayout
                page="adminconfig"
                customer={customer}
                currentPage={'adminconfig'}
            >
                {state.messageActive && (
                    <MessagePopup
                        kind={state.messageKind}
                        active={'active'}
                        duration={3000}
                        durationCallback={() =>
                            actions.displaySuccessMessage(false)
                        }
                        message={state.messageContent}
                    ></MessagePopup>
                )}
                <Link
                    to={addCSRFToken(
                        allRoutes['private.javascript.errors'].pathname
                    )}
                    class={'btn btn-link'}
                >
                    {t('Accéder aux erreurs javascript')}
                </Link>
                <p class="sup-title" style={{ 'margin-bottom': '35px' }}>
                    {t('Sécurité')}
                </p>
                <Form
                    classes={'col-xs-12 sup-custom-form btzForm'}
                    onsubmit={(event) => event.preventDefault()}
                >
                    <div class="sup-admin-download-entry">
                        <p
                            class="sup-admin-download-entry-label"
                            style={{
                                'vertical-align': 'middle',
                                'margin': 'auto',
                            }}
                        >
                            {t('Durée de la session')}
                        </p>
                        <AdminFormDropDown
                            key={'sessionDuration'}
                            name={'sessionDuration'}
                            document={{
                                name: 'sessionDuration',
                                type: 'string',
                                field: 'text',
                                list: constants.custo.SESSION_DURATION,
                                value: state.securitySettings.sessionDuration,
                            }}
                            list={constants.custo.SESSION_DURATION}
                            onclick={actions.updateAdminSecurityInfos}
                        ></AdminFormDropDown>
                        <p
                            class="sup-admin-download-entry-label"
                            style={{
                                'vertical-align': 'middle',
                                'margin': 'auto',
                            }}
                        >
                            {t('Fréquence de renouvellement de mot de passe')}
                        </p>
                        <AdminFormDropDown
                            key={'passwordRenewalPeriod'}
                            name={'passwordRenewalPeriod'}
                            document={{
                                name: 'passwordRenewalPeriod',
                                type: 'string',
                                field: 'text',
                                list: constants.custo.PASSWORD_RENEWAL_PERIOD,
                                value: state.securitySettings
                                    .passwordRenewalPeriod,
                            }}
                            list={constants.custo.PASSWORD_RENEWAL_PERIOD}
                            onclick={actions.updateAdminSecurityInfos}
                        ></AdminFormDropDown>
                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{
                                'text-align': 'right',
                                'display': 'inline-block',
                                'vertical-align': 'middle',
                                'width': '210px',
                            }}
                        >
                            <Button
                                primary
                                flat
                                onclick={actions.sendAdminSecurityInfos}
                            >
                                {t('Mettre à jour')}
                            </Button>
                        </Form.Group>
                    </div>
                    <p class="sup-title" style={{ margin: '25px 0 35px' }}>
                        {t('Téléchargements')}
                    </p>
                    {state.list.map((l) => (
                        <div class="sup-admin-download-entry">
                            <p class="sup-admin-download-entry-label">
                                {t('Téléchargement ciblé') +
                                    ' ' +
                                    l.label +
                                    ' ' +
                                    '(xlsx)'}
                            </p>
                            <AdminFormDownloadDropDown
                                list={constants.custo.CONFIG_DOWNLOADS_LANGS}
                                helloList={constants.custo.CONFIG_HELLO_CARDS}
                                definition={l}
                            ></AdminFormDownloadDropDown>
                        </div>
                    ))}
                    <div class="sup-admin-download-entry">
                        <p
                            class="sup-admin-download-entry-label"
                            style={{
                                'vertical-align': 'middle',
                                'margin': 'auto',
                            }}
                        >
                            {t('Vérifier les KPI de la base de données')}
                        </p>
                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{
                                'text-align': 'right',
                                'display': 'inline-block',
                                'vertical-align': 'middle',
                                'width': '130px',
                            }}
                        >
                            <Button
                                primary
                                flat
                                active
                                loading={state.verifying}
                                onclick={actions.launchKPIVerification}
                            >
                                {t('Vérifier')}
                            </Button>
                        </Form.Group>
                    </div>
                    <div
                        class="sup-admin-download-entry"
                        style={{ margin: '35px 0 0 0' }}
                    >
                        <p
                            class="sup-title"
                            style={{
                                'margin': '0',
                                'display': 'inline-block',
                                'vertical-align': 'top',
                                'width': '270px',
                            }}
                        >
                            {t('Upload batchs')}
                        </p>
                        <AdminFormDownloadDropDown
                            special={'batch'}
                            btnLabel={'Envoyer'}
                            isActive={true}
                            list={constants.custo.CONFIG_DOWNLOADS_LANGS}
                            helloList={constants.custo.CONFIG_HELLO_CARDS}
                            definition={
                                state.list.filter((f) => f.key === 'cards')[0]
                            }
                        ></AdminFormDownloadDropDown>
                    </div>
                    <div
                        class="sup-admin-download-entry"
                        style={{ margin: '35px 0 0 0' }}
                    >
                        <p
                            class="sup-title"
                            style={{
                                'margin': '0',
                                'display': 'inline-block',
                                'vertical-align': 'top',
                                'width': '270px',
                            }}
                        >
                            {t('Upload patients')}
                        </p>
                        <AdminFormDownloadDropDown
                            special={'patients'}
                            btnLabel={'Envoyer'}
                            isActive={true}
                            list={[]}
                        ></AdminFormDownloadDropDown>
                    </div>
                    <div
                        class="sup-admin-download-entry"
                        style={{ margin: '35px 0 0 0' }}
                    >
                        <p
                            class="sup-title"
                            style={{
                                'margin': '0',
                                'display': 'inline-block',
                                'vertical-align': 'top',
                                'width': '270px',
                            }}
                        >
                            {t('Message admin')}
                        </p>
                        <AdminFormDownloadDropDown
                            special={'adminmessage'}
                            adminMessage={state.adminMessage}
                            btnLabel={'Valider'}
                            isActive={true}
                            list={constants.custo.CONFIG_MESSAGE_TYPES}
                        ></AdminFormDownloadDropDown>
                    </div>
                    <div
                        class="sup-admin-download-entry"
                        style={{ margin: '35px 0 0 0' }}
                    >
                        {/*<p class='sup-title' style={{ 'margin': '0', 'display': 'inline-block', 'vertical-align': 'top', 'width': '270px' }}>{t('Avatar par défaut')}</p>
                <AdminFormMedia document={state.document.media} onclick={actions.updateDocument}></AdminFormMedia>*/}

                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{
                                'text-align': 'right',
                                'display': 'inline-block',
                                'vertical-align': 'middle',
                                'width': '130px',
                            }}
                        >
                            <Button
                                primary
                                flat
                                active
                                loading={state.sending}
                                onclick={actions.sendDefaultMedia}
                            >
                                {t('Envoyer')}
                            </Button>
                        </Form.Group>
                    </div>
                    <div
                        class="sup-admin-download-entry"
                        style={{ margin: '35px 0 0 0' }}
                    >
                        <p
                            class="sup-title"
                            style={{
                                'margin': '0',
                                'display': 'inline-block',
                                'vertical-align': 'top',
                                'width': '270px',
                            }}
                        >
                            {t('PATIENT - Documents réglementaires')}
                        </p>
                        <div class="tableWrapper">
                            <table>
                                <thead>
                                    <tr class="customtable-head">
                                        <th>{t('Date')}</th>
                                        <th>{t('CGU')}</th>
                                        <th>{t('PolConf')}</th>
                                        <th>{t("WHAT'S NEW?")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.agreements.patient.map(
                                        (doc, idx) => (
                                            <tr class="customtable-row">
                                                <td>
                                                    {getYMDfromDateObj(
                                                        new Date(doc.date.date),
                                                        true
                                                    )}
                                                </td>
                                                <td class="green-colored">
                                                    {isDefined(
                                                        state.agreements
                                                            .patient[idx - 1]
                                                    ) &&
                                                    isDefined(
                                                        state.agreements.patient[
                                                            idx - 1
                                                        ].legalDocuments.filter(
                                                            (f) =>
                                                                f.type ===
                                                                    'terms' &&
                                                                f.id ===
                                                                    doc.legalDocuments.filter(
                                                                        (
                                                                            ldoc
                                                                        ) =>
                                                                            ldoc.type ===
                                                                            'terms'
                                                                    )[0].id
                                                        )[0]
                                                    )
                                                        ? '-'
                                                        : doc.legalDocuments.filter(
                                                              (ldoc) =>
                                                                  ldoc.type ===
                                                                  'terms'
                                                          )[0].version}
                                                </td>
                                                <td class="green-colored">
                                                    {isDefined(
                                                        state.agreements
                                                            .patient[idx - 1]
                                                    ) &&
                                                    isDefined(
                                                        state.agreements.patient[
                                                            idx - 1
                                                        ].legalDocuments.filter(
                                                            (f) =>
                                                                f.type ===
                                                                    'privacy' &&
                                                                f.id ===
                                                                    doc.legalDocuments.filter(
                                                                        (
                                                                            ldoc
                                                                        ) =>
                                                                            ldoc.type ===
                                                                            'privacy'
                                                                    )[0].id
                                                        )[0]
                                                    )
                                                        ? '-'
                                                        : doc.legalDocuments.filter(
                                                              (ldoc) =>
                                                                  ldoc.type ===
                                                                  'privacy'
                                                          )[0].version}
                                                </td>
                                                <td
                                                    class="customtable-row-see"
                                                    onclick={() => {
                                                        actions.setPreviewedDocument(
                                                            doc
                                                        )
                                                    }}
                                                >
                                                    {'voir'}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    <tr class="customtable-footer">
                                        <td colspan="4">
                                            <Button
                                                primary
                                                flat
                                                active
                                                onclick={() => {
                                                    actions.openModalDocuments({
                                                        opened: true,
                                                        kind: 'patient',
                                                    })
                                                }}
                                            >
                                                {t(
                                                    'mettre à jour les documents'
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div
                        class="sup-admin-download-entry"
                        style={{ margin: '35px 0 0 0' }}
                    >
                        <p
                            class="sup-title"
                            style={{
                                'margin': '0',
                                'display': 'inline-block',
                                'vertical-align': 'top',
                                'width': '270px',
                            }}
                        >
                            {t('SOIGNANT - Documents réglementaires')}
                        </p>
                        <div class="tableWrapper">
                            <table>
                                <thead>
                                    <tr class="customtable-head">
                                        <th>{t('Date')}</th>
                                        <th>{t('CGU')}</th>
                                        <th>{t('PolConf')}</th>
                                        <th>{t("WHAT'S NEW?")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.agreements.doctor.map((doc, idx) => (
                                        <tr class="customtable-row">
                                            <td>
                                                {getYMDfromDateObj(
                                                    new Date(doc.date.date),
                                                    true
                                                )}
                                            </td>
                                            <td class="green-colored">
                                                {isDefined(
                                                    state.agreements.doctor[
                                                        idx - 1
                                                    ]
                                                ) &&
                                                isDefined(
                                                    state.agreements.doctor[
                                                        idx - 1
                                                    ].legalDocuments.filter(
                                                        (f) =>
                                                            f.type ===
                                                                'terms' &&
                                                            f.id ===
                                                                doc.legalDocuments.filter(
                                                                    (ldoc) =>
                                                                        ldoc.type ===
                                                                        'terms'
                                                                )[0].id
                                                    )[0]
                                                )
                                                    ? '-'
                                                    : doc.legalDocuments.filter(
                                                          (ldoc) =>
                                                              ldoc.type ===
                                                              'terms'
                                                      )[0].version}
                                            </td>
                                            <td class="green-colored">
                                                {isDefined(
                                                    state.agreements.doctor[
                                                        idx - 1
                                                    ]
                                                ) &&
                                                isDefined(
                                                    state.agreements.doctor[
                                                        idx - 1
                                                    ].legalDocuments.filter(
                                                        (f) =>
                                                            f.type ===
                                                                'privacy' &&
                                                            f.id ===
                                                                doc.legalDocuments.filter(
                                                                    (ldoc) =>
                                                                        ldoc.type ===
                                                                        'privacy'
                                                                )[0].id
                                                    )[0]
                                                )
                                                    ? '-'
                                                    : doc.legalDocuments.filter(
                                                          (ldoc) =>
                                                              ldoc.type ===
                                                              'privacy'
                                                      )[0].version}
                                            </td>
                                            <td
                                                class="customtable-row-see"
                                                onclick={() => {
                                                    actions.setPreviewedDocument(
                                                        doc
                                                    )
                                                }}
                                            >
                                                {'voir'}
                                            </td>
                                        </tr>
                                    ))}
                                    <tr class="customtable-footer">
                                        <td colspan="4">
                                            <Button
                                                primary
                                                flat
                                                active
                                                onclick={() => {
                                                    actions.openModalDocuments({
                                                        opened: true,
                                                        kind: 'doctor',
                                                    })
                                                }}
                                            >
                                                {t(
                                                    'mettre à jour les documents'
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {state.modalMedicalOpened && (
                        <ModalDocument
                            {...state}
                            lastAgreement={
                                state.agreements[state.modalMedicalKind][
                                    state.agreements[state.modalMedicalKind]
                                        .length - 1
                                ]
                            }
                            closeModalDocuments={actions.closeModalDocuments}
                            updateLegalDocumentsListing={
                                actions.updateLegalDocumentsListing
                            }
                        ></ModalDocument>
                    )}
                    {isDefined(state.previewedDocument) && (
                        <ModalConfirm
                            {...state}
                            previewedDocument={state.previewedDocument}
                            closeModal={actions.closePreviewedDocuments}
                            mode={'preview'}
                        ></ModalConfirm>
                    )}
                </Form>
            </IndexLayout>
        )

export default Component(state, actions, view, 'adminconfiguration')

import { isValidPhone } from '.'

// require('@app/__tests__/setup')

describe('isValidPhone', () => {
    test('should failed when phone is invalid and prefix undefined', async () => {
        expect(isValidPhone(false)).toBe(false)
    })

    test('should failed when phone is undefined and prefix is invalid', async () => {
        expect(isValidPhone(undefined, false)).toBe(false)
    })

    test('should success when phone and prefix are valid', async () => {
        expect(isValidPhone('0644940583', '+33')).toBe(true)
    })

    test('should fail when phone and prefix are valid (+49)', async () => {
        expect(isValidPhone('1707654321', '+49')).toBe(true)
    })

    test('should fail when phone and prefix are valid (+31)', async () => {
        expect(isValidPhone('613611111', '+31')).toBe(true)
    })

    test('should fail when phone is invalid and prefix is valid', async () => {
        expect(isValidPhone('06449405865', '+33')).toBe(false)
    })
})

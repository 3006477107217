import { t, h } from '@app/utils'
import { Button } from '@app/elements'
import './index.scss'

export const ModalViewConfirm = (props, actions) => (
    <div class="btzModalConfirm">
        <p class="btzTitle btzXl">{props.title}</p>
        <p class="btzSubTitle">{props.description}</p>

        <Button
            primary
            active
            onclick={() => {
                actions.updateParentProps(props)
            }}
        >
            {t('Confirmer')}
        </Button>

        <Button
            primary
            onclick={() => {
                actions.closeModal()
            }}
        >
            {t('Annuler')}
        </Button>
    </div>
)

import { t, h, Component } from '@app/utils'
import { Pdfs, Card } from '@app/api'
import {
    isDefined,
    addCSRFToken,
    allRoutes,
    getYMDfromDateObj,
} from '@app/core'
import {
    AdminFormDownloadDropDown,
    AdminFormMedia,
    AdminFormRadio,
    Form,
    Button,
    MessagePopup,
    Link,
} from '@app/elements'
import './index.scss'

import * as constants from '@app/constants'

import ModalConfirm from './../modalConfirm'

const state = {
    modalMedicalKind: '',
    activeCGU: 0,
    enableCGU: false,
    activePDC: 0,
    enablePDC: false,
    datas: {},
    lastAgreement: { terms: null, privacy: null },
    errorMessage: '',
    messageActive: false,
    messageKind: 'success',
    messageContent: '',
    corruptedFile: [
        {
            name: 'terms',
            isCorrupted: false,
            corruptionMessage:
                'Le fichier CGU est corrompu, veuillez le ré-uploader.',
        },
        {
            name: 'privacy',
            isCorrupted: false,
            corruptionMessage:
                'Le fichier PDC est corrompu, veuillez le ré-uploader.',
        },
    ],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let datas = {
            cgu: {
                type: 'terms',
                version: '',
                media: '',
            },
            pdc: {
                type: 'privacy',
                version: '',
                media: '',
            },
            title: '',
            description: '',
        }
        actions.setDatas(datas)
        //
        if (isDefined(props.modalMedicalKind)) {
            actions.setModalMedicalKind(props.modalMedicalKind)
        }
        if (isDefined(props.closeModalDocuments)) {
            actions.setCloseModalDocuments(props.closeModalDocuments)
        }
        if (isDefined(props.lastAgreement)) {
            let lastAgreement = {}
            if (
                isDefined(
                    props.lastAgreement.legalDocuments.filter(
                        (f) => f.type === 'terms'
                    )[0]
                )
            ) {
                lastAgreement.terms = props.lastAgreement.legalDocuments.filter(
                    (f) => f.type === 'terms'
                )[0]
            }
            if (
                isDefined(
                    props.lastAgreement.legalDocuments.filter(
                        (f) => f.type === 'privacy'
                    )[0]
                )
            ) {
                lastAgreement.privacy =
                    props.lastAgreement.legalDocuments.filter(
                        (f) => f.type === 'privacy'
                    )[0]
            }
            actions.setLastAgreement(lastAgreement)
        }
        if (isDefined(props.updateLegalDocumentsListing)) {
            actions.setUpdateLegalDocumentsListing(
                props.updateLegalDocumentsListing
            )
        }
        //
        Pdfs.getLegalDocument().then((res) => {
            if (isDefined(res.data)) {
                if (isDefined(res.data[props.modalMedicalKind])) {
                    let types = res.data[props.modalMedicalKind].types
                    if (isDefined(types.filter((f) => f === 'terms')[0])) {
                        actions.enabledCGU(true)
                    }
                    if (isDefined(types.filter((f) => f === 'privacy')[0])) {
                        actions.enabledPDC(true)
                    }
                }
            }
        })
    },
    updateFields: (field) => (state, actions) => {
        let value = field.target.value,
            names = field.target.name,
            name = ''
        if (names.indexOf('-') > -1) {
            name = names.split('-')
        } else {
            name = names
        }
        //pattern="[0-9]+.[0-9]+.[0-9]+"
        let datas = state.datas,
            datasObj = null
        if (name[0] === 'cgu') {
            datasObj = datas.cgu
            if (name[1] === 'activeCGU') {
                actions.activateCGU(value)
            }
        } else if (name[0] === 'pdc') {
            datasObj = datas.pdc
            if (name[1] === 'activePDC') {
                actions.activatePDC(value)
            }
        } else {
            datasObj = datas
        }
        //
        if (isDefined(datasObj[name])) {
            datasObj[name] = value
        } else if (isDefined(datasObj[name[1]])) {
            datasObj[name[1]] = value
        }
        actions.setErrorMessage('')
        actions.setDatas(datas)
    },
    convertImageToB64: (el) => (state, actions) => {
        var f = el.target.files[0]
        let inputName = el.target.getAttribute('name').split('-')
        var reader = new FileReader()
        reader.addEventListener(
            'load',
            function () {
                let datas = state.datas
                if (inputName[0] === 'cgu') {
                    datas.cgu.media = f
                } else if (inputName[0] === 'pdc') {
                    datas.pdc.media = f
                }
                actions.setDatas(datas)
            },
            false
        )
        reader.addEventListener('loadstart', function () {
            actions.setMessageKind('success')
            actions.setMessageContent(
                t(`Le chargement du fichier ${inputName[0]} a commencé.`)
            )
            actions.displaySuccessMessage(true)
        })
        reader.addEventListener('loadend', function (event) {
            actions.setMessageKind('success')
            actions.setMessageContent(
                t(
                    `Le fichier ${inputName[0]} a bien été chargé. (${event.loaded} bits transférés )`
                )
            )
            actions.displaySuccessMessage(true)
            //
            if (event.loaded > 0) {
                const corruptedObjects = [...state.corruptedFile]
                const corruptedObject = (corruptedObjects || []).find(
                    (file) => file.name === inputName[0]
                )
                if (corruptedObject) {
                    corruptedObject.isCorrupted = false
                }
                actions.isCorruptedFile(corruptedObject)
            }
        })
        reader.addEventListener('error', function () {
            actions.setMessageKind('error')
            actions.setMessageContent(
                t(`Le chargement du fichier ${inputName[0]} a échoué.`)
            )
            actions.displaySuccessMessage(true)
            //
            const corruptedObjects = [...state.corruptedFile]
            const corruptedObject = (corruptedObjects || []).find(
                (file) => file.name === inputName[0]
            )
            if (corruptedObject) {
                corruptedObject.isCorrupted = true
            }
            actions.isCorruptedFile(corruptedObject)
        })
        reader.readAsDataURL(f)
    },
    confirmSendFields: () => (state, actions) => {
        const corruptedFile = (state.corruptedObject || []).find(
            (legal) => legal.isCorrupted
        )
        if (corruptedFile) {
            actions.setErrorMessage(corruptedFile.corruptionMessage)
            return false
        }
        //
        let datas = state.datas
        if (
            datas.title === '' ||
            datas.description === '' ||
            (+state.activeCGU !== 1 && +state.activePDC !== 1)
        ) {
            if (+state.activeCGU !== 1 && +state.activePDC !== 1) {
                actions.setErrorMessage(
                    t("Veuillez activer au moins l'un des deux documents.")
                )
            } else {
                actions.setErrorMessage(
                    t('Veuillez renseigner un titre et une description.')
                )
            }
            return false
        }
        if (+state.activeCGU === 1) {
            if (datas.cgu.version === '' || datas.cgu.media === '') {
                actions.setErrorMessage(
                    t(
                        'Veuillez renseigner une version et un media pour les CGU.'
                    )
                )
                return false
            } else if (
                datas.cgu.version.match(/[0-9]+.[0-9]+.[0-9]+/) === null
            ) {
                actions.setErrorMessage(
                    t(
                        'Veuillez renseigner une version au format X.Y.Z pour le CGU.'
                    )
                )
                return false
            } else {
                if (datas.cgu.media.size >= 14000000) {
                    actions.setErrorMessage(
                        t(
                            "Veuillez renseigner un fichier CGU d'une taille inférieure à 14 megaoctets."
                        )
                    )
                    return false
                }
            }
        } else {
            let existingCGU = state.lastAgreement.terms
            if (isDefined(existingCGU)) {
                //
            } else {
                actions.setErrorMessage(
                    t(
                        "Aucune CGU n'existe, veuillez activer le document et le renseigner."
                    )
                )
                return false
            }
        }
        if (+state.activePDC === 1) {
            if (datas.pdc.version === '' || datas.pdc.media === '') {
                actions.setErrorMessage(
                    t(
                        'Veuillez renseigner une version et un media pour les PDC.'
                    )
                )
                return false
            } else if (
                datas.pdc.version.match(/[0-9]+.[0-9]+.[0-9]+/) === null
            ) {
                actions.setErrorMessage(
                    t(
                        'Veuillez renseigner une version au format X.Y.Z pour les PDC.'
                    )
                )
                return false
            } else {
                if (datas.pdc.media.size >= 14000000) {
                    actions.setErrorMessage(
                        t(
                            "Veuillez renseigner un fichier PDC d'une taille inférieure à 14 megaoctets."
                        )
                    )
                    return false
                }
            }
        } else {
            let existingPDC = state.lastAgreement.privacy
            if (isDefined(existingPDC)) {
                //
            } else {
                actions.setErrorMessage(
                    t(
                        "Aucune PDC n'existe, veuillez activer le document et le renseigner."
                    )
                )
                return false
            }
        }
        //
        let legalDescription = state.datas.description,
            regExp = /\[\[\s*(\w+)\s*]]/g
        let foundTags = legalDescription.match(regExp),
            foundTag = '',
            legitTags = []
        if (state.enableCGU) {
            legitTags.push('terms')
        }
        if (state.enablePDC) {
            legitTags.push('privacy')
        }
        for (let fT in foundTags) {
            foundTag = foundTags[fT].replace(/\[|]| /g, '')
            if (legitTags.indexOf(foundTag) === -1) {
                actions.setErrorMessage(
                    t('Vous ne pouvez utiliser que les tags') +
                        ' ' +
                        legitTags.join(', ') +
                        '.'
                )
                return false
            }
        }
        //
        actions.openModalConfirm(true)
    },
    sendFields: () => (state, actions) => {
        let datas = state.datas,
            medias = []
        if (+state.activeCGU === 1) {
            medias.push({ type: 'cgu', media: datas.cgu.media })
        }
        if (+state.activePDC === 1) {
            medias.push({ type: 'pdc', media: datas.pdc.media })
        }
        //
        let nMedia = medias.length
        if (nMedia > 0) {
            for (let md in medias) {
                Card.sendMedia(medias[md].media).then((res) => {
                    datas[medias[md].type].media = res.data.id
                    nMedia--
                    if (nMedia === 0) {
                        actions.sendLegalDocuments(datas)
                    }
                })
            }
        } else {
            actions.sendLegalDocuments(datas)
        }
    },
    sendLegalDocuments: (datas) => (state, actions) => {
        let legalDocuments = [],
            idsLegalDocuments = []
        if (+state.activeCGU === 1) {
            legalDocuments.push(datas.cgu)
        } else {
            idsLegalDocuments.push(state.lastAgreement.terms.id)
        }
        if (+state.activePDC === 1) {
            legalDocuments.push(datas.pdc)
        } else {
            idsLegalDocuments.push(state.lastAgreement.privacy.id)
        }
        //
        let nLDoc = legalDocuments.length
        for (let ldoc in legalDocuments) {
            Pdfs.createLegalDocument(legalDocuments[ldoc]).then((res) => {
                idsLegalDocuments.push(res.data.id)
                nLDoc--
                if (nLDoc === 0) {
                    actions.sendLegalAgreement(idsLegalDocuments)
                }
            })
        }
    },
    sendLegalAgreement: (idsLegalDocuments) => (state, actions) => {
        let legalAgreement = {
            userType: state.modalMedicalKind,
            legalDocuments: idsLegalDocuments,
            title: state.datas.title,
            description: state.datas.description,
        }
        Pdfs.createLegalAgreement(legalAgreement).then((res) => {
            state.updateLegalDocumentsListing()
            state.closeModalDocuments()
        })
    },
    setUpdateLegalDocumentsListing: (newState) => (state) => ({
        updateLegalDocumentsListing: newState,
    }),
    setCloseModalDocuments: (newState) => (state) => ({
        closeModalDocuments: newState,
    }),
    setModalMedicalKind: (newState) => (state) => ({
        modalMedicalKind: newState,
    }),
    setLastAgreement: (newState) => (state) => ({
        lastAgreement: newState,
    }),
    activateCGU: (newState) => (state) => ({
        activeCGU: newState,
    }),
    activatePDC: (newState) => (state) => ({
        activePDC: newState,
    }),
    enabledCGU: (newState) => (state) => ({
        enableCGU: newState,
    }),
    enabledPDC: (newState) => (state) => ({
        enablePDC: newState,
    }),
    setDatas: (newState) => (state) => ({
        datas: newState,
    }),
    setErrorMessage: (newState) => (state) => ({
        errorMessage: newState,
    }),
    openModalConfirm: (newState) => (state) => ({
        modalConfirm: newState,
    }),
    closeModalConfirm: (newState) => (state) => ({
        modalConfirm: false,
    }),
    isCorruptedFile: (newState) => (state) => ({
        corruptedFile: newState,
    }),
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
    displaySuccessMessage: (newState) => (state) => ({
        messageActive: newState,
    }),
    setMessageKind: (newState) => (state) => ({
        messageKind: newState,
    }),
    setMessageContent: (newState) => (state) => ({
        messageContent: newState,
    }),
}

const view = (state, actions) => (props, children) =>
    (
        <div class="modalWrapper">
            {state.messageActive && (
                <MessagePopup
                    kind={state.messageKind}
                    active={'active'}
                    duration={3000}
                    durationCallback={() =>
                        actions.displaySuccessMessage(false)
                    }
                    message={state.messageContent}
                ></MessagePopup>
            )}
            <p class="modalTitle">
                {state.modalMedicalKind.toUpperCase() +
                    ' - ' +
                    t('Quel(s) document(s) à mettre à jour ?')}
            </p>
            <p class="modalSubTitle">
                {t(
                    'Choisissez le ou les document(s) à mettre à jour, uploadez le document puis rédigez un message à destination des utilisateurs afin de leur faire comprendre simplement les mises à jour notables.'
                )}
            </p>
            <div class="btzAdminForm">
                <Form
                    classes={'col-xs-12 sup-custom-form btzForm'}
                    onsubmit={(event) => event.preventDefault()}
                    autocomplete="off"
                >
                    <Form.Group
                        classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                        style={{ 'text-align': 'right' }}
                    >
                        {state.enableCGU && (
                            <div
                                class="sup-group-form"
                                style="display: inline-block; width: 49%;vertical-align: top;text-align: center;"
                            >
                                <p class="modalFormTitle">
                                    {t("Conditions générales d'utilisation")}
                                </p>
                                <AdminFormRadio
                                    name={'cgu-activeCGU'}
                                    document={{
                                        name: 'activeCGU',
                                        value: state.activeCGU,
                                        choice: { oui: 1, non: 0 },
                                    }}
                                    onclick={actions.updateFields}
                                ></AdminFormRadio>
                                {+state.activeCGU !== 1 &&
                                    isDefined(state.lastAgreement.terms) && (
                                        <p class="modalFormSubTitle">
                                            {t('Version') +
                                                ' ' +
                                                state.lastAgreement.terms
                                                    .version +
                                                ' ' +
                                                t('en ligne depuis le') +
                                                ' ' +
                                                getYMDfromDateObj(
                                                    new Date(
                                                        state.lastAgreement.terms.date.date
                                                    ),
                                                    true
                                                )}
                                        </p>
                                    )}
                                {+state.activeCGU === 1 && (
                                    <dummy style="display: block;margin: 20px 0 0;text-align: left;">
                                        <p class="">
                                            {t('Numéro de version (ex: 1.0.0)')}
                                        </p>
                                        <input
                                            key={'cgu-version'}
                                            type={'int'}
                                            name={'cgu-version'}
                                            value={state.datas.cgu.version}
                                            oninput={actions.updateFields}
                                            style="margin-left: 15px;"
                                        />
                                        <input
                                            type="file"
                                            id="cgu-media"
                                            name="cgu-media"
                                            accept=".pdf"
                                            onchange={(el) =>
                                                actions.convertImageToB64(el)
                                            }
                                        />
                                    </dummy>
                                )}
                            </div>
                        )}
                        {state.enablePDC && (
                            <div
                                class="sup-group-form"
                                style="display: inline-block; width: 49%;vertical-align: top;text-align: center;"
                            >
                                <p class="modalFormTitle">
                                    {t('Politique de confidentialité')}
                                </p>
                                <AdminFormRadio
                                    name={'pdc-activePDC'}
                                    document={{
                                        name: 'activeCGU',
                                        value: state.activePDC,
                                        choice: { oui: 1, non: 0 },
                                    }}
                                    onclick={actions.updateFields}
                                ></AdminFormRadio>
                                {+state.activePDC !== 1 &&
                                    isDefined(state.lastAgreement.privacy) && (
                                        <p class="modalFormSubTitle">
                                            {t('Version') +
                                                ' ' +
                                                state.lastAgreement.privacy
                                                    .version +
                                                ' ' +
                                                t('en ligne depuis le') +
                                                ' ' +
                                                getYMDfromDateObj(
                                                    new Date(
                                                        state.lastAgreement.privacy.date.date
                                                    ),
                                                    true
                                                )}
                                        </p>
                                    )}
                                {+state.activePDC === 1 && (
                                    <dummy style="display: block;margin: 20px 0 0;text-align: left;">
                                        <p class="">
                                            {t('Numéro de version (ex: 1.0.0)')}
                                        </p>
                                        <input
                                            key={'pdc-version'}
                                            type={'int'}
                                            name={'pdc-version'}
                                            value={state.datas.pdc.version}
                                            oninput={actions.updateFields}
                                            style="margin-left: 15px;"
                                        />
                                        <input
                                            type="file"
                                            id="pdc-media"
                                            name="pdc-media"
                                            accept=".pdf"
                                            onchange={(el) =>
                                                actions.convertImageToB64(el)
                                            }
                                        />
                                    </dummy>
                                )}
                            </div>
                        )}

                        <div class="" style="margin-bottom: 25px;">
                            {state.enableCGU && (
                                <p
                                    class=""
                                    style="margin: 0;text-align: left;font-size: 11px;"
                                >
                                    {t(
                                        "Ajouter un liens vers les Conditions générales d'utilisation en insérant le tag"
                                    ) + ' '}
                                    <b>{'[[ terms ]]'}</b>
                                    {' ' + t('dans la description.')}
                                </p>
                            )}
                            {state.enablePDC && (
                                <p
                                    class=""
                                    style="margin: 0;text-align: left;font-size: 11px;"
                                >
                                    {t(
                                        'Ajouter un liens vers la Politique de confidentialité en insérant le tag'
                                    ) + ' '}
                                    <b>{'[[ privacy ]]'}</b>
                                    {' ' + t('dans la description.')}
                                </p>
                            )}
                        </div>

                        <div class="sup-group-form sup-group-form-bottom">
                            <p class="">{t("What's new? (obligatoire)")}</p>
                            <input
                                key={'title'}
                                type={'string'}
                                name={'title'}
                                placeholder={t('Titre')}
                                value={state.datas.title}
                                oninput={actions.updateFields}
                            />
                            <textarea
                                rows="10"
                                key={'textarea'}
                                name={'description'}
                                class={'input-text'}
                                placeholder={t('Description')}
                                oninput={actions.updateFields}
                            >
                                {state.datas.description}
                            </textarea>
                        </div>
                        <div class="">
                            <p class="" style="margin: 0px;">
                                {t("Les Conditions Générales d'Utilisation") +
                                    ' ' +
                                    (+state.activeCGU === 1
                                        ? t('vont être mises à jour')
                                        : t('ne vont pas être mises à jour'))}
                            </p>
                            <p class="" style="margin: 0px;">
                                {t('La politique de Confidentialité') +
                                    ' ' +
                                    (+state.activePDC === 1
                                        ? t('va être mise à jour')
                                        : t('ne va pas être mise à jour'))}
                            </p>
                        </div>
                        <div class="modalFooter">
                            <p class="modalErrorMessage">
                                {state.errorMessage}
                            </p>
                            <Button
                                primary
                                flat
                                cancel
                                onclick={() => {
                                    state.closeModalDocuments()
                                }}
                            >
                                {t('annuler')}
                            </Button>
                            <Button
                                primary
                                flat
                                active
                                onclick={() => {
                                    actions.confirmSendFields()
                                }}
                            >
                                {t('mettre à jour les documents')}
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>
            {state.modalConfirm && (
                <ModalConfirm
                    {...state}
                    previewedDocument={state.datas}
                    closeModal={actions.closeModalConfirm}
                    sendFields={actions.sendFields}
                    mode={'confirm'}
                ></ModalConfirm>
            )}
        </div>
    )

export default Component(state, actions, view, 'modaldocuments')

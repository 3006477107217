import { t, h, Component } from '@app/utils'
import { Form, AdminFormRadio, AdminFormMedia } from '@app/elements'
import { getYMDfromDateObj } from '@app/core'
import './../AdminForm/index.scss'
import './index.scss'
import * as constants from '@app/constants'

import icoTeam from '@app/img/default/def-team.png'
import icoTeamx2 from '@app/img/default/def-team@2x.png'

const AdminResults = Component(
    {
        document: null,
        results: null,
        originalResults: null,
        target: null,
        formIsSubmitting: false,
        ticksMode: 'normal',
        refDate: '20190429',
        mode: 'week',
        particularKind: null,
        loadingData: true,
        tournamentsType: constants.custo.CHALLENGE_TOURNAMENT_TYPES,
        tournamentUnit: '',
        tournamentStatus: 'running', // running / ended / incoming
        tournamentDateStart: null,
        tournamentDateEnd: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            //
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
            if (props.results) {
                actions.setOriginalResults(props.results)
            }
            if (props.target) {
                actions.setTarget(props.target)
            }
            if (props.particularKind) {
                actions.setParticularKind(props.particularKind)
            }

            actions.getResults()
        },
        getResults: () => (state, actions) => {
            state.target(state.document.id.value).then((res) => {
                let newdataType = ''
                for (var type in state.tournamentsType) {
                    if (state.tournamentsType[type].key === res.data.type) {
                        newdataType = state.tournamentsType[type].value
                        state.tournamentUnit = state.tournamentsType[type].unit
                        //
                        state.tournamentDateStart = getYMDfromDateObj(
                            new Date(res.data.startDate.date),
                            true
                        )
                        state.tournamentDateEnd = getYMDfromDateObj(
                            new Date(res.data.endDate.date),
                            true
                        )
                        //
                        let today = getYMDfromDateObj(false, true, true)
                        let start = getYMDfromDateObj(
                            new Date(res.data.startDate.date),
                            true,
                            true
                        )
                        let end = getYMDfromDateObj(
                            new Date(res.data.endDate.date),
                            true,
                            true
                        )
                        if (
                            today.replace(/\//g, '') < +start.replace(/\//g, '')
                        ) {
                            state.tournamentStatus = 'incoming'
                        } else if (
                            +end.replace(/\//g, '') < today.replace(/\//g, '')
                        ) {
                            state.tournamentStatus = 'ended'
                        } else {
                            state.tournamentStatus = 'running'
                        }
                    }
                }
                res.data.type = newdataType
                actions.setResults(res.data)
            })
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setOriginalResults: (newState) => (state) => ({
            originalResults: newState,
        }),
        setResults: (newState) => (state) => ({
            results: newState,
        }),
        setSubmitting: (newState) => (state) => ({
            formIsSubmitting: newState,
        }),
        setTarget: (newState) => (state) => ({
            target: newState,
        }),
        setParticularKind: (newState) => (state) => ({
            particularKind: newState,
        }),
        setLoadingData: (newState) => (state) => ({
            loadingData: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class={'btzAdminForm btzAdminResults'}>
            {state.results !== null && (
                <Form
                    classes={'col-xs-12 sup-custom-form btzForm'}
                    onsubmit={(event) => event.preventDefault()}
                >
                    <div key={'main-form'}>
                        <div class="col-12 sup-group-form">
                            {state.tournamentStatus === 'ended' && (
                                <label class="sup-label">
                                    {t('Challenge terminé')}
                                </label>
                            )}
                            {state.tournamentStatus === 'running' && (
                                <label class="sup-label">
                                    {t('Challenge en cours')}
                                </label>
                            )}
                            {state.tournamentStatus === 'incoming' && (
                                <label class="sup-label">
                                    {t('Challenge à venir')}
                                </label>
                            )}
                            <label class="sup-label">
                                {state.results.name}
                            </label>
                        </div>

                        <div class="col-12 sup-group-form">
                            <div
                                style={{
                                    'display': 'inline-block',
                                    'vertical-align': 'top',
                                }}
                            >
                                <label class="sup-label">{t('Période')}</label>
                            </div>
                            <p class="sup-label">
                                {t('du') + ' '}
                                <b>{state.tournamentDateStart}</b>
                                {' ' + t('au') + ' '}
                                <b>{state.tournamentDateEnd}</b>
                            </p>
                        </div>

                        <div class="col-6 col-xs-12 sup-group-form">
                            <div
                                style={{
                                    'display': 'inline-block',
                                    'vertical-align': 'top',
                                }}
                            >
                                <label class="sup-label">
                                    {t('Activation')}
                                </label>
                            </div>
                            <AdminFormRadio
                                name={'status'}
                                document={state.document.status}
                                desabled={'desabled'}
                            ></AdminFormRadio>
                        </div>

                        <div class="col-12 sup-group-form">
                            <AdminFormMedia
                                document={state.results.media}
                                desabled={'desabled'}
                            ></AdminFormMedia>
                            <div class="sup-group-form-title-wrapper">
                                <p class="sup-label">
                                    <b>{state.results.title}</b>
                                    {' - ' + state.results.type}
                                </p>
                                <p class="sup-label sup-label-description">
                                    {state.results.description}
                                </p>
                            </div>
                        </div>

                        <div class="col-12 sup-group-form">
                            <div
                                style={{
                                    'display': 'inline-block',
                                    'vertical-align': 'top',
                                }}
                            >
                                <label class="sup-label">
                                    {t('Équipe gagnante')}
                                </label>
                            </div>
                            <p class="sup-label">
                                {state.results.winner !== null &&
                                state.results.winner !== '' ? (
                                    <font>
                                        {
                                            state.results.teams.filter(
                                                (f) =>
                                                    f.id ===
                                                    state.results.winner
                                            )[0].label
                                        }
                                    </font>
                                ) : (
                                    <i>
                                        {state.tournamentStatus === 'ended' &&
                                            t('Challenge terminé')}
                                        {state.tournamentStatus === 'running' &&
                                            t('Challenge en cours')}
                                        {state.tournamentStatus ===
                                            'incoming' &&
                                            t('Challenge à venir')}
                                    </i>
                                )}
                            </p>
                        </div>

                        <div class="col-12 sup-group-form">
                            <div
                                style={{
                                    'display': 'inline-block',
                                    'vertical-align': 'top',
                                }}
                            >
                                {state.tournamentStatus === 'ended' && (
                                    <label class="sup-label">
                                        {t('Classement définitif par équipes')}
                                    </label>
                                )}
                                {state.tournamentStatus === 'running' && (
                                    <label class="sup-label">
                                        {t('Classement provisoire par équipes')}
                                    </label>
                                )}
                                {state.tournamentStatus === 'incoming' && (
                                    <label class="sup-label">
                                        {t(
                                            'Le challenge n‘a pas encore commencé'
                                        )}
                                    </label>
                                )}
                            </div>
                            <div class="adminresults-teams">
                                {state.results.teams.sort((a, b) =>
                                    +a.profilValueTeam > +b.profilValueTeam
                                        ? 1
                                        : -1
                                ).length === 0 ? (
                                    <dummy>
                                        <p class="sup-label">
                                            <i>
                                                {t(
                                                    'Aucune équipe n‘a participé à ce challenge'
                                                )}
                                            </i>
                                        </p>
                                    </dummy>
                                ) : (
                                    <dummy>
                                        {state.results.teams
                                            .sort((a, b) =>
                                                +a.profilValueTeam >
                                                +b.profilValueTeam
                                                    ? 1
                                                    : -1
                                            )
                                            .map((m, idx) => (
                                                <div class="adminresults-team">
                                                    <p class="adminresults-team-index">
                                                        {'#' + (+idx + 1)}
                                                    </p>
                                                    <div class="adminresults-team-media">
                                                        {m.media !== null ? (
                                                            <img
                                                                src={
                                                                    m.media.url
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                src={icoTeam}
                                                                srcset={`${icoTeamx2} 2x`}
                                                            />
                                                        )}
                                                    </div>
                                                    <div class="adminresults-team-infos">
                                                        <p class="adminresults-team-info-label">
                                                            {m.label}
                                                        </p>
                                                        <p class="adminresults-team-info-points">
                                                            {m.profilValueTeam +
                                                                ' ' +
                                                                t(
                                                                    state.tournamentUnit
                                                                )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                    </dummy>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    ),
    'adminresults'
)

export { AdminResults }

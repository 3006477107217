import { h, Component } from '@app/utils'
import { isDefined, castToBoolean } from '@app/core'
import './index.scss'

const AFRadio = Component(
    {
        name: '',
        readonly: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.name) {
                state.name = props.name
            }
            console.log('parseAndSetDocument RADIO : ', props)
            // actions.onComponentUpdate(props);
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
    },
    (state, actions) => (props, children) =>
        (
            <div
                key={'sup-group-radio-' + props.lang + '-' + props.goodResponse}
                class={
                    'sup-group-radio length-' +
                    props.goodResponse +
                    ' sup-group-radio-' +
                    props.desabled
                }
            >
                <label
                    key={
                        'radiocontrol-' + props.lang + '-' + props.goodResponse
                    }
                    class="control radio"
                >
                    {(props.desabled && props.desabled === 'desabled') ||
                    props.readonly ? (
                        <input
                            key={
                                'radio-desabled-' +
                                props.lang +
                                '-' +
                                props.goodResponse
                            }
                            type="radio"
                            name={state.name}
                            id=""
                            class="to-check check-checked is-retina desabled"
                            value={props.choice[props.document]}
                            checked={
                                (props.goodResponse === null &&
                                    castToBoolean(
                                        props.choice[props.document]
                                    ) === castToBoolean(props.selected)) ||
                                +props.goodResponse === 1
                                    ? 'checked'
                                    : ''
                            }
                            disabled="disabled"
                        />
                    ) : (
                        <input
                            key={
                                'radio-' + props.lang + '-' + props.goodResponse
                            }
                            type="radio"
                            name={state.name}
                            id=""
                            class={
                                'to-check check-checked is-retina ' +
                                props.lang +
                                '-' +
                                +props.goodResponse +
                                ' ' +
                                ((props.goodResponse === null &&
                                    castToBoolean(
                                        props.choice[props.document]
                                    ) === castToBoolean(props.selected)) ||
                                +props.goodResponse === 1
                                    ? 'checked'
                                    : '')
                            }
                            value={String(props.choice[props.document])}
                            onclick={props.onnclick}
                            checked={
                                (props.goodResponse === null &&
                                    props.choice[props.document] ===
                                        props.selected) ||
                                +props.goodResponse === 1
                                    ? 'checked'
                                    : ''
                            }
                        />
                    )}
                    <span
                        key={
                            'radiospan-' + props.lang + '-' + props.goodResponse
                        }
                        class={
                            'control-indicator' +
                            ((props.choice[props.document] === 0 ||
                                props.choice[props.document] === false) &&
                            !isDefined(props.goodResponse)
                                ? ' control-indicator-negative'
                                : '')
                        }
                    ></span>
                    <label
                        key={
                            'radiolabel-' +
                            props.lang +
                            '-' +
                            props.goodResponse
                        }
                        class="sup-input-label"
                    >
                        {props.document}
                    </label>
                </label>
            </div>
        ),
    'afradio'
)

export { AFRadio }

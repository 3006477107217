import { h, Component } from '@app/utils'
import { Table } from '@app/elements'
import { Forum } from '@app/api'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.forum_TAGS.form,
    initialized: false,
    tableHead: documents.forum_TAGS.tableHead,
    tableOptions: documents.forum_TAGS.tableOptions,
    tableAllOptions: documents.forum_TAGS.tableAllOptions,
    tableFilter: documents.forum_TAGS.filter,
    tableSearch: documents.forum_TAGS.search,
    document: documents.forum_TAGS,
    display: '',
    editId: null,
    tags: [],
    currentUrl: '',
    kind: 'tags',
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Content/pages/actions')

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout page="forumtag" customer={customer} currentPage={'forum'}>
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' && (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            search={state.tableSearch}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Forum.getTags}
                            updateUrl={actions.updateUrl}
                        ></Table>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'forumtag')

import {
    isValidPhoneNumber,
    validatePhoneNumberLength,
} from 'libphonenumber-js/mobile'

const isValidPhone = function (phone, prefix) {
    try {
        if (
            phone &&
            typeof phone === 'string' &&
            prefix &&
            typeof prefix === 'string'
        ) {
            return isValidPhoneNumber(`${prefix}${phone}`)
        }
        throw new Error(
            `Phone or Prefix are not a string : ${phone} / ${prefix}`
        )
    } catch {
        return false
    }
}

const isValidPhoneLength = function (phone, prefix) {
    try {
        if (phone && typeof phone === 'string') {
            // [undefined] result mean that phone lenght is valid
            return [undefined, 'TOO_SHORT'].includes(
                validatePhoneNumberLength(`${prefix || ''}${phone}`)
            )
        }
        throw new Error(
            `Phone or Prefix are not a string : ${phone} / ${prefix}`
        )
    } catch {
        return false
    }
}

export { isValidPhone, isValidPhoneLength }

import { t, h, Component } from '@app/utils'
import { addCSRFToken, scrollTo, getQueryVariable, isDefined } from '@app/core'
import { Forum } from '@app/api'
import { Form, AFTopicAnswer, Button } from '@app/elements'
import './index.scss'

import icoDefaultUser from '@app/img/default/default.png'
import icoDefaultUserx2 from '@app/img/default/default@2x.png'

const AdminFormForumTopic = Component(
    {
        sendToParent: null,
        name: '',
        user: null,
        topicId: '',
        moderated: 0,
        moderatedCount: 0,
        placeholder: '',
        original: null,
        answers: [],
        abusesCount: 0,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.placeholder) {
                state.placeholder = props.placeholder
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }

            if (props.name) {
                actions.setName(props.name)
            }

            if (props.user) {
                actions.setUser(props.user)
            }

            if (props.moderated) {
                actions.setModerated(props.moderated)
            }

            if (props.original) {
                actions.setOriginal(props.original)
                if (props.original.user) {
                    actions.setUser(props.original.user)
                }
                if (props.original.id) {
                    actions.setTopicId(props.original.id)
                }
                if (props.original.answers) {
                    actions.setAnswers(props.original.answers)
                }
                if (props.original.abusesCount) {
                    actions.setAbusesCount(props.original.abusesCount)
                }

                let moderatedCount = 0
                if (props.original.moderated) {
                    actions.setModerated(props.original.moderated)
                    moderatedCount += props.original.moderated > 0 ? 1 : 0
                }
                if (props.original.moderationsCount) {
                    moderatedCount += props.original.moderationsCount
                }
                actions.setModeratedCount(moderatedCount)
            }

            setTimeout(() => {
                let id = getQueryVariable('id', false)
                if (id && isDefined(id) && id !== '') {
                    let trg = document.getElementById(id)
                    if (trg) {
                        let scrTrg = document.querySelectorAll(
                            '.btzMainPage.btzMainPageCenter'
                        )[0]
                        if (scrTrg) {
                            let offset =
                                trg.getBoundingClientRect().top + window.scrollY
                            if (offset > 0) {
                                offset = offset - 100
                            }
                            scrollTo(scrTrg, offset, 240)
                        }
                    }
                }
            }, 500)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.original) {
                if (props.original.answers) {
                    actions.setAnswers(props.original.answers)
                }
            }
        },
        onUpdate: (el) => (state, actions) => {
            let answerValue = el.target.value
            let answerId = el.target.id
            let newAnswers = state.answers
            if (answerId === '' || answerId === null) {
                if (newAnswers.filter((f) => f.id === null)[0] !== undefined) {
                    newAnswers.filter((f) => f.id === null)[0].content =
                        answerValue
                } else {
                    newAnswers.push({ id: null, content: answerValue })
                }
            } else {
                newAnswers.filter((f) => f.id === answerId)[0].content =
                    answerValue
            }
            state.sendToParent({
                target: { name: 'answers', value: newAnswers },
            })
        },
        onUpdateMedia: (props) => (state, actions) => {
            state.sendToParent({
                target: { name: props.target.name, value: props.target.value },
            })
        },
        onModerate: () => (state, actions) => {
            Forum.moderateTopic(state.topicId).then(() => {
                actions.setModerated(1)
                actions.addOneModeratedCount()
            })
        },
        onResetTopic: () => (state, actions) => {
            Forum.resetTopic(state.topicId).then(() => {
                actions.setModerated(1)
                actions.addOneModeratedCount()
                actions.setAbusesCount(0)
            })
        },
        addOneModeratedCount: () => (state, actions) => {
            actions.setModeratedCount(state.moderatedCount + 1)
        },
        getDate: ({ el, dat }) => {
            dat = dat.replace(/-/g, '/')
            dat = dat.split('.')[0]
            var date = new Date(Date.parse(new Date(dat)))
            var options = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }
            el.innerHTML =
                t('Le') + ' ' + date.toLocaleDateString('fr-FR', options)
        },
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setUser: (newState) => (state) => ({
            user: newState,
        }),
        setOriginal: (newState) => (state) => ({
            original: newState,
        }),
        setTopicId: (newState) => (state) => ({
            topicId: newState,
        }),
        setModerated: (newState) => (state) => ({
            moderated: newState,
        }),
        setModeratedCount: (newState) => (state) => ({
            moderatedCount: newState,
        }),
        setAnswers: (newState) => (state) => ({
            answers: newState,
        }),
        setAbusesCount: (newState) => (state) => ({
            abusesCount: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            {state.original !== null && (
                <label
                    class="sup-label"
                    style={{ 'display': 'block', 'margin-bottom': '30px' }}
                >
                    {t('POST(S) MODÉRÉ(S)') +
                        ' ' +
                        state.moderatedCount +
                        '/' +
                        state.original.answers.length}
                </label>
            )}
            <div class="forumtopic-left-hand">
                <div class="forumtopic-left-hand-wrapper">
                    <label class="forumtopic-left-hand-subtitle sup-label">
                        {t('User')}
                    </label>
                </div>
                <div class="forumtopic-left-hand-wrapper">
                    <label class="forumtopic-left-hand-title sup-label">
                        {t('Topic')}
                    </label>
                    {state.original !== null && (
                        <p
                            class="btzLightTexte"
                            oncreate={(el) =>
                                actions.getDate({
                                    el: el,
                                    dat: state.original.date.date,
                                })
                            }
                        ></p>
                    )}
                </div>
            </div>
            <div class="forumtopic-center-hand">
                <div class="forumtopic-center-hand-user">
                    {state.user !== null && (
                        <dummy>
                            {state.user.media !== null ? (
                                <img
                                    style={{ 'max-width': '100%' }}
                                    src={state.user.media.url}
                                />
                            ) : (
                                <img
                                    style={{ 'max-width': '100%' }}
                                    src={icoDefaultUser}
                                    srcset={`${icoDefaultUserx2} 2x`}
                                />
                            )}
                            <div
                                class=""
                                style={{
                                    'cursor': 'pointer',
                                    'white-space': 'normal',
                                    'line-height': '1.2',
                                    'max-width': '85%',
                                }}
                                onclick={() => {
                                    window.location.href = addCSRFToken(
                                        '/users/users/' + state.user.id
                                    )
                                }}
                            >
                                {state.user.fname + ' / ' + state.user.email}
                            </div>
                        </dummy>
                    )}
                </div>
                <div class="forumtopic-center-hand-content">
                    {state.moderated > 0 && (
                        <p class="forumtopic-redlabel">
                            {t(
                                'Considéré inapproprié, ce post a été modéré par les administrateurs.'
                            )}
                        </p>
                    )}
                    <div class="sup-textarea" style="">
                        <textarea
                            name={props.document.name}
                            class={'input-text'}
                            value={props.document.value}
                            placeholder={props.document.placeholder.textarea}
                            oninput={props.onclick}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="forumtopic-right-hand">
                <div class="forumtopic-right-hand-user">
                    {state.original !== null && (
                        <dummy>
                            {state.original.abusesCount > 0 ? (
                                <div>
                                    <p
                                        class="forumtopic-redlabel"
                                        style={{ display: 'inline-block' }}
                                    >
                                        {t('Signalement(s)') +
                                            ' ' +
                                            state.original.abusesCount}
                                    </p>
                                    <p
                                        class="forumtopic-redlabel"
                                        onclick={actions.onResetTopic}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                            margin: '0 0 0 50px',
                                        }}
                                    >
                                        {t('> Reset')}
                                    </p>
                                </div>
                            ) : (
                                <p class="">{t('Aucun signalement')}</p>
                            )}
                        </dummy>
                    )}
                </div>
            </div>

            {(state.moderated === null || state.moderated === 0) && (
                <Form.Group classes="btzForm-btzFooter">
                    <Button primary flat onclick={actions.onModerate}>
                        {t('Modérer')}
                    </Button>
                </Form.Group>
            )}

            <label
                class="forumtopic-left-hand-title sup-label"
                style={{
                    'font-weight': 'bold',
                    'display': 'block',
                    'margin-top': '50px',
                }}
            >
                {t('Réponses au topic')}
            </label>
            {state.answers.length > 0 && (
                <dummy>
                    {state.answers
                        .filter((f) => f.id !== null)
                        .map((item) => (
                            <div id={item.id}>
                                <AFTopicAnswer
                                    key={item.id}
                                    {...item}
                                    addOneModeratedCount={
                                        actions.addOneModeratedCount
                                    }
                                    oninput={actions.onUpdate}
                                    placeholder={t('Rédigez un commentaire')}
                                ></AFTopicAnswer>
                            </div>
                        ))}
                </dummy>
            )}
            <dummy>
                <AFTopicAnswer
                    content={
                        state.answers.filter((f) => f.id === null)[0] !==
                        undefined
                            ? state.answers.filter((f) => f.id === null)[0]
                                  .content
                            : ''
                    }
                    id={null}
                    oninput={actions.onUpdate}
                    placeholder={t(
                        'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)'
                    )}
                ></AFTopicAnswer>
            </dummy>
        </div>
    ),
    'adminformforumtopic'
)

export { AdminFormForumTopic }

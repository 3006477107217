import { t } from '@app/utils'
import * as constants from '@app/constants'

const card_QUIZZ = {
    header: ['Configurer la carte', 'Quizz'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: '',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        type: {
            type: 'int',
            field: 'hidden',
            value: '',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Question',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Réponse',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de la carte',
                i18nMessage: t(
                    'Veuillez renseigner la description de la carte'
                ),
            },
            infos: [
                'Vous pouvez intégrer des liens.',
                'Par exemple pour créer le lien ',
                'Aller vers google',
                'écrire : [[link|http://www.google.com|Aller vers google]]',
            ],
            i18nInfos: [
                t('Vous pouvez intégrer des liens.'),
                t('Par exemple pour créer le lien '),
                t('Aller vers google'),
                t('écrire : [[link|http://www.google.com|Aller vers google]]'),
            ],
        },
        theme: {
            name: 'theme',
            type: 'int',
            label: 'Thématique',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
        },
        frequency: {
            name: 'frequency',
            type: 'int',
            label: 'Fréquence',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.FREQUENCY,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        forms: {
            // for evaluations
            name: 'eval',
            substitute: 'formDatas',
            type: 'translation',
            label: 'Détail du formulaire',
            field: 'quizz',
            model: {},
            list: 'static',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message:
                    'Veuillez choisir une des réponses aux quizz comme bonne réponse',
                i18nMessage: t(
                    'Veuillez choisir une des réponses aux quizz comme bonne réponse'
                ),
            },
            sendElsewhere: true,
            wrappedIn: 'forms',
            withMedia: false,
            formHeader: [
                { label: 'Ordre', width: '165px' },
                { label: 'Réponse', width: '260px' },
                { label: 'Valeur', width: '75px' },
                { label: 'Bonne réponse', width: '100px' },
            ],
        },
        formsType: {
            name: 'type',
            substitute: 'type',
            type: 'int',
            label: 'Type',
            field: 'hidden',
            list: {},
            value: 'Button',
            wrappedIn: 'forms',
        },
        dataDate: {
            field: 'hidden',
            value: 'P0D',
            wrappedIn: 'forms',
        },
    },
}

export { card_QUIZZ }

import { queueQueries } from '@app/api/controlleur'
import { settings } from '@app/core/settings'

const { APP_API_URL } = settings

function updateDoctorNursesTeam(data) {
    return queueQueries(
        `${APP_API_URL}/medical/supervisor`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function addTrustIP(data) {
    return queueQueries(
        `${APP_API_URL}/admin/trusted-ip`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { trustedIps: data }
    )
}

function removeTrustIP(data) {
    return queueQueries(
        `${APP_API_URL}/admin/trusted-ip`,
        'DELETE',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { trustedIps: data }
    )
}

function getTrustIP() {
    return queueQueries(`${APP_API_URL}/admin/trusted-ip`, 'GET')
}

function addInteropSource(interopSourceLabel) {
    return queueQueries(
        `${APP_API_URL}/admin/interoperability/source`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { label: interopSourceLabel }
    )
}

function removeInteropSource(interopSourceLabel) {
    return queueQueries(
        `${APP_API_URL}/admin/interoperability/source/${interopSourceLabel}`,
        'DELETE',
        'application/json',
        true,
        true,
        true,
        true,
        true
    )

}

function getInteropSources() {
    return queueQueries(`${APP_API_URL}/admin/interoperability/source`, 'GET')
}

function getInteropSourcesDropDown() {
    return getInteropSources().then(sources =>({data:[
        {
            key: 'null',
            value: null,
            label:'None (disabled)'
        },
        ...sources.data.entry.map(source=>
            ({
                key: source.id,
                value: source.label,
                label:source.label,
            }))
    ]}))

}

export const Operations = {
    updateDoctorNursesTeam,
    addTrustIP,
    removeTrustIP,
    getTrustIP,
    addInteropSource,
    removeInteropSource,
    getInteropSources,
    getInteropSourcesDropDown,
}

import * as documents from '@app/documents'

export const CARDS = {
    1: { value: 'Article', key: '18', document: documents.card_ARTICLE },
    2: {
        value: 'Bandeau Image',
        key: '15',
        document: documents.card_BANDEAU_IMAGE,
    },
    3: { value: 'Bonjour', key: '16', document: documents.card_HELLO },
    4: { value: 'Citation', key: '20', document: documents.card_CITATION },
    5: { value: 'Conseil', key: '2', document: documents.card_ADVICE },
    6: {
        value: 'Dashboard Info',
        key: '17',
        document: documents.card_DASHBOARD,
    },
    7: { value: 'Défi', key: '14', document: documents.card_CHALLENGE },
    8: {
        value: 'Évaluation Classique',
        key: '1',
        document: documents.card_EVALUATION,
    },
    9: {
        value: 'Évaluation Comportement',
        key: '22',
        document: documents.card_EVALUATION_BEHAVIOUR,
    },
    10: {
        value: 'Évaluation Questionnaire',
        key: '58',
        document: documents.card_EVALUATION_SURVEY,
    },
    11: { value: 'Mail Hebdo', key: '7', document: documents.card_MAIL },
    12: { value: 'Partenaire', key: '24', document: documents.card_SPONSOR },
    13: { value: 'Programme', key: '6', document: documents.program_CARD },
    14: { value: 'Quizz', key: '12', document: documents.card_QUIZZ },
    15: { value: 'Recette', key: '19', document: documents.card_RECIPE },
    16: {
        value: 'SA Article intro',
        key: '32',
        document: documents.card_ADVICE,
    },
    17: {
        value: 'SA Article suite',
        key: '33',
        document: documents.card_ADVICE,
    },
    18: { value: 'SA Bonjour', key: '40', document: documents.card_ADVICE },
    19: {
        value: 'SA Conseil intro',
        key: '28',
        document: documents.card_ADVICE,
    },
    20: {
        value: 'SA Conseil choix',
        key: '29',
        document: documents.card_ADVICE,
    },
    21: {
        value: 'SA Conseil suite 1',
        key: '30',
        document: documents.card_ADVICE,
    },
    22: {
        value: 'SA Conseil suite 2',
        key: '31',
        document: documents.card_ADVICE,
    },
    23: { value: 'SA Éval intro', key: '25', document: documents.card_ADVICE },
    24: {
        value: 'SA Éval intermédiaire',
        key: '26',
        document: documents.card_ADVICE,
    },
    25: { value: 'SA Évaluation', key: '41', document: documents.card_ADVICE },
    26: {
        value: 'SA Évaluation Comportement',
        key: '42',
        document: documents.card_ADVICE,
    },
    27: {
        value: 'SA Éval conclusion',
        key: '27',
        document: documents.card_ADVICE,
    },
    28: { value: 'SA Graph intro', key: '36', document: documents.card_ADVICE },
    29: { value: 'SA Graph choix', key: '37', document: documents.card_ADVICE },
    30: { value: 'SA Graph suite', key: '38', document: documents.card_ADVICE },
    31: {
        value: 'SA Recette intro',
        key: '34',
        document: documents.card_ADVICE,
    },
    32: {
        value: 'SA Recette suite',
        key: '35',
        document: documents.card_ADVICE,
    },
    33: {
        value: 'SA Registration',
        key: '39',
        document: documents.card_ADVICE,
    },
    34: { value: 'Coach', key: '50', document: documents.card_ADVICE },
    35: { value: 'Alfred', key: '13', document: documents.card_ALFRED },
    36: {
        value: 'Coup de pouce',
        key: '54',
        document: documents.card_COUP_DE_POUCE,
    },
    37: {
        value: 'Évaluation Mesure',
        key: '55',
        document: documents.card_EVALUATION_MESURE,
    },
}

export const STEP_CATEGORIES = {
    1: {
        value: 'Objectif',
        key: 1,
        document: documents.program_step_CARD_OBJECTIF,
    },
    2: {
        value: 'Évaluation',
        key: 2,
        document: documents.program_step_CARD_EVALUATION,
    },
    3: {
        value: 'Exercice',
        key: 3,
        document: documents.program_step_CARD_EXERCICE,
    },
    4: {
        value: 'Conseil',
        key: 4,
        document: documents.program_step_CARD_ADVICE,
    },
}

export const ALERTS = {
    1: { value: 'Défis', key: 1, document: documents.alert_CHALLENGE },
    2: { value: 'Programme', key: 3, document: documents.alert_PROGRAM },
}

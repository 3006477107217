import {
    iePolyfills,
    getClosest,
    isDefined,
    settings,
    getQueryVariable,
    checkForValidCSRFToken,
} from '@app/core'
import {
    app,
    t,
    h,
    location,
    withTranslation,
    withStorage,
    withSecurity,
} from '@app/utils'
import { Route, Switch, ProgressBar, Meta } from '@app/elements'
// import persist from 'hyperapp-persist';

import * as constants from '@app/constants'
const { APP_CUSTOMISATION } = settings

import * as serviceWorker from './registerServiceWorker'

import { BotFooterView } from '@app/layouts/botfooter'

import icoCloseWhite from '@app/img/ico/close-cross.png'
import icoCloseWhitex2 from '@app/img/ico/close-cross@2x.png'

// import { Advice } from '@bit/adrienbelair.betterise-web.src/dist/modules/Timeline/cards/advice';
// import { Advice } from '@bit/adrienbelair.betterise-web.modules/dist/modules';

import {
    Login,
    AdminIndex,
    Cards,
    Programs,
    Articles,
    Recipes,
    Challenges,
    Food,
    Coachs,
    Emails,
    Alerts,
    Objectives,
    CorpoWordsRh,
    CorpoCitations,
    CorpoEvaluations,
    CorpoSurveys,
    CorpoChallenges,
    Actions,
    Badges,
    Levels,
    RewActions,
    RewPromotions,
    UUsers,
    Customers,
    Scenario,
    Nurses,
    Patients,
    Establishments,
    Configuration,
    Operations,
    Forum,
    ForumFR,
    ForumUS,
    Tag,
    Calendars,
    Themes,
    Statistiques,
    ClientErrors,
    Error,
} from '@app/modules'

const state = {
    location: location.state,
    rendering: false,
    loaded: 'nope', // this set overflow hidden on body until complete load
    customerssoname: APP_CUSTOMISATION?.toLowerCase(), // reference ssoname for custo
    meta: null, // meta are raw setted in public/index.html, then we override them if needed
    browserlang: 'fr', // reference language
}

const actions = {
    onUpdate: () => (state, actions) => {
        checkForValidCSRFToken()
    },
    location: location.actions,
    pendingAjaxQuery: () => (state, actions) => {
        let scheme = getQueryVariable('scheme', false) // redirect to mobile app
        if (
            typeof scheme !== 'undefined' &&
            scheme !== '' &&
            scheme !== null &&
            scheme !== false
        ) {
            window.location.href = scheme
            return false
        }

        let customerssoname = null
        let forcedLanguage = null
        if (settings.APP_DEBUG === 'true' || settings.APP_DEBUG === true) {
            // get customer by query variable only if dev
            customerssoname = getQueryVariable('customerssoname', false)
            let customersso = getQueryVariable('customersso', false)
            customerssoname =
                customerssoname === false ? customersso : customerssoname // look at query parameter
            customerssoname =
                customerssoname === false
                    ? state.customerssoname
                    : customerssoname // if nothing, take CUSTO_SSO constant
        } else {
            // else we use the .env customer, no dyn, each customer has his instance
            customerssoname = state.customerssoname
        }
        actions.setCustomer(customerssoname)

        // actions.loadFontFace(customerssoname);
        actions.loadCSS(customerssoname)
        actions.overrideMeta(customerssoname)
        actions.defineLanguage(forcedLanguage)

        var intervalID = window.setInterval(function () {
            if (document.readyState === 'complete') {
                // actions.setLoaded('loaded');
                window.clearInterval(intervalID)
                // actions.rendering();
            }
        }, 500)
    },
    loadCSS: (customerssoname) => (state, actions) => {
        try {
            require('@app/custo/' + customerssoname + '/index.scss')
        } catch (error) {
            require('@app/custo/betterise/index.scss')
            actions.setCustomer('betterise')
        }
    },
    overrideMeta: (customerssoname) => (state, actions) => {
        let ccustomer = constants.custo.CUSTOMERS[customerssoname]
        if (isDefined(ccustomer)) {
            let meta = {
                customer: customerssoname,
                title: ccustomer.title,
                description: t(ccustomer.description),
                favicon:
                    window.location.origin +
                    '/favicon/' +
                    (customerssoname !== false
                        ? customerssoname
                        : 'betterise') +
                    '/favicon.ico',
            }
            actions.setMeta(meta)
        }
    },
    defineLanguage: (forcedLanguage) => (state, actions) => {
        let browserlang =
            window.navigator.userLanguage || window.navigator.language
        if (browserlang.indexOf('_') > -1) {
            browserlang = browserlang.split('_')[0]
        }
        let systemLang = state.lang
        if (forcedLanguage !== null) {
            browserlang = forcedLanguage
            systemLang = forcedLanguage
        }
        if (browserlang !== 'fr') {
            window.main.changeLanguage(browserlang)
            actions.setBrowserLang(browserlang)
        } else if (systemLang !== 'fr') {
            window.main.changeLanguage(systemLang)
            actions.setBrowserLang(systemLang)
        }
    },
    setCustomer: (newState) => (state) => ({
        customerssoname: newState,
    }),
    setMeta: (newState) => (state) => ({
        meta: newState,
    }),
    setBrowserLang: (newState) => (state) => ({
        browserlang: newState,
    }),
    setLoaded: (newState) => (state) => ({
        loaded: newState,
    }),
    rendering: () => (state) => ({
        rendering: !state.rendering,
    }),
}

const hasRuntime =
    !!settings['APP_WEB_URL'] &&
    !!settings['APP_FRONT_WEB_URL'] &&
    !!settings['APP_API_URL']

const view = (state, actions) => (
    <div
        id="btzRoot"
        class={state.customerssoname}
        onupdate={actions.onUpdate}
        oncreate={actions.pendingAjaxQuery}
        data-loading={'loaded'}
        key={state.customerssoname}
    >
        {state.meta !== null && (
            <div key={state.meta.customer}>
                <Meta {...state.meta}></Meta>
            </div>
        )}
        <ProgressBar customer={state.customerssoname}></ProgressBar>
        <Switch>
            {!hasRuntime ? (
                <Route path="/" parent>
                    {() => (
                        <Error message="Error parsing runtime env from config file" />
                    )}
                </Route>
            ) : null}

            <Route path="/index">
                {() => <AdminIndex customer={state.customerssoname} />}
            </Route>

            <Route path="/content">
                {({ location, match }) => (
                    <Cards
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/cards">
                {({ location, match }) => (
                    <Cards
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/cards/:id">
                {({ location, match }) => (
                    <Cards
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/articles">
                {({ location, match }) => (
                    <Articles
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/articles/:id">
                {({ location, match }) => (
                    <Articles
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/recipes">
                {({ location, match }) => (
                    <Recipes
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/recipes/:id">
                {({ location, match }) => (
                    <Recipes
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/challenges">
                {({ location, match }) => (
                    <Challenges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/challenges/:id">
                {({ location, match }) => (
                    <Challenges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/aliments">
                {({ location, match }) => (
                    <Food
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/aliments/:id">
                {({ location, match }) => (
                    <Food
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/coachs">
                {({ location, match }) => (
                    <Coachs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/coachs/:id">
                {({ location, match }) => (
                    <Coachs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/emails">
                {({ location, match }) => (
                    <Emails
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/emails/:id">
                {({ location, match }) => (
                    <Emails
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/alerts">
                {({ location, match }) => (
                    <Alerts
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/alerts/:id">
                {({ location, match }) => (
                    <Alerts
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/objectives">
                {({ location, match }) => (
                    <Objectives
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/objectives/:id">
                {({ location, match }) => (
                    <Objectives
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/programs">
                {({ location, match }) => (
                    <Programs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/programs/:id">
                {({ location, match }) => (
                    <Programs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/programs/:id/sessions">
                {({ location, match }) => (
                    <Programs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/programs/:id/sessions/:sid">
                {({ location, match }) => (
                    <Programs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/programs/:id/sessions/:sid/step">
                {({ location, match }) => (
                    <Programs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/programs/:id/sessions/:sid/step/:ssid">
                {({ location, match }) => (
                    <Programs
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/wordsrh">
                {({ location, match }) => (
                    <CorpoWordsRh
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/wordsrh/:id">
                {({ location, match }) => (
                    <CorpoWordsRh
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/citations">
                {({ location, match }) => (
                    <CorpoCitations
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/citations/:id">
                {({ location, match }) => (
                    <CorpoCitations
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/evaluations">
                {({ location, match }) => (
                    <CorpoEvaluations
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/evaluations/:id">
                {({ location, match }) => (
                    <CorpoEvaluations
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/evaluations/:id/statistics">
                {({ location, match }) => (
                    <CorpoEvaluations
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                        particularDisplay={'statistics'}
                        particularKind={'corpo-eval'}
                    />
                )}
            </Route>
            <Route path="/content/surveys">
                {({ location, match }) => (
                    <CorpoSurveys
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/surveys/:id">
                {({ location, match }) => (
                    <CorpoSurveys
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/surveys/:id/statistics">
                {({ location, match }) => (
                    <CorpoSurveys
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                        particularDisplay={'statistics'}
                        particularKind={'corpo-survey'}
                    />
                )}
            </Route>
            <Route path="/content/corpo/challenges">
                {({ location, match }) => (
                    <CorpoChallenges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/corpo/challenges/:id">
                {({ location, match }) => (
                    <CorpoChallenges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/content/challenges/:id/results">
                {({ location, match }) => (
                    <CorpoChallenges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                        particularDisplay={'results'}
                    />
                )}
            </Route>

            <Route path="/users">
                {({ location, match }) => (
                    <UUsers
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/users">
                {({ location, match }) => (
                    <UUsers
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/users/:id">
                {({ location, match }) => (
                    <UUsers
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/customers">
                {({ location, match }) => (
                    <Customers
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/customers">
                {({ location, match }) => (
                    <Customers
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/customers/:id">
                {({ location, match }) => (
                    <Customers
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/customers/:id/scenario">
                {({ location, match }) => (
                    <Scenario
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/customers/:id/scenario/:idscenario">
                {({ location, match }) => (
                    <Scenario
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/nurses">
                {({ location, match }) => (
                    <Nurses
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/nurses/:id">
                {({ location, match }) => (
                    <Nurses
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/patients">
                {({ location, match }) => (
                    <Patients
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/patients/:id">
                {({ location, match }) => (
                    <Patients
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/establishments">
                {({ location, match }) => (
                    <Establishments
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/users/establishments/:id">
                {({ location, match }) => (
                    <Establishments
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/reward">
                {({ location, match }) => (
                    <RewActions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/reward/actions">
                {({ location, match }) => (
                    <RewActions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/reward/actions/:id">
                {({ location, match }) => (
                    <RewActions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/reward/promotions">
                {({ location, match }) => (
                    <RewPromotions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/reward/promotions/:id">
                {({ location, match }) => (
                    <RewPromotions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/gamif">
                {({ location, match }) => (
                    <Badges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/gamif/actions">
                {({ location, match }) => (
                    <Actions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/gamif/actions/:id">
                {({ location, match }) => (
                    <Actions
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/gamif/badges">
                {({ location, match }) => (
                    <Badges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/gamif/badges/:id">
                {({ location, match }) => (
                    <Badges
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/gamif/levels">
                {({ location, match }) => (
                    <Levels
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/gamif/levels/:id">
                {({ location, match }) => (
                    <Levels
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/configuration">
                {({ location, match }) => (
                    <Configuration
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/operations">
                {({ location, match }) => (
                    <Operations
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/statistiques">
                {({ location, match }) => (
                    <Statistiques
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/forum">
                {({ location, match }) => (
                    <Forum
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/forum/forumfr">
                {({ location, match }) => (
                    <ForumFR
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/forum/forumfr/:id">
                {({ location, match }) => (
                    <ForumFR
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/forum/forumus">
                {({ location, match }) => (
                    <ForumUS
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/forum/forumus/:id">
                {({ location, match }) => (
                    <ForumUS
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/forum/tag">
                {({ location, match }) => (
                    <Tag
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/forum/tag/:id">
                {({ location, match }) => (
                    <Tag
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/calendars">
                {({ location, match }) => (
                    <Calendars
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/calendars/:id">
                {({ location, match }) => (
                    <Calendars
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/themes">
                {({ location, match }) => (
                    <Themes
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/errors">
                {({ location, match }) => (
                    <ClientErrors
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/errors/:id">
                {({ location, match }) => (
                    <ClientErrors
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            {/*<Route path='/alfred'>
                {() => <Alfred customer={state.customerssoname} />}
            </Route>
            <Route path='/alfred/containers'>
                {() => <Containers customer={state.customerssoname} />}
            </Route>
            <Route path='/alfred/savalidation'>
                {() => <SAValidation customer={state.customerssoname} />}
            </Route>
            <Route path='/alfred/conversations'>
                {() => <Conversations customer={state.customerssoname} />}
            </Route>
            <Route path='/alfred/testers'>
                {() => <Testers customer={state.customerssoname} />}
            </Route>*/}

            <Route path="/login" parent>
                {({ location, match }) => (
                    <Login
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/" parent>
                {({ location, match }) => (
                    <Login
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
        </Switch>
        <BotFooterView customer={state.customerssoname}></BotFooterView>

        <span id="btzRuler"></span>
        <div id="btzMainOverlay"></div>
        <div id="btzMainMessage" data-kind="error">
            <div
                id="btzMainMessageCross"
                onclick={(e) => {
                    getClosest(e.target, '#btzMainMessage').classList.remove(
                        'active'
                    )
                }}
            >
                <img
                    src={icoCloseWhite}
                    srcset={`${icoCloseWhitex2} 2x`}
                    alt=""
                />
            </div>
            <div id="btzMaintextContent"></div>
        </div>
    </div>
)

/*app = persist(app, {
    storage: 'my-app/v1',
    include: [ 'router', 'player' ]
});*/

let appOnSteroids = app
appOnSteroids = withTranslation({})(appOnSteroids) // grabs all locales, set one as default
// appOnSteroids = withStorage({})(appOnSteroids) // syncs state objects in localstorage
appOnSteroids = withSecurity({})(appOnSteroids) // keeps an eye on tokens and triggers actions

if (settings.APP_DEBUG) {
    // const { withLogger } = require('@hyperapp/logger')
    // appOnSteroids = withLogger({})(appOnSteroids)
}

// const instance = persist(appOnSteroids(state, actions, view, document.body), {});
const instance = appOnSteroids(state, actions, view, document.body)
// eslint-disable-next-line
const unsubscribe = location.subscribe(instance.location)

window.main = instance

// instance.initStorage()

serviceWorker.unregister()

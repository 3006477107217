import { t, h, Component } from '@app/utils'
import { allRoutes, getUser, dynLoad } from '@app/core'
import { User } from '@app/api'

export const UserMenuView = dynLoad('view', 'usermenus', 'layouts')

const UserMenu = Component(
    {
        URLEmptyTimeline: '',
        URLUserMedia: '',
        userName: '',
        currentLanguage: 'fr',
        messageContent: '',
        messageKind: 'success',
        messageActive: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            let currentLang = localStorage.getItem('language')
            if (currentLang !== null && currentLang !== undefined) {
                actions.setCurrentLanguage(currentLang)
            }

            if (props && props.display !== 'login') {
                let cb = function (user) {
                    if (user) {
                        if (user.nick) {
                            actions.setUserName(user.nick)
                        }
                        if (user.media && user.media.url) {
                            actions.setUserMedia(user.media.url)
                        }
                    }
                }
                getUser(cb)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {},
        setUserName: (newState) => (state) => ({
            userName: newState,
        }),
        setUserMedia: (newState) => (state) => ({
            URLUserMedia: newState,
        }),
        setCurrentLanguage: (newState) => (state) => ({
            currentLanguage: newState,
        }),
        clearTimeline: () => (state, actions) => {
            console.log('CLEAR TIM')
            User.clearTimeline().then(() => {
                console.log('CLEAR TIM done')
                actions.setQueryMessage({
                    newStateKind: 'success',
                    newStateContent: t(
                        'Votre journal a été vider avec succés.'
                    ),
                })
                actions.displaySuccessMessage(true)
            })
        },
        deconnectUser: () => (state, actions) => {
            User.destroySession()
                .catch(() => {
                    //if the auth token cannot be deleted on the API we do nothing
                    //as we don't want to prevent the user for being disconnected
                })
                .finally(() => {
                    localStorage.setItem('apitoken', null)
                    localStorage.setItem('refresh', null)
                    localStorage.setItem('expiration', null)
                    window.location = allRoutes['index'].pathname
                })
        },
        updateLanguage: (lang) => (state, actions) => {
            if (state.currentLanguage !== lang) {
                localStorage.setItem('language', lang)
                setTimeout(() => {
                    window.location.reload()
                }, 400)
            }
        },
        setQueryMessage:
            ({ newStateKind, newStateContent }) =>
            (state) => ({
                messageKind: newStateKind,
                messageContent: newStateContent,
            }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
    },
    UserMenuView,
    'usermenu'
)

export { UserMenu }

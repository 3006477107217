import { h, Component } from '@app/utils'
import { Table, AdminForm, AdminStats } from '@app/elements'
import { Card } from '@app/api'
import { allRoutes, addCSRFToken } from '@app/core'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.corpoevaluation_CARD.form,
    initialized: false,
    tableHead: documents.corpoevaluation_CARD.tableHead,
    tableOptions: documents.corpoevaluation_CARD.tableOptions,
    tableAllOptions: documents.corpoevaluation_CARD.tableAllOptions,
    tableFilter: documents.corpoevaluation_CARD.filter,
    header: documents.corpoevaluation_CARD.header,
    document: documents.corpoevaluation_CARD.form,
    display: '',
    editId: null,
    editType: null,
    // cards: Object.keys(constants.custo.CARDS_CREATION).map(i => constants.custo.CARDS_CREATION[i]),
    currentUrl: '',
    kind: 'cards',
    modeDuplicate: false,
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Content/pages/actions')

const view =
    (state, actions) =>
    ({ match, customer, particularKind }, children) => (
        <IndexLayout
            page="corpoevaluations"
            customer={customer}
            currentPage={'content'}
        >
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Card.getCorpoEvaluations}
                            updateUrl={actions.updateUrl}
                        ></Table>
                    ) : (
                        <dummy>
                            {state.display === 'statistics' ? (
                                <AdminStats
                                    particularKind={particularKind}
                                    header={
                                        documents.corpoevaluation_STATISTICS
                                            .header
                                    }
                                    document={state.document}
                                    statistics={
                                        documents.corpoevaluation_STATISTICS
                                            .form
                                    }
                                    target={Card.getCardStatisticsByKind}
                                ></AdminStats>
                            ) : (
                                <div
                                    class=""
                                    key={
                                        state.initialized +
                                        '-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    {state.editId !== null ? (
                                        <div
                                            class={
                                                'adminform-edition-' +
                                                Object.keys(state.document)
                                                    .length
                                            }
                                            key={
                                                'adminform-edition-' +
                                                Object.keys(state.document)
                                                    .length
                                            }
                                        >
                                            <AdminForm
                                                header={state.header}
                                                editType={state.editType}
                                                document={state.document}
                                                target={
                                                    state.modeDuplicate
                                                        ? Card.addCard
                                                        : Card.editCard
                                                }
                                                secondtarget={Card.sendCardForm}
                                                backUrl={addCSRFToken(
                                                    allRoutes[
                                                        'private.evaluations'
                                                    ].pathname
                                                )}
                                                customer={customer}
                                            ></AdminForm>
                                        </div>
                                    ) : (
                                        <div key={'adminform-creation'}>
                                            <AdminForm
                                                header={state.header}
                                                editType={state.editType}
                                                document={state.document}
                                                target={Card.addCard}
                                                secondtarget={Card.sendCardForm}
                                                card={state.document}
                                                backUrl={addCSRFToken(
                                                    allRoutes[
                                                        'private.evaluations'
                                                    ].pathname
                                                )}
                                                customer={customer}
                                            ></AdminForm>
                                        </div>
                                    )}
                                </div>
                            )}
                        </dummy>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'corpoevaluations')

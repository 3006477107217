import { t, h, Component } from '@app/utils'
import { AFTeams } from '@app/elements'
import { Corpo } from '@app/api'
import { isDefined } from '@app/core'
import './index.scss'

const AdminFormTeams = Component(
    {
        idchallenge: null,
        name: '',
        teams: [],
        suggestEmail: '',
        oneSubmitAtOnce: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.name) {
                state.name = props.name
            }
            if (props.document) {
                if (props.document.value) {
                    actions.setTeams(props.document.value)
                }
            }
            if (props.original) {
                if (props.original.id) {
                    actions.setChallengeId(props.original.id)
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
        removeThisOne: (props) => (state, actions) => {
            if (state.oneSubmitAtOnce) {
                return false
            }
            let idchallenge = state.idchallenge
            let idteam = props.id

            actions.setOneSubmitAtOnce(true)
            if (isDefined(idchallenge) && isDefined(idteam)) {
                Corpo.deleteChallengeTeam(idchallenge, idteam).then(() => {
                    let teams = state.teams
                    for (var team in teams) {
                        if (teams[team].id === idteam) {
                            teams.splice(team, 1)
                        }
                    }
                    actions.setTeams(teams)
                    actions.setOneSubmitAtOnce(false)
                })
            }
        },
        setTeams: (newState) => (state) => ({
            teams: newState,
        }),
        setChallengeId: (newState) => (state) => ({
            idchallenge: newState,
        }),
        setOneSubmitAtOnce: (newState) => (state) => ({
            oneSubmitAtOnce: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {state.teams.length > 0 && (
                <div class="adminformteams-wrapper">
                    <div class="sup-group-form adminformteams-wrapper-header">
                        <label class="sup-label adminformteams-wrapper-header-entry">
                            {t('Image')}
                        </label>
                        <label class="sup-label adminformteams-wrapper-header-entry">
                            {t('Nom')}
                        </label>
                        <label class="sup-label adminformteams-wrapper-header-entry">
                            {t('Taille')}
                        </label>
                    </div>
                    {state.teams.map((team, idx) => (
                        <AFTeams
                            key={'team-entry-' + idx}
                            challengeid={state.idchallenge}
                            document={team}
                            name={state.name}
                            onDeleteTeam={() => actions.removeThisOne(team)}
                        ></AFTeams>
                    ))}
                </div>
            )}
        </div>
    ),
    'adminformteams'
)

export { AdminFormTeams }

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const gamif_BADGES = {
    filter: [],
    search: { label: 'ID' },
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'title', label: 'titre' },
        { key: 'date', label: 'date' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/gamif/badges/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/gamif/badges/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editGamification,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteGamification,
        },
    ],
    tableAllOptions: {
        label: 'BADGES SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editGamification,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editGamification,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteGamification,
            },
        ],
    },
    header: ['Configurer la gamif', 'Badge'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'gamif-badge',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer le badge',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom du badge',
            field: 'text',
            placeholder: 'Renseignez ici le nom du badge',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom du badge',
                i18nMessage: t('Veuillez renseigner le nom du badge'),
            },
        },
        treshold: {
            name: 'treshold',
            type: 'int',
            label: 'Treshold',
            field: 'int',
            placeholder: 'Renseignez ici le treshold de votre badge',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le treshold du badge',
                i18nMessage: t('Veuillez renseigner le treshold du badge'),
            },
        },
        points: {
            name: 'points',
            type: 'int',
            label: 'Points',
            field: 'int',
            placeholder: 'Renseignez ici les points de votre badge',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner les points du badge',
                i18nMessage: t('Veuillez renseigner les points du badge'),
            },
        },
        notification: {
            name: 'notification',
            type: 'boolean',
            label: 'Notification',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        category: {
            name: 'category',
            type: 'int',
            label: 'Categorie (id)',
            field: 'dropdown',
            list: constants.custo.BADGES_CATEGORIES,
            value: '',
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image du badge gagné',
            field: 'media',
            value: '',
            display: {
                mode: 'half',
            },
        },
        mediaOff: {
            name: 'mediaOff',
            substitute: 'mediaOffId',
            type: 'file',
            label: 'Image du badge non gagné',
            field: 'media',
            value: '',
            display: {
                mode: 'half',
            },
        },
        rewards: {
            name: 'rewards',
            type: 'autocomplete/reward',
            label: 'Reward(s) associé(s)',
            field: 'dyninput-to-div',
            searchbox: true,
            model: { key: 'id', value: 'name' },
            list: 'dynamic',
            placeholder: {
                input: 'Aucun',
                textarea: '',
            },
            value: '',
        },
        actions: {
            name: 'actions',
            type: 'tag',
            label: 'Tag évènement',
            field: 'dropdown-to-div',
            searchbox: false,
            model: { key: 'key', value: 'value' },
            list: constants.custo.BADGES_ACTIONS,
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneSelected',
                message: 'Veuillez sélectionner au moins un tag évènement',
                i18nMessage: t(
                    'Veuillez sélectionner au moins un tag évènement'
                ),
            },
            value: '',
        },

        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘achievement',
            field: 'text',
            placeholder: 'Renseignez ici le titre de votre achievement',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de l‘achievement',
                i18nMessage: t('Veuillez renseigner le titre de l‘achievement'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Description',
            field: 'textarea',
            placeholder:
                'Renseignez ici la description de votre achievement (entre 300 et 600 caractères)',
            value: {},
        },
        successMessage: {
            name: 'successMessage',
            type: 'translation',
            label: 'Message de succès',
            field: 'textarea',
            placeholder:
                'Renseignez ici le message de succès de votre achievement (entre 300 et 600 caractères)',
            value: {},
        },
        categoryName: {
            name: 'categoryName',
            type: 'translation',
            label: 'Catégorie (traduction)',
            field: 'text',
            placeholder: 'Renseignez ici la traduction de la catégorie',
            value: {},
        },
    },
}

export { gamif_BADGES }

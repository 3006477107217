import { t, h, Component } from '@app/utils'
import { Customers } from '@app/api'
import { getUser, deepCopy, isDefined } from '@app/core'
import { Form, Button, MessagePopup } from '@app/elements'
import './index.scss'

import * as constants from '@app/constants'

import IndexLayout from '@app/layouts/pages/layout'

import themeTicked from '@app/img/ico/ico-tick.png'
import themeTickedx2 from '@app/img/ico/ico-tick@2x.png'

const state = {
    customerId: null,
    themes: [],
    originalThemes: [],
    availableThemes: constants.custo.THEMES_CONTENT,
    sending: false,
    enableSaving: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        getUser((sessionUser) => {
            let customerId = null
            if (sessionUser && sessionUser.customerId !== undefined) {
                customerId = sessionUser.customerId
                actions.setCustomerId(customerId)
                Customers.getCustomersById(customerId).then((res) => {
                    if (isDefined(res.data) && !res.data.isError) {
                        let customerThemes = res.data.themes
                        actions.setThemes(customerThemes)
                        actions.setOriginalThemes(deepCopy(customerThemes))
                    }
                })
            }
        })
    },
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
    setThemes: (newState) => (state) => ({
        themes: newState,
    }),
    setOriginalThemes: (newState) => (state) => ({
        originalThemes: newState,
    }),
    switchThemes: (key) => (state, actions) => {
        let themes = state.themes
        if (themes.indexOf(+key) > -1) {
            themes.splice(themes.indexOf(+key), 1)
        } else {
            themes.push(+key)
        }
        actions.setThemes(themes)
        //
        for (var t in state.availableThemes) {
            if (
                (themes.indexOf(state.availableThemes[t].key) === -1 &&
                    state.originalThemes.indexOf(
                        state.availableThemes[t].key
                    ) === -1) ||
                (themes.indexOf(state.availableThemes[t].key) > -1 &&
                    state.originalThemes.indexOf(state.availableThemes[t].key) >
                        -1)
            ) {
                continue
            } else {
                actions.setEnableSaving(true)
                return
            }
        }
        actions.setEnableSaving(false)
    },
    updateThemes: () => (state, actions) => {
        Customers.updateCustomers(state.customerId, {
            themes: state.themes,
        }).then((res) => {
            if (isDefined(res.data) && !res.data.isError) {
                let customerThemes = res.data.themes
                actions.setThemes(customerThemes)
                actions.setOriginalThemes(deepCopy(customerThemes))
            }
            actions.setEnableSaving(false)
        })
    },
    displaySuccessMessage: (newState) => (state) => ({
        messageActive: newState,
    }),
    setCustomerId: (newState) => (state) => ({
        customerId: newState,
    }),
    setEnableSaving: (newState) => (state) => ({
        enableSaving: newState,
    }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout page="themes" customer={customer} currentPage={'content'}>
            {state.messageActive && (
                <MessagePopup
                    kind={state.messageKind}
                    active={'active'}
                    duration={3000}
                    durationCallback={() =>
                        actions.displaySuccessMessage(false)
                    }
                    message={t('Mise à jour réussie')}
                ></MessagePopup>
            )}

            <Form
                classes={'col-xs-12 sup-custom-form btzForm'}
                onsubmit={(event) => event.preventDefault()}
            >
                <p
                    class="sup-title"
                    style={{
                        'margin-bottom': '35px',
                        'text-transform': 'uppercase',
                    }}
                >
                    {t('Thématiques article')}
                </p>
                {state.availableThemes.map((t) => (
                    <div
                        class="themes-entry"
                        onclick={() => actions.switchThemes(t.key)}
                    >
                        {state.themes.indexOf(+t.key) > -1 ? (
                            <img
                                class=""
                                src={themeTicked}
                                srcset={`${themeTickedx2} 2x`}
                                alt=""
                            />
                        ) : (
                            <img
                                class=""
                                src={t.img.normal}
                                srcset={`${t.img.retina} 2x`}
                                alt=""
                            />
                        )}
                        <p class="">{t.value}</p>
                    </div>
                ))}
                {state.enableSaving && (
                    <Form.Group classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits">
                        <Button
                            primary
                            flat
                            active
                            onclick={actions.updateThemes}
                        >
                            {t('Valider')}
                        </Button>
                    </Form.Group>
                )}
            </Form>
        </IndexLayout>
    )

export default Component(state, actions, view, 'themes')

import { t, h, Component } from '@app/utils'
import { Button, AdminFormCheckBoxLinkedModal } from '@app/elements'
import { isDefined } from '@app/core'
import { AFScenarioSeuils } from './AFScenarioTypes/AFScenarioSeuils'
import { AFScenarioUpAndDown } from './AFScenarioTypes/AFScenarioUpAndDown'
import './index.scss'

const ViewModalConfirmSave = Component(
    {
        document: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div
            key={props.key + '-modal-confirmsave'}
            class={
                'btzModalCustomHDS btzModalCustomHDS-modal btzModalCustomHDS-modal-confirmsave ' +
                (props.readonly ? '' : 'supModalContainer')
            }
            style={{
                'padding': '0px',
                'min-width': '650px',
                'margin-left': '-90px',
            }}
        >
            <dummy>
                <div class="btzModalCustomHDS-header">
                    <label class="sup-label afscenario-header-title">
                        {t('Confirmation.')}
                    </label>
                    <p class="afscenario-header-subtitle">
                        {t(
                            'Vous êtes sur le point de modifier la configuration des seuils & alertes pour la mesure'
                        ) + ' '}
                        <b>
                            {state.document !== null
                                ? state.document.header.title
                                : 'default'}
                        </b>
                        <br />
                        {t('Confirmez-vous les valeurs suivantes ?')}
                    </p>
                </div>
                {isDefined(props.document.form.alerts) &&
                    isDefined(props.document.form.alerts.list) && (
                        <div class="btzModalCustomHDS-confirmsave-header">
                            <label
                                class="sup-label afscenario-header-title"
                                style={{
                                    'text-transform': 'initial',
                                    'margin': '10px 25px',
                                    'display': 'inline-block',
                                }}
                            >
                                {t('Vous souhaitez être alerté en cas de :')}
                            </label>
                            <div class="btzModalCustomHDS-confirmsave-header-triggers">
                                {props.document.form.alerts.list
                                    .filter(
                                        (f) =>
                                            props.document.form.alerts.value[
                                                f.id
                                            ] !== undefined
                                    )
                                    .map((l) => (
                                        <p>
                                            {props.document.header.title +
                                                ' ' +
                                                l.name}
                                        </p>
                                    ))}
                            </div>
                        </div>
                    )}
                <div
                    class=""
                    style={{ 'text-align': 'left', 'margin': '0px 25px' }}
                >
                    {props.document.form.template.value === 'seuils' ? (
                        <AFScenarioSeuils
                            key={
                                props.key +
                                '-afsseuils-' +
                                props.document.header.key
                            }
                            document={props.document}
                            readonly={true}
                            confirmsave={true}
                        ></AFScenarioSeuils>
                    ) : (
                        <AFScenarioUpAndDown
                            key={
                                props.key +
                                '-afsupanddown-' +
                                props.document.header.key
                            }
                            document={props.document}
                            readonly={true}
                            confirmsave={true}
                        ></AFScenarioUpAndDown>
                    )}
                </div>
            </dummy>
            <div class="alerting-obj-alert alerting-obj-alert-seuils">
                <div class="btzModalCustomHDS-buttons">
                    <Button
                        primary
                        cancel
                        flat
                        onclick={() => props.onConfirmCancel()}
                    >
                        {t('Annuler la configuration')}
                    </Button>
                    <Button
                        primary
                        flat
                        active
                        onclick={() => props.onConfirmSaveDocument()}
                    >
                        {t('Je confirme')}
                    </Button>
                </div>
            </div>
        </div>
    ),
    'viewmodalconfirmsave'
)

export { ViewModalConfirmSave }

import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getSurveys(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-survey/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}

function getSurvey(id) {
    return queueQueries(`${APP_API_URL}/admin/corpo-survey/${id}/`, 'GET')
}

function addSurvey(data) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-survey/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function editSurvey(id, data) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-survey/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function deleteSurvey(id) {
    return queueQueries(`${APP_API_URL}/admin/corpo-survey/${id}/`, 'DELETE')
}

function getChallenge(id) {
    return queueQueries(`${APP_API_URL}/admin/corpo-tournament/${id}/`, 'GET')
}

function addChallene(data) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-tournament/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function suggestChallengeMember(idchallenge, idteam, data) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-tournament/${idchallenge}/team/${idteam}/member/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function deleteChallengeMember(idchallenge, idteam, idmember) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-tournament/${idchallenge}/team/${idteam}/member/${idmember}/`,
        'DELETE'
    )
}

function deleteChallengeTeam(idchallenge, idteam) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-tournament/${idchallenge}/team/${idteam}/`,
        'DELETE'
    )
}

function editChallenge(id, data) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-tournament/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function deleteChallenge(id) {
    return queueQueries(`${APP_API_URL}/admin/corpo-tournament/${id}/`, 'DELETE')
}

function getChallenges(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${APP_API_URL}/admin/corpo-tournament/search/${page}/?country=fr`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}

export const Corpo = {
    getSurveys,
    getSurvey,
    addSurvey,
    editSurvey,
    deleteSurvey,
    getChallenge,
    getChallenges,
    addChallene,
    editChallenge,
    deleteChallenge,
    suggestChallengeMember,
    deleteChallengeMember,
    deleteChallengeTeam,
}

import { t, h, Component } from '@app/utils'
import { User } from '@app/api'
import { Button } from '@app/elements'

import icoNoAvatar from '@app/img/ico/ico-no-avatar.png'
import icoNoAvatarx2 from '@app/img/ico/ico-no-avatar@2x.png'

const ImageCropper = Component(
    {
        value: null,
        mediaType: 'image/png',
        currentCropping: null,
        height: 200,
        width: '100%',
        targetId: 'cropImg',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.height) {
                actions.setHeight(props.height)
            }
            if (props.width) {
                actions.setWidth(props.width)
            }
            if (props.media && props.media !== null) {
                actions.setValue(props.media)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.media && props.media !== null) {
                actions.setValue(props.media)
            }
        },
        convertImageToB64: (el) => (state, actions) => {
            let cropImg = null

            var f = el.target.files[0]
            var reader = new FileReader()
            reader.addEventListener(
                'load',
                function () {
                    cropImg = document.getElementById(state.targetId)
                    cropImg.src = reader.result

                    setTimeout(() => {
                        actions.initCropper()
                    }, 500)
                },
                false
            )
            reader.readAsDataURL(f)
        },

        initCropper: () => (state, actions) => {
            let cropImg = document.getElementById(state.targetId)

            let generalRefH = state.height
            let generalRefW = state.width
            let canvasHeight = 50
            let canvasWidth = 50
            let containerHeight = generalRefH
            let containerWidth = generalRefW

            let options = {
                aspectRatio: 1,
                viewMode: 1,
                minContainerHeight: containerHeight,
                minContainerWidth: containerWidth,
                minCanvasHeight: canvasHeight,
                minCanvasWidth: canvasWidth,
                built: function () {
                    cropImg.cropper('setCropBoxData', {
                        height: canvasHeight,
                        width: canvasWidth,
                    })
                    cropImg.cropper('setCanvasData', {
                        height: containerHeight,
                        width: containerWidth,
                    })
                },
            }
            // eslint-disable-next-line
            let cropper = new Cropper(cropImg, options);
            actions.setCurrentCropping(cropper)
        },

        onAvatarCreate: (el) => (state, actions) => {
            el.addEventListener('click', () => {
                let srcAvatar = document.querySelectorAll(
                    'input.cropit-image-input'
                )[0]
                srcAvatar.click()
            })
        },

        sendCropperImage: () => (state, actions) => {
            if (state.currentCropping === null) {
                return false
            }
            let imageData = state.currentCropping
                .getCroppedCanvas()
                .toDataURL(state.mediaType)

            function urltoFile(url, filename, mimeType) {
                return fetch(url)
                    .then(function (res) {
                        return res.arrayBuffer()
                    })
                    .then(function (buf) {
                        return new File([buf], filename, { type: mimeType })
                    })
            }

            urltoFile(imageData, 'avatar.png', state.mediaType).then(
                function (file) {
                    var data = {
                        media: [file],
                    }
                    User.sendAvatar(data).then((res) => {
                        let avaImg = document.getElementById('avatarThumb')
                        avaImg.src = res.data.url
                        state.value = res.data.url
                        actions.resetCurrentCropping()
                    })
                }
            )
        },

        resetCurrentCropping: () => (state, actions) => {
            actions.setCurrentCropping(null)
            state.currentCropping.reset()
            state.currentCropping.clear()
            state.currentCropping.destroy()
        },

        setCurrentCropping: (newState) => (state) => ({
            currentCropping: newState,
        }),

        setHeight: (newState) => (state) => ({
            height: newState,
        }),
        setWidth: (newState) => (state) => ({
            width: newState,
        }),
        setValue: (newState) => (state) => ({
            value: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div
            id="image-cropper"
            style={{ width: '100%', height: state.height + 'px' }}
        >
            <div
                style={{
                    display: state.currentCropping === null ? 'block' : 'none',
                }}
            >
                <div
                    class="cropit-preview"
                    oncreate={(el) => actions.onAvatarCreate(el)}
                >
                    {state.value !== null ? (
                        <div class="btzAvatarContainer">
                            <img
                                id="avatarThumb"
                                src={state.value}
                                alt=""
                                class=""
                            />
                            <div>
                                <p>{t('modifier ma photo')}</p>
                            </div>
                        </div>
                    ) : (
                        <div class="btzAvatarContainer">
                            <img
                                id="avatarThumb"
                                src={icoNoAvatar}
                                srcset={`${icoNoAvatarx2} 2x`}
                                alt=""
                                class=""
                            />
                            <div>
                                <p>{t('ajoutez une photo')}</p>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    id="select-image-btn"
                    class="bticon bticon-circled icon-ico-edit-simple avatarUploader"
                ></div>
            </div>
            <div
                id="select-image-btn-controls"
                style={{
                    display: state.currentCropping !== null ? 'block' : 'none',
                }}
            >
                <input
                    type="file"
                    class="cropit-image-input"
                    onchange={(el) => actions.convertImageToB64(el)}
                    style={{ display: 'none' }}
                />
                <img id="cropImg" alt="" class="" />

                <div class="lvb-buttons-wrapper">
                    <div
                        style={{
                            margin: '5px 20px 5px 5px',
                            display: 'inline-block',
                        }}
                    >
                        <Button
                            primary
                            flat
                            cancel
                            onclick={() => {
                                actions.resetCurrentCropping()
                            }}
                        >
                            {t('Annuler')}
                        </Button>
                    </div>
                    <div
                        style={{
                            margin: '5px 5px 5px 20px',
                            display: 'inline-block',
                        }}
                    >
                        <Button
                            primary
                            active
                            flat
                            onclick={() => {
                                actions.sendCropperImage()
                            }}
                        >
                            {t('Valider')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    ),
    'imagecropper'
)

export { ImageCropper }

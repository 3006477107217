import { t, h, Component } from '@app/utils'
import { Form, AFExtra, Button } from '@app/elements'
import { isDefined } from '@app/core'
import './index.scss'

const AdminFormExtra = Component(
    {
        sendToParent: null,
        name: '',
        extras: [],
        model: { key: null, value: null, order: 'order' },
        extramodel: { extras: '' },
        lang: '',
        list: [],
        placeholder: { title: '', description: '' },
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.oninput) {
                state.sendToParent = props.oninput
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.placeholder) {
                state.placeholder = props.placeholder
            }
            if (props.list) {
                actions.setList(props.list)
            }

            let extras = []
            if (
                props.document &&
                isDefined(props.document) &&
                Array.isArray(props.document)
            ) {
                extras = props.document
            }
            actions.setExtras(extras)

            if (props.name) {
                actions.setName(props.name)
            }
            if (isDefined(props.document)) {
                if (props.document.model) {
                    actions.setModel(props.document.model)
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.list) {
                actions.setList(props.list)
            }
            let extras = []
            if (
                props.document &&
                isDefined(props.document) &&
                Array.isArray(props.document)
            ) {
                extras = props.document
            }
            actions.setExtras(extras)
        },
        addExtra: () => (state, actions) => {
            let extras = state.extras
            extras.push(JSON.parse(JSON.stringify(state.extramodel)))
            actions.setExtras(extras)
        },
        onUpdateExtra: () => (state, actions) => {
            state.sendToParent({
                target: {
                    name: state.name,
                    value: state.extras,
                    lang: state.lang,
                },
            })
        },
        removeThisOne: (el) => (state, actions) => {
            let extras = state.extras
            let parent = el.target.closest('.afextra')
            let targetIndex = Array.prototype.indexOf.call(
                parent.parentElement.children,
                parent
            )
            extras.splice(targetIndex, 1)
            actions.setExtras(extras)
            actions.onUpdateExtra()
        },
        setExtras: (newState) => (state) => ({
            extras: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            <div>
                {state.extras.map((stp, idx) => (
                    <AFExtra
                        key={'stp-entry-' + idx}
                        document={stp}
                        name={state.name}
                        order={idx}
                        placeholder={state.placeholder}
                        model={state.model}
                        onclick={actions.onUpdateExtra}
                        ondelete={actions.removeThisOne}
                    ></AFExtra>
                ))}
            </div>

            <Form.Group classes="btzForm-btzFooter">
                <Button primary flat onclick={actions.addExtra}>
                    {t('Ajouter un extra')}
                </Button>
            </Form.Group>
        </div>
    ),
    'adminformextra'
)

export { AdminFormExtra }

import { h, Component } from '@app/utils'
import { isDefined } from '@app/core'
import { AFRadio } from '@app/elements'
import './index.scss'

const AdminFormRadio = Component(
    {
        sendToParent: null,
        selected: null,
        goodResponse: null,
        readonly: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                actions.setSendToParent(props.onclick)
            }

            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document !== null) {
                if (isDefined(props.force)) {
                    actions.setSelected(props.force)
                } else {
                    if (
                        isDefined(props.document.value) &&
                        isDefined(props.document.value.date)
                    ) {
                        actions.setSelected(1)
                    } else {
                        actions.setSelected(props.document.value)
                    }
                }
                if (props.document.goodResponse !== undefined) {
                    actions.setGoodResponse(props.document.goodResponse)
                }
            }
        },
        selectThisOne: (el) => (state, actions) => {
            let target = el.target || el
            let radioName = target.name
            let radioValue = target.value
            actions.setSelected(radioValue)
            console.log('SEND TO APRENT : ', target, radioName, radioValue)
            state.sendToParent({
                target: { name: radioName, value: radioValue, type: 'boolean' },
            })
        },
        setSendToParent: (newState) => (state) => ({
            sendToParent: newState,
        }),
        setSelected: (newState) => (state) => ({
            selected: newState,
        }),
        setGoodResponse: (newState) => (state) => ({
            goodResponse: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class="sup-group-radio">
            {props.document.choice && (
                <div class="">
                    {Object.keys(props.document.choice).map((rad) => (
                        <AFRadio
                            key={
                                'sup-group-radio-' +
                                (props.lang || 'default') +
                                '-' +
                                rad
                            }
                            readonly={props.readonly}
                            class={rad}
                            document={rad}
                            lang={props.lang}
                            choice={props.document.choice}
                            selected={state.selected}
                            goodResponse={state.goodResponse}
                            name={props.name}
                            onnclick={actions.selectThisOne}
                            desabled={props.document.desabled}
                        ></AFRadio>
                    ))}
                </div>
            )}
        </div>
    ),
    'adminformradio'
)

export { AdminFormRadio }

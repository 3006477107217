import { t, h, Component } from '@app/utils'
import {
    AdminFormECS,
    AdminFormEvalMaker,
    AdminFormStep,
    AdminFormExtra,
    AdminFormMedia,
    AdminFormPictures,
    AdminFormHelp,
} from '@app/elements'
import { isDefined, deepCopy } from '@app/core'

import './index.scss'

const AdminFormBlockAsTabLang = Component(
    {
        document: null,
        propsdoc: null,
        original: null,
        active: 'fr',
        sendToParent: null,
        kpiList: [],
        dataTags: [],
        setDisplayedLang: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('TABLANG : ', props)
            if (props.onclick) {
                props.sendToParent = props.onclick
            }
            if (props.setDisplayedLang) {
                state.setDisplayedLang = props.setDisplayedLang
            }
            state.propsdoc = props.document
            state.original = props.document
            //
            let document = Object.keys(props.document).map(
                (i) => props.document[i]
            )
            state.document = document.filter(
                (f) =>
                    isDefined(f) &&
                    isDefined(f.type) &&
                    f.type === 'translation'
            )
            state.countries = props.document.flags.value
            if (props.kpiList) {
                actions.setKpiList(props.kpiList)
            }
            if (props.dataTags) {
                actions.setDataTags(props.dataTags)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            state.propsdoc = props.document
            let document = Object.keys(props.document).map(
                (i) => props.document[i]
            )
            state.document = document.filter(
                (f) =>
                    isDefined(f) &&
                    isDefined(f.type) &&
                    f.type === 'translation'
            )
            // console.log('documentDD : ', document.filter((f) => (isDefined(f) && isDefined(f.type)) && (f.type === 'translation')));
            if (props.kpiList) {
                actions.setKpiList(props.kpiList)
            }
            if (props.dataTags) {
                actions.setDataTags(props.dataTags)
            }
        },
        onSynchEachLanguage:
            ({ lang, order }) =>
            (state, actions) => {
                return false
                //
                for (var entry in state.document) {
                    if (
                        state.document[entry].field === 'eval' ||
                        state.document[entry].field === 'evalClinical'
                    ) {
                        for (var formLang in state.document[entry].value) {
                            if (formLang !== lang) {
                                if (order === null) {
                                    if (
                                        isDefined(
                                            state.document[entry].value[lang]
                                        ) &&
                                        state.document[entry].value[formLang]
                                            .length <
                                            state.document[entry].value[lang]
                                                .length
                                    ) {
                                        state.document[entry].value[
                                            formLang
                                        ].push({
                                            media: null,
                                            name: '',
                                            tag: state.document[entry].value[
                                                formLang
                                            ][0].tag,
                                            value: state.document[entry].value[
                                                formLang
                                            ][0].value,
                                            min: state.document[entry].value[
                                                formLang
                                            ][0].min,
                                            max: state.document[entry].value[
                                                formLang
                                            ][0].max,
                                            default:
                                                state.document[entry].value[
                                                    formLang
                                                ][0].default,
                                            step: state.document[entry].value[
                                                formLang
                                            ][0].step,
                                        })
                                    }
                                } else {
                                    state.document[entry].value[
                                        formLang
                                    ].splice(order, 1)
                                }
                            }
                        }
                    }
                }
                console.log('state.document : ', state.document)
                //
                actions.setDocument(state.document)
            },
        onSynchEachLanguageTag:
            ({ lang, evals }) =>
            (state, actions) => {
                for (var entry in state.document) {
                    if (
                        state.document[entry].field === 'eval' ||
                        state.document[entry].field === 'evalClinical'
                    ) {
                        for (var formLang in state.document[entry].value) {
                            if (formLang !== lang) {
                                if (
                                    isDefined(state.document[entry].value[lang])
                                ) {
                                    state.document[entry].value[formLang] =
                                        deepCopy(evals)
                                }
                            }
                        }
                    }
                }
                //
                actions.setDocument(state.document)
            },
        setActiveBlock: (country) => (state, actions) => {
            if (isDefined(state.setDisplayedLang)) {
                state.setDisplayedLang(country.key)
            }
            actions.setActive(country.key)
        },
        setActive: (newState) => (state) => ({
            active: newState,
        }),
        setKpiList: (newState) => (state) => ({
            kpiList: newState,
        }),
        setDataTags: (newState) => (state) => ({
            dataTags: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
    },
    (state, actions) => (props, children) =>
        (
            <div>
                <div class="sup-country-header">
                    {state.countries.map((country) => (
                        <div
                            class={
                                'sup-country sup-' +
                                country.key +
                                (state.active === country.key ? ' active' : '')
                            }
                            onclick={() => {
                                actions.setActiveBlock(country)
                            }}
                        >
                            <img
                                src={country.flag.normal}
                                srcset={country.flag.retina}
                            />
                            <p class="">{t(country.label)}</p>

                            {country.canBeActivated && (
                                <label
                                    class="switch"
                                    data-name={'langs'}
                                    data-lang={country.key}
                                    value=""
                                    onclick={props.onclick}
                                >
                                    <input type="hidden" />
                                    <input
                                        type="checkbox"
                                        name={'langs'}
                                        data-name={'langs'}
                                        data-lang={country.key}
                                        value=""
                                        oncreate={(el) => {
                                            props.onncreate(el)
                                        }}
                                        checked={
                                            [
                                                'coach',
                                                'session',
                                                'step',
                                            ].indexOf(
                                                props.document.template.value
                                            ) > -1 || !isDefined(props.langs)
                                                ? true
                                                : props.langs.value[country.key]
                                        }
                                    />
                                    <div
                                        class="slider round"
                                        data-name={'langs'}
                                        data-lang={country.key}
                                        value=""
                                    ></div>
                                </label>
                            )}
                        </div>
                    ))}
                </div>
                <div class="sup-country-blocks">
                    {state.countries.map((country) => (
                        <div
                            key={'country-' + country.key}
                            class={
                                'sup-country sup-' +
                                country.label +
                                (state.active === country.key ? ' active' : '')
                            }
                        >
                            {state.document.map((npt) => (
                                <dummy style={{ 'vertical-align': 'top' }}>
                                    {(npt.dependsOn === undefined ||
                                        (npt.dependsOn !== undefined &&
                                            (state.propsdoc[npt.dependsOn.field]
                                                .value == npt.dependsOn.value ||
                                                (Array.isArray(
                                                    npt.dependsOn.value
                                                ) &&
                                                    npt.dependsOn.value.indexOf(
                                                        state.propsdoc[
                                                            npt.dependsOn.field
                                                        ].value
                                                    ) > -1) ||
                                                (Array.isArray(
                                                    state.propsdoc[
                                                        npt.dependsOn.field
                                                    ].value
                                                ) &&
                                                    state.propsdoc[
                                                        npt.dependsOn.field
                                                    ].value.indexOf(
                                                        npt.dependsOn.value
                                                    ) > -1 &&
                                                    (!isDefined(
                                                        npt.dependsOn.no
                                                    ) ||
                                                        (isDefined(
                                                            npt.dependsOn.no
                                                        ) &&
                                                            state.propsdoc[
                                                                npt.dependsOn
                                                                    .field
                                                            ].value.indexOf(
                                                                npt.dependsOn.no
                                                            ) === -1)))))) && (
                                        <div
                                            key={
                                                npt.name +
                                                '-' +
                                                npt.name +
                                                '-' +
                                                country.key
                                            }
                                            class={
                                                npt.name +
                                                '-' +
                                                npt.name +
                                                '-' +
                                                country.key +
                                                ' sup-group-form col-12' +
                                                (props.formSubmitted &&
                                                npt.validation !== undefined &&
                                                npt.validation.isValid === false
                                                    ? ' field-is-in-error'
                                                    : '')
                                            }
                                        >
                                            {npt.label !== '' && (
                                                <div
                                                    style={{
                                                        'display':
                                                            'inline-block',
                                                        'vertical-align': 'top',
                                                    }}
                                                >
                                                    <label class="sup-label">
                                                        {t(npt.label) +
                                                            (npt.validation !==
                                                                undefined &&
                                                            npt.validation
                                                                .mandatory ===
                                                                true
                                                                ? '*'
                                                                : '')}
                                                    </label>
                                                    <AdminFormHelp
                                                        editType={
                                                            props.editType
                                                        }
                                                        type={npt.type}
                                                        field={npt.name}
                                                        infos={npt.infos}
                                                        value={
                                                            npt.value[
                                                                country.key
                                                            ]
                                                        }
                                                    ></AdminFormHelp>
                                                </div>
                                            )}
                                            {npt.field === 'textarea' && (
                                                <div
                                                    class="sup-textarea"
                                                    style={{
                                                        'margin-left': '0px',
                                                    }}
                                                >
                                                    <textarea
                                                        key={
                                                            npt.name +
                                                            '-' +
                                                            npt.name +
                                                            '-' +
                                                            country.key
                                                        }
                                                        name={npt.name}
                                                        class={
                                                            'input-text ' +
                                                            (npt.value[
                                                                country.key
                                                            ] === undefined ||
                                                            npt.value[
                                                                country.key
                                                            ] === null
                                                                ? 'undefined'
                                                                : npt.value[
                                                                      country
                                                                          .key
                                                                  ].value)
                                                        }
                                                        data-lang={country.key}
                                                        placeholder={t(
                                                            npt.placeholder
                                                        )}
                                                        oninput={props.onclick}
                                                        oncreate={(el) => {
                                                            props.onncreate(el)
                                                        }}
                                                    >
                                                        {npt.value[country.key]}
                                                    </textarea>
                                                </div>
                                            )}
                                            {npt.field === 'ecs' && (
                                                <AdminFormECS
                                                    name={npt.name}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                    list={state.kpiList}
                                                ></AdminFormECS>
                                            )}
                                            {npt.field === 'eval' && (
                                                <AdminFormEvalMaker
                                                    key={
                                                        'eval-' +
                                                        npt.name +
                                                        '-' +
                                                        country.key
                                                    }
                                                    specialDisplay={npt.name}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    original={
                                                        props.document.original
                                                    }
                                                    onSynchEachLanguage={
                                                        actions.onSynchEachLanguage
                                                    }
                                                    onSynchEachLanguageTag={
                                                        actions.onSynchEachLanguageTag
                                                    }
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                    list={state.dataTags}
                                                    formsType={
                                                        props.document
                                                            .formsType ===
                                                        undefined
                                                            ? ''
                                                            : props.document
                                                                  .formsType
                                                                  .value
                                                    }
                                                    customer={props.customer}
                                                ></AdminFormEvalMaker>
                                            )}
                                            {npt.field === 'evalClinical' && (
                                                <AdminFormEvalMaker
                                                    key={
                                                        'evalClinical-' +
                                                        npt.name +
                                                        '-' +
                                                        country.key
                                                    }
                                                    formName={'formsClinical'}
                                                    specialDisplay={npt.name}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    original={
                                                        props.document.original
                                                    }
                                                    onSynchEachLanguage={
                                                        actions.onSynchEachLanguage
                                                    }
                                                    onSynchEachLanguageTag={
                                                        actions.onSynchEachLanguageTag
                                                    }
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                    list={state.dataTags}
                                                    formsType={
                                                        props.document
                                                            .formsType ===
                                                        undefined
                                                            ? ''
                                                            : props.document
                                                                  .formsType
                                                                  .value
                                                    }
                                                    customer={props.customer}
                                                ></AdminFormEvalMaker>
                                            )}
                                            {npt.field === 'forcedtag' && (
                                                <AdminFormEvalMaker
                                                    key={country.key}
                                                    specialDisplay={'forcedtag'}
                                                    forcedtag={'-1'}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    original={
                                                        props.document.original
                                                    }
                                                    onSynchEachLanguage={
                                                        actions.onSynchEachLanguage
                                                    }
                                                    onSynchEachLanguageTag={
                                                        actions.onSynchEachLanguageTag
                                                    }
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                    list={state.dataTags}
                                                    formsType={
                                                        props.document
                                                            .formsType ===
                                                        undefined
                                                            ? ''
                                                            : props.document
                                                                  .formsType
                                                                  .value
                                                    }
                                                    customer={props.customer}
                                                ></AdminFormEvalMaker>
                                            )}
                                            {npt.field === 'media' && (
                                                <AdminFormMedia
                                                    key={'field-' + npt.name}
                                                    name={npt.name}
                                                    lang={country.key}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    onclick={props.onclick}
                                                ></AdminFormMedia>
                                            )}
                                            {npt.field ===
                                                'mediaUrlGenerator' && (
                                                <AdminFormMedia
                                                    key={'field-' + npt.name}
                                                    name={npt.name}
                                                    lang={country.key}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    onclick={props.onclick}
                                                    particularity={
                                                        'mediaUrlGenerator'
                                                    }
                                                ></AdminFormMedia>
                                            )}
                                            {npt.field === 'pictures' && (
                                                <AdminFormPictures
                                                    key={'field-' + npt.name}
                                                    name={npt.name}
                                                    lang={country.key}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    onclick={props.onclick}
                                                    nMedia={npt.nMedia}
                                                ></AdminFormPictures>
                                            )}
                                            {npt.field === 'quizz' && (
                                                <AdminFormEvalMaker
                                                    key={country.key}
                                                    specialDisplay={'quizz'}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    original={npt}
                                                    onSynchEachLanguage={
                                                        actions.onSynchEachLanguage
                                                    }
                                                    onSynchEachLanguageTag={
                                                        actions.onSynchEachLanguageTag
                                                    }
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                    formsType={'Button'}
                                                ></AdminFormEvalMaker>
                                            )}
                                            {npt.field === 'step' && (
                                                <AdminFormStep
                                                    name={npt.name}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    placeholder={t(
                                                        npt.placeholder
                                                    )}
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                ></AdminFormStep>
                                            )}
                                            {npt.field === 'extra' && (
                                                <AdminFormExtra
                                                    name={npt.name}
                                                    document={
                                                        npt.value[country.key]
                                                    }
                                                    placeholder={
                                                        npt.placeholder
                                                    }
                                                    lang={country.key}
                                                    oninput={props.onclick}
                                                    onncreate={props.onncreate}
                                                ></AdminFormExtra>
                                            )}
                                            {[
                                                'textarea',
                                                'ecs',
                                                'eval',
                                                'evalClinical',
                                                'quizz',
                                                'yesno',
                                                'step',
                                                'extra',
                                                'media',
                                                'mediaUrlGenerator',
                                                'pictures',
                                                'forcedtag',
                                            ].indexOf(npt.field) === -1 && (
                                                <input
                                                    key={npt.name}
                                                    type={npt.name}
                                                    name={npt.name}
                                                    data-lang={country.key}
                                                    placeholder={t(
                                                        npt.placeholder
                                                    )}
                                                    oninput={props.onclick}
                                                    oncreate={(el) => {
                                                        props.onncreate(el)
                                                    }}
                                                    value={
                                                        npt.value[country.key]
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                </dummy>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        ),
    'adminformblockastablang'
)

export { AdminFormBlockAsTabLang }

import { t, h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { Card } from '@app/api'
import { allRoutes, addCSRFToken, getQueryVariable, isDefined } from '@app/core'
import './index.scss'
import * as definitions from '@app/definitions'
import * as constants from '@app/constants'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    initialized: false,
    documents: Object.keys(definitions.custo.ALERTS).map(
        (i) => definitions.custo.ALERTS[i]
    ),
    tableHead: documents.alert_CARD.tableHead,
    tableOptions: documents.alert_CARD.tableOptions,
    tableAllOptions: documents.alert_CARD.tableAllOptions,
    tableFilter: documents.alert_CARD.filter,
    header: '',
    document: { template: '' },
    display: '',
    editId: null,
    alerts: [],
    currentUrl: '',
    kind: 'alerts',
    modeDuplicate: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let alerts = Object.keys(constants.custo.ALERTS_CREATION).map(
            (i) => constants.custo.ALERTS_CREATION[i]
        )
        state.alerts = alerts
        let params = props === null ? null : props.match.params
        if (
            params === null ||
            params === undefined ||
            (params.id !== undefined && params.id === 'null')
        ) {
            actions.onComponentUpdate(props)
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        let params = props === null ? null : props.match.params
        if (params === null || params === undefined) {
            actions.setDisplay('table')
            actions.setInitialized(true)
        } else {
            let modeDuplicate = getQueryVariable('mode', false) === 'duplicate'
            actions.setModeDuplicate(modeDuplicate)
            let editType = getQueryVariable('type', false)
            if (isDefined(editType) && editType !== false) {
                actions.setDocument(
                    state.documents.filter((f) => +f.key === +editType)[0]
                        .document.form
                )
                actions.setEditType(editType)
            } else {
                actions.setEditType(null)
            }

            let alertid = params.id
            if (alertid) {
                if (alertid !== 'null') {
                    actions.setEditId(alertid)
                    actions.setDisplay('form')
                    actions.setInitialized(true)
                    Card.getAlert(alertid).then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setDocument(
                                state.documents.filter(
                                    (f) => +f.key === +res.data.type
                                )[0].document.form
                            )
                            if (modeDuplicate) {
                                res.data.id = undefined
                                if (res.data.name) {
                                    res.data.name = res.data.name + '-copy'
                                }
                                res.data.status = 0
                            }
                            actions.parseAndSetDocument(res.data)
                        }
                    })
                } else {
                    actions.setEditId(null)
                    actions.setDisplay('form')
                    actions.setInitialized(true)
                }
            } else {
                actions.setDisplay('table')
                actions.setInitialized(true)
            }
        }
    },
    parseAndSetDocument: (doc) => (state, actions) => {
        let document = JSON.parse(JSON.stringify(state.document))
        let sliderType = '',
            sliderTag = '',
            secondFieldName = ''
        for (var field in doc) {
            var fieldName = field
            var mappedFieldName = fieldName
            if (mappedFieldName === 'mappedEcs') {
                mappedFieldName = 'ecs'
            }
            field = doc[fieldName]
            if (field !== null && field !== undefined) {
                if (
                    document[mappedFieldName] &&
                    document[mappedFieldName] !== null &&
                    document[mappedFieldName] !== undefined
                ) {
                    if (
                        document[mappedFieldName].type === 'translation' &&
                        field[0] !== undefined &&
                        field[0].datas !== undefined
                    ) {
                        let lang = '',
                            name = '',
                            value = ''
                        let newValues = {}
                        for (var entry in field) {
                            sliderType = field[entry].type
                            sliderTag = field[entry].tag
                            for (var dat in field[entry].datas) {
                                lang = field[entry].datas[dat].lang
                                name = field[entry].datas[dat].name
                                value = field[entry].datas[dat].value
                                if (newValues[lang] === undefined) {
                                    newValues[lang] = []
                                }
                                newValues[lang].push({
                                    name: name,
                                    value: value,
                                    tag: sliderTag,
                                })
                            }
                        }

                        document[mappedFieldName].value = newValues
                    } else {
                        document[mappedFieldName].value = field
                        secondFieldName = document[mappedFieldName].secondName
                        if (isDefined(secondFieldName)) {
                            document[mappedFieldName].secondValue =
                                doc[secondFieldName]
                        }
                    }
                }
            }
        }
        if (sliderType !== '') {
            document.formsType.value = sliderType
        }
        actions.setDocument(document)
    },
    setCurrentUrl: (newState) => (state) => ({
        currentUrl: newState,
    }),
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
    setDisplay: (newState) => (state) => ({
        display: newState,
    }),
    setEditId: (newState) => (state) => ({
        editId: newState,
    }),
    setEditType: (newState) => (state) => ({
        editType: newState,
    }),
    setDocument: (newState) => (state) => ({
        document: newState,
    }),
    setInitialized: (newState) => (state) => ({
        initialized: newState,
    }),
    setModeDuplicate: (newState) => (state) => ({
        modeDuplicate: newState,
    }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout page="alerts" customer={customer} currentPage={'content'}>
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            type={'alert'}
                            head={state.tableHead}
                            filter={state.tableFilter}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Card.getAlerts}
                        ></Table>
                    ) : (
                        <div
                            class=""
                            key={
                                state.editId +
                                '-' +
                                state.initialized +
                                '-' +
                                Object.keys(state.document).length
                            }
                        >
                            {state.editId !== null ? (
                                <div
                                    class={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                    key={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    <AdminForm
                                        editType={'1'}
                                        document={state.document}
                                        target={
                                            state.modeDuplicate
                                                ? Card.addAlert
                                                : Card.editAlert
                                        }
                                        secondtarget={Card.sendCardForm}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.alerts'].pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            ) : (
                                <div>
                                    {state.editType !== null ? (
                                        <div key={'adminform-creation'}>
                                            <AdminForm
                                                editType={state.editType}
                                                document={state.document}
                                                target={Card.addAlert}
                                                secondtarget={Card.sendCardForm}
                                                card={state.document}
                                                backUrl={addCSRFToken(
                                                    allRoutes['private.alerts']
                                                        .pathname
                                                )}
                                            ></AdminForm>
                                        </div>
                                    ) : (
                                        <div>
                                            {state.alerts.map((alert) => (
                                                <div class="sup-content-card">
                                                    <a
                                                        href={addCSRFToken(
                                                            allRoutes[
                                                                alert.addurl
                                                            ].pathname.replace(
                                                                ':id',
                                                                'null'
                                                            ) +
                                                                '?type=' +
                                                                alert.type
                                                        )}
                                                    >
                                                        <div class="sup-content-card-header">
                                                            <p class="sup-content-card-header-title">
                                                                {t(alert.label)}
                                                            </p>
                                                        </div>
                                                        <div class="sup-content-card-infos">
                                                            {alert.infos.map(
                                                                (info) => (
                                                                    <div class="sup-content-card-info">
                                                                        <p class="sup-content-card-info-puce"></p>
                                                                        <p class="sup-content-card-info-content">
                                                                            {t(
                                                                                info
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                            <div class="sup-content-card-add">
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'alerts')

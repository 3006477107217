import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Corpo } from '@app/api'
import { addCSRFToken } from '@app/core'

const corpochallenge_CARD = {
    filter: ['status'],
    tableHead: [
        { key: 'name', label: 'ID' },
        { key: 'title', label: 'titre' },
        { key: 'startDate', label: 'date de début' },
        { key: 'endDate', label: 'date de fin' },
        { key: 'corpoChallengeType', label: 'Mécanique' },
        { key: 'corpoChallengeWinner', label: 'Gagnant' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/corpo/challenges/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action:
                addCSRFToken('/content/corpo/challenges/:id') +
                '&mode=duplicate',
        },
        {
            key: 'results',
            label: 'Voir résultats',
            kind: 'web',
            action: addCSRFToken('/content/challenges/:id/results'),
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Corpo.editChallenge,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Corpo.deleteChallenge,
        },
    ],
    tableAllOptions: {
        label: 'CHALLENGES SÉLECTIONNÉS',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Corpo.editChallenge,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Corpo.editChallenge,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Corpo.deleteChallenge,
            },
        ],
    },
    header: ['Configurer le challenge'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'evaluation_generique',
        },
        theme: {
            field: 'hidden',
            value: '99',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activation',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            display: {
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id du challenge',
            field: 'text',
            mandatory: true,
            placeholder: "Renseignez ici l'Id du challenge",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: "Veuillez renseigner l'Id du challenge",
                i18nMessage: t("Veuillez renseigner l'Id du challenge"),
            },
        },
        startDate: {
            name: 'startDate',
            type: 'corpocompare',
            label: 'Date de début',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner une date de début',
                i18nMessage: t('Veuillez renseigner une date de début'),
            },
            display: {
                mode: 'half',
            },
        },
        endDate: {
            name: 'endDate',
            type: 'corpocompare',
            label: 'Date de fin',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner une date de fin',
                i18nMessage: t('Veuillez renseigner une date de fin'),
            },
            display: {
                mode: 'half',
            },
        },
        type: {
            name: 'type',
            type: 'typeCorpoChallenge', //'int',
            label: 'Type de challenge',
            field: 'dropdown',
            list: constants.custo.CHALLENGE_TOURNAMENT_TYPES,
            placeholder: 'Faites dérouler la liste',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un type de challenge',
                i18nMessage: t('Veuillez sélectionner un type de challenge'),
            },
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        title: {
            name: 'title',
            type: 'string',
            label: 'Titre',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre challenge',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre challenge',
                i18nMessage: t(
                    'Veuillez renseigner le titre de votre challenge'
                ),
            },
        },
        description: {
            name: 'description',
            type: 'textarea',
            label: 'Résumé',
            field: 'textarea',
            placeholder: 'Renseignez ici le résumé de votre challenge',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le résumé de votre challenge',
                i18nMessage: t(
                    'Veuillez renseigner le résumé de votre challenge'
                ),
            },
            display: {
                mode: 'half',
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            labelinfo: '626x420px',
            field: 'media',
            value: '',
            display: {
                mode: 'half',
            },
        },
        targetCustom: {
            name: 'targetCustom',
            substitute: 'targetCustom',
            duplicateby: ['targetCustomWalk', 'targetCustomAltitude'],
            type: 'int',
            label: 'Distance à parcourir',
            field: 'int',
            value: '',
            placeholder: 'en mètres',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigner une valeur à atteindre',
                i18nMessage: t('Veuillez sélectionner une valeur à atteindre'),
            },
            dependsOn: {
                field: 'type',
                value: 'distance_target',
            },
        },
        targetCustomWalk: {
            name: '',
            duplicateof: 'targetCustom',
            type: 'int',
            label: 'Nombre de pas à atteindre',
            field: 'int',
            value: '',
            placeholder: 'en pas',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigner une valeur à atteindre',
                i18nMessage: t('Veuillez sélectionner une valeur à atteindre'),
            },
            dependsOn: {
                field: 'type',
                value: 'walk_target',
            },
        },
        targetCustomAltitude: {
            name: '',
            duplicateof: 'targetCustom',
            type: 'int',
            label: 'Dénivelé à atteindre',
            field: 'int',
            value: '',
            placeholder: 'en mètres',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigner une valeur à atteindre',
                i18nMessage: t('Veuillez sélectionner une valeur à atteindre'),
            },
            dependsOn: {
                field: 'type',
                value: 'altitude_target',
            },
        },
        maxOnTeam: {
            name: 'maxOnTeam',
            type: 'int',
            label: 'Taille des équipes',
            field: 'int',
            placeholder: 'personnes au maximum',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la taille de l‘équipe',
                i18nMessage: t('Veuillez renseigner la taille de l‘équipe'),
            },
        },
        teams: {
            name: 'teams',
            type: 'teams',
            label: '',
            field: 'teams',
            placeholder: '',
            value: '',
        },
    },
}

export { corpochallenge_CARD }

const Guid = () =>
    [...Array(8)]
        .map(() =>
            Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
        )
        .join('')

export { Guid }

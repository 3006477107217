import { h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { Card } from '@app/api'
import { allRoutes, addCSRFToken } from '@app/core'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.gamif_ACTIONS.form,
    initialized: false,
    tableHead: documents.gamif_ACTIONS.tableHead,
    tableOptions: documents.gamif_ACTIONS.tableOptions,
    tableAllOptions: documents.gamif_ACTIONS.tableAllOptions,
    tableFilter: documents.gamif_ACTIONS.filter,
    tableSearch: documents.gamif_ACTIONS.search,
    header: documents.gamif_ACTIONS.header,
    document: documents.gamif_ACTIONS,
    display: '',
    editId: null,
    actions: [],
    currentUrl: '',
    kind: 'gamification-point',
    modeDuplicate: false,
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Content/pages/actions')

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout page="actions" customer={customer} currentPage={'gamif'}>
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            search={state.tableSearch}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Card.getGamificationPoints}
                            updateUrl={actions.updateUrl}
                        ></Table>
                    ) : (
                        <div
                            class=""
                            key={
                                state.editId +
                                '-' +
                                state.initialized +
                                '-' +
                                Object.keys(state.document).length
                            }
                        >
                            {state.editId !== null ? (
                                <div
                                    class={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                    key={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        target={
                                            state.modeDuplicate
                                                ? Card.addGamificationPoint
                                                : Card.editGamificationPoint
                                        }
                                        secondtarget={Card.sendCardForm}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.gamif.actions']
                                                .pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            ) : (
                                <div key={'adminform-creation'}>
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        target={Card.addGamificationPoint}
                                        secondtarget={Card.sendCardForm}
                                        card={state.document}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.gamif.actions']
                                                .pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'gamifactions')

import { t, h, Component } from '@app/utils'
import { Form, AFStep, Button } from '@app/elements'
import { isDefined } from '@app/core'
import './index.scss'

const AdminFormStep = Component(
    {
        sendToParent: null,
        name: '',
        steps: [],
        model: { key: null, value: null, order: 'order' },
        stepmodel: {},
        lang: '',
        list: [],
        placeholder: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.oninput) {
                state.sendToParent = props.oninput
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.placeholder) {
                state.placeholder = props.placeholder
            }
            if (props.list) {
                actions.setList(props.list)
            }

            let steps = []
            if (
                props.document &&
                props.document !== undefined &&
                Array.isArray(props.document)
            ) {
                steps = props.document
            }
            actions.setSteps(steps)

            if (props.name) {
                actions.setName(props.name)
            }
            if (props.document !== undefined) {
                if (props.document.model) {
                    actions.setModel(props.document.model)
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.list) {
                actions.setList(props.list)
            }
            let steps = []
            if (
                props.document &&
                props.document !== undefined &&
                Array.isArray(props.document)
            ) {
                steps = props.document
            }
            actions.setSteps(steps)
        },
        addStep: () => (state, actions) => {
            let steps = state.steps
            let newStep = JSON.parse(JSON.stringify(state.stepmodel))
            newStep.order = steps.length
            newStep.value = ''
            steps.push(newStep)
            actions.setSteps(steps)
        },
        onUpdateOrder:
            ({ step, upOrDown }) =>
            (state, actions) => {
                let actualStepOrder = step.order
                let targetStepOrder = 0
                let steps = state.steps
                if (upOrDown === 'up') {
                    targetStepOrder = actualStepOrder - 1
                    steps.filter((f) => f.order === step.order)[0].order =
                        targetStepOrder
                    steps.filter((f) => f.order === targetStepOrder)[0].order =
                        actualStepOrder
                } else if (upOrDown === 'down') {
                    targetStepOrder = actualStepOrder + 1
                    steps.filter((f) => f.order === targetStepOrder)[0].order =
                        actualStepOrder
                    steps.filter((f) => f.order === step.order)[0].order =
                        targetStepOrder
                }
                actions.setSteps(steps)
            },
        onUpdateStep: () => (state, actions) => {
            state.sendToParent({
                target: {
                    name: state.name,
                    value: state.steps,
                    lang: state.lang,
                },
            })
        },
        removeThisOne: (el) => (state, actions) => {
            let steps = state.steps
            let parent = el.target.closest('.afstep')
            let targetIndex = Array.prototype.indexOf.call(
                parent.parentElement.children,
                parent
            )
            steps.splice(targetIndex, 1)
            for (var step in steps) {
                steps[step].order = +step
            }
            actions.setSteps(steps)
            actions.onUpdateStep()
        },
        setSteps: (newState) => (state) => ({
            steps: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            <div>
                {state.steps
                    .sort((a, b) =>
                        a.order === undefined ? -1 : b.order > a.order ? -1 : 1
                    )
                    .map((stp, idx) => (
                        <AFStep
                            key={'stp-entry-' + idx + '-' + stp.order}
                            document={stp}
                            name={state.name}
                            order={isDefined(stp.order) ? stp.order : idx}
                            placeholder={state.placeholder}
                            model={state.model}
                            onclick={actions.onUpdateStep}
                            ondelete={actions.removeThisOne}
                            onupdateorder={actions.onUpdateOrder}
                        ></AFStep>
                    ))}
            </div>

            <Form.Group classes="btzForm-btzFooter">
                <Button primary flat onclick={actions.addStep}>
                    {t('Ajouter une étape')}
                </Button>
            </Form.Group>
        </div>
    ),
    'adminformstep'
)

export { AdminFormStep }

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { User, Card, Consent, Settings } from '@app/api'
import { addCSRFToken, deepCopy } from '@app/core'

var userThemes = deepCopy(constants.custo.CARDS_TYPES)
for (var usert in userThemes) {
    if (
        constants.custo.CARDS_TYPES_USER_IGNORE.indexOf(userThemes[usert].key) >
        -1
    ) {
        delete userThemes[usert]
    }
}

const user_PATIENT = {
    filter: ['mobileDevices', 'customer', 'licence', 'registerStep'],
    search: { label: 'ID, KPI, Patients' },
    tableHead: [
        { key: 'lastName', label: 'nom' },
        { key: 'firstName', label: 'prénom' },
        { key: 'email', label: 'email' },
        { key: 'id', label: 'licence-id' },
        { key: 'customer', label: 'établissement' },
        { key: 'version', label: 'version' },
        { key: 'connexion', label: 'connexion' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/users/patients/:id'),
        },
        {
            key: 'clearCardType',
            label: 'Vider une carte',
            i18nlabel: t('Vider une carte'),
            kind: 'list-api',
            list: Object.keys(constants.custo.CARDS_CREATION).map((i) => {
                return {
                    key: constants.custo.CARDS_CREATION[i].type,
                    value: constants.custo.CARDS_CREATION[i].label,
                }
            }),
            action: Card.resetCardType,
        },
        {
            key: 'resiliatePatient',
            label: 'Résilier',
            kind: 'confirm',
            action: User.resiliateUser,
        },
        {
            key: 'deletePatient',
            label: 'Supprimer',
            kind: 'confirm',
            action: User.deleteUser,
        },
    ],
    tableAllOptions: {
        label: 'PATIENTS SÉLECTIONNÉS',
        list: [
            {
                key: 'clearCardType',
                label: 'Vider une carte',
                i18nlabel: t('Vider une carte'),
                kind: 'list-api',
                list: Object.keys(constants.custo.CARDS_CREATION).map((i) => {
                    return {
                        key: constants.custo.CARDS_CREATION[i].type,
                        value: constants.custo.CARDS_CREATION[i].label,
                    }
                }),
                action: Card.resetCardType,
            },
            {
                key: 'resiliatePatient',
                label: 'Résilier',
                kind: 'confirm',
                action: User.resiliateUser,
            },
        ],
    },
    header: ['Configurer un patient'],
    form: {
        'tabs': {
            type: 'string',
            field: 'tabs',
            value: [
                {
                    label: 'equipe',
                    key: 'accountnurseteam',
                    marker: 'listing-nurseteam',
                },
                {
                    label: 'kpi du compte',
                    key: 'accountkpi',
                    marker: 'listing-kpi',
                },
            ],
        },
        'id': {
            field: 'hidden',
            value: '',
        },
        'template': {
            field: 'hidden',
            value: 'patients',
        },
        'lname': {
            name: 'lname',
            type: 'string',
            label: 'Nom utilisé',
            field: 'readonly',
            placeholder: 'Renseignez ici le nom de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'fname': {
            name: 'fname',
            type: 'string',
            label: 'Prénom utilisé',
            field: 'readonly',
            placeholder: 'Renseignez ici le prénom de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'bname': {
            name: 'bname',
            type: 'string',
            label: 'Nom de naissance',
            field: 'readonly',
            placeholder: 'Renseignez ici le nom de naissance de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'birthFirstNames': {
            name: 'birthFirstNames',
            type: 'string',
            label: 'Prénom(s) de naissance',
            field: 'readonly',
            placeholder:
                'Renseignez ici le(s) prénom(s) de naissance de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'birthFirstName': {
            name: 'birthFirstName',
            type: 'string',
            label: '1er prénom de naissance',
            field: 'readonly',
            placeholder:
                'Renseignez ici le premier prénom de naissance de l‘utilisateur',
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'gender': {
            name: 'gender',
            type: 'int',
            label: 'Sexe',
            field: 'dropdown',
            readonly: true,
            list: {
                0: { key: 1, value: 'Homme' },
                1: { key: '0', value: 'Femme' },
                2: { key: '2', value: 'Indéterminé' },
            },
            value: '',
            display: {
                mode: 'full',
            },
        },
        'birthdate': {
            name: 'birthdate',
            type: 'date',
            label: 'Date de naissance',
            field: 'readonly',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            sending: false,
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'birthPlaceINSEE': {
            name: 'birthPlaceINSEE',
            type: 'string',
            label: 'Code INSEE',
            field: 'readonly',
            placeholder: 'Renseignez ici le code INSEE de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'phonePrefix': {
            name: 'phonePrefix',
            type: 'string',
            label: 'Préfix téléphonique',
            field: 'dropdown',
            placeholder: '',
            list: {
                0: { key: '+33', value: '+33' },
                1: { key: '+32', value: '+32' },
                2: { key: '+49', value: '+49' },
            },
            value: '+33',
            display: {
                mode: 'half',
            },
        },
        'phone': {
            name: 'phone',
            type: 'int',
            label: 'Téléphone',
            field: 'int',
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le téléphone de l‘utilisateur',
                i18nMessage: t(
                    'Veuillez renseigner le téléphone de l‘utilisateur'
                ),
            },
            display: {
                mode: 'half',
            },
        },
        'email': {
            name: 'email',
            type: 'string',
            label: 'Email',
            field: 'email',
            placeholder: 'Renseignez ici l‘email de l‘utilisateur',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'is-email',
                message: 'Veuillez renseigner l‘email de l‘utilisateur',
                i18nMessage: t('Veuillez renseigner l‘email de l‘utilisateur'),
            },
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'INS': {
            name: 'INS',
            type: 'string',
            label: 'Matricule INS',
            field: 'readonly',
            placeholder: 'Renseignez ici le matricule INS de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'INSOID': {
            name: 'INSOID',
            type: 'string',
            label: 'OID',
            field: 'readonly',
            placeholder: 'Renseignez ici le OID de l‘utilisateur',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'userTheraflow.socialSecurityNumber': {
            name: 'userTheraflow.socialSecurityNumber',
            type: 'string',
            label: 'Num. sécurité sociale',
            field: 'readonly',
            sending: false,
            placeholder: '',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'userTheraflow.hospitalId': {
            name: 'userTheraflow.hospitalId',
            type: 'string',
            label: 'Id patient unique',
            field: 'readonly',
            sending: false,
            placeholder: 'Renseignez ici l‘identifiant du patient',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'timezone': {
            name: 'timezone',
            type: 'timezone',
            label: 'Fuseau horaire',
            field: 'dropdown',
            placeholder: 'Aucun',
            readonly: true,
            list: 'dynamic',
            simplecall: true,
            apicall: Settings.getTimezones,
            apiparam: '',
            value: 'Europe/Paris',
            display: {
                mode: 'half',
            },
        },
        'locale': {
            name: 'locale',
            type: 'int',
            label: 'Langue préféré',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.LANGUAGES,
            value: 'FR',
            display: {
                mode: 'half',
            },
        },
        'metrics': {
            name: 'metrics',
            type: 'int',
            label: 'Unités poids',
            field: 'dropdown',
            readonly: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.METRICS_WIDTH,
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'heightMetrics': {
            name: 'heightMetrics',
            type: 'int',
            label: 'Unité taille',
            field: 'dropdown',
            readonly: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.METRICS_HEIGHT,
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'parent': {
            name: 'parent',
            type: 'customer',
            label: 'Établissement mère',
            field: 'dropdown',
            list: 'dynamic',
            readonly: true,
            sending: false,
            placeholder: '',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'customer': {
            name: 'customer',
            type: 'customer',
            label: 'Établissement fille',
            field: 'dropdown',
            list: 'dynamic',
            readonly: true,
            sending: false,
            placeholder: '',
            value: '',
            display: {
                mode: 'half',
            },
        },
        'userTheraflow.etapes': {
            name: 'userTheraflow.etapes',
            type: 'boolean',
            label: 'Programme étapes',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            desabled: 'desabled',
            sending: false,
            placeholder: '',
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'edsConsent': {
            name: 'edsConsent',
            type: 'boolean',
            label: 'Consentement santé',
            field: 'radio',
            choice: {
                'Accepté': true,
                'Refusé': false,
                'À demander': '',
            },
            desabled: 'desabled',
            sending: false,
            placeholder: '',
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'agreements': {
            name: 'agreements',
            type: 'array',
            label: 'Consentement',
            field: 'display-from-query',
            displayQuery: Consent.getUserLegalAgreements,
            displayParam: [
                { key: 'id', kind: 'dynamic' },
                { key: 'patient', kind: 'static' },
            ],
            placeholder: '',
            readonly: true,
            sending: false,
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'connexion': {
            name: 'connexion',
            type: 'date',
            label: 'Dernière visite',
            field: 'readonly',
            withHour: true,
            additionalFields: ['withHour', 'withStatus'],
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            sending: false,
            display: {
                mode: 'full',
            },
        },
        'devices': {
            name: 'devices',
            type: 'listing',
            label: 'Version app',
            field: 'listing',
            sending: false,
            value: [],
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'isTestData': {
            name: 'isTestData',
            type: 'boolean',
            label: 'Labelliser comme donnée de test',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            placeholder: '',
            value: false,
            display: {
                mode: 'half',
            },
        },
    },
}

export { user_PATIENT }

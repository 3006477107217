import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const challenge_CARD = {
    filter: ['duration'],
    search: { label: 'ID, KPI, Utilisateurs' },
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'duration', label: 'durée' },
        { key: 'title', label: 'titre' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/challenges/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/challenges/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editChallenge,
        },
        // {'key': 'frequency', 'label': 'Changer la fréquence', 'kind': 'list-api', 'list': Object.keys(constants.custo.FREQUENCY).map(i => constants.custo.FREQUENCY[i]), 'action': Card.editChallenge},
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteChallenge,
        },
    ],
    tableAllOptions: {
        label: 'DÉFIS SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteChallenge,
            },
        ],
    },
    header: ['Configurer le défi'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'challenge',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        theme: {
            name: 'theme',
            type: 'int',
            label: 'Thématique',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
        },
        duration: {
            name: 'duration',
            type: 'int',
            label: 'Période de validité',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_VALIDITY,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une période de validité',
                i18nMessage: t('Veuillez sélectionner une période de validité'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘article',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        why: {
            name: 'why',
            type: 'translation',
            label: 'Pourquoi',
            i18nLabel: t('Pourquoi'),
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le pourquoi de votre défi (entre 300 et 600 caractères)',
            i18nplaceholder: t(
                'Renseignez ici le pourquoi de votre défi (entre 300 et 600 caractères)'
            ),
            value: {},
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
    },
}

export { challenge_CARD }

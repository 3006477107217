import { t, h, Component } from '@app/utils'
import './index.scss'

const BotFooterView = Component(
    {
        customer: 'betterise',
        displayed: true,
        urlPdfCgu: '',
        urlPdfCga: '',
        contactMail: 'alfred@betterise.me',
        allRightReserved: 'Betterise Health Tech',
        todayYear: 'Ø',
        startYear: '2013',
    },
    {
        onComponentUpdate: (props) => (state, actions) => {
            if (props.customer) {
                if (props.customer === 'corpo') {
                    actions.setStartYear('2019')
                    actions.setAllRightReserved('Groupe VYV')
                }
                // if (props.customer === 'gjensidige') {
                //     actions.setUrlPdfCgu(allRoutes['pdf.iver.cgu'].pathname);
                //     actions.setAllRightReserved('Iver');
                //     actions.setContactMail('iver@gjensidige.no');
                // } else if (props.customer === 'partena') {
                //     actions.setUrlPdfCgu(allRoutes['pdf.partena.cgu'].pathname);
                //     actions.setAllRightReserved('Partenamut');
                //     actions.setContactMail('zoe@partenamutvitalite.be');
                // } else if (props.customer === 'auchan') {
                //     let lang = 'fr';
                //     actions.setUrlPdfCgu(allRoutes['pdf.auchan.cgu'].pathname.replace(':lang', lang));
                //     actions.setAllRightReserved('Auchan');
                // } else if (props.customer === 'cerba') {
                //     actions.setAllRightReserved('Cerballiance');
                //     actions.setContactMail('eliott@cerbahealthcare.fr');
                // }
                // actions.setCustomer(props.customer);
            }

            let d = new Date()
            actions.setTodayYear(d.getFullYear())
        },
        removeDisclamer: () => (state) => ({
            displayed: false,
        }),
        setStartYear: (newState) => (state) => ({
            startYear: newState,
        }),
        setUrlPdfCgu: (newState) => (state) => ({
            urlPdfCgu: newState,
        }),
        setUrlPdfCga: (newState) => (state) => ({
            urlPdfCga: newState,
        }),
        setContactMail: (newState) => (state) => ({
            contactMail: newState,
        }),
        setCustomer: (newState) => (state) => ({
            customer: newState,
        }),
        setAllRightReserved: (newState) => (state) => ({
            allRightReserved: newState,
        }),
        setTodayYear: (newState) => (state) => ({
            todayYear: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class="btzFooter-btzFooters">
            <p class="btzFooter-btzFooter">
                {t('Tous droits réservés') + ' ' + state.allRightReserved}{' '}
                {state.startYear} - {state.todayYear}
            </p>
        </div>
    ),
    'botfooterview'
)

export { BotFooterView }

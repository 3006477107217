/*
- màj le code 
- binder les actions correctement

*/

/**
 * Persist an object in Storage
 *
 * @param {any} name
 * @param {any} obj
 */
const Stored = (name, obj, driver = localStorage) => {
    const initialObj = obj
    const storedObj = JSON.parse(localStorage.getItem(name)) || {}

    const areArraysEqual = (first, second) =>
        JSON.stringify(first) === JSON.stringify(second)

    const isObjPresentInStorage = areArraysEqual(
        Object.keys(initialObj).sort(),
        Object.keys(storedObj).sort()
    )

    const choosenObj = isObjPresentInStorage ? storedObj : initialObj

    if (!isObjPresentInStorage) driver.setItem(name, JSON.stringify(initialObj))

    const proxiedObj = new Proxy(choosenObj, {
        get: (target, key) => {
            return target[key]
        },
        set: (target, key, value) => {
            return (target[key] = value)
        },
    })

    return proxiedObj
}

const withStorage =
    (opts) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        const options = {
            ...{
                driver: localStorage,
                name: 'default',
            },
            ...opts,
        }

        var enhancedActions = {
            initStorage: () => {
                let storedState = options.driver.getItem(options.name)
                if (storedState)
                    enhancedActions.restoreStorage(JSON.parse(storedState))

                addEventListener('unload', () => (state, actions) => {
                    enhancedActions.saveStorage(state)
                }),
                    addEventListener('popstate', () => (state, actions) => {
                        enhancedActions.saveStorage(state)
                    })
            },

            restoreStorage: () => (previousState) =>
                previousState || initialState,

            saveStorage: () => (state) =>
                options.driver.setItem(options.name, JSON.stringify(state)),
        }

        window.addEventListener('load', () => {
            enhancedActions.initStorage()
        })

        return nextApp(
            Stored(options.name, initialState),
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )
    }

export { withStorage, Stored }

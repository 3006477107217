import { t, h, Component } from '@app/utils'
import { Button, AdminFormCheckBoxLinkedModal } from '@app/elements'
import './index.scss'

const ViewModalConfirmDeleteWeight = Component(
    {
        //
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            //
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
    },
    (state, actions) => (props, children) => (
        <div
            key={props.key}
            class={
                'btzModalCustomHDS btzModalCustomHDS-modal ' +
                (props.readonly ? '' : 'supModalContainer')
            }
            style={{ 'padding': '0px', 'min-width': '450px' }}
        >
            <dummy>
                <div class="btzModalCustomHDS-header">
                    <label class="sup-label afscenario-header-title">
                        {t('Confirmation.')}
                    </label>
                    <p class="afscenario-header-subtitle">
                        {t(
                            'Vous êtes sur le point de supprimer une alerte liée au'
                        ) + ' '}
                        <b style={{ 'vertical-align': 'initial' }}>
                            {props.header !== undefined
                                ? props.header.title
                                : 'default'}
                        </b>
                    </p>
                    <p class="afscenario-header-subtitle">
                        {t('Confirmez-vous la suppression ?')}
                    </p>
                </div>
            </dummy>
            <div class="alerting-obj-alert alerting-obj-alert-seuils">
                <div class="btzModalCustomHDS-buttons">
                    <Button
                        primary
                        cancel
                        flat
                        onclick={() => props.onConfirmCancel()}
                    >
                        {t('Annuler la suppression')}
                    </Button>
                    <Button
                        primary
                        flat
                        onclick={() =>
                            props.onConfirmDeleteDocument(props.document)
                        }
                    >
                        {t('Je confirme')}
                    </Button>
                </div>
            </div>
        </div>
    ),
    'viewmodalconfirmdeleteweight'
)

export { ViewModalConfirmDeleteWeight }

import { h, Component } from '@app/utils'
import { deepCopy, isDefined } from '@app/core'
import { AdminFormRadio, AdminFormCheckBox } from '@app/elements'
import './index.scss'

const AdminFormLinkedSelect = Component(
    {
        name: '',
        document: null,
        isSelected: false,
        sendToParent: null,
        checkedboxs: [],
        linkedDocument: [],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.sendToParent) {
                state.sendToParent = props.sendToParent
            }
            if (props.label) {
                state.name = props.label
            }
            //
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                let doc = props.document
                if (props.linkedDocument) {
                    doc.list = deepCopy(props.linkedDocument)
                }
                actions.setDocument(deepCopy(doc))
            }
            //
            let checked = []
            let defaulted = []
            let statued = []
            for (var lk in props.linkedDocument) {
                if (props.linkedDocument[lk].checked) {
                    checked.push(props.linkedDocument[lk].id)
                    if (props.linkedDocument[lk].bydefault) {
                        defaulted.push(props.linkedDocument[lk].id)
                    }
                }
                if (props.linkedDocument[lk].isactive) {
                    statued.push(props.linkedDocument[lk].id)
                }
            }
            actions.setCheckedBoxs(deepCopy(checked))
            actions.setDefaulted(deepCopy(defaulted))
            actions.setStatued(deepCopy(statued))
        },
        updateDocument: (el) => (state, actions) => {
            let doc = state.document
            if (+el.target.value === 0) {
                doc.value = 0
            } else if (+el.target.value === 1) {
                doc.value = 1
            }
            actions.setDocument(doc)
            state.sendToParent({
                target: {
                    name: state.name,
                    kind: 'active',
                    value: +el.target.value,
                },
            })
        },
        updateLinkedDocument: (el) => (state, actions) => {
            if (el.target.value.indexOf('bydefault') > -1) {
                state.sendToParent({
                    target: {
                        name: state.name,
                        kind: 'bydefault',
                        value: el.target.value,
                    },
                })
            } else {
                state.sendToParent({
                    target: {
                        name: state.name,
                        kind: 'linkeddoc',
                        value: el.target.value,
                    },
                })
            }
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setCheckedBoxs: (newState) => (state) => ({
            checkedboxs: newState,
        }),
        setDefaulted: (newState) => (state) => ({
            defaulted: newState,
        }),
        setStatued: (newState) => (state) => ({
            statued: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.label + '-' + props.key}>
            <div
                style={{
                    'display': 'inline-block',
                    'vertical-align': 'top',
                }}
            >
                <label class="sup-label">{props.label}</label>
            </div>
            <AdminFormRadio
                name={''}
                document={state.document}
                onclick={actions.updateDocument}
                force={props.active}
            ></AdminFormRadio>
            {props.active === 1 &&
                isDefined(state.document.list) &&
                isDefined(state.document.list[0]) && (
                    <dummy>
                        <AdminFormCheckBox
                            key={props.label + '-module-checkbox-' + props.key}
                            name={props.label + '-checkedboxs'}
                            document={state.document}
                            checkedboxs={state.checkedboxs}
                            defaultcheckedboxs={state.defaulted}
                            statuscheckedboxs={state.statued}
                            onclick={actions.updateLinkedDocument}
                        ></AdminFormCheckBox>
                    </dummy>
                )}
        </div>
    ),
    'adminformlinkedselect'
)

export { AdminFormLinkedSelect }

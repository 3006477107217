import { h, Component } from '@app/utils'
import { Table, AdminForm } from '@app/elements'
import { Card, Programs } from '@app/api'
import { allRoutes, addCSRFToken } from '@app/core'
import './index.scss'
import * as definitions from '@app/definitions'
import * as constants from '@app/constants'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.program_CARD.form,
    initialized: false,
    documents: Object.keys(definitions.custo.STEP_CATEGORIES).map(
        (i) => definitions.custo.STEP_CATEGORIES[i]
    ),
    tableHead: documents.program_CARD.tableHead,
    tableOptions: documents.program_CARD.tableOptions,
    tableAllOptions: documents.program_CARD.tableAllOptions,
    tableFilter: documents.program_CARD.filter,
    header: documents.program_CARD.header,
    document: documents.program_CARD,
    secondDocument: {
        urlKey: 'session',
        document: documents.program_session_CARD,
    },
    thirdDocument: { urlKey: 'step', document: documents.program_step_CARD },
    originaldocument: null,
    display: '',
    editId: null,
    editType: null,
    programs: [],
    cards: Object.keys(constants.custo.STEPS_CREATION).map(
        (i) => constants.custo.STEPS_CREATION[i]
    ),
    currentUrl: '',
    kind: 'programs',
    refId: null,
    refSId: null,
    refSSId: null,
    previousPage: null,
    modeDuplicate: false,
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Content/pages/actions')

const view =
    (state, actions) =>
    ({ location, match, customer }, children) => (
        <IndexLayout
            page={
                location.pathname.indexOf('step') > -1
                    ? 'programs steps'
                    : location.pathname.indexOf('session') > -1
                    ? 'programs sessions'
                    : 'programs'
            }
            customer={customer}
            currentPage={'content'}
        >
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    {state.display === 'table' ? (
                        <Table
                            head={state.tableHead}
                            filter={state.tableFilter}
                            options={state.tableOptions}
                            allOptions={state.tableAllOptions}
                            target={Card.getPrograms}
                            updateUrl={actions.updateUrl}
                        ></Table>
                    ) : (
                        <div
                            class=""
                            key={
                                state.editId +
                                '-' +
                                state.initialized +
                                '-' +
                                Object.keys(state.document).length
                            }
                        >
                            {state.refSSId !== null ||
                            (state.refSId !== null &&
                                location.pathname.indexOf('sessions/') > -1 &&
                                location.pathname.indexOf('step') === -1) ||
                            (state.editId !== null &&
                                location.pathname.indexOf('sessions') === -1 &&
                                location.pathname.indexOf('step') === -1) ? (
                                <div
                                    class={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                    key={
                                        'adminform-edition-' +
                                        Object.keys(state.document).length
                                    }
                                >
                                    <AdminForm
                                        header={state.header}
                                        editType={'1'}
                                        document={state.document}
                                        header={state.header}
                                        previousPage={state.previousPage}
                                        refId={state.refId}
                                        refSId={state.refSId}
                                        refSSId={state.refSSId}
                                        originaldocument={
                                            state.originaldocument
                                        }
                                        target={
                                            location.pathname.indexOf('step/') >
                                            -1
                                                ? Programs.editProgramSessionStep
                                                : location.pathname.indexOf(
                                                      'session'
                                                  ) > -1
                                                ? Programs.editProgramSession
                                                : state.modeDuplicate
                                                ? Card.addProgram
                                                : Card.editProgram
                                        }
                                        secondtarget={Card.sendCardForm}
                                        backUrl={addCSRFToken(
                                            allRoutes['private.programs']
                                                .pathname
                                        )}
                                    ></AdminForm>
                                </div>
                            ) : (
                                <div>
                                    {location.pathname.indexOf('step') > -1 &&
                                    (state.refSSId === null ||
                                        state.refSSId === false) &&
                                    (state.editType === null ||
                                        state.editType === false) ? (
                                        <div>
                                            {state.cards.map((card) => (
                                                <div class="sup-content-card">
                                                    <a
                                                        href={addCSRFToken(
                                                            allRoutes[
                                                                'private.programs.sessions.steps'
                                                            ].pathname
                                                                .replace(
                                                                    ':id',
                                                                    state.refId
                                                                )
                                                                .replace(
                                                                    ':sid',
                                                                    state.refSId
                                                                ) +
                                                                '?type=' +
                                                                card.type
                                                        )}
                                                    >
                                                        <div class="sup-content-card-header">
                                                            <p class="sup-content-card-header-title">
                                                                {card.label}
                                                            </p>
                                                            <p class="sup-content-card-header-kpi">
                                                                kpi
                                                            </p>
                                                        </div>
                                                        <div class="sup-content-card-infos">
                                                            {card.infos.map(
                                                                (info) => (
                                                                    <div class="sup-content-card-info">
                                                                        <p class="sup-content-card-info-puce"></p>
                                                                        <p class="sup-content-card-info-content">
                                                                            {
                                                                                info
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                            <div class="sup-content-card-add">
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div key={'adminform-creation'}>
                                            <AdminForm
                                                header={state.header}
                                                editType={'1'}
                                                document={state.document}
                                                header={state.header}
                                                previousPage={
                                                    state.previousPage
                                                }
                                                refId={state.refId}
                                                refSId={state.refSId}
                                                originaldocument={
                                                    state.originaldocument
                                                }
                                                target={
                                                    location.pathname.indexOf(
                                                        'step'
                                                    ) > -1
                                                        ? Programs.addProgramSessionStep
                                                        : location.pathname.indexOf(
                                                              'session'
                                                          ) > -1
                                                        ? Programs.addProgramSession
                                                        : Card.addProgram
                                                }
                                                secondtarget={Card.sendCardForm}
                                                card={state.document}
                                                backUrl={addCSRFToken(
                                                    allRoutes[
                                                        'private.programs'
                                                    ].pathname
                                                )}
                                            ></AdminForm>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'programs')

import { h, Component } from '@app/utils'
import { allRoutes, addCSRFToken, isDefined } from '@app/core'
import { settings } from '@app/core/settings'
import { Link } from '@app/elements'

const { APP_CUSTOMISATION, APP_WEB_URL } = settings

const TopLogoView = Component(
    {
        customer: 'betterise',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.customer) {
                actions.setCustomer(props.customer)
            } else {
                actions.setCustomer(APP_CUSTOMISATION.toLowerCase())
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.customer) {
                actions.setCustomer(props.customer)
            } else {
                actions.setCustomer(APP_CUSTOMISATION.toLowerCase())
            }
        },
        setCustomer: (newState) => (state) => ({
            customer: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <ul id="btzMainTopLogo">
            <li style={{ display: 'block' }}>
                <Link
                    class={'btn btn-link'}
                    to={addCSRFToken(
                        isDefined(localStorage.getItem('apitoken'))
                            ? allRoutes['private.index'].pathname
                            : allRoutes['index'].pathname
                    )}
                    style={{ opacity: 1 }}
                >
                    {props.color && props.color === 'black' ? (
                        <img
                            src={`${APP_WEB_URL}/__custo/${state.customer}/logo-btr-black.png`}
                            srcset={`${APP_WEB_URL}/__custo/${state.customer}/logo-btr-black@2x.png`}
                            alt=""
                        />
                    ) : (
                        <img
                            src={`${APP_WEB_URL}/__custo/${state.customer}/logo-small.png`}
                            srcset={`${APP_WEB_URL}/__custo/${state.customer}/logo-small@2x.png`}
                            alt=""
                        />
                    )}
                </Link>
            </li>
        </ul>
    ),
    'toplogoview'
)

export { TopLogoView }

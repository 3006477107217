import { t, h } from '@app/utils'
import { allRoutes, addCSRFToken } from '@app/core'
import { Link } from '@app/elements'

export const TopMenuView = (props, actions) => (
    <div style={{ 'display': 'table-cell', 'vertical-align': 'middle' }}>
        {props.customer === 'betterise' && (
            <ul>
                <li class={props.currentPage == 'content' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.content'].pathname)}
                    >
                        {t('Contenu')}
                    </Link>
                </li>
                <li class={props.currentPage == 'users' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.users'].pathname)}
                    >
                        {t('Utilisateurs')}
                    </Link>
                </li>
                <li class={props.currentPage == 'reward' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.reward'].pathname)}
                    >
                        {t('Reward')}
                    </Link>
                </li>
                <li class={props.currentPage == 'gamif' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.gamif'].pathname)}
                    >
                        {t('Gamif')}
                    </Link>
                </li>
                {/*<li class={props.currentPage == 'alfred' ? 'active' : ''}><Link to={addCSRFToken(allRoutes['private.alfred'].pathname)}>{t('Smartalfred')}</Link></li>*/}
            </ul>
        )}

        {props.customer === 'maternite' && (
            <ul>
                <li class={props.currentPage == 'content' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.content'].pathname)}
                    >
                        {t('Contenu')}
                    </Link>
                </li>
                <li class={props.currentPage == 'users' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.users'].pathname)}
                    >
                        {t('Utilisateurs')}
                    </Link>
                </li>
                <li class={props.currentPage == 'forum' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(
                            allRoutes['private.forum.forumfr'].pathname
                        )}
                    >
                        {t('Forum')}
                    </Link>
                </li>
                {/*<li class={props.currentPage == 'alfred' ? 'active' : ''}><Link to={addCSRFToken(allRoutes['private.alfred'].pathname)}>{t('Smartalfred')}</Link></li>*/}
            </ul>
        )}

        {props.customer === 'diabetes' && (
            <ul>
                <li class={props.currentPage == 'content' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.content'].pathname)}
                    >
                        {t('Contenu')}
                    </Link>
                </li>
                <li class={props.currentPage == 'users' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.users'].pathname)}
                    >
                        {t('Utilisateurs')}
                    </Link>
                </li>
                <li class={props.currentPage == 'forum' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(
                            allRoutes['private.forum.forumfr'].pathname
                        )}
                    >
                        {t('Forum')}
                    </Link>
                </li>
                {/*<li class={props.currentPage == 'alfred' ? 'active' : ''}><Link to={addCSRFToken(allRoutes['private.alfred'].pathname)}>{t('Smartalfred')}</Link></li>*/}
            </ul>
        )}
        {props.customer === 'corpo' && (
            <ul>
                <li class={props.currentPage == 'content' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.content'].pathname)}
                    >
                        {t('Contenu')}
                    </Link>
                </li>
                <li class={props.currentPage == 'users' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.users'].pathname)}
                    >
                        {t('Utilisateurs')}
                    </Link>
                </li>
            </ul>
        )}

        {props.customer === 'theraflow' && (
            <ul>
                <li class={props.currentPage == 'content' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(allRoutes['private.content'].pathname)}
                    >
                        {t('Contenu')}
                    </Link>
                </li>
                <li class={props.currentPage == 'users' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(
                            allRoutes['private.users.patients'].pathname
                        )}
                    >
                        {t('Utilisateurs')}
                    </Link>
                </li>
                <li class={props.currentPage == 'statistiques' ? 'active' : ''}>
                    <Link
                        to={addCSRFToken(
                            allRoutes['private.statistiques'].pathname
                        )}
                    >
                        {t('Statistiques')}
                    </Link>
                </li>
                {/*<li class={props.currentPage == 'alfred' ? 'active' : ''}><Link to={addCSRFToken(allRoutes['private.alfred'].pathname)}>{t('Smartalfred')}</Link></li>*/}
            </ul>
        )}
    </div>
)

import { h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { trimSpecialChar, isDefined } from '@app/core'
import './index.scss'

const AdminFormWithDropDownToDiv = Component(
    {
        ulDisplayed: false,
        list: [],
        listKind: 'static',
        selected: [],
        expression: '',
        model: { key: null, value: null },
        name: '',
        type: '',
        currentSearch: '',
        sendToParent: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document.name) {
                state.name = props.document.name
            }
            if (props.document.type) {
                state.type = props.document.type
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.document.model) {
                actions.setModel(props.document.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            actions.defineList(props)

            if (props.document.value) {
                if (Array.isArray(props.document.value)) {
                    if (props.document.model.key !== null) {
                        let newEntry = {}
                        let newEntries = []
                        for (var entry in props.document.value) {
                            newEntry = {}
                            newEntry[props.document.model.key] =
                                props.document.value[entry][
                                    props.document.model.key
                                ]
                            newEntry[props.document.model.value] =
                                props.document.value[entry][
                                    props.document.model.value
                                ]
                            //
                            if (
                                newEntry[props.document.model.key] ===
                                    undefined ||
                                newEntry[props.document.model.value] ===
                                    undefined
                            ) {
                                let searchedValue = props.document.value[entry]
                                if (!isNaN(+props.document.value[entry])) {
                                    searchedValue = +props.document.value[entry]
                                }
                                newEntry[props.document.model.key] =
                                    searchedValue

                                let newValue = state.list.filter(
                                    (f) => +f.key === +searchedValue
                                )[0]
                                if (newValue !== undefined) {
                                    newEntry[props.document.model.value] =
                                        newValue.value
                                }
                            }
                            if (
                                newEntry[props.document.model.value] ===
                                undefined
                            ) {
                                newEntry[props.document.model.value] =
                                    newEntry[props.document.model.key]
                            }
                            newEntries.push(newEntry)
                        }
                        actions.setSelected(newEntries)
                    } else {
                        actions.setSelected(props.document.value)
                    }
                } else {
                    actions.setExpression(props.document.value)
                }
            }
        },
        selectThisOne:
            ({ el, props }) =>
            (state, actions) => {
                let found = false
                let selected = state.selected
                for (var sel in state.selected) {
                    if (
                        (el.key !== undefined &&
                            state.selected[sel].key ===
                                trimSpecialChar(el.key)) ||
                        state.selected[sel] === trimSpecialChar(el)
                    ) {
                        found = true
                    }
                }
                if (!found) {
                    selected.push(el)
                }

                let value = ''
                value = selected.map((s) => s)

                state.sendToParent({
                    target: { name: state.name, value: trimSpecialChar(value) },
                })
                actions.toggleUl(false)
            },
        createThisOne:
            ({ props }) =>
            (state, actions) => {
                actions.toggleUl(false)
            },
        removeThisOne:
            ({ el, props }) =>
            (state, actions) => {
                for (var sel in state.selected) {
                    if (
                        (state.selected[sel].id !== undefined &&
                            state.selected[sel].id === el.id) ||
                        state.selected[sel] === el
                    ) {
                        let selected = state.selected
                        selected.splice(sel, 1)
                        let value = ''
                        if (selected.length > 0) {
                            value = selected.map((s) => s)
                        }
                        state.sendToParent({
                            target: { name: state.name, value: value },
                        })
                        break
                    }
                }
                actions.toggleUl(false)
            },
        defineList: (props) => (state, actions) => {
            let list = props.list
            if (list === undefined) {
                list = props.document.list
            }
            if (list === 'dynamic') {
                if (props.document.name === 'families') {
                    list = props.ciqualFamily
                }
            }
            if (isDefined(list)) {
                list = Object.keys(list).map((i) =>
                    list[i] === 0 ? i : list[i]
                )
                if (isDefined(list)) {
                    state.list = list
                    actions.setList(list)
                }
            }
        },
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setListKind: (newState) => (state) => ({
            listKind: newState,
        }),
        setCurrentSearch: (newState) => (state) => ({
            currentSearch: newState,
        }),
        setExpression: (newState) => (state) => ({
            expression: newState,
        }),
        setSelected: (newState) => (state) => ({
            selected: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        onUpdate: (value) => (state, actions) => {
            state.selected.name = value
        },
        onBlur: () => (state, actions) => {
            // var target = (el.target || el);
            // state.sendToParent({'target': {'name': state.name, 'value': target.value}});
        },
        waitForLastInput: ({ target }) =>
            debounce(500, 'onUpdate', target.value),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            <div
                class="sup-custom-select sup-custom-select-wide-one"
                tabindex="0"
                onblur={() => {
                    actions.toggleUl(false)
                }}
            >
                <div style={{ height: '30px' }}>
                    <div
                        class="sup-custom-select-default-label"
                        data-key-value={props.document.value}
                        data-val-value={props.document.value}
                        onclick={() => {
                            actions.toggleUl(!state.ulDisplayed)
                        }}
                    >
                        {'Faites dérouler la liste'}
                    </div>
                </div>
                <div
                    class="sup-drop-down"
                    style={{ 'margin-top': '-30px' }}
                    onclick={() => {
                        actions.toggleUl(!state.ulDisplayed)
                    }}
                ></div>
                <ul
                    class="sup-custom-select-ul"
                    style={{
                        display: state.ulDisplayed ? 'block' : 'none',
                        margin: '0px',
                    }}
                >
                    {state.list
                        .filter(
                            (li) =>
                                (state.model.key === null
                                    ? li !== ''
                                    : li[state.model.value] !== '') ||
                                ((state.model.key === null
                                    ? li
                                    : li[state.model.value]) !== '' &&
                                    ((state.currentSearch !== '' &&
                                        (state.model.key === null
                                            ? li
                                            : li[state.model.value]
                                        ).indexOf(state.currentSearch) > -1) ||
                                        state.currentSearch === ''))
                        )
                        .map((l) => (
                            <li
                                class="sup-custom-select-li"
                                data-value={
                                    state.model.key === null
                                        ? l
                                        : l[state.model.key]
                                }
                                onclick={() => {
                                    actions.selectThisOne({
                                        el: l,
                                        props: props,
                                    })
                                }}
                            >
                                {state.model.key === null
                                    ? l
                                    : l[state.model.value]}
                            </li>
                        ))}
                </ul>
            </div>
            <div class="sup-textarea">
                <div class="" style="white-space: normal;">
                    {state.selected.map((s) => (
                        <div
                            class="sup-kpis-aliments-wrapper"
                            onclick={() => {
                                actions.removeThisOne({ el: s, props: props })
                            }}
                        >
                            {props.document.type === 'theme' ? (
                                <dummy>
                                    <input
                                        class="sup-kpis-aliments"
                                        name={props.name}
                                        type="hidden"
                                        value={s.key}
                                        readonly=""
                                        oncreate={() => {
                                            actions.createThisOne({
                                                props: props,
                                            })
                                        }}
                                    />
                                    {s.value}
                                </dummy>
                            ) : (
                                <dummy>
                                    <input
                                        class="sup-kpis-aliments"
                                        name={props.name}
                                        type="hidden"
                                        value={
                                            state.model.key === null
                                                ? s
                                                : s[state.model.key]
                                        }
                                        readonly=""
                                        oncreate={() => {
                                            actions.createThisOne({
                                                props: props,
                                            })
                                        }}
                                    />
                                    {state.model.value === null
                                        ? s
                                        : s[state.model.value]}
                                </dummy>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ),
    'adminformwithdropdowntodiv'
)

export { AdminFormWithDropDownToDiv }

import * as constants from '@app/constants'
export const state = {
    values: {
        email: '',
        password: '',
    },
    isTouched: {
        email: false,
        password: false,
    },
    isValid: {
        email: false,
        password: false,
    },
    api: {
        message: '',
        code: 200,
        kind: 'info',
    },
    showResetPwd: false,
    isPasswordContentVisible: true,
    message: [],
    formIsSubmitting: false,
    formSubmittedAtLeastOneTime: false,
    sendToParent: null,
    rendering: false,
    targetapi: {
        value: 'theraflow-api.betterise.me',
        name: 'overrideapi',
        list: constants.custo.APIS,
    },
    targetinstance: {
        value: 'release',
        name: 'overrideinstance',
        list: constants.custo.INSTANCES,
    },
}

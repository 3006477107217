import { h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { Card, AutoComplete } from '@app/api'
import { isDefined } from '@app/core'
import './index.scss'

const AdminFormWithDynInputToDiv = Component(
    {
        ulDisplayed: false,
        list: [],
        listKind: 'static',
        selected: [],
        expression: '',
        model: { key: null, value: null },
        name: '',
        type: '',
        currentSearch: '',
        sendToParent: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document.type) {
                state.type = props.document.type
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.document.name) {
                actions.setName(props.document.name)
            }
            if (props.document.model) {
                actions.setModel(props.document.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document.value) {
                if (Array.isArray(props.document.value)) {
                    if (props.document.model.key !== null) {
                        let newEntry = {}
                        let newEntries = []
                        for (var entry in props.document.value) {
                            newEntry = {}
                            newEntry[props.document.model.key] =
                                props.document.value[entry][
                                    props.document.model.key
                                ] || props.document.value[entry]
                            newEntry[props.document.model.value] =
                                props.document.value[entry][
                                    props.document.model.value
                                ] || props.document.value[entry]
                            newEntries.push(newEntry)
                        }
                        actions.setSelected(newEntries)
                    } else {
                        actions.setSelected(props.document.value)
                    }
                } else {
                    actions.setExpression(props.document.value)
                }
            }
        },
        selectThisOne:
            ({ el, props }) =>
            (state, actions) => {
                let found = false
                let selected = state.selected
                for (var sel in state.selected) {
                    if (state.selected[sel].id === el.id) {
                        found = true
                    }
                }
                if (!found) {
                    selected.push(el)
                }

                let value = ''
                value = selected.map((s) => s)
                if (value !== undefined) {
                    state.sendToParent({
                        target: { name: state.name, value: value },
                    })
                }
                actions.toggleUl(false)
            },
        createThisOne:
            ({ props }) =>
            (state, actions) => {
                actions.toggleUl(false)
            },
        removeThisOne:
            ({ el, props }) =>
            (state, actions) => {
                for (var sel in state.selected) {
                    if (state.selected[sel].id === el.id) {
                        let selected = state.selected
                        selected.splice(sel, 1)
                        let value = ''
                        if (selected.length > 0) {
                            value = selected.map((s) => s)
                        }
                        state.sendToParent({
                            target: { name: state.name, value: value },
                        })
                        break
                    }
                }
                actions.toggleUl(false)
            },
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setListKind: (newState) => (state) => ({
            listKind: newState,
        }),
        setCurrentSearch: (newState) => (state) => ({
            currentSearch: newState,
        }),
        setExpression: (newState) => (state) => ({
            expression: newState,
        }),
        setSelected: (newState) => (state) => ({
            selected: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        onUpdate: (value) => (state, actions) => {
            state.selected.name = value
            if (value !== '') {
                if (state.type.indexOf('autocomplete') > -1) {
                    let completeKind = state.type.split('/')[1]
                    switch (completeKind) {
                        case 'reward':
                            AutoComplete.getRewards(value).then((res) => {
                                if (res.data === undefined) {
                                    actions.setList([])
                                } else if (
                                    isDefined(res.data) &&
                                    !res.data.isError
                                ) {
                                    actions.setList(res.data)
                                }
                                actions.toggleUl(true)
                            })
                            break
                        case 'objectif':
                            AutoComplete.getObjectives(value).then((res) => {
                                if (res.data === undefined) {
                                    actions.setList([])
                                } else if (
                                    isDefined(res.data) &&
                                    !res.data.isError
                                ) {
                                    actions.setList(res.data)
                                }
                                actions.toggleUl(true)
                            })
                            break
                        default:
                            console.error(
                                'Sorry, we are out of ' + completeKind + '.'
                            )
                    }
                } else {
                    if (state.name === 'tags') {
                        Card.getCiqual(value).then((res) => {
                            if (res.data === undefined) {
                                actions.setList([])
                            } else if (
                                isDefined(res.data) &&
                                !res.data.isError
                            ) {
                                actions.setList(res.data)
                            }
                            actions.toggleUl(true)
                        })
                    } else {
                        console.error(
                            'Sorry, we are out of ' + state.name + '.'
                        )
                    }
                }
            } else {
                actions.setList([])
            }
        },
        onBlur: () => (state, actions) => {
            // var target = (el.target || el);
            // state.sendToParent({'target': {'name': state.name, 'value': target.value}});
        },
        waitForLastInput: ({ target }) =>
            debounce(500, 'onUpdate', target.value),
    },
    (state, actions) => (props, children) => (
        <div key={state.name + '-dyninput-todiv'} tabindex="0">
            <div
                key={state.name + '-dyninput'}
                class="sup-custom-select sup-custom-select-wide-one"
                onblur={() => {
                    actions.toggleUl(false)
                }}
                onclick={() => {
                    actions.toggleUl(!state.ulDisplayed)
                }}
            >
                <input
                    class={'state-' + state.name}
                    type="text"
                    placeholder={props.document.placeholder.input}
                    oninput={actions.waitForLastInput}
                    oninput={actions.waitForLastInput}
                    onblur={(e) => {
                        isDefined(e.relatedTarget)
                            ? ''
                            : actions.toggleUl(false)
                    }}
                />
                <ul
                    class="sup-custom-select-ul"
                    style={{
                        display: state.ulDisplayed ? 'block' : 'none',
                        margin: '0px',
                    }}
                >
                    {state.list
                        .filter(
                            (li) =>
                                (props.document.searchbox === false &&
                                    (state.model.key === null
                                        ? li !== ''
                                        : li[state.model.value] !== '')) ||
                                ((state.model.key === null
                                    ? li
                                    : li[state.model.value]) !== '' &&
                                    ((state.currentSearch !== '' &&
                                        (state.model.key === null
                                            ? li
                                            : li[state.model.value]
                                        ).indexOf(state.currentSearch) > -1) ||
                                        state.currentSearch === ''))
                        )
                        .map((l) => (
                            <li
                                class="sup-custom-select-li"
                                data-value={
                                    state.model.key === null
                                        ? l
                                        : l[state.model.key]
                                }
                                onclick={() => {
                                    actions.selectThisOne({
                                        el: l,
                                        props: props,
                                    })
                                }}
                            >
                                {state.model.key === null
                                    ? l
                                    : l[state.model.value]}
                            </li>
                        ))}
                </ul>
            </div>
            <div key={state.name + '-todiv'} class="sup-textarea">
                <div class="" style="white-space: normal;">
                    {state.selected.map((s) => (
                        <div
                            class="sup-kpis-aliments-wrapper"
                            onclick={() => {
                                actions.removeThisOne({ el: s, props: props })
                            }}
                        >
                            {props.document.type === 'theme' ? (
                                <dummy>
                                    <input
                                        class="sup-kpis-aliments"
                                        name={props.name}
                                        type="hidden"
                                        value={
                                            state.list.filter(
                                                (f) => f.key === s
                                            )[0] !== undefined
                                                ? state.list.filter(
                                                      (f) => f.key === s
                                                  )[0].key
                                                : ''
                                        }
                                        readonly=""
                                        oncreate={() => {
                                            actions.createThisOne({
                                                props: props,
                                            })
                                        }}
                                    />
                                    {state.list.filter(
                                        (f) => f.key === s
                                    )[0] !== undefined
                                        ? state.list.filter(
                                              (f) => f.key === s
                                          )[0].value
                                        : ''}
                                </dummy>
                            ) : (
                                <dummy>
                                    <input
                                        class="sup-kpis-aliments"
                                        name={props.name}
                                        type="hidden"
                                        value={
                                            state.model.key === null
                                                ? s
                                                : s[state.model.key]
                                        }
                                        readonly=""
                                        oncreate={() => {
                                            actions.createThisOne({
                                                props: props,
                                            })
                                        }}
                                    />
                                    {state.model.value === null
                                        ? s
                                        : s[state.model.value]}
                                </dummy>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ),
    'adminformwithdyninputtodiv'
)

export { AdminFormWithDynInputToDiv }

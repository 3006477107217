import { h } from '@app/utils'
import './index.scss'

const Error = (props, children) => {
    const { message } = props

    return (
        <div class="error">
            <p>Error</p>
            <p>{message}</p>
        </div>
    )
}

export default Error

import { h, Component } from '@app/utils'
import { AdminForm } from '@app/elements'
import { User } from '@app/api'
import './index.scss'
import * as documents from '@app/documents'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    form: documents.scenario_CARD.form,
    initialized: false,
    document: documents.scenario_CARD,
    header: documents.scenario_CARD.header,
    originaldocument: null,
    display: '',
    editId: null,
    scenario: [],
    currentUrl: '',
    kind: 'scenario',
}

import { dynLoad } from '@app/core'
const actions = dynLoad('action', 'Content/pages/actions')

const view =
    (state, actions) =>
    ({ match, customer }, children) => (
        <IndexLayout
            page="customers-scenario"
            customer={customer}
            currentPage={'users'}
        >
            {state.initialized === true ? (
                <div
                    key={state.initialized + '-' + state.display}
                    class={state.initialized + '-' + state.display}
                >
                    <div
                        class=""
                        key={
                            state.editId +
                            '-' +
                            state.initialized +
                            '-' +
                            Object.keys(state.document).length
                        }
                    >
                        {state.editId !== null ? (
                            <div
                                class={
                                    'adminform-edition-' +
                                    Object.keys(state.document).length
                                }
                                key={
                                    'adminform-edition-' +
                                    Object.keys(state.document).length
                                }
                            >
                                <AdminForm
                                    header={state.header}
                                    editType={'1'}
                                    document={state.document}
                                    originaldocument={state.originaldocument}
                                    target={User.editCustomerScenario}
                                ></AdminForm>
                            </div>
                        ) : (
                            <div key={'adminform-creation'}>
                                <AdminForm
                                    header={state.header}
                                    editType={'1'}
                                    document={state.document}
                                    target={User.addCustomerScenario}
                                    card={state.document}
                                ></AdminForm>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div> NOP {state.initialized} </div>
            )}
        </IndexLayout>
    )

export default Component(state, actions, view, 'scenario')

import { Forum } from '@app/api'
import { addCSRFToken } from '@app/core'

const forum_FORUMUS = {
    filter: ['abuses'],
    search: { label: 'Topic, Utilisateurs' },
    tableHead: [
        { key: 'user.fname', label: 'utilisateur' },
        { key: 'content', label: 'topic' },
        { key: 'answersCount', label: 'commentaires' },
        { key: 'date', label: 'date de création' },
        { key: 'totalAbuses', label: 'signals' },
        { key: 'shown', label: 'status' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/forum/forumus/:id'),
        },
        {
            key: 'shown',
            label: 'Rendre visible',
            kind: 'api',
            action: Forum.editForumUS,
        },
    ],
    header: ['Configurer le topic', 'US'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'forum-topic',
        },
        shown: {
            name: 'shown',
            type: 'boolean',
            label: 'Afficher le topic',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        tags: {
            name: 'tags',
            type: 'array',
            label: 'Tags',
            field: 'array',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image du topic',
            field: 'media',
            cantChooseMedia: true,
            value: '',
        },
        content: {
            name: 'content',
            type: 'topic',
            label: '',
            field: 'topic',
            placeholder:
                'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)',
            display: {
                mode: 'full',
            },
        },
        answers: {
            name: 'answerContent',
            type: 'answers',
            label: '',
            field: 'answers',
            placeholder: '',
        },
    },
}

export { forum_FORUMUS }

import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { Button, Modal } from '@app/elements'
import { Corpo } from '@app/api'
import { isEmail, isDefined } from '@app/core'
import './index.scss'

import { ModalViewTeam } from './modalView'
import { ModalViewDeleteTeam } from './modalViewDelete'

import icoTeam from '@app/img/default/def-team.png'
import icoTeamx2 from '@app/img/default/def-team@2x.png'

const AFTeams = Component(
    {
        challengeid: null,
        document: null,
        email: '',
        emailError: '',
        view: 'team-compose',
        currentTeamMember: null,
        modalDeleteDisplayed: false,
        oneSubmitAtOnce: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
            if (props.challengeid) {
                actions.setChallengeId(props.challengeid)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        suggestThisOne: () => (state, actions) => {
            if (state.oneSubmitAtOnce) {
                return false
            }
            let idchallenge = state.challengeid
            let idteam = state.document.id
            let data = {}
            if (state.email === '') {
                actions.setSuggestEmailError(t('Veuillez renseigner un email.'))
                return false
            } else if (!isEmail(state.email)) {
                actions.setSuggestEmailError(
                    t('Veuillez renseigner un email valide.')
                )
                return false
            }
            actions.setSuggestEmailError('')

            data.email = state.email
            if (
                isDefined(idchallenge) &&
                isDefined(idteam) &&
                isDefined(state.email, true) &&
                isDefined(data.email)
            ) {
                actions.setOneSubmitAtOnce(true)
                Corpo.suggestChallengeMember(idchallenge, idteam, data).then(
                    () => {
                        actions.setOneSubmitAtOnce(false)
                        actions.updateStateView('team-compose')
                    }
                )
            }
        },
        deleteThisOne: () => (state, actions) => {
            if (state.oneSubmitAtOnce) {
                return false
            }

            let idchallenge = state.challengeid
            let idteam = state.document.id
            let idmember = state.currentTeamMember.id

            if (
                isDefined(idchallenge) &&
                isDefined(idteam) &&
                isDefined(idmember)
            ) {
                actions.setOneSubmitAtOnce(true)
                Corpo.deleteChallengeMember(idchallenge, idteam, idmember).then(
                    () => {
                        let doc = state.document
                        for (var member in doc.members) {
                            if (doc.members[member].id === idmember) {
                                doc.members.splice(member, 1)
                            }
                        }
                        actions.setDocument(doc)
                        actions.updateStateView('team-compose')

                        actions.setOneSubmitAtOnce(false)
                    }
                )
            }
        },
        updateStateView: (newState) => (state) => ({
            view: newState,
        }),
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                if (name === 'email') {
                    actions.setSuggestEmail(value)
                }
            },
        waitForLastInput: ({ target }) => debounce(500, 'onUpdate', { target }),
        setCurrentTeamMember: (newState) => (state) => ({
            currentTeamMember: newState,
        }),
        setChallengeId: (newState) => (state) => ({
            challengeid: newState,
        }),
        setSuggestEmail: (newState) => (state) => ({
            email: newState,
        }),
        setSuggestEmailError: (newState) => (state) => ({
            emailError: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setModalDeleteDisplayed: (newState) => (state) => ({
            modalDeleteDisplayed: newState,
        }),
        setOneSubmitAtOnce: (newState) => (state) => ({
            oneSubmitAtOnce: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <div class="afteams-entry">
                {state.document.media !== null ? (
                    <img
                        class="afteams-entry-img afteams-entry-smallimg"
                        src={state.document.media.url}
                    />
                ) : (
                    <img
                        class="afteams-entry-img afteams-entry-smallimg"
                        src={icoTeam}
                        srcset={`${icoTeamx2} 2x`}
                    />
                )}
            </div>
            <p class="afteams-entry">{state.document.label}</p>
            <p class="afteams-entry">
                {state.document.members.length +
                    ' ' +
                    (state.document.members.length > 1
                        ? t('personnes')
                        : t('personne'))}
            </p>
            <div class="afteams-entry">
                <Modal
                    content={
                        <ModalViewTeam
                            view={state.view}
                            email={state.email}
                            team={state.document}
                            setCurrentTeamMember={actions.setCurrentTeamMember}
                            setSuggestEmail={actions.setSuggestEmail}
                            suggestThisOne={actions.suggestThisOne}
                            deleteThisOne={actions.deleteThisOne}
                            updateStateView={actions.updateStateView}
                            waitForLastInput={actions.waitForLastInput}
                            emailError={state.emailError}
                        ></ModalViewTeam>
                    }
                >
                    <div class="">
                        <Button primary flat active>
                            {t('Gérer')}
                        </Button>
                    </div>
                </Modal>
            </div>
            <div class="afteams-entry">
                <Modal
                    modalDisplayed={state.modalDeleteDisplayed}
                    content={
                        <ModalViewDeleteTeam
                            confirmAction={props.onDeleteTeam}
                            cancelAction={() =>
                                actions.setModalDeleteDisplayed(false)
                            }
                        ></ModalViewDeleteTeam>
                    }
                >
                    <div class="">
                        <Button primary flat dangerous>
                            {t('Supprimer')}
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>
    ),
    'afteams'
)

export { AFTeams }

import { h, Component } from '@app/utils'
import { AFScenario } from '@app/elements'
import { isDefined } from '@app/core'
import './index.scss'

import {
    scenario_CARD_CUSTOMER_BPM,
    scenario_CARD_CUSTOMER_TENSION,
    scenario_CARD_CUSTOMER_WEIGHT,
    scenario_CARD_CUSTOMER_ACTIVITY,
} from '@app/documents'

const AdminFormScenarios = Component(
    {
        sendToParent: null,
        name: '',
        scenarios: [
            scenario_CARD_CUSTOMER_BPM,
            scenario_CARD_CUSTOMER_TENSION,
            scenario_CARD_CUSTOMER_WEIGHT,
            scenario_CARD_CUSTOMER_ACTIVITY,
        ],
        lang: '',
        document: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.document) {
                state.document = props.document
            }

            if (props.name) {
                actions.setName(props.name)
            }
            if (props.document !== undefined) {
                if (props.document.model) {
                    actions.setModel(props.document.model)
                }
            }
        },
        onUpdateParentDocument:
            ({ newDocument, mode = 'update' }) =>
            (state, actions) => {
                let alerts = null,
                    seuils = null
                let alertKind = newDocument.header.key
                let scenarios = state.document.scenarios
                if (typeof scenarios.value !== 'object') {
                    scenarios.value = {}
                }
                if (mode === 'update') {
                    if (typeof scenarios.value[alertKind] !== 'object') {
                        scenarios.value[alertKind] = {}
                    }
                    if (isDefined(newDocument.form.alerts)) {
                        alerts = newDocument.form.alerts.value
                        scenarios.value[alertKind].alerts = alerts
                    }
                    if (isDefined(newDocument.form.seuils)) {
                        seuils = newDocument.form.seuils.value
                        scenarios.value[alertKind].seuils = seuils
                    }
                    let targetScenario = state.scenarios.filter(
                        (f) => f.header.key === alertKind
                    )
                    if (targetScenario.length > 0) {
                        targetScenario = targetScenario[0]
                        if (
                            alerts !== null &&
                            isDefined(targetScenario.form.alerts)
                        ) {
                            targetScenario.form.alerts.value = alerts
                        }
                        if (
                            seuils !== null &&
                            isDefined(targetScenario.form.seuils)
                        ) {
                            targetScenario.form.seuils.value = seuils
                        }
                        actions.setScenarios(state.scenarios)
                    }
                } else if (mode === 'delete') {
                    scenarios.value[alertKind] = false
                }
                state.document.scenarios = scenarios
                state.sendToParent({
                    target: { name: state.name, value: scenarios.value },
                })
            },
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setScenarios: (newState) => (state) => ({
            scenarios: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            <div>
                {state.scenarios.map((scnr, idx) => (
                    <AFScenario
                        key={'scnr-entry-' + idx}
                        document={scnr}
                        name={state.name}
                        order={idx}
                        onclick={actions.onUpdateParentDocument}
                    ></AFScenario>
                ))}
            </div>
        </div>
    ),
    'adminformscenarios'
)

export { AdminFormScenarios }

import { t } from '@app/utils'

export const CUSTOMERS = {
    corpo: {
        title: 'Corpo Connect',
        description: 'Corpo Connect description',
        i18ndescription: t('Corpo Connect description'),
        mailto: 'alfred@betterise.me',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
    },
}

import icoFR from '@app/img/ico/flag/flag-fr.png'
import icoFRx2 from '@app/img/ico/flag/flag-fr@2x.png'

export const COUNTRIES = {
    0: {
        label: 'Français',
        key: 'fr',
        flag: { normal: icoFR, retina: icoFRx2 },
    },
}

export const CHALLENGE_TOURNAMENT_TYPES = {
    0: {
        key: 'distance_target',
        value: 'Distance à parcourir',
        i18nValue: t('Distance à parcourir'),
        help: 'l’équipe qui atteint en premier, le nombre de pas imposé, gagne le challenge. Si à la fin du challenge, personne n’a atteint le nombre de pas imposé, c’est l’équipe qui a comptabilisé le plus de pas dans le temps imparti, qui gagne.',
        i18nHelp: t(
            'l’équipe qui atteint en premier, le nombre de pas imposé, gagne le challenge. Si à la fin du challenge, personne n’a atteint le nombre de pas imposé, c’est l’équipe qui a comptabilisé le plus de pas dans le temps imparti, qui gagne.'
        ),
        type: 1,
        unit: 'pas',
        i18nUnit: t('pas'),
    },
    1: {
        key: 'distance',
        value: 'Concours de distance',
        i18nValue: t('Concours de distance'),
        help: 'à la fin du temps imparti, c’est l’équipe qui a parcouru le plus de kilomètres, qui gagne le challenge.',
        i18nHelp: t(
            'à la fin du temps imparti, c’est l’équipe qui a parcouru le plus de kilomètres, qui gagne le challenge.'
        ),
        type: 1,
        unit: 'pas',
        i18nUnit: t('pas'),
    },
    2: {
        key: 'walk_target',
        value: 'Nombre de pas à atteindre',
        i18nValue: t('Nombre de pas à atteindre'),
        help: 'l’équipe qui atteint en premier, le nombre de pas imposé, gagne le challenge. Si à la fin du challenge, personne n’a atteint le nombre de pas imposé, c’est l’équipe qui a comptabilisé le plus de pas dans le temps imparti, qui gagne.',
        i18nHelp: t(
            'l’équipe qui atteint en premier, le nombre de pas imposé, gagne le challenge. Si à la fin du challenge, personne n’a atteint le nombre de pas imposé, c’est l’équipe qui a comptabilisé le plus de pas dans le temps imparti, qui gagne.'
        ),
        type: 1,
        unit: 'mètres',
        i18nUnit: t('mètres'),
    },
    3: {
        key: 'walk',
        value: 'Concours de pas',
        i18nValue: t('Concours de pas'),
        help: 'à la fin du temps imparti, c’est l’équipe qui a comptabilisé le plus de pas, qui gagne le challenge.',
        i18nHelp: t(
            'à la fin du temps imparti, c’est l’équipe qui a comptabilisé le plus de pas, qui gagne le challenge.'
        ),
        type: 1,
        unit: 'mètres',
        i18nUnit: t('mètres'),
    },
    // '4' : {
    //     'key' : 'altitude_target',
    //     'value' : 'Dénivelés à atteindre',
    //     'i18nValue' : t('Dénivelés à atteindre'),
    //     'help' : 'l’équipe qui atteint le dénivelé imposé en premier gagne le challenge. Si à la fin du challenge, personne n’a atteint le dénivelé imposé, c’est l’équipe qui a atteint le dénivelé dans le temps imparti, qui gagne.',
    //     'i18nHelp' : t('l’équipe qui atteint le dénivelé imposé en premier gagne le challenge. Si à la fin du challenge, personne n’a atteint le dénivelé imposé, c’est l’équipe qui a atteint le dénivelé dans le temps imparti, qui gagne.'),
    //     'type': 1,
    //     'unit': 'mètres',
    //     'i18nUnit': t('mètres'),
    // },
    // '5' : {
    //     'key' : 'altitude',
    //     'value' : 'Concours de dénivelés',
    //     'i18nValue' : t('Concours de dénivelés'),
    //     'help' : 'à la fin du temps imparti, c’est l’équipe qui a atteint le plus grand dénivelé, qui gagne le challenge.',
    //     'i18nHelp' : t('à la fin du temps imparti, c’est l’équipe qui a atteint le plus grand dénivelé, qui gagne le challenge.'),
    //     'type': 1,
    //     'unit': 'mètres',
    //     'i18nUnit': t('mètres'),
    // },
}

import themeFood from '@app/img/ico/corpo/picto-corpo_food.png'
import themeFoodx2 from '@app/img/ico/corpo/picto-corpo_food@2x.png'
import themeMove from '@app/img/ico/corpo/picto-corpo_move.png'
import themeMovex2 from '@app/img/ico/corpo/picto-corpo_move@2x.png'
import themePersonal from '@app/img/ico/corpo/picto-corpo_personal.png'
import themePersonalx2 from '@app/img/ico/corpo/picto-corpo_personal@2x.png'
import themeSleep from '@app/img/ico/corpo/picto-corpo_sleep.png'
import themeSleepx2 from '@app/img/ico/corpo/picto-corpo_sleep@2x.png'
import themeWellness from '@app/img/ico/corpo/picto-corpo_wellness.png'
import themeWellnessx2 from '@app/img/ico/corpo/picto-corpo_wellness@2x.png'
import themeZen from '@app/img/ico/corpo/picto-corpo_zen.png'
import themeZenx2 from '@app/img/ico/corpo/picto-corpo_zen@2x.png'

export const THEMES_CONTENT = [
    {
        key: 22,
        value: 'Je bouge',
        i18nValue: t('Je bouge'),
        type: 'corpo_move',
        img: {
            normal: themeMove,
            retina: themeMovex2,
        },
    },
    {
        key: 23,
        value: 'Je suis zen',
        i18nValue: t('Je suis zen'),
        type: 'corpo_zen',
        img: {
            normal: themeZen,
            retina: themeZenx2,
        },
    },
    {
        key: 24,
        value: 'Je mange équilibré',
        i18nValue: t('Je mange équilibré'),
        type: 'corpo_food',
        img: {
            normal: themeFood,
            retina: themeFoodx2,
        },
    },
    {
        key: 25,
        value: 'Je concilie ma vie pro/perso',
        i18nValue: t('Je concilie ma vie pro/perso'),
        type: 'corpo_personal',
        img: {
            normal: themePersonal,
            retina: themePersonalx2,
        },
    },
    {
        key: 26,
        value: 'Je gère mon sommeil',
        i18nValue: t('Je gère mon sommeil'),
        type: 'corpo_sleep',
        img: {
            normal: themeSleep,
            retina: themeSleepx2,
        },
    },
    {
        key: 27,
        value: 'Je suis bien dans mon environnement de travail',
        i18nValue: t('Je suis bien dans mon environnement de travail'),
        type: 'corpo_wellness',
        img: {
            normal: themeWellness,
            retina: themeWellnessx2,
        },
    },
]

import themeFood2 from '@app/img/themes/picto-nutrition.png'
import themeActivity from '@app/img/themes/picto-activite.png'
import themeSleep2 from '@app/img/themes/picto-sommeil.png'
import themeStress from '@app/img/themes/picto-stress.png'
import themeShape from '@app/img/themes/picto-moral.png'
import themeTobacco from '@app/img/themes/picto-tabac.png'
import themeAlcool from '@app/img/themes/picto-alcool.png'
import themeMemory from '@app/img/themes/picto-memoire.png'
import themeAnticancer from '@app/img/themes/picto-anticancer.png'
import themeBackache from '@app/img/themes/picto-maldedos.png'
import themeWorkingPosition from '@app/img/themes/picto-autravail.png'
import themeShapedHeart from '@app/img/themes/picto-coeur.png'
import themeHappinessPsychology from '@app/img/themes/picto-bonheur.png'
import themeProfil from '@app/img/themes/picto-profil.png'
import themeFollowedWeight from '@app/img/themes/picto-poids.png'
import themeHappyPregnancy from '@app/img/themes/picto-grossesse.png'
import themeTiredness from '@app/img/themes/picto-fatigue.png'
import themeSport from '@app/img/themes/picto-sport.png'
import themeDenivele from '@app/img/themes/picto-activite.png'

export const CARDS_TYPES = {
    0: { theme: '', value: '', key: 0, color: '' },
    1: {
        theme: 'food',
        value: 'Nutrition',
        i18nLabel: t('Nutrition'),
        key: 1,
        color: 'FF6F41',
        order: 13,
        img: themeFood2,
        display: 'both',
    },
    2: {
        theme: 'activity',
        value: 'Activité',
        i18nLabel: t('Activité'),
        key: 2,
        color: '049588',
        order: 1,
        img: themeActivity,
        display: 'both',
    },
    3: {
        theme: 'sleep',
        value: 'Sommeil',
        i18nLabel: t('Sommeil'),
        key: 3,
        color: '3E51B5',
        order: 16,
        img: themeSleep2,
        display: 'both',
    },
    4: {
        theme: 'stress',
        value: 'Stress',
        i18nLabel: t('Stress'),
        key: 4,
        color: 'E81E63',
        order: 17,
        img: themeStress,
        display: 'both',
    },
    5: {
        theme: 'shape',
        value: 'Moral',
        i18nLabel: t('Moral'),
        key: 5,
        color: 'FEC009',
        order: 12,
        img: themeShape,
        display: 'both',
    },
    6: {
        theme: 'tobacco',
        value: 'Tabac',
        i18nLabel: t('Tabac'),
        key: 6,
        color: 'FF9A00',
        order: 19,
        img: themeTobacco,
        display: 'both',
    },
    7: {
        theme: 'alcool',
        value: 'Alcool',
        i18nLabel: t('Alcool'),
        key: 8,
        color: 'A62671',
        order: 2,
        img: themeAlcool,
        display: 'both',
    },
    8: {
        theme: 'memory',
        value: 'Mémoire',
        i18nLabel: t('Mémoire'),
        key: 9,
        color: '00BABA',
        order: 12,
        img: themeMemory,
        display: 'both',
    },
    9: {
        theme: 'anticancer',
        value: 'Anti-cancer',
        i18nLabel: t('Anti-cancer'),
        key: 10,
        color: '',
        order: 3,
        img: themeAnticancer,
        display: 'both',
    },
    10: {
        theme: 'backache',
        value: 'Mal de dos',
        i18nLabel: t('Mal de dos'),
        key: 11,
        color: 'C83B4C',
        order: 11,
        img: themeBackache,
        display: 'both',
    },
    11: {
        theme: 'working-position',
        value: 'Au travail',
        i18nLabel: t('Au travail'),
        key: 12,
        color: '4EC9DF',
        order: 4,
        img: themeWorkingPosition,
        display: 'both',
    },
    12: {
        theme: 'shaped-heart',
        value: 'Coeur',
        i18nLabel: t('Coeur'),
        key: 13,
        color: '',
        order: 6,
        img: themeShapedHeart,
        display: 'both',
    },
    13: {
        theme: 'happiness-psychology',
        value: 'Bonheur',
        i18nLabel: t('Bonheur'),
        key: 14,
        color: '',
        order: 5,
        img: themeHappinessPsychology,
        display: 'both',
    },
    14: {
        theme: 'profil',
        value: 'Profil',
        i18nLabel: t('Profil'),
        key: 15,
        color: '',
        order: 14,
        img: themeProfil,
        display: 'both',
    },
    15: {
        theme: 'followed-weight',
        value: 'Suivis de poids',
        i18nLabel: t('Suivis de poids'),
        key: 16,
        color: '5F7D8B',
        order: 15,
        img: themeFollowedWeight,
        display: 'both',
    },
    16: {
        theme: 'happy-pregnancy',
        value: 'Grossesse',
        i18nLabel: t('Grossesse'),
        key: 17,
        color: '7481CF',
        order: 10,
        img: themeHappyPregnancy,
        display: 'both',
    },
    17: {
        theme: 'tiredness',
        value: 'Fatigue',
        i18nLabel: t('Fatigue'),
        key: 18,
        color: '3576A1',
        order: 9,
        img: themeTiredness,
        display: 'both',
    },
    // '18' : {'theme' : 'sport', 'value' : 'Sport', 'i18nLabel' : t('Sport'), 'key' : 19, 'color' : '4CAF50', 'order': 18, 'img': themeSport, 'display': 'both'},
    // '19' : {'theme' : 'denivele', 'value' : 'Dénivelé', 'i18nLabel' : t('Dénivelé'), 'key' : 20, 'color' : '049588', 'order': 8, 'img': themeDenivele, 'display': 'both'},
    18: {
        theme: 'je_bouge',
        value: 'Je bouge',
        i18nLabel: t('Je bouge'),
        key: 22,
        color: '049588',
        order: 22,
        img: themeActivity,
        display: 'card',
    },
    19: {
        theme: 'je_suis_zen',
        value: 'Je suis zen',
        i18nLabel: t('Je suis zen'),
        key: 23,
        color: 'E81E63',
        order: 23,
        img: themeStress,
        display: 'card',
    },
    20: {
        theme: 'je_mange_equilibre',
        value: 'Je mange équilibré',
        i18nLabel: t('Je mange équilibré'),
        key: 24,
        color: 'FF6F41',
        order: 24,
        img: themeFood2,
        display: 'card',
    },
    21: {
        theme: 'je_concilie_ma_vie_pro_perso',
        value: 'Je concilie ma vie pro/perso',
        i18nLabel: t('Je concilie ma vie pro/perso'),
        key: 25,
        color: '4CAF50',
        order: 25,
        img: themeSport,
        display: 'card',
    },
    22: {
        theme: 'je_gere_mon_sommeil',
        value: 'Je gère mon sommeil',
        i18nLabel: t('Je gère mon sommeil'),
        key: 26,
        color: '3E51B5',
        order: 26,
        img: themeSleep2,
        display: 'card',
    },
    23: {
        theme: 'je_suis_bien_dans_mon_environnement_de_travail',
        value: 'Je suis bien dans mon environnement de travail',
        i18nLabel: t('Je suis bien dans mon environnement de travail'),
        key: 27,
        color: 'FEC009',
        order: 27,
        img: themeShape,
        display: 'card',
    },
}

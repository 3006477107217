import { z } from 'zod'

const isValidEmail = function (email) {
    try {
        if (typeof email === 'string') {
            return z.string().email().safeParse(email).success
        }
        throw new Error(`Email is not a string : ${email}`)
    } catch {
        return false
    }
}

export { isValidEmail }

import { h, Component } from '@app/utils'
import { AdminFormMedia } from '@app/elements'
import './index.scss'

const AdminFormPictures = Component(
    {
        nMedia: 1,
        document: null,
        name: '',
        lang: null,
        sendToParent: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.name) {
                state.name = props.name
            }
            if (props.lang) {
                state.lang = props.lang
            }
            if (props.onclick) {
                actions.setSendToParent(props.onclick)
            }
            if (props.document) {
                if (props.document.nMedia) {
                    actions.setNMedia(props.document.nMedia)
                } else if (props.nMedia) {
                    actions.setNMedia(props.nMedia)
                }
            } else {
                if (props.nMedia) {
                    actions.setNMedia(props.nMedia)
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                if (props.document.value) {
                    actions.setDocument(props.document.value)
                } else {
                    actions.setDocument(props.document)
                }
            } else {
                actions.setDocument([])
            }
        },
        updateDocument: (field) => (state, actions) => {
            let idx = field.target.idx
            let document = state.document
            if (!Array.isArray(document)) {
                document = []
            }
            if (document[idx] === undefined) {
                document.push(field.target.value)
            } else {
                document[idx] = field.target.value
            }
            state.sendToParent({
                target: { name: state.name, value: document, lang: state.lang },
            })
        },
        setNMedia: (newState) => (state) => ({
            nMedia: newState,
        }),
        setSendToParent: (newState) => (state) => ({
            sendToParent: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class={'sup-group-pictures ' + state.kind}>
            <div>
                {Array.from({ length: state.nMedia }, (item, index) => (
                    <AdminFormMedia
                        key={'field-' + index}
                        name={index}
                        index={index}
                        lang={state.lang}
                        document={
                            state.document !== null
                                ? state.document[index] || {}
                                : {}
                        }
                        onclick={actions.updateDocument}
                    ></AdminFormMedia>
                ))}
            </div>
        </div>
    ),
    'adminformpictures'
)

export { AdminFormPictures }

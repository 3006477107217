import { t, h, Component } from '@app/utils'
import { sortObjectBy, getYMDfromDateObj, isDefined } from '@app/core'
import * as d3 from 'd3'
import './../AdminForm/index.scss'
import './index.scss'

const AdminDataviz = Component(
    {
        created: false,
        statistics: null,
        data: null,
        readableDate: '',
        type: 'barchart',
        legendHeight: 20,
        padding: {
            left: 50,
            bottom: 20,
        },
        margin: {
            left: 50,
            top: 30,
            right: 15,
        },
        ticksMode: 'normal',
        ticksNumber: {
            x: {
                date: {
                    week: 7,
                    month: null,
                    year: 12,
                },
                normal: {
                    week: 3,
                    month: 3,
                    year: 3,
                },
            },
            y: {
                date: 3,
                normal: 3,
            },
            format: {
                date: {
                    week: '%d/%m',
                    month: '%a',
                    year: '%B',
                },
                normal: '',
            },
            substring: {
                date: {
                    week: 5,
                    month: 1,
                    year: 3,
                },
                normal: {
                    week: 3,
                    month: 3,
                    year: 3,
                },
            },
        },
        rect: {
            week: {
                width: 38,
                height: 38,
                fontsize: 24,
                dy: 9,
            },
            month: {
                width: 22,
                height: 22,
                fontsize: 14,
                dy: 5,
            },
            year: {
                width: 38,
                height: 38,
                fontsize: 24,
                dy: 9,
            },
        },
        mode: 'week',
        xLabelHeight: 50,
        pathLine: null,
        pathsLine: null,
        circlesGoup: null,
        valueLine: null,
        rectBg: null,
        x: null,
        y: null,
        xAxis: null,
        xAxisWeekly: null,
        yAxis: null,
        yAxisUnit: '',
        xAxisUnit: '%',
        ydomain: 10,
        ydomainMin: 0,
        ydomainMax: 10,
        ydomainOffset: 10,
        defaultAxis: [1, 2, 3],
        display: 'horizontal',
        percentRatio: 100,
        refDate: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props) {
                if (props.data && props.data.ticksMode) {
                    actions.setTicksMode(props.data.ticksMode)
                }
                if (props.data && props.data.dvType) {
                    actions.setType(props.data.dvType)
                }
                if (props.data && props.data.dvDisplay) {
                    actions.setDisplay(props.data.dvDisplay)
                }
                //
                if (props.refDate) {
                    actions.setRefDate(props.refDate)
                }
                if (props.mode) {
                    actions.setMode(props.mode)
                }
                if (
                    props.data &&
                    props.data.results &&
                    props.data.results.value
                ) {
                    actions.parseAndSetData(props.data)
                }

                if (state.created) {
                    actions.updateDataViz()
                }
            }
        },
        parseAndSetData: (statistics) => (state, actions) => {
            let parsedValues = statistics.results.value

            if (state.ticksMode === 'date') {
                actions.setXAxis(d3.scaleTime())
                actions.setYAxisUnit('%')
            } else {
                actions.setXAxis(d3.scaleLinear())
            }
            actions.setYAxis(d3.scaleLinear())
            actions.setXAxisWeekly(d3.scaleLinear())

            actions.setData(parsedValues)

            actions.setStatistics(statistics)
        },
        setData: (newState) => (state) => ({
            data: newState,
        }),
        setStatistics: (newState) => (state) => ({
            statistics: newState,
        }),
        //
        createDataViz: (props) => (state, actions) => {
            state.created = true
            if (state.type === 'linechart') {
                state.margin.left = 60
            } else if (state.type === 'barchart') {
                state.margin.left = 200
            }
            actions.setMargin(state.margin)
            //
            var rootContainer = null,
                svg = null
            actions.setRootContainerJS(props)
            svg = d3.select(props)
            var root = svg.append('g')
            root.attr('class', 'root-container').attr(
                'transform',
                'translate(' + state.margin.left + ', ' + state.margin.top + ')'
            )

            var availableWidth = props.parentNode.offsetWidth
            var availableHeight = props.parentNode.offsetHeight

            actions.setDataVizWidth(availableWidth)
            actions.setDataVizHeight(availableHeight)

            let maxXRange =
                availableWidth -
                state.padding.left -
                (state.margin.left + state.margin.right)
            // if (availableWidth <= 414) {
            //     maxXRange = (availableWidth - (state.padding.left * 1));
            // }
            // state.xAxis.range([state.padding.left, maxXRange]);
            // state.yAxis.range([(availableHeight - state.padding.bottom - state.xLabelHeight), 0]);
            state.xAxis.range([0, maxXRange])
            state.xAxisWeekly.range([0, maxXRange])
            state.yAxis.range([
                0,
                availableHeight - state.padding.bottom - state.xLabelHeight,
            ])

            var rectBg = root.append('g').attr('class', 'rectBg')
            actions.setRootContainer(root)
            actions.setBgContainer(rectBg)
            rootContainer = root

            var g = rootContainer
                .append('g')
                .attr('transform', 'translate(0, 0)')

            var x = g
                .append('g')
                .attr('class', 'axis axis--x')
                .attr(
                    'transform',
                    'translate(0, ' +
                        (availableHeight - state.xLabelHeight) +
                        ')'
                )
            actions.setX(x)

            var y = g.append('g').attr('class', 'axis axis--y')
            if (state.display === 'horizontal') {
                y.attr(
                    'transform',
                    'translate(0, ' +
                        (availableHeight / state.data.length / 2 - 6) +
                        ')'
                )
            }
            actions.setY(y)

            var valueLine = d3
                .line()
                .defined(function (d) {
                    return d.percent !== null && d.percent != 'null'
                })
                .x(function (d) {
                    if (state.ticksMode === 'date' && state.mode === 'month') {
                        return state.xAxisWeekly(d.idx)
                    }
                    return state.xAxis(d.date)
                })
                .y(function (d) {
                    return state.yAxis(d.percent)
                })

            if (state.type === 'linechart') {
                actions.createLegend(state.data)
            }

            actions.setPathLine(rootContainer.append('path'))
            let pathsLine = rootContainer.append('g').attr('class', 'pathsLine')
            actions.setPathsLine(pathsLine)

            let circlesGoup = rootContainer
                .append('g')
                .attr('class', 'circlesGoup')
            actions.setCirclesGoup(circlesGoup)

            actions.setValueLine(valueLine)

            actions.updateDataViz()
        },
        updateDataViz: () => (state, actions) => {
            if (state.data === null || state.data == 'null') {
                return false
            }

            // parse data date and value
            var total = 0,
                maxValue = 0
            if (state.ticksMode === 'normal') {
                total = state.data
                    .map((m) => m.value)
                    .reduce((sum, current) => sum + current, 0)
                state.data.forEach(function (d, i) {
                    d.percent = 0
                    if (d.value > 0) {
                        d.percent = (
                            (d.value / total) *
                            state.percentRatio
                        ).toFixed()
                    }
                    if (d.percent !== null && maxValue < +d.percent) {
                        maxValue = +d.percent
                    }
                    d.date = d.date || i
                    d.index = i
                    d.maxValue = maxValue
                })
            } else if (state.ticksMode === 'date') {
                let parsedAsArray = []
                var parseTime = d3.timeParse('%Y%m%d')
                let weekRefIdx = 0
                for (var d in state.data) {
                    let dat = state.data[d] // each day

                    let idx = 0
                    weekRefIdx++
                    for (var valuesSet in dat) {
                        let total = Object.keys(dat).reduce(
                            (sum, key) => sum + parseFloat(dat[key] || 0),
                            0
                        )
                        let percentVal = (
                            (dat[valuesSet] / total) *
                            state.percentRatio
                        ).toFixed()

                        if (parsedAsArray[idx] === undefined) {
                            parsedAsArray[idx] = []
                        }

                        if (isDefined(percentVal) && maxValue < +percentVal) {
                            maxValue = +percentVal
                        }
                        let parsedDate = parseTime(d)
                        if (state.mode === 'month') {
                            parsedDate.setTime(
                                parsedDate.getTime() + 1 * 86400000
                            )
                        }
                        parsedAsArray[idx].push({
                            label: valuesSet,
                            percent: percentVal,
                            date: parsedDate,
                            edate: d,
                            maxValue: maxValue,
                            idx: weekRefIdx,
                        })

                        idx++
                    }
                }
                for (var set in parsedAsArray) {
                    // for each data set
                    weekRefIdx = 0
                    for (var dd in state.data) {
                        // iterate on all day
                        weekRefIdx++
                        if (
                            parsedAsArray[set].filter(
                                (f) => +f.edate === +dd
                            )[0] === undefined
                        ) {
                            // add missing day
                            let parsedDate = parseTime(dd)
                            if (state.mode === 'month') {
                                parsedDate.setTime(
                                    parsedDate.getTime() + 1 * 86400000
                                )
                            }
                            parsedAsArray[set].push({
                                label: 'Ø',
                                percent: null,
                                date: parsedDate,
                                edate: dd,
                                maxValue: maxValue,
                                idx: weekRefIdx,
                            })
                        }
                    }
                    // order set by date
                    parsedAsArray[set] = sortObjectBy(
                        parsedAsArray[set],
                        'edate'
                    )
                }
                state.data = parsedAsArray
            }

            actions.updateAxis()

            if (state.type === 'linechart') {
                actions.updateLineChart()
                actions.updateDotMarks('g.circles')
            } else if (state.type === 'barchart') {
                actions.updateBarChart()
            }

            if (state.rootContainerJS === undefined) {
                return false
            }
            // actions.setReadableDate(actions.getPeriodLabel(state.data));
        },
        updateLineChart: () => (state, actions) => {
            // line path
            for (var dat in state.data) {
                let pathsLine = state.pathsLine
                if (pathsLine === null) {
                    continue
                }
                pathsLine
                    .selectAll('path')
                    .data(state.data)
                    .enter()
                    .append('path')
                    .attr('d', (d) => state.valueLine(d))
                    .attr('class', 'line')
                    .attr('opacity', '0')
                    .transition()
                    .duration(400)
                    .ease(d3.easeLinear)
                    .attr('opacity', '1')
            }
        },
        updateBarChart: () => (state, actions) => {
            var tmpData = state.data
            var rootContainer = state.rootContainer

            actions.updateBgRect()

            let _x = function () {
                return refBoundingBox.width / 2 - 16
            }
            let _y = function (d) {
                return state.yAxis(d.percent)
            }
            let _h = function (d) {
                if (d.percent === undefined) {
                    return 0
                }
                return (
                    state.height - state.yAxis(d.percent) - state.xLabelHeight
                )
            }
            let _w = function () {
                let bgWidth =
                    (state.width - state.padding.left - state.margin.left) /
                        state.data.length -
                    4 // 4 is the stroke
                return bgWidth / 3
            }
            if (state.display === 'horizontal') {
                _x = function () {
                    return 0
                }
                _y = function () {
                    let bgWidth =
                        (state.height -
                            state.padding.bottom -
                            state.margin.top) /
                        state.data.length // 4 is the stroke
                    bgWidth = bgWidth / 2
                    return refBoundingBox.height / 2 - bgWidth / 2
                }
                _h = function () {
                    let bgWidth =
                        (state.height -
                            state.padding.bottom -
                            state.margin.top) /
                        state.data.length // 4 is the stroke
                    return bgWidth / 2
                }
                _w = function (d) {
                    if (d.percent === undefined) {
                        return 0
                    }
                    return state.xAxis(d.percent)
                }
            }
            if (rootContainer === undefined) {
                return false
            }
            var grpBgRect = rootContainer.selectAll('g.grpbgrectangles')
            var node = grpBgRect.node()
            if (node !== null) {
                var refBoundingBox = node.getBoundingClientRect()
                grpBgRect
                    .selectAll('rect.objgcharts')
                    .data(function (d) {
                        let returning = d
                        return [returning]
                    })
                    .enter()
                    .append('path')
                    .attr('class', function (d) {
                        return d.index + ' objgcharts'
                    })
                    .attr('d', function (d) {
                        if (
                            d.percent === 0 ||
                            isNaN(_y(d)) ||
                            isNaN(_x()) ||
                            isNaN(_h(d))
                        ) {
                            return ''
                        }
                        if (state.display === 'horizontal') {
                            return actions.rounded_rect({
                                x: _x(),
                                y: _y(),
                                w: _w(d),
                                h: _h(),
                                r: 20 - tmpData.length,
                                tl: false,
                                tr: true,
                                bl: false,
                                br: true,
                            })
                        }
                        return actions.rounded_rect({
                            x: _x(),
                            y: _y(d),
                            w: _w(),
                            h: _h(d),
                            r: 22,
                            tl: true,
                            tr: true,
                            bl: false,
                            br: false,
                        })
                    })
            }
        },
        updateDotMarks: () => (state, actions) => {
            let circlesGoup = state.circlesGoup
            if (circlesGoup === null) {
                return false
            }
            let wrappers = circlesGoup
                .selectAll('g')
                .data(function () {
                    return state.data
                })
                .enter()
                .append('g')
                .attr('class', 'circles')

            wrappers
                .selectAll('rect')
                .data(function (d, i) {
                    return state.data[i]
                })
                .enter()
                .filter((f) => {
                    return f.percent !== null
                })
                .append('rect')
                .attr('width', 12)
                .attr('height', 12)
                .attr('x', function (d) {
                    if (state.ticksMode === 'date' && state.mode === 'month') {
                        return state.xAxisWeekly(d.idx) - 6 + 'px'
                    }
                    return state.xAxis(d.date) - 6 + 'px'
                })
                .attr('y', function (d) {
                    return state.yAxis(d.percent) - 6 + 'px'
                })
                .attr('rx', 6)
                .attr('ry', 6)
                .transition()
                .duration(400)
                .ease(d3.easeLinear)
                .attr('opacity', '1')
        },
        updateAxis: () => (state, actions) => {
            // axis update
            var tmpYMax = state.ydomainMax,
                tmpYMin = state.ydomainMin
            let tmpDomain = null
            let xDomain = d3.extent(
                state.data[0].date === undefined ? state.data[0] : state.data,
                function (d, i) {
                    if (Array.isArray(d) && d.length > 0) {
                        return d[i].date
                    }
                    return d.date
                }
            )
            if (state.ticksMode === 'normal') {
                xDomain = [0, state.percentRatio]
            } else if (state.ticksMode === 'date' && state.mode === 'month') {
                // xDomain = [...state.data[0].map((m) => m.date)];
                tmpDomain = [
                    state.data[0][0].idx,
                    state.data[0][state.data[0].length - 1].idx,
                ]
                // tmpDomain = [...state.data[0].map((m) => m.idx)];
                state.xAxisWeekly.domain(tmpDomain)
                // actions.setXAxisWeekly(d3.scaleLinear());
            }
            // define X
            state.xAxis.domain(xDomain)
            if (state.x !== null) {
                state.x.call(
                    d3
                        .axisBottom(
                            state.ticksMode === 'date' && state.mode === 'month'
                                ? state.xAxisWeekly
                                : state.xAxis
                        )
                        .tickFormat(function (d) {
                            if (state.ticksMode === 'date') {
                                if (
                                    state.ticksNumber.format[
                                        state.ticksMode
                                    ] !== ''
                                ) {
                                    if (
                                        state.ticksNumber.substring[
                                            state.ticksMode
                                        ] !== null
                                    ) {
                                        if (state.mode === 'month') {
                                            let refDate =
                                                state.data[0][d - 1].date
                                            let startDate =
                                                getYMDfromDateObj(refDate)
                                            refDate.setTime(
                                                refDate.getTime() + 6 * 86400000
                                            )
                                            let endDate =
                                                getYMDfromDateObj(refDate)
                                            return (
                                                t('Du') +
                                                ' ' +
                                                startDate.day +
                                                '/' +
                                                startDate.month +
                                                ' ' +
                                                t('au') +
                                                ' ' +
                                                endDate.day +
                                                '/' +
                                                endDate.month
                                            )
                                        }
                                        return d3
                                            .timeFormat(
                                                state.ticksNumber.format[
                                                    state.ticksMode
                                                ][state.mode]
                                            )(d)
                                            .substring(
                                                0,
                                                state.ticksNumber.substring[
                                                    state.ticksMode
                                                ][state.mode]
                                            )
                                    }
                                    return d3.timeFormat(
                                        state.ticksNumber.format[
                                            state.ticksMode
                                        ][state.mode]
                                    )(d)
                                }
                            } else {
                                //
                            }
                            return d + state.xAxisUnit
                        })
                        .ticks(
                            state.ticksNumber.x[state.ticksMode][state.mode] !==
                                null
                                ? state.ticksNumber.x[state.ticksMode][
                                      state.mode
                                  ]
                                : tmpDomain !== null
                                ? tmpDomain[tmpDomain.length - 1]
                                : xDomain.length
                        )
                )
                //
            }
            // define Y
            if (state.data && state.data.length > 0) {
                tmpYMax = state.data.length
                tmpYMin = 0
                state.defaultAxis = Array.from(Array(state.data.length).keys())
            } else if (state.defaultAxis && state.defaultAxis.length > 1) {
                tmpYMax = Math.max.apply(Math, state.defaultAxis)
                tmpYMin = Math.min.apply(Math, state.defaultAxis)
            }
            if (state.ticksMode === 'date') {
                tmpYMin = 100
                tmpYMax = 0
                state.defaultAxis = [0, 50, 100]
            }
            actions.setYDomainMax(tmpYMax)
            actions.setYDomainMin(tmpYMin)
            //
            state.yAxis.domain([tmpYMin, tmpYMax])
            //
            if (state.y !== null) {
                state.y
                    .call(
                        d3
                            .axisLeft(state.yAxis)
                            .tickFormat(function (d, i) {
                                if (state.ticksMode === 'normal') {
                                    let formatLabel = state.data.filter(
                                        (f) => f.index === i
                                    )
                                    if (formatLabel.length > 0) {
                                        formatLabel = formatLabel[0].label
                                        return formatLabel
                                    }
                                }
                                return d + state.yAxisUnit
                            })
                            .tickSize(0)
                            .tickPadding(20)
                            .tickValues(
                                state.defaultAxis.map(function (d) {
                                    return d
                                })
                            )
                            .ticks(state.ticksNumber.y[state.ticksMode])
                    )
                    .selectAll('.tick text')
                    .call(actions.wrap)
            }
        },
        // https://bl.ocks.org/mbostock/7555321
        wrap: (el) => (state, actions) => {
            let width = 180
            let texts = el._groups[0]
            for (var text in texts) {
                if (texts[text].innerHTML !== undefined) {
                    text = d3.select(texts[text])
                    var words = text.text().split(/\s+/).reverse(),
                        word,
                        line = [],
                        lineNumber = 0,
                        lineHeight = 0.8, // ems
                        y = text.attr('y'),
                        dy = parseFloat(text.attr('dy')),
                        tspan = text
                            .text(null)
                            .append('tspan')
                            .attr('x', '-1em')
                            .attr('y', y)
                            .attr('dy', dy + 'em')
                    // eslint-disable-next-line
                    while (word = words.pop()) {
                        line.push(word)
                        tspan.text(line.join(' '))
                        if (tspan.node().getComputedTextLength() > width) {
                            line.pop()
                            tspan.text(line.join(' '))
                            line = [word]
                            tspan = text
                                .append('tspan')
                                .attr('x', '-1em')
                                .attr('y', y)
                                .attr(
                                    'dy',
                                    ++lineNumber * lineHeight + dy + 'em'
                                )
                                .text(word)
                        }
                    }
                }
            }
        },
        updateBgRect: () => (state, actions) => {
            if (state.rectBg === null) {
                return false
            }
            var rect = state.rectBg
                .selectAll('g.grpbgrectangles')
                .data(state.data)
            rect.exit().remove()

            var grpBgRect = rect
                .enter()
                .append('g')
                .attr('class', function (d) {
                    return 'grpbgrectangles grpbgrectangles-' + (+d.index % 2)
                })
                .merge(rect)

            grpBgRect.selectAll('*').remove()
            grpBgRect.exit().remove()
            grpBgRect
                .append('rect')
                .attr('class', function (d) {
                    return 'bgrectangles bgrectangles-' + (+d.index % 2)
                })
                .attr('height', function () {
                    if (state.display === 'horizontal') {
                        return (
                            (state.height -
                                state.padding.bottom -
                                state.margin.top) /
                                state.data.length +
                            'px'
                        )
                    }
                    return state.height - state.xLabelHeight
                })
                .merge(grpBgRect)
                .attr('width', function () {
                    if (state.display === 'horizontal') {
                        return state.xAxis(state.percentRatio)
                    }
                    return (
                        (state.width - state.padding.left - state.margin.left) /
                            state.data.length +
                        'px'
                    )
                })

            state.rectBg
                .selectAll('g.grpbgrectangles')
                .merge(rect)
                .transition()
                .duration(400)
                .attr('transform', function (d, i) {
                    if (state.display === 'horizontal') {
                        return 'translate(0, ' + state.yAxis(i) + ')'
                    }
                    return (
                        'translate(' +
                        (state.xAxis(state.width / d.date) -
                            (state.width -
                                state.padding.left -
                                state.margin.left) /
                                state.data.length /
                                2) +
                        ', 0)'
                    )
                })
        },
        orderLegends: (legends) => (state, actions) => {
            return legends
        },
        createLegend: (data) => (state, actions) => {
            var legend = state.rootContainer
                .append('g')
                .attr('class', 'legend legend--x')
                .attr(
                    'transform',
                    'translate(0, ' +
                        (state.height + state.legendHeight / 2) +
                        ')'
                )

            let legendEntries = []
            for (var date in data) {
                for (var entrie in data[date]) {
                    if (legendEntries.indexOf(entrie) === -1) {
                        legendEntries.push(entrie)
                    }
                }
            }
            let xIdx = 0
            let yIdx = 0
            let breakIdx = 0
            for (var legendEntry in legendEntries) {
                breakIdx++
                let gLegend = legend
                    .append('g')
                    .attr('transform', 'translate(' + xIdx + ', ' + yIdx + ')')
                xIdx += 150
                if (breakIdx >= 5) {
                    xIdx = 0
                    yIdx += 30
                    breakIdx = 0
                    state.legendHeight += 10
                }
                gLegend.append('circle').attr('r', 6)
                gLegend
                    .append('text')
                    .text(legendEntries[legendEntry])
                    .attr('x', 15)
                    .attr('y', 4)
            }
        },
        setReadableDate: (newState) => (state) => ({
            readableDate: newState,
        }),
        setBgContainer: (newState) => (state) => ({
            rectBg: newState,
        }),
        setRootContainer: (newState) => (state) => ({
            rootContainer: newState,
        }),
        setRootContainerJS: (newState) => (state) => ({
            rootContainerJS: newState,
        }),
        setDataVizWidth: (newState) => (state) => ({
            width: newState,
        }),
        setDataVizHeight: (newState) => (state) => ({
            height: newState,
        }),
        setPathLine: (newState) => (state) => ({
            pathLine: newState,
        }),
        setPathsLine: (newState) => (state) => ({
            pathsLine: newState,
        }),
        setCirclesGoup: (newState) => (state) => ({
            circlesGoup: newState,
        }),
        setValueLine: (newState) => (state) => ({
            valueLine: newState,
        }),
        setXAxis: (newState) => (state) => ({
            xAxis: newState,
        }),
        setXAxisWeekly: (newState) => (state) => ({
            xAxisWeekly: newState,
        }),
        setYAxis: (newState) => (state) => ({
            yAxis: newState,
        }),
        setX: (newState) => (state) => ({
            x: newState,
        }),
        setY: (newState) => (state) => ({
            y: newState,
        }),
        setYDomainMax: (newState) => (state) => ({
            ydomainMax: newState,
        }),
        setYDomainMin: (newState) => (state) => ({
            ydomainMin: newState,
        }),
        setTicksMode: (newState) => (state) => ({
            ticksMode: newState,
        }),
        setType: (newState) => (state) => ({
            type: newState,
        }),
        setMargin: (newState) => (state) => ({
            margin: newState,
        }),
        setDisplay: (newState) => (state) => ({
            display: newState,
        }),
        setYAxisUnit: (newState) => (state) => ({
            yAxisUnit: newState,
        }),
        setRefDate: (newState) => (state) => ({
            refDate: newState,
        }),
        setMode: (newState) => (state) => ({
            mode: newState,
        }),
        rounded_rect:
            ({ x, y, w, h, r, tl, tr, bl, br }) =>
            (state, actions) => {
                var retval
                retval = 'M' + (x + r) + ',' + y
                retval += 'h' + (w - 2 * r)
                if (tr) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r
                } else {
                    retval += 'h' + r
                    retval += 'v' + r
                }
                retval += 'v' + (h - 2 * r)
                if (br) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r
                } else {
                    retval += 'v' + r
                    retval += 'h' + -r
                }
                retval += 'h' + (2 * r - w)
                if (bl) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r
                } else {
                    retval += 'h' + -r
                    retval += 'v' + -r
                }
                retval += 'v' + (2 * r - h)
                if (tl) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r
                } else {
                    retval += 'v' + -r
                    retval += 'h' + r
                }
                retval += 'z'
                return retval
            },
    },
    (state, actions) => (props, children) => (
        <div class={'btzAdminForm btzAdminStats'}>
            {state.statistics !== null && (
                <dummy>
                    {props.data.type.value === 'corpo-eval' && (
                        <div class="btzAdminStats-header">
                            <p class="sup-title">
                                {t('Santé de l‘entreprise')}
                            </p>
                            <p class="sup-title sup-title-colored">
                                {state.statistics.question.value}
                            </p>
                            <p class="">
                                {state.statistics.results.value
                                    .map((m) => m.value)
                                    .reduce(
                                        (sum, current) => sum + current,
                                        0
                                    ) +
                                    ' ' +
                                    (state.statistics.results.value
                                        .map((m) => m.value)
                                        .reduce(
                                            (sum, current) => sum + current,
                                            0
                                        ) > 1
                                        ? t('réponses')
                                        : t('réponse'))}
                            </p>
                        </div>
                    )}
                    {props.data.type.value === 'corpo-survey' && (
                        <div class="btzAdminStats-header">
                            {state.statistics.question !== undefined && (
                                <p class="sup-title sup-title-colored">
                                    {t('QUEST') +
                                        ' #' +
                                        (Math.floor(props.idx / 2) + 1)}
                                    <font>
                                        {' : ' +
                                            state.statistics.question.value}
                                    </font>
                                </p>
                            )}
                            {/*<p class='sup-title'>{state.statistics.countResponsePeriod.value + ' ' + t('réponses sur la période.')}</p>*/}
                        </div>
                    )}
                    <div key={state.refDate} class="btzAdminStats-wrapper">
                        <svg
                            key={state.refDate}
                            class={state.display}
                            oncreate={actions.createDataViz}
                            data-kind={props.kind}
                            width={state.width}
                            height={
                                state.height +
                                (state.type === 'linechart'
                                    ? state.legendHeight * 3
                                    : 0)
                            }
                        ></svg>
                    </div>

                    {/*(state.type === 'linechart') &&
                        <div class='btzAdminStats-legends'>
                            {(Array.isArray(state.data) && (state.data.length > 0)) &&
                                <dummy>
                                    {state.data.map((m) =>
                                        <div class='btzAdminStats-legend'>
                                            <p class='btzAdminStats-legendPuce'></p>
                                            <p class='btzAdminStats-legendLabel'>{m.filter((f) => f.percent !== null)[0].label}</p>
                                        </div>
                                    )}
                                </dummy>
                            }
                        </div>
                    */}
                </dummy>
            )}
        </div>
    ),
    'admindataviz'
)

export { AdminDataviz }

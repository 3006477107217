import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const corpoevaluation_CARD = {
    filter: ['status'],
    tableHead: [
        { key: 'name', label: 'ID' },
        { key: 'title', label: 'titre' },
        { key: 'startDate', label: 'date de début' },
        { key: 'endDate', label: 'date de fin' },
        { key: 'status', label: 'statut' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/evaluations/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action:
                addCSRFToken('/content/evaluations/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editCard,
        },
        {
            key: 'statistics',
            label: 'Voir statistiques',
            kind: 'web',
            action: addCSRFToken('/content/evaluations/:id/statistics'),
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteCard,
        },
    ],
    tableAllOptions: {
        label: 'EVALUATIONS SÉLECTIONNÉES',
        list: [
            {
                key: 'status_activate',
                label: 'Activer',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'status_deactivate',
                label: 'Désactiver',
                kind: 'api',
                action: Card.editCard,
            },
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteCard,
            },
        ],
    },
    header: ['Configurer la question'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'evaluation_generique',
        },
        type: {
            field: 'hidden',
            value: '57',
        },
        theme: {
            field: 'hidden',
            value: '99',
        },
        // 'customers': {
        //     'name': 'customers',
        //     'type': 'customer',
        //     'label': '',
        //     'field': 'checkbox',
        //     'list': 'static',
        //     'allSelect': true,
        //     'mandatory': true,
        //     'value' : '',
        //     'validation': {
        //         'mandatory': true,
        //         'isValid': false,
        //         'type': 'atLeastOneChecked',
        //         'message': 'Veuillez sélectionner au moins un customer',
        //         'i18nMessage': t('Veuillez sélectionner au moins un customer'),
        //     }
        // },
        // 'owner': {
        //     'name': 'owner',
        //     'type': 'owner',
        //     'label': 'Attribué à',
        //     'field': 'dropdown',
        //     'list': 'dynamic',
        //     'deletable': true,
        //     'placeholder': 'Betterise',
        //     'value' : '',
        // },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activation',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
            display: {
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la question',
            field: 'text',
            mandatory: true,
            placeholder: "Renseignez ici l'id de la question",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: "Veuillez renseigner l'id de la question",
                i18nMessage: t("Veuillez renseigner l'id de la question"),
            },
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        startDate: {
            name: 'startDate',
            type: 'date',
            label: 'Date de début',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner une date de début',
                i18nMessage: t('Veuillez renseigner une date de début'),
            },
            display: {
                mode: 'half',
            },
        },
        endDate: {
            name: 'endDate',
            type: 'date',
            label: 'Date de fin',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner une date de fin',
                i18nMessage: t('Veuillez renseigner une date de fin'),
            },
            display: {
                mode: 'half',
            },
        },
        formsType: {
            name: 'type',
            substitute: 'type',
            type: 'int',
            label: 'Type',
            field: 'dropdown',
            list: constants.custo.EVAL_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un type pour votre évaluation',
                i18nMessage: t(
                    'Veuillez sélectionner un type pour votre évaluation'
                ),
            },
            wrappedIn: 'forms',
        },
        flags: {
            field: 'hidden',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            field: 'hidden',
            value: { fr: true },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Question',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre question',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de votre question',
                i18nMessage: t(
                    'Veuillez renseigner le titre de votre question'
                ),
            },
        },
        dataDate: {
            name: 'dataDate',
            type: 'string',
            label: 'dateDate',
            field: 'hidden',
            value: 'P0D',
            wrappedIn: 'forms',
        },
        forms: {
            // for evaluations
            name: 'eval',
            substitute: 'formDatas',
            type: 'translation',
            label: 'Détail du formulaire',
            field: 'forcedtag',
            model: {},
            list: 'static',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastTwoFilledForceValueToOrder',
                message:
                    'Veuillez renseigner au moins deux réponses à la question',
                i18nMessage: t(
                    'Veuillez renseigner au moins deux réponses à la question'
                ),
            },
            sendElsewhere: true,
            wrappedIn: 'forms',
        },
        // 'sliderTag': {
        //     'name': 'sliderTag',
        //     'type': 'datatags',
        //     'label': 'Data tag',
        //     'field': 'hidden',
        //     'list': 'dynamic',
        //     'value' : 'PAI_ZEROPOINTS', //force default
        //     'placeholder': 'Tapez votre recherche ici ...',
        //     'validation': {
        //         'mandatory': true,
        //         'isValid': false,
        //         'type': 'selected',
        //         'message': 'Veuillez sélectionner un dataTag pour votre slider',
        //         'i18nMessage': t('Veuillez sélectionner un dataTag pour votre slider'),
        //     },
        //     'wrappedIn': 'forms',
        //     'dependsOn': {
        //         'field': 'formsType',
        //         'value': 'Slider'
        //     }
        // },
    },
}

export { corpoevaluation_CARD }

import { h } from '@app/utils'
import { classMerge } from '@app/utils'

const Progress = (props, children) => {
    const { max, value, classes, ...otherProps } = props

    const allClasses = classMerge(['progress', classes])

    return (
        <progress class={allClasses} value={value} max={max} {...otherProps}>
            {children}
        </progress>
    )
}

export { Progress }

import { t, h, Component } from '@app/utils'
import { User } from '@app/api'
import { Link, Form, Button, MessagePopup } from '@app/elements'
import { debounce } from 'hyperapp-fx'
import {
    isInteger,
    getDateObjFromYMD,
    getYMDfromDateObj,
    isDefined,
    createUTCDate,
} from '@app/core'
import { settings } from '@app/core/settings'
import './index.scss'

const { APP_API_URL } = settings

Date.prototype.addDays = function (days, sub = false) {
    var date = new Date(this.valueOf())
    if (sub) {
        date.setDate(date.getDate() - days)
    } else {
        date.setDate(date.getDate() + days)
    }
    return date
}

const AdminFormDate = Component(
    {
        name: '',
        day: '',
        month: '',
        year: '',
        type: 'normal',
        userId: null,
        token: null,
        withHour: false,
        connectState: null,
        isValidDay: false,
        isValidMonth: false,
        isValidYear: false,
        sendToParent: null,
        compareList: [],
        errorCompare: '',
        registerDate: null,
        original: null,
        submitting: false,
        messageActive: false,
        messageKind: 'success',
        unarchivatePatientModal: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('WITH HOUR : ', props)
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.original && props.original.id) {
                actions.setUserId(props.original.id)
            }
            actions.setToken(
                encodeURIComponent(localStorage.getItem('apitoken'))
            )
            // props.document.value = {"date":"2019-11-21 09:29:06.875000","timezone_type":3,"timezone":"UTC"};
            if (props.document) {
                if (props.document.name) {
                    actions.setName(props.document.name)
                }
                if (props.document.type) {
                    actions.setType(props.document.type)
                }
                if (props.document.additionalFields) {
                    let hourDate = ''
                    let connectState = null
                    if (
                        props.document.additionalFields.indexOf('withQrCode') >
                            -1 &&
                        isDefined(props.original) &&
                        +props.original.stepRegistration === 2
                    ) {
                        hourDate = t('invitation en attente')
                        connectState = 'qrcode'
                    } else if (
                        props.document.additionalFields.indexOf('withHour') >
                            -1 &&
                        isDefined(props.document.value.date)
                    ) {
                        let utcDate = createUTCDate(
                            props.document.value.date,
                            false
                        )
                        let utcTime =
                            (+utcDate.getHours() < 10
                                ? '0' + +utcDate.getHours()
                                : utcDate.getHours()) +
                            ':' +
                            (+utcDate.getMinutes() < 10
                                ? '0' + +utcDate.getMinutes()
                                : utcDate.getMinutes()) +
                            ':' +
                            (+utcDate.getSeconds() < 10
                                ? '0' + +utcDate.getSeconds()
                                : utcDate.getSeconds())
                        hourDate = t('à') + ' ' + utcTime
                        connectState = 'qrcode'
                    } else if (
                        props.document.additionalFields.indexOf('withInvit') >
                        -1
                    ) {
                        hourDate = t('invitation à envoyer')
                        if (
                            isDefined(props.original) &&
                            isDefined(props.original.settings)
                        ) {
                            let originalSettings =
                                props.original.settings.filter(
                                    (f) => f.name == 'DOCTOR_INVITATION_SENT'
                                )[0]
                            if (
                                isDefined(originalSettings) &&
                                +originalSettings.value === 1
                            ) {
                                hourDate = t('invitation en attente')
                            }
                        }
                        connectState += '/invit'
                    }
                    if (
                        props.document.additionalFields.indexOf('withArchive') >
                        -1
                    ) {
                        connectState = 'archive'
                        if (!isDefined(props.document.value.date)) {
                            hourDate = '-'
                        }
                    }
                    if (
                        isDefined(props.original) &&
                        isDefined(props.original.security) &&
                        isDefined(props.original.security.blockedLogin) &&
                        isDefined(props.original.security.blockedLogin.date)
                    ) {
                        hourDate = t('Compte bloqué (X5 pin)')
                        if (
                            props.document.additionalFields.indexOf(
                                'withStatus'
                            ) > -1
                        ) {
                            connectState = 'sendResetCode'
                            /*if (props.document.additionalFields.indexOf('withUnlock') > -1) {
                                connectState = 'unlock';
                            }*/
                        }
                    }

                    console.log('connectState : ', connectState, props.original)
                    actions.setConnectState(connectState)
                    actions.setWithHour(hourDate)
                }
            }
            if (props.original) {
                actions.setOriginal(props.original)
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.compareList) {
                actions.setCompareList(props.compareList)
            }
            if (props.document.value) {
                if (
                    props.document.value.date &&
                    props.document.value.day === undefined
                ) {
                    let regDate = new Date(props.document.value.date)
                    if (regDate instanceof Date) {
                        let date = props.document.value.date.split(' ')
                        if (!date || !isDefined(date) || date.length === 0) {
                            date = props.document.value.date
                                .split('T')[0]
                                .split('-')
                        }
                        date = date[0].split('-')
                        actions.setYear(+date[0])
                        actions.setMonth(date[1])
                        actions.setDay(date[2])
                        actions.setIsValidDay(true)
                        actions.setIsValidMonth(true)
                        actions.setIsValidYear(true)
                    }
                }
            }
            if (props.original) {
                actions.setOriginal(props.original)
            }
        },
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                let locValue = {
                    day: state.day,
                    month: state.month,
                    year: state.year,
                }
                let locValid = {
                    day: state.isValidDay,
                    month: state.isValidMonth,
                    year: state.isValidYear,
                }
                if (isInteger(value)) {
                    let validInput = true
                    if (name.indexOf('day') > -1) {
                        if (+value > 31) {
                            value = 31
                        }
                        if (+value < 1) {
                            value = 1
                        }
                        locValue.day = +value
                        actions.setDay(value)
                        locValid.day = true
                        actions.setIsValidDay(true)
                    } else if (name.indexOf('month') > -1) {
                        if (+value > 12) {
                            value = 12
                        }
                        if (+value < 1) {
                            value = 1
                        }
                        locValue.month = +value
                        actions.setMonth(value)
                        locValid.month = true
                        actions.setIsValidMonth(true)
                    } else if (name.indexOf('year') > -1) {
                        if (+value < 1900) {
                            validInput = false
                        }
                        locValue.year = +value
                        actions.setYear(+value)
                        locValid.year = validInput
                        actions.setIsValidYear(validInput)
                    }
                } else {
                    if (name.indexOf('day') > -1) {
                        locValue.day = ''
                        actions.setDay('')
                        locValid.day = false
                        actions.setIsValidDay(false)
                    } else if (name.indexOf('month') > -1) {
                        locValue.month = ''
                        actions.setMonth('')
                        locValid.month = false
                        actions.setIsValidMonth(false)
                    } else if (name.indexOf('year') > -1) {
                        locValue.year = ''
                        actions.setYear('')
                        locValid.year = false
                        actions.setIsValidYear(false)
                    }
                }
                //
                if (locValid.day && locValid.month && locValid.year) {
                    if (+locValue.month < 10) {
                        locValue.month = '0' + +locValue.month
                    }
                    if (+locValue.day < 10) {
                        locValue.day = '0' + +locValue.day
                    }
                    let refDate =
                        locValue.year + '' + locValue.month + '' + locValue.day
                    let dateValue = getDateObjFromYMD(refDate)
                    //
                    if (state.type === 'corpocompare') {
                        let startDate = null,
                            endDate = null
                        let tournamentStartDate = null,
                            tournamentEndDate = null,
                            tournamentName = ''
                        //
                        for (var compare in state.compareList) {
                            startDate =
                                state.compareList[compare].startDate.date
                            endDate = state.compareList[compare].endDate.date

                            startDate = startDate
                                .split(' ')[0]
                                .split('-')
                                .join('')
                            endDate = endDate.split(' ')[0].split('-').join('')
                            let errorMessage = ''

                            if (
                                state.original.id !==
                                    state.compareList[compare].id &&
                                refDate > startDate &&
                                refDate < endDate
                            ) {
                                tournamentName = state.compareList[compare].name
                                tournamentStartDate = state.compareList[
                                    compare
                                ].startDate.date
                                    .split(' ')[0]
                                    .split('-')
                                    .reverse()
                                    .join('/')
                                tournamentEndDate = state.compareList[
                                    compare
                                ].endDate.date
                                    .split(' ')[0]
                                    .split('-')
                                    .reverse()
                                    .join('/')
                                errorMessage =
                                    t(
                                        'Un challenge est déjà activé sur cette période : '
                                    ) +
                                    t('challenge') +
                                    ' "' +
                                    tournamentName +
                                    '" ' +
                                    t('du') +
                                    ' ' +
                                    tournamentStartDate +
                                    ' ' +
                                    t('au') +
                                    ' ' +
                                    tournamentEndDate +
                                    '.' +
                                    ' ' +
                                    t('Veuillez choisir une autre date.')
                            }
                            actions.setErrorCompare(errorMessage)
                        }
                        if (state.name === 'startDate') {
                            let registerDate = new Date(dateValue.date).addDays(
                                14,
                                true
                            )
                            registerDate = getYMDfromDateObj(registerDate)
                            let registerMessageDate =
                                t('L‘inscription sera lancée à partir du') +
                                ' ' +
                                registerDate.day +
                                '/' +
                                registerDate.month +
                                '/' +
                                registerDate.year
                            actions.setRegisterDate(registerMessageDate)
                        }
                    }
                    state.sendToParent({
                        target: { name: state.name, value: dateValue },
                    })
                } else {
                    state.sendToParent({
                        target: {
                            name: state.name,
                            value:
                                locValue.year +
                                '' +
                                locValue.month +
                                '' +
                                locValue.day,
                        },
                    })
                }
            },
        sendQRCODE: () => (state, actions) => {
            //
        },
        sendInvitation: () => (state, actions) => {
            if (state.submitting) {
                return false
            }
            actions.setSubmitting(true)
            User.sendInvitationUsingId(state.userId).then(() => {
                actions.setSubmitting(false)
                actions.setWithHour(t('invitation en attente'))
                actions.setMessageContent(t('Invitation envoyée.'))
                actions.displaySuccessMessage(true)
            })
        },
        resetPassword: () => (state, actions) => {
            if (state.submitting) {
                return false
            }
            actions.setSubmitting(true)
            User.resetPasswordUser(state.userId).then(() => {
                actions.setSubmitting(false)
            })
        },
        unarchivatePatient: () => (state, actions) => {
            User.unarchivatePatient(state.userId).then(() => {
                actions.setSubmitting(false)
                actions.setMessageContent(t('Patient désarchivé.'))
                actions.displaySuccessMessage(true)
                actions.setUnarchivatePatientModal(false)
                window.location.reload()
            })
        },
        unlockAccount: () => (state, actions) => {
            if (state.submitting) {
                return false
            }
            actions.setSubmitting(true)
            User.editUser(state.userId, {
                security: { unblockLogin: true },
            }).then(() => {
                actions.setSubmitting(false)
                actions.setMessageContent(t('Compté débloqué.'))
                actions.displaySuccessMessage(true)
            })
        },
        setUnarchivatePatientModal: (newState) => (state) => ({
            unarchivatePatientModal: newState,
        }),
        setErrorCompare: (newState) => (state) => ({
            errorCompare: newState,
        }),
        setSubmitting: (newState) => (state) => ({
            submitting: newState,
        }),
        setDay: (newState) => (state) => ({
            day: newState,
        }),
        setMonth: (newState) => (state) => ({
            month: newState,
        }),
        setYear: (newState) => (state) => ({
            year: newState,
        }),
        setIsValidDay: (newState) => (state) => ({
            isValidDay: newState,
        }),
        setIsValidMonth: (newState) => (state) => ({
            isValidMonth: newState,
        }),
        setIsValidYear: (newState) => (state) => ({
            isValidYear: newState,
        }),
        setIsValid: (newState) => (state) => ({
            isValid: newState,
        }),
        setOriginal: (newState) => (state) => ({
            original: newState,
        }),
        setType: (newState) => (state) => ({
            type: newState,
        }),
        setCompareList: (newState) => (state) => ({
            compareList: newState,
        }),
        setRegisterDate: (newState) => (state) => ({
            registerDate: newState,
        }),
        setWithHour: (newState) => (state) => ({
            withHour: newState,
        }),
        setConnectState: (newState) => (state) => ({
            connectState: newState,
        }),
        setToken: (newState) => (state) => ({
            token: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setUserId: (newState) => (state) => ({
            userId: newState,
        }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
        setMessageContent: (newState) => (state) => ({
            messageContent: newState,
        }),
        waitForLastInput: ({ target }) => debounce(500, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div key={state.name}>
            {state.messageActive && (
                <MessagePopup
                    kind={state.messageKind}
                    active={'active'}
                    duration={3000}
                    durationCallback={() =>
                        actions.displaySuccessMessage(false)
                    }
                    message={state.messageContent}
                ></MessagePopup>
            )}
            {isDefined(state.connectState) &&
                state.connectState.indexOf('archive') > -1 &&
                isDefined(props.document.value.date) && (
                    <p
                        style={{
                            display: 'inline-block',
                            margin: '0px 10px 0 0',
                        }}
                    >
                        {'Patient archivé le'}
                    </p>
                )}
            <input
                key={state.name + '-day'}
                type="text"
                placeholder="jj"
                maxlength="2"
                value={state.day}
                name={state.name + '-day'}
                class="date-day"
                oninput={actions.waitForLastInput}
                readonly={props.readonly === true ? 'readonly' : false}
            />
            <input
                key={state.name + '-month'}
                type="text"
                placeholder="mm"
                maxlength="2"
                value={state.month}
                name={state.name + '-month'}
                class="date-month"
                oninput={actions.waitForLastInput}
                readonly={props.readonly === true ? 'readonly' : false}
            />
            <input
                key={state.name + '-year'}
                type="text"
                placeholder="aaaa"
                maxlength="4"
                value={state.year}
                name={state.name + '-year'}
                class="date-year"
                oninput={actions.waitForLastInput}
                readonly={props.readonly === true ? 'readonly' : false}
            />
            {state.registerDate !== null && (
                <p class="btzInfoBlock">{state.registerDate}</p>
            )}
            {state.errorCompare !== '' && (
                <p class="btzErrorBlock">{state.errorCompare}</p>
            )}
            {state.withHour !== false && (
                <p class="" style={{ display: 'inline-block', margin: '0px' }}>
                    {' ' + state.withHour}
                </p>
            )}
            {state.connectState !== null && isDefined(props.original) && (
                <div
                    class="btzForm-btzFormGroup btzForm-btzFooter"
                    style="float: right;margin: 0 0 0 15px;"
                >
                    {state.connectState.indexOf('qrcode') > -1 && (
                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{
                                'display': 'inline-block',
                                'vertical-align': 'top',
                            }}
                        >
                            <Link
                                target={'_blank'}
                                to={`${APP_API_URL}/admin/users/${state.userId}/qrcode?xtoken=${state.token}`}
                                class=""
                            >
                                {t('QR code')}
                            </Link>
                        </Form.Group>
                    )}
                    {isDefined(state.userId) && (
                        <dummy>
                            {state.connectState.indexOf('invit') > -1 && (
                                <div
                                    class="btzButtonWrapper"
                                    style={{
                                        'display': 'inline-block',
                                        'vertical-align': 'top',
                                    }}
                                >
                                    <Button
                                        primary
                                        flat
                                        blue={!state.submitting}
                                        active={!state.submitting}
                                        loading={state.submitting}
                                        onclick={actions.sendInvitation}
                                    >
                                        {t('Envoyer l’invitation')}
                                    </Button>
                                </div>
                            )}
                            {state.connectState.indexOf('sendResetCode') >
                                -1 && (
                                <div
                                    class="btzButtonWrapper"
                                    style={{
                                        'display': 'inline-block',
                                        'vertical-align': 'top',
                                    }}
                                >
                                    <Button
                                        primary
                                        flat
                                        blue={!state.submitting}
                                        active={!state.submitting}
                                        loading={state.submitting}
                                        onclick={actions.resetPassword}
                                    >
                                        {t('Envoyer le code de vérification')}
                                    </Button>
                                </div>
                            )}
                            {state.connectState.indexOf('archive') > -1 &&
                                isDefined(props.document.value.date) && (
                                    <div
                                        class="btzButtonWrapper"
                                        style={{
                                            'display': 'inline-block',
                                            'vertical-align': 'top',
                                        }}
                                    >
                                        <Button
                                            primary
                                            flat
                                            blue={!state.submitting}
                                            active={!state.submitting}
                                            loading={state.submitting}
                                            onclick={() => {
                                                actions.setUnarchivatePatientModal(
                                                    true
                                                )
                                            }}
                                        >
                                            {t('Désarchiver')}
                                        </Button>
                                    </div>
                                )}
                            {/*(state.connectState.indexOf('unlock') > -1) &&
                                <div class='btzButtonWrapper' style={{ 'display': 'inline-block', 'vertical-align': 'top' }}>
                                    <Button primary flat blue={!state.submitting} active={!state.submitting} loading={(state.submitting)} onclick={actions.unlockAccount}>{t('Débloquer le compte')}</Button>
                                </div>
                            */}
                        </dummy>
                    )}
                </div>
            )}
            {state.unarchivatePatientModal && (
                <div class={'rslModalWrapper'}>
                    <div class={'rslModalBackdrop'} />
                    <div class={'rslModal'}>
                        <p class={'rslModalTitle'}>
                            {'Souhaitez-vous désarchiver ce patient ?'}
                        </p>
                        <div class={'rslModalFooter'}>
                            <Button
                                primary
                                flat
                                cancel
                                onclick={() => {
                                    actions.setUnarchivatePatientModal(false)
                                }}
                            >
                                {t('Annuler')}
                            </Button>
                            <Button
                                primary
                                flat
                                blue={!state.submitting}
                                active={!state.submitting}
                                loading={state.submitting}
                                onclick={() => {
                                    actions.unarchivatePatient()
                                }}
                            >
                                {t('Désarchiver')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    ),
    'adminformdate'
)

export { AdminFormDate }

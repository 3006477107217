import { h, Component } from '@app/utils'
import { isDefined, isInteger } from '@app/core'

import './index.scss'

const AdminFormPhone = Component(
    {
        value: ['', '', '', '', ''],
        name: '',
        readonly: false,
        verifyQuery: null,
        sendToParent: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('WITH QUERY : ', props)
            if (props.oninput) {
                actions.setSendToParent(props.oninput)
            }
            if (props.name) {
                actions.setName(props.name)
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            let phoneValue = ['', '', '', '', '']
            if (props.document.value) {
                if (
                    isDefined(props.document.value) &&
                    props.document.value !== ''
                ) {
                    phoneValue = props.document.value
                }
            }
            actions.setValue(phoneValue)
        },
        updateField: () => (state, actions) => {
            if (state.sendToParent !== null) {
                state.sendToParent({ name: state.name, value: state.value })
            }
        },
        updateValue:
            ({ e, idx }) =>
            (state, actions) => {
                let newValue = e.target.value
                let value = state.value
                if (isInteger(newValue) || newValue === '') {
                    value[idx] = newValue
                    actions.updateField()
                }
                actions.setValue(value)
                if (String(newValue).length === 2) {
                    let target = e.srcElement || e.target
                    if (isDefined(target.nextElementSibling)) {
                        target.nextElementSibling.focus()
                    }
                }
            },
        setValue: (newState) => (state) => ({
            value: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setSendToParent: (newState) => (state) => ({
            sendToParent: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <input
                type="text"
                value={state.value[0]}
                maxlength="2"
                oninput={(el) => {
                    actions.updateValue({ e: el, idx: 0 })
                }}
            />
            <input
                type="text"
                value={state.value[1]}
                maxlength="2"
                oninput={(el) => {
                    actions.updateValue({ e: el, idx: 1 })
                }}
            />
            <input
                type="text"
                value={state.value[2]}
                maxlength="2"
                oninput={(el) => {
                    actions.updateValue({ e: el, idx: 2 })
                }}
            />
            <input
                type="text"
                value={state.value[3]}
                maxlength="2"
                oninput={(el) => {
                    actions.updateValue({ e: el, idx: 3 })
                }}
            />
            <input
                type="text"
                value={state.value[4]}
                maxlength="2"
                oninput={(el) => {
                    actions.updateValue({ e: el, idx: 4 })
                }}
            />
        </div>
    ),
    'adminformphone'
)

export { AdminFormPhone }

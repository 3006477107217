import { t, h, Component } from '@app/utils'
import { Settings, Pdfs } from '@app/api'
import moment from 'moment'
import {
    isDefined,
    addCSRFToken,
    allRoutes,
    getYMDfromDateObj,
} from '@app/core'
import { Form, Button, MessagePopup } from '@app/elements'

import Healthteam from './Healthteam'
console.log('Healthteam : ', Healthteam)

import * as constants from '@app/constants'

import IndexLayout from '@app/layouts/pages/layout'

const state = {
    actions: {
        addDoctorsToDoctor: { method: 'POST', api: '' },
        removeDoctorsFromDoctor: { method: 'DELETE', api: '' },
    },
    datas: {
        targetDoctorId: '',
        processedDoctorsIds: [],
    },
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
    setState:
        ({ key, value }) =>
        (state) => ({
            ...state,
            [key]: value,
        }),
}

const view =
    (state, actions) =>
    ({ match, customer }, children) =>
        (
            <IndexLayout
                page="adminoperations"
                customer={customer}
                currentPage={'adminoperations'}
            >
                <Healthteam />
            </IndexLayout>
        )

export default Component(state, actions, view, 'adminoperations')

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const article_CARD = {
    filter: [],
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'date', label: 'date création' },
        { key: 'title', label: 'titre' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/content/articles/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/content/articles/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editArticle,
        },
        // {'key': 'frequency', 'label': 'Changer la fréquence', 'kind': 'list-api', 'list': Object.keys(constants.custo.FREQUENCY).map(i => constants.custo.FREQUENCY[i]), 'action': Card.editArticle},
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteArticle,
        },
    ],
    tableAllOptions: {
        label: 'ARTICLES SÉLECTIONNÉS',
        list: [
            {
                key: 'delete',
                label: 'Supprimer',
                kind: 'confirm',
                action: Card.deleteArticle,
            },
        ],
    },
    header: ['Configurer l‘article'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        preview: {
            field: 'hidden',
            value: true,
            sending: false,
        },
        template: {
            field: 'hidden',
            value: 'article',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Titre de l‘article',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le titre de votre contenu',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le titre de la carte',
                i18nMessage: t('Veuillez renseigner le titre de la carte'),
            },
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Résumé de l‘article',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de la carte',
                i18nMessage: t(
                    'Veuillez renseigner la description de la carte'
                ),
            },
        },
        content: {
            name: 'content',
            type: 'translation',
            label: 'Rédaction de l‘article',
            field: 'textarea',
            mandatory: true,
            placeholder:
                'Renseignez ici le corps de votre contenu (entre 300 et 600 caractères)',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la description de la carte',
                i18nMessage: t(
                    'Veuillez renseigner la description de la carte'
                ),
            },
        },
        readtime: {
            name: 'readtime',
            type: 'translation',
            label: 'Temps de lecture',
            field: 'text',
            mandatory: true,
            placeholder:
                'Renseignez ici le temps de lecture estimé de votre contenu',
            value: {},
        },
        ecs: {
            name: 'ecs',
            type: 'translation',
            neededApi: 'kpi',
            label: '[[ECS]]',
            field: 'ecs',
            model: { key: null, value: null },
            list: 'static',
            placeholder: 'Texte dynamique',
            value: '',
        },
        tags: {
            name: 'tags',
            type: 'tags',
            label: 'Tag Aliment',
            field: 'dyninput-to-div',
            searchbox: true,
            model: { key: 'id', value: 'alias' },
            list: 'dynamic',
            placeholder: {
                input: 'Aucune',
                textarea: '',
            },
            value: '',
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
    },
}

export { article_CARD }

import { t, h, Component } from '@app/utils'
import { Settings } from '@app/api'
import { AFCheckBox } from '@app/elements'
import { deepCopy, isDefined } from '@app/core'
import { settings } from '@app/core/settings'
import './index.scss'

const { APP_CUSTOMISATION } = settings

const AdminFormCheckBox = Component(
    {
        checkboxs: null,
        checkedboxs: [],
        defaultcheckedboxs: [],
        statuscheckedboxs: [],
        sendToParent: null,
        readonly: false,
        readonlyValues: [],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.name) {
                actions.getCheckBoxList(props)
            }
            if (props.document) {
                if (props.document.readonlyValues) {
                    actions.setReadonlyValues(
                        deepCopy(props.document.readonlyValues)
                    )
                }
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            let checkedboxs = []
            if (props.checkedboxs) {
                checkedboxs = props.checkedboxs
            }
            if (
                props.document !== null &&
                props.document.value !== undefined &&
                props.document.value.length > 0
            ) {
                checkedboxs = props.document.value
            }
            actions.setCheckedCheckBoxList(checkedboxs)
            //
            if (props.defaultcheckedboxs) {
                actions.setDefaultCheckedBoxs(props.defaultcheckedboxs)
            }
            if (props.statuscheckedboxs) {
                actions.setStatuscheckedboxs(props.statuscheckedboxs)
            }
        },
        getCheckBoxList: (props) => (state, actions) => {
            let checkboxs = []
            if (props.name === 'customers') {
                if (
                    APP_CUSTOMISATION.toLowerCase() === 'theraflow'
                ) {
                    Settings.getCustomerDaugthers().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setCheckBoxList(res.data)
                        }
                    })
                } else {
                    Settings.getCustomers().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setCheckBoxList(res.data)
                        }
                    })
                }
            } else if (props.document.list) {
                let list = props.document.list
                if (!Array.isArray(list)) {
                    let parsedList = []
                    for (var o in list) {
                        // concern themes, especially
                        if (list[o].value !== '') {
                            parsedList.push({
                                name: list[o].value,
                                id: list[o].key || o,
                                img: list[o].img,
                            })
                        }
                    }
                    list = parsedList
                }
                console.log('setCheckBoxList : ', list, props.statuscheckedboxs)
                actions.setCheckBoxList(list)
            }
        },
        selectAllCheckbox: (el) => (state, actions) => {
            let checkedboxs = []
            let target = el.target || el
            let checkboxName = target.name
            let checkboxChecked = target.checked
            if (checkboxChecked) {
                for (var checkbox in state.checkboxs) {
                    checkedboxs.push(state.checkboxs[checkbox].id)
                }
            }
            state.sendToParent({
                target: { name: checkboxName, value: checkedboxs },
            })

            if (checkboxChecked) {
                checkedboxs.push('-1')
            }
            actions.setCheckedCheckBoxList(checkedboxs)
        },
        defaultThisOne: (el) => (state, actions) => {
            let checkedboxs = state.checkedboxs
            let target = el.target || el
            let checkboxName = target.name || target.getAttribute('data-name')
            let checkboxByDefault =
                target.bydefault || target.getAttribute('data-bydefault')
            let checkboxId = target.id || target.getAttribute('data-id')
            if (checkboxByDefault === false || checkboxByDefault === 'false') {
                checkboxByDefault = true
            } else {
                checkboxByDefault = false
            }
            //
            let targetCheckbox = checkedboxs.filter((f) => f === checkboxId)
            state.sendToParent({
                target: {
                    name: checkboxName,
                    value: checkboxId + '-bydefault-' + checkboxByDefault,
                },
            })
        },
        selectThisOne: (el) => (state, actions) => {
            let checkedboxs = state.checkedboxs
            let target = el.target || el
            let checkboxName = target.name || target.getAttribute('data-name')
            let checkboxChecked =
                target.checked || target.getAttribute('data-checked')
            let checkboxValue =
                target.value || target.getAttribute('data-value')

            if (state.readonlyValues.indexOf(+checkboxValue) > -1) {
                //
            } else {
                if (checkboxChecked === true || checkboxChecked === 'true') {
                    checkedboxs.push(
                        isNaN(+checkboxValue) ? checkboxValue : +checkboxValue
                    )
                } else {
                    for (var checkedbox in checkedboxs) {
                        if (
                            checkedboxs[checkedbox] === checkboxValue ||
                            +checkedboxs[checkedbox] === +checkboxValue
                        ) {
                            checkedboxs.splice(+checkedbox, 1)
                        }
                    }
                }
            }
            actions.setCheckedCheckBoxList(checkedboxs)
            state.sendToParent({
                target: { name: checkboxName, value: checkedboxs },
            })
        },
        createThisOne: (el) => (state, actions) => {
            let checkedboxs = state.checkedboxs
            let target = el.target || el
            let checkboxName = target.name || target.getAttribute('data-name')
            let checkboxChecked =
                target.checked || target.getAttribute('data-checked')
            let checkboxValue =
                target.value || target.getAttribute('data-value')
            state.sendToParent({
                target: { name: checkboxName, value: checkedboxs },
            })
        },
        setCheckBoxList: (newState) => (state) => ({
            checkboxs: newState,
        }),
        setCheckedCheckBoxList: (newState) => (state) => ({
            checkedboxs: newState,
        }),
        setReadonlyValues: (newState) => (state) => ({
            readonlyValues: newState,
        }),
        setDefaultCheckedBoxs: (newState) => (state) => ({
            defaultcheckedboxs: newState,
        }),
        setStatuscheckedboxs: (newState) => (state) => ({
            statuscheckedboxs: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.name + '-' + props.key} class="sup-group-checkboxs">
            {state.checkboxs !== null && Array.isArray(state.checkboxs) && (
                <div>
                    {state.checkboxs.map((chk, idx) => (
                        <div
                            style={{
                                'display': 'inline-block',
                                'vertical-align': 'top',
                                'position': 'relative',
                            }}
                            key={chk.id}
                        >
                            <AFCheckBox
                                key={props.name + '-' + props.key + '-' + idx}
                                checkbox={chk}
                                checkedboxs={state.checkedboxs}
                                name={props.name}
                                onclick={actions.selectThisOne}
                            ></AFCheckBox>
                            {chk.defaultOpt === true &&
                                state.checkedboxs.indexOf(chk.id) > -1 && (
                                    <div
                                        class={
                                            'sup-group-checkboxs-defaults ' +
                                            (props.defaultcheckedboxs.indexOf(
                                                chk.id
                                            ) > -1
                                                ? true
                                                : false)
                                        }
                                        data-name={props.name}
                                        key={
                                            props.name +
                                            '-' +
                                            props.key +
                                            '-' +
                                            idx +
                                            '-default'
                                        }
                                        checkbox={chk}
                                        checkedboxs={state.checkedboxs}
                                        data-id={chk.id}
                                        data-bydefault={String(
                                            props.defaultcheckedboxs.indexOf(
                                                chk.id
                                            ) > -1
                                                ? true
                                                : false
                                        )}
                                        onclick={actions.defaultThisOne}
                                    >
                                        {t('par défaut ?')}
                                    </div>
                                )}
                            {chk.activeLabel === true && (
                                <p
                                    class={
                                        'sup-group-checkboxs-active ' +
                                        (props.statuscheckedboxs.indexOf(
                                            chk.id
                                        ) > -1
                                            ? t('active')
                                            : t('inactive'))
                                    }
                                >
                                    {props.statuscheckedboxs.indexOf(chk.id) >
                                    -1
                                        ? t('active')
                                        : t('inactive')}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {props.document.allSelect === true && (
                <AFCheckBox
                    checkbox={{ id: '-1', name: 'Tous' }}
                    checkedboxs={state.checkedboxs}
                    name={props.name}
                    onclick={actions.selectAllCheckbox}
                ></AFCheckBox>
            )}
        </div>
    ),
    'adminformcheckbox'
)

export { AdminFormCheckBox }

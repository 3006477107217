import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { AutoComplete } from '@app/api'
import { isDefined, deepCopy } from '@app/core'
import './index.scss'

const OrderedLinked = Component(
    {
        document: null,
        kind: '',
        type: '',
        linkedvalues: [],
        parentdocument: null,
        sendToParent: null,
        ulDisplayed: false,
        list: [],
        name: '',
        model: { key: null, value: null },
        currentSearch: '',
        readOnly: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.parentdocument.linkedQuery) {
                if (props.parentdocument.linkedQuery.kind) {
                    state.kind = props.parentdocument.linkedQuery.kind
                }
                if (props.parentdocument.linkedQuery.type) {
                    state.type = props.parentdocument.linkedQuery.type
                }
                if (props.parentdocument.linkedQuery.value) {
                    state.linkedvalues = props.parentdocument.linkedQuery.value
                }
            }
            if (props.document) {
                actions.setDocument(props.document)
            }
            if (props.parentdocument) {
                actions.setParentDocument(props.parentdocument)
            }
            if (props.model) {
                actions.setModel(props.model)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            console.log('>>>> JJJJ : ', props)
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        setModel: (newState) => (state) => ({
            model: newState,
        }),
        selectThisOne:
            ({ el, props }) =>
            (state, actions) => {
                let document = state.document
                document[
                    isDefined(state.model.value) ? state.model.value : 'name'
                ] = isDefined(state.model.value) ? el[state.model.value] : el
                document[isDefined(state.model.key) ? state.model.key : 'id'] =
                    isDefined(state.model.key) ? el[state.model.key] : el
                console.log('document : ', document)

                actions.setCurrentSearch('')
                actions.setDocument(document)
                state.sendToParent()
                actions.toggleUl(false)
            },
        onUpdate:
            ({ target: { name, value } }) =>
            (state, actions) => {
                actions.toggleUl(true)
                actions.onSearch(value)
            },
        setOrder: (order) => (state, actions) => {
            let document = state.document
            document.order = order
            actions.setDocument(document)
        },
        setCurrentSearch: (newState) => (state) => ({
            currentSearch: newState,
        }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setParentDocument: (newState) => (state) => ({
            parentdocument: newState,
        }),
        setList: (newState) => (state) => ({
            list: newState,
        }),
        waitForLastInput: ({ target }) => debounce(500, 'onUpdate', { target }),
        onSearch: (value) => (state, actions) => {
            if (state.kind == 'autocomplete') {
                if (value === '') {
                    actions.setList([])
                    actions.toggleUl(false)
                } else {
                    AutoComplete.getCardsByType(value, state.type).then(
                        (res) => {
                            let parsedList = []
                            if (isDefined(res.data) && !res.data.isError) {
                                for (var o in res.data) {
                                    if (isDefined(res.data[o].name)) {
                                        parsedList.push({
                                            name: res.data[o].name,
                                            id: isDefined(res.data[o].id)
                                                ? res.data[o].id
                                                : res.data[o].name,
                                        })
                                    }
                                }
                            }
                            actions.setList(parsedList)
                            actions.toggleUl(true)
                        }
                    )
                }
            } else {
                if (value === '') {
                    actions.setList(deepCopy(state.originalList))
                    actions.toggleUl(false)
                } else {
                    let list = state.list
                    list = list.filter((f) => {
                        if (isDefined(f.value) && typeof f.value === 'string') {
                            return (
                                String(f.value)
                                    .toLowerCase()
                                    .indexOf(value.toLowerCase()) > -1
                            )
                        } else if (
                            isDefined(f.name) &&
                            typeof f.name === 'string'
                        ) {
                            return (
                                String(f.name)
                                    .toLowerCase()
                                    .indexOf(value.toLowerCase()) > -1
                            )
                        }
                        return true
                    })
                    actions.setList(list)
                    actions.toggleUl(true)
                }
            }
        },
    },
    (state, actions) => (props, children) => (
        <div key={props.key} class={props.key}>
            <input
                key={'aforderedlinkeds-order-' + props.key}
                type={'hidden'}
                name={'order'}
                value={state.document[state.model.order]}
                oncreate={() => {
                    actions.setOrder(props.order)
                }}
                onupdate={() => {
                    actions.setOrder(props.order)
                }}
            />
            <div
                class="sup-custom-select sup-custom-select-wide-one"
                tabindex="0"
                onblur={() => {
                    actions.toggleUl(false)
                }}
                onclick={() => {
                    actions.toggleUl(!state.ulDisplayed)
                }}
            >
                <input
                    key={'value'}
                    name={'value'}
                    type="text"
                    placeholder={t('Tapez votre rechercher ici ...')}
                    oninput={(e) => {
                        actions.waitForLastInput(e)
                        actions.setCurrentSearch(e.currentTarget.value)
                    }}
                    value={
                        isDefined(state.currentSearch) &&
                        state.currentSearch !== ''
                            ? state.currentSearch
                            : state.document[
                                  isDefined(state.model.value)
                                      ? state.model.value
                                      : 'name'
                              ]
                    }
                    onblur={(e) => {
                        isDefined(e.relatedTarget)
                            ? ''
                            : actions.toggleUl(false)
                        actions.setCurrentSearch('')
                    }}
                    readOnly={state.readOnly}
                    autocomplete="off"
                />
                {state.readOnly === false && (
                    <div>
                        <ul
                            class="sup-custom-select-ul"
                            style={{
                                display: state.ulDisplayed ? 'block' : 'none',
                                margin: '0px',
                            }}
                        >
                            {state.list
                                .filter(
                                    (li) =>
                                        (state.model.key === null
                                            ? li
                                            : li[state.model.value]) !== '' &&
                                        state.linkedvalues.indexOf(
                                            state.model.key === null
                                                ? li
                                                : li[state.model.value]
                                        ) === -1
                                )
                                .map((l) => (
                                    <li
                                        class="sup-custom-select-li"
                                        data-value={
                                            state.model.key === null
                                                ? l
                                                : l[state.model.key]
                                        }
                                        onclick={() => {
                                            actions.selectThisOne({
                                                el: l,
                                                props: props,
                                            })
                                        }}
                                    >
                                        {state.model.key === null
                                            ? l
                                            : l[state.model.value]}
                                    </li>
                                ))}
                        </ul>
                        <div class="sup-custom-select-number">
                            {props.idx + 1}
                        </div>
                        <div
                            class="sup-custom-select-delete"
                            onclick={(el) => {
                                props.ondelete(props.idx)
                            }}
                        >
                            <p class="">{t('Supprimer')}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ),
    'aforderedlinkeds'
)

export { OrderedLinked }

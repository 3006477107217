import { t, h, Component } from '@app/utils'
import { deepCopy, isDefined } from '@app/core'
import { displayErrors } from '@app/api'
import { Form, Button, Modal } from '@app/elements'
import { AFScenarioSeuils } from './AFScenarioTypes/AFScenarioSeuils'
import { AFScenarioUpAndDown } from './AFScenarioTypes/AFScenarioUpAndDown'
import { ViewModalConfirmDelete } from './modalConfirmDelete'
import { ViewModalConfirmSave } from './modalConfirmSave'
import { debounce } from 'hyperapp-fx'
import './index.scss'

const AFScenario = Component(
    {
        document: null,
        documentClone: null,
        configured: false,
        sendToParent: null,
        modalDisplayed: false,
        modalConfirmDisplayed: false,
        confirmSaveDisplayed: false,
        list: [],
        name: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.index) {
                state.index = props.index
            }
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.list) {
                state.list = props.list
            }
            if (props.document) {
                state.documentClone = deepCopy(props.document)
                actions.setDocument(props.document)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document) {
                actions.setDocument(props.document)
            }
        },
        onConfirmSaveDocument: () => (state, actions) => {
            let seuils = null,
                alerts = null
            if (isDefined(state.documentClone.form.seuils)) {
                seuils = state.documentClone.form.seuils.value
            }
            if (isDefined(state.documentClone.form.alerts)) {
                alerts = state.documentClone.form.alerts.value
            }
            let isValid = true,
                errormssg = ''
            if (isDefined(state.documentClone.form.template)) {
                if (state.documentClone.form.template.value === 'seuils') {
                    if (!Array.isArray(seuils) || seuils.length !== 4) {
                        errormssg = t(
                            'Vous devez configurer tous les seuils pour pouvoir enregistrer.'
                        )
                        isValid = false
                    } else {
                        let refSeuils = [
                            t('très faible'),
                            t('faible'),
                            t('élevé'),
                            t('très élevé'),
                        ]
                        for (var seuil in seuils) {
                            if (seuils[seuil] === '') {
                                isValid = false
                                errormssg = t(
                                    'Vous devez configurer tous les seuils pour pouvoir enregistrer.'
                                )
                            } else if (seuil > 0) {
                                if (+seuils[seuil] < +seuils[seuil - 1]) {
                                    isValid = false
                                    errormssg =
                                        t('Attention, le seuil') +
                                        ' « ' +
                                        refSeuils[seuil - 1] +
                                        ' » ' +
                                        t('doit être inférieur au seuil') +
                                        ' « ' +
                                        refSeuils[seuil] +
                                        ' ».'
                                    break
                                }
                            }
                        }
                    }
                } else if (
                    state.documentClone.form.template.value === 'upanddown'
                ) {
                    if (
                        isDefined(alerts) &&
                        (Object.keys(alerts).length === 0 ||
                            (alerts.up.length === 0 &&
                                alerts.down.length === 0))
                    ) {
                        isValid = false
                        errormssg = t(
                            'Vous devez configurer au moins une alerte pour pouvoir enregistrer.'
                        )
                    }
                }
            }

            if (!isValid) {
                displayErrors(errormssg, 3000)
            } else {
                actions.toggleDisplayConfirmSaveDocument(true)
            }
        },
        toggleDisplayConfirmSaveDocument: (newState) => (state) => ({
            confirmSaveDisplayed: newState,
        }),
        onSaveDocument: () => (state, actions) => {
            actions.setConfigured(true)
            actions.toggleDisplayConfirmSaveDocument(false)
            actions.displayModal(false)
            actions.setDocument(deepCopy(state.documentClone))
            state.sendToParent({
                newDocument: deepCopy(state.documentClone),
                mode: 'update',
            })
        },
        onConfirmDeleteDocument: () => (state, actions) => {
            let seuils = null,
                alerts = null,
                documentClone = state.documentClone
            if (isDefined(documentClone.form.seuils)) {
                seuils = documentClone.form.seuils.value
            }
            if (isDefined(documentClone.form.alerts)) {
                alerts = documentClone.form.alerts.value
            }
            if (seuils !== null) {
                if (documentClone.form.template.value === 'seuils') {
                    seuils = ['', '', '', '']
                }
                documentClone.form.seuils.value = seuils
            }
            if (alerts !== null) {
                if (documentClone.form.template.value === 'seuils') {
                    alerts = {}
                } else if (documentClone.form.template.value === 'upanddown') {
                    alerts = { up: [], down: [] }
                }
                documentClone.form.alerts.value = alerts
            }
            actions.setConfigured(false)
            actions.setDocument(deepCopy(documentClone))
            state.sendToParent({
                newDocument: deepCopy(state.documentClone),
                mode: 'delete',
            })
            actions.displayConfirmModal(false)
        },
        onCancel: () => (state, actions) => {
            actions.displayModal(false)
            actions.setDocumentClone(deepCopy(state.document))
        },
        onUpdate: () => (state, actions) => {
            //
        },
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setDocumentClone: (newState) => (state) => ({
            documentClone: newState,
        }),
        setConfigured: (newState) => (state) => ({
            configured: newState,
        }),
        displayModal: (newState) => (state) => ({
            modalDisplayed: newState,
        }),
        displayConfirmModal: (newState) => (state) => ({
            modalConfirmDisplayed: newState,
        }),
        waitForLastInput: ({ target }) => debounce(240, 'onUpdate', { target }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <div class="sup-group-form">
                {state.confirmSaveDisplayed && (
                    <Modal
                        key={props.key + '-confirmsave'}
                        class={
                            'btzModalCenter btzModalConfirmSave ' +
                            state.confirmSaveDisplayed
                        }
                        modalDisplayed={state.confirmSaveDisplayed}
                        cancelAction={() =>
                            actions.toggleDisplayConfirmSaveDocument(false)
                        }
                        content={
                            <ViewModalConfirmSave
                                document={state.documentClone}
                                onConfirmCancel={() =>
                                    actions.toggleDisplayConfirmSaveDocument(
                                        false
                                    )
                                }
                                onConfirmSaveDocument={actions.onSaveDocument}
                            ></ViewModalConfirmSave>
                        }
                    ></Modal>
                )}
                <div class="afscenario-header">
                    <label class="sup-label afscenario-header-title">
                        {state.document.header.title}
                    </label>
                    {state.documentClone.form.template.value === 'seuils' ? (
                        <dummy>
                            {Object.keys(state.document.form.alerts.value)
                                .length > 0 ? (
                                <p class="afscenario-header-subtitle">
                                    {Object.keys(
                                        state.document.form.alerts.value
                                    ).length +
                                        ' ' +
                                        t('alertes configurées')}
                                </p>
                            ) : (
                                <p class="afscenario-header-subtitle">
                                    {state.document.header.subtitle}
                                </p>
                            )}
                        </dummy>
                    ) : (
                        <dummy>
                            {state.document.form.alerts.value.up.length +
                                state.document.form.alerts.value.down.length >
                            0 ? (
                                <p class="afscenario-header-subtitle">
                                    {state.document.form.alerts.value.up
                                        .length +
                                        state.document.form.alerts.value.down
                                            .length +
                                        ' ' +
                                        t('alertes configurées')}
                                </p>
                            ) : (
                                <p class="afscenario-header-subtitle">
                                    {state.document.header.subtitle}
                                </p>
                            )}
                        </dummy>
                    )}
                </div>
                {state.configured ? (
                    <div
                        class="afscenario-buttons"
                        style={{ display: 'block', width: '100%' }}
                    >
                        <div style={{ display: 'inline-block', width: '85%' }}>
                            {state.documentClone.form.template.value ===
                            'seuils' ? (
                                <AFScenarioSeuils
                                    key={
                                        props.key +
                                        '-afsseuils-' +
                                        state.document.header.key
                                    }
                                    document={state.documentClone}
                                    readonly={true}
                                ></AFScenarioSeuils>
                            ) : (
                                <AFScenarioUpAndDown
                                    key={
                                        props.key +
                                        '-afsupanddown-' +
                                        state.document.header.key
                                    }
                                    document={state.documentClone}
                                    readonly={true}
                                ></AFScenarioUpAndDown>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'inline-block',
                                width: '15%',
                                position: 'relative',
                            }}
                        >
                            <Form.Group classes="btzForm-btzFooter">
                                <Modal
                                    key={props.key + '-delete'}
                                    class="btzModalCenter"
                                    modalDisplayed={state.modalConfirmDisplayed}
                                    cancelAction={() =>
                                        actions.displayConfirmModal(false)
                                    }
                                    content={
                                        <ViewModalConfirmDelete
                                            document={state.document}
                                            onConfirmCancel={() =>
                                                actions.displayConfirmModal(
                                                    false
                                                )
                                            }
                                            onConfirmDeleteDocument={
                                                actions.onConfirmDeleteDocument
                                            }
                                        ></ViewModalConfirmDelete>
                                    }
                                >
                                    <Button
                                        primary
                                        flat
                                        dangerous
                                        onclick={() =>
                                            actions.displayConfirmModal(true)
                                        }
                                        style={{
                                            position: 'absolute',
                                            right: '0',
                                            top: '-50px',
                                        }}
                                    >
                                        {t('Supprimer')}
                                    </Button>
                                </Modal>
                                <Modal
                                    key={props.key + '-edit'}
                                    class="btzModalCenter"
                                    modalDisplayed={
                                        state.modalDisplayed &&
                                        !state.confirmSaveDisplayed
                                    }
                                    cancelAction={actions.onCancel}
                                    content={
                                        state.documentClone.form.template
                                            .value === 'seuils' ? (
                                            <AFScenarioSeuils
                                                key={
                                                    props.key +
                                                    '-seuils-edit-' +
                                                    state.document.header.key
                                                }
                                                document={state.documentClone}
                                                onCancel={actions.onCancel}
                                                onSaveDocument={
                                                    actions.onConfirmSaveDocument
                                                }
                                                readonly={false}
                                            ></AFScenarioSeuils>
                                        ) : (
                                            <AFScenarioUpAndDown
                                                key={
                                                    props.key +
                                                    '-upandown-edit-' +
                                                    state.document.header.key
                                                }
                                                document={state.documentClone}
                                                onCancel={actions.onCancel}
                                                onSaveDocument={
                                                    actions.onConfirmSaveDocument
                                                }
                                                readonly={false}
                                            ></AFScenarioUpAndDown>
                                        )
                                    }
                                >
                                    <Button
                                        primary
                                        flat
                                        onclick={() =>
                                            actions.displayModal(true)
                                        }
                                    >
                                        {t('Modifier')}
                                    </Button>
                                </Modal>
                            </Form.Group>
                        </div>
                    </div>
                ) : (
                    <div class="afscenario-buttons">
                        <Form.Group classes="btzForm-btzFooter">
                            <Modal
                                class="btzModalCenter"
                                modalDisplayed={
                                    state.modalDisplayed &&
                                    !state.confirmSaveDisplayed
                                }
                                cancelAction={actions.onCancel}
                                content={
                                    state.documentClone.form.template.value ===
                                    'seuils' ? (
                                        <AFScenarioSeuils
                                            key={
                                                props.key +
                                                '-seuils-create-' +
                                                state.document.header.key
                                            }
                                            document={state.documentClone}
                                            onCancel={actions.onCancel}
                                            onSaveDocument={
                                                actions.onConfirmSaveDocument
                                            }
                                            readonly={false}
                                        ></AFScenarioSeuils>
                                    ) : (
                                        <AFScenarioUpAndDown
                                            key={
                                                props.key +
                                                '-upandown-create-' +
                                                state.document.header.key
                                            }
                                            document={state.documentClone}
                                            onCancel={actions.onCancel}
                                            onSaveDocument={
                                                actions.onConfirmSaveDocument
                                            }
                                            readonly={false}
                                        ></AFScenarioUpAndDown>
                                    )
                                }
                            >
                                <Button
                                    primary
                                    flat
                                    onclick={() => actions.displayModal(true)}
                                >
                                    {t('Configurer les seuils & alertes')}
                                </Button>
                            </Modal>
                        </Form.Group>
                    </div>
                )}
            </div>
        </div>
    ),
    'afscenario'
)

export { AFScenario }

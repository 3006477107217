import { t, h } from '@app/utils'
import { Button, Form } from '@app/elements'

export const ModalViewDeleteTeam = (props, actions) => (
    <div class={'supModalContainer team-delete'} style={{ width: '550px' }}>
        <div class="">
            <p class="sup-title" style={{ 'margin-bottom': '50px' }}>
                {t('Êtes-vous certain de vouloir supprimer cette équipe ?')}
            </p>

            <Form.Group classes="btzForm-btzFooter">
                <Button primary flat cancel onclick={props.cancelAction}>
                    {t('Non')}
                </Button>
            </Form.Group>
            <Form.Group classes="btzForm-btzFooter">
                <Button primary flat active onclick={props.confirmAction}>
                    {t('Oui')}
                </Button>
            </Form.Group>
        </div>
    </div>
)

import { t } from '@app/utils'

export const CUSTOMERS = {
    diabetes: {
        title: 'Diabetes Connect',
        description: 'Diabetes Connect description',
        i18ndescription: t('Diabetes Connect description'),
        mailto: 'alfred@betterise.me',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
    },
}

export const REDIRECT_URL = {
    0: {
        value: 'Page Programmes',
        i18nValue: t('Page Programmes'),
        key: 'PROGRAMS',
    },
    1: {
        value: 'Page Objectifs',
        i18nValue: t('Page Objectifs'),
        key: 'OBJECTIVES',
    },
    2: { value: 'Page Profil', i18nValue: t('Page Profil'), key: 'PROFIL' },
    3: { value: 'Page OC', i18nValue: t('Page OC'), key: 'OC' },
    4: { value: 'Page Données', i18nValue: t('Page Données'), key: 'DATAS' },
    5: { value: 'Page Badges', i18nValue: t('Page Badges'), key: 'BADGES' },
    6: {
        value: 'Page Nutrition',
        i18nValue: t('Page Nutrition'),
        key: 'NUTRITION',
    },
    7: {
        value: 'Page Favoris',
        i18nValue: t('Page Favoris'),
        key: 'FAVORITES',
    },
    8: {
        value: 'Page Traitement',
        i18nValue: t('Page Traitement'),
        key: 'TREATMENT',
    },
    9: {
        value: 'Page Glycémies',
        i18nValue: t('Page Glycémies'),
        key: 'GLYCEMY',
    },
    10: { value: 'Lien', i18nValue: t('Lien'), key: '' },
}

export const USER_ROLES = {
    0: {
        key: 'ROLE_BETTERISE_SUPERADMIN',
        value: 'Super Administrateur',
        i18nValue: t('Super Administrateur'),
    },
    1: {
        key: 'ROLE_CUSTOMER_SUPER_ADMIN',
        value: 'Customer Stat + Admin + Users',
        i18nValue: t('Customer Stat + Admin + Users'),
    },
    2: {
        key: 'ROLE_CUSTOMER_ADMIN',
        value: 'Customer Stat + Admin',
        i18nValue: t('Customer Stat + Admin'),
    },
    3: {
        key: 'ROLE_CUSTOMER_CONTENT',
        value: 'Customer contenu',
        i18nValue: t('Customer contenu'),
    },
    4: {
        key: 'ROLE_CUSTOMER_STAT',
        value: 'Customer Stat',
        i18nValue: t('Customer Stat'),
    },
    5: { key: 'ROLE_USER', value: 'Utilisateur', i18nValue: t('Utilisateur') },
    6: {
        key: 'ROLE_CARDIO_CARDIOLOGIST',
        value: 'Cardiologue',
        i18nValue: t('Cardiologue'),
    },
    7: {
        key: 'ROLE_CARDIO_NURSE',
        value: 'Infirmière',
        i18nValue: t('Infirmière'),
    },
    8: {
        key: 'ROLE_CARDIO_COACH',
        value: 'Coach Syndo',
        i18nValue: t('Coach Syndo'),
    },
    9: { key: 'ROLE_DOCTOR', value: 'Docteur', i18nValue: t('Docteur') },
}

import icoFR from '@app/img/ico/flag/flag-fr.png'
import icoFRx2 from '@app/img/ico/flag/flag-fr@2x.png'
import icoEN from '@app/img/ico/flag/flag-en.png'
import icoENx2 from '@app/img/ico/flag/flag-en@2x.png'
import icoIT from '@app/img/ico/flag/flag-it.png'
import icoITx2 from '@app/img/ico/flag/flag-it@2x.png'
import icoDE from '@app/img/ico/flag/flag-de.png'
import icoDEx2 from '@app/img/ico/flag/flag-de@2x.png'
import icoNL from '@app/img/ico/flag/flag-nl.png'
import icoNLx2 from '@app/img/ico/flag/flag-nl@2x.png'
import icoES from '@app/img/ico/flag/flag-es.png'
import icoESx2 from '@app/img/ico/flag/flag-es@2x.png'

export const COUNTRIES = {
    0: {
        label: 'Français',
        key: 'fr',
        flag: { normal: icoFR, retina: icoFRx2 },
    },
    1: {
        label: 'Anglais',
        key: 'en',
        flag: { normal: icoEN, retina: icoENx2 },
    },
    2: {
        label: 'Flamand',
        key: 'nl',
        flag: { normal: icoNL, retina: icoNLx2 },
    },
    3: {
        label: 'Italien',
        key: 'it',
        flag: { normal: icoIT, retina: icoITx2 },
    },
    4: {
        label: 'Allemand',
        key: 'de',
        flag: { normal: icoDE, retina: icoDEx2 },
    },
    5: {
        label: 'Espagnol',
        key: 'es',
        flag: { normal: icoES, retina: icoESx2 },
    },
}

export const CONFIG_DOWNLOADS_LANGS = {
    0: { value: 'Français', key: 'fr' },
    1: { value: 'Anglais', key: 'en' },
    2: { value: 'Flamand', key: 'nl' },
    3: { value: 'Italien', key: 'it' },
    4: { value: 'Allemand', key: 'de' },
    5: { value: 'Espagnol', key: 'es' },
}

export const LANGUAGES = {
    0: { key: 'fr', value: 'FR' },
    1: { key: 'en', value: 'EN' },
    2: { key: 'nl', value: 'NL' },
    3: { key: 'it', value: 'IT' },
    4: { key: 'de', value: 'DE' },
    5: { key: 'es', value: 'ES' },
}

export const CARDS_CREATION = {
    0: {
        label: 'Alfred',
        infos: ['Illustration', '1 par jour'],
        type: 13,
        template: 'alfred_info',
        name: 'alfred',
        addurl: 'private.cards.edit',
    },
    1: {
        label: 'Article',
        infos: ['Sujet de fond', '1 par jour', 'Entre 1000 et 2000 caractères'],
        type: 18,
        template: 'article',
        name: 'article',
        addurl: 'private.cards.edit',
    },
    2: {
        label: 'Bandeau Image',
        infos: ['Illustration', '1 par jour', '1280x300px'],
        type: 15,
        template: 'bandeau_image',
        name: '',
        addurl: 'private.cards.edit',
    },
    3: {
        label: 'Bonjour',
        infos: [
            'Carte d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        type: 16,
        template: 'hello',
        name: 'hello',
        addurl: 'private.cards.edit',
    },
    4: {
        label: 'Calendrier',
        infos: ['Semaine et week-end', '1 par jour'],
        type: 51,
        template: 'calendar',
        name: 'calendar',
        addurl: 'private.cards.edit',
    },
    5: {
        label: 'Conseil',
        infos: [
            'Fortement personnalisé',
            '3 par jour',
            'Entre 400 et 500 caractères',
        ],
        type: 2,
        template: 'accompagnement_generique',
        name: 'advice',
        addurl: 'private.cards.edit',
    },
    6: {
        label: 'Coup de pouce',
        infos: [],
        type: 54,
        template: 'coup_de_pouce',
        name: '',
        addurl: 'private.cards.edit',
    },
    7: {
        label: 'Dashboard',
        infos: [
            'Analyse de la donnée quantitative',
            'Accompagne les graphiques',
            'Entre 300 et 400 caractères',
        ],
        type: 17,
        template: 'dashboard_info',
        name: 'dashboard',
        addurl: 'private.cards.edit',
    },
    8: {
        label: 'Défi',
        infos: ['Cap ou pas cap', 'Défi simple', 'Nombre de caractères limité'],
        type: 14,
        template: 'defis',
        name: 'challenge',
        addurl: 'private.cards.edit',
    },
    9: {
        label: 'Évaluation classique',
        infos: [
            'Question / collecte de données',
            '3 par jour',
            'Plusieurs types possibles',
        ],
        type: 1,
        template: 'evaluation_generique',
        name: 'eval classic',
        addurl: 'private.cards.edit',
    },
    10: {
        label: 'Évaluation comportement',
        infos: [
            'Question / collecte de données',
            '4 par jour',
            'Plusieurs types possibles',
        ],
        type: 22,
        template: 'evaluation_comportement',
        name: 'eval comportement',
        addurl: 'private.cards.edit',
    },
    11: {
        label: 'Évaluation mesure',
        infos: [
            'Question / collecte de données',
            'À chaque mesure',
            'Plusieurs types possibles',
        ],
        type: 55,
        template: 'evaluation_generique',
        name: 'eval questionnaire',
        addurl: 'private.cards.edit',
    },
    12: {
        label: 'Mail Hebdo',
        infos: [
            'Mail d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        type: 7,
        template: 'mail_hebdo',
        name: 'hebdo',
        addurl: 'private.cards.edit',
    },
    13: {
        label: 'Recette',
        infos: ['Semaine et week-end', '1 par jour', 'Variante possible'],
        type: 19,
        template: 'recipe',
        name: 'recipe',
        addurl: 'private.cards.edit',
    },
}

export const CONFIG_DOWNLOADS = {
    0: {
        label: 'de traductions',
        key: 'translations',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Anglais',
            key: 'en',
        },
        defaultId: '',
    },
    1: {
        label: 'de cartes',
        key: 'cards',
        availableType: true,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: {
            label: 'Bonjour',
            key: '16',
        },
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
        typeList: CARDS_CREATION,
    },
    // '2' : {
    //     'label' : 'd‘aliments',
    //     'key' : 'foods',
    //     'availableType' : false,
    //     'availableLang' : true,
    //     'availableId' : false,
    //     'directLink' : true,
    //     'defaultType' : '',
    //     'defaultLang' : {
    //         'label' : 'Français',
    //         'key' : 'fr'
    //     },
    //     'defaultId' : ''
    // },
    2: {
        label: 'd‘articles',
        key: 'articles',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    3: {
        label: 'de recettes',
        key: 'recipes',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    4: {
        label: 'de défis',
        key: 'challenges',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
}

import themeFood from '@app/img/themes/picto-nutrition.png'
import themeActivity from '@app/img/themes/picto-activite.png'
import themeSleep from '@app/img/themes/picto-sommeil.png'
import themeStress from '@app/img/themes/picto-stress.png'
import themeShape from '@app/img/themes/picto-moral.png'
import themeTobacco from '@app/img/themes/picto-tabac.png'
import themeAlcool from '@app/img/themes/picto-alcool.png'
import themeMemory from '@app/img/themes/picto-memoire.png'
import themeAnticancer from '@app/img/themes/picto-anticancer.png'
import themeBackache from '@app/img/themes/picto-maldedos.png'
import themeWorkingPosition from '@app/img/themes/picto-autravail.png'
import themeShapedHeart from '@app/img/themes/picto-coeur.png'
import themeHappinessPsychology from '@app/img/themes/picto-bonheur.png'
import themeProfil from '@app/img/themes/picto-profil.png'
import themeFollowedWeight from '@app/img/themes/picto-poids.png'
import themeHappyPregnancy from '@app/img/themes/picto-grossesse.png'
import themeTiredness from '@app/img/themes/picto-fatigue.png'
import themeSport from '@app/img/themes/picto-sport.png'
import themeDenivele from '@app/img/themes/picto-activite.png'

export const CARDS_TYPES = {
    0: { theme: '', value: '', key: 0, color: '' },
    1: {
        theme: 'food',
        value: 'Nutrition',
        i18nLabel: t('Nutrition'),
        key: 1,
        color: 'FF6F41',
        order: 13,
        img: themeFood,
        display: 'both',
    },
    2: {
        theme: 'activity',
        value: 'Activité',
        i18nLabel: t('Activité'),
        key: 2,
        color: '049588',
        order: 1,
        img: themeActivity,
        display: 'both',
    },
    3: {
        theme: 'sleep',
        value: 'Sommeil',
        i18nLabel: t('Sommeil'),
        key: 3,
        color: '3E51B5',
        order: 16,
        img: themeSleep,
        display: 'both',
    },
    4: {
        theme: 'stress',
        value: 'Stress',
        i18nLabel: t('Stress'),
        key: 4,
        color: 'E81E63',
        order: 17,
        img: themeStress,
        display: 'both',
    },
    5: {
        theme: 'shape',
        value: 'Moral',
        i18nLabel: t('Moral'),
        key: 5,
        color: 'FEC009',
        order: 12,
        img: themeShape,
        display: 'both',
    },
    6: {
        theme: 'tobacco',
        value: 'Tabac',
        i18nLabel: t('Tabac'),
        key: 6,
        color: 'FF9A00',
        order: 19,
        img: themeTobacco,
        display: 'both',
    },
    7: {
        theme: 'alcool',
        value: 'Alcool',
        i18nLabel: t('Alcool'),
        key: 8,
        color: 'A62671',
        order: 2,
        img: themeAlcool,
        display: 'both',
    },
    8: {
        theme: 'memory',
        value: 'Mémoire',
        i18nLabel: t('Mémoire'),
        key: 9,
        color: '00BABA',
        order: 12,
        img: themeMemory,
        display: 'both',
    },
    9: {
        theme: 'anticancer',
        value: 'Anti-cancer',
        i18nLabel: t('Anti-cancer'),
        key: 10,
        color: '',
        order: 3,
        img: themeAnticancer,
        display: 'both',
    },
    10: {
        theme: 'backache',
        value: 'Mal de dos',
        i18nLabel: t('Mal de dos'),
        key: 11,
        color: 'C83B4C',
        order: 11,
        img: themeBackache,
        display: 'both',
    },
    11: {
        theme: 'working-position',
        value: 'Au travail',
        i18nLabel: t('Au travail'),
        key: 12,
        color: '4EC9DF',
        order: 4,
        img: themeWorkingPosition,
        display: 'both',
    },
    12: {
        theme: 'shaped-heart',
        value: 'Coeur',
        i18nLabel: t('Coeur'),
        key: 13,
        color: '',
        order: 6,
        img: themeShapedHeart,
        display: 'both',
    },
    13: {
        theme: 'happiness-psychology',
        value: 'Bonheur',
        i18nLabel: t('Bonheur'),
        key: 14,
        color: '',
        order: 5,
        img: themeHappinessPsychology,
        display: 'both',
    },
    14: {
        theme: 'profil',
        value: 'Profil',
        i18nLabel: t('Profil'),
        key: 15,
        color: '',
        order: 14,
        img: themeProfil,
        display: 'both',
    },
    15: {
        theme: 'followed-weight',
        value: 'Suivis de poids',
        i18nLabel: t('Suivis de poids'),
        key: 16,
        color: '5F7D8B',
        order: 15,
        img: themeFollowedWeight,
        display: 'both',
    },
    16: {
        theme: 'happy-pregnancy',
        value: 'Grossesse',
        i18nLabel: t('Grossesse'),
        key: 17,
        color: '7481CF',
        order: 10,
        img: themeHappyPregnancy,
        display: 'both',
    },
    17: {
        theme: 'tiredness',
        value: 'Fatigue',
        i18nLabel: t('Fatigue'),
        key: 18,
        color: '3576A1',
        order: 9,
        img: themeTiredness,
        display: 'both',
    },
    // '18' : {'theme' : 'sport', 'value' : 'Sport', 'i18nLabel' : t('Sport'), 'key' : 19, 'color' : '4CAF50', 'order': 18, 'img': themeSport, 'display': 'both'},
    // '19' : {'theme' : 'denivele', 'value' : 'Dénivelé', 'i18nLabel' : t('Dénivelé'), 'key' : 20, 'color' : '049588', 'order': 8, 'img': themeDenivele, 'display': 'both'},
}

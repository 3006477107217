import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card } from '@app/api'
import { addCSRFToken } from '@app/core'

const reward_PROMOTIONS = {
    filter: [],
    tableHead: [
        { key: 'name', label: 'id' },
        { key: 'description', label: 'Message' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/reward/promotions/:id'),
        },
        {
            key: 'duplicate',
            label: 'Dupliquer',
            kind: 'web',
            action: addCSRFToken('/reward/promotions/:id') + '&mode=duplicate',
        },
        {
            key: 'status',
            label: 'Désactiver',
            kind: 'api',
            action: Card.editReward,
        },
        {
            key: 'delete',
            label: 'Supprimer',
            kind: 'confirm',
            action: Card.deleteReward,
        },
    ],
    tableAllOptions: {
        label: null,
        list: [],
    },
    header: ['Configurer le reward', 'Promotion'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'rew-promotions',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Id de la carte',
            field: 'text',
            placeholder: 'Renseignez ici l‘id de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner l‘id de la carte',
                i18nMessage: t('Veuillez renseigner l‘id de la carte'),
            },
        },
        redirectUrl: {
            name: 'redirectUrl',
            type: 'string',
            label: 'Url de l‘offre',
            field: 'text',
            placeholder: 'Renseignez ici le lien de l‘offre',
            value: '',
        },
        media: {
            name: 'media',
            substitute: 'mediaId',
            type: 'file',
            label: 'Image',
            field: 'media',
            value: '',
        },
        howTo: {
            name: 'howTo',
            type: 'translation',
            label: 'Comment débloquer ?',
            field: 'textarea',
            placeholder:
                'Renseignez ici la méthode pour débloquer votre offre (entre 300 et 600 caractères)',
            value: {},
        },
        description: {
            name: 'description',
            type: 'translation',
            label: 'Déscription de l‘offre',
            field: 'textarea',
            placeholder:
                'Renseignez ici la description de votre offre (entre 300 et 600 caractères)',
            value: {},
        },
    },
}

export { reward_PROMOTIONS }

import * as documents from '@app/documents'

export const CARDS = {
    1: { value: 'Article', key: '18', document: documents.card_ARTICLE },
    2: {
        value: 'Bandeau Image',
        key: '15',
        document: documents.card_BANDEAU_IMAGE,
    },
    3: { value: 'Bonjour', key: '16', document: documents.card_HELLO },
    4: { value: 'Conseil', key: '2', document: documents.card_ADVICE },
    5: {
        value: 'Évaluation Classique',
        key: '1',
        document: documents.card_EVALUATION,
    },
    6: {
        value: 'Évaluation Comportement',
        key: '22',
        document: documents.card_EVALUATION_BEHAVIOUR,
    },
    7: { value: 'Quizz', key: '12', document: documents.card_QUIZZ },
    8: { value: 'Recette', key: '19', document: documents.card_RECIPE },
    9: { value: 'Coach', key: '50', document: documents.card_ADVICE },
    10: { value: 'Alfred', key: '13', document: documents.card_ALFRED },
    11: { value: 'Calendrier', key: '51', document: documents.card_CALENDRIER },
    12: { value: 'Prénom', key: '8', document: documents.card_SURNAME },
    13: { value: 'Votre bébé', key: '52', document: documents.card_YOUR_BABY },
    14: {
        value: 'Évaluation Classique',
        key: '1',
        document: documents.card_EVALUATION,
    },
    15: {
        value: 'Évaluation Symptomes',
        key: '9',
        document: documents.card_EVALUATION_SYMPTOME,
    },
}

export const STEP_CATEGORIES = {
    1: {
        value: 'Objectif',
        key: 1,
        document: documents.program_step_CARD_OBJECTIF,
    },
    2: {
        value: 'Évaluation',
        key: 2,
        document: documents.program_step_CARD_EVALUATION,
    },
    3: {
        value: 'Exercice',
        key: 3,
        document: documents.program_step_CARD_EXERCICE,
    },
    4: {
        value: 'Conseil',
        key: 4,
        document: documents.program_step_CARD_ADVICE,
    },
}

export const ALERTS = {
    1: { value: 'Défis', key: 1, document: documents.alert_CHALLENGE },
    2: { value: 'Programme', key: 3, document: documents.alert_PROGRAM },
}

import { t } from '@app/utils'
import { Customers } from '@app/api'
import { addCSRFToken } from '@app/core'

const user_CUSTOMER = {
    filter: [],
    search: { label: 'ID, KPI, Entreprises' },
    tableHead: [
        { key: 'name', label: 'entreprise' },
        { key: 'parent', label: 'entreprise mère' },
        { key: 'maxLicenses', label: 'lic. disponibles' },
        { key: 'maxChildren', label: 'lic. secondaires' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/users/customers/:id'),
        },
        {
            key: 'deleteCustomer',
            label: 'Supprimer',
            kind: 'confirm',
            action: Customers.deleteCustomer,
        },
    ],
    tableAllOptions: {
        label: 'ENTREPRISES SÉLECTIONNÉES',
        list: [
            {
                key: 'deleteCustomer',
                label: 'Supprimer',
                kind: 'confirm',
                action: Customers.deleteCustomer,
            },
        ],
    },
    header: ['Configurer l‘entreprise'],
    form: {
        tabs: [
            {
                type: 'string',
                field: 'tabs',
                value: [
                    {
                        label: 'scenarios',
                        key: 'alerts&seuiles',
                        marker: 'listing-alerts&seuiles',
                    },
                ],
            },
            {
                type: 'string',
                field: 'tabs',
                value: [
                    {
                        label: 'licences',
                        key: 'accountlicenses',
                        marker: 'listing-licenses',
                    },
                ],
            },
        ],
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'customer',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer',
            field: 'radio',
            choice: {
                Oui: 0,
                Non: 1,
            },
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de l‘entreprise',
            field: 'text',
            placeholder: 'Renseignez ici le nom de l‘entreprise',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de l‘entreprise',
                i18nMessage: t('Veuillez renseigner le nom de l‘entreprise'),
            },
            display: {
                mode: 'half',
            },
        },
        mutual: {
            name: 'mutual',
            type: 'boolean',
            label: 'Mutuelle',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        email: {
            name: 'email',
            type: 'string',
            label: 'Email de l‘entreprise',
            field: 'email',
            placeholder: 'Renseignez ici l‘email de l‘entreprise',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'is-email',
                message: 'Veuillez renseigner l‘email de l‘entreprise',
                i18nMessage: t('Veuillez renseigner l‘email de l‘entreprise'),
            },
            display: {
                mode: 'half',
            },
        },
        partner: {
            name: 'partner',
            type: 'boolean',
            label: 'Partenaire',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        expiration: {
            name: 'expiration',
            substitute: 'expire',
            type: 'date',
            label: 'Date d‘expiration',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'is-date',
                message:
                    'Veuillez renseigner la date d‘expiration de l‘entreprise',
                i18nMessage: t(
                    'Veuillez renseigner la date d‘expiration de l‘entreprise'
                ),
            },
            display: {
                mode: 'half',
            },
        },
        hospital: {
            name: 'hospital',
            type: 'boolean',
            label: 'Hopital',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        inviteCode: {
            name: 'inviteCode',
            type: 'string',
            label: 'Code d‘invitation',
            field: 'text',
            placeholder: 'Renseignez ici le nom de l‘entreprise',
            value: '',
            display: {
                mode: 'half',
            },
        },
        corporate: {
            name: 'corporate',
            type: 'boolean',
            label: 'Corporate',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: '',
            display: {
                mode: 'half',
            },
        },
        domain: {
            name: 'domain',
            type: 'domaincustomer',
            label: 'Domaine email entreprise',
            field: 'text',
            placeholder: 'Renseignez ici le nom de domaine de l‘entreprise',
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        maxLicenses: {
            name: 'maxLicenses',
            type: 'int',
            label: 'Quotas',
            field: 'int',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        maxChildren: {
            name: 'maxChildren',
            type: 'int',
            label: 'Licences secondaire',
            field: 'int',
            value: '',
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
    },
}

export { user_CUSTOMER }

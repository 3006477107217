import { t } from '@app/utils'
import * as constants from '@app/constants'

const momentStudies = [
    'curve',
    'urorein',
    'uroreinambu',
    'uroprostate',
    'urovessie',
    'less',
    'uroaccurate99',
    'uroanxiety113',
    'uroanxiety113dipru',
    'urodipru',
    'urosocratic152sa',
    'urosocratic152sur',
    'uropersonalize114',
]
const card_EVALUATION_SURVEY = {
    header: ['Configurer la carte', 'Évaluation questionnaire'],
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'formulaire_survey',
        },
        flags: {
            type: 'string',
            field: 'translation',
            value: Object.keys(constants.custo.COUNTRIES).map(
                (i) => constants.custo.COUNTRIES[i]
            ),
        },
        langs: {
            type: 'string',
            field: 'translation',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins une langue',
                i18nMessage: t('Veuillez sélectionner au moins une langue'),
            },
        },
        type: {
            type: 'int',
            field: 'hidden',
            value: '',
        },
        status: {
            name: 'status',
            type: 'boolean',
            label: 'Activer la carte',
            field: 'radio',
            choice: {
                oui: 1,
                non: 0,
            },
            value: '',
        },
        skippableEval: {
            name: 'skippableEval',
            type: 'boolean',
            label: 'Facultative ?',
            field: 'radio',
            choice: {
                oui: true,
                non: false,
            },
            value: false,
            // 'beforeSend': {
            //     'ifvalue': {'key': 0, 'value': null},
            // },
        },
        customers: {
            name: 'customers',
            type: 'customer',
            label: '',
            field: 'checkbox',
            list: 'static',
            allSelect: true,
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastOneChecked',
                message: 'Veuillez sélectionner au moins un customer',
                i18nMessage: t('Veuillez sélectionner au moins un customer'),
            },
        },
        owner: {
            name: 'owner',
            type: 'owner',
            label: 'Attribué à',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: 'Betterise',
            value: '',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Nom de la carte',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici le nom de la carte',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner le nom de la carte',
                i18nMessage: t('Veuillez renseigner le nom de la carte'),
            },
        },
        title: {
            name: 'title',
            type: 'translation',
            label: 'Question',
            field: 'text',
            mandatory: true,
            placeholder: 'Renseignez ici votre question',
            value: {},
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez renseigner la question',
                i18nMessage: t('Veuillez renseigner la question'),
            },
        },
        shortTitle: {
            name: 'shortTitle',
            type: 'translation',
            label: 'Version courte',
            field: 'text',
            placeholder: 'Renseignez ici la version courte de votre question',
            value: {},
        },
        extra: {
            name: 'extra',
            type: 'translation',
            label: 'Mot d‘alfred',
            field: 'textarea',
            placeholder: 'Renseignez ici le mot d‘alfred',
            value: {},
        },
        theme: {
            name: 'theme',
            type: 'int',
            label: 'Thématique',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_TYPES,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une thématique',
                i18nMessage: t('Veuillez sélectionner une thématique'),
            },
        },
        frequency: {
            name: 'frequency',
            type: 'int',
            label: 'Fréquence',
            field: 'dropdown',
            mandatory: true,
            list: constants.custo.FREQUENCY,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une fréquence',
                i18nMessage: t('Veuillez sélectionner une fréquence'),
            },
        },
        expression: {
            name: 'expression',
            type: 'kpi',
            label: 'KPI',
            field: 'input-to-textarea',
            searchbox: true,
            model: { key: null, value: null },
            list: 'static',
            placeholder: {
                input: 'Tapez votre recherche ici ...',
                textarea: 'Renseignez ici les critères définissant cette carte',
            },
            value: '',
        },
        medicalStudy: {
            name: 'medicalStudy',
            type: 'string',
            label: 'Étude médicale',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_MEDICAL_STUDIES,
            resetTargetValue: 'medicalStudyMoment',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner une étude médicale',
                i18nMessage: t('Veuillez sélectionner une étude médicale'),
            },
        },
        parent: {
            name: 'parent',
            substitute: 'parent',
            type: 'autocomplete/cards/type[58',
            label: 'Carte mère',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: '',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'selected',
                message: 'Veuillez renseigner une carte mère',
                i18nMessage: t('Veuillez renseigner une carte mère'),
            },
        },
        medicalStudyMoment: {
            name: 'medicalStudyMoment',
            type: 'string',
            label: 'Moment',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_MEDICAL_MOMENT,
            resetTargetValue: 'medicalStudySurvey',
            dynListKey: 'medicalStudy',
            value: 'M1',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un moment',
                i18nMessage: t('Veuillez sélectionner un moment'),
            },
            dependsOn: {
                field: 'medicalStudy',
                value: momentStudies,
            },
        },
        medicalStudySurvey: {
            name: 'medicalStudySurvey',
            type: 'string',
            label: 'Questionnaire',
            field: 'dropdown',
            mandatory: true,
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.CARDS_MEDICAL_MOMENT_STUDIES,
            filterValue: ['medicalStudyMoment'],
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un moment',
                i18nMessage: t('Veuillez sélectionner un moment'),
            },
            dependsOn: {
                field: 'medicalStudy',
                value: momentStudies,
            },
        },
        forms: {
            // for evaluations
            name: 'eval',
            substitute: 'formDatas',
            type: 'translation',
            label: 'Détail du formulaire',
            field: 'eval',
            model: {},
            list: 'static',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'atLeastTwoFilled',
                noValidationType: {
                    field: 'formsType',
                    value: 'Textarea',
                },
                message:
                    'Veuillez renseigner au moins deux entrées dans votre évaluation',
                i18nMessage: t(
                    'Veuillez renseigner au moins deux entrées dans votre évaluation'
                ),
            },
            sendElsewhere: true,
            wrappedIn: 'forms',
            dependsOn: {
                field: 'formsType',
                value: ['Slider', 'Button', 'QCM', 'Textarea', 'Yesno'],
            },
        },
        formsClinical: {
            // for evaluations
            name: 'evalClinical',
            substitute: 'formDatas',
            type: 'translation',
            label: 'Détail du formulaire',
            field: 'evalClinical',
            model: {},
            list: 'static',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'minMaxDefault',
                message:
                    'Veuillez renseigner les trois valeurs, minimale, maximale et par défaut',
                i18nMessage: t(
                    'Veuillez renseigner les trois valeurs, minimale, maximale et par défaut'
                ),
            },
            sendElsewhere: true,
            wrappedIn: 'formsClinical',
            dependsOn: {
                field: 'formsType',
                value: [
                    'CryptedWeight',
                    'BloodPressure',
                    'CryptedTemperature',
                    'Heartbeat',
                ],
            },
        },
        dataDate: {
            name: 'dataDate',
            type: 'string',
            label: 'dateDate',
            field: 'hidden',
            value: 'P0D',
            wrappedIn: ['forms', 'formsClinical'],
        },
        formsType: {
            name: 'type',
            substitute: 'type',
            type: 'int',
            label: 'Type',
            field: 'dropdown',
            list: constants.custo.EVAL_TYPES_MEDICAL,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un type pour votre évaluation',
                i18nMessage: t(
                    'Veuillez sélectionner un type pour votre évaluation'
                ),
            },
            wrappedIn: ['forms', 'formsClinical'],
        },
        sliderTag: {
            name: 'sliderTag',
            type: 'datatags',
            label: 'Data tag',
            field: 'dropdown',
            list: 'dynamic',
            value: '',
            placeholder: 'Tapez votre recherche ici ...',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un dataTag pour votre slider',
                i18nMessage: t(
                    'Veuillez sélectionner un dataTag pour votre slider'
                ),
            },
            wrappedIn: 'forms',
            dependsOn: {
                field: 'formsType',
                value: 'Slider',
            },
        },
        textareaTag: {
            name: 'textareaTag',
            type: 'datatags',
            label: 'Data tag',
            field: 'dropdown',
            list: 'dynamic',
            value: '',
            placeholder: 'Tapez votre recherche ici ...',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un dataTag pour votre textarea',
                i18nMessage: t(
                    'Veuillez sélectionner un dataTag pour votre textarea'
                ),
            },
            wrappedIn: 'forms',
            dependsOn: {
                field: 'formsType',
                value: 'Textarea',
            },
        },
        counterTag: {
            name: 'counterTag',
            type: 'datatags',
            label: 'Data tag',
            field: 'dropdown',
            list: 'dynamic',
            value: '',
            placeholder: 'Tapez votre recherche ici ...',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message: 'Veuillez sélectionner un dataTag pour votre compteur',
                i18nMessage: t(
                    'Veuillez sélectionner un dataTag pour votre compteur'
                ),
            },
            wrappedIn: ['forms'],
            dependsOn: {
                field: 'formsType',
                value: ['Counter', 'StatCounter'],
            },
        },
        medicalStudyEvalMeasureKpi: {
            name: 'medicalStudyEvalMeasureKpi',
            type: 'datatags',
            label: 'Kpi de la mesure',
            field: 'dropdown',
            list: 'dynamic',
            value: '',
            placeholder: 'Tapez votre recherche ici ...',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'selected',
                message:
                    'Veuillez sélectionner la kpi de la mesure de votre évaluation',
                i18nMessage: t(
                    'Veuillez sélectionner la kpi de la mesure de votre évaluation'
                ),
            },
            dependsOn: {
                field: 'formsType',
                value: [
                    'CryptedWeight',
                    'BloodPressure',
                    'CryptedTemperature',
                    'Heartbeat',
                ],
                // 'ifUnset': '',
            },
        },
        min: {
            name: 'min',
            type: 'int',
            label: 'Valeur minimale',
            field: 'int',
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez renseigner la valeur minimale pour votre compteur',
                i18nMessage: t(
                    'Veuillez renseigner la valeur minimale pour votre compteur'
                ),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'formsType',
                value: ['Counter', 'StatCounter'],
            },
            wrappedIn: 'forms',
        },
        max: {
            name: 'max',
            type: 'int',
            label: 'Valeur maximale',
            field: 'int',
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez renseigner la valeur maximale pour votre compteur',
                i18nMessage: t(
                    'Veuillez renseigner la valeur maximale pour votre compteur'
                ),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'formsType',
                value: ['Counter', 'StatCounter'],
            },
            wrappedIn: 'forms',
        },
        default: {
            name: 'default',
            type: 'int',
            label: 'Valeur par défaut',
            field: 'int',
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez renseigner la valeur par défaut pour votre compteur',
                i18nMessage: t(
                    'Veuillez renseigner la valeur par défaut pour votre compteur'
                ),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'formsType',
                value: ['Counter', 'StatCounter'],
            },
            wrappedIn: 'forms',
        },
        step: {
            name: 'step',
            type: 'float',
            label: 'Valeur du pas',
            field: 'float',
            mandatory: true,
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message:
                    'Veuillez renseigner la valeur du pas pour votre compteur',
                i18nMessage: t(
                    'Veuillez renseigner la valeur du pas pour votre compteur'
                ),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'formsType',
                value: ['Counter', 'StatCounter'],
            },
            wrappedIn: 'forms',
        },
    },
}

export { card_EVALUATION_SURVEY }

import { t, h, Component } from '@app/utils'
import { Settings } from '@app/api'
import { isDefined } from '@app/core'
import { AFCheckBoxLinkedModal } from '@app/elements'
import './index.scss'

const AdminFormCheckBoxLinkedModal = Component(
    {
        checkboxs: null,
        checkedboxs: {},
        sendToParent: null,
        readonly: false,
        readonlyValues: [],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.onclick) {
                state.sendToParent = props.onclick
            }
            if (props.name) {
                actions.getCheckBoxList(props)
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.document !== null && props.document.value !== undefined) {
                actions.setCheckedCheckBoxList(props.document.value)
                if (props.document.readonlyValues) {
                    actions.setReadonlyValues(props.document.readonlyValues)
                }
            }
        },
        getCheckBoxList: (props) => (state, actions) => {
            let checkboxs = []
            if (props.name === 'customers') {
                Settings.getCustomers().then((res) => {
                    if (isDefined(res.data) && !res.data.isError) {
                        actions.setCheckBoxList(res.data)
                    }
                })
            } else if (props.document.list) {
                let list = props.document.list
                if (!Array.isArray(list)) {
                    let parsedList = []
                    for (var o in list) {
                        // concern themes, especially
                        if (list[o].value !== '') {
                            parsedList.push({
                                name: list[o].value,
                                id: o,
                                img: list[o].img,
                            })
                        }
                    }
                    list = parsedList
                }
                actions.setCheckBoxList(list)
            }
        },
        selectThisOne: (el) => (state, actions) => {
            let checkedboxs = state.checkedboxs
            let target = el.target || el
            let checkboxName = target.name || target.getAttribute('data-name')
            let checkboxChecked =
                target.checked || target.getAttribute('data-checked')
            let checkboxValue =
                target.value || target.getAttribute('data-value')

            if (checkedboxs[checkboxName] === undefined) {
                checkedboxs[checkboxName] = []
            } else {
                delete checkedboxs[checkboxName]
            }
            actions.setCheckedCheckBoxList(checkedboxs)
            state.sendToParent({
                target: { name: checkboxName, value: checkedboxs },
            })
        },
        setCheckBoxList: (newState) => (state) => ({
            checkboxs: newState,
        }),
        setCheckedCheckBoxList: (newState) => (state) => ({
            checkedboxs: newState,
        }),
        setReadonlyValues: (newState) => (state) => ({
            readonlyValues: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={state.name} class="sup-group-checkboxs">
            {state.checkboxs !== null && Array.isArray(state.checkboxs) && (
                <div>
                    {state.checkboxs.map((chk) => (
                        <div
                            style={{
                                'display': 'inline-block',
                                'vertical-align': 'top',
                            }}
                            key={chk.id + '-' + props.name}
                            class={chk.id + '-' + props.name}
                        >
                            <AFCheckBoxLinkedModal
                                key={props.key}
                                checkbox={chk}
                                checkedboxs={state.checkedboxs}
                                name={chk.id}
                                onclick={actions.selectThisOne}
                            ></AFCheckBoxLinkedModal>
                            {props.document.withConditions === 'questions' && (
                                <div class="sup-group-checkboxs-conditions-wrapper">
                                    {state.checkedboxs[chk.id] !==
                                        undefined && (
                                        <p
                                            class="btn btn-link"
                                            onclick={() => props.ontoggle(chk)}
                                        >
                                            {t(
                                                '+ ajouter une condition' +
                                                    ' (' +
                                                    state.checkedboxs[chk.id]
                                                        .length +
                                                    ')'
                                            )}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    ),
    'adminformcheckboxlinkedmodal'
)

export { AdminFormCheckBoxLinkedModal }

import { h } from '@app/utils'

const TextTransform = (props, children) => {
    let parsedChildren = children

    if (parsedChildren === null || parsedChildren === undefined) {
        return parsedChildren
    } else if (parsedChildren[0] !== undefined) {
        parsedChildren = parsedChildren[0]
    }

    if (props.mode === 'capitalize') {
        return parsedChildren.charAt(0).toUpperCase() + parsedChildren.slice(1)
    }

    return parsedChildren
}

export { TextTransform }

import { t, h } from '@app/utils'
import { allRoutes, addCSRFToken } from '@app/core'
import { Link } from '@app/elements'

import { UserMenu } from '@app/layouts/usermenu'

import icoAddEntry from './maternite/add-icon.png'
import icoAddEntryx2 from './maternite/add-icon@2x.png'

import icoCards from '@app/img/ico/left-menu/ico-cartes.png'
import icoCardsx2 from '@app/img/ico/left-menu/ico-cartes@2x.png'
import icoCardsGrey from '@app/img/ico/left-menu/ico-cartes-grey.png'
import icoCardsGreyx2 from '@app/img/ico/left-menu/ico-cartes-grey@2x.png'

import icoArticles from '@app/img/ico/left-menu/ico-articles.png'
import icoArticlesx2 from '@app/img/ico/left-menu/ico-articles@2x.png'
import icoArticlesGrey from '@app/img/ico/left-menu/ico-articles-grey.png'
import icoArticlesGreyx2 from '@app/img/ico/left-menu/ico-articles-grey@2x.png'

import icoRecipes from '@app/img/ico/left-menu/ico-recettes.png'
import icoRecipesx2 from '@app/img/ico/left-menu/ico-recettes@2x.png'
import icoRecipesGrey from '@app/img/ico/left-menu/ico-recettes-grey.png'
import icoRecipesGreyx2 from '@app/img/ico/left-menu/ico-recettes-grey@2x.png'

import icoCoachs from '@app/img/ico/left-menu/ico-coachs.png'
import icoCoachsx2 from '@app/img/ico/left-menu/ico-coachs@2x.png'
import icoCoachsGrey from '@app/img/ico/left-menu/ico-coachs-grey.png'
import icoCoachsGreyx2 from '@app/img/ico/left-menu/ico-coachs-grey@2x.png'

import icoEmails from '@app/img/ico/left-menu/ico-message-rh.png'
import icoEmailsx2 from '@app/img/ico/left-menu/ico-message-rh@2x.png'
import icoEmailsGrey from '@app/img/ico/left-menu/ico-message-rh-grey.png'
import icoEmailsGreyx2 from '@app/img/ico/left-menu/ico-message-rh-grey@2x.png'

import icoUsers from '@app/img/ico/left-menu/ico-users.png'
import icoUsersx2 from '@app/img/ico/left-menu/ico-users@2x.png'
import icoUsersGrey from '@app/img/ico/left-menu/ico-users-grey.png'
import icoUsersGreyx2 from '@app/img/ico/left-menu/ico-users-grey@2x.png'

import icoCustomers from '@app/img/ico/left-menu/ico-entreprises.png'
import icoCustomersx2 from '@app/img/ico/left-menu/ico-entreprises@2x.png'
import icoCustomersGrey from '@app/img/ico/left-menu/ico-entreprises-grey.png'
import icoCustomersGreyx2 from '@app/img/ico/left-menu/ico-entreprises-grey@2x.png'

export default {
    initView: function () {
        let view = (props, actions) => (
            <div style={{ padding: '20px 0px' }}>
                <div>
                    {props.currentPage == 'content' && (
                        <ul>
                            <li class={props.page == 'cards' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCards}
                                        srcset={`${icoCardsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCardsGrey}
                                        srcset={`${icoCardsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.cards'].pathname
                                    )}
                                >
                                    {t('Cartes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.cards.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={props.page == 'articles' ? 'active' : ''}
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoArticles}
                                        srcset={`${icoArticlesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoArticlesGrey}
                                        srcset={`${icoArticlesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.articles'].pathname
                                    )}
                                >
                                    {t('Articles')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.articles.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'recipes' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoRecipes}
                                        srcset={`${icoRecipesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoRecipesGrey}
                                        srcset={`${icoRecipesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.recipes'].pathname
                                    )}
                                >
                                    {t('Recettes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.recipes.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'coachs' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCoachs}
                                        srcset={`${icoCoachsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCoachsGrey}
                                        srcset={`${icoCoachsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.coachs'].pathname
                                    )}
                                >
                                    {t('Coachs')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.coachs.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'emails' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoEmails}
                                        srcset={`${icoEmailsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoEmailsGrey}
                                        srcset={`${icoEmailsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.emails'].pathname
                                    )}
                                >
                                    {t('Emails')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.emails.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'calendars' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoEmails}
                                        srcset={`${icoEmailsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoEmailsGrey}
                                        srcset={`${icoEmailsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.calendars'].pathname
                                    )}
                                >
                                    {t('Calendrier')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.calendars.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'users' && (
                        <ul>
                            <li class={props.page == 'users' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoUsers}
                                        srcset={`${icoUsersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoUsersGrey}
                                        srcset={`${icoUsersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.users']
                                            .pathname
                                    )}
                                >
                                    {t('Utilisateurs')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.users.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'customers' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCustomers}
                                        srcset={`${icoCustomersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCustomersGrey}
                                        srcset={`${icoCustomersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.customers']
                                            .pathname
                                    )}
                                >
                                    {t('Entreprises')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.customers.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'forum' && (
                        <ul>
                            <li class={props.page == 'forumfr' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoEmails}
                                        srcset={`${icoEmailsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoEmailsGrey}
                                        srcset={`${icoEmailsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.forum.forumfr']
                                            .pathname
                                    )}
                                >
                                    {t('Forum FR')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.forum.forumfr.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'forumus' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoEmails}
                                        srcset={`${icoEmailsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoEmailsGrey}
                                        srcset={`${icoEmailsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.forum.forumus']
                                            .pathname
                                    )}
                                >
                                    {t('Forum US')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.forum.forumus.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={props.page == 'forumtag' ? 'active' : ''}
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCustomers}
                                        srcset={`${icoCustomersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCustomersGrey}
                                        srcset={`${icoCustomersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.forum.tag'].pathname
                                    )}
                                >
                                    {t('Tag')}
                                </Link>
                                <dummy></dummy>
                            </li>
                        </ul>
                    )}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '65px',
                        }}
                    >
                        <UserMenu display={'connected'}></UserMenu>
                    </div>
                </div>
            </div>
        )
        return view
    },
}

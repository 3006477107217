import { h } from '@app/utils'
import './index.scss'

let lastPath = ''
const Meta = (props, children) => {
    if (lastPath != location.pathname) {
        if (document.querySelector('title') && props.title) {
            document.querySelector('title').text = props.title
            document
                .querySelector('meta[name=ogtitle]')
                .setAttribute('content', props.title)
        }
        if (
            document.querySelector('meta[name=description]') &&
            props.description
        ) {
            document
                .querySelector('meta[name=description]')
                .setAttribute('content', props.description)
            document
                .querySelector('meta[name=ogdescription]')
                .setAttribute('content', props.description)
        }
        if (document.querySelector('meta[name=image]') && props.image) {
            document
                .querySelector('meta[name=image]')
                .setAttribute('content', props.image)
            document
                .querySelector('meta[name=ogimage]')
                .setAttribute('content', props.image)
        }
        if (document.querySelector('meta[name=url]') && props.url) {
            document
                .querySelector('meta[name=url]')
                .setAttribute('content', props.url)
            document
                .querySelector('meta[name=ogurl]')
                .setAttribute('content', props.url)
        }
        if (document.querySelector('meta[name=type]') && props.type) {
            document
                .querySelector('meta[name=type]')
                .setAttribute('content', props.type)
            document
                .querySelector('meta[name=ogtype]')
                .setAttribute('content', props.type)
        }
        if (document.querySelector('link[name=favicon]') && props.favicon) {
            let milliseconds = new Date().getTime()
            document
                .querySelector('link[name=favicon]')
                .setAttribute('href', props.favicon + '?v=' + milliseconds)
        }

        lastPath = location.pathname
    }
}
export { Meta }

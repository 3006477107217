import { t, h, Component } from '@app/utils'
// import { allRoutes } from '@app/core';
import { Link, Button } from '@app/elements'
import { createCookie, readCookie } from '@app/core'
import './index.scss'

const BotDisclamerView = Component(
    {
        displayed: true,
        urlPdfCgu: '',
    },
    {
        onComponentUpdate: (props) => (state, actions) => {
            if (props.customer) {
                // if (props.customer === 'gjensidige') {
                //     actions.setUrlPdfCgu(allRoutes['pdf.iver.cgu'].pathname);
                // } else if (props.customer === 'partena') {
                //     actions.setUrlPdfCgu(allRoutes['pdf.partena.cgu'].pathname);
                // } else if (props.customer === 'auchan') {
                //     let lang = 'fr';
                //     actions.setUrlPdfCgu(allRoutes['pdf.auchan.cgu'].pathname.replace(':lang', lang));
                // }
            }
        },
        removeDisclamer: () => (state) => ({
            displayed: false,
        }),
        setUrlPdfCgu: (newState) => (state) => ({
            urlPdfCgu: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {(readCookie('rgpd') !== 'accepted' ||
                readCookie('rgpd') === null) &&
                state.displayed && (
                    <div
                        id="btzDisclamer-btzDisclamers"
                        class="btzDisclamer-btzDisclamers"
                    >
                        <div class="col-12 p-0 btzDisclamer-btzDisclamers-btzWrapper">
                            <div class="col-8 col-xs-12">
                                <p class="btzDisclamer-btzDisclamer">
                                    {t(
                                        'En poursuivant votre navigation vous acceptez les CGU et par conséquent l‘utilisation de cookies pour vous proposer des services et offres adaptés à vos centres d‘intérêts.'
                                    )}{' '}
                                    <Link
                                        to={state.urlPdfCgu}
                                        target={'_blank'}
                                    >
                                        {t('En savoir plus')}
                                    </Link>
                                    .
                                </p>
                            </div>
                            <div class="col-4 col-xs-12">
                                <p class="btzDisclamer-btzDisclamer btzDisclamer-btzDisclamer-btzLinks">
                                    <Button
                                        flat
                                        active
                                        onclick={() => {
                                            createCookie('rgpd', 'accepted', '')
                                            actions.removeDisclamer()
                                        }}
                                    >
                                        {t('J‘ai compris')}
                                    </Button>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    ),
    'botdisclamerview'
)

export { BotDisclamerView }
